import React from 'react'

import RadioGroupSimpleList from '../../common/RadioGroupSimpleList'
import { useSelector } from 'react-redux'
import { useLanguage } from '../../../app/hooks/useLanguage'
import { useTranslation } from 'react-i18next'


const lang = [
    { id: null, name: 'English', value: "en" },
    { id: 1, name: 'Francais', value: "fr" },
]

const theme = [
    { id: null, name: 'Light', value: "light" },
    { id: 1, name: 'Dark', value: "dark" },
]

const AccountSection = () => {
    const { lng } = useSelector(state => state.app)
    const { changeLanguage } = useLanguage()
    const { t } = useTranslation()

    return (
      <div className="mt-10 divide-y divide-gray-200">
        <div className="space-y-1">
          <h3 className="text-lg font-medium leading-6 text-gray-900">
            {t("account")}
          </h3>
          <p className="max-w-2xl text-sm text-gray-500">
            {t("manage_how_information_is_displayed_on_your_account")}
          </p>
        </div>
        <div className="mt-6">
          <dl className="divide-y divide-gray-200">
            <RadioGroupSimpleList
              value={lng}
              setValue={changeLanguage}
              data={lang}
              label={"Language"}
            />
            <RadioGroupSimpleList data={theme} label={"Theme"} />
            
          </dl>
        </div>
       
      </div>
    );
}

export default AccountSection