import React, { useState } from 'react'
import TabsFlat from '../../common/TabsFlat';
import WithDivisions from './WithDivisions';
import WithCoordinates from './WithCoordinates';


const options = [
    { id: null, name: 'Administration Division' },
    { id: 1, name: 'Geographic Coordinates' },
]

const CreateAreaForm = () => {
    const [tabValue, setTabValue] = useState("Administration Division")

  return (
    <div className = "shadow-md bg-white px-3 py-4 " >
        <div className="my-4" >
              <TabsFlat setTabValue={setTabValue} tabValue={tabValue} tabs={options} />
        </div>
        {tabValue === "Administration Division" && <WithDivisions />}
        {tabValue === "Geographic Coordinates" && <WithCoordinates />}
    </div>
  )
}

export default CreateAreaForm