import React from 'react'
import { useSelector } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import { dispatch } from '../../app'
import { handleHeaderType } from '../../app/features/authSlice'
import CardComponent from '../../components/common/card'
import VerifyOtpForm from '../../components/layout/VerifyOtpForm'
import { HOME } from '../../constants/routes'
import logo from "../../assets/images/Logo_AFLP.png";
import { useTranslation } from 'react-i18next'
import { scroolWindowToTop } from '../../utilities'

const VerifyOtpCode = () => {
  const { t } = useTranslation();

  const { isLogged } = useSelector(state => state.auth)
  const navigate = useNavigate()
  if (isLogged) navigate(`${HOME}`)
  React.useEffect(() => {
    dispatch(handleHeaderType({ headerType: "none", isStore: false, header: false, checkout: false }))
    scroolWindowToTop()
  }, [])
  return (
    <div>
      <Link className="cursor-pointer" to={HOME}>
        <img onContextMenu={(event) => { event.preventDefault(); }}
          src={logo}
          className="w-20 h-20 object-contain object-center mx-auto my-4 "
          alt='logo'
        />
      </Link>
      <CardComponent title={"Verify code"}>
        <h2>Confirm Code</h2>
        <p className="auth-text" style={{ width: "75%", marginBottom: 25 }}>
          {t("otp_msg")}
        </p>
        <VerifyOtpForm />
      </CardComponent>
    </div>
  );
}

export default VerifyOtpCode