import { createSlice } from "@reduxjs/toolkit";
import { toJson, toString } from "../../utilities";

const initialState = {
    pagination: { "total": 0, "count": 0, "perPage": 0, "currentPage": 0, "totalPages": 0, "nextLink": "" },
    items: [],
    itemsFilter: [],
    categories: [],
    selectedStore: null,
    categoryLevel2: [],
    categoryLevel3: [],
    categoryLevel4: [],
    indexLevel2: 0,
    indexLevel3: 0,
    indexLevel4: null,
    storeGrade: 0,
    StoreGrade: ['All grades', 'Promo', 'Sell Fast', 'Gold', 'Premium', 'Silver'],
    categoriesStore: [],
    categoriesNames: [],
    subMenu: [],
    filterCategories: [],
    categoryFilter: 0,
    indexSubMenu: 0,
    stores: {},
    tabValue: "home"


}

const storeSlice = createSlice({
    name: 'store',
    initialState,
    reducers: {
        handleMenuStore(state, { payload }) {
            state.menus = payload
            state.categoryLevel2 = state.menus.categoryLevel2.sort((a, b) => Number(a?.orderHint) - Number(b?.orderHint));
            state.categoryLevel3 = state.menus.categoryLevel3.filter(m => m.parent === state.categoryLevel2[0].key).sort((a, b) => Number(a?.orderHint) - Number(b?.orderHint));
            state.categoryLevel4 = state.menus.categoryLevel4.filter(m => m.parent === state.categoryLevel3[0].key).sort((a, b) => Number(a?.orderHint) - Number(b?.orderHint));

            state.indexLevel2 = 0
            state.indexLevel3 = 0
            state.indexLevel4 = null
        },
        handleMenuLevel2(state, { payload }) {
            const { parent, index } = payload
            state.indexLevel2 = index
            state.indexLevel3 = 0
            state.categoryLevel3 = state.menus.categoryLevel3.filter(m => m.parent === parent).sort((a, b) => Number(a?.orderHint) - Number(b?.orderHint));
            if (state.categoryLevel3.length > 0)
                state.categoryLevel4 = state.menus.categoryLevel4.filter(m => m.parent === state.categoryLevel3[0].key).sort((a, b) => Number(a?.orderHint) - Number(b?.orderHint));
            else state.categoryLevel4 = []
        },
        handleMenuLevel3(state, { payload }) {
            const { parent, index } = payload
            state.indexLevel3 = index
            state.indexLevel4 = null
            state.categoryLevel4 = state.menus.categoryLevel4.filter(m => m.parent === parent).sort((a, b) => Number(a?.orderHint) - Number(b?.orderHint));
        },
        handleMenuLevel4(state, { payload }) {
            const { category, index } = payload
            state.indexLevel4 = index
            state.category = category
        },

        handleSetIndexSubMenu(state, action) {
            state.indexSubMenu = action.payload
        },
        handleSelectedStore(state, action) {
            state.selectedStore = action.payload
            localStorage.setItem("cst", toString(action.payload))
        },
        handleRefreshSelectedStore(state, action) {
            let get = localStorage.getItem("cst")
            state.selectedStore = toJson(get)
        },
        handlePagePagination(state, action) {
            state.pagination.currentPage = action.payload
        },
        handleStoreGrade(state, action) {
            state.storeGrade = action.payload
        },
        handleSetNameCategory(state, action) {
            state.categoriesNames = action?.payload
        },
        handleChangeCategory(state, action) {
            state.subMenu = action?.payload
        },
        handleRefresh(state, action) {
            state.subMenu = []
            state.categoriesNames = []
            state.categoriesStore = []
        },
        handleFilterCategories(state, action) {
            state.filterCategories = action?.payload
            state.categoryFilter = 0
        },
        handleCategoryFilter(state, action) {
            state.categoryFilter = action.payload
        },
        handleItemsStoreGrade(state, action) {
            let search = action.payload
            if (search !== '') {
                let results = state.items?.filter(item => {
                    return item?.description?.en.toLowerCase().includes(search.toString().toLowerCase())
                })
                state.itemsFilter = results
            }
            else state.itemsFilter = state.items
        },
        handleCategoriesStores(state, action) {
            state.categories = action.payload
        },
        handleGetAllStores(state, action) {
            state.stores = action.payload.stores
        },
        handleStore(state, { payload }) {
            state.store = payload
        },
        handleItemsByStore(state, action) {

            state.pagination = action.payload?.pagination
            state.items = action.payload?.items
            state.itemsFilter = action.payload?.items

            if (state.categoriesStore?.length <= 0) {
                state.categoriesStore = action.payload?.categories
            }
            // else getNameCategory()
            getNameCategory()
            state.filterCategories = state.categoriesNames


            function getNameCategory() {
                let results = [], parent = [];
                state.categoriesStore?.map(key => {
                    let occ = state.categories?.filter(c => c.key === key)?.at(0)
                    results.push({
                        name: occ?.name,
                        key: occ?.key,
                        icon: occ?.assets.at(0)?.url,
                        parent: occ?.parent,
                    })
                    //get direct parent
                    let p = state.categories?.filter(c => c.key === occ?.parent)?.at(0)
                    var found = false
                    for (let i = 0; i < parent?.length; i++) {
                        if (parent[i]?.key === p?.key) {
                            found = true
                            break;
                        }
                    }
                    if (found === false)
                        parent.push(p)
                    state.subMenu = [{ name: { en: "All" } }, ...parent]
                    return null
                })
                state.categoriesNames = results
            }
            state.status = 'success'
        },

        handleToSizesStore(state, { payload }) {
            state.toViewSizeStore = payload
        },
        setTabValue(state, { payload }) {
            state.tabValue = payload
        }
    },
})

export const {
    handleChangeCategory,
    handleMenuStore,
    handleSelectedStore,
    handleRefreshSelectedStore,
    handlePagePagination,
    handleStoreGrade,
    handleSetNameCategory,
    handleCategoriesStores,
    handleRefresh,
    handleFilterCategories,
    handleCategoryFilter,
    handleItemsStoreGrade,
    handleSetIndexSubMenu,
    handleGetAllStores,
    handleItemsByStore,
    handleStore,
    handleMenuLevel2,
    handleMenuLevel3,
    handleMenuLevel4,
    handleToSizesStore,
    setTabValue,


} = storeSlice.actions

export default storeSlice