import { fetchBaseQuery, retry } from '@reduxjs/toolkit/query/react';
import { Mutex } from 'async-mutex';
import { baseUrl, ENDPOINTS, maxRetries, METHODS } from '.';
import { isRealValue, setValidity, tokenIsAfter28Min } from '../../utilities';
import { loggedOut, setAuthorization } from '../features/authSlice';
import { token, refreshToken } from '../hooks/useAuthentication';

const mutex = new Mutex();

const baseQuery = retry(fetchBaseQuery({
    baseUrl,
    prepareHeaders: (headers) => {
        if (isRealValue(token)) headers.set("Authorization", token)
        return headers
    },
}), { maxRetries })

const refreshTokenAPi = async (args, api, extraOptions) => {
    const refreshResult = await baseQuery({
        url: `${ENDPOINTS?.REFRESH_TOKEN}`,
        method: `${METHODS?.POST}`,
        body: { refreshToken },
    }, api, {maxRetries: 0})
    if (refreshResult?.data && refreshResult?.data?.error === false) {
        setValidity(new Date().getTime())
        api.dispatch(setAuthorization(refreshResult.data?.accessToken))
        return await baseQuery(args, api, extraOptions)
    }
    // else {
    //     api.dispatch(loggedOut())
    // }
}

export const baseQueryInterpolatorAuth = async (args, api, extraOptions) => {
    await mutex.waitForUnlock()
    if (tokenIsAfter28Min() && isRealValue(refreshToken) && isRealValue(token)) {
        await refreshTokenAPi(args, api, extraOptions)
    }
    let result = await baseQuery(args, api, extraOptions)
    if (result?.error && result?.error?.status === 400) {
        if (result?.error?.data?.error === "UnauthorizedError: jwt expired") {
            if (!mutex.isLocked()) {
                const release = await mutex.acquire()
                try {
                    const refreshResult = await baseQuery({
                        url: `${ENDPOINTS?.REFRESH_TOKEN}`,
                        method: `${METHODS?.POST}`,
                        body: { refreshToken },
                    }, api, {maxRetries: 0})
                    if (refreshResult?.data && refreshResult?.data?.error === false) {
                        setValidity(new Date().getTime())
                        api.dispatch(setAuthorization(refreshResult.data?.accessToken))
                        result = await baseQuery(args, api, extraOptions)
                    } else {
                        api.dispatch(loggedOut())
                    }
                } finally {
                    release()
                }
            } else {
                await mutex.waitForUnlock()
                result = await baseQuery(args, api, extraOptions)
            }
        }
        if (result?.error?.data?.error === true && result?.error?.data?.message === "Invalid refresh token") {
            api.dispatch(loggedOut())
        }
    }
    return result
}