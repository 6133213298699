import React from 'react';
import './style/index.css'
import ChatConversation from '../../components/common/Chat/ChatConversation';
import ChatList from '../../components/common/Chat/ChatList';
import { scroolWindowToTop } from '../../utilities';

const Chats = () => {

  React.useEffect(() => {
    scroolWindowToTop()
  }, [])

  return (
    <div className="chat">
      <ChatList />
      <ChatConversation />
    </div>
  );
};

export default Chats;