import React from 'react'
import { useSelector } from 'react-redux'
import { dispatch } from '../..'
import { handlePickupLocation } from '../../app/features/bookmarkCardItemsSlice'
import {isRealValue} from '../../utilities'
import { useTranslation } from 'react-i18next'
import { useNotifications } from '../../app/hooks/useNotifications'

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

const AddressTableList = ({ addresses, noCountry=false }) => {
    const { lng } = useSelector(state => state.app);
    const { t } = useTranslation();
    const { dangerNotification } = useNotifications();

    const { order } = useSelector(state => state.bookmarkCard);
    const checkbox = React.useRef()

     const [currentPosition, setCurrentPosition] = React.useState(null);

     React.useEffect(() => {
       // Function to get the current position
       const getCurrentPosition = () => {
         if (navigator.geolocation) {
           navigator.geolocation.getCurrentPosition(
             (position) => {
               const { latitude, longitude } = position.coords;
               setCurrentPosition({ latitude, longitude });
             },
             (error) => {
               dangerNotification(
                 "Error getting current position:" + error.message
               );
             }
           );
         } else {
           console.error("Geolocation is not supported by this browser.");
         }
       };

       getCurrentPosition();
     }, []);

     const openGoogleMaps = (latitude, longitude) => {
       const url = `https://www.google.com/maps?q=${latitude},${longitude}`;
       window.open(url, "_blank");
     };

     const openGoogleMapsDirections = (latitude, longitude) => {
       if (currentPosition != null) {
         const url = `https://www.google.com/maps/dir/${currentPosition?.latitude},${currentPosition?.longitude}/${latitude},${longitude}`;
         window.open(url, "_blank");
         return;
       }

       dangerNotification("Error getting current position. Try again");
     };
   
  return (
    <div className="flex flex-col mt-8 ">
      <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
          <div className="relative overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
            <table className="min-w-full divide-y divide-gray-300 table-fixed">
              <thead className="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    className="relative w-12 px-6 sm:w-16 sm:px-8"
                  >
                    <input
                      type="checkbox"
                      className="absolute w-4 h-4 -mt-2 border-gray-300 rounded left-4 top-1/2 text-[#D90429] focus:ring-[#D90429] sm:left-6"
                      ref={checkbox}
                      checked={addresses
                        ?.map((i) => i?._id)
                        .includes(order?.order?.pickupLocation)}
                      onChange={() => dispatch(handlePickupLocation(""))}
                    />
                  </th>
                  {addresses[0]?.name && (
                    <th
                      scope="col"
                      className="min-w-[12rem] py-3.5 pr-3 text-left text-sm font-semibold text-gray-900"
                    >
                      {t("name")}
                    </th>
                  )}
                  <th
                    scope="col"
                    className="min-w-[12rem] py-3.5 pr-3 text-left text-sm font-semibold text-gray-900"
                  >
                    {t("Address")}
                  </th>
                  {/* <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                      Code
                                  </th> */}
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    Arrondissement
                  </th>
                  {/* <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                      Type
                                  </th> */}
                  {/* {!noCountry && (
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      {t("country")}
                    </th>
                  )} */}
                  <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                    <span className="sr-only">Edit</span>
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {addresses.length >= 1 ? (
                  addresses.map((address, index) => (
                    <tr
                      key={address?._id}
                      className={
                        address?._id === order?.order?.pickupLocation
                          ? "bg-gray-50"
                          : undefined
                      }
                    >
                      {/* Montant total */}
                      <td className="relative w-12 px-6 sm:w-16 sm:px-8">
                        {address?._id === order?.order?.pickupLocation && (
                          <div className="absolute inset-y-0 left-0 w-0.5 bg-[#D90429]" />
                        )}
                        <input
                          type="checkbox"
                          onClick={() =>
                            dispatch(handlePickupLocation(address?._id))
                          }
                          className="absolute w-4 h-4 -mt-2 border-gray-300 rounded left-4 top-1/2 text-[#D90429] focus:ring-[#D90429] sm:left-6"
                          value={address.Region}
                          checked={
                            address?._id === order?.order?.pickupLocation
                          }
                        />
                      </td>
                      {isRealValue(address?.name) && (
                        <td
                          className={classNames(
                            "whitespace-nowrap py-4 pr-3 text-sm font-medium",
                            address?._id === order?.order?.pickupLocation
                              ? "text-[#D90429]"
                              : "text-gray-900"
                          )}
                        >
                          {isRealValue(address?.name) && address?.name[lng]}
                        </td>
                      )}
                      <td
                        className={classNames(
                          "whitespace-nowrap py-4 pr-3 text-sm font-medium",
                          address?._id === order?.order?.pickupLocation
                            ? "text-[#D90429]"
                            : "text-gray-900"
                        )}
                      >
                        {isRealValue(address?.address[lng])
                          ? address?.address[lng]
                          : address?.address}
                      </td>
                      {/* <td className="px-3 py-4 text-sm text-gray-500 whitespace-nowrap">
                                                  {isRealValue(address?.address[lng]) ? address?.code : address?.addressCode}
                                              </td> */}
                      <td className="px-3 py-4 text-sm text-gray-500 whitespace-nowrap">
                        {typeof address?.adminDivision3 === "object"
                          ? address?.adminDivision3?.value[lng]
                          : " - "}
                      </td>
                      {/* <td className="px-3 py-4 text-sm text-gray-500 whitespace-nowrap">{address?.type}</td> */}
                      {/* <td className="px-3 py-4 text-sm text-gray-500 whitespace-nowrap">
                        {address?.country?.toUpperCase()}
                      </td> */}
                      <td className="py-4 space-x-1 pl-3 pr-4 text-sm font-medium text-right whitespace-nowrap sm:pr-6">
                        <span
                          onClick={() =>
                            openGoogleMaps(
                              address?.geometry?.coordinates[1],
                              address?.geometry?.coordinates[0]
                            )
                          }
                          className="text-[10px] text-[#D90429] cursor-pointer hover:underline"
                        >
                          {t("map_view")}
                        </span>
                        <span
                          onClick={(e) =>
                            openGoogleMapsDirections(
                              address?.geometry?.coordinates[1],
                              address?.geometry?.coordinates[0]
                            )
                          }
                          className="text-[10px] text-[#D90429] cursor-pointer hover:underline"
                        >
                          {t("get_dir")}
                        </span>
                      </td>
                    </tr>
                  ))
                ) : (
                  <th colSpan="6" className="w-full ">
                    <h4 className="py-4 pr-3 mx-auto text-sm font-medium whitespace-nowrap">
                      {" "}
                      No data found{" "}
                    </h4>
                  </th>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddressTableList