import { createSlice } from "@reduxjs/toolkit";

const initialState = {
   transitShipments: [],
   transitKey: "",
   status: [],
   routes: [],
   agents: [],
   destinations: [],
   carriers: [],
   timeFlags: [],
   openedTransits: [],
   closedTransits: [],
}

const transitShipmentSlice = createSlice({
   name: 'transitShipments',
   initialState,
   reducers: {
      handleTransitKey(state, action) {
         state.transitKey = action.payload
      },
      handleStatus(state, action) {
         state.status = action.payload
      },
      handleRoutes(state, action) {
         state.routes = action.payload
      },
      handleAgents(state, action) {
         state.agents = action.payload
      },
      handleDestinations(state, action) {
         state.destinations = action.payload
      },
      handleCarriers(state, action) {
         state.carriers = action.payload
      },
      handleTimeFlags(state, action) {
         state.timeFlags = action.payload
      },
      handleOpenedTransits(state, action) {
         state.openedTransits = action.payload
      },
      handleClosedTransits(state, action) {
         state.closedTransits = action.payload
      },
   },
})

export const {
   handleTransitKey,
   handleStatus,
   handleRoutes,
   handleAgents,
   handleDestinations,
   handleCarriers,
   handleTimeFlags,
   handleOpenedTransits,
   handleClosedTransits,

} = transitShipmentSlice.actions

export default transitShipmentSlice