import React, { useEffect } from 'react'
import StoreListItems from '../../components/StoreListItems';
import ProductListItems from '../Products/ProductListItems';
import { Pagination, Stack } from '@mui/material';
import { useSearchParams } from 'react-router-dom';
import { useSearchItemsByKeywordMutation, useSearchItemsInBrandsMutation, useSearchItemsInCategoriesMutation, useSearchItemsInStoreMutation } from '../../app/services/Api';
import { useSelector } from 'react-redux';
import useMutationApi from '../../app/hooks/useMutationApi';
import { isRealValue, scroolWindowToTop } from '../../utilities';
import { handlePageItemsSearch } from '../../app/features/AppSlice';
import { dispatch } from '../../app';
import LoadingComponent from '../../components/LoadingComponent';

const Search = () => {

  const [searchParams] = useSearchParams()
  const keywordSearch = searchParams.get("keyword") ?? ""
  const context = searchParams.get("c") ?? null
  const key = searchParams.get("key") ?? null

  const { itemsSearchFilter, paginationSearch, limitSearch, pageSearch, searchElement } = useSelector(state => state.app)

  const isSearchProducts = searchElement === "products" ? true : false
  const [searchItemsByKeyword, { isLoading: isLoadingH }] = useSearchItemsByKeywordMutation()
  const [searchItemsInBrands, { isLoading: isLoadingB }] = useSearchItemsInBrandsMutation()
  const [searchItemsInCategories, { isLoading: isLoadingC }] = useSearchItemsInCategoriesMutation()
  const [searchItemsInStore, { isLoading: isLoadingS }] = useSearchItemsInStoreMutation()

  const [storesSearches, setStoresSearches] = React.useState([]);
  const { searchStores, isLoadingSearchStoresByKeyword } = useMutationApi()
  const handleSearchStores = (keyword) => searchStores(keyword).then(r => setStoresSearches(r))
  const searchStore = (keyword) => handleSearchStores(keyword)

  const search = (_) => {
    if (isSearchProducts) {
      if (!isRealValue(context)) searchItemsByKeyword({ body: { keyword: keywordSearch }, params: { limit: limitSearch, page: pageSearch } })
      else if (context === 'categories') searchItemsInCategories({ body: { keyword: keywordSearch }, id: key, params: { limit: limitSearch, page: pageSearch } })
      else if (context === 'stores') searchItemsInStore({ body: { keyword: keywordSearch }, id: key, params: { limit: limitSearch, page: pageSearch } })
      else if (context === 'brands') searchItemsInBrands({ body: { keyword: keywordSearch }, id: key, params: { limit: limitSearch, page: pageSearch } })
    }
    else searchStore(keywordSearch)
  }
  const handlePagePaginations = (e, value) => {
    e.preventDefault();
    if (isRealValue(keywordSearch)) {
      dispatch(handlePageItemsSearch(value))
      search("")
    }
    window.scrollTo({ behavior: 'smooth', top: 0 })
  }

  useEffect(() => {
    scroolWindowToTop()
    if (isRealValue(keywordSearch))
      search("")
  }, [keywordSearch])

  return (
    <div className="w-full">
      {isSearchProducts &&
        <ProductListItems
          itemsFilter={itemsSearchFilter}
          loading={isLoadingH || isLoadingB || isLoadingC || isLoadingS || isLoadingSearchStoresByKeyword}
        />
      }
      {!isSearchProducts &&
        <StoreListItems
          itemsFilter={storesSearches}
          isLoading={isLoadingH || isLoadingB || isLoadingC || isLoadingS || isLoadingSearchStoresByKeyword}
        />
      }
      {isSearchProducts && itemsSearchFilter?.length > 0 &&
        <Stack className='w-[fit-content] mx-auto my-4 ' spacing={8}>
          <Pagination
            onChange={handlePagePaginations}
            shape="rounded"
            color='primary'
            sx={{ color: '#D90429' }}
            size="large"
            count={paginationSearch?.totalPages}
            page={paginationSearch?.currentPage}
          />
        </Stack>
      }
      <LoadingComponent
        open={isLoadingH || isLoadingB || isLoadingC || isLoadingS || isLoadingSearchStoresByKeyword}
      />
    </div>
  );
};

export default Search