import React from 'react'
import { useState, useEffect, useRef } from "react";
import Dropdown from '../DropdownItem';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import './styles.css'
import { useNavigate } from 'react-router-dom';
import { PRODUCT } from '../../../constants/routes';
import { Api } from '../../../app/services/Api';
import { dispatch } from '../../../app';
import { useSelector } from 'react-redux';
import { handleCatHover } from '../../../app/features/categoriesSlice';
import { handleKeyCat } from '../../../app/features/AppSlice';

const MenuItems = (props) => {
  const { items, depthLevel, AllCategories } = props;
  const { lng } = useSelector((state) => state.app);

  const [dropdown, setDropdown] = useState(false);
  let ref = useRef();
  const navigate = useNavigate();
  const handleClickAway = () => dispatch(handleCatHover(false));


  useEffect(() => {
    const handler = (event) => {
      if (dropdown && ref.current && !ref.current.contains(event.target)) {
        setDropdown(false);
      }
    };
    document.addEventListener("mousedown", handler);
    document.addEventListener("touchstart", handler);
    return () => {
      document.removeEventListener("mousedown", handler);
      document.removeEventListener("touchstart", handler);
    };
  });

  const onMouseEnter = () => {
    window.innerWidth > 960 && setDropdown(true);
  };

  const onMouseLeave = () => {
    window.innerWidth > 960 && setDropdown(false);
  };

  const handlerItemsByCategory = () => {
    if (depthLevel >= 2) {
      // dispatch(handleKeyCat(items?.key))

      navigate(`${PRODUCT}/${items.key}?page=1`)
      dispatch({ type: 'categories/selectedCategoryItem', payload: items?.name[lng] })
      dispatch(Api.endpoints.getItemsByCategory.initiate({ key: items?.key, }))
      handleClickAway();
    }
  }

  return (
    <li
      className="menu-items"
      ref={ref}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      {
        items?.submenu?.length > 1 ? (
          <div className='drop-item' >
            <div
              className='row-flex-space'
              onClick={() => setDropdown((prev) => !prev)}
            >
              <div className='row-flex'
                onClick={handlerItemsByCategory}
              >
                {items?.icon && (
                  <div className='row-flex circle' >
                    <img onContextMenu={(event) => { event.preventDefault(); }} src={items?.icon} alt='logos' />
                  </div>
                )}
                <span style={{ color: 'var(--title-gray)', fontSize: 14, fontWeight: '500' }} > {items?.name[lng]} </span>
              </div>
              <ChevronRightIcon sx={{ color: 'var(--unnamed-color)', marginRight: 2 }} />
            </div>
            <Dropdown
              depthLevel={depthLevel}
              submenus={items.submenu}
              dropdown={dropdown}
              AllCategories={AllCategories}
            />
          </div>
        ) : (
          <div className='drop-item' >
            <div
              className='row-flex-space'
              onClick={() => setDropdown((prev) => !prev)}
            >
              <div className='row-flex' onClick={handlerItemsByCategory}>
                {items?.icon && (
                  <div className='row-flex circle' >
                    <img onContextMenu={(event) => { event.preventDefault(); }} src={items?.icon} alt='logos' />
                  </div>
                )}
                <span style={{ color: 'var(--title-gray)', fontSize: 14, fontWeight: '500' }} > {items?.name[lng]} </span>
              </div>
            </div>
          </div>
        )}
    </li>
  );
};

export default MenuItems;