import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import en from './en.json'
import fr from './fr.json'

export const lang = {
    en: 'en',
    fr: 'fr'
}
const resources = { en, fr }

i18n.use(initReactI18next).init({
    compatibilityJSON: 'v3',
    lng: lang.fr,
    fallbackLng: ["en", "fr"],
    resources,
   
    interpolation: {
        skipOnVariables: false,
        escapeValue: false
    },
    react: {
        useSuspense: false,
    },
    // debug: process.env.REACT_APP_DEBUG_TRANSLATION_ENABLED || false,
    debug: false
});

export default i18n;
