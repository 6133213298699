import React, { useEffect } from 'react'
import "../styles.css"
import { handleHeaderType } from '../../../app/features/authSlice'
import { dispatch } from '../../..'
import { scroolWindowToTop } from '../../../utilities'

const ConditionGeneral = () => {
  useEffect(() => {
    dispatch(handleHeaderType({ headerType: "none", isStore: false, header: false, checkout: true }))
    scroolWindowToTop()
  }, [])


  return (
    <div className="page-content">
      <h1 className="heading-1">Conditions générales de vente</h1>
      <p>
        Les présentes conditions générales de vente s'appliquent à toutes les
        ventes conclues sur le site internet aflp.com, sous réserve des
        conditions particulières indiquées dans la présentation des produits.
      </p>
      <h3 className="heading-2">Article 1 : Mentions obligatoires</h3>
      <p>Le site Internet aflp.com est un service de :</p>
      <ul>
        <li
          className="all-caps list-d "
          style={{
            marginBottom: "10px",
            listStyleType: "disc",
            marginLeft: 45,
          }}
        >
          AFLP CAMEROUN SARL
        </li>
        <li
          style={{
            marginBottom: "10px",
            listStyleType: "disc",
            marginLeft: 45,
          }}
        >
          Lieu-dit dos d’âne Foréké, BP 370 Dschang Cameroun
        </li>
        <li
          style={{
            marginBottom: "10px",
            listStyleType: "disc",
            marginLeft: 45,
          }}
        >
          RC/DSC/2023/B/35
        </li>
        <li
          style={{
            marginBottom: "10px",
            listStyleType: "disc",
            marginLeft: 45,
          }}
        >
          M042318162433G
        </li>
        <li
          style={{
            marginBottom: "10px",
            listStyleType: "disc",
            marginLeft: 45,
          }}
        >
          info@aflp.com
        </li>
        <li
          style={{
            marginBottom: "10px",
            listStyleType: "disc",
            marginLeft: 45,
          }}
        >
          +237 690 86 87 91
        </li>
      </ul>

      <section>
        <h3 className="heading-2">
          Article 2 : Caractéristiques essentielles des produits et services
          vendus
        </h3>
        <p>Le site Internet aflp.com vend les produits relatifs aux :</p>
        <ul>
          <li
            style={{
              marginBottom: "10px",
              listStyleType: "disc",
              marginLeft: 45,
            }}
          >
            Vêtements, Chaussures, Bijoux et Accessoires
          </li>
          <li
            style={{
              marginBottom: "10px",
              listStyleType: "disc",
              marginLeft: 45,
            }}
          >
            Enfants, Bébé et Femme Enceinte
          </li>
          <li
            style={{
              marginBottom: "10px",
              listStyleType: "disc",
              marginLeft: 45,
            }}
          >
            Sport, Loisir et Activités d’Extérieur
          </li>
          <li
            style={{
              marginBottom: "10px",
              listStyleType: "disc",
              marginLeft: 45,
            }}
          >
            Electronique
          </li>
          <li
            style={{
              marginBottom: "10px",
              listStyleType: "disc",
              marginLeft: 45,
            }}
          >
            Electroménager
          </li>
          <li
            style={{
              marginBottom: "10px",
              listStyleType: "disc",
              marginLeft: 45,
            }}
          >
            Mobilier, Ustensile, Décoration et Accessoires de Maison
          </li>
          <li
            style={{
              marginBottom: "10px",
              listStyleType: "disc",
              marginLeft: 45,
            }}
          >
            Matériaux de Construction et Finition
          </li>
          <li
            style={{
              marginBottom: "10px",
              listStyleType: "disc",
              marginLeft: 45,
            }}
          >
            Voitures, Motos et Bicyclettes
          </li>
        </ul>
        <p>
          Le client déclare avoir pris connaissance et avoir accepté les
          conditions générales de vente antérieurement à la passation de sa
          commande. La validation de votre commande vaut donc pour acceptation
          des conditions générales de vente.
        </p>
      </section>

      <section>
        <h3 className="heading-2">Article 3 : Prix</h3>
        <p style={{ marginBottom: "10px" }}>
          Les prix de nos produits sont indiqués en Franc CFA (FCFA) toutes
          taxes comprises (TTC). <br />
        </p>
        <p style={{ marginBottom: "10px" }}>
          Les prix affichés sur notre site internet et application dépendent du
          mode d’acheminement sélectionné pour le Cameroun à partir de la Chine
          notamment par voie maritime ou voie aérienne. <br />
        </p>
        <p style={{ marginBottom: "10px" }}>
          Les frais de livraison sont compris dans le prix uniquement pour les
          commandes dont du fait de la valeur totale et la localisation du
          client, nous nous chargeons de procéder à la livraison. Les autres
          commandes, nous nous chargeons de rendre disponible les produits au
          lieu de collecte indiquée par le client <br />
        </p>
      </section>

      <section>
        <h3 className="heading-2">
          Article 4 : Délai de disponibilité des produits{" "}
        </h3>
        <p style={{ marginBottom: "10px" }}>
          Les produits disponibles apparaissent sur notre site accompagné de la
          mention « Délai estimé de livraison ». Afin de répondre au mieux aux
          attentes de notre clientèle, la disponibilité de nos produits est
          régulièrement mise à jour sur notre site internet.
        </p>
        <p style={{ marginBottom: "10px" }}>
          Si vous avez commandé un produit indisponible postérieurement à la
          validation de votre commande, vous en serez immédiatement informé.
          Nous procéderons à l'annulation de votre achat. Si la somme avait déjà
          été débitée, vous seriez immédiatement remboursé.
        </p>
      </section>

      <section>
        <h3 className="heading-2">Article 5 : Commande </h3>
        <p style={{ marginBottom: "10px" }}>
          Vous avez la possibilité de commander nos produits directement sur
          notre site internet ou par l’intermédiaire de notre application
          Android.
        </p>
        <p style={{ marginBottom: "10px" }}>
          Pour passer une commande sur notre site, choisissez vos produits et
          ajoutez-les au panier. Sélectionner les produits que vous souhaiteriez
          commander votre panier.
        </p>

        <p style={{ marginBottom: "10px" }}>Selon le cas :</p>

        <ul>
          <li
            style={{
              marginBottom: "10px",
              listStyleType: "disc",
              marginLeft: 45,
            }}
          >
            Si vous possédez déjà un compte client sur notre site, veuillez-vous
            identifier.
          </li>
          <li
            style={{
              marginBottom: "10px",
              listStyleType: "disc",
              marginLeft: 45,
            }}
          >
            Si vous ne possédez pas de compte client sur notre site, veuillez en
            créer un.
          </li>
        </ul>

        <p style={{ marginBottom: "10px" }}>Selon le cas :</p>

        <ul>
          <li
            style={{
              marginBottom: "10px",
              listStyleType: "disc",
              marginLeft: 45,
            }}
          >
            Choisissez un lieu de collecte dans la case « Lieux de collecte »
            pour valider l’endroit où vous iriez récupérer les produits de votre
            commande une fois disponible muni d’une pièce d’identité.
          </li>
          <li
            style={{
              marginBottom: "10px",
              listStyleType: "disc",
              marginLeft: 45,
            }}
          >
            Si le montant donne droit à la livraison gratuite dans une zone
            couverte, veuillez sélectionner dans la case « Adresses de livraison
            » l’une de vos adresses pour valider l’endroit où vous sera livré
            les produits de votre commande. Vous pouvez toujours y ajouter une
            adresse à condition d’activer la localisation de votre téléphone ou
            ordinateur afin que nous puissions vérifier que vous êtes dans une
            zone de livraison.
          </li>
        </ul>

        <p style={{ marginBottom: "10px" }}>Selon le cas :</p>

        <ul>
          <li
            style={{
              marginBottom: "10px",
              listStyleType: "disc",
              marginLeft: 45,
            }}
          >
            Sélectionner votre mode de paiement dans le cadre des options de
            paiements.
          </li>
          <li
            style={{
              marginBottom: "10px",
              listStyleType: "disc",
              marginLeft: 45,
            }}
          >
            Cochez la case d’acception et lecture des conditions générales de
            vente.
          </li>
        </ul>

        <p style={{ marginBottom: "10px" }}>Validez votre paiement.</p>

        <p style={{ marginBottom: "10px" }}>
          Vous recevrez un message de confirmation de votre commande sur
          l'adresse électronique ou au numéro de téléphone que vous avez
          indiquée lors de la création de votre compte client.
        </p>

        <p style={{ marginBottom: "10px" }}>
          Le transfert de propriété du produit n'aura lieu qu'au paiement
          complet de votre commande.
        </p>
      </section>

      <section>
        <h3 className="heading-2">Article 6 : Livraison et collecte</h3>

        <p style={{ marginBottom: "10px" }}>Selon le cas :</p>

        <ul>
          <li
            style={{
              marginBottom: "10px",
              listStyleType: "disc",
              marginLeft: 45,
            }}
          >
            Nous effectuons nos livraisons uniquement au Cameroun dans les zones
            géographiques que nous avons défini à condition pour le client de
            s’y trouver et d’avoir activée la localisation au moment de
            l’enregistrement de son adresse. Nous livrons exclusivement à
            l'adresse indiquée par le client.
          </li>
          <li
            style={{
              marginBottom: "10px",
              listStyleType: "disc",
              marginLeft: 45,
            }}
          >
            Nous effectuons nos expéditions uniquement au Cameroun dans nos
            agences et auprès de nos partenaires logistiques à condition pour le
            client de sélectionner le lieu de collecte des produits de sa
            commande. Les expéditions sont collectées exclusivement au lieu
            indiqué par le client.
          </li>
        </ul>

        <p style={{ marginBottom: "10px" }}>
          Les délais de livraison sont donnés à titre indicatif. Ils peuvent
          évoluer en raison de différents facteurs comme la disponibilité des
          vols et navires, les délais en douane Export et Import, la
          disponibilité de notre transporteur local.
        </p>
      </section>

      <section>
        <h3 className="heading-2">Article 7 : Modalités de paiement</h3>

        <p style={{ marginBottom: "10px" }}>
          Plusieurs modes de paiement sont acceptés. En tant que client, vous
          avez la possibilité de payer par :
        </p>

        <ul>
          <li
            style={{
              marginBottom: "10px",
              listStyleType: "disc",
              marginLeft: 45,
            }}
          >
            Cash dans l’une de nos agences ;
          </li>
          <li
            style={{
              marginBottom: "10px",
              listStyleType: "disc",
              marginLeft: 45,
            }}
          >
            Cash à l’un de nos livreurs lors de la réception de votre commande ;
          </li>
          <li
            style={{
              marginBottom: "10px",
              listStyleType: "disc",
              marginLeft: 45,
            }}
          >
            Dépôt bancaire dans l’un de nos comptes bancaires indiqués au moment
            de la commande ;
          </li>
          <li
            style={{
              marginBottom: "10px",
              listStyleType: "disc",
              marginLeft: 45,
            }}
          >
            Virement bancaire dans l’un de nos comptes bancaires indiqués au
            moment de la commande ;
          </li>
          <li
            style={{
              marginBottom: "10px",
              listStyleType: "disc",
              marginLeft: 45,
            }}
          >
            Carte de crédit sur notre site internet ou application.
          </li>
        </ul>

        <p style={{ marginBottom: "10px" }}>
          Les délais de livraison sont donnés à titre indicatif. Ils peuvent
          évoluer en raison de différents facteurs comme la disponibilité des
          vols et navires, les délais en douane Export et Import, la
          disponibilité de notre transporteur local.
        </p>

        <p style={{ marginBottom: "10px" }}>Selon le cas :</p>

        <ul>
          <li
            style={{
              marginBottom: "10px",
              listStyleType: "disc",
              marginLeft: 45,
            }}
          >
            Nous acceptons les paiements par carte [Visa / MasterCard/ Union
            Pay/ American Express]. Lors de votre paiement en ligne, indiquez le
            titulaire de la carte bancaire, le numéro de la carte bancaire, sa
            date d'expiration et les 3 chiffres inscrits au dos de celle-ci. Le
            paiement en ligne est sécurisé.
          </li>
          <li
            style={{
              marginBottom: "10px",
              listStyleType: "disc",
              marginLeft: 45,
            }}
          >
            Nous acceptons les preuves de paiement par virement ou dépôt
            bancaire dont vous ' pouvez nous envoyer une photo sur notre
            plateforme de conversation intégrée à votre compte ou au numéro +237
            690 86 87 91. Nous disposons d’un délai de 24 heures pour confirmer
            votre paiement par dépôt bancaire et 48 heures par virement
            bancaire.
          </li>
        </ul>

        <p style={{ marginBottom: "10px" }}>Éventuellement :</p>

        <ul>
          <li
            style={{
              marginBottom: "10px",
              listStyleType: "disc",
              marginLeft: 45,
            }}
          >
            Dès la confirmation de votre paiement, nous procédons dans un délai
            de 24 heures à la réservation des produits de votre commande par un
            achat direct au près du fournisseur en Chine.
          </li>
          <li
            style={{
              marginBottom: "10px",
              listStyleType: "disc",
              marginLeft: 45,
            }}
          >
            En l'absence de la confirmation de votre paiement dans un délai de
            48 heures, votre commande sera supprimée de notre plateforme. Vous
            devriez procéder à la création d’une nouvelle commande si vous
            souhaitez acquérir les mêmes produits.
          </li>
        </ul>

        <p style={{ marginBottom: "10px" }}>
          Nous pouvons exiger un paiement intégral de la commande. Dans le cas
          d’un paiement partiel, le solde devra être intégralement payé avant
          réception de votre commande soit :
        </p>

        <ul>
          <li
            style={{
              marginBottom: "10px",
              listStyleType: "disc",
              marginLeft: 45,
            }}
          >
            Avant expédition pour collecte chez l’un de nos partenaires
            logistiques ;
          </li>
          <li
            style={{
              marginBottom: "10px",
              listStyleType: "disc",
              marginLeft: 45,
            }}
          >
            Avant collecte dans l’une de nos agences ;
          </li>
          <li
            style={{
              marginBottom: "10px",
              listStyleType: "disc",
              marginLeft: 45,
            }}
          >
            Avant la livraison au près d’un de nos livreurs.
          </li>
        </ul>
      </section>

      <section>
        <h3 className="heading-2">Article 8 : Droit de rétractation</h3>

        <p style={{ marginBottom: "10px" }}>Selon le cas :</p>

        <ul>
          <li
            style={{
              marginBottom: "10px",
              listStyleType: "disc",
              marginLeft: 45,
            }}
          >
            Conformément à l'article 13 alinéa 1 du décret d’application numéro
            2011/1521 relatif à la loi numéro 2010/021 régissant le commerce
            électronique au Cameroun, « Pour tout contrat conclu par voie
            électronique, le consommateur dispose d’un délai de quinze (15)
            jours ouvrables pour se rétracter, sans indication de motif et sans
            pénalité. » Toutefois, suivant le présent contrat, le client qui
            souhaite exercer son droit de rétraction doit informer notre service
            client par courriel via son courriel d’enregistrement ou par
            téléphone via son numéro d’enregistrement avant que l’achat effectif
            des produits de sa commande dont il peut consulter le statut dans la
            rubrique de l’historique de ses commandes.
          </li>
          <li
            style={{
              marginBottom: "10px",
              listStyleType: "disc",
              marginLeft: 45,
            }}
          >
            Le droit de rétraction acquis pour tout produit dont l’achat
            effectif n’a pas encore été effectué auprès des fournisseurs en
            Chine.
          </li>
        </ul>

        <p style={{ marginBottom: "10px" }}>
          Les délais de livraison sont donnés à titre indicatif. Ils peuvent
          évoluer en raison de différents facteurs comme la disponibilité des
          vols et navires, les délais en douane Export et Import, la
          disponibilité de notre transporteur local.
        </p>
      </section>

      <section>
        <h3 className="heading-2">
          Article 9 : Garantie et droit de retour du produit
        </h3>

        <p style={{ marginBottom: "10px" }}>
          La garantie est applicable à nos produits ayant un caractère
          défectueux (absence de fonctionnalité, produit impropre à l'usage
          auquel vous pouvez vous attendre, absence des caractéristiques
          présentées en ligne, dysfonctionnement partiel ou total du produit,
          panne du produit) et ce, même en l'absence de garantie contractuelle.
        </p>

        <p style={{ marginBottom: "10px" }}>
          L’absence de défaut de conformité est présumée au jour de la livraison
          du produit et passez ce moment, la preuve de la démonstration de
          l'existence du défaut de conformité incombe au client.
        </p>

        <p style={{ marginBottom: "10px" }}>
          Dans le cas d'un défaut de conformité, nous nous engageons à :
        </p>

        <ul>
          <li
            style={{
              marginBottom: "10px",
              listStyleType: "disc",
              marginLeft: 45,
            }}
          >
            Remplacer le produit ;.
          </li>
          <li
            style={{
              marginBottom: "10px",
              listStyleType: "disc",
              marginLeft: 45,
            }}
          >
            Réparer le produit dans un délai de 60 jours et sans frais.
          </li>
        </ul>

        <p style={{ marginBottom: "10px" }}>
          En cas de défaillance dans l'exécution de notre obligation ou en cas
          de défectuosité majeure, nous nous engageons à vous rembourser la
          totalité du prix versé ou une partie du prix si vous souhaitez
          conserver le produit.
        </p>

        <p style={{ marginBottom: "10px" }}>
          La présomption du défaut de conformité ne s'applique plus s'il
          apparaît après 1 jour suivant la vente pour les produits couverts par
          une garantie et après 1 mois pour les produits couverts par une
          garantie. Vous devez rapporter la preuve de la défectuosité de notre
          produit.
        </p>

        <p style={{ marginBottom: "10px" }}>
          Le retour pour cause d’inadéquation entre un produit et la mensuration
          du client n’est pas permis.
        </p>
      </section>

      <section>
        <h3 className="heading-2">
          Article 10 : Conditions et délais de remboursement
        </h3>

        <p style={{ marginBottom: "10px" }}>
          Le remboursement des produits est intégral. Il s'effectue par virement
          bancaire dans un compte à votre nom ou au comptant dans l’une nos
          agences dans les plus brefs délais et au plus tard dans les 7 jours à
          compter de la date d'exigibilité.
        </p>
      </section>

      <section>
        <h3 className="heading-2">Article 11 : Réclamations du consommateur</h3>

        <p style={{ marginBottom: "10px" }}>
          Toute réclamation du consommateur est à adresser par courrier postal à
          l'adresse mentionnée ci-contre BP 370 Dschang ou par voie électronique
          à <a href="mailto:info@aflp.com">info@aflp.com</a>
          ou via notre plateforme de conversation accessible sur le compte du
          consommateur.
        </p>
      </section>

      <section>
        <h3 className="heading-2">Article 12 : Propriété intellectuelle</h3>

        <p style={{ marginBottom: "10px" }}>
          Tous les commentaires, images, illustrations de notre site nous sont
          exclusivement réservées. Au titre de la propriété intellectuelle et du
          droit d'auteur, toute utilisation est prohibée sauf à usage privé.
        </p>

        <p style={{ marginBottom: "10px" }}>
          Sans autorisation préalable, toute reproduction de notre site, qu'elle
          soit partielle ou totale, est strictement interdite.
        </p>
      </section>

      <section>
        <h3 className="heading-2">Article 13 : Responsabilité </h3>

        <p style={{ marginBottom: "10px" }}>
          Nous engageons notre responsabilité contractuelle de plein droit à
          votre égard en cas d'inexécution ou de mauvaise exécution du contrat
          conclu.
        </p>

        <p style={{ marginBottom: "10px" }}>
          Toutefois, notre responsabilité contractuelle ne peut être engagée
          dans les situations mentionnées ci-dessous :
        </p>

        <ul>
          <li
            style={{
              marginBottom: "10px",
              listStyleType: "disc",
              marginLeft: 45,
            }}
          >
            Cas de la force majeure ;
          </li>
          <li
            style={{
              marginBottom: "10px",
              listStyleType: "disc",
              marginLeft: 45,
            }}
          >
            Fait étranger qui ne peut nous être imputable ;
          </li>
          <li
            style={{
              marginBottom: "10px",
              listStyleType: "disc",
              marginLeft: 45,
            }}
          >
            Les photographies / illustrations / images de notre site n'ont
            aucune valeur contractuelle. Elles ne sauraient donc engager notre
            responsabilité.
          </li>
        </ul>
      </section>

      <section>
        <h3 className="heading-2">
          Article 14 : Données à caractère personnel{" "}
        </h3>

        <p style={{ marginBottom: "10px" }}>
          Certaines informations relatives au client seront transmises à nos
          livreurs (à savoir le nom, prénom, code de localisation géographique,
          numéro de pièce d’identification et numéro de téléphone) et
          partenaires logistiques (à savoir le nom, prénom, numéro de pièce
          d’identification et numéro de téléphone) et ce, afin de permettre le
          traitement et la livraison des produits commandés.
        </p>

        <p style={{ marginBottom: "10px" }}>
          Le site assure au client une collecte et un traitement d'informations
          personnelles dans le respect de la vie privée conformément aux normes
          relatives à l'informatique, aux fichiers et aux libertés.
        </p>

        <p style={{ marginBottom: "10px" }}>
          Il est important que les données personnelles que nous détenons à
          votre sujet soient exactes et à jour. Veuillez nous tenir informés si
          vos données personnelles changent pendant votre relation avec nous.
          Dans certaines circonstances, vous avez des droits en vertu des lois
          sur la protection des données en ce qui concerne vos données
          personnelles, le droit de vous opposer ou de restreindre le traitement
          de vos données personnelles. Y compris le droit d'accéder, de corriger
          ou de supprimer vos données personnelles via:
        </p>

        <ul>
          <li
            style={{
              marginBottom: "10px",
              listStyleType: "disc",
              marginLeft: 45,
            }}
          >
            Son espace personnel
          </li>
          <li
            style={{
              marginBottom: "10px",
              listStyleType: "disc",
              marginLeft: 45,
            }}
          >
            La plateforme de conversation accessible sur le compte du client
          </li>
          <li
            style={{
              marginBottom: "10px",
              listStyleType: "disc",
              marginLeft: 45,
            }}
          >
            Par mail à info@aflp.com
          </li>
          <li
            style={{
              marginBottom: "10px",
              listStyleType: "disc",
              marginLeft: 45,
            }}
          >
            Par voie postale au BP 370 Dschang.
          </li>
        </ul>
      </section>

      <section>
        <h3 className="heading-2">
          Article 15 : Juridiction compétente et droit applicable{" "}
        </h3>

        <p style={{ marginBottom: "10px" }}>
          En cas de litige entre le client consommateur et notre société, le
          droit applicable est le droit Camerounais. Tout éventuel litige est
          porté auprès des tribunaux relevant du siège social de notre société.
        </p>
      </section>
    </div>
  );
}

export default ConditionGeneral