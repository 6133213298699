import React, { useState } from 'react'
import ChatListHead from './ChatListHead'
import ChatListItem from './ChatListItem'
import ContactList from '../ContactList';
import { useTranslation } from 'react-i18next';
import ModalFlat from '../../../ModalFlat';

const ChatList = () => {

    let [isOpen, setIsOpen] = useState(false);
    let [selected, setSelected] = useState(0);
    const { t } = useTranslation();
    function closeModal() {
      setIsOpen(false);
    }
    function openModal() {
      setIsOpen(true);
    }

    const userArr = [1,2,3,4,5,6,7,8,9,10]

    const ListChats = ()=> {
        return  userArr.map((item, index)=> <ChatListItem selected={selected} setSelected={setSelected} /> )
    }

  return (
    <section className=" antialiased bg-gray-50 text-gray-600 min-h-screen p-4">
      <div className="h-full ">
        {/* <!-- Card --> */}
        <div className="relative max-w-[340px] mx-auto bg-white shadow-lg rounded-lg">
          {/* <!-- Card header --> */}
          <ChatListHead />
          {/* <!-- Card body --> */}
          <div className="py-3 px-5">
            <h3 className="text-xs font-semibold uppercase text-gray-400 mb-1">
              Chats
            </h3>
            {/* <!-- Chat list --> */}
            <div className="divide-y divide-gray-200">
              {/* <!-- User --> */}
              <ListChats />
            </div>
          </div>
          {/* <!-- Bottom right button --> */}
          <button
            onClick={openModal}
            className="absolute bottom-0 right-0 inline-flex items-center text-sm font-medium text-white bg-[#D90429] hover:bg-[#D90429] rounded-full text-center px-3 py-2 shadow-lg focus:outline-none focus-visible:ring-2"
          >
            <svg
              className="w-3 h-3 fill-current text-white flex-shrink-0 mr-2"
              viewBox="0 0 12 12"
            >
              <path d="M11.866.146a.5.5 0 0 0-.437-.139c-.26.044-6.393 1.1-8.2 2.913a4.145 4.145 0 0 0-.617 5.062L.305 10.293a1 1 0 1 0 1.414 1.414L7.426 6l-2 3.923c.242.048.487.074.733.077a4.122 4.122 0 0 0 2.933-1.215c1.81-1.809 2.87-7.94 2.913-8.2a.5.5 0 0 0-.139-.439Z" />
            </svg>
            <span className="text-white text-sm font-semibold ">New Chat</span>
          </button>
        </div>
      </div>
      <ModalFlat
        isOpen={isOpen}
        children={<ContactList />}
        title={t("add_card")}
        closeModal={closeModal}
      />
    </section>
  );
}

export default ChatList