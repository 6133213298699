import { createSlice } from "@reduxjs/toolkit";
import { isRealValue } from "../../utilities";

const initialState = {
    cartItemsSelected: [],
    numberCartBookmark: 0,
    totalAmountSelected: 0,
    selectedStore: [],
    selectedAll: false,
    data: [],
}

const bookmarkCardItemsSlice = createSlice({
    name: 'bookmarkCard',
    initialState,
    reducers: {
        handleItemsCartBookMarker(state, action) {
            state.data = action.payload?.filter(t => t?.items.length > 0) ?? []
            state.numberCartBookmark = 0
            state.numberCartBookmark = state.data?.reduce((acc, item) => acc += item?.items?.length, 0)
            if (state.cartItemsSelected?.length > 0) {
                var exist = state.data.map((item) => item.items).flat()
                var newSelected = exist.filter(item => {
                    const v = state.cartItemsSelected.find(el => el?.key === item?.key && el?.variantKey === item.variantKey)
                    return isRealValue(v)
                })
                state.cartItemsSelected = newSelected
                state.totalAmountSelected = state.cartItemsSelected.reduce((acc, item) => acc += (item?.unitPrice * item?.quantity), 0)
            }
        },
        handleResetCartItemsSelected(state) {
            state = {
                cartItemsSelected: [],
                totalAmountSelected: 0,
                selectedStore: 0,
                numberCartBookmark: 0
            }
        },
        handleStoreSelect(state, { payload }) {
            const { store, items } = payload;
            const ifStoreExist = state.selectedStore?.find((item) => item === store)
            if (isRealValue(ifStoreExist)) {
                state.cartItemsSelected = state.cartItemsSelected.filter((item) => item?.store !== store)
                state.totalAmountSelected = state.cartItemsSelected.reduce((acc, item) => acc += (item?.unitPrice * item?.quantity), 0)
                state.selectedStore = state.selectedStore.filter(item => item !== store)
            } else {
                var exist = state.cartItemsSelected.filter((item) => item.store === store)
                var newSelected = (items.filter(item => exist?.every(el => !(el?.key === item?.key && el?.variantKey === item.variantKey))))?.map(
                    v => { return { ...v, store } }
                )
                state.cartItemsSelected = state.cartItemsSelected.concat(newSelected)
                state.totalAmountSelected = state.cartItemsSelected.reduce((acc, item) => acc += (item?.unitPrice * item?.quantity), 0)
                state.selectedStore = state.selectedStore.concat([store])
            }
        },
        handleSelectAllCart(state) {
            state.selectedAll = !state.selectedAll;
            var store = [];
            if (state.selectedAll) {
                state.cartItemsSelected = state.data.map((item) => {
                    if (!store.includes(item?.store)) {
                        store = store.concat([item?.store])
                    }
                    return item.items.map(i => { return { ...i, store: item?.store } })
                }).flat();
                state.selectedStore = store;
                state.totalAmountSelected = state.cartItemsSelected.reduce((acc, item) => acc += (item?.unitPrice * item?.quantity), 0)
            } else {
                state.selectedStore = [];
                state.cartItemsSelected = []
                state.totalAmountSelected = 0;
            }
        },
        handleBuildOrder(state) {
            state.order = {
                order: {
                    totalAmount: state.totalAmountSelected,
                    pickupLocation: "",
                    items: state.cartItemsSelected?.map((item) => {
                        return {
                            itemKey: item?.key,
                            variantKey: item?.variantKey,
                            store: item?.store,
                            route: item?.route,
                            unitPrice: item.unitPrice,
                            quantity: item.quantity,
                            totalPrice: item.unitPrice * item.quantity
                        }
                    })
                }
            }
        },
        handleSetCartOrder(state, { payload }) {
            state.order = payload
        },
        handlePickupLocation(state, action) {
            state.order.order.pickupLocation = action.payload
        },
        handleSelectedItem(state, { payload }) {
            const item = payload
            const selectedIndex = state.cartItemsSelected.findIndex(s => s.key === item?.key && s.variantKey === item.variantKey)
            if (selectedIndex === -1) {
                state.cartItemsSelected = state.cartItemsSelected.concat([item])
            }
            else if (selectedIndex > -1) {
                state.cartItemsSelected = state.cartItemsSelected.filter(s => !(s.key === item?.key && s.variantKey === item.variantKey))
            }
            state.totalAmountSelected = state.cartItemsSelected.reduce((acc, item) => acc += (item?.unitPrice * item?.quantity), 0)

            const l = state.data.find(s => s.store === item?.store).items?.length
            const inSelect = state.cartItemsSelected.filter(s => s.store === item?.store).length
            if (l === inSelect && selectedIndex === -1) {
                state.selectedStore = state.selectedStore.concat([item?.store])
            } else {
                state.selectedStore = state.selectedStore.filter((v) => v !== item?.store)
            }

        },
        handlePage(state, action) {
            state.page = parseInt(action.payload, 10)
        },
        handleRowsPerPage(state, action) {
            state.rowsPerPage = parseInt(action.payload, 10)
            state.page = 0
        },
        handleRequest(state, action) {
            const property = action.payload
            const isAsc = state.orderBy === property && state.order === 'asc'
            state.order = isAsc ? 'asc' : 'desc'
            state.orderBy = property
        },
        handleSelectedAll(state, action) {
            if (action.payload) {
                const newSelected = state.cartItemsFilter?.map((item) => item?.variantKey)
                state.selected = newSelected
                state.selectedInt = newSelected?.map(k => parseInt(k, 10))
            }
            else state.selected = []
        },
        handleSelected(state, action) {
            const variantKey = action.payload
            const selectedIndex = state.selected.indexOf(variantKey)
            let newSelected = []
            if (selectedIndex === -1) {
                newSelected = newSelected.concat(state.selected, variantKey)
            } else if (selectedIndex === 0) {
                newSelected = newSelected.concat(state.selected.slice(1))
            } else if (selectedIndex === state.selected?.length - 1) {
                newSelected = newSelected.concat(state.selected.slice(0, -1))
            } else if (selectedIndex > 0) {
                newSelected = newSelected.concat(
                    state.selected.slice(0, selectedIndex),
                    state.selected.slice(selectedIndex + 1),
                )
            }
            state.selectedInt = newSelected?.map(k => parseInt(k, 10))
            state.selected = newSelected
        },
        handleSetUpdateKey(state, { payload }) {
            state.updateItemKey = payload;
        },
        handleCheckoutToCartScreen(state, { payload }) {
            const { item, cartToAdd } = payload // item details and cartToAdd to cart
            const basePrice = cartToAdd?.route === "sea" ? item?.basePrice["cm"]?.seaPrice : item?.basePrice["cm"]?.airPrice
            const reducedPrice = isRealValue(item?.reducedPrice) ? (cartToAdd?.route === "sea" ? item?.reducedPrice["cm"]?.seaPrice : item?.reducedPrice["cm"]?.airPrice) : null
            const unitPrice = isRealValue(reducedPrice) ? reducedPrice : basePrice
            const totalPrice = unitPrice * cartToAdd?.quantity
            state.order = {
                order: {
                    totalAmount: totalPrice,
                    pickupLocation: "",
                    items: [{
                        itemKey: cartToAdd?.itemKey,
                        variantKey: cartToAdd?.variantKey,
                        store: cartToAdd?.store,
                        route: cartToAdd?.route,
                        quantity: cartToAdd?.quantity,
                        unitPrice,
                        totalPrice
                    }
                    ]
                }
            }
        },


      
        
        handleItemToUpdate(state, { payload }) {
            const { item, store } = payload;
            state.itemToUpdate = item;
            state.updateData = {
                itemKey: item?.key,
                variantKey: item?.variantKey,
                store: store,
                route: item?.route,
                quantity: item?.quantity,
            }
        },
        handleUpdateRoute(state, { payload }) {
            const bool = payload === 0 ? true : false
            state.updateData = { ...state.updateData, route: bool ? "sea" : "air" }
        },
        handleCounterUpdateType(state, { payload }) {
            state.updateData = { ...state.updateData, quantity: Number(payload) }
        },
        handleCounterUpdate(state, action) {
            const { updateData } = state;
            let op = action.payload
            if (op === 'add') {
                updateData.quantity += 1
            } else {
                let count = updateData.quantity <= 1 ? 1 : updateData.quantity - 1
                updateData.quantity = count
            }
        },
        handleVariantUpdate(state, { payload }) {
            console.info('payload :>> ', payload);
            state.updateData = { ...state.updateData, variantKey: payload }
        },
        handleUpdateImg(state, { payload }) {
            state.updateItemImg = payload;
        },

    },
})

export const {
    handleIncrementQuantity,
    handleDecrementQuantity,
    handleSelectRouting,
    handleItemsCartBookMarker,
    handleBuildOrder,
    handlePickupLocation,
    handleSelectedItem,
    handleGetItemsCartBookMarker,
    handleRequest,
    handleSelectedAll,
    handleSelected,
    handleRowsPerPage,
    handlePage,
    handleResetCartItemsSelected,
    handleCheckoutToCartScreen,
    handleSetCartOrder,
    handleStoreSelect,
    handleItemToUpdate,
    handleSetUpdateKey,
    handleUpdateRoute,
    handleCounterUpdate,
    handleCounterUpdateType,
    handleVariantUpdate,
    handleUpdateImg,
    handleSelectAllCart

} = bookmarkCardItemsSlice.actions

export default bookmarkCardItemsSlice