import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App"
import { Provider } from 'react-redux'
import store from "./app/store";
import { Translator } from "react-translator-component";
import './index.css'
import "./config"

export const dispatch = store.dispatch

ReactDOM
    .createRoot(document.getElementById('root'))
    .render(
        <React.StrictMode>
            <Provider store={store}>
                <Translator>
                    <App />
                </Translator>
            </Provider>
        </React.StrictMode>
    )
