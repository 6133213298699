import { createSlice } from "@reduxjs/toolkit";
import { isRealValue } from "../../utilities";

const initialState = {
    users: [],
    usersFilter: [],
    gradesUsers: [],
    grade: "",
    customerView: null,
    transactions: [],
    transactionsFilter: [],
    transactionStates: [],
    payments: [],
    paymentsFilter: [],
    paymentStatus: [],
    holds: [],
    statements: { details: [] },
    toStatement: "", //date
    fromStatement: "", //date
}

const usersSlice = createSlice({
    name: "users",
    initialState,
    reducers: {
        handleUsers(state, { payload }) {
            state.users = payload
            state.usersFilter = payload
        },
        handleGradesUser(state, { payload }) {
            state.gradesUsers = payload
        },
        handleUsersByGrade(state, { payload }) {
            state.grade = payload === "All" ? "" : payload
        },
        handleCustomerView(state, { payload }) {
            state.customerView = payload
        },
        handlePayments(state, { payload }) {
            state.payments = payload
            state.paymentsFilter = payload
        },
        handleHolds(state, { payload }) {
            state.holds = payload
        },
        handlePaymentsByStatus(state, { payload }) {
            if (payload === "All") state.paymentsFilter = state.payments
            else state.paymentsFilter = state.payments?.filter(order => order.status === payload)
        },
        handlePaymentStatus(state, { payload }) {
            state.paymentStatus = payload
        },
        handleTransactionStates(state, { payload }) {
            state.transactionStates = payload
        },
        handleUsersByUsername(state, { payload }) {
            state.usersFilter = state.users.filter(user => user.userName.toString().includes(payload)
                || user.firstName.toString().toLowerCase().includes(payload.toString().toLowerCase())
                || user.lastName.toString().toLowerCase().includes(payload.toString().toLowerCase())
            )
        },
        handleTransactions(state, { payload }) {
            state.transactions = payload
            state.transactionsFilter = payload
        },
        filterByState(state, { payload }) {
            state.transactions = state.transactionsFilter.filter(t => t.state === payload)
        },
        filterByMethod(state, { payload }) {
            state.transactions = state.transactionsFilter.filter(t => t.method === payload)
        },
        filterByOperation(state, { payload }) {
            state.transactions = state.transactionsFilter.filter(t => t.operation === payload)
        },
        handleStatements(state, { payload }) {
            state.statements = payload
        },
        handleFromToStatementDate(state, { payload }) {
            const { to, from } = payload
            if (isRealValue(from)) state.fromStatement = from
            if (isRealValue(to)) state.toStatement = to
        }
    },
})

export const {
    handleUsers,
    handleGradesUser,
    handleUsersByGrade,
    handleCustomerView,
    handlePayments,
    handleHolds,
    handlePaymentsByStatus,
    handlePaymentStatus,
    handleTransactionStates,
    handleUsersByUsername,
    handleTransactions,
    filterByMethod,
    filterByOperation,
    filterByState,
    handleStatements,
    handleFromToStatementDate,


} = usersSlice.actions

export default usersSlice