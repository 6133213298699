import React from 'react'
import PhoneInput from 'react-phone-number-input';
import SelectMenu from '../common/SelectMenus';
import ButtonFlat from '../ButtonFlat';

const Countries = [
    {id: 1, name: "Cameroon"}
]

const CreateLocationForm = () => {
  return (
    <form className='' >
          <div className="pt-8">
              <div>
                  <h3 className="text-lg font-medium leading-6 text-gray-900">Personal Information</h3>
                  <p className="mt-1 text-sm text-gray-500">Use a permanent address where you can receive mail.</p>
              </div>
              <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                  <div className="sm:col-span-4">
                      <label htmlFor="code" className="block text-sm font-medium text-gray-700">
                          Code
                      </label>
                      <div className="mt-1">
                          <input
                              id="code"
                              name="code"
                              type="text"
                              autoComplete="code"
                              className="block w-full rounded-md border-gray-300 shadow-sm focus:border-[#D90429] focus:ring-[#D90429] sm:text-sm"
                          />
                      </div>
                  </div>
                  
                  <div className="sm:col-span-4">
                      <label htmlFor="Service" className="block text-sm font-medium text-gray-700">
                          Service
                      </label>
                      <div className="mt-1">
                          <select
                              id="Service"
                              name="Service"
                              autoComplete="Service-name"
                              className="block w-full rounded-md border-gray-300 shadow-sm focus:border-[#D90429] focus:ring-[#D90429] sm:text-sm"
                          >
                              <option>No Service</option>

                          </select>
                      </div>
                  </div>

                  <h3 className="sm:col-span-6 block text-xl font-medium text-gray-700" >Countries</h3>
                  <div className="sm:col-span-4">
                      <SelectMenu data={Countries} />
                  </div>

                  <div className="sm:col-span-4">
                      <label htmlFor="type" className="block text-sm font-medium text-gray-700">
                          Type
                      </label>
                      <div className="mt-1">
                          <select
                              id="type"
                              name="type"
                              autoComplete="type-name"
                              className="block w-full rounded-md border-gray-300 shadow-sm focus:border-[#D90429] focus:ring-[#D90429] sm:text-sm"
                          >
                              <option>No Type</option>
                              
                          </select>
                      </div>
                  </div>

                  <h3 className="sm:col-span-6 block text-xl font-medium text-gray-700" >Telephone</h3>
                  <PhoneInput
                      className="sm:col-span-4 flex px-3 flex-row items-center border rounded-md border-gray-300 shadow-sm sm:text-sm" 
                      inputComponent={(value, onChange) => <input
                          id="tel"
                          name="tel"
                        //   value={value}
                        //   onChange={onChange}
                          type="tel"
                          placeholder="Enter phone number"
                          autoComplete="tel"
                          className="outline-none border-none border-0 rounded-md w-full bg-transparent "
                      />}
                      
                      international
                      defaultCountry='CM'
                      countryCallingCodeEditable={false}
                      initialValueFormat="national"
                      countrySelectProps={{ unicodeFlags: true, border: "none" }}
                      style={{
                          height: "50px",
                          width: "100%",
                      }}
                  />

                  <h3 className="sm:col-span-6 block text-xl font-medium text-gray-700" >Name</h3>
                  <div className="sm:col-span-3">
                      <label htmlFor="name-fr" className="block text-sm font-medium text-gray-700">
                          French Name
                      </label>
                      <div className="mt-1">
                          <input
                              type="text"
                              name="name-fr"
                              id="name-fr"
                              className="block w-full rounded-md border-gray-300 shadow-sm focus:border-[#D90429] focus:ring-[#D90429] sm:text-sm"
                          />
                      </div>
                  </div>

                  <div className="sm:col-span-3">
                      <label htmlFor="name-en" className="block text-sm font-medium text-gray-700">
                          English Name
                      </label>
                      <div className="mt-1">
                          <input
                              type="text"
                              name="name-en"
                              id="name-en"
                              autoComplete="name-en"
                              className="block w-full rounded-md border-gray-300 shadow-sm focus:border-[#D90429] focus:ring-[#D90429] sm:text-sm"
                          />
                      </div>
                  </div>

                  <h3 className="sm:col-span-6 block text-xl font-medium text-gray-700" >Address</h3>
                  <div className="sm:col-span-6">
                      <label htmlFor="address-fr" className="block text-sm font-medium text-gray-700">
                          French Name
                      </label>
                      <div className="mt-1">
                          <input
                              type="text"
                              name="address-fr"
                              id="address-fr"
                              className="block w-full rounded-md border-gray-300 shadow-sm focus:border-[#D90429] focus:ring-[#D90429] sm:text-sm"
                          />
                      </div>
                  </div>

                  <div className="sm:col-span-6">
                      <label htmlFor="address-en" className="block text-sm font-medium text-gray-700">
                          English Name
                      </label>
                      <div className="mt-1">
                          <input
                              type="text"
                              name="address-en"
                              id="address-en"
                              autoComplete="address-en"
                              className="block w-full rounded-md border-gray-300 shadow-sm focus:border-[#D90429] focus:ring-[#D90429] sm:text-sm"
                          />
                      </div>
                  </div>

                  <h3 className="sm:col-span-6 block text-xl font-medium text-gray-700" >Geometry</h3>
                  <div className="sm:col-span-6">
                      <label htmlFor="geometry" className="block text-sm font-medium text-gray-700">
                          Geometry
                      </label>
                      <div className="mt-1">
                          <select
                              id="geometry"
                              name="geometry"
                              autoComplete="Geometry-name"
                              defaultValue={"Geometry Type"}
                              className="block w-full rounded-md border-gray-300 shadow-sm focus:border-[#D90429] focus:ring-[#D90429] sm:text-sm"
                          >
                              <option>Point</option>
                          </select>
                      </div>
                  </div>

                  <h3 className="sm:col-span-6 block text-xl font-medium text-gray-700" >Coordinates</h3>
                  <div className="sm:col-span-3">
                      <label htmlFor="latitude" className="block text-sm font-medium text-gray-700">
                          Latitude
                      </label>
                      <div className="mt-1">
                          <input
                              type="text"
                              name="latitude"
                              id="latitude"
                              className="block w-full rounded-md border-gray-300 shadow-sm focus:border-[#D90429] focus:ring-[#D90429] sm:text-sm"
                          />
                      </div>
                  </div>

                  <div className="sm:col-span-3">
                      <label htmlFor="longitude" className="block text-sm font-medium text-gray-700">
                          Longitude
                      </label>
                      <div className="mt-1">
                          <input
                              type="text"
                              name="longitude"
                              id="longitude"
                              autoComplete="longitude"
                              className="block w-full rounded-md border-gray-300 shadow-sm focus:border-[#D90429] focus:ring-[#D90429] sm:text-sm"
                          />
                      </div>
                  </div>

                  <h3 className="sm:col-span-6 block text-xl font-medium text-gray-700" >Opening Hours</h3>
                  <div className="sm:col-span-3">
                      <label htmlFor="time-open-fr" className="block text-sm font-medium text-gray-700">
                          French Name
                      </label>
                      <div className="mt-1">
                          <input
                              type="time"
                              name="time-open-fr"
                              id="time-open-fr"
                              className="block w-full rounded-md border-gray-300 shadow-sm focus:border-[#D90429] focus:ring-[#D90429] sm:text-sm"
                          />
                      </div>
                  </div>

                  <div className="sm:col-span-3">
                      <label htmlFor="time-open-en" className="block text-sm font-medium text-gray-700">
                          English Name
                      </label>
                      <div className="mt-1">
                          <input
                              type="time"
                              name="time-open-en"
                              id="time-open-en"
                              autoComplete="time-open-en"
                              className="block w-full rounded-md border-gray-300 shadow-sm focus:border-[#D90429] focus:ring-[#D90429] sm:text-sm"
                          />
                      </div>
                  </div>

                  <div className="sm:col-span-6 flex flex-row justify-center">
                          <ButtonFlat title={"Save"} />
                  </div>
              </div>
          </div>
    </form>
  )
}

export default CreateLocationForm