
import React from 'react'
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { useSelector } from 'react-redux';
import { IconButton } from '@mui/material';
import { handleCounter, handleCounterType } from '../../app/features/itemSlice';
import { dispatch } from '../../app';
import './styles.css';

export const ProductQuantity = () => {

  const { counter } = useSelector(state => state?.item)
  const setCounter = (op) => dispatch(handleCounter(op))
  const setCounterType = (op) => dispatch(handleCounterType(op))

  const ProductQuantityDesktop = () => {
    return (
      <div
        className="row-flex-space"
        style={{ marginTop: 15, marginBottom: 15 }}
      >
        <div
          className="space-x-2 row-flex-space"
          style={{ paddingLeft: 15, paddingRight: 15, appearance: "unset" }}
        >
          <IconButton
            onClick={() => setCounter("rm")}
            aria-label="delete"
            color="primary"
          >
            <RemoveIcon sx={{ fontSize: 24 }} />
          </IconButton>
          <input
            value={counter > 0 ? counter : 1}
            type="number"
            min={1}
            className="focus:outline-[#D90429] focus:border-0 focus:border-transparent focus:ring-0 focus:outline-none rounded-md text-center w-32 md:w-48 cursor-text"
            onChange={(e) => setCounterType(Math.abs(e.target.value))}
          />
          <IconButton
            onClick={() => setCounter("add")}
            aria-label="delete"
            color="primary"
          >
            <AddIcon sx={{ fontSize: 24 }} />
          </IconButton>
        </div>
      </div>
    );
  }

  return (
    <>
      <ProductQuantityDesktop />
    </>
  )
}