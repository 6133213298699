import React, { useEffect } from 'react'
import "../styles.css"
import { handleHeaderType } from '../../../app/features/authSlice'
import { dispatch } from '../../..'
import { scroolWindowToTop } from '../../../utilities'

const PolitiqueConfidentialite = () => {
  useEffect(() => {
    dispatch(handleHeaderType({ headerType: "none", isStore: false, header: false, checkout: true }))
    scroolWindowToTop()
  }, [])


  return (
    <div className="page-content">
      <h1 className="heading-1">Politique de confidentialité</h1>

      <section>

        <p style={{ marginBottom: "10px" }}>
          Certaines informations relatives au client seront transmises à nos
          livreurs (à savoir le nom, prénom, code de localisation géographique,
          numéro de pièce d’identification et numéro de téléphone) et
          partenaires logistiques (à savoir le nom, prénom, numéro de pièce
          d’identification et numéro de téléphone) et ce, afin de permettre le
          traitement et la livraison des produits commandés.
        </p>

        <p style={{ marginBottom: "10px" }}>
          Le site assure au client une collecte et un traitement d'informations
          personnelles dans le respect de la vie privée conformément aux normes
          relatives à l'informatique, aux fichiers et aux libertés.
        </p>

        <p style={{ marginBottom: "10px" }}>
          Il est important que les données personnelles que nous détenons à
          votre sujet soient exactes et à jour. Veuillez nous tenir informés si
          vos données personnelles changent pendant votre relation avec nous.
          Dans certaines circonstances, vous avez des droits en vertu des lois
          sur la protection des données en ce qui concerne vos données
          personnelles, le droit de vous opposer ou de restreindre le traitement
          de vos données personnelles. Y compris le droit d'accéder, de corriger
          ou de supprimer vos données personnelles via:
        </p>

        <ul>
          <li
            style={{
              marginBottom: "10px",
              listStyleType: "disc",
              marginLeft: 45,
            }}
          >
            Son espace personnel
          </li>
          <li
            style={{
              marginBottom: "10px",
              listStyleType: "disc",
              marginLeft: 45,
            }}
          >
            La plateforme de conversation accessible sur le compte du client
          </li>
          <li
            style={{
              marginBottom: "10px",
              listStyleType: "disc",
              marginLeft: 45,
            }}
          >
            Par mail à info@aflp.com
          </li>
          <li
            style={{
              marginBottom: "10px",
              listStyleType: "disc",
              marginLeft: 45,
            }}
          >
            Par voie postale au BP 370 Dschang.
          </li>
        </ul>
      </section>


    </div>
  );
}

export default PolitiqueConfidentialite