import React from 'react'
import InputFlat from './InputFlat'
import { Controller, useForm } from 'react-hook-form';
import { checkCreditCard, creditCardType, formatCardNumber } from '../app/hooks/useCustomStripe';
import useMutationApi from '../app/hooks/useMutationApi';

import { isRealValue } from '../utilities';
import LoadingButton from './common/LoadingButton';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useViewClientStripeQuery } from '../app/services/Api';

const AddCartForm = () => {
  useViewClientStripeQuery()

  const { t } = useTranslation();
  const { clientStripe } = useSelector(state => state.stripe)

  const { control, handleSubmit, setError, watch, clearErrors, formState: { errors } } = useForm({ defaultValues: { name: "", cvc: "", number: "", exp_month: "", exp_year: "" } });
  const [cc, setCC] = React.useState(creditCardType())
  const { isAddCreditCardByUser, addCreditCardByUser, addUserToClientStripe, isLoadingAddCreditCard } = useMutationApi()
  const rmSpace = v => isRealValue(v) ? v.replaceAll(/\s/g, '') : ""

  const handleAddCard = (data) => {
    const year = new Date().getFullYear()
    const ftwo = year.toString().substring(0, 2)
    const year1 = parseInt(ftwo.concat(data.exp_year))

    if (isRealValue(clientStripe)) {
      addCreditCardByUser({
        "name": data.name,
        "number": parseInt(rmSpace(data.number)),
        "month": data.exp_month,
        "year": year1,
        "cvc": data.cvc,
      })
    } else {
      addUserToClientStripe().then((r) => {
        addCreditCardByUser({
          "name": data.name,
          "number": parseInt(rmSpace(data.number)),
          "month": data.exp_month,
          "year": year1,
          "cvc": data.cvc,
        })
      })
    }
  }

  const validateNumberCard = (value) => {
    const resp = checkCreditCard(rmSpace(value))
    if (resp?.success === true) {
      clearErrors("number")
    } else {
      setError("number", { message: t(resp?.message), type: "required" })
    }
    return resp?.success
  }

  const handleCardNumber = (v, onChange) => {
    onChange(formatCardNumber(v))
    let value = rmSpace(v)
    setCC(creditCardType(value))
    validateNumberCard(v)
  }


  return (
    <form>
      <Controller
        control={control}
        rules={{ required: true }}
        render={({ field: { onChange, onBlur, value } }) => (
          <InputFlat
            label={t("card_holder_name")}
            onChange={onChange}
            value={value}
            placeholder={t("card_holder_name")}
            error={errors?.name ? t("check_field") : null}
          />
        )}
        name="name"
      />
      <Controller
        control={control}
        rules={{ required: true, validate: v => validateNumberCard(rmSpace(v)) }}
        render={({ field: { onChange, onBlur, value } }) => (
          <InputFlat
            label={t("no_cart")}
            onChange={(e) => handleCardNumber(e.target.value, onChange)}
            value={value}
            error={errors.number ? errors?.number?.message : null}
            placeholder={"XXXX XXXX XXXX XXXX"}
            leftIcon={
              <img
                onContextMenu={(event) => { event.preventDefault(); }}
                className="mx-auto rounded-lg "
                style={{
                  width: 25,
                  height: 25,
                }}
                src={cc?.image}
                alt="card type"
              />
            }
          />
        )}
        name="number"
      />

      <div className="grid grid-cols-2 ">
        <Controller
          control={control}
          rules={{
            required: true,
            maxLength: 2,
            validate: v => {
              if (!isRealValue(v) || (v > 12 || v < 0))
                return false;
              let year = watch("exp_year")
              let date = new Date()
              if (isRealValue(year)) {
                if (date.getFullYear() === year) {
                  if (v < date.getMonth() + 1) {
                    setError('exp_year', { type: 'required' })
                    return false
                  }
                  else {
                    clearErrors("exp_year")
                    return true
                  }
                }
              }
              return true;
            }
          }}
          render={({ field: { onChange, onBlur, value } }) => (
            <InputFlat
              label={t("exp_month")}
              onChange={(e) => onChange(parseInt(e.target.value, 10))}
              value={value}
              placeholder={(new Date().getMonth() + 4).toString()}
              type={"number"}
              error={errors.exp_month ? t("check_field") : null}
              min={1}
              max={12}
              step={1}
              maxLength={2}
            />
          )}
          name="exp_month"
        />
        <Controller
          control={control}
          rules={{
            required: true,
            maxLength: 2,
            validate: v => {
              let date = new Date()
              const year = date.getFullYear()
              const ftwo = year.toString().substring(0, 2)
              const year1 = parseInt(ftwo.concat(v))

              if (v && v.toString().length === 2) {
                if (year1 < year) return false
                let month = watch("exp_month")
                if (isRealValue(month)) {
                  if (date.getMonth() + 1 > month && year1 == year) setError('exp_month', { type: 'required' }, { shouldFocus: true })
                  else clearErrors('exp_month')
                }
                return true
              }
            }
          }}
          render={({ field: { onChange, onBlur, value } }) => (
            <InputFlat
              label={t("exp_year")}
              placeholder={(new Date().getFullYear() + 1).toString()}
              onChange={(e) => onChange(parseInt(e.target.value, 10))}
              type={"number"}
              error={errors?.exp_year ? t("check_field") : null}
              value={value}
              defaultValue={"25"}
              maxLength={2}
            />
          )}
          name="exp_year"
        />
      </div>
      <Controller
        control={control}
        rules={{ required: true, maxLength: 3 }}
        render={({ field: { onChange, onBlur, value } }) => (
          <InputFlat
            label={"CVC/CVV"}
            onChange={onChange}
            type={"number"}
            value={value}
            error={errors?.cvc ? t("check_field") : null}
            placeholder={t("cvv_code")}
            maxLength={3}
          />
        )}
        name="cvc"
      />

      <LoadingButton
        isLoading={isAddCreditCardByUser || isLoadingAddCreditCard}
        title={"Confirm"}
        disabled={isAddCreditCardByUser || isLoadingAddCreditCard}
        onClick={handleSubmit(handleAddCard)}
      />
    </form>
  );
}

export default AddCartForm