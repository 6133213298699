import React, { useRef } from 'react'
import { useSelector } from 'react-redux'
import { handleImgPreview, handleVariants } from '../../app/features/itemSlice'
import { dispatch } from '../..'
import { useTranslation } from 'react-i18next'

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const VariantsUpdate = ({ item = {} }) => {
  const spanRef = useRef();

  const { t } = useTranslation()

  const { selectedVariants } = useSelector((state) => state.item);
  const { lng } = useSelector((state) => state.app);
  const variantsConfigs = item?.variantsConfig ?? {};

  const descFunc = (p_id) => {
    for (const [pid, vid] of Object.entries(selectedVariants)) {
      if (p_id === pid)
        return variantsConfigs[pid]?.values[vid]?.propertyValue[lng];
    }
  };

  const VariantsTypes = ({ vid, pid, value, index }) => {

    const { variantKey, pictureUrl, miniPictureUrl, propertyValue } = value;

    const handleImgChange = (uri, type, index) => {
      window.scrollTo({ behavior: "smooth", top: 0 });
      dispatch(handleVariants({ variantKey, vid, pid }));
      dispatch(handleImgPreview({ uri, type, index }));
    }
    const selectVariant = (e) => {
      dispatch(handleVariants({ variantKey, vid, pid }));
    }

    return pictureUrl && miniPictureUrl ? (
      <div className="relative ">
        <div
          onClick={() => handleImgChange(pictureUrl, "variant", index)}
          className="relative flex h-14 w-14 cursor-pointer items-center justify-center rounded-md bg-[#D90429] text-sm font-medium uppercase text-gray-900 hover:bg-gray-50 focus:outline-none focus:ring focus:ring-opacity-50 focus:ring-offset-4 mr-1"
        >
          <>
            <span className="sr-only"> {propertyValue[lng]} </span>
            <span className="absolute inset-0 overflow-hidden rounded-md">
              <img
                onContextMenu={(event) => {
                  event.preventDefault();
                  window.open(event?.target?.currentSrc, "_blank");
                }}
                loading="lazy"
                src={pictureUrl}
                alt=""
                className="object-cover object-center mx-auto"
              />
            </span>

            <span
              className={classNames(
                selectedVariants[pid] === vid 
                    ? "ring-[#D90429]"
                    : "ring-transparent",
                "pointer-events-none absolute inset-0 rounded-md ring-2 ring-offset-2"
              )}
              aria-hidden="true"
            />
          </>
        </div>
      </div>
    ) : (
      <div
        onClick={(e) => {
          e.preventDefault();
          selectVariant(e);
        }}
        className={classNames(
          selectedVariants[pid] === vid
            ? "ring-1 ring-offset-1 ring-[#D90429]"
            : "",
          selectedVariants[pid] === vid
            ? "bg-[#D90429] border-transparent text-white hover:bg-[#D9042990]"
            : "bg-white border-gray-200 text-gray-900 hover:bg-gray-50",
          "cursor-pointer focus:outline-none border rounded-md py-2 px-2 flex items-center justify-center text-sm font-medium uppercase sm:flex-1 "
        )}
      >
        <span
          ref={spanRef}
          onClick={(e) => {
            e.preventDefault();
          }}
          className="break-words truncate line-clamp-1 "
        >
          {propertyValue[lng] ?? "invalid"}
        </span>
      </div>
    );
  };

  return (
    <div className="w-full ">
      {Object.keys(variantsConfigs)?.map((pid, i) => (
        <React.Fragment>
          <div className="mt-6" key={i + "pid"}>
            <h2 className="text-sm font-medium text-gray-900">
              {variantsConfigs[pid]?.propertyName[lng]}
            </h2>

            <div className="flex flex-wrap items-center w-full gap-1 mx-auto my-4 space-y-2 ">
              {Object.keys(variantsConfigs[pid]?.values).map((vid, index) => {
                const colorName = variantsConfigs[pid];
                return (
                  <div>
                    <VariantsTypes
                      key={index + " vid"}
                      value={variantsConfigs[pid]?.values[vid]}
                      vid={vid}
                      pid={pid}
                      index={index}
                    />
                  </div>
                );
              })}
            </div>
          </div>
          { }
          {Object.keys(selectedVariants).length > 0 &&
            variantsConfigs[pid]?.propertyName[lng].toLowerCase().includes(
              t("color")
            ) && (
              <p className="text-sm font-medium text-gray-700">
                {descFunc(pid)}
              </p>
            )}
        </React.Fragment>
      ))}
    </div>
  );
};

export default VariantsUpdate;
