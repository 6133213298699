import React from 'react'
import { useTranslation } from 'react-i18next';
import HoldsTable from './HoldsTable';
import { useGetHoldByCustomerQuery } from '../../../app/services/Api';
import LoadingComponent from '../../../components/LoadingComponent';
import { scroolWindowToTop } from '../../../utilities';

const HoldsPage = () => {
  const { t } = useTranslation()
  const { isFetching: isLoading } = useGetHoldByCustomerQuery()

  React.useEffect(() => {
    scroolWindowToTop()
  })

  return (
    <div className="px-4 sm:px-6 lg:px-8" >
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-2xl font-semibold text-gray-900">{t("holds")}</h1>
          <p className="mt-2 text-sm text-gray-700">
            {t("a_list_of_all_the_holds_in_your_account")}
          </p>
        </div>
      </div>
      <HoldsTable />
      <LoadingComponent open={isLoading} />
    </div>
  )
}

export default HoldsPage
