import React, { useState } from 'react'
import LocationList from '../../components/Location/LocationList'
import CreateLocationForm from '../../components/Location/CreateLocationForm'
import ModalFlat from '../../components/ModalFlat'
import { scroolWindowToTop } from '../../utilities'

const Locations = () => {
  let [isOpen, setIsOpen] = useState(false)

  function closeModal() {
    setIsOpen(false)
  }

  function openModal() {
    setIsOpen(true)
  }

  React.useEffect(() => {
    scroolWindowToTop()
  }, [0])

  return (
    <div className="w-full h-full px-4 sm:px-6 lg:px-8 " >
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-xl font-semibold text-gray-900">Locations</h1>
          <p className="mt-2 text-sm text-gray-700">
            A list of all the locations in your account including.
          </p>
        </div>
        <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
          <button
            type="button"
            onClick={openModal}
            className="inline-flex items-center justify-center rounded-md border border-transparent bg-[#D90429] px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-[#D9042990] focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto"
          >
            Add location
          </button>
        </div>
      </div>

      <LocationList />
      <ModalFlat
        isOpen={isOpen}
        children={<CreateLocationForm />}
        title={"Create New Location"}
        closeModal={closeModal} />
    </div>
  )
}

export default Locations
