import { createSlice } from "@reduxjs/toolkit";
import { diffLocalDate } from "../../utilities";

const initialState = {
    page: 0,
    order: 'asc',
    orderBy: 'id',
    orders: [],
    orderFiltered: [],
    rowsPerPage: 5,
    selected: [],
    headCells: [
        { id: 'id', numeric: true, disablePadding: false, label: 'View' },
        { id: 'date', numeric: false, disablePadding: false, label: 'Date' },
        { id: 'countries', numeric: false, disablePadding: false, label: 'Countries' },
        { id: 'number_items', numeric: true, disablePadding: false, label: 'Number Items' },
        { id: 'shipments', numeric: false, disablePadding: false, label: 'Shipments' },
        { id: 'status', numeric: false, disablePadding: false, label: 'Status' },
        { id: 'requiredDeposit', numeric: true, disablePadding: false, label: 'Required Deposit' },
        { id: 'totalAmount', numeric: true, disablePadding: false, label: 'Total Amount' },
        { id: 'actions', numeric: false, disablePadding: false, label:""}
    ],
    requireDeposit: null,
    openAlert: false,
    command: {},
    value: '',
    isLoadingRequiredDeposit: false,
    selectedIndex: 0,


    searchByKey: "",
    searchByStoreName: "",
}

const ordersSlice = createSlice({
    name: 'orders',
    initialState,
    reducers: {
        handleOrderData(state, action){
            state.orders = action.payload
            state.orderFiltered = action.payload
        },
        handleSearchOrderNumber(state,action) {
            const keyword = action.payload
            state.searchByKey = keyword
            state.searchByStoreName=""
            state.orderFiltered = state.orders?.filter((order) => {return order?.key?.toLowerCase().includes(keyword.toString().toLowerCase())})
        },
        handleSearchStoreName(state, action){
            const keyword = action.payload
            state.searchByStoreName = keyword
            state.searchByKey = ""
            state.orderFiltered = state.orders?.filter((order) => {
                return order?.storeName?.en?.toLowerCase().includes(keyword.toString().toLowerCase()) ||
                order?.storeName?.fr?.toLowerCase().includes(keyword.toString().toLowerCase())
            })
        },
        handleRouting(state, action){
            const routing = action.payload
            state.orderFiltered = state.orders?.filter((order) => {
                const items = order?.items?.filter(item => item?.routing === routing)
                return items.length > 0 && order
            })
        },
        handleItemStatus(state, action){
            const status = action.payload
            var orders = state.orders
            state.orderFiltered = orders?.filter((order) => {
                const items = order?.items?.filter(item => item?.status === status)
                return items.length > 0 && order
            })
        },
        handleByDate(state, action){
            state.orderFiltered = state.orders?.filter(order => action.payload === diffLocalDate(order?.created))
        },
        handleSelectedIndex(state, action){
            state.selectedIndex = action.payload
        },
        handleorderFiltered(state, action) {
            state.orderFiltered = action.payload
            state.orders = action.payload
        },
        handleRequireDeposit(state, action) {
            state.requireDeposit = action.payload
            state.openAlert = true
            state.isLoadingRequiredDeposit = false
        },
        handleOpenAlert(state, action) {
            state.openAlert = action.payload
        },
        handleCommand(state, action) {
            state.command = action.payload
        },
        handleSelected(state, action) {
            const variantKey = action.payload
            const selectedIndex = state.selected?.indexOf(variantKey)
            let newSelected = []
            if (selectedIndex === -1) {
                newSelected = newSelected?.concat(state.selected, variantKey)
            } else if (selectedIndex === 0) {
                newSelected = newSelected?.concat(state.selected.slice(1))
            } else if (selectedIndex === state.selected.length - 1) {
                newSelected = newSelected.concat(state.selected.slice(0, -1))
            } else if (selectedIndex > 0) {
                newSelected = newSelected.concat(
                    state.selected.slice(0, selectedIndex),
                    state.selected.slice(selectedIndex + 1),
                )
            }
            state.selected = newSelected
        },
        handleValue(state, action){
            const status = action.payload
            state.value = status
            if (status === 'All Status') state.orderFiltered = state.orders
            else {
                const filtered = state.orders?.filter(order => order.status === status)
                state.orderFiltered = filtered
            }
        },
        handleIsLoadingRequiredDeposit(state, action){
            state.isLoadingRequiredDeposit = action.payload
        },
        handleOrderViews(state, action) {
            state.orderViews = action.payload
            state.orderViewsFilter = action.payload
            state.totalAmountViews = (state.orderViews?.map(order => order?.totalAmount)).reduce((a, c) => a + c, 0)
            state.totalAmountPaidViews = (state.orderViews?.map(order => order?.amountPaid)).reduce((a, c) => a + c, 0)
            state.totalBalanceViews = (state.orderViews?.map(order => order?.balance)).reduce((a, c) => a + c, 0)
        },
        handleOrdersByStatus(state, action) {
            if (action.payload === "All") state.orderFiltered = state.orders
            else state.orderFiltered = state.orders?.filter(order => order.status === action.payload)
        },
        handleOrderViewsByStatus(state, action) {
            if (action.payload === "All") state.orderViewsFilter = state.orderViews
            else state.orderViewsFilter = state.orderViews?.filter(order => order.status === action.payload)
        },
        handleOrderViewsByItemsStatus(state, action) {
            // if(action.payload ==="All") state.orderFiltered = state.orders
            // else state.orderFiltered = state.orders?.filter(order => order.status === action.payload)
        },

    },
})

export const { 
    handleOrderData, 
    handleSearchOrderNumber, 
    handleSearchStoreName, 
    handleRouting, 
    handleItemStatus, 
    handleorderFiltered, 
    handleRequireDeposit, 
    handleIsLoadingRequiredDeposit, 
    handleSelectedIndex,
    handleOpenAlert, 
    handleCommand, 
    handleSelected, 
    handleValue, 
    handleByDate,
    handleOrderViews,
    handleOrdersByStatus,
    handleOrderViewsByItemsStatus,
    handleOrderViewsByStatus,
    
} = ordersSlice.actions

export default ordersSlice