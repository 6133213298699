
import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import PersonAdd from '@mui/icons-material/PersonAdd';
import Logout from '@mui/icons-material/Logout';
import { getUser, isRealValue } from '../../../utilities';
import { LinearProgress, Typography } from '@mui/material';
import { StyledBadge } from './style';
import { useNavigate } from 'react-router-dom';
import { DASHBOARD, DASHBOARD_SETTINGS, HOME, SIGN_UP } from '../../../constants/routes';
import { useSignOutMutation } from '../../../app/services/Api';
import profileImage from '../../../assets/images/profileImage.jpeg'
import { loggedOut } from '../../../app/features/authSlice';
import { useDispatch, useSelector } from 'react-redux';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import { MEDIA_URL } from '../../../app/services';
import { useTranslation } from 'react-i18next';

const AccountSettingsMenu = () => {
    const { InfoAccount, user } = useSelector((state) => state.auth);
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [signOut, { isLoading }] = useSignOutMutation()


    const { t } = useTranslation();

    const goTo = (to, options) => {
        handleClose()
        navigate(to)
    }

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    function stringAvatar(name) {
        let blocks = name.split(' '), children = `${blocks[0][0]}`;
        if (blocks?.length > 1) children += `${blocks[1][0]}`
        children = children.toUpperCase()
        return { children }
    }

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const signout = () => {
        signOut().unwrap().then((resp) => {
            if (resp?.error === false) {
                dispatch( loggedOut())
                goTo(`${HOME}`)
            }
        }).catch((err) => {})
    }
    return (
      <>
        <Tooltip title="Account settings">
          <IconButton
            onClick={handleClick}
            size="small"
            sx={{ ml: 2 }}
            aria-controls={open ? "account-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
          >
            <StyledBadge
              overlap="circular"
              anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
              variant="dot"
            >
              {isRealValue(InfoAccount?.profilePicture) ? (
                <Avatar
                  sx={{ width: 32, height: 32 }}
                  src={`${MEDIA_URL}${InfoAccount?.profilePicture}`}
                />
              ) : (
                <Avatar
                  sx={{ width: 32, height: 32 }}
                  {...stringAvatar(`${getUser()?.userName}`)}
                />
              )}
            </StyledBadge>
          </IconButton>
        </Tooltip>
        <Menu
          anchorEl={anchorEl}
          id="account-menu"
          open={open}
          onClose={handleClose}
          PaperProps={{
            elevation: 0,
            sx: {
              overflow: "visible",
              filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
              mt: 1.5,
              "& .MuiAvatar-root": {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
              },
              "&:before": {
                content: '""',
                display: "block",
                position: "absolute",
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                bgcolor: "background.paper",
                transform: "translateY(-50%) rotate(45deg)",
                zIndex: 0,
              },
            },
          }}
          transformOrigin={{ horizontal: "right", vertical: "top" }}
          anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        >
          <div
            style={{
              alignItems: "center",
              justifyContent: "center",
              textAlign: "center",
              padding: "10px",
            }}
          >
            {/* <div >
                        {
                            isRealValue(InfoAccount?.profilePicture) ? <img onContextMenu = { (event)=> { event.preventDefault();    }  } style={{ borderRadius: 50, width: "70px", height: "70px" }} src={`${MEDIA_URL}${InfoAccount?.profilePicture}`} alt={"profile"} /> :
                            <img onContextMenu = { (event)=> { event.preventDefault();    }  } style={{ borderRadius: 50, width: "70px", height: "70px" }} src={profileImage} alt={"profile"} />
                        }
                    </div> */}
            <Typography
              className="link-text"
              sx={{ fontSize: 14, fontWeight: "bold" }}
            >
              {getUser()?.userName}
            </Typography>
            <Typography
              className="link-text"
              sx={{ fontSize: 17, fontWeight: "400" }}
            >
              {getUser()?.email}
            </Typography>
          </div>
          {user?.role != "user" && (
            <MenuItem
              onClick={() => goTo(`${DASHBOARD_SETTINGS}`)}
              sx={{ fontSize: 14, fontFamily: "Futura" }}
            >
              {isRealValue(InfoAccount?.profilePicture) ? (
                <Avatar
                  sx={{ width: 25, height: 25 }}
                  src={`${MEDIA_URL}${InfoAccount?.profilePicture}`}
                />
              ) : (
                <Avatar {...stringAvatar(`${getUser()?.userName}`)} />
              )}
              {t("profile")}
            </MenuItem>
          )}
          {user?.role != "user" && (
            <MenuItem
              onClick={() => goTo(`${DASHBOARD}`)}
              sx={{ fontSize: 14, fontFamily: "Futura" }}
            >
              <ListItemIcon>
                <HomeOutlinedIcon fontSize="small" />
              </ListItemIcon>
              {t("dash")}
            </MenuItem>
          )}
          <Divider />
          {/* <MenuItem
            onClick={() => goTo(`${SIGN_UP}`)}
            sx={{ fontSize: 14, fontFamily: "Futura" }}
          >
            <ListItemIcon>
              <PersonAdd fontSize="small" />
            </ListItemIcon>
            Add another account
          </MenuItem>
          <Divider /> */}
          <MenuItem
            onClick={signout}
            sx={{ fontSize: 14, fontFamily: "Futura" }}
            disabled={isLoading}
          >
            <ListItemIcon>
              <Logout fontSize="small" />
            </ListItemIcon>
            {t("lgo")}
          </MenuItem>
          {isLoading && <LinearProgress color="secondary" />}
        </Menu>
      </>
    );
}
export default AccountSettingsMenu