import React from 'react'
import { useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { useCodeValuesQuery, useGetOrderByIdQuery, useGetOrderviewByKeyQuery, useViewCardsQuery } from '../../app/services/Api'
import { useFormatter } from '../../app/hooks/useFormatter'
import { PRODUCT_DETAILS } from '../../constants/routes'
import { getOrderItemStatus, getOrderOrderStatus, getPaymentStatus, putOperationMethod, putOperationTransaction } from '../../app/hooks'
import LoadingComponent from '../../components/LoadingComponent'
import LoadingButton from '../../components/common/LoadingButton'
import ModalFlat from '../../components/ModalFlat'
import CompletePaymentForm from '../../components/OrderDetails/CompletePaymentForm'
import { useTranslation } from 'react-i18next'
import { scroolWindowToTop } from '../../utilities'


function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const OrderDetails = () => {

  let [isOpen, setIsOpen] = React.useState(false);
  function closeModal() {
    setIsOpen(false);
  }
  function openModal() {
    setIsOpen(true);
  }

  const location = useLocation()
  const { t } = useTranslation()
  const { id } = location?.state

  const { orderViewsFilter, totalAmountViews, totalAmountPaidViews, totalBalanceViews, } = useSelector(state => state.orders);
  const { lng } = useSelector(state => state.app)
  const { user } = useSelector(state => state.auth)
  const isStaff = !(user?.role === "customer" || user?.role === "user")
  const { formatPrice, display, formatDate, getOrderItemStatusData } = useFormatter()

  const { isFetching: isLoading } = useGetOrderviewByKeyQuery(id, { skip: isStaff });
  const { isFetching: isLoadingO } = useGetOrderByIdQuery(id, { skip: !(user?.role !== "customer" && user?.role !== "user") })
  const { isLoading: isLoadingP } = useViewCardsQuery(null, { skip: isStaff, refetchOnFocus: true })

  const { isLoading: isLoadingT } = useCodeValuesQuery(getOrderItemStatus, { refetchOnReconnect: true })
  const { isLoading: isLoadingPS } = useCodeValuesQuery(getPaymentStatus, { refetchOnFocus: true })
  const { isLoading: isLoadingS } = useCodeValuesQuery(getOrderOrderStatus, { refetchOnReconnect: true })
  const { isLoading: isLoadingM } = useCodeValuesQuery(putOperationMethod, { refetchOnFocus: true })
  const { isLoading: isLoadingTO } = useCodeValuesQuery(putOperationTransaction, { refetchOnFocus: true })


  React.useEffect(() => {
    scroolWindowToTop()
  }, [0])

  return (
    <div className="bg-gray-50 ">
      {orderViewsFilter?.map((order, index) => (
        <main
          className="max-w-2xl pt-8 pb-24 mx-auto sm:px-6 sm:pt-16 lg:max-w-7xl lg:px-8"
          key={index}
        >
          <div className="px-4 space-y-2 sm:flex sm:items-baseline sm:justify-between sm:space-y-0 sm:px-0">
            <div className="flex sm:items-baseline sm:space-x-4">
              <h1 className="text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl">
                {t("order")} {order?.key || order?.ref}
              </h1>
              {/* <a
                href="#"
                className="hidden text-sm font-medium text-[#D90429] hover:text-[#D90429] sm:block"
              >
                View invoice
                <span aria-hidden="true"> &rarr;</span>
              </a> */}
            </div>
            <p className="text-sm text-gray-600">
              {t("order_placed")}{" "}
              <time
                dateTime={formatDate(order?.created)}
                className="font-medium text-gray-900"
              >
                {formatDate(order?.created)}
              </time>
            </p>
            <a
              href="#"
              className="text-sm font-medium text-[#D90429] hover:text-[#D90429] sm:hidden"
              aria-disabled={order?.status !== "delivered"}
            >
              View invoice
              <span aria-hidden="true"> &rarr;</span>
            </a>
          </div>

          <section aria-labelledby="products-heading" className="mt-6">
            <h2 id="products-heading" className="sr-only">
              Products purchased
            </h2>

            <div className="space-y-8">
              {order?.items?.map((product, index) => {
                const indexStatus = getOrderItemStatusData?.findIndex(
                  (s) => s.value === product?.status
                );
                return (
                  <div
                    key={index}
                    className="bg-white border-t border-b border-gray-200 shadow-sm sm:rounded-lg sm:border"
                  >
                    <div className="px-4 py-6 sm:px-6 lg:grid lg:grid-cols-12 lg:gap-x-8 lg:p-8">
                      <div className="sm:flex lg:col-span-7">
                        <div className="flex-shrink-0 w-full overflow-hidden rounded-lg aspect-w-1 aspect-h-1 sm:aspect-none sm:h-40 sm:w-40">
                          <img
                            onContextMenu={(event) => {
                              event.preventDefault();
                              window.open(event?.target?.currentSrc, "_blank");
                            }}
                            src={product?.picture}
                            alt={"product image"}
                            className="object-cover object-center w-full h-full sm:h-full sm:w-full"
                          />
                        </div>

                        <div className="mt-6 sm:mt-0 sm:ml-6">
                          <h3 className="text-base font-medium text-gray-900">
                            <a href={`${PRODUCT_DETAILS}/${product?.key}`}>
                              {product?.description[lng]}
                            </a>
                          </h3>
                          <p className="mt-2 text-sm font-medium text-gray-900">
                            {product?.unitPriceDisplayed} x {product?.quantity}{" "}
                            = {product?.totalPriceDisplayed}
                          </p>
                          <p className="mt-3 text-sm text-gray-500">
                            {product?.description[lng]}
                          </p>
                        </div>
                      </div>

                      <div className="mt-6 lg:col-span-5 lg:mt-0">
                        <dl className="grid grid-cols-2 text-sm gap-x-6">
                          <div>
                            <dt className="font-medium text-gray-900">
                              {t("variants")}
                            </dt>
                            <dd className="mt-3 text-gray-500">
                              <span className="block">
                                {product.variants[0]?.propertyName[lng]}{" "}
                                {product.variants[0]?.propertyValue[lng]}
                              </span>
                              <span className="block">
                                {product.variants[1]?.propertyName[lng]}{" "}
                                {product.variants[1]?.propertyValue[lng]}
                              </span>
                            </dd>
                          </div>
                          <div>
                            <dt className="font-medium text-gray-900">
                              {t("route")}
                            </dt>
                            <dd className="mt-3 space-y-3 text-gray-500">
                              <p>{product?.route}</p>
                              {user?.role != "user" || user?.role != "customer" && <button
                                type="button"
                                className="font-medium text-[#D90429] hover:text-[#D90429]"
                              >
                                Edit
                              </button>}
                            </dd>
                          </div>
                        </dl>
                      </div>
                    </div>

                    <div className="px-4 py-6 border-t border-gray-200 sm:px-6 lg:p-8">
                      <h4 className="sr-only">Status</h4>
                      <p className="text-sm font-medium text-gray-900">
                        {product?.status}
                      </p>
                      <div className="mt-6" aria-hidden="true">
                        <div className="overflow-hidden bg-gray-200 rounded-full">
                          <div
                            className="h-2 rounded-full bg-[#D90429]"
                            style={{
                              width: `calc((${indexStatus} * 2 + 1) / 12 * 100%)`,
                            }}
                          />
                        </div>

                        <div className="hidden grid-cols-7 mt-8 text-sm font-medium text-gray-600 sm:grid">
                          {/* <div className="text-[#D90429]">Order placed</div> */}
                          {getOrderItemStatusData?.map((st, index) => (
                            <div
                              className={classNames(
                                st.value ===
                                  getOrderItemStatusData[indexStatus]?.value
                                  ? "text-[#D90429]"
                                  : "",
                                "text-center"
                              )}
                            >
                              {display(st)}
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </section>

          <section aria-labelledby="summary-heading" className="mt-16">
            <h2 id="summary-heading" className="sr-only">
              Billing Summary
            </h2>

            <div className="px-4 py-6 bg-gray-100 sm:rounded-lg sm:px-6 lg:grid lg:grid-cols-12 lg:gap-x-8 lg:px-8 lg:py-8">
              <dl className="grid grid-cols-2 gap-6 text-sm sm:grid-cols-2 md:gap-x-8 lg:col-span-7">
                <div>
                  <dt className="font-medium text-gray-900">
                    {t("billing_add")}
                  </dt>
                  <dd className="mt-3 text-gray-500">
                    <span className="block">{order?.delivery}</span>
                  </dd>
                </div>
                <div>
                  <dt className="font-medium text-gray-900">{t("pay_info")}</dt>
                  <dd className="flex flex-wrap -mt-1 -ml-4">
                    {/* <div className="flex-shrink-0 mt-4 ml-4">
                                                <svg
                                                    aria-hidden="true"
                                                    width={36}
                                                    height={24}
                                                    viewBox="0 0 36 24"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    className="w-auto h-6"
                                                >
                                                    <rect width={36} height={24} rx={4} fill="#224DBA" />
                                                    <path
                                                        d="M10.925 15.673H8.874l-1.538-6c-.073-.276-.228-.52-.456-.635A6.575 6.575 0 005 8.403v-.231h3.304c.456 0 .798.347.855.75l.798 4.328 2.05-5.078h1.994l-3.076 7.5zm4.216 0h-1.937L14.8 8.172h1.937l-1.595 7.5zm4.101-5.422c.057-.404.399-.635.798-.635a3.54 3.54 0 011.88.346l.342-1.615A4.808 4.808 0 0020.496 8c-1.88 0-3.248 1.039-3.248 2.481 0 1.097.969 1.673 1.653 2.02.74.346 1.025.577.968.923 0 .519-.57.75-1.139.75a4.795 4.795 0 01-1.994-.462l-.342 1.616a5.48 5.48 0 002.108.404c2.108.057 3.418-.981 3.418-2.539 0-1.962-2.678-2.077-2.678-2.942zm9.457 5.422L27.16 8.172h-1.652a.858.858 0 00-.798.577l-2.848 6.924h1.994l.398-1.096h2.45l.228 1.096h1.766zm-2.905-5.482l.57 2.827h-1.596l1.026-2.827z"
                                                        fill="#fff"
                                                    />
                                                </svg>
                                                <p className="sr-only">Visa</p>
                                            </div> */}
                    <div className="mt-4 ml-4">
                      <p className="text-gray-900">
                        {formatPrice(totalAmountViews)}{" "}
                        {order?.key || order?.ref}
                      </p>
                      <p className="text-gray-600">
                        {t("created")} {formatDate(order?.created)}
                      </p>
                    </div>
                  </dd>
                </div>
              </dl>

              <dl className="mt-8 text-sm divide-y divide-gray-200 lg:col-span-5 lg:mt-0">
                <div className="flex items-center justify-between pb-4">
                  <dt className="text-gray-600">{t("balance")}</dt>
                  <dd className="font-medium text-gray-900">
                    {formatPrice(totalBalanceViews)}
                  </dd>
                </div>
                <div className="flex items-center justify-between py-4">
                  <dt className="text-gray-600">{t("amt_pay")}</dt>
                  <dd className="font-medium text-gray-900">
                    {formatPrice(totalAmountPaidViews)}
                  </dd>
                </div>
                <div className="flex items-center justify-between pt-4">
                  <dt className="font-medium text-gray-900">
                    {t("Total_amount")}{" "}
                  </dt>
                  <dd className="font-medium text-[#D90429]">
                    {formatPrice(totalAmountViews)}
                  </dd>
                </div>
                {totalBalanceViews > 0 && <LoadingButton
                  style={{ margin: `10px 0px` }}
                  title={t("complet_pay")}
                  onClick={openModal}
                />}
              </dl>
            </div>
          </section>
        </main>
      ))}
      <LoadingComponent
        open={
          isLoading ||
          isLoadingO ||
          isLoadingP ||
          isLoadingT ||
          isLoadingPS ||
          isLoadingS ||
          isLoadingM ||
          isLoadingTO
        }
      />
      <ModalFlat
        isOpen={isOpen}
        children={<CompletePaymentForm orderId={id} />}
        title={"Complete Payment"}
        closeModal={closeModal}
      />
    </div>
  );
}

export default OrderDetails