import React, { Fragment, useState } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";
import { useSelector } from "react-redux";
import { flags } from "../../../app/hooks";
import globe from '../../../assets/images/globe-search.svg'
import { useListCountriesQuery } from "../../../app/services/Api";
import { isRealValue } from "../../../utilities";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function CountryPicker({ label, onSelect = () => {}, errorProps, style }) {
  useListCountriesQuery()

  const {  lng } = useSelector((state) => state.app);
  const { countries } = useSelector((state) => state.auth);

  const [selected, setSelected] = useState(isRealValue(countries) ? countries[0] : null);

  const onChange = (val) => {
    onSelect(val?.callingCode);
    setSelected(val);
  };

  React.useEffect(() => { }, [countries])

  return (
    <>
      <Listbox value={selected} onChange={onChange}>
        {({ open }) => (
          <div>
            {label && (
              <h3 className="block text-sm font-medium text-gray-700">
                {label}
              </h3>
            )}
            <div style={style} className="relative w-full">
              <Listbox.Button
                style={{
                  width: "100%",
                  margin: `10px 0px`,
                }}
                className="relative w-full cursor-default rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 text-left shadow-sm focus:border-[#D90429] focus:outline-none focus:ring-1 focus:ring-[#D90429] sm:text-sm"
              >
                {selected != null && (
                  <span className="flex items-center">
                    <img
                      src={flags(selected?.country)}
                      alt=""
                      className="h-6 w-6 flex-shrink-0 rounded-full"
                    />
                    <span className="ml-3 block truncate">
                      {selected.name[lng]}
                    </span>
                  </span>
                )}
                {selected === null && (
                  <span className="flex items-center">
                    <img
                      src={globe}
                      alt=""
                      className="h-6 w-6 flex-shrink-0 rounded-full"
                    />
                    <span className="ml-3 block truncate">
                      Select your country
                    </span>
                  </span>
                )}
                <span className="pointer-events-none absolute inset-y-0 right-0 ml-3 flex items-center pr-2">
                  <ChevronUpDownIcon
                    className="h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                </span>
              </Listbox.Button>

              <Transition
                show={open}
                as={Fragment}
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Listbox.Options className="absolute z-10 mt-1 max-h-56 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                  {countries?.map((country, index) => (
                    <Listbox.Option
                      key={index}
                      className={({ active }) =>
                        classNames(
                          active
                            ? "text-white bg-[#D9042970]"
                            : "text-gray-900",
                          "relative cursor-default select-none py-2 pl-3 pr-9"
                        )
                      }
                      value={country}
                    >
                      {({ selected, active }) => (
                        <>
                          <div className="flex items-center">
                            <img
                              src={flags(country?.country)}
                              alt=""
                              className="h-6 w-6 flex-shrink-0 rounded-full"
                            />
                            <span
                              className={classNames(
                                selected ? "font-semibold" : "font-normal",
                                "ml-3 block truncate"
                              )}
                            >
                              {country.name[lng]}
                            </span>
                          </div>

                          {selected ? (
                            <span
                              className={classNames(
                                active ? "text-white" : "text-[#D90429]",
                                "absolute inset-y-0 right-0 flex items-center pr-4"
                              )}
                            >
                              <CheckIcon
                                className="h-5 w-5"
                                aria-hidden="true"
                              />
                            </span>
                          ) : null}
                        </>
                      )}
                    </Listbox.Option>
                  ))}
                </Listbox.Options>
              </Transition>
            </div>
          </div>
        )}
      </Listbox>
      {errorProps && <p> Please select your country </p>}
    </>
  );
}
