import { Divider } from '@mui/material'
import React from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { dispatch } from '../../app'
import { handleHeaderType } from '../../app/features/authSlice'
import LoginForm from '../../components/layout/LoginForm'
import RegisterForm from '../../components/layout/RegisterForm'
import { HOME } from '../../constants/routes'
import './styles.css'
import { scroolWindowToTop } from '../../utilities'

const SignInSignUp = () => {
  const { isLogged } = useSelector(state => state.auth)
  const navigate = useNavigate()
  if (isLogged) navigate(`${HOME}`)

  React.useEffect(() => {
    scroolWindowToTop()
  }, [0])
  return (
    <div>
      <div id='signIn-signUp' className='row-flex-space signIn-signUp' style={{ marginTop: 45, justifyContent: 'space-around' }} >
        <div className='column-flex' >
          <h1> Sign In </h1>
          {/* <p className='auth-text' style={{ marginBottom: 25 }} >
            Lorem Ipsum is simply dummy text of the printing and <br></br>
            typesetting industry. Lorem Ipsum has been the industry's <br />
            standard dummy text ever since the 1500s
          </p> */}
          <LoginForm />
        </div>
        <Divider orientation='vertical' variant='middle' flexItem sx={{ borderWidth: 2, borderColor: '#D9042940' }} />
        <div className='column-flex'>
          <h1> Sign Up </h1>
          {/* <p className='auth-text' style={{ marginBottom: 25 }} >
            Lorem Ipsum is simply dummy text of the printing and <br></br>
            typesetting industry. Lorem Ipsum has been the industry's <br />
            standard dummy text ever since the 1500s
          </p> */}
          <RegisterForm />
        </div>
      </div>
    </div>
  )
}

export default SignInSignUp