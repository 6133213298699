import React from 'react';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { dispatch } from '../../app';
import { handleHeaderType } from '../../app/features/authSlice';
import CardComponent from '../../components/common/card';
import RegisterForm from '../../components/layout/RegisterForm';
import { HOME } from '../../constants/routes';
import logo from "../../assets/images/Logo_AFLP.png";

import './styles.css'
import { useListCountriesQuery } from '../../app/services/Api';
import { scroolWindowToTop } from '../../utilities';

const SignUp = () => {
  useListCountriesQuery()

  const { isLogged } = useSelector(state => state.auth)
  const navigate = useNavigate()
  if (isLogged) navigate(`${HOME}`)

  React.useEffect(() => {
    dispatch(handleHeaderType({ headerType: "none", isStore: false, header: false, checkout: false }))
    scroolWindowToTop()
  }, [0])

  return (
    <div className="container mx-auto ">
      <Link className="cursor-pointer" to={HOME}>
        <img onContextMenu={(event) => { event.preventDefault(); }}
          src={logo}
          className="w-20 h-20 object-contain object-center mx-auto my-4 "
          alt='logo'
        />
      </Link>
      <CardComponent title={"Sign Up"}>
        <RegisterForm />
      </CardComponent>
    </div>
  );
};
export default SignUp;