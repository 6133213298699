import { createSlice } from "@reduxjs/toolkit";
import { getAttributes, isRealValue } from "../../utilities";

const initialState = {
    pagination: {
        "total": 0,
        "count": 0,
        "perPage": 0,
        "currentPage": 0,
        "totalPages": 0,
        "nextLink": ""
    },
    items: [],
    itemsFilter: [],
    recommendations: [],
    keyword: '',

    search: "",
    status: null | 'loading' | 'success' | 'failed',
    sortPrice: 'asc',
    attributesCategories: [],
    minPrice: 0,
    maxPrice: 0,
    page: 1,
    pages: [],
    limit: 96,
    isLoadingPage: false,
    brands: [],
    brandsObject: [],
    stores: [],
    grades: [],
    priceRange: [],
    categories: [],
    keyCategory: '',
    keyStore: '',
}

const itemsSlice = createSlice({
    name: 'items',
    initialState,
    reducers: {
        handleLimit(state) {
            state.limit = state.pagination.total > state.limit ? state.limit + 50 : state.pagination?.total
        },
        handlePageItems(state, {payload}) {
            state.page = payload
            // state.page = state.pagination.totalPages > state.page ? state.page + 1 : state.pagination?.totalPages
        },
        handleSearchItems(state, action) {
            state.items = action.payload
        },
        handleIsLoading(state, action) {
            state.isLoadingPage = false
        },
        handleSearch(state, { payload }) {
            const { keyword, lng } = payload
            state.keyword = keyword
            if (keyword === '') state.itemsFilter = state.items
            else state.itemsFilter = state.items.filter(item => item.description[lng].toLowerCase().includes(keyword.toLowerCase()))
        },
        handleSortByPrice(state, action) {
            state.sortPrice = action.payload
            state.itemsFilter = state.items.sort(sortFunction)

            function sortFunction(prevItem, nextItem) {
                switch (state.sortPrice) {
                    case 'desc': {
                        if (prevItem.basePrice.cm.seaPrice < nextItem.basePrice.cm.seaPrice) return 1
                        if (prevItem.basePrice.cm.seaPrice > nextItem.basePrice.cm.seaPrice) return -1
                        return 0
                    }
                    case 'asc': {
                        if (prevItem.basePrice.cm.seaPrice > nextItem.basePrice.cm.seaPrice) return 1
                        if (prevItem.basePrice.cm.seaPrice < nextItem.basePrice.cm.seaPrice) return -1
                        return 0
                    }
                    default:
                }
            }
        },
        handleFilterByAttribute(state, action) {
            let attrKey = action.payload
            let results = []
            state.items?.map(item => {
                item?.attributes?.map(attribute => {
                    if (attribute.valueKey === attrKey) {
                        results.push(item)
                        return null
                    }
                    return null
                })
                return null
            })
            state.itemsFilter = results
        },
        handleGetAllItemsCategory(state, action) {
            const { items, brands, stores, grades, pagination, categories, attributes, priceRange } = action.payload
            state.pagination = pagination

            // if (!state.pages.includes(state.page)) {
            //     state.pages = state.pages.concat(pagination.currentPage)
            // }
            state.items = items ?? []
            state.itemsFilter = items ?? []
            state.brands = brands ?? []
            state.stores = stores ?? []
            state.categories = categories ?? []
            state.grades = grades ?? []

            if(isRealValue(priceRange)){
                state.priceRange = priceRange
                state.priceRange = Object.keys(priceRange).map(key => {
                    return {
                        title: priceRange[`${key}`],
                        desc: key
                    }
                })
            }
            state.attributesCategories = getAttributes(attributes)
            
        },
        handleKeyCategory(state, { payload }) {
            state.keyCategory = payload
        },
        handleKeyStore(state, { payload }) {
            state.keyStore = payload
        },
        handleBrandsObject(state, { payload }) {
            state.brandsObject = payload
        },
        handleRecommendations(state, { payload }) {
            state.recommendations = payload
        },
        handleLimitPagination(state, action) {
            state.pagination.perPage = parseInt(action.payload, 10)
        },
        handlePagePagination(state, action) {
            state.pagination.currentPage = parseInt(action.payload, 10)
        },
    },
})

export const {
    handleFilterByAttribute,
    handleGetAllItemsCategory,
    handleSortByPrice,
    handleSearch,
    handleSearchItems,
    handleIsLoading,
    handleLimit,
    handleKeyCategory,
    handleKeyStore,
    handleBrandsObject,
    handlePageItems,
    handleRecommendations,
    handleLimitPagination,
    handlePagePagination

} = itemsSlice.actions

export default itemsSlice