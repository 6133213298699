import React from 'react';
import { Checkbox, Table, TableCell, TableContainer, TableRow, useMediaQuery } from '@mui/material';
import { useSelector } from 'react-redux';
import { handleRequest, handleSelectAllCart, handleStoreSelect } from '../../app/features/bookmarkCardItemsSlice';
import './styles.css'

import { EnhancedTableHead } from '../../components/common/Table/EnhancedTableHead';
import { TableRowCard } from './TableRowCard';
import { dispatch } from '../../app';
import { useTranslation } from 'react-i18next';
import { isRealValue, scroolWindowToTop } from '../../utilities';

function EnhancedTable() {

  const { cartItemsSelected, data, selectedStore } = useSelector((state) => state.bookmarkCard);
  const { lng } = useSelector((state) => state.app);
  const isSelected = (key, variantKey) => isRealValue(cartItemsSelected.find(i => i.key === key && i.variantKey === variantKey))
  const { t } = useTranslation();
  const resizeTxt = useMediaQuery("(min-width:1024px)");
  const handleRequestSort = (property) => dispatch(handleRequest(property))
  const handleSelectAllClick = () => dispatch(handleSelectAllCart())
  const handleSelectStore = (store, items = []) => dispatch(handleStoreSelect({ store, items }))

  React.useEffect(() => {
    scroolWindowToTop()
  })

  return (
    <div>
      <>
        <TableContainer>
          <Table
            aria-labelledby="tableTitle"
            size="medium"
            aria-label="enhanced table"
          >
            {resizeTxt && (
              <EnhancedTableHead
                numSelected={cartItemsSelected?.length}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={cartItemsSelected?.length}
              />
            )}
          </Table>
        </TableContainer>
      </>
      <div className="flex-1 overflow-y-scroll h-[65vh] my-2 rounded-lg ">
        {data?.length <= 0 ? (
          <TableRow>
            <TableCell colSpan={6} sx={{ height: 100, textAlign: "center" }}>
              {t("empty_cart")}
            </TableCell>
          </TableRow>
        ) : (
          <div className="my-7 ">
            {data.map((articles, key) => (
              <div className="my-4" key={key}>
                <div className="mb-2">
                  <Checkbox
                    onClick={(e) => { e.preventDefault(); handleSelectStore(articles.store, articles.items) }}
                    checked={selectedStore?.includes(articles.store)}
                    inputProps={{ "aria-labelledby": key }}
                    sx={{ color: "#D90429", margin: 0, padding: 0 }}
                  />
                  <span>{articles.storeName[lng]}</span>
                </div>
                <ul className="py-4 bg-gray-100 border border-gray-300 rounded-lg shadow-sm ">
                  {articles?.items?.map((item, index) => {
                    const isItemSelected = isSelected(item?.key, item?.variantKey);
                    const labelId = `enhanced-table-checkbox-${index}`;
                    return (
                      <TableRowCard
                        key={index}
                        index={index}
                        item={item}
                        store={articles.store}
                        labelId={labelId}
                        isItemSelected={isItemSelected}
                      />
                    );
                  })}
                </ul>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
}

export default EnhancedTable