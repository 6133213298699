import { CircularProgress } from '@mui/material'
import React from 'react'
import { Link } from 'react-router-dom'
import { SIGN_IN } from '../../../constants/routes'
import './styles.css';
import { isRealValue } from '../../../utilities'
import { Controller, useForm } from 'react-hook-form'
import { Visibility, VisibilityOff } from '@mui/icons-material'
import { useSelector } from 'react-redux'
import ButtonFlat from '../../ButtonFlat'
import InputFlat from '../../InputFlat';
import { startWithCallingCode, validatePhone } from '../../../app/hooks'

import { useTranslation } from 'react-i18next'
import useMutationApi from '../../../app/hooks/useMutationApi'
import CountryPicker from '../../common/CountryPicker'
import { useListCountriesQuery } from '../../../app/services/Api';

const RegisterForm = () => {
  useListCountriesQuery()

  const { countries } = useSelector(state => state.auth)
  const { t } = useTranslation()
  const [valueCountry, setValueCountry] = React.useState(isRealValue(countries) ? countries[0] : { callingCode: '+237', })

  const [form, setForm] = React.useState({})
  const [showPassword, setShowPassword] = React.useState(false)
  const { handleSubmit, control, formState: { errors }, getValues, watch } = useForm({
    defaultValues: {
      password: '',
      email: '',
      userName: '',
      firstName: '',
      lastName: '',
      confirmPassword: '',
      phone: '',
      isChecked: true,
      callingCode: valueCountry?.callingCode,
    }
  })
  const toogleShowPassword = () => setShowPassword(!showPassword)
  const { signupByUser, isLoadingSignUp, isLoadingsendOtpCode } = useMutationApi()

  const handleSignUp = (data) => {

    const { email, confirmPassword, callingCode, phone, isChecked, ...rest } = data
    const c = watch("callingCode");
    const ct = countries?.find((v) => v?.callingCode === c)
    var p = phone.trim()
    if (startWithCallingCode(p, ct?.country)) {
      p = phone?.startsWith(c) ? phone?.replace(c, "") :
        phone?.startsWith(c.substring(1)) ? phone?.replace(c.substring(1), "") : () => { }
    }

    if (isChecked) {
      const em = isRealValue(email) ? { email } : {}
      const body = { ...rest, ...em, phone: p?.trim() }
      signupByUser({
        callingCode: callingCode?.replace("+", "%2b"),
        body,
      });
    }

  }

  const changePhone = (event, onChange) => {
    const p = event.target.value;
    onChange(p)
  }


  React.useEffect(() => { }, [countries])

  return (
    <form
      className="reg-form"
      style={{ width: "100%" }}
      onSubmit={handleSubmit(handleSignUp)}
    >
      <Controller
        control={control}
        rules={{ required: true }}
        render={({ field: { onChange } }) => (
          <CountryPicker
            onSelect={(v) => {
              onChange(v);
              setValueCountry(v);
            }}
            errorProps={errors?.callingCode}
            // containerStyle={{ marginVertical: 25 }}
          />
        )}
        name="callingCode"
      />

      <Controller
        control={control}
        rules={{
          required: true,
          minLength: 4,
          maxLength: 30,
          validate: () => {},
          // pattern:
        }}
        render={({ field: { onChange, value } }) => (
          <InputFlat
            error={errors?.firstName ? t("eyf") : undefined}
            type="text"
            name={t("fname")}
            placeholder={t("fname")}
            style={{ fontSize: 16 }}
            value={value}
            onChange={onChange}
          />
        )}
        name="firstName"
      />
      <Controller
        control={control}
        rules={{
          required: true,
          minLength: 4,
          maxLength: 30,
          validate: () => {},
          // pattern:
        }}
        render={({ field: { onChange, value } }) => (
          <InputFlat
            error={errors.lastName ? t("eyl") : null}
            type="text"
            name={t("lname")}
            placeholder={t("lname")}
            value={value}
            onChange={onChange}
            style={{ fontSize: 16 }}
          />
        )}
        name="lastName"
      />
      <Controller
        control={control}
        rules={{
          required: true,
          minLength: 4,
          maxLength: 30,
          validate: () => {},
          // pattern:
        }}
        render={({ field: { onChange, value } }) => (
          <InputFlat
            error={errors.userName ? t("eyn") : null}
            type="text"
            name={t("username")}
            placeholder={t("username")}
            value={value}
            onChange={onChange}
            style={{ fontSize: 16 }}
          />
        )}
        name="userName"
      />

      <Controller
        control={control}
        rules={{
          required: true,
          maxLength: 30,
          // pattern: {
          //   value: /^6\d{2}\d{3}\d{3}$/,
          //   message: "Invalid phone number format",
          // },
          // validate: () => {},
          validate: (v) => {
            const c = watch("callingCode");
            const ct = countries?.find((v) => v?.callingCode === c);
            if (startWithCallingCode(v, ct?.country)) {
              return validatePhone(v?.replace(/\s?/g, ""), ct?.country);
            }
            return validatePhone((c + v)?.replace(/\s?/g, ""), ct?.country);
          },
          // pattern:
        }}
        render={({ field: { onChange, value } }) => (
          <InputFlat
            error={
              errors.phone || form?.phone
                ? "invalid"
                : //  handleAllErrors(errors.phone || { error: form?.phone })
                  null
            }
            type="tel"
            leftIcon={
              watch("callingCode").length > 0 ? (
                <p className="text-sm ">{getValues("callingCode")}</p>
              ) : null
            }
            name={t("tel_numb")}
            placeholder={t("ent_phone_number")}
            value={value}
            style={{ fontSize: 16 }}
            onChange={(e) => changePhone(e, onChange)}
          />
        )}
        name="phone"
      />

      <Controller
        control={control}
        rules={{
          required: false,
          minLength: 4,
          maxLength: 30,
          validate: () => {},
          // pattern:
        }}
        render={({ field: { onChange, value } }) => (
          <InputFlat
            error={errors.email ? t("eye") : null}
            type="email"
            name={"email"}
            style={{ fontSize: 16 }}
            placeholder={t("ent_email")}
            value={value}
            onChange={onChange}
          />
        )}
        name="email"
      />
      <Controller
        control={control}
        rules={{
          required: true,
          minLength: 6,
          maxLength: 30,
          validate: () => {},
          // pattern:
        }}
        render={({ field: { onChange, value } }) => (
          <InputFlat
            icon={
              showPassword ? (
                <Visibility fontSize="small" onClick={toogleShowPassword} />
              ) : (
                <VisibilityOff fontSize="small" onClick={toogleShowPassword} />
              )
            }
            error={
              errors?.password &&
              (errors?.password?.type === "required"
                ? t("eyp")
                : errors?.password?.type === "minLength"
                ? t("min_pass_err")
                : undefined)
            }
            type={showPassword ? "text" : "password"}
            name={t("password")}
            placeholder={t("password")}
            style={{ fontSize: 16 }}
            value={value}
            onChange={onChange}
          />
        )}
        name="password"
      />
      <Controller
        control={control}
        rules={{
          required: true,
          minLength: 6,
          maxLength: 30,
          validate: () => {},
          // pattern:
        }}
        render={({ field: { onChange, value } }) => (
          <InputFlat
            error={
              errors?.confirmPassword &&
              (errors?.confirmPassword?.type === "required"
                ? t("eyp")
                : errors?.confirmPassword?.type === "minLength"
                ? "avoir au moins 6 caractères"
                : errors?.confirmPassword?.type === "validate"
                ? t("pass_not_cor_err")
                : undefined)
            }
            type="password"
            name={t("confirm_pass")}
            placeholder={t("confirm_pass")}
            style={{ fontSize: 16 }}
            value={value}
            onChange={onChange}
          />
        )}
        name="confirmPassword"
      />

      {errors.isChecked && <span>Agree to continuos</span>}
      {/* <div>
        <Controller
          control={control}
          rules={{ required: true }}
          render={({ field: { onChange, value } }) => (
            <Checkbox
              sx={{ "& .MuiSvgIcon-root": { fontSize: 28, color: "#D90429" } }}
              checked={value}
              onChange={onChange}
            />
          )}
          name="isChecked"
        />
        <span className="terms-services">
          I agree to the Google Terms of Service and Privacy Policy
        </span>
        <br />
        {errorApi && errorApi?.status === "FETCH_ERROR" ? (
          <span style={{ color: "red" }}>NetWork error.</span>
        ) : null}
      </div> */}
      {isLoadingsendOtpCode || isLoadingSignUp ? (
        <CircularProgress sx={{ color: "#D90429" }} />
      ) : (
        <ButtonFlat type={"submit"} title={t("sign_up")} />
      )}
      <div className=".row-flex" style={{ alignSelf: "center" }}>
        <span>{t("already_member")}</span>
        <Link className="link" to={SIGN_IN}>
          <span className="signin-link">{t("sign_in")}</span>
        </Link>
      </div>
    </form>
  );
}

export default RegisterForm