import React from 'react'
import { Fragment } from 'react'
import { Menu, Transition } from '@headlessui/react'
import { EllipsisVerticalIcon } from '@heroicons/react/24/outline'
import { CheckCircleIcon } from '@heroicons/react/20/solid'
import { useNavigate } from 'react-router-dom'
import { ORDER_DETAILS, PRODUCT_DETAILS } from '../../constants/routes'
import { isRealValue } from '../../utilities'
import { useFormatter } from '../../app/hooks/useFormatter'
import { useTranslation } from 'react-i18next'
import { Divider, Stack } from '@mui/material'

const OrderItem = ({ order, lng }) => {
    const navigate = useNavigate()
  const { t } = useTranslation();

    function classNames(...classes) {
        return classes.filter(Boolean).join(' ')
    }

    const { formatDate } = useFormatter()
    return (
      <div
        key={order.number}
        className="bg-white border-t border-b border-gray-200 shadow-sm sm:rounded-lg sm:border"
      >
        <h3 className="sr-only">
          {t("ord_plc_on")}{" "}
          <time dateTime={formatDate(order?.created)}>
            {formatDate(order?.created)}
          </time>
        </h3>
        <div className="flex items-center p-4 border-b border-gray-200 sm:gap-x-6 sm:p-6 relative">
          <dl className=" flex-1 flex flex-wrap items-center justify-between text-sm ">
            <div className="">
              <dt className="font-medium text-xs lg:text-sm text-gray-700">
                {t("ord_by")}
              </dt>
              <dd className="mt-1 text-[#D90429] text-sm lg:text-base font-semibold ">
                {order?.key || order?.ref}
              </dd>
            </div>
            <div className="">
              <dt className="font-medium text-xs lg:text-sm text-gray-700">
                {t("dte_plc")}
              </dt>
              <dd className="mt-1 text-[#D90429] text-sm lg:text-base font-semibold ">
                <time dateTime={formatDate(order?.created)}>
                  {formatDate(order?.created)}
                </time>
              </dd>
            </div>
            <div>
              <dt className="font-medium text-xs lg:text-sm text-gray-700">
                {t("Total_amount")}
              </dt>
              <dd className="mt-1 text-[#D90429] text-sm lg:text-base font-semibold ">
                {order?.totalAmountDisplayed}
              </dd>
            </div>
          </dl>
          {/* Mobile Only  */}
          <Menu
            as="div"
            className="absolute top-4 right-0 flex justify-end lg:hidden "
          >
            <div className="flex items-center">
              <Menu.Button className="flex items-center p-2 -m-2 text-gray-400 hover:text-gray-500">
                <span className="sr-only">Options for order {order?.key}</span>
                <EllipsisVerticalIcon className="w-6 h-6" aria-hidden="true" />
              </Menu.Button>
            </div>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items className="absolute right-0 z-10 w-[fit-content] mt-2 origin-bottom-right bg-white rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                <div className="py-1">
                  <Menu.Item>
                    {({ active }) => (
                      <a
                        onClick={() =>
                          navigate(
                            `${ORDER_DETAILS}${
                              isRealValue(order?.key) ? order?.key : order?.ref
                            }`,
                            { state: { id: order?._id } }
                          )
                        }
                        className="flex line-clamp-1 w-[max-content] items-center cursor-pointer justify-center rounded-md bg-white py-2 px-1.5 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-[#D90429] focus:ring-offset-2"
                      >
                        <span>{t("view_ord")}</span>
                      </a>
                    )}
                  </Menu.Item>
                </div>
              </Menu.Items>
            </Transition>
          </Menu>

          <div className="hidden lg:flex lg:items-center lg:justify-end">
            <a
              onClick={() =>
                navigate(
                  `${ORDER_DETAILS}${
                    isRealValue(order?.key) ? order?.key : order?.ref
                  }`,
                  { state: { id: order?._id } }
                )
              }
              className="flex items-center line-clamp-1 truncate cursor-pointer justify-center rounded-md border border-gray-300 bg-white py-2 px-2.5 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-[#D90429] focus:ring-offset-2"
            >
              <span>{t("view_ord")}</span>
            </a>
          </div>
        </div>
        <h4 className="sr-only">Items</h4>
        <ul role="list" className="divide-y divide-gray-200">
          {order?.items?.map((product, index) => (
            <li key={index} className="p-4 sm:p-6">
              <div className=" grid grid-col-3 space-y-1 md:space-y-0 md:flex md:flex-wrap lg:flex items-center justify-between sm:items-start">
                {/* <div className="flex-shrink-0 w-20 h-20 overflow-hidden bg-gray-200 rounded-lg sm:h-40 sm:w-40">
                                <img onContextMenu = { (event)=> { event.preventDefault();    }  }
                                    src={product.imageSrc}
                                    alt={product.imageAlt}
                                    className="object-cover object-center w-full h-full"
                                />
                            </div> */}
                <div className="flex-1 text-sm">
                  <div className="font-medium text-gray-700 sm:flex-col sm:justify-between">
                    <h5 className="text-sm font-semibold text-gray-600">
                      {" "}
                      {t("unt_prc")}
                    </h5>
                    <p className="mt-2 text-[#D90429] sm:mt-0 text- ">
                      {product?.unitPriceDisplayed}
                    </p>
                  </div>
                </div>
                <div className="flex-1 text-sm">
                  <div className="font-medium text-gray-700 sm:flex-col sm:justify-between">
                    <h5 className="text-sm font-semibold text-gray-600">
                      {t("qty")}
                    </h5>
                    <p className="mt-2 text-[#D90429] sm:mt-0">
                      {product?.quantity}
                    </p>
                  </div>
                </div>
                <div className="flex-1 text-sm">
                  <div className="font-medium text-gray-700 sm:flex-col sm:justify-between">
                    <h5 className="text-sm font-semibold text-gray-600">
                      {t("tot_price")}
                    </h5>
                    <p className="mt-2 text-[#D90429] sm:mt-0">
                      {product?.totalPriceDisplayed}
                    </p>
                  </div>
                </div>
                <div className="flex-1 text-sm">
                  <div className="font-medium text-gray-700 sm:flex-col sm:justify-between">
                    <h5 className="text-sm font-semibold text-gray-600">
                      {t("route")}
                    </h5>
                    <p className="mt-2 text-[#D90429] sm:mt-0">
                      {product?.route}
                    </p>
                  </div>
                  {/* <p className="hidden text-gray-500 sm:mt-2 text-[#D90429] sm:block">{`Route ${product?.route}`}</p> */}
                </div>
                <div className="flex-1 text-sm">
                  <div className="font-medium text-gray-700 sm:flex-col sm:justify-between">
                    <h5 className="text-sm font-semibold text-gray-600">
                      {t("status")}
                    </h5>
                    <p className="mt-2 text-[#D90429] sm:mt-0">
                      {product?.status}
                    </p>
                  </div>
                  {/* <p className="hidden text-gray-500 sm:mt-2 sm:block">{`Route ${product?.route}`}</p> */}
                </div>
              </div>
              <div className="mt-6 flex justify-end">
                <div className="flex items-center justify-end pt-4 mt-6 space-x-4 text-sm font-medium border-t border-gray-200 divide-x divide-gray-200 sm:mt-0 sm:ml-4 sm:border-none sm:pt-0">
                  <div className="flex justify-center flex-1">
                    <a
                      href={`${PRODUCT_DETAILS}/${product?.itemKey}`}
                      className="whitespace-nowrap text-[#D90429] hover:text-[#D90429]"
                    >
                      {t("vw_itm")}
                    </a>
                  </div>
                </div>
              </div>
            </li>
          ))}
        </ul>
        <Divider color="#cfcfcf" />
        <Stack
          direction={"row"}
          alignItems={"center"}
          spacing={1}
          justifyContent={"flex-start"}
          padding={"20px"}
        >
          <p className="text-[#D90429] text-sm font-semibold ">
            {t("ord_stat")}
          </p>
          <div className="flex items-center">
            <CheckCircleIcon
              className="w-5 h-5 text-green-500"
              aria-hidden="true"
            />
            <p className="ml-2 text-sm font-medium text-gray-500">
              {order?.status}
              {/* <time dateTime={formatDate(order?.created)}>{formatDate(order?.created)}</time> */}
            </p>
          </div>
        </Stack>
      </div>
    );
}

export default OrderItem
