import { createSlice } from "@reduxjs/toolkit";
import { toString } from "../../utilities";

const initialState = {
    // this list for item filtering
    sort: null, //sales, new, price, -price, score
    grade: [],
    brand: [],
    propertyKey: [],
    numberPropertySelected: {},
    valueKey: [],
    selectedValues: [],
    priceMin: 0,
    priceMax: 0,
    filter: {},

    codesValues: [], //arrays values of code_context : [values]
    // code, context, method
}

const filterItemSlice = createSlice({
    name: 'filters',
    initialState,
    reducers: {
        handleResetFilter(state){
            state.filter = {}
            state.sort =  null
            state.grade =  []
            state.brand =  []
            state.propertyKey =  []
            state.numberPropertySelected =  {}
            state.valueKey =  []
            state.selectedValues =  []
            state.priceMin =  0
            state.priceMax =  0
        },
        handleSort(state, { payload }) {
            state.sort = payload
            state.filter = { ...state.filter, sort: payload }
        },
        handlePrinceRanges(state, { payload }) {
            const { min, max } = payload
            state.priceMin = min
            state.priceMax = max
        },
        handleBrandKey(state, { payload }) {
            const indexBrand = state.brand.indexOf(payload)

            if (indexBrand === -1) state.brand.unshift(payload)
            else if (indexBrand === 0) state.brand.shift()
            else if (indexBrand === state.brand?.length - 1) state.brand.pop()
            else if (indexBrand > 0) {
                state.brand = [].concat(
                    state.brand.slice(0, indexBrand),
                    state.brand.slice(indexBrand + 1));
            }
        },
        handleValueKey(state, { payload }) {
            const { propertyKey, value } = payload
            const { valueKey, valueName } = value

            const indexProperty = state.propertyKey.indexOf(propertyKey)
            const indexValue = state.valueKey.indexOf(valueKey)
            //initialize the array
            if (indexProperty === -1) {
                state.propertyKey.unshift(propertyKey)
                state.valueKey.unshift(valueKey)
                state.selectedValues.unshift({...valueName, valueKey, propertyKey})
                state.numberPropertySelected[`p_${propertyKey}`] = 1
            } else {
                if (indexValue === -1) {
                    state.valueKey.unshift(valueKey)
                    state.selectedValues.unshift({...valueName, valueKey, propertyKey})
                    state.numberPropertySelected[`p_${propertyKey}`] += 1
                }
                else {
                    //update valueKey state and selectedValues state
                    if (indexValue === 0) {
                        state.valueKey.shift()
                        state.selectedValues.shift()
                    }
                    else if (indexValue === state.valueKey?.length - 1) {
                        state.valueKey.pop()
                        state.selectedValues.pop()
                    }
                    else if (indexValue > 0) {
                        state.valueKey = [].concat(
                            state.valueKey.slice(0, indexValue),
                            state.valueKey.slice(indexValue + 1));

                        state.selectedValues = [].concat(
                            state.selectedValues.slice(0, indexValue),
                            state.selectedValues.slice(indexValue + 1));
                    }
                    state.numberPropertySelected[`p_${propertyKey}`] -= 1

                    if (state.numberPropertySelected[`p_${propertyKey}`] <= 0) {
                        //update propertyKey state
                        if (indexProperty === 0) state.propertyKey.shift()
                        else if (indexProperty === state.propertyKey?.length - 1)
                            state.propertyKey.pop()
                        else if (indexProperty > 0) {
                            state.propertyKey = [].concat(
                                state.propertyKey.slice(0, indexProperty),
                                state.propertyKey.slice(indexProperty + 1));
                        }
                    }
                }
            }
        },
        handleValueKeyHistory(state, {payload}){
            const {valueKey, propertyKey} = payload
            const indexProperty = state.propertyKey.indexOf(propertyKey)
            const indexValue = state.valueKey.indexOf(valueKey)
            //update valueKey state and selectedValues state
            if (indexValue === 0) {
                state.valueKey.shift()
                state.selectedValues.shift()
            }
            else if (indexValue === state.valueKey?.length - 1) {
                state.valueKey.pop()
                state.selectedValues.pop()
            }
            else if (indexValue > 0) {
                state.valueKey = [].concat(
                    state.valueKey.slice(0, indexValue),
                    state.valueKey.slice(indexValue + 1));

                state.selectedValues = [].concat(
                    state.selectedValues.slice(0, indexValue),
                    state.selectedValues.slice(indexValue + 1));
            }
            state.numberPropertySelected[`p_${propertyKey}`] -= 1

            if (state.numberPropertySelected[`p_${propertyKey}`] <= 0) {
                //update propertyKey state
                if (indexProperty === 0) state.propertyKey.shift()
                else if (indexProperty === state.propertyKey?.length - 1)
                    state.propertyKey.pop()
                else if (indexProperty > 0) {
                    state.propertyKey = [].concat(
                        state.propertyKey.slice(0, indexProperty),
                        state.propertyKey.slice(indexProperty + 1));
                }
            } 
        },
        handleApplyFilter(state){
            var filter = {}
            if(state.sort !==null) filter['sort'] = state.sort
            if(state.brand.length > 0) filter.brand = toString(state.brand) 
            if(state.propertyKey.length > 0) filter.propertyKey = toString(state.propertyKey)
            if(state.valueKey.length > 0) filter.valueKey = toString(state.valueKey)
            if(state.priceMin >0 ) filter.priceMin = state.priceMin
            if(state.priceMax >0 ) filter.priceMax = state.priceMax
            if (state.grade > 0) filter.grade = toString(state.grade)

            state.filter = filter
        },
        handleApplyCategoryToFilter (state, {payload}){
            state.filter.category = payload
        },
        handleApplyStoreToFilter (state, {payload}){
            state.filter.store = payload
        },
        handleSelectGrades(state, { payload }) {
            state.grade = payload
            if (payload?.length > 0){
                state.filter.grade = toString(payload)
            }else {
                const { grade,  ...filter} = state.filter
                state.filter = { ...filter}
            }
        },
    },
})

export const {
    handleResetFilter,
    handleSort,
    handlePrinceRanges,
    handleValueKey,
    handleBrandKey,
    handleValueKeyHistory,
    handleApplyFilter,
    handleApplyCategoryToFilter,
    handleApplyStoreToFilter,
    handleSelectGrades,


} = filterItemSlice.actions
export const filtersSelector = (state)=>state.filters;

export default filterItemSlice