
import React from 'react'
import { Outlet } from 'react-router-dom'
import AppBar from '../components/common/AppBar'
import Footer from '../components/common/Footer'
import { useNetworkOnline } from '../app/hooks/useNetworkOnline';
import OffLine from './OffLine';
import { FiChevronUp } from "react-icons/fi";
import useCustomStripe from '../app/hooks/useCustomStripe';
import ScrollToTop from 'react-scroll-to-top'

const IndexPage = () => {
  const online = useNetworkOnline();
  useCustomStripe()

  // componentDidUpdate() {
  //   window.history.pushState(null, document.title, window.location.href);
  //   window.addEventListener('popstate', function (event) {
  //     window.history.pushState(null, document.title, window.location.href);
  //   });
  // }

  return (
    <>
      {!online && false ?
        <OffLine /> :
        <div className='w-full min-h-screen px-1 md:container md:mx-auto' >
          <AppBar />
          <div className='flex-1 h-full' >
            <Outlet />
          </div>
          <ScrollToTop style={{ backgroundColor: "var(--primary)", }} smooth component={<FiChevronUp size={28} color='white' className='mx-auto' />} />
          <Footer />
        </div>
      }
    </>
  )
}

export default IndexPage
