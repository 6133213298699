import React from 'react'
import CheckoutWrapper from '../../components/CheckoutWrapper'
import { scroolWindowToTop } from '../../utilities'

const ProceedToPayment = () => {

  React.useEffect(() => {
    scroolWindowToTop()
  }, [0])

  return (
    <div className="bg-white w-full ">
      <CheckoutWrapper />
    </div>
  )
}

export default ProceedToPayment