import React from "react";
import ItemLoading from "../../components/common/ProductItem/ItemLoading";

const LoadingProductListItems = () => {
  let Data = new Array(12).fill(0);
  return (
    <div className="grid grid-cols-2 gap-3 justify-items-center md:grid-cols-3 lg:grid-cols-4 2xl:grid-cols-6 my-6">
      {Data?.map((item, i) => (
        <div className=" w-[100%]" key={i}>
          <ItemLoading />
        </div>
      ))}
    </div>
  );
};

export default LoadingProductListItems;