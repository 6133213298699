import React from 'react'
import Card from '../common/Card'
import TextSmallGray from '../common/TextSmallGray'
import HeadingFour from '../common/HeadingFour'
import LoadingUserDetailsSection from './LoadingUserDetailsSection'
import { useSelector } from 'react-redux'
import TextGray from '../common/TextGray'
import { useTranslation } from 'react-i18next'

const UserDetailsSection = ({ isLoading }) => {
    const { InfoAccount } = useSelector(state => state?.auth);
    const {t} = useTranslation()

    const userDetailsData = [
      {
        label: t("fname"),
        value: InfoAccount?.firstName,
      },
      {
        label: t("lname"),
        value: InfoAccount?.lastName,
      },
      {
        label: t("e_mail"),
        value: InfoAccount?.email,
      },
      {
        label: t("tel_numb"),
        value: InfoAccount?.phone,
      },
    ];
  return (
      isLoading ? <LoadingUserDetailsSection /> : <Card style={{ padding: `20px` }} >
          <div className="grid grid-cols-1 xl:grid-cols-4 " >
              {
                  userDetailsData.map(({ value, label }) => <div
                      className="flex flex-col justify-center items-center w-[fit-content] my-2 " >
                      <TextGray
                          content={label}
                          style={{ textAlign: 'left', width: '100%', }}
                      />
                      <TextSmallGray
                          content={value}
                          style={{ textAlign: 'left', width: '100%' }}
                      />
                  </div>)
              }
          </div>
      </Card>
  )
}

export default UserDetailsSection