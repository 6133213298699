
import Dexie from 'dexie';

const dbName = "aflp"

export const db = new Dexie(dbName);
db.version(1).stores({
       bookmarksItems: '&key',
       bookmarksStore: '&key',
       bookmarksCart: '[itemKey+variantKey], route, store, quantity',
       returnPages: '&linkPage, stringifyStateJson',
       proposeSizes: '&key, value'
});
