import { useEffect } from "react";
import { useSelector } from "react-redux";
import { dispatch } from "../..";
import { getLanguage, setLanguage } from "../../utilities";
import { handleLanguage } from "../features/AppSlice";
import i18n from "../lang/i18n";


export function useLanguage() {
    const {lng } = useSelector(state => state.app)
    const changeLanguage = (lang) => { 
        dispatch(handleLanguage(lang))
        i18n.changeLanguage(lang, ()=> {
            setLanguage(lang)
            dispatch(handleLanguage(lang))
        })
    }

    useEffect(() => {
        const lang = getLanguage()
        if(lang===null)
            changeLanguage(lng)
        else changeLanguage(lang)
    }, [lng])

    return { changeLanguage };
}
