import "video-react/dist/video-react.css"
import React from 'react'
import {
    Player, ControlBar, VolumeMenuButton, PlaybackRateMenuButton,
    Shortcut, LoadingSpinner, BigPlayButton, ForwardControl, ReplayControl, FullscreenToggle
} from 'video-react'
import { Box } from "@mui/material"

const Video = (props) => {
    const { poster, src, width, height } = props

    const newShortcuts = [
        {
            keyCode: 49,
            handle: (player, actions) => {
                const duration = player.duration
                actions.seek(duration * 0.1)
            }
        },
    ]

    return (
     
            <Player
                playsInline
                poster={poster}
                src={src}

                preload={"metadata"}
                muted={true}
                autoPlay={false}
                aspectRation={"auto"}
                playbackRate={2}

                fluid={false}
                width={width}
                height={height}
                volume={50}
            >
                <Shortcut clickable={false} shortcuts={newShortcuts} />
                <BigPlayButton position="center" />
                <FullscreenToggle />
                <LoadingSpinner />
                <ControlBar  autoHide={true} autoHideTime={true} >
                    <VolumeMenuButton vertical={true} />
                    <ReplayControl seconds={5} order={2.1} />
                    <ForwardControl seconds={5} order={3.1} />
                </ControlBar>
            </Player>
    )
}

export default Video