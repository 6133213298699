import React from 'react'
import UserAvatarSection from './UserAvatarSection'
import HeadingFour from '../../components/common/HeadingFour'
import TextSmallGray from '../../components/common/TextSmallGray'
import Card from '../common/Card'
import LoadingAvatarSection from './LoadingAvatarSection'
import LoadingUserActivity from './LoadingUserActivity'
import { useSelector } from 'react-redux'
import { useFormatter } from '../../app/hooks/useFormatter'


const UserActivitySection = ({ isLoading }) => {
    const { orders } = useSelector(state => state.orders)
    const { getOrderOrderStatusData, display } = useFormatter()

    const userActivityData = getOrderOrderStatusData?.map(status => {
        return { 
            value: orders?.filter(order => order?.status === status?.value)?.length, 
            label: display(status)
        }
    })
    
  return (
    <div className="grid grid-cols-1 my-2 bg-transparent gap-7 lg:grid-cols-2 " >
          {isLoading ? <LoadingAvatarSection /> : <Card>
              <UserAvatarSection />
          </Card>}  
        <div className="grid grid-cols-2 gap-7 xl:grid-cols-4 " >
            {
                userActivityData.map(({value, label}, index) => {
                   return(
                       isLoading ? <LoadingUserActivity /> : <Card key={index + label} >
                           <div className="flex flex-col items-center justify-center " >
                               <HeadingFour style={{ color: `#D90429` }} content={value} />
                               <TextSmallGray content={label} style={{ textAlign: 'center' }} />
                           </div>
                       </Card>
                   )
                } )
            }
        </div>
    </div>
  )
}

export default UserActivitySection