import React, { useState } from "react";
import { RadioGroup } from "@headlessui/react";
import { useSelector } from 'react-redux'
import { dispatch } from '../..'
import { handlePickupLocation } from '../../app/features/bookmarkCardItemsSlice'
import { isRealValue } from '../../utilities'
import { flags } from "../../app/hooks";
import { useTranslation } from "react-i18next";
import { useNotifications } from "../../app/hooks/useNotifications";



function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function AddressTableMobile({ addresses }) {
  const [selected, setSelected] = useState(null)
  const { t } = useTranslation();


  const { lng } = useSelector(state => state.app);
  const { order } = useSelector(state => state.bookmarkCard);
  const checkbox = React.useRef()
  const { dangerNotification } = useNotifications();


  const [currentPosition, setCurrentPosition] = React.useState(null);

  React.useEffect(() => {
    // Function to get the current position
    const getCurrentPosition = () => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const { latitude, longitude } = position.coords;
            setCurrentPosition({ latitude, longitude });
          },
          (error) => {
            dangerNotification(
              "Error getting current position:" + error.message
            );
          }
        );
      } else {
        console.error("Geolocation is not supported by this browser.");
      }
    };

    getCurrentPosition();
  }, []);

  const openGoogleMaps = (latitude, longitude) => {
    const url = `https://www.google.com/maps?q=${latitude},${longitude}`;
    window.open(url, "_blank");
  };

  const openGoogleMapsDirections = (latitude, longitude) => {
    if (currentPosition != null) {
      const url = `https://www.google.com/maps/dir/${currentPosition?.latitude},${currentPosition?.longitude}/${latitude},${longitude}`;
      window.open(url, "_blank");
      return;
    }

    dangerNotification("Error getting current position. Try again");
  };

  const onChange = (val) => {
    // console.log(val);
    dispatch(handlePickupLocation(val?._id));
    setSelected(val);
  };

  return (
    <RadioGroup value={selected} onChange={onChange}>
      <RadioGroup.Label className="sr-only">
        {" "}
        Pricing addresss{" "}
      </RadioGroup.Label>
      <div className="relative -space-y-px rounded-md bg-white">
        {addresses.map((address, addressIdx) => (
          <RadioGroup.Option
            key={address.name}
            value={address}
            checked={address?._id === order?.order?.pickupLocation}
            className={({ checked }) =>
              classNames(
                addressIdx === 0 ? "rounded-tl-md rounded-tr-md" : "",
                addressIdx === addresses.length - 1
                  ? "rounded-bl-md rounded-br-md"
                  : "",
                address?._id === order?.order?.pickupLocation
                  ? "bg-[#D9042930] border-[#D90429] z-10"
                  : "border-gray-200",
                "relative border p-4 flex flex-col cursor-pointer md:pl-4 md:pr-6 md:grid md:grid-cols-3 focus:outline-none"
              )
            }
          >
            {({ active, checked }) => (
              <>
                <span className="flex items-center text-sm">
                  <span
                    className={classNames(
                      address?._id === order?.order?.pickupLocation
                        ? "bg-[#D90429] border-transparent"
                        : "bg-white border-gray-300",
                      active ? "ring-2 ring-offset-2 ring-[#D90429]" : "",
                      "h-4 w-4 rounded-full border flex items-center justify-center"
                    )}
                    aria-hidden="true"
                  >
                    <span className="rounded-full bg-white w-1.5 h-1.5" />
                  </span>
                  <RadioGroup.Label
                    as="span"
                    className={classNames(
                      address?._id === order?.order?.pickupLocation
                        ? "text-[#D9042990]"
                        : "text-gray-900",
                      "ml-3 font-medium"
                    )}
                  >
                    {isRealValue(address?.address[lng])
                      ? address?.address[lng]
                      : address?.address}
                  </RadioGroup.Label>
                </span>
                <RadioGroup.Description
                  as="span"
                  className="ml-6 pl-1 text-sm md:ml-0 md:pl-0 md:text-center"
                >
                  <span
                    className={classNames(
                      address?._id === order?.order?.pickupLocation
                        ? "text-[#D9042990]"
                        : "text-gray-900",
                      "font-medium text-sm"
                    )}
                  >
                    {isRealValue(address?.adminDivision3?.value)
                      ? "Arrondissement :"
                      : null}{" "}
                  </span>{" "}
                  <span
                    className={
                      address?._id === order?.order?.pickupLocation
                        ? "text-[#D9042990]"
                        : "text-gray-500"
                    }
                  >
                    {isRealValue(address?.address[lng])
                      ? address?.adminDivision3?.value[lng]
                      : " - "}
                  </span>
                </RadioGroup.Description>
                <RadioGroup.Description
                  as="span"
                  className={classNames(
                    address?._id === order?.order?.pickupLocation
                      ? "text-[#D9042990]"
                      : "text-gray-500",
                    "ml-6 pl-1 text-sm md:ml-0 md:pl-0 md:text-right"
                  )}
                >
                  <span className="flex items-center">
                    {address?.country && (
                      <>
                        <span className="mr-3 block truncate">
                          {address?.country?.toUpperCase()}
                        </span>
                        <img
                          src={flags(address?.country)}
                          alt=""
                          className="h-6 w-6 flex-shrink-0 rounded-full"
                        />
                      </>
                    )}
                  </span>
                </RadioGroup.Description>
                <RadioGroup.Description className="space-x-1 divide-x-2 divide-gray-400 text-sm font-medium text-right whitespace-nowrap sm:pr-6">
                  <span
                    onClick={() =>
                      openGoogleMaps(
                        address?.geometry?.coordinates[1],
                        address?.geometry?.coordinates[0]
                      )
                    }
                    className="text-[10px] text-[#D90429] cursor-pointer hover:underline"
                  >
                    {t("map_view")}
                  </span>
                  <span
                    onClick={(e) =>
                      openGoogleMapsDirections(
                        address?.geometry?.coordinates[1],
                        address?.geometry?.coordinates[0]
                      )
                    }
                    className="text-[10px] text-[#D90429] cursor-pointer hover:underline ml-1"
                  >
                    {t("get_dir")}
                  </span>
                </RadioGroup.Description>
              </>
            )}
          </RadioGroup.Option>
        ))}
      </div>
    </RadioGroup>
  );
}
