import cm from "../../assets/flags/cm.png"

const regCM = /^(\+237|237)6[0-9]{8}$/;

export const validatePhone = (phone, c = 'cm') => {
    switch (c) {
        case 'cm': return new RegExp(regCM).test(phone)
    }
}


const sCM = /^(\+237|237)/g;
export const startWithCallingCode = (phone, c = 'cm') => {
    switch (c) {
        case 'cm': return new RegExp(sCM).test(phone)
    }
}

export const flags = (c = 'cm') => {
    switch (c) {
        case 'cm': return cm
    }
}

export const returnFilterWithoutLang = (obj) => {
    const { lang, ...filter } = obj
    return filter
}


export const all = [{ name: { en: 'All', fr: 'Tout' }, value: "All" }]
export const locked = [
    { name: { en: 'No', fr: 'Non' }, value: false },
    { name: { en: 'Yes', fr: 'Oui' }, value: true }
]

export const locationService = ["cash in", "cash out", "pickup", "delivery"]
//Codes values combination
export const putOperationTransaction = { code: "transaction", context: "operation", method: "put" }
export const putOperationMethod = { code: "method", context: "operation", method: "put" }
export const putOperationState = { code: "state", context: "operation", method: "put" }
export const getOperationTransaction = { code: "transaction", context: "operation", method: "get" }
export const getOperationMethod = { code: "method", context: "operation", method: "get" }
export const getOperationState = { code: "state", context: "operation", method: "get" }

export const getOrderOrderStatus = { code: "orderStatus", context: "order", method: "get" }
export const getOrderItemStatus = { code: "itemStatus", context: "order", method: "get" }
export const getOrderRoute = { code: "route", context: "order", method: "get" }
export const putOrderOrderStatus = { code: "orderStatus", context: "order", method: "put" }
export const putOrderItemStatus = { code: "itemStatus", context: "order", method: "put" }
export const putOrderRoute = { code: "route", context: "order", method: "put" }

export const getStoreGrade = { code: "grade", context: "store", method: "get" }
export const putStoreGrade = { code: "grade", context: "store", method: "put" }

export const getItemGrade = { code: "grade", context: "item", method: "get" }
export const putItemGrade = { code: "grade", context: "item", method: "put" }

export const getPaymentStatus = { code: "status", context: "payment", method: "get" }
export const putPaymentStatus = { code: "status", context: "payment", method: "put" }


export const getLocationType = { code: "type", context: "location", method: "get" }
export const getLocationService = { code: "service", context: "location", method: "get" }
export const putLocationType = { code: "type", context: "location", method: "put" }
export const putLocationService = { code: "service", context: "location", method: "put" }


export const putTerritoryType = { code: "type", context: "territory", method: "put" }
export const getTerritoryType = { code: "type", context: "territory", method: "get" }

export const getMessageType = { code: "type", context: "message", method: "get" }
export const putMessageType = { code: "type", context: "message", method: "put" }


export const getUserGrade = { code: "grade", context: "user", method: "get" }
export const getUserRole = { code: "role", context: "user", method: "get" }
export const getUserDocument = { code: "document", context: "user", method: "get" }
export const getUserCountry = { code: "country", context: "user", method: "get" } //only by admin
export const putUserCountry = { code: "country", context: "user", method: "put" } //only by admin
export const putUserGrade = { code: "grade", context: "user", method: "put" }
export const putUserRole = { code: "role", context: "user", method: "put" }
export const putUserDocument = { code: "document", context: "user", method: "put" }



export const getTransitItineraryArrivalFlag = { code: "itineraryArrivalFlag", context: "transit", method: "get" }
export const getTransitDestination = { code: "destination", context: "transit", method: "get" }
export const getTransitCarrier = { code: "carrier", context: "transit", method: "get" }
export const getTransitStatus = { code: "status", context: "transit", method: "get" }
export const getTransitAgent = { code: "agent", context: "transit", method: "get" }
export const getTransitRoute = { code: "route", context: "transit", method: "get" }
export const putTransitAgent = { code: "agent", context: "transit", method: "put" }
export const putTransitRoute = { code: "agent", context: "transit", method: "put" }
export const putTransitStatus = { code: "status", context: "transit", method: "put" }
export const putTransitCarrier = { code: "carrier", context: "transit", method: "put" }
export const putTransitDestination = { code: "destination", context: "transit", method: "put" }
export const putTransitItineraryArrivalFlag = { code: "itineraryArrivalFlag", context: "transit", method: "put" }



export const getPurchaseDeliveryStatus = { code: "deliveryStatus", context: "purchase", method: "get" }
export const getPurchaseLocalAgent = { code: "localAgent", context: "purchase", method: "get" }
export const getPurchaseCourierCode = { code: "courierCode", context: "purchase", method: "get" }
export const putPurchaseCourierCode = { code: "courierCode", context: "purchase", method: "put" }
export const putPurchaseLocalAgent = { code: "localAgent", context: "purchase", method: "put" }
export const putPurchaseDeliveryStatus = { code: "deliveryStatus", context: "purchase", method: "put" }


export const getPurchaseType = { code: "type", context: "fulfillment", method: "get" }
export const getPurchaseStatus = { code: "status", context: "fulfillment", method: "get" }
export const getPurchaseHoldReason = { code: "holdReason", context: "fulfillment", method: "get" }
export const putPurchaseType = { code: "type", context: "fulfillment", method: "put" }
export const putPurchaseStatus = { code: "status", context: "fulfillment", method: "put" }
export const putPurchaseHoldReason = { code: "holdReason", context: "fulfillment", method: "put" }


export const getBillHoldType = { code: "type", context: "bill", method: "get" }
export const getBillStatus = { code: "status", context: "bill", method: "get" }
export const putBillHoldType = { code: "type", context: "bill", method: "put" }
export const putBillStatus = { code: "status", context: "bill", method: "put" }

export const putOtpStatus = { code: "status", context: "otp", method: "put" }
export const getOtpStatus = { code: "status", context: "otp", method: "get" }


export const getHomeValue = { code: "value", context: "home", method: "get" }
export const getHomeType = { code: "type", context: "home", method: "get" }

  // http://sallaka.com:3000/api/codes?code=body&context=dimension&method=get

export const getDimensionBody = { code: "body", context: "dimension", method: "get" };

