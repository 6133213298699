import React from 'react'

const Transits = () => {
  return (
    <div>
      Transits
    </div>
  )
}

export default Transits
