import { CircularProgress, Link } from '@mui/material'
import React, { useRef, useState, Fragment } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useVerifyOtpCodeMutation } from '../../../app/services/Api'
import { RESEND_OTP_CODE, SIGN_IN } from '../../../constants/routes'
import Button from '../../common/Button'
import Input from '../../common/Input'
import './styles.css'
import useMutationApi from '../../../app/hooks/useMutationApi'
import LoadingButton from '../../common/LoadingButton'
import { useLocation, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import AuthCode, { AuthCodeRef } from 'react-auth-code-input';
import { Dialog, Transition } from '@headlessui/react'
import { CheckIcon } from '@heroicons/react/24/outline'
import validCode from '../../../assets/images/confirmOtp.png'

const VerifyOtpForm = () => {
  const [open, setOpen] = React.useState(false)

  const [verifyOtpCode, { isLoading, error }] = useVerifyOtpCodeMutation()
  const { state } = useLocation()
  const { t } = useTranslation();
  const navigation = useNavigate()

  const { handleSubmit, control, formState: { errors } } = useForm({
    defaultValues: {
      otp: "",
      user: state?.user?.user
    }
  })

  const AuthInputRef = useRef(null);
  const [result, setResult] = useState();
  const handleOnChange = (res) => {
    // console.log('res :>> ', res);
    setResult(res);
  };

  const { resendOtpCodeByUser, verifyOtpCodeByUser, isLoadingverifyOtpCode, isLoadingResendOtpCode } = useMutationApi()
  const handleconfirmOtp = (data) => {
    verifyOtpCodeByUser(data, () => {
      setOpen(true);
      setTimeout(() => {
        setOpen(false);
        navigation(SIGN_IN);
      }, 3000);
    })
  }
  const handleResendOtpCode = () => resendOtpCodeByUser(state?.user)
  return (
    <form className='reg-form relative ' style={{ width: '100%' }} onSubmit={handleSubmit(handleconfirmOtp)} >
      {error && <span> {error?.data?.response?.message}</span>}
      <Controller
        control={control}
        rules={{
          required: true,
        }}
        render={({ field: { onChange, value } }) => (
          <AuthCode
            allowedCharacters='numeric'
            onChange={onChange}
            containerClassName="flex justify-center space-x-3 w-[100%]"
            inputClassName="max-w-[40px] md:max-w-[40px] rounded-md shadow focus:ring-2 focus:ring-[#D90429] focus:ring-offset-2 text-center"
          />
        )}
        name="otp"
      />
      {errors?.otp && <span className='text-red-500 my-3' >{t("otp_req")}</span>}


      {isLoadingverifyOtpCode ? <CircularProgress size={""} sx={{ color: "#D90429" }} /> :
        <Button
          title={"Verify code"}
          style={{ marginTop: 30, backgroundColor: "#D90429" }}
          type="submit"
        />
      }
      <div className="flex flex-row justify-center items-center w-full"  >
        <h5>{t("resend_otp_msg")}{" "}{isLoadingResendOtpCode ? <CircularProgress size={18} sx={{ color: "#D90429" }} /> : <span onClick={handleResendOtpCode} className='cursor-pointer' style={{ color: "#D90429" }} >{t("resend")}</span>}</h5>
      </div>

      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={setOpen}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
                  <div>
                    {/* <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-red-100">
                      <CheckIcon className="h-6 w-6 text-[#D90429]" aria-hidden="true" />
                    </div> */}
                    <img src={validCode} className=' w-40 h-40 object-contain object-center mx-auto  ' />

                    <div className="mt-3 text-center sm:mt-5">
                      <Dialog.Title as="h2" className="text-lg font-medium leading-6 text-gray-900">
                        Congratulations
                      </Dialog.Title>
                      <div className="mt-2">
                        <p className="text-md text-gray-500">
                          {t("wel_to_home")}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="mt-5 sm:mt-6">
                    <button
                      type="button"
                      className="inline-flex w-full justify-center rounded-md border border-transparent bg-[#D90429] px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-[#D9042970] focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:text-sm"
                      onClick={() => {
                        navigation(SIGN_IN);
                        setOpen(false)
                      }}
                    >
                      {t("done")}
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </form>
  )
}

export default VerifyOtpForm