const CryptoJS = require("crypto-js")
const password = 'l5JmP+G0/1zB%;r8B8?2?2pcqGcL^3:YoUsHoP2022$%4&&*()_++!@`@!`'
const tokenType = 'Bearer '
const keyAuth = 'ayeuioshp'
const validityToken = "expiredToken" //28 //30 min validity saved in milliseconds

export const crypted = (text) => CryptoJS.AES.encrypt(text, password)
export const decrypted = (text) => (CryptoJS.AES.decrypt(text, password)).toString(CryptoJS.enc.Utf8)
export const isRealValue = (obj) => obj && obj !== null && obj !== undefined
export const toString = (json) => JSON.stringify(json)
export const toJson = (string) => JSON.parse(string)
export const cryptKey = (key) => key
export const saveItem = (key, value) => localStorage.setItem(cryptKey(key), crypted(value))
export const getItem = (key) => !isRealValue(localStorage.getItem(key)) ? null : decrypted((localStorage.getItem(key))?.toString())
export const deleteItem = (key) => localStorage.removeItem(cryptKey(key))


export const setValidity = (time = 0) => saveItem(validityToken, time.toString())
const getValidity = () => parseInt(getItem(cryptKey(validityToken)) || 0)
export const tokenIsAfter28Min = () => { var min = Math.floor((Math.abs(new Date() - new Date(getValidity())) / 1000) / 60); return min >= 28 }

export const setAuthUser = (auth) => saveItem(keyAuth, toString(auth))
export const getAuth = () => {
  return toJson(getItem(cryptKey(keyAuth))) || null
}
export const deleteAuth = () => deleteItem(keyAuth)
export const updateAuth = (auth) => setAuthUser(auth)
export const getUser = () => getAuth()?.user
export const getId = () => getUser()?._id
export const getToken = () => getAuth()?.accessToken
export const getRefreshToken = () => getAuth()?.refreshToken
export const isLogged = () => isRealValue(getAuth())
export const authorization = () => tokenType + getToken()
export const refreshToken = () => getRefreshToken()
export const clearStorage = () => localStorage.clear()


export const compare = (a, b) => (a < b) ? -1 : (a > b) ? 1 : 0
export const compareEquals = (a, b) => (isRealValue(a) && isRealValue(b)) ? ((a === b) ? true : false) : false
export const providesList = (results, tagType) => results ? [...results.map((item) => ({ type: tagType, id: item?.key })), { type: tagType, id: 'LIST' }] : [{ type: tagType, id: 'LIST' }]
export const providesKeyList = (results, tagType) => results ? [{ type: tagType, id: 'LIST' }, ...results.map((key) => ({ type: tagType, id: key })),] : [{ type: tagType, id: 'LIST' }]
export const diffForHumanDate = (date) => new Date(date).toDateString()
export const diffLocalDate = (date) => new Date(date).toLocaleDateString()
export const timeDate = (date) => new Date(date).getMilliseconds()

export const serverHost = () => window.location.host
export const serverHostname = () => window.location.hostname
export const serverHref = () => window.location.href
export const serverOrigin = () => window.location.origin
export const serverPathname = () => window.location.pathname
export const serverPort = () => window.location.port
export const serverProtocol = () => window.location.protocol
export const serverSearch = () => window.location.search
export const capitalizeFirst = (string) => string.charAt(0).toUpperCase() + string.slice(1);
export const capitalize = (string) => string.toUpperCase()
export const getFakeName = (name) => { let worlds = name?.trim()?.split(" "); return worlds?.at(0) + " " + worlds?.at(1); }
export const setLanguage = (lang) => saveItem("lang", lang)
export const getLanguage = () => getItem("lang")
export const getStorage = (key) => !isRealValue(localStorage.getItem(key)) ? null : localStorage.getItem(key)
export const setStorage = (key, value) => localStorage.setItem(key, value)

export const getObjectAsSearchParameter = (jsonObject) => {
  let str = '', i = 0;
  if (isRealValue(jsonObject)) {
    for (const [key, value] of Object.entries(jsonObject)) {
      let param = `${key}=${value}`
      i === 0 ? str = `?${param}` : str += `&${param}`
      i++
    }
  }
  return str
}

export function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) return -1;
  if (b[orderBy] > a[orderBy]) return 1;
  return 0;
}

export function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

export function stableSort(array, comparator) {
  const stabilizedThis = array?.map((el, index) => [el, index]);
  stabilizedThis?.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis?.map((el) => el[0]);
}

var file = null
export function getFiles() { return file }
export function SetFiles(files) { file = files }

export function checkLinkInString(str) {
  const matches = str.match(/\b(?:http|https|ftp|file)?:\/\/(\S+)/gi)
  if (isRealValue(matches) && matches.length > 0) return { url: matches[0], in: true }
  return { in: false }
}

export const classNames = (...classes) => {
  return classes.filter(Boolean).join(' ');
}

export function getAttributes(attributes = []) {
  let results = [];
  attributes.map((attribute) => {
    let propertyKey = attribute.propertyKey;
    let propertyName = attribute.propertyName;
    let newValue = {
      propertyName: attribute.propertyName, // TODO delete this line after update page product
      valueName: attribute.valueName,
      valueKey: attribute.valueKey
    };
    if (results?.length === 0) {
      results = Array.of({ propertyKey, propertyName, values: [newValue] });
    }
    else {
      let exist = false;
      let results0 = results?.map(old => {
        if (old.propertyKey === propertyKey) {
          var values = old.values
          values.push(newValue)
          exist = true;
          return { ...old, values }
        }
        else return old
      });
      results = results0;
      if (!exist) results.push({ propertyKey, propertyName, values: [newValue] });
    }
    return null;
  })
  return results;
}

export function scroolWindowToTop() {
  window.scrollTo({ behavior: 'smooth', top: 0 })
  // console.log("enabled to top page =====  ..")
}

export function applySelectVariant(pid, vid, variants) {
  var selectableProperties = {}
  var otherVariants = variants.filter(v => checkIfConfiguratorHavePidAndVid(pid, vid, v.configurators))
  otherVariants.forEach(v => {
    v.configurators.forEach(c => {
      if (!isRealValue(selectableProperties[`p_${c.pid}`])) {
        selectableProperties[`p_${c.pid}`] = [`v_${c.vid}`]
      } else {
        if (!selectableProperties[`p_${c.pid}`].includes(`v_${c.vid}`)) {
          selectableProperties[`p_${c.pid}`] = [].concat(selectableProperties[`p_${c.pid}`], [`v_${c.vid}`])
        }
      }
    })
  })
  return selectableProperties
}
function checkIfConfiguratorHavePidAndVid(pid, vid, configs = []) {
  var valid = false
  configs.map(v => {
    if (v.pid === pid && v.vid === vid) {
      valid = true
      return
    }
  })
  return valid
}

// export const paternemail = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i