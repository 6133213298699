import React from "react";
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import './styles.css'

const CardComponent = (props) => {
  const { children, title } = props
  return(
    <div className="card-wrapper" > 
        <div className='card-header' >
             <h2 className="card-title" > { title } </h2>
             {/* <CancelOutlinedIcon className="card-icon" sx= {{ fontSize: 45 }} /> */}
        </div>
        <div className="card-content" >
          { children }
        </div>
    </div>
  ) 
  ;
};
export default CardComponent;