import React, { useState } from 'react'
import CreditCardItem from './CreditCardItem'

const UserAvailableCards = () => {
  const [Cards, setCards] = useState([
    {
      name: 'Ben Aflek Jr',
      number: '4545454545454545',
      cvc: '465',
      month: '4',
      year: '2026',
      brand: 'Vi'
    },
    {
      name: 'Ben Aflek Jr',
      number: '4545454545454545',
      cvc: '465',
      month: '4',
      year: '2026',
      brand: 'Vi'
    },
  ])
  return (
    <div className='border-[1px] shadow-sm rounded-md border-slate-300 bg-white py-3 ' >
        <CreditCardItem cards={Cards} />
    </div>
  )
}

export default UserAvailableCards