import React from 'react'
import { CircularProgress, Paper, Rating } from '@mui/material';
import './styles.css'
import { useNavigate } from 'react-router-dom';
import { STORE } from '../../../constants/routes';
import { handleRefresh, handleSelectedStore } from '../../../app/features/storeSlice';
import { HOST_API } from '../../../app/services';
import ActionButton from '../ActionButton';
import FavoriteBorderOutlinedIcon from '@mui/icons-material/FavoriteBorderOutlined';
import { dispatch } from '../../../app';
import { useSelector } from 'react-redux';
import { useFormatter } from '../../../app/hooks/useFormatter';
import defaultStore from '../../../assets/images/Closed_Stores-amico.svg'
import useMutationApi from '../../../app/hooks/useMutationApi';
import { useLocalDbHook } from '../../../app/localDatabase/useLocalDbHook';


const StoreItem = (props) => {
  const { store, active } = props
  const navigate = useNavigate()
  const { isLoadingBookmarkStores, handleBookmarkStore } = useMutationApi()
  const { bookmarkStores } = useSelector(state => state.bookmarkStore)
  const { isLogged } = useSelector((state) => state.auth);

  const { bookmarkStoresOff } = useLocalDbHook()

  const isFavorite = () => {
    const itemFav = isLogged ? bookmarkStores?.find((article) => article?.key === store?.key) : bookmarkStoresOff?.find(v => v.key === store?.key)
    if (itemFav) {
      return true;
    }
    return false;
  }

  const { display } = useFormatter()
  const handleAddFavoriteStore = () => handleBookmarkStore(store?.key)
  
  const handleGoToPageStore = () => {
    dispatch(handleRefresh())
    dispatch(handleSelectedStore(store))
    navigate(`${STORE}/${store.key}`)
  }

  const getStoreRating = (grade) => {
    switch (grade) {
      case "standard":
        return 1;
      case "silver":
        return 2;
      case "gold":
        return 3;
      case "platinum":
        return 4;

      default:
        return 1;
    }
  }

  return (
    <Paper className="h-[fit-content] max-w-[220px] p-4 ">
      <div className="row-flex-space">
        {isLoadingBookmarkStores ? (
          <CircularProgress sx={{ color: "#D90429" }} />
        ) : (
          <ActionButton
            activ={active || isFavorite()}
            inactiveIcon={
              <FavoriteBorderOutlinedIcon
                className="inactive-icon"
                sx={{ fontSize: 22, color: "#707070" }}
              />
            }
            activeIcon={
              <FavoriteBorderOutlinedIcon
                sx={{ fontSize: 22, color: "white" }}
              />
            }
            addBookmarkItem={handleAddFavoriteStore}
          />
        )}
      </div>
      <div className="img-wrapper pointer" onClick={handleGoToPageStore}>
        {store?.assets?.at(0)?.url != "" ?
          <img onContextMenu={(event) => { event.preventDefault();   }}
            className=" rounded-lg mx-auto  "
            style={{
              width: `${store?.assets?.at(0)?.dimensions?.w}`,
              height: `${store?.assets?.at(0)?.dimensions?.w}`,
            }}
            src={`${HOST_API + store?.assets?.at(0)?.url}`}
            alt="store logo"
          /> :
          <img onContextMenu={(event) => { event.preventDefault();   }}
            className=" rounded-lg mx-auto  "
            style={{
              width: 80,
              height: 80,
            }}
            src={defaultStore}
            alt="store logo"
          />
        }
      </div>
      <h2 className="text-lg font-medium text-gray-700 ">{display(store)}</h2>
      <div>
        <Rating name="read-only" max={4} value={getStoreRating(store?.grade)} readOnly />
        <br />
        <span>{store?.grade}</span>
      </div>
    </Paper>
  );
}

export default StoreItem;
