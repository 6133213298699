import { Container } from '@mui/material'
import React from 'react'
import './styles.css'
import { useParams, useSearchParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { handleHeaderType, handleTitleMobilePage } from '../../app/features/authSlice';
import { dispatch } from '../../app';
import ProductList from '../Products/ProductsList';
import { useCodeValuesQuery, useGetItemsByStoreQuery, useMenuOfStoreQuery, useStoreByKeyQuery } from '../../app/services/Api';
import { getStoreGrade, returnFilterWithoutLang } from '../../app/hooks';
import { isRealValue, scroolWindowToTop } from '../../utilities';

const StoresProducts = () => {
  const { lng } = useSelector(state => state.app)
  const [searchParams, setSearchParams] = useSearchParams();

  const params = useParams()
  const key = params?.key

  const page = searchParams.get('page') ? searchParams.get('get') : null
  var filterGet = {}
  for (let entry of searchParams.entries()) {
    if (isRealValue(entry[1])) {
      filterGet[entry[0]] = entry[1]
    }
  }
  filterGet['page'] = isRealValue(page) ? page : 1
  filterGet['lang'] = lng
  const [request, setRequest] = React.useState(returnFilterWithoutLang(filterGet))
  const [rangePrice, setRangePrice] = React.useState(
    {
      priceMin: filterGet?.priceMin ? filterGet?.priceMin : "",
      priceMax: filterGet?.priceMax ? filterGet?.priceMax : ""
    })


  const resetApplyFilter = () => {
    setRangePrice({ priceMin: "", priceMax: "" })
    setSearchParams({})
    setRequest({})
  }

  const setRequestParams = (obj) => {
    const { lang, ...r } = obj
    setSearchParams(r)
    setRequest(returnFilterWithoutLang(r))
  }

  const { isFetching: isLoadingS } = useStoreByKeyQuery(key, { refetchOnReconnect: true, refetchOnMountOrArgChange: true })
  const { isFetching: isLoadingC } = useGetItemsByStoreQuery(
    {
      key,
      params: { ...request }
    },
    { refetchOnReconnect: true, refetchOnMountOrArgChange: true }
  )
  const { isFetching: isLoadingM } = useMenuOfStoreQuery({ store: key }, { refetchOnReconnect: true, refetchOnMountOrArgChange: true })
  const { isFetching: isLoadingSG } = useCodeValuesQuery(getStoreGrade)

  React.useEffect(() => {
    scroolWindowToTop()
    dispatch(handleHeaderType({ isStore: false, header: true, checkout: false }))
    dispatch(handleTitleMobilePage("Store items"))
  }, [request, lng])

  return (
    <div>
      <Container maxWidth='l'>
        <div id='tab-content' className='row-flex' style={{ alignItems: 'flex-start', position: 'relative', marginTop: 10 }} >
          <ProductList
            params={request}
            setParams={setRequestParams}
            isLoading={isLoadingS || isLoadingC || isLoadingM || isLoadingSG}
            resetApplyFilter={resetApplyFilter}
            rangePrice={rangePrice}
            setRangePrice={setRangePrice}
          />
        </div>
      </Container>
    </div>
  )
}

export default StoresProducts