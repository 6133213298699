import { useSelector } from 'react-redux'
import {
    useAddCardsMutation,
    useAddContactUserMutation,
    useAddCreditCardClientMutation,
    useAddItemsHomesByForeignLogisticianMutation,
    useAddPurchaseToTransitByIdMutation,
    useBookmarkCardItemMutation,
    useBookmarkItemMutation,
    useBookmarkStoreMutation,
    useCapturePaymentIntentByControllerMutation,
    useChangeIdentityCniProfileByUserMutation,
    useChangeProfileByUserMutation,
    useChangeRoleUserMutation,
    useConfirmIntentionPaymentMutation,
    useConfirmRefundBySupervisorMutation,
    useConfirmResetPasswordMutation,
    useCreateAddressByUserMutation,
    useCreateBankAccountMutation,
    useCreateDepositByCustomerMutation,
    useCreateDepositByOperatorMutation,
    useCreateFulfillmentMutation,
    useCreateIntentionPaymentMutation,
    useCreateOrderMutation,
    useCreatePaymentByCustomerMutation,
    useCreatePurchaseMutation,
    useCreateRefundByControllerMutation,
    useCreateTransferByCustomerMutation,
    useCreateWithdrawalByCustomerMutation,
    useDeleteAccountBankByIdMutation,
    useDeleteAccountInAppByUserMutation,
    useDeleteAddressByUserMutation,
    useDeleteBookmarkCardItemMutation,
    useDeleteContactUserMutation,
    useDeleteLocationByAdminMutation,
    useDeliverFulfillmentMutation,
    useLockContactUserMutation,
    usePaymentForexOrderByUserWithCardMutation,
    usePutLocationInMapGoogleMutation,
    usePutSizeOfStoreByCategoryLevel3ByLogForeignMutation,
    useReceiveFulfillmentMutation,
    useRejetTransactionToInitialStateBySupervisorMutation,
    useResendOtpCodeMutation,
    useResetPasswordMutation,
    useSearchItemsByKeywordMutation,
    useSearchStoresByKeywordMutation,
    useSendOtpCodeMutation,
    useSignInMutation,
    useSignOutMutation,
    useSignUpUserMutation,
    useUnlockContactUserMutation,
    useUpdateAddressByUserMutation,
    useUpdateDepositTransactionByControllerMutation,
    useUpdateFulfillmentLineMutation,
    useUpdateInfoUserByAdminMutation,
    useUpdateInfoUserByEmployeeMutation,
    useUpdateItemsHomesByForeignLogisticianMutation,
    useUpdateOrderlineByLogLocMutation,
    useUpdateOtherTransitShipmentsInfosMutation,
    useUpdateStatusFulfillmentMutation,
    useUpdateWithdrawalTransactionByOperatorMutation,
    useVerifyOtpCodeMutation,

} from '../services/Api'
import { useNotifications } from './useNotifications'
import { HOME, ACKNOWLEDGEMENT, VERIFY_OTP_CODE, SIGN_IN, CONFIRM_PASSWORD_RESET, CART } from '../../constants/routes'
import { deleteAuth, isRealValue, toJson } from '../../utilities'
import useCustomStripe from './useCustomStripe'
import { dispatch } from '../..'
import { handleAmountToPay } from '../features/StripeSlice'
import { handleCounter, handleMainImg, handleSeaOrAir } from '../features/itemSlice'
import { handleUsersByGrade, handleUsersByUsername } from '../features/usersSlice'
import { useAuthentication } from './useAuthentication'
import { useNavigate } from 'react-router-dom'
import { handleResetCartItemsSelected } from '../features/bookmarkCardItemsSlice'
import { useLocalDbHook, useRedirection } from '../localDatabase/useLocalDbHook'

export default function useMutationApi() {
    const { lng } = useSelector(state => state.app)
    const { isLogged } = useSelector(state => state.auth)
    const { cartToAdd } = useSelector(state => state?.item);
    const { order } = useSelector(state => state.bookmarkCard);
    const { card } = useSelector(state => state.stripe)
    const navigation = useNavigate()
    const { dangerNotification, successNotification } = useNotifications()
    const { availableAuthentication } = useAuthentication()
    const { getReturnLinkForAfterLogin, deleteReturnLinkForAfterLogin } = useRedirection()

    const response = (r) => {
        console.log("response api", r)
        if (r?.error === false) {
            if (isRealValue(r?.data?.translation))
                successNotification(r?.translation[lng])
            else
                successNotification(r?.message)
        }

    }
    const error = (e) => {
        console.error("Error api", e)
        // if (e?.error === true) {
        //     if (isRealValue(e?.data?.translation))
        //         dangerNotification(e?.translation[lng])
        //     else
        //         dangerNotification(e?.message || e?.error)
        // }
        if (e?.error === true) dangerNotification(e?.translation[lng])
        else {
            if (isRealValue(e?.data?.translation))
                dangerNotification(e?.data?.translation[lng])
            console.error('Other error', e)
        }
    }
    /**
     * 
     * 
     * AUTHENTICATION PROCESSORS
     */
    const [resetPassword, { isLoading: isLoadingResetPassword }] = useResetPasswordMutation()
    const [confirmResetPassword, { isLoading: isLoadingConfirmPassword }] = useConfirmResetPasswordMutation()
    const [signIn, { isLoading: isLoadingLogin, error: errorLogin }] = useSignInMutation();
    const [signOut, { isLoading: isLoadingLogout }] = useSignOutMutation();
    const [signUpUser, { isLoading: isLoadingSignUp }] = useSignUpUserMutation()
    const [sendOtpCode, { isLoading: isLoadingsendOtpCode }] = useSendOtpCodeMutation()
    const [resendOtpCode, { isLoading: isLoadingResendOtpCode }] = useResendOtpCodeMutation()
    const [verifyOtpCode, { isLoading: isLoadingverifyOtpCode }] = useVerifyOtpCodeMutation()

    const loginByUser = (body) => signIn(body)
        .unwrap()
        .then(async (r) => {
            response(r);
            if (r?.error === false) {
                availableAuthentication();
                const linked = await getReturnLinkForAfterLogin()
                if (isRealValue(linked)) {
                    deleteReturnLinkForAfterLogin(linked.linkPage);
                    navigation(linked.linkPage, toJson(linked.stringifyStateJson))
                }
                else {
                    // window.location.href = `${HOME}`;
                    navigation(`${HOME}`)
                }
            }
        })
        .catch(e => error(e))

    const resetPasswordByUser = (body) => resetPassword(body).unwrap().then(r => {
        response(r)
        const str = r?.message
        const words = str.split('/');
        const token = words.pop()
        const userId = words.pop()
        navigation(`${CONFIRM_PASSWORD_RESET}/${userId}/${token}`)
    }).catch(e => error(e))
    const logoutByUser = () => signOut().unwrap().then(r => response(r)).catch(e => error(e))
    const confirmResetPasswordByUser = (userId, token, body) => {
        confirmResetPassword({ userId, token, body }).unwrap().then(r => { response(r); navigation(SIGN_IN); }).catch(e => {
            if (e?.originalStatus === 200) {
                successNotification(e?.data); navigation(SIGN_IN);
            }
            if (e?.originalStatus === 400) {
                dangerNotification(e?.data);
            }
            else error(e)
        })
    }
    const sendOtpCodeByUser = (body) => sendOtpCode(body).unwrap().then(r => { response(r); navigation(VERIFY_OTP_CODE, { state: { user: body } }); }).catch(e => error(e))
    const signupByUser = (body) => signUpUser(body).unwrap().then(r => { response(r); sendOtpCodeByUser({ ...r?.data, phone: "+".concat(r?.data?.phone) }) }).catch(e => error(e))
    const resendOtpCodeByUser = (body) => resendOtpCode({ ...body, phone: "+".concat(body?.phone) }).unwrap().then(r => { response(r); }).catch(e => error(e))
    const verifyOtpCodeByUser = (body, f = () => { }) => verifyOtpCode(body).unwrap().then(r => {
        f();
        response(r);
    }).catch(e => error(e))


    const { handleNextActionWeb } = useCustomStripe()
    const [createPaymentByCustomer, { isLoading: isPaymentOrderByUser }] = useCreatePaymentByCustomerMutation();
    const [createDepositByOperator, { isLoading: isDepositByOperator }] = useCreateDepositByOperatorMutation()
    const [createOrder, { isLoading: isCreateOrder }] = useCreateOrderMutation()
    const [createDepositByCustomer, { isLoading: isDepositByCustomer }] = useCreateDepositByCustomerMutation();
    const [createTransferByCustomer, { isLoading: isTransferByCustomer }] = useCreateTransferByCustomerMutation();
    const [createWithdrawalByCustomer, { isLoading: isWithdrawalByCustomer }] = useCreateWithdrawalByCustomerMutation();
    const [createIntentionPayment, { isLoading: isCreateIntentionPayment }] = useCreateIntentionPaymentMutation()
    const [confirmIntentionPayment, { isLoading: isConfirmIntentionPayment }] = useConfirmIntentionPaymentMutation()
    const [addCard, { isLoading: isAddCreditCardByUser }] = useAddCardsMutation()
    const [updateWithdrawalTransactionByOperator, { isLoading: isLoadingUpdateWithdrawalByOperator }] = useUpdateWithdrawalTransactionByOperatorMutation()
    const [updateDepositTransactionByController, { isLoading: isLoadingUpdateDepositByController }] = useUpdateDepositTransactionByControllerMutation()
    const [rejetTransactionToInitialStateBySupervisor, { isLoading: isLoadingRejectInitialBySupervisor }] = useRejetTransactionToInitialStateBySupervisorMutation()
    const [confirmRefundBySupervisor, { isLoading: isLoadingConfirmRefundBySupervisor }] = useConfirmRefundBySupervisorMutation()
    const [createRefundByController, { isLoading: isLoadingCreateRefundByController }] = useCreateRefundByControllerMutation()
    const [paymentForexOrderByUserWithCard, { isLoading: isLoadingPaymentForex }] = usePaymentForexOrderByUserWithCardMutation()
    const [capturePaymentIntentByController, { isLoading: isLoadingCapturePaymentIntent }] = useCapturePaymentIntentByControllerMutation()
    const [addCreditCardClient, { isLoading: isLoadingAddCreditCard }] = useAddCreditCardClientMutation()

    const addUserToClientStripe = () => addCreditCardClient().unwrap().then(r => { response(r); return r }).catch(e => error(e))
    const updateWithdrawalByOperator = (transactionId, body) => updateWithdrawalTransactionByOperator({ id: transactionId, body }).unwrap().then((r) => response(r)).catch((e) => error(e))
    const updateDepositByController = (transactionId, body) => updateDepositTransactionByController({ id: transactionId, body }).unwrap().then((r) => response(r)).catch((e) => error(e))
    const rejectToInitialStateBySupervisor = (transactionId, body) => rejetTransactionToInitialStateBySupervisor({ id: transactionId, body }).unwrap().then((r) => response(r)).catch((e) => error(e))
    const refundBySupervisor = (transactionId, body) => confirmRefundBySupervisor({ transactionId, body }).unwrap().then((r) => response(r)).catch((e) => error(e))
    const refundByController = (body) => createRefundByController(body).unwrap().then((r) => response(r)).catch((e) => error(e))
    const addCreditCardByUser = (body) => addCard(body).unwrap().then(r => response(r)).catch(e => { error(e) })
    const payOrderByUser = (orderId, body) => createPaymentByCustomer({ body, orderId }).unwrap().then(r => { response(r); /*navigation(CART)*/ navigation(ACKNOWLEDGEMENT, { state: { wallet: { ...r, ...body, orderId: orderId }, translation: r?.translation } }); }).catch(e => error(e))
    const depositByOperator = (body) => createDepositByOperator(body).unwrap().then(r => response(r)).catch(e => error(e))
    const buildOrderAndPayByUser = (bodyPayment) => createOrder(order).unwrap().then(r => { response(r); payOrderByUser(r?.data?._id, bodyPayment); }).catch(e => error(e))
    const buildOrderAndPayWithCardByUser = () => createOrder(order).unwrap().then(r => { response(r); payWithCreditCard(r?.data?.totalAmount, r?.data?._id, card); }).catch(e => error(e))
    const depositByCustomer = (body) => createDepositByCustomer(body).unwrap().then(r => response(r)).catch(e => error(e))
    const transferByCustomer = (body) => createTransferByCustomer(body).unwrap().then(r => response(r)).catch(e => error(e))
    const withdrawalByCustomer = (body) => createWithdrawalByCustomer(body).unwrap().then(r => response(r)).catch(e => error(e))
    const buildOrderByUser = (location) => createOrder(order).unwrap().then(r => { response(r); navigation(ACKNOWLEDGEMENT, { state: { order: r?.data, location, translation: r?.translation } }); }).catch(e => error(e))
    const capturePaymentIntent = (amount, paymentIntent) => capturePaymentIntentByController({ paymentIntent, amount }).unwrap().then((r) => response(r)).catch((e) => error(e))
    const buildOrderAndPayForexByUser = (cardId, body) => createOrder(order).unwrap().then(r => { response(r); paymentForex(r?.data?._id, cardId, body); }).catch(e => error(e))
    const payWithCreditCard = (amount, orderId, cardId) => { createIntentionPayment({ body: { amount }, orderId, cardId }).unwrap().then((rs) => { confirmIntentionPaymentWithCard(rs) }).catch((e) => error(e)) }
    const paymentForex = (orderId, cardId, body) => paymentForexOrderByUserWithCard({ orderId, cardId, body }).unwrap().then((rs) => confirmIntentionPaymentWithCard(rs)).catch((e) => error(e))
    const confirmIntentionPaymentWithCard = (rs) => {
        if (rs.error === false) {
            confirmIntentionPayment({ paymentIntent: rs?.data?.id }).unwrap().then(cip => {
                response(cip)
                if (isRealValue(cip?.data?.next_action) && cip?.data?.status === "requires_action") {
                    handleNextActionWeb(cip?.data?.client_secret).then(ac => {
                        if (!isRealValue(ac?.error)) {
                            confirmIntentionPayment({ paymentIntent: rs?.data?.id }).unwrap().then(acip => {
                                if (acip.error === false)
                                    navigation(ACKNOWLEDGEMENT, { state: { payment: acip?.data, translation: rs?.translation } })
                            }).catch(err => {
                                error(err)
                                if (err?.status === 400)
                                    navigation(ACKNOWLEDGEMENT, { state: { payment: err?.data, error: true, translation: rs?.translation } })
                            })
                        }
                        else
                            navigation(ACKNOWLEDGEMENT, { state: { payment: ac?.error, error: true, translation: rs?.translation } })
                    }).catch(e => error(e))
                }
                else {
                    if (cip.error === false)
                        navigation(ACKNOWLEDGEMENT, { state: { payment: cip?.data, translation: rs?.translation } })
                    else
                        navigation(ACKNOWLEDGEMENT, { state: { payment: cip?.data, error: true, translation: rs?.translation } })
                }
            }).catch(er => error(er))
        }
    }
    /**
     * 
     * 
     * CONTACTS, CHATS
     */
    const [lockContactUser, { isLoading: isLoadingLockContact }] = useLockContactUserMutation()
    const [unlockContactUser, { isLoading: isLoadingUnlockContact }] = useUnlockContactUserMutation()
    const [deleteContactUser, { isLoading: isLoadingDeleteContact }] = useDeleteContactUserMutation()
    const [addContactUser, { isLoading: isLoadingAddContact }] = useAddContactUserMutation()
    const lockContactByUser = (blockage) => lockContactUser({ blockage }).unwrap().then(r => response(r)).catch(e => error(e))
    const unLockContactByUser = (blockage) => unlockContactUser({ blockage }).unwrap().then(r => response(r)).catch(e => error(e))
    const deleteContactByUser = (contact) => deleteContactUser({ contact }).unwrap().then(r => response(r)).catch(e => error(e))
    const addContactByUser = (contact) => addContactUser({ contact }).unwrap().then(r => response(r)).catch(e => error(e))
    /**
     * 
     * 
     * ITEMS
    */
    const { addOrRemoveBookmarkItem, addOrRemoveBookmarkStores, addBookmarkCart, removeBookmarkCart } = useLocalDbHook()

    const [bookmarkItem, { isLoading: isLoadingFavorite }] = useBookmarkItemMutation()
    const [bookmarkCardItem, { isLoading: isLoadingBookmarkCardItem }] = useBookmarkCardItemMutation();
    const [deleteBookmarkCardItem, { isLoading: loadingDeleteBookmarkCard }] = useDeleteBookmarkCardItemMutation();
    const [searchItemsByKeyword, { isLoading: isLoadingSearchItems, isSuccess: isSuccessSearchItems }] = useSearchItemsByKeywordMutation()
    const handleAddBookmarkItem = (key) => {
        if (isLogged) {
            bookmarkItem({ item: key }).unwrap().then(r => response(r)).catch(e => error(e))
        }
        else {
            addOrRemoveBookmarkItem(key)
        }

    }
    const searchItems = (keyword) => searchItemsByKeyword({ keyword })
    const deleteToCartItemsByUser = (data = [], finished = () => { }) => {
        if (isLogged) {
            deleteBookmarkCardItem(data).unwrap().then(r => { response(r); finished(); }).catch(e => error(e))
        } else {
            removeBookmarkCart(data)
            finished()
        }
    }
    const handleAddToFavoriteCart = (cartToAdd) => {
        if (isLogged) {
            bookmarkCardItem(cartToAdd).unwrap().then(r => response(r)).catch(e => error(e))
        }
        else {
            addBookmarkCart(cartToAdd)
        }
    }
    /**
     * 
     * 
     * BANKS
     */
    const [createBankAccount, { isLoading: isLoadingCreateBankAccount }] = useCreateBankAccountMutation()
    const [deleteAccountBankById, { isLoading: isLoadingDeleteBankAccount }] = useDeleteAccountBankByIdMutation()
    const createBankAccountByAdmin = (body) => createBankAccount(body).unwrap().then(r => response(r)).catch(e => error(e))
    const deleteAccountBankByIdByAdmin = (id) => deleteAccountBankById(id).unwrap().then(r => response(r)).catch(e => error(e))
    /**
     * 
     * 
     * LOCATIONS
     */
    const [deleteLocationByAdmin, { isLoading: isLoadingDeleteLocation }] = useDeleteLocationByAdminMutation()
    const [putLocationInMapGoogle, { isLoading: isLoadingLocationMap }] = usePutLocationInMapGoogleMutation()
    const deleteLocationByIdByAdmin = (id) => deleteLocationByAdmin(id).unwrap().then(r => response(r)).catch(e => error(e))
    const putLocationInMapGoogleByUser = (id) => putLocationInMapGoogle(id).unwrap().then(r => response(r)).catch(e => error(e))
    /**
     * 
     * 
     * USERS
     */
    const [changeProfileByUser, { isLoadingChangeProfile }] = useChangeProfileByUserMutation();
    const [changeIdentityCniProfileByUser, { isLoading: isLoadingChangeIdentityCniProfile }] = useChangeIdentityCniProfileByUserMutation()
    const [updateInfoUserByAdmin, { isLoading: isLoadingUpdateInfoUserByAdmin }] = useUpdateInfoUserByAdminMutation();
    const [updateInfoUserByEmployee, { isLoading: isLoadingUpdateInfoUserByStaff }] = useUpdateInfoUserByEmployeeMutation();
    const [changeRoleUser, { isLoading: isLoadingChangeRoleUserByStaff }] = useChangeRoleUserMutation()
    const [deleteAccountInAppByUser, { isLoading: isLoadingDeleteAccountInAppByUser }] = useDeleteAccountInAppByUserMutation()
    const changeImageProfileByUser = body => changeProfileByUser(body).unwrap().then(r => response(r)).catch(e => error(e))
    const changeImageIdentityCnByUser = body => changeIdentityCniProfileByUser(body).unwrap().then(r => response(r)).catch(e => error(e))
    const updateUserByAdmin = (body, user) => updateInfoUserByAdmin({ body, user }).unwrap().then(r => response(r)).catch(e => error(e))
    const updateUserByStaff = (body, user) => updateInfoUserByEmployee({ body, user }).unwrap().then(r => response(r)).catch(e => error(e))
    const setUserAsCustomerByStaff = (userEmailPhone) => changeRoleUser({ userEmailPhone }).unwrap().then(r => response(r)).catch(e => error(e))
    const deleteAccountByUser = () => deleteAccountInAppByUser().unwrap().then(r => { logoutByUser(); response(r); }).catch(e => error(e))
    /**
     *
     * 
     * PURCHASE SHIPMENTS
    */
    const [createPurchase, { isLoading: isLoadingCreatePurchaseShipment }] = useCreatePurchaseMutation()
    const createPurchaseShipmentLogFg = (body) => createPurchase(body).unwrap().then(r => response(r)).catch(e => error(e))
    /**
     * 
     * 
     * TRANSIT SHIPMENT
     */
    const [updateOtherTransitShipmentsInfos, { isLoading: isLoadingUpdateInfoTransit }] = useUpdateOtherTransitShipmentsInfosMutation()
    const [addPurchaseToTransitById, { isLoading: isLoadingAddPurchaseToTransit }] = useAddPurchaseToTransitByIdMutation()
    const updateInfoTransitByRemoteLogistician = (id, body) => updateOtherTransitShipmentsInfos({ id, body }).unwrap().then(r => response(r)).catch(e => error(e))
    const addPurchaseToTransitByRemoteLogistician = (id, body) => addPurchaseToTransitById({ id, body }).unwrap().then(r => response(r)).catch(e => error(e))
    /**
     * 
     * 
     * FULFILLMENTS
     */
    const [updateStatusFulfillment, { isLoading: isLoadingUpdateStatusFulfillment }] = useUpdateStatusFulfillmentMutation()
    const [createFulfillment, { isLoading: isLoadingCreateFulfillment }] = useCreateFulfillmentMutation()
    const [updateOrderlineByLogLoc, { isLoading: isLoadingUpdateOrderlineByLogLoc }] = useUpdateOrderlineByLogLocMutation()
    const [updateFulfillmentLine, { isLoading: isLoadingUpdateFulfillmentLine }] = useUpdateFulfillmentLineMutation()
    const [receiveFulfillment, { isLoading: isLoadingReceiveFulfillment }] = useReceiveFulfillmentMutation()
    const [deliverFulfillment, { isLoading: isLoadingDeliverFulfillment }] = useDeliverFulfillmentMutation()
    const updateFulfillmentStatusByLogLoc = (id, body) => updateStatusFulfillment({ id, body }).unwrap().then(r => response(r)).catch(e => error(e))
    const updateOrderlineByLogisticianLoc = (id, body) => updateOrderlineByLogLoc({ id, body }).unwrap().then(r => response(r)).catch(e => error(e))
    const createFulfillmentByLogFg = () => createFulfillment().unwrap().then(r => response(r)).catch(e => error(e))
    const updateFulfillmentLineByLogLoc = (id, body) => updateFulfillmentLine({ id, body }).unwrap().then(r => response(r)).catch(e => error(e))
    const receiveFulfillmentByLogLocOperator = (id, body) => receiveFulfillment({ id, body }).unwrap().then(r => response(r)).catch(e => error(e))
    const deliverFulfillmentByLogLocOperator = (id, body) => deliverFulfillment({ id, body }).unwrap().then(r => response(r)).catch(e => error(e))
    /**
     * 
     * 
     * STORES
     */
    const [bookmarkStore, { isLoading: isLoadingBookmarkStores }] = useBookmarkStoreMutation()
    const [putSizeOfStoreByCategoryLevel3ByLogForeign, { isLoading: isLoadingUpdateSizeStore }] = usePutSizeOfStoreByCategoryLevel3ByLogForeignMutation()
    const handleBookmarkStore = (key) => {
        if (isLogged) {
            bookmarkStore({ store: key }).unwrap().then(r => response(r)).catch(e => error(e))
        }
        else {
            addOrRemoveBookmarkStores(key)
        }
    }

    const updateSizeCategoryStoreByLogistician = (storeKey, categoryKey, body, goBack = () => { }) => putSizeOfStoreByCategoryLevel3ByLogForeign({ storeKey, categoryKey, body }).unwrap().then(r => { response(r); goBack(); }).catch(e => error(e))
    /**
     * 
     * 
     * ADDRESSES
     */
    const [createAddressByUser, { isLoading: isLoadingCreateAddress }] = useCreateAddressByUserMutation();
    const [deleteAddressByUser, { isLoading: isLoadingDeleteAddress }] = useDeleteAddressByUserMutation();
    const [updateAddressByUser, { isLoading: isLoadingUpdateAddress }] = useUpdateAddressByUserMutation();
    const createShippingAddressByUser = (data) => createAddressByUser(data).unwrap().then(r => response(r)).catch(e => error(e))
    const deleteShippingAddressByUser = (id) => deleteAddressByUser(id).unwrap().then(r => response(r)).catch(e => error(e))
    const updateShippingAddressByUser = (id, body) => updateAddressByUser({ id, body }).unwrap().then(r => response(r)).catch(e => error(e))
    /**
     * 
     *
     * Dispatch action for store
     */
    const handleAmountToPayXaf = (amount) => dispatch(handleAmountToPay(parseInt(amount, 10)))
    const handleDeliveryMethod = (index) => dispatch(handleSeaOrAir(index))
    const handleMainImage = (image) => dispatch(handleMainImg(image?.xlarge))
    const setCounter = op => { dispatch(handleCounter(op)); }
    const incrementCounter = () => setCounter('add')
    const decrementCounter = () => setCounter('rm')
    const handleByGrade = (grade) => dispatch(handleUsersByGrade(grade?.value))
    const handleOnChangeUsername = (v) => dispatch(handleUsersByUsername(v))


    const [searchStoresByKeyword, { isLoading: isLoadingSearchStoresByKeyword }] = useSearchStoresByKeywordMutation()
    const searchStores = (keyword) => searchStoresByKeyword({ keyword }).unwrap().then(r => r?.data).catch((e) => { console.error(e); return [] })


    //ItemsHome
    const [addItemsHomesByForeignLogistician, { isLoading: isLoadingAddItemsHomesByForeignLogistician }] = useAddItemsHomesByForeignLogisticianMutation()
    const [updateItemsHomesByForeignLogistician, { isLoading: isLoadingUpdateItemsHomesByForeignLogistician }] = useUpdateItemsHomesByForeignLogisticianMutation()

    /**
     * 
     * @param {*} body 
     * 
    //  field     required                    type        default     values                          comments
    //  type      true                        string                  selection, category             values from GET on code: type, context: home
    //  code      true if type = selection    string                  sales, new, brand, ...          values from GET on code: value, context: home
    //  itemKey   true 
     * @returns 
     */
    const addItemsHome = (body, closeModal = () => { }) => addItemsHomesByForeignLogistician(body).unwrap().then(r => { closeModal(); response(r) }).catch(e => error(e))
    const updateItemsHome = (body, code, closeModal = () => { }) => updateItemsHomesByForeignLogistician({ body, code }).unwrap().then(r => { closeModal(); response(r); }).catch(e => error(e))

    return {

        addItemsHome, isLoadingAddItemsHomesByForeignLogistician,
        updateItemsHome, isLoadingUpdateItemsHomesByForeignLogistician,

        deleteAccountByUser, isLoadingDeleteAccountInAppByUser,
        //SHIPPING ADDRESSES
        createShippingAddressByUser, isLoadingCreateAddress,
        deleteShippingAddressByUser, isLoadingDeleteAddress,
        updateShippingAddressByUser, isLoadingUpdateAddress,
        //STORES
        handleBookmarkStore, isLoadingBookmarkStores,
        searchStores, isLoadingSearchStoresByKeyword,
        updateSizeCategoryStoreByLogistician, isLoadingUpdateSizeStore,
        //AUTHENTICATION PROCESSORS
        signupByUser, isLoadingSignUp,
        loginByUser, isLoadingLogin, errorLogin,
        resetPasswordByUser, isLoadingResetPassword,
        confirmResetPasswordByUser, isLoadingConfirmPassword,
        logoutByUser, isLoadingLogout,
        resendOtpCodeByUser, verifyOtpCodeByUser, isLoadingverifyOtpCode, isLoadingResendOtpCode, isLoadingsendOtpCode,
        //USERS
        changeImageProfileByUser, isLoadingChangeProfile,
        changeImageIdentityCnByUser, isLoadingChangeIdentityCniProfile,
        //ITEMS
        handleAddToFavoriteCart, isLoadingBookmarkCardItem,
        deleteToCartItemsByUser, loadingDeleteBookmarkCard,
        deleteContactByUser, isLoadingDeleteContact,
        lockContactByUser, isLoadingLockContact,
        unLockContactByUser, isLoadingUnlockContact,
        addContactByUser, isLoadingAddContact,
        searchItems, isLoadingSearchItems, isSuccessSearchItems,
        isLoadingFavorite,
        handleAddBookmarkItem,
        // PAYMENTS AND TRANSACTIONS ACTIONS
        addUserToClientStripe, isLoadingAddCreditCard,

        payOrderByUser, isPaymentOrderByUser,
        depositByOperator, isDepositByOperator,
        buildOrderAndPayByUser, buildOrderByUser, isCreateOrder,
        depositByCustomer, isDepositByCustomer,
        transferByCustomer, isTransferByCustomer,
        withdrawalByCustomer, isWithdrawalByCustomer,
        payWithCreditCard, buildOrderAndPayWithCardByUser, isCreateIntentionPayment, isConfirmIntentionPayment,
        addCreditCardByUser, isAddCreditCardByUser,
        updateWithdrawalByOperator, isLoadingUpdateWithdrawalByOperator,
        updateDepositByController, isLoadingUpdateDepositByController,
        rejectToInitialStateBySupervisor, isLoadingRejectInitialBySupervisor,
        refundByController, isLoadingCreateRefundByController,
        refundBySupervisor, isLoadingConfirmRefundBySupervisor,
        paymentForex, buildOrderAndPayForexByUser, isLoadingPaymentForex,
        capturePaymentIntent, isLoadingCapturePaymentIntent,
        updateUserByAdmin, updateUserByStaff, setUserAsCustomerByStaff, isLoadingUpdateInfoUserByAdmin, isLoadingUpdateInfoUserByStaff, isLoadingChangeRoleUserByStaff,
        //BANKS
        createBankAccountByAdmin, isLoadingCreateBankAccount,
        deleteAccountBankByIdByAdmin, isLoadingDeleteBankAccount,

        deleteLocationByIdByAdmin, isLoadingDeleteLocation,
        putLocationInMapGoogleByUser, isLoadingLocationMap,

        createPurchaseShipmentLogFg, isLoadingCreatePurchaseShipment,
        updateInfoTransitByRemoteLogistician, isLoadingUpdateInfoTransit,
        addPurchaseToTransitByRemoteLogistician, isLoadingAddPurchaseToTransit,

        updateFulfillmentStatusByLogLoc, isLoadingUpdateStatusFulfillment,
        deliverFulfillmentByLogLocOperator, isLoadingDeliverFulfillment,
        receiveFulfillmentByLogLocOperator, isLoadingReceiveFulfillment,
        updateFulfillmentLineByLogLoc, isLoadingUpdateFulfillmentLine,
        createFulfillmentByLogFg, isLoadingCreateFulfillment,
        updateOrderlineByLogisticianLoc, isLoadingUpdateOrderlineByLogLoc,

        //Actions stores
        handleAmountToPayXaf,
        handleDeliveryMethod,
        handleMainImage,
        handleByGrade,
        handleOnChangeUsername,
        decrementCounter,
        incrementCounter,
    }
}