import { Grid, List, ListItem, ListItemText, Menu, MenuItem, Typography, useMediaQuery, useTheme } from '@mui/material'
import React from 'react'
import { useSelector } from 'react-redux';
import { dispatch } from '../../../app';
import { handleSelectedIndex, handleValue } from '../../../app/features/ordersSlice';
import { useGetOrderByKeyQuery } from '../../../app/services/Api';

const TabHeader = () => {
    const { data: orderStatus } = useGetOrderByKeyQuery()
    const { selectedIndex } = useSelector(state => state.orders)

    const theme = useTheme()
    const matches = useMediaQuery(theme.breakpoints.down('md'))
    const handleChange = (value) => dispatch(handleValue(value))

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClickListItem = (e, index, status) => {
        dispatch(handleSelectedIndex(index))
        dispatch(handleValue(status))
        // setAnchorEl(e.currentTarget);
    };

    // const handleMenuItemClick = (event, index) => setAnchorEl(null);

    const handleClose = () => {
        setAnchorEl(null);
    };
    return (
        <React.Fragment>
            {
                matches ?
                    <>
                        <List
                            component="nav"
                            sx={{ bgcolor: 'background.paper' }}
                        >
                            <ListItem
                                button
                                id="lock-button"
                                aria-haspopup="listbox"
                                aria-controls="lock-menu"
                                aria-label="Select Filter Type"
                                aria-expanded={open ? 'true' : undefined}
                                // onClick={(e) => handleMenuItemClick(e)}
                            >
                                <ListItemText
                                    primary="Select Filter Type"
                                    secondary={orderStatus[selectedIndex]}
                                />
                            </ListItem>
                        </List>
                        <Menu
                            id="lock-menu"
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleClose}
                            MenuListProps={{
                                'aria-labelledby': 'lock-button',
                                role: 'listbox',
                            }}
                        >
                            {orderStatus?.map((status, index) => (
                                <MenuItem
                                    key={status}
                                    selected={index === selectedIndex}
                                    // onClick={(event) => handleClickListItem(event, index+1, status)}
                                >
                                    {status}
                                </MenuItem>
                            ))}
                        </Menu>
                    </> :
                    <Grid container spacing={0} my={2} bgcolor={'#F9F9F9'} borderRadius={2} padding={2} alignItems={'flex-start'} justifyContent={'space-between'} >
                        <Grid item borderRight={1} mb={1} borderColor={'#707070'} className='link' >
                            <Typography  onClick={()=> handleChange("All Status")} mx={3} variant='h6' fontSize={15} color={'#333333'} >All</Typography>
                        </Grid>
                        <Grid container item borderRight={1} mb={1} flexDirection={"row"} borderColor={'#707070'} className='link'  alignItems={"center"} >
                        {
                            orderStatus?.map((status, index) => (
                                <Typography  
                                    onClick={(e) =>handleClickListItem(e, index+1, status)}
                                    // sx={{ background:"red"}} 
                                    mx={1} variant='h6' fontSize={15} color={'#333333'} key={index} 
                                >
                                    {status}
                                </Typography> 
                            ))
                        }
                        </Grid>
                    </Grid>
            }
        </React.Fragment>
    )
}

export default TabHeader