import React from 'react';

import { useSelector } from 'react-redux';
import { handleBrandKey } from '../../../app/features/filterItemSlice';
import { dispatch } from '../../../app';
import { Disclosure } from '@headlessui/react';
import { MinusIcon, PlusIcon } from '@heroicons/react/20/solid'
import InputFlatBorder from '../InputFlatBorder';
import LoadingButton from '../LoadingButton';
import { useTranslation } from 'react-i18next';
import { classNames, isRealValue } from '../../../utilities';
import { IoCheckmarkCircleSharp } from 'react-icons/io5';

function FilterProduct({
  attributes,
  rangePrice,
  setRangePrice,
  applyFilter,
  resetApplyFilter,
  valueKeys,
  propertyKeys,
  setPropertiesKeys,
  setValuesKeys,
  virtualSetValuesKeys,
  virtualSetPropertiesKeys,
}) {

  const { lng } = useSelector(state => state.app);
  const { t } = useTranslation()
  const stepAmount = 500
  const { status, priceRange, brands } = useSelector(state => state.items);
  const { brand } = useSelector((state) => state.filters);

  var detectedNumSelected = {}
  attributes?.map(pp => {
    if (propertyKeys?.includes(pp?.propertyKey)) {
      detectedNumSelected[`p_${pp.propertyKey}`] = 0
      pp?.values?.map(vv => {
        if (valueKeys?.includes(vv?.valueKey)) {
          detectedNumSelected[`p_${pp?.propertyKey}`] += 1
        }
      })
    }
  })
  const [numberPropertySelected, setNumberPropertySelected] = React.useState(detectedNumSelected)

  const handleValuesKeys = (propertyKey, value) => {
    const { valueKey } = value
    const indexProperty = propertyKeys.indexOf(propertyKey)
    const indexValue = valueKeys.indexOf(valueKey)
    //initialize the array
    var valuesKeysF = valueKeys ?? []
    var propertiesKeysF = propertyKeys ?? []
    var propertiesSelectedN = numberPropertySelected ?? {}

    if (indexProperty === -1) {
      valuesKeysF = valuesKeysF.concat(valueKey)
      propertiesKeysF = propertiesKeysF.concat(propertyKey)
      propertiesSelectedN[`p_${propertyKey}`] = 1;
    }
    else {
      if (indexValue === -1) {
        valuesKeysF = valuesKeysF.concat(valueKey)
        propertiesSelectedN[`p_${propertyKey}`] += 1;
      }
      else {
        valuesKeysF = valuesKeysF.filter(v => v !== valueKey)
        propertiesSelectedN[`p_${propertyKey}`] -= 1;
        if (!isRealValue(propertiesSelectedN[`p_${propertyKey}`])) {
          propertiesKeysF = propertiesKeysF.filter(v => v !== propertyKey)
        }
      }
    }
    virtualSetValuesKeys(valuesKeysF);
    virtualSetPropertiesKeys(propertiesKeysF);

    setPropertiesKeys(propertiesKeysF);
    setValuesKeys(valuesKeysF);
    setNumberPropertySelected(propertiesSelectedN);
  }
  const selectVariant = (propertyKey, value) => handleValuesKeys(propertyKey, value)

  const applyFilters = (e) => {
    e.preventDefault();
    applyFilter()
  }
  const resetFilter = (e) => {
    e.preventDefault();
    resetApplyFilter()
  }
  const handleMinPrice = (e) => setRangePrice({ priceMin: e.target.value, priceMax: rangePrice?.priceMax })
  const handleMaxPrice = (e) => setRangePrice({ priceMax: e.target.value, priceMin: rangePrice?.priceMin })

  const MarqueButton = ({ title, value }) => {
    const onSelect = () => dispatch(handleBrandKey(value));

    return (
      <span
        onClick={onSelect}
        className={classNames(
          brand?.includes(value)
            ? " bg-[#D90429] text-white"
            : " bg-[#D9042920] text-[#D9042990]",
          " rounded-full w-[45%] h-8 inline-flex items-center justify-center pr-1 text-base font-semibold cursor-pointer"
        )}
      >
        {title}
        {brand?.includes(value) && (
          <button
            type="button"
            className="ml-0.5 inline-flex h-4 w-4 flex-shrink-0 items-center justify-center rounded-full text-indigo-400 hover:bg-indigo-200 hover:text-indigo-500 focus:bg-indigo-500 focus:text-white focus:outline-none"
          >
            <IoCheckmarkCircleSharp className="w-4 h-4 text-white" />
          </button>
        )}
      </span>
    );
  }

  return (
    <div className="px-2 bg-white rounded-md shadow-sm ">
      <form className="lg:block">
        <h5
          htmlFor="first-name"
          className="block my-3 text-sm font-medium text-gray-900"
        >
          {t("filt_price")}
        </h5>
        <InputFlatBorder
          placeholder={t("min_price")}
          label={t("min_price")}
          type="number"
          rightIcon={<span>XAF</span>}
          value={rangePrice?.priceMin}
          onChange={handleMinPrice}
          step={stepAmount}
        />
        <InputFlatBorder
          placeholder={t("max_price")}
          label={t("max_price")}
          type="number"
          rightIcon={<span>XAF</span>}
          value={rangePrice?.priceMax}
          onChange={handleMaxPrice}
          step={stepAmount}
        />
        <div className="flex flex-col my-2 space-y-2 xl:flex-row xl:space-x-2 xl:items-center xl:justify-between ">
          {priceRange?.map((range, index) => (
            <div
              key={index}
              className="flex flex-col items-center justify-center p-2 bg-gray-100 rounded-md"
            >
              <h5 className="font-medium text-gray-700 text-[9px]">
                {range?.title}
              </h5>
              <span className="font-normal text-gray-400 text-[7px]">
                {range?.desc}
              </span>
            </div>
          ))}
        </div>
        <LoadingButton title={t("filter")} onClick={applyFilters} />
        {status === "loading" ? (
          <>Loading...</>
        ) : attributes?.length <= 0 ? (
          <p className="">No filter available</p>
        ) : (
          attributes?.map((attribute, i) => {
            return (
              <Disclosure
                as="div"
                key={i + "Attributes"}
                className="py-6 transition-all border-b border-gray-200 "
              >
                {({ open }) => (
                  <>
                    <h3 className="flow-root -my-6">
                      <Disclosure.Button className="items-center justify-between flex w-full py-3 text-sm text-gray-400 bg-white flapplyFilterex hover:text-gray-500">
                        <span
                          className={
                            propertyKeys?.includes(attribute?.propertyKey)
                              ? "font-medium text-[#D90429]"
                              : "font-medium text-gray-900"
                          }
                        >
                          {attribute?.propertyName[lng]}
                        </span>
                        <span className="flex items-center ml-6">
                          {open ? (
                            <MinusIcon className="w-5 h-5" aria-hidden="true" />
                          ) : (
                            <PlusIcon className="w-5 h-5" aria-hidden="true" />
                          )}
                        </span>
                      </Disclosure.Button>
                    </h3>
                    <Disclosure.Panel className="pt-6">
                      <div className="space-y-4">
                        {attribute.values?.map((v, index) => (
                          <div
                            onClick={() =>
                              selectVariant(attribute?.propertyKey, v)
                            }
                            key={index + "attribute"}
                            className="flex items-center"
                          >
                            <input
                              id={`filter-${v?.valueKey}-${index}`}
                              name={`${v.valueKey}`}
                              defaultValue={v?.propertyName[lng]}
                              type="checkbox"
                              checked={valueKeys?.includes(v?.valueKey)}
                              className="w-4 h-4 border-gray-300 rounded text-[#D90429] focus:ring-[#D90429] "
                            />
                            <label
                              htmlFor={`filter-${v?.valueKey}-${index}`}
                              className="ml-3 text-sm text-gray-600"
                            >
                              {v?.valueName[lng]}
                            </label>
                          </div>
                        ))}
                      </div>
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>
            );
          })
        )}
        {isRealValue(brands) && brands?.length > 0 && (
          <Disclosure
            as="div"
            className="py-6 transition-all border-b border-gray-200 "
          >
            {({ open }) => (
              <>
                <h3 className="flow-root -my-6">
                  <Disclosure.Button className="items-center justify-between w-full py-3 text-sm text-gray-400 bg-white flapplyFilterex hover:text-gray-500">
                    <span className="font-medium text-gray-900">
                      {t("brands")}
                    </span>
                    <span className="flex items-center ml-6">
                      {open ? (
                        <MinusIcon className="w-5 h-5" aria-hidden="true" />
                      ) : (
                        <PlusIcon className="w-5 h-5" aria-hidden="true" />
                      )}
                    </span>
                  </Disclosure.Button>
                </h3>
                <Disclosure.Panel className="pt-6">
                  <div className="space-x-2 space-y-4">
                    {brands?.map(({ name, key }, index) => (
                      <MarqueButton key={index} title={name} value={key} />
                    ))}
                  </div>
                </Disclosure.Panel>
              </>
            )}
          </Disclosure>
        )}
      </form>
      <div className="flex flex-col space-y-2 xl:flex-row xl:space-x-2 xl:items-end">
        <LoadingButton
          style={{ height: 45 }}
          title={t("filter")}
          onClick={applyFilters}
        />
        <LoadingButton
          style={{ backgroundColor: "tomato", height: 45 }}
          title={t("rst_fltr")}
          onClick={resetFilter}
        />
      </div>
    </div>
  );
}

export default FilterProduct;