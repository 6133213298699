import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import { dispatch } from "../../app";
import { handleContextSearch, handleKeyOfStoreOrCategory } from "../../app/features/AppSlice";
import { useBookmarkStoresQuery } from "../../app/services/Api";
import StoreItem from "../../components/common/StoreItem";
import LoadingComponent from "../../components/LoadingComponent";
import { GridNotFound } from "../Products/style";
import { useTranslation } from "react-i18next";
import { scroolWindowToTop } from "../../utilities";

function FavoritesStores() {
  const { t } = useTranslation()
  const { bookmarkStoresFilter } = useSelector(state => state.bookmarkStore);
  const { isLoading } = useBookmarkStoresQuery()

  React.useEffect(() => {
    scroolWindowToTop()
    dispatch(handleContextSearch(null))
    dispatch(handleKeyOfStoreOrCategory(null))
  })

  return (
    <div>
      <div className="max-w-full px-2 mx-auto overflow-hidden sm:px-10 lg:px-16">
        <Box>
          <Box
            className="row-flex"
            sx={{
              display: "grid",
              gridTemplateColumns: "0.5fr 0.5fr",
              gridGap: "16px",
            }}
          >
            <h2 style={{ fontSize: 28, fontWeight: "bold" }}>
              {t("fav_store")}
            </h2>
          </Box>
          <div className="grid grid-cols-2 border-gray-200  gap-y-1 gap-x-1 md:gap-y-2 md:gap-x-2 lg:gap-y-3 lg:gap-x-3 xl:gap-y-4 xl:gap-x-4 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-6">
            {bookmarkStoresFilter?.length <= 0 ? (
              <GridNotFound container>
                <Typography>{t("not_fnd")}</Typography>
              </GridNotFound>
            ) : (
              bookmarkStoresFilter?.map((store, i) => (
                <Grid key={i} item xs={2} sm={4} md={3} lg={3} xl={4}>
                  <StoreItem store={store} active={true} />
                </Grid>
              ))
            )}
          </div>
        </Box>
      </div>
      <LoadingComponent open={isLoading} />
    </div>
  );
}

export default FavoritesStores; 
