import React from "react";

const InputFlat = ({
  name,
  type,
  leftIcon,
  rightIcon,
  placeholder,
  className,
  inputStyle,
  onChange,
  label,
  error,
  ...props
}) => {
  return (
    <div className="my-2 w-full">
      {label && (
        <label
          htmlFor={name}
          className="block text-sm font-medium text-gray-700"
        >
          {label}
        </label>
      )}
      <div className="relative mt-2 flex-1 border-0 rounded-xl shadow-sm bg-gray-100 h-[50px]">
        {leftIcon && (
          <div className="pointer-events-none absolute inset-y-0 -left-2 flex items-center pl-3">
            {leftIcon}
          </div>
        )}
        <input
          type={type}
          name={name}
          onChange={onChange}
          className={`block w-[100%] h-full bg-transparent rounded-xl outline-none border-0 py-1.5 pl-10 pr-20 text-gray-900 focus:ring-2 focus:ring-inset focus:ring-[#D90429] placeholder:text-gray-400 text-sm sm:leading-6 ${className}`}
          placeholder={placeholder}
          style={inputStyle}
          {...props}
        />
        {rightIcon && (
          <div className="absolute inset-y-0 right-4 flex items-center cursor-pointer">
            {rightIcon}
          </div>
        )}
      </div>
      {error && <div className="text-sm text-red-500 text-left">{error}</div>}
    </div>
  );
};

export default InputFlat;
