import React, { useState } from 'react'
import FavoriteBorderOutlinedIcon from '@mui/icons-material/FavoriteBorderOutlined';
import ActionButton from '../ActionButton'
import { CircularProgress } from '@mui/material';
import './styles.css'
import { getFakeName } from '../../../utilities';
import { useNavigate } from 'react-router-dom';
import { PRODUCT_DETAILS, STORE } from '../../../constants/routes';
import { useSelector } from 'react-redux';
import ViewSeaOrAirPrice from '../../../pages/ProductDetails/ViewSeaOrAirPrice';
import { handleInvalidateVariants } from '../../../app/features/itemSlice';
import { dispatch } from '../../..';
import { useLocalDbHook } from '../../../app/localDatabase/useLocalDbHook';
import useMutationApi from '../../../app/hooks/useMutationApi';

const FavoriteItem = (props) => {
  const { item } = props
  const { isLogged } = useSelector(state => state.auth)
  const { lng } = useSelector((state) => state?.app);
  const navigate = useNavigate()
  const [active, setActive] = useState(true)

  const { isLoadingAddBookmark, } = useLocalDbHook()
  const { isLoadingFavorite, handleAddBookmarkItem } = useMutationApi()

  const handleStoreHome = (e) => {
    e.preventDefault();
    navigate(`${STORE}/${item?.store}`);
  };
  const handleDetailItem = (e) => {
    e.preventDefault();
    dispatch(handleInvalidateVariants());
    navigate(`${PRODUCT_DETAILS}/${item.key}`)
  }
  const handleRemoveToFavorise = () => {
    setActive(false)
    handleAddBookmarkItem(item?.key)
  }


  return (
    <div className="mx-1 sm:mx-0 group relative flex flex-col shadow-sm overflow-hidden rounded-lg border border-gray-200 bg-white max-w-[310px]">
      <div
        // onClick={handleDetailItem}
        className="bg-transparent group-hover:opacity-75 relative pb-[120%] "
      >
        <img onClick={handleDetailItem} onContextMenu={(event) => { event.preventDefault(); }}
          loading="eager"
          src={item?.picture?.medium}
          alt={item?.description?.en}
          className="object-contain object-center h-full mx-auto w-full top-0 absolute "
        />
        <div className="row-flex-space">
          {!active && (isLoadingAddBookmark || isLoadingFavorite) ? (
            <CircularProgress sx={{ color: "#D90429" }} />
          ) : (
            <ActionButton
              activ={active}
              style={{ position: "absolute", top: 10, right: 10 }}
              inactiveIcon={
                <FavoriteBorderOutlinedIcon
                  className="inactive-icon"
                  sx={{ fontSize: 18, color: "#707070" }}
                />
              }
              activeIcon={
                <FavoriteBorderOutlinedIcon
                  sx={{ fontSize: 18, color: "white" }}
                />
              }
              addBookmarkItem={handleRemoveToFavorise}
            />
          )}
        </div>
      </div>
      <div className="flex flex-col pl-2">
        <h3 className="w-full truncate text-md font-medium text-gray-900">
          {item?.description[lng]}
        </h3>
        <a
          onClick={handleStoreHome}
          className="sm:text-sm lg:text-base font-medium text-gray-600 cursor-pointer focus:text-[#D90429] truncate focus:underline "
        >
          {item?.storeName?.en}
        </a>
        <div className="flex flex-1 flex-col justify-end">
          <ViewSeaOrAirPrice
            isLogged={isLogged}
            item={item}
            seaOrAir={true}
          />
        </div>
      </div>
    </div>
  );
}

export default FavoriteItem