import React, { useState } from 'react'
import { Switch } from '@headlessui/react'


function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

const NotificationTab = () => {

    const [automaticTimezoneEnabled, setAutomaticTimezoneEnabled] = useState(true)
    const [autoUpdateApplicantDataEnabled, setAutoUpdateApplicantDataEnabled] = useState(false)

  return (
      <div className="relative container md:mx-auto md:px-8 xl:px-0">
          <div className="pt-3 pb-16">
              <div className="px-4 sm:px-6 md:px-0">
                  <div className="py-6">
                      {/* Description list with inline editing */}
                      <div className="mt-10 divide-y divide-gray-200">
                          <div className="space-y-1">
                              <h3 className="text-lg font-medium leading-6 text-gray-900">Notification</h3>
                              <p className="max-w-2xl text-sm text-gray-500">
                                  Adjust your notification settings to fit your needs.
                              </p>
                          </div>

                        <div className="mt-6" >
                <dl className="divide-y divide-gray-200">
                                

                    <Switch.Group as="div" className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:pt-5">
                      <Switch.Label as="dt" className="text-sm font-medium text-gray-500" passive>
                          Notifications <br />
                          <span className="text-xs font-normal text-gray-400" >Receive notifications on latest offers ans store updates.</span>
                      </Switch.Label>
                      <dd className="mt-1 flex text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                          <Switch
                              checked={automaticTimezoneEnabled}
                              onChange={setAutomaticTimezoneEnabled}
                              className={classNames(
                                  automaticTimezoneEnabled ? 'bg-[#D90429]' : 'bg-gray-200',
                                  'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-[#D90429] focus:ring-offset-2 sm:ml-auto'
                              )}
                          >
                              <span
                                  aria-hidden="true"
                                  className={classNames(
                                      automaticTimezoneEnabled ? 'translate-x-5' : 'translate-x-0',
                                      'inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
                                  )}
                              />
                          </Switch>
                      </dd>
                    </Switch.Group>
                    <Switch.Group as="div" className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:pt-5">
                        <Switch.Label as="dt" className="text-sm font-medium text-gray-500" passive>
                            Popups <br />
                            <span className="text-xs font-normal text-gray-400" >Disable all popups ans adverts from third party vendors</span>
                        </Switch.Label>
                        <dd className="mt-1 flex text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                            <Switch
                                checked={autoUpdateApplicantDataEnabled}
                                onChange={setAutoUpdateApplicantDataEnabled}
                                className={classNames(
                                    autoUpdateApplicantDataEnabled ? 'bg-[#D90429]' : 'bg-gray-200',
                                    'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-[#D90429] focus:ring-offset-2 sm:ml-auto'
                                )}
                            >
                                <span
                                    aria-hidden="true"
                                    className={classNames(
                                        autoUpdateApplicantDataEnabled ? 'translate-x-5' : 'translate-x-0',
                                        'inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
                                    )}
                                />
                            </Switch>
                        </dd>
                    </Switch.Group>
        
                </dl>
                        </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
    
  )
}

export default NotificationTab