import React, { useState } from 'react'
import GeneralSection from '../../components/Settings/GeneralTab'
import NotificationSection from '../../components/Settings/NotificationTab'
import { scroolWindowToTop } from '../../utilities'

const tabs = [
  { name: 'General', href: '#', current: true },
  { name: 'Notifications', href: '#', current: false },
  // { name: 'Password', href: '#', current: true },
  // { name: 'Plan', href: '#', current: false },
  // { name: 'Billing', href: '#', current: false },
  // { name: 'Team Members', href: '#', current: false },
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const Settings = () => {
  const [tabValue, setTabValue] = useState("General")

  React.useEffect(() => {
    scroolWindowToTop()
  }, [0])

  return (
    <div className="relative px-4 sm:px-6 lg:px-8">
      <div className="pt-10 pb-16">
        <div className="px-4 sm:px-6 md:px-0">
          <h1 className="text-3xl font-bold tracking-tight text-gray-900">
            Settings
          </h1>
        </div>
        <div className="px-4 sm:px-6 md:px-0">
          <div className="py-6">
            {/* Tabs */}
            <div className="lg:hidden">
              <label htmlFor="selected-tab" className="sr-only">
                Select a tab
              </label>
              <select
                id="selected-tab"
                name="selected-tab"
                onClick={(e) => {
                  setTabValue(e.target.value);
                }}
                // onSelect={(e)=> { console.log("e.target.value"+e.target.value) }}
                className="mt-1 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-[#D9042990] focus:outline-none focus:ring-[#D9042990] sm:text-sm"
                defaultValue={tabs.find((tab) => tab.current).name}
              >
                {tabs.map((tab) => (
                  <option value={tab.name} key={tab.name}>
                    {tab.name}
                  </option>
                ))}
              </select>
            </div>
            <div className="hidden lg:block">
              <div className="border-b border-gray-200">
                <nav className="-mb-px flex space-x-8">
                  {tabs.map((tab, i) => (
                    <a
                      key={tab.name}
                      onClick={() => setTabValue(tab.name)}
                      className={classNames(
                        tabValue === tab.name
                          ? "border-[#D9042990] text-[#D90429]"
                          : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700",
                        "whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm"
                      )}
                    >
                      {tab.name}
                    </a>
                  ))}
                </nav>
              </div>
            </div>
          </div>
        </div>
        {tabValue === "General" && <GeneralSection />}
        {tabValue === "Notifications" && <NotificationSection />}
      </div>
    </div>
  );
}

export default Settings