import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { dispatch } from "../..";
import { handleFromToStatementDate } from "../../app/features/usersSlice";
import { getOperationMethod, getOperationTransaction } from "../../app/hooks";
import { useFormatter } from "../../app/hooks/useFormatter";
import { useCodeValuesQuery, useViewStatementsByCustomerQuery } from "../../app/services/Api";

import LoadingComponent from "../../components/LoadingComponent";
import { isRealValue, scroolWindowToTop } from "../../utilities";
import StatementUserTable from "../Dashboard/Users/StatementUserTable";

const Statements = () => {
  const { toStatement, fromStatement } = useSelector(state => state.users)
  const { t } = useTranslation()
  const { asDate } = useFormatter()

  const date = new Date()
  const to = asDate(date)
  const from = asDate(new Date(date.getTime() - 1000 * 60 * 60 * 24 * 179))

  const onChangeTo = (v) => dispatch(handleFromToStatementDate({ to: v }))
  const onChangeFrom = (v) => dispatch(handleFromToStatementDate({ from: v }))

  const { isFetching: isLoadingStatement } = useViewStatementsByCustomerQuery({ from: isRealValue(fromStatement) ? fromStatement : from, to: isRealValue(toStatement) ? toStatement : to })
  const { isFetching: isLoadingOperationTransaction } = useCodeValuesQuery(getOperationTransaction)
  const { isFetching: isLoadingOperationMethod } = useCodeValuesQuery(getOperationMethod)

  React.useEffect(() => {
    scroolWindowToTop()
  }, [])

  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-2xl font-semibold text-gray-900">
            {t("statement")}
          </h1>
        </div>
      </div>
      <div className="flex md:flex-row md:justify-end md:space-x-3 ">
        <div className="w-full max-w-lg lg:max-w-xs ">
          <label
            htmlFor="search"
            className="block text-sm font-medium text-gray-700"
          >
            {t("From")}
          </label>
          <div className="relative text-gray-400 focus-within:text-gray-500 mt-1">
            <input
              id="date"
              className="block w-full rounded-md border border-gray-300 bg-white text-base py-2 pl-10 pr-3 leading-5 text-gray-900 placeholder-gray-500 focus:border-[#D90429] focus:placeholder-gray-500 focus:outline-none focus:ring-1 focus:ring-[#D90429] sm:text-sm"
              placeholder="Search date"
              type="date"
              name="date"
              onChange={(e) => {
                const d = e.target.value;
                onChangeFrom(d);
              }}
            />
          </div>
        </div>
        <div className="w-full max-w-lg lg:max-w-xs ">
          <label
            htmlFor="search"
            className="block text-sm font-medium text-gray-700"
          >
            {t("To")}
          </label>
          <div className="relative text-gray-400 focus-within:text-gray-500 mt-1">
            <input
              id="date"
              className="block w-full rounded-md border border-gray-300 bg-white text-base py-2 pl-10 pr-3 leading-5 text-gray-900 placeholder-gray-500 focus:border-[#D90429] focus:placeholder-gray-500 focus:outline-none focus:ring-1 focus:ring-[#D90429] sm:text-sm"
              placeholder="Search date"
              type="date"
              name="date"
              onChange={(e) => {
                const d = e.target.value;
                onChangeTo(d);
              }}
            />
          </div>
        </div>
      </div>

      <StatementUserTable />

      <LoadingComponent
        open={
          isLoadingStatement ||
          isLoadingOperationTransaction ||
          isLoadingOperationMethod
        }
      />
    </div>
  );
};

export default Statements;
