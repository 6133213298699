import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    openedPurchases: [],
    closedPurchases: [],
    selectedItems: [],
    commands: [],
    toAdd: [],
    id: null,
}

const purchaseShipmentSlice = createSlice({
    name: 'purchaseShipments',
    initialState,
    reducers: {
        handleOpenedPurchases(state, action) {
            state.openedPurchases = action.payload
        },
        handleClosedPurchases(state, action) {
            state.closedPurchases = action.payload
        },
        handleCommands(state, action) {
            state.commands = action.payload
        },
        handleSelectedAll(state, action) {
            if (action.payload) {
                var toAdd = []
                const newSelected = state.commands?.map((command) => command?.items?.map((item) => {
                    toAdd.push({ lineItem: item?._id, quantity: item.quantity })
                    return item?._id
                })).flat()
                state.toAdd = toAdd;
                state.selectedItems = newSelected
            }
            else {
                state.selectedItems = []
                state.toAdd = []
            }
        },
        handleSelectItem(state, action) {
            state.selectedItems = action.payload
        },
        handleToAddPurchasedItem(state, action) {
            const { lineItem, quantity, act } = action.payload
            if (act === "add") state.toAdd.push({ quantity, lineItem })
            else if (act === "rm") {
                const rm = state.toAdd.filter(item => item.lineItem !== lineItem)
                state.toAdd = rm
            }
        },
        handleId(state, action) {
            state.id = action.payload
        },

    },

})

export const {
    handleOpenedPurchases,
    handleClosedPurchases,
    handleCommands,
    handleSelectedAll,
    handleSelectItem,
    handleToAddPurchasedItem,
    handleId,

} = purchaseShipmentSlice.actions

export default purchaseShipmentSlice