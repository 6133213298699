import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    addresses: [],
    deliveryAddress: null,
    addressToAdd: {},
    addressId: null,
    addressTitle: ""

}

const addressesSlice = createSlice({
    name: 'addresses',
    initialState,
    reducers: {
        handleAddresses(state, action) {
            state.addresses = action.payload
        },
        handleDeliveryAddress(state, action) {
            state.deliveryAddress = action.payload
        },
        handleAddress(state, action) {
            const { addressId, addressTitle } = action.payload
            state.addressId = addressId
            state.addressTitle = addressTitle
        },
        handleViewAddress(state, action) {
            state.address = action.payload
        },

    },
})

export const {
    handleAddresses,
    handleDeliveryAddress,
    handleAddress,
    handleViewAddress,

} = addressesSlice.actions

export default addressesSlice