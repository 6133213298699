import * as React from 'react';
import { getSideBarNavArray } from './listItems';
import { Link, Outlet, useLocation, useNavigate } from 'react-router-dom';
import { dispatch } from '../../app';
import { handleHeaderType, handleSelected, handleTitleMobilePage } from '../../app/features/authSlice';
import { Fragment, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { ArrowLeftOnRectangleIcon, Bars3BottomLeftIcon, QuestionMarkCircleIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { getStorage, scroolWindowToTop } from '../../utilities';
import { useSelector } from 'react-redux';
import { HOME } from '../../constants/routes';
import logo from "../../assets/images/Logo_AFLP.png";
import { useTranslation } from 'react-i18next';


const secondaryNavigation = [
  { name: 'Help', href: '#', icon: QuestionMarkCircleIcon },
  { name: 'Logout', href: '#', icon: ArrowLeftOnRectangleIcon },
]


function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

function DashboardContent() {

  const [sidebarOpen, setSidebarOpen] = useState(false)
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const { t } = useTranslation()
  const getBoard = (url, index) => {
    dispatch(handleSelected(index))
    navigate(url)
  }
  const { selected, user } = useSelector(state => state.auth)

  var routesArr = getSideBarNavArray({ role: user?.role })
  var routeIndex = routesArr.findIndex(route => route.url === pathname)

  React.useEffect(() => {

    dispatch(handleSelected(routeIndex))
    dispatch(handleHeaderType({ headerType: "none", isStore: false, header: true, checkout: false }))
    dispatch(handleTitleMobilePage('Wallet'))

    if (getStorage("dsbcheck") === null) dispatch(handleSelected(0))
    else dispatch(handleSelected(getStorage("dsbcheck")))
    scroolWindowToTop()

  }, [pathname])


  return (
    <div>
      <Transition.Root show={sidebarOpen} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-[620] md:hidden"
          onClose={setSidebarOpen}
        >
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-600 bg-opacity-75" />
          </Transition.Child>

          <div className="fixed inset-0 z-40 flex">
            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
            >
              <Dialog.Panel className="relative flex w-full max-w-xs flex-1 flex-col bg-white pt-5 pb-4">
                <Transition.Child
                  as={Fragment}
                  enter="ease-in-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in-out duration-300"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="absolute top-0 right-0 -mr-14 p-1">
                    <button
                      type="button"
                      className="flex h-12 w-12 items-center justify-center rounded-full focus:bg-gray-600 focus:outline-none"
                      onClick={() => setSidebarOpen(false)}
                    >
                      <XMarkIcon
                        className="h-6 w-6 text-white"
                        aria-hidden="true"
                      />
                      <span className="sr-only">Close sidebar</span>
                    </button>
                  </div>
                </Transition.Child>
                <div className="flex flex-shrink-0 items-center px-4">
                  <Link className="link " to={HOME}>
                    <img onContextMenu={(event) => { event.preventDefault(); }}
                      src={logo}
                      className="w-20 md:w-20 lg:w-32 lg:h-20 object-contain object-center"
                    />
                  </Link>
                </div>
                <div className="mt-5 h-0 flex-1 overflow-y-auto">
                  <nav className="flex h-full flex-col">
                    <div className="space-y-1 cursor-pointer ">
                      {routesArr?.map((route, i) => {
                        return (
                          <a
                            key={i}
                            href={route.href}
                            onClick={() => {
                              setSidebarOpen(false);
                              getBoard(route.url, i);
                            }}
                            className={classNames(
                              selected === i
                                ? "bg-purple-50 border-[#D9041990] text-[#D90419]"
                                : "border-transparent text-gray-600 hover:text-gray-900 hover:bg-gray-50",
                              "group border-l-4 py-2 px-3 flex items-center text-sm font-medium cursor-pointer"
                            )}
                          >
                            <route.icon
                              className={classNames(
                                selected === i
                                  ? "text-[#D9041980]"
                                  : "text-gray-400 group-hover:text-gray-500",
                                "mr-3 flex-shrink-0 h-6 w-6"
                              )}
                              aria-hidden="true"
                            />
                            {t(route?.name)}
                          </a>
                        );
                        // }
                      })}
                    </div>
                    <div className="mt-auto space-y-1 pt-10">
                      {secondaryNavigation.map((item) => (
                        <a
                          key={item.name}
                          href={item.href}
                          className="group flex items-center border-l-4 border-transparent py-2 px-3 text-base font-medium text-gray-600 hover:bg-gray-50 hover:text-gray-900"
                        >
                          <item.icon
                            className="mr-4 h-6 w-6 text-gray-400 group-hover:text-gray-500"
                            aria-hidden="true"
                          />
                          {item.name}
                        </a>
                      ))}
                    </div>
                  </nav>
                </div>
              </Dialog.Panel>
            </Transition.Child>
            <div className="w-14 flex-shrink-0" aria-hidden="true">
              {/* Dummy element to force sidebar to shrink to fit close icon */}
            </div>
          </div>
        </Dialog>
      </Transition.Root>
      <div className="md:flex w-full ">
        {/* Static sidebar for desktop */}
        <div className="hidden md:relative md:w-64 md:flex md:flex-col h-screen overflow-y-scroll">
          {/* Sidebar component, swap this element with another sidebar if you like */}
          <nav className="flex flex-grow flex-col overflow-y-auto border-r border-gray-200  pt-5 pb-4">
            <div className="pt-5 flex-grow">
              <div className="space-y-1 cursor-pointer">
                {
                  routesArr?.map((route, i) => {
                    return (
                      <a
                        key={i}
                        href={route.href}
                        onClick={() => getBoard(route.url, i)}
                        className={classNames(
                          selected === i
                            ? "bg-purple-50 border-[#D9041990] text-[#D90419]"
                            : "border-transparent text-gray-600 hover:text-gray-900 hover:bg-gray-50",
                          "group border-l-4 py-2 px-3 flex items-center text-sm font-medium cursor-pointer "
                        )}
                      >
                        <route.icon
                          className={classNames(
                            selected === i
                              ? "text-[#D90419]"
                              : "text-gray-400 group-hover:text-gray-500",
                            "mr-3 flex-shrink-0 h-6 w-6"
                          )}
                          aria-hidden="true"
                        />
                        {t(route?.name)}
                      </a>
                    );
                  })
                }
              </div>
            </div>
            <div className="block w-full flex-shrink-0">
              {secondaryNavigation.map((item) => (
                <a
                  key={item.name}
                  href={item.href}
                  className="group flex items-center border-l-4 border-transparent py-2 px-3 text-sm font-medium text-gray-600 hover:bg-gray-50 hover:text-gray-900"
                >
                  <item.icon
                    className="mr-3 h-6 w-6 text-gray-400 group-hover:text-gray-500"
                    aria-hidden="true"
                  />
                  {item.name}
                </a>
              ))}
            </div>
          </nav>
        </div>
        <div className="w-full h-screen overflow-y-scroll ">
          <div className="flex flex-col md:mx-auto ">
            <div className="flex h-16 flex-shrink-0 border-b border-gray-200 bg-transparent">
              <button
                type="button"
                className="border-r border-gray-200 px-4 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-[#D90429] md:hidden"
                onClick={() => setSidebarOpen(true)}
              >
                <span className="sr-only">Open sidebar</span>
                <Bars3BottomLeftIcon className="h-6 w-6" aria-hidden="true" />
              </button>
            </div>
            <main className="flex-1 mx-auto w-full ">
              <div className="relative">
                <div className="pt-10 pb-16">
                  <Outlet />
                </div>
              </div>
            </main>
          </div>
        </div>
      </div>
    </div>
  );
}

export default function DashboardIndex() {
  const { isLogged } = useSelector(state => state?.auth)
  const navigate = useNavigate()

  if (!isLogged) navigate(HOME)
  return <DashboardContent />
}