import React from "react";

import "./styles.css";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { useGetItemByKeyQuery, useGetSourceItemsByForeignLogQuery, useRecommendationsItemsQuery } from "../../app/services/Api";
import { useBrandByKeyQuery } from "../../app/services/Api";
import { dispatch } from "../../app";
import ImagesSection from "../../components/ProductDetails/ImagesSection";
import ProductInfoSection from "../../components/ProductDetails/ProductInfoSection";
import { getDimensionBody, getOrderRoute } from "../../app/hooks";
import { useCodeValuesQuery } from "../../app/services/Api";
import { classNames, isRealValue, scroolWindowToTop } from "../../utilities";
import ProductListItems from "../Products/ProductListItems";
import LoadingComponent from "../../components/LoadingComponent";
import { handleContextSearch, handleKeyOfStoreOrCategory, } from "../../app/features/AppSlice";
import { resetCategoryItem } from "../../app/features/categoriesSlice";
import { useTranslation } from "react-i18next";
import { handleInvalidate } from "../../app/features/itemSlice";
import { PRODUCT } from "../../constants/routes";
import useChangeLangParams from "../../app/hooks/useChangeLangParams";

const ProductDetails = () => {
  useChangeLangParams();
  const params = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const key = params?.key;

  const { item } = useSelector((state) => state?.item);
  const { lng } = useSelector((state) => state?.app);
  const { recommendations } = useSelector((state) => state.items);
  const { user } = useSelector((state) => state.auth);
  const isForeignLog = user?.role === "logistician-foreign";

  const { isFetching: isLoadingRA, isLoading: loadingRecom } = useRecommendationsItemsQuery(key, { refetchOnMountOrArgChange: true });
  const { isLoading: isLoadingC } = useCodeValuesQuery(getOrderRoute);
  const { isFetching: isLoadingD } = useCodeValuesQuery(getDimensionBody);
  const { isFetching: isLoading } = useGetItemByKeyQuery(key, { refetchOnMountOrArgChange: true });
  useBrandByKeyQuery(item?.brand, { skip: !isRealValue(item) });
  const { data: sourceItem, isFetching: isLoadingSI } = useGetSourceItemsByForeignLogQuery(key, { skip: !isForeignLog });

  React.useEffect(() => {
    scroolWindowToTop();
    dispatch(handleInvalidate());
    dispatch(handleContextSearch("products"));
    dispatch(handleKeyOfStoreOrCategory(key));

    return () => {
      dispatch(resetCategoryItem());
    };
  }, [key]);

  const handlerItemsByCategory = (i, key) => {
    if (i === 0 || i === 1) {
      navigate(`${PRODUCT}/${key}?page=1`);
      dispatch({
        type: "categories/selectedCategoryItem",
        payload: item?.name[lng],
      });
    }
  };

  return (
    <div>
      {!(isLoading || isLoadingC || isLoadingSI || isLoadingD) &&
        isRealValue(item) && (
          <main className="">
            <div className="flex flex-row items-center justify-start my-2 space-x-1 font-medium text-gray-500 ">
              <span className="text-[8px] sm:text-[10px] md:text-sm ">
                Categories
              </span>
              <div className="flex flex-row-reverse ">
                {item?.hierarchy?.map(({ name, key }, i) => (
                  <span
                    key={key}
                    onClick={() => handlerItemsByCategory(i, key)}
                    className={classNames(
                      i === 0 || i === 1
                        ? "hover:cursor-pointer hover:underline hover:text-[#D90429]"
                        : "",
                      "text-[8px] sm:text-[10px] md:text-sm "
                    )}
                  >
                    {`/ ${name[lng]}`}
                  </span>
                ))}
              </div>
            </div>
            <div className="w-full mx-auto mb-8 lg:max-w-none ">
              <div className="w-full lg:grid lg:grid-cols-2 lg:items-start lg:gap-x-2 ">
                <ImagesSection />
                <ProductInfoSection sourceItem={sourceItem?.data} />
              </div>
            </div>
          </main>
        )}
      <h3 className="my-2 text-2xl font-semibold text-left text-gray-800">
        {t("also_like")}
      </h3>
      <ProductListItems
        itemsFilter={recommendations}
        loading={isLoadingRA || loadingRecom}
        xs={12}
      />
      <LoadingComponent
        open={isLoading || isLoadingC || isLoadingSI || isLoadingD}
      />
    </div>
  );
};
export default ProductDetails;
