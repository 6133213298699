import React, { Fragment } from 'react'
import {
  XMarkIcon,
} from '@heroicons/react/24/outline'
import { Dialog, Transition } from '@headlessui/react'

const ModalFlat = ({ isOpen, closeModal, title, children}) => {
  return (
    <Transition appear show={isOpen} as={Fragment}  >
        <Dialog as="div" className="relative z-[140]  " onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-scroll">
            <div className="flex w-full min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="bg-white w-1/3 transform overflow-hidden rounded-2xl p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="div"
                    className="flex-row flex items-center justify-between "
                  >
                    <h3 className='text-lg font-medium leading-4 ' >{title}</h3>
                    <button
                      type="button"
                      className="justify-center items-center rounded-md border border-transparent bg-[#D90429] px-4 py-2 text-lg text-white font-medium hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2 w-[fit-content] h-[fit-content] "
                      onClick={closeModal}
                    >
                        <XMarkIcon className="h-6 w-6 text-white" aria-hidden="true" />
                    </button>
                  </Dialog.Title>
                  { children }
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
  )
}

export default ModalFlat