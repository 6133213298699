import { useState } from 'react';

import { Accordion, AccordionDetails, AccordionSummary, Box, Divider, Drawer, IconButton, LinearProgress, ListItemIcon, styled, Typography, useTheme } from "@mui/material";
import { ChevronLeft, ChevronLeftOutlined, ChevronRight, ExpandMore, Logout, MenuOpenOutlined } from "@mui/icons-material";
import React from "react";
import MenuItemsMobile from "./MenuItemsMobile";
import { LinkItemTextDrawer, ListItemTextDrawer, MyMenu } from '../AppBar/style';
import { CHECKOUT_FILTER, DASHBOARD, HOME, PRODUCT, SIGN_IN, SIGN_UP, STORE } from '../../../constants/routes';
import { useSelector } from 'react-redux';
import { useSignOutMutation } from '../../../app/services/Api';
import { useNavigate } from 'react-router-dom';
import { loggedOut } from '../../../app/features/authSlice';
import { dispatch } from '../../../app';
import { handleLevel, handleProductByCategory, handleToLastLevel, handleToLevelThree, handleToLevelTwo } from '../../../app/features/categoriesSlice';
import { useFormatter } from '../../../app/hooks/useFormatter';
import { handleKeyCat } from '../../../app/features/AppSlice';


export default function MenuMobile() {
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedTitle, setSelectedTitle] = useState("")
  const [levelTitle, setLevelTitle] = useState([""]);
    const [levelMenu, setLevelMenu] = useState([]);
  const open = Boolean(anchorEl);

  const { all, level, levelOne, levelTwo, levelThree, lastLevel, indexLevelOne, indexLevelTwo, indexLevelThree, indexLastLevel } = useSelector(state => state.categories)
  const { display } = useFormatter()
  const { isLogged, user } = useSelector(state => state.auth)
  const navigate = useNavigate()
  const [signOut, { isLoading }] = useSignOutMutation()

  const theme = useTheme();

  const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    justifyContent: 'flex-start',
  }));

  const signout = () => {
    signOut().unwrap().then((resp) => {
      if (resp?.error === false) {
        dispatch(loggedOut())
        handleClose()
        navigate(`${SIGN_IN}`)
      }
    }).catch((err) => { })
  }

  const handleClick = (e) => {
    setAnchorEl(e.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const lenghtSubmenuOfCategory = (menu) => {
    return all?.filter(c => c?.parent === menu.key).length
  }

  const handleForLevelTwo = (index, category) => dispatch(handleToLevelTwo({ index, category }))
  const handleForLevelThree = (index, category) => dispatch(handleToLevelThree({ index, category }));
  const handleForLastLevel = (index, category) => dispatch(handleToLastLevel({ index, category }));
  const viewProduct = (category, index) => {
    dispatch(handleProductByCategory({ index })); 
    // dispatch(handleKeyCat(category?.key))
   
    navigate(`${PRODUCT}/${category?.key}?page=1`);
  };

  const handleEnterMenuItemClick = (index, category) => {
    if (level === 1) {
      levelTitle?.push(display(category))
      dispatch(handleLevel(level + 1))
      handleForLevelTwo(index, category)
      setSelectedTitle(levelTitle.at(-1))
    }
    else if (level === 2) {
      levelTitle?.push(display(category))
      dispatch(handleLevel(level + 1))
      handleForLevelThree(index, category)
      setSelectedTitle(levelTitle.at(-1))

    }
    else if (level === 3) {
      levelTitle?.push(display(category))
      dispatch(handleLevel(level + 1))
      handleForLastLevel(index, category)
      setSelectedTitle(levelTitle.at(-1))

    }
    else if (level === 4) {
      dispatch(handleLevel(4))
      viewProduct(category, index)
      setAnchorEl(null);
    }
  }
  const handleBackMenuItemClick = () => {
    if (level > 1) {
      levelTitle?.pop()
      setSelectedTitle(levelTitle.at(-1))
      dispatch(handleLevel(level - 1))
    } else {
      setLevelTitle([""]);
      setSelectedTitle("");
    }
  }

  function MenuItems() {

    return (
      <Box sx={{ mr: 2 }}>
        {
          (level === 1 ? levelOne : level === 2 ? levelTwo : level === 3 ? levelThree : lastLevel)?.map((menu, index) => {
            return <MenuItemsMobile
              items={menu}
              sizeSubMenu={lenghtSubmenuOfCategory(menu)}
              handleClose={handleClose}
              setAnchorEl = {setAnchorEl}
              key={index}
              level = {level}
              selected={level === 1 ? indexLevelOne === index : level === 2 ? indexLevelTwo === index : level === 3 ? indexLevelThree === index : level === 4 && indexLastLevel === index}
              handleClick={() => handleEnterMenuItemClick(index, menu)}
            />
          })
        }
      </Box>
    );
  }

  return (
    <>
      <IconButton style={{ marginRight: 3 }} sx={{ margin: 0, padding: 0 }} onClick={handleClick}>
        <MenuOpenOutlined fontSize="medium" />
      </IconButton>
      <Drawer
        anchor="left"
        open={open}
        onClose={handleClose}
        variant="temporary"
      >
        <Box sx={{ minWidth: "250px", maxWidth: "315px" }}>
          <DrawerHeader>
            <IconButton onClick={handleClose}>
              {theme.direction === "ltr" ? <ChevronLeft /> : <ChevronRight />}
            </IconButton>
          </DrawerHeader>
          <Divider />
          <Divider />
          <MyMenu type="block">
            <LinkItemTextDrawer to={HOME} onClick={handleClose}>
              Home
            </LinkItemTextDrawer>
            <Divider />

            <Divider />
            {/* <LinkItemTextDrawer to={STORE} onClick={handleClose} >
                            Stores
                        </LinkItemTextDrawer> */}
            <Divider />
            {isLogged && (
              <LinkItemTextDrawer to={CHECKOUT_FILTER} onClick={handleClose}>
                Orders
              </LinkItemTextDrawer>
            )}
            <Divider />
            {isLogged && (
              <LinkItemTextDrawer to={DASHBOARD} onClick={handleClose}>
                Dashboard
              </LinkItemTextDrawer>
            )}
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMore />}
                aria-controls="panel21a-content"
                id="panel21a-header"
                aria-expanded="false"
                sx={{ height: 8 }}
              >
                <ListItemTextDrawer>Menu</ListItemTextDrawer>
              </AccordionSummary>
              <AccordionDetails sx={{ marginTop: -1.5, marginLeft: -3 }}>
                {selectedTitle === "" ? (
                  <Typography
                    
                    variant="body2"
                    sx={{ pl: 5 }}
                  >
                    <Typography
                      
                      variant="subtitle5"
                      sx={{
                        fontFamily: '"Futura"',
                        fontWeight: 900,
                        fontSize: 22,
                        color: "#707070",
                      }}
                    ></Typography>
                  </Typography>
                ) : (
                  <Typography
                    
                    className="row-flex"
                    sx={{ justifyContent: "left", pb: 1, pl: 2 }}
                    onClick={handleBackMenuItemClick}
                  >
                    <Typography  className="row-flex">
                      <ChevronLeftOutlined
                        fontSize="small"
                        sx={{ color: "#dfdddd" }}
                      />
                      <Typography
                        
                        sx={{
                          ml: 2,
                          fontWeight: "bold",
                          fontSize: 18,
                          color: "#707070",
                          fontFamily: '"Futura"',
                        }}
                      >
                        {selectedTitle}
                      </Typography>
                    </Typography>
                  </Typography>
                )}
                <MenuItems menuList={levelOne} />
              </AccordionDetails>
            </Accordion>
            <Divider />
            {!isLogged && (
              <>
                <LinkItemTextDrawer to={SIGN_IN} onClick={handleClose}>
                  Sign in
                </LinkItemTextDrawer>
                <Divider />
              </>
            )}
            {isLogged && (
              <>
                <LinkItemTextDrawer onClick={signout} disabled={isLoading}>
                  <ListItemIcon>
                    <Logout fontSize="small" />
                  </ListItemIcon>
                  Logout
                </LinkItemTextDrawer>
                <Divider />
                {isLoading && <LinearProgress color="secondary" />}
              </>
            )}
          </MyMenu>
        </Box>
      </Drawer>
    </>
  );
}