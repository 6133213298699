import { ArrowBack } from '@mui/icons-material'
import { Box, Container, Stack, Typography, Avatar, TextField, IconButton, FormControl, Select, MenuItem } from '@mui/material'
import React from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useSelector } from 'react-redux'
import YButton from '../../../components/common/ButtonStyle'
import useMutationApi from '../../../app/hooks/useMutationApi'

const PaymentFormUser = ({ setForm }) => {
    const { orders } = useSelector(state => state?.orders)
    const { handleSubmit, control, formState: { errors } } = useForm({
        defaultValues: {
            operation: "payment",
            order: '',
            amount: 0,
        }
    })
    const { payOrderByUser, isPaymentOrderByUser } = useMutationApi()
    const handlePay = (data) => {
        const { order, operation, amount } = data
        payOrderByUser(order, { operation, amount })
    }

    return (
        <Box>
            <Container maxWidth='sm' >
                <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"} my={2} >
                    <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"} >
                        <IconButton onClick={() => setForm(undefined)} aria-label='back button' >
                            <ArrowBack />
                        </IconButton>
                        <Typography sx={{ fontSize: 15, fontFamily: 'Futura', color: '#333', ml: 2 }} >
                            Back
                        </Typography>
                    </Stack>
                    <Avatar>H</Avatar>
                </Stack>
                <Typography sx={{ fontSize: 24, fontFamily: 'Futura', color: '#000', ml: 2, fontWeight: '700', my: 2, textDecorationLine: 'underline', }} >
                    Payment
                </Typography>

                <form onSubmit={handleSubmit(handlePay)}>
                    <Typography >
                        Order
                    </Typography>
                    {errors?.order && <span style={{ color: "red" }}>Oder number required</span>}
                    <Controller
                        control={control}
                        rules={{
                            required: true,
                            validate: () => { },
                            // pattern: 
                        }}
                        render={({ field: { onChange, value } }) => (
                            <FormControl fullWidth sx={{ my: 1, }}>
                                <Select
                                    labelId="demo-simple-select-helper-label"
                                    id="demo-simple-select-helper"
                                    value={value}
                                    color='primary'
                                    onChange={onChange}
                                >
                                    {
                                        orders?.map(order => (
                                            <MenuItem key={order?.key} value={order?._id}>{order?.key}</MenuItem>
                                        ))
                                    }
                                </Select>
                            </FormControl>
                        )}
                        name="order"
                    />
                    <Typography >
                        Amount
                    </Typography>
                    {errors?.amount && <span style={{ color: "red" }}>Amount required</span>}
                    <Controller
                        control={control}
                        rules={{
                            required: true,
                            validate: () => { },
                        }}
                        render={({ field: { onChange, value } }) => (
                            <TextField onChange={onChange} value={value} fullWidth id="outlined-basic" variant="outlined" />
                        )}
                        name="amount"
                    />
                    <YButton loading={isPaymentOrderByUser} type="submit" title={"Pay"} />
                </form>
            </Container>
        </Box>
    )
}

export default PaymentFormUser