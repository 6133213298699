import React, { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { useGetItemByKeyQuery } from '../../app/services/Api';
import LoadingComponent from "../LoadingComponent";
import ProductInfoSectionUpdate from "./ProductInfoSectionUpdate";
import { useSelector } from "react-redux";

const UpdateCartItem = ({ open, setOpen, itemKey, route, quantity, variantKey, itemCard }) => {

  const { isFetching: isLoading } = useGetItemByKeyQuery(itemKey, {refetchOnMountOrArgChange: true});
  const { item } = useSelector(state => state?.item)


  return (
    <Transition.Root show={open} as={"Fragment"}>
      <Dialog as="div" className="relative z-[510]" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 hidden transition-opacity bg-gray-500 bg-opacity-75 md:block" />
        </Transition.Child>

        <div className="fixed inset-0 z-[510] overflow-y-scroll h-[70%]">
          <div className="flex items-stretch justify-center min-h-full text-center md:items-center md:px-2 lg:px-4">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 md:translate-y-0 md:scale-95"
              enterTo="opacity-100 translate-y-0 md:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 md:scale-100"
              leaveTo="opacity-0 translate-y-4 md:translate-y-0 md:scale-95"
            >
              <Dialog.Panel className="flex w-full text-base text-left transition transform md:my-8 md:max-w-2xl md:px-4 lg:max-w-4xl">
                <div className="relative flex items-center w-full px-4 pb-8 overflow-hidden bg-white shadow-2xl pt-14 sm:px-6 sm:pt-8 md:p-6 lg:p-8">
                  <button
                    type="button"
                    className="absolute text-gray-400 top-4 right-4 hover:text-gray-500 sm:top-8 sm:right-6 md:top-6 md:right-6 lg:top-8 lg:right-8"
                    onClick={() => setOpen(false)}
                  >
                    <span className="sr-only">Close</span>
                    <XMarkIcon className="w-6 h-6" aria-hidden="true" />
                  </button>

                  <div className="grid items-start w-full grid-cols-1 gap-y-8 gap-x-6 sm:grid-cols-12 lg:gap-x-8">
                    <div className="overflow-hidden bg-gray-100 rounded-lg aspect-w-2 aspect-h-3 sm:col-span-4 lg:col-span-5">
                      <img
                        src={item?.mainPicture}
                        alt={"item_image"}
                        className="object-contain object-center"
                      />
                    </div>
                    <div className="sm:col-span-8 lg:col-span-7">
                      <ProductInfoSectionUpdate 
                        setOpen = {setOpen}
                        open={open}
                        route={route}
                        quantity={quantity}
                        variantKey={variantKey}
                        itemKey={itemKey}
                        itemCard={itemCard}
                      />
                    </div>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
      <LoadingComponent open={isLoading} />
    </Transition.Root>
  );
}

export default UpdateCartItem