import React from 'react'
import InputFlat from './InputFlat'
import { useTranslation } from 'react-i18next'
import FilterSelect from './common/FilterSelect'
import { useSelector } from 'react-redux'
import { Controller, useForm } from 'react-hook-form'
import { useGetCountryOfUserQuery, useGetSubDivisionsQuery } from '../app/services/Api'
import { handleTerritories } from '../app/features/territorySlice'
import { classNames, isRealValue } from '../utilities'
import LoadingComponent from './LoadingComponent'
import { dispatch } from '../app'
import LoadingButton from './common/LoadingButton'
import useMutationApi from '../app/hooks/useMutationApi'
import usePermission from '../app/hooks/usePermission'



const AddAddressForm = ({ closeModal }) => {
  const { lng } = useSelector(state => state.app);
  const { createShippingAddressByUser, isLoadingCreateAddress } = useMutationApi()
  const { coordinates, getCurrentPosition } = usePermission()

  const { t } = useTranslation()
  const display = v => v?.value[lng]
  const { division, parent, division1, division2, division3, } = useSelector(state => state.territories);
  const { handleSubmit, control, setValue, formState: { /*errors*/ } } = useForm({ defaultValues: { address: '', adminDivision3: "" } });

  const tabs = [
    { name: t("slt_arnd"), current: true },
    { name: t("add_cur_loc"), current: false },
  ];

  const [tabValue, setTabValue] = React.useState(tabs[0]);

  const handleTerritory = (parent, division) => dispatch(handleTerritories({ parent, division }))
  const handleSelectCountry = () => handleTerritory(country?._id, "division1")
  const selectDivision1 = (territory) => handleTerritory(territory?._id, "division2")
  const selectDivision2 = (territory) => handleTerritory(territory?._id, "division3")
  const selectDivision3 = (territory, onChange) => { setValue('address', territory?.value[lng]); onChange(territory?._id); }
  const handleSaveAddress = data => createShippingAddressByUser(data).then(() => closeModal())

  const { data: country, isFetching: isLoadingC } = useGetCountryOfUserQuery()
  const { isFetching: isLoadingD } = useGetSubDivisionsQuery({ parent, division }, { skip: !(parent !== null) })

  const GeoPositionAddress = () => {
    const { handleSubmit, control, formState: { /*errors*/ } } = useForm({ defaultValues: { address: '' } });
    const getLocation = data => {
      const body = {
        ...data,
        ...{
          gps: {
            lat: coordinates?.latitude,
            lon: coordinates?.longitude,
          },
        },
      }
      handleSaveAddress(body)
    }

    return (
      <div className='my-9'>
        <p>Save address with your actual position !</p>
        <Controller control={control} rules={{ required: true }}
          render={({ field: { onChange, value } }) => (
            <InputFlat label={"Address"} placeholder={'address'} value={value} onChange={onChange} />
          )}
          name="address"
        />
        <LoadingButton
          isLoading={isLoadingCreateAddress}
          title={t('Add address')}
          disabled={isLoadingCreateAddress}
          type={"submit"}
          onClick={handleSubmit(getLocation)}
        />
      </div>
    )
  }

  React.useEffect(() => {
    getCurrentPosition()
  }, [])

  return (
    <form
      className="overflow-y-scroll"
      onSubmit={handleSubmit(handleSaveAddress)}
    >
      {/* // Tabs */}
      <div className='mb-6' >
        <div className="sm:hidden">
          <label htmlFor="tabs" className="sr-only">
            Select a tab
          </label>
          {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
          <select
            id="tabs"
            name="tabs"
            className="block w-full rounded-md border-gray-300 focus:border-[#D90429] focus:ring-[#D90429]"
            defaultValue={tabs.find((tab) => tab.current).name}
            onClick={(e) => {
              setTabValue(e.target.value);
            }}
          >
            {tabs.map((tab) => (
              <option key={tab.name}>{tab.name}</option>
            ))}
          </select>
        </div>
        <div className="hidden sm:block">
          <nav
            className="isolate flex divide-x divide-gray-200 rounded-lg shadow"
            aria-label="Tabs"
          >
            {tabs.map((tab, tabIdx) => (
              <a
                key={tab.name}
                href={tab.href}
                onClick={() => setTabValue(tab.name)}
                className={classNames(
                  tabValue === tab.name
                    ? "text-gray-900"
                    : "text-gray-500 hover:text-gray-700",
                  tabIdx === 0 ? "rounded-l-lg" : "",
                  tabIdx === tabs.length - 1 ? "rounded-r-lg" : "",
                  "group relative min-w-0 flex-1 overflow-hidden bg-white py-4 px-4 text-sm font-medium text-center hover:bg-gray-50 focus:z-10"
                )}
                aria-current={tab.current ? "page" : undefined}
              >
                <span>{tab.name}</span>
                <span
                  aria-hidden="true"
                  className={classNames(
                    tabValue === tab.name ? "bg-[#D90429]" : "bg-transparent",
                    "absolute inset-x-0 bottom-0 h-0.5"
                  )}
                />
              </a>
            ))}
          </nav>
        </div>
      </div>

      {/* // Filters */}
     { tabValue === t("slt_arnd") && <div>
        <div className=" grid grid-cols-1 lg:space-x-2 my-2 md:grid-cols-2 lg:grid-cols-4 ">
          <FilterSelect
            defaultText={country?.name[lng] || "Country"}
            data={isRealValue(country) ? [country] : []}
            display={display}
            style={{ borderColor: "#00000030", borderWidth: "1px" }}
            onChange={handleSelectCountry}
            zIndex={50}
            key={1}
          />
          <FilterSelect
            data={division1}
            display={display}
            style={{ borderColor: "#00000030", borderWidth: "1px" }}
            onChange={selectDivision1}
            defaultText={division1[0]?.name[lng] || "Region"}
            zIndex={40}
            key={2}
          />
          <FilterSelect
            data={division2}
            display={display}
            style={{ borderColor: "#00000030", borderWidth: "1px" }}
            onChange={selectDivision2}
            defaultText={division2[0]?.name[lng] || "Department"}
            zIndex={30}
            key={3}
          />
          <Controller
            control={control}
            rules={{ required: true }}
            render={({ field: { onChange } }) => (
              <FilterSelect
                data={division3}
                display={display}
                style={{ borderColor: "#00000030", borderWidth: "1px" }}
                onChange={(v) => selectDivision3(v, onChange)}
                defaultText={division3[0]?.name[lng] || "Arrondissement"}
                zIndex={20}
                key={5}
              />
            )}
            name="adminDivision3"
          />
        </div>
        <Controller
          control={control}
          rules={{ required: true }}
          render={({ field: { onChange, value } }) => (
            <InputFlat
              label={"Address"}
              placeholder={"address"}
              value={value}
              onChange={onChange}
            />
          )}
          name="address"
        />
        <LoadingButton
          isLoading={isLoadingCreateAddress}
          title={t("Add address")}
          disabled={isLoadingCreateAddress}
          type={"submit"}
        />
      </div>
      }
     { tabValue === t("add_cur_loc") && <div>
        <GeoPositionAddress />
          <LoadingComponent open={isLoadingC || isLoadingD} />
        </div>
      }
    </form>
  );
}

export default AddAddressForm