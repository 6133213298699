import React, { useState } from 'react'
import { useSelector } from 'react-redux';
import AddressTableList from '../ShippingAddress/AddressTableList';
import { useGetAddressesByUserQuery } from '../../app/services/Api';
import ModalFlat from '../ModalFlat';
import AddAddressForm from '../AddAddressForm';
import { useTranslation } from 'react-i18next';
import AddressTableMobile from '../ShippingAddress/AddressTableMobile';
import { useMediaQuery } from '@mui/material';


const classNames =(...classes) =>{
  return classes.filter(Boolean).join(' ');
}

const SelectLocations = () => {
   
    const { addresses } = useSelector((state) => state.addresses);
    const { allLocations } = useSelector((state) => state.locations);
    const { requireDeposit } = useSelector((state) => state.orders);

  const resizeAdd = useMediaQuery("(max-width:768px)");
  const {t} = useTranslation()
  const { isFetching: isLoading } = useGetAddressesByUserQuery();
  const [isOpen, setIsOpen] = React.useState(false);
   const [tabValue, setTabValue] = useState(
     addresses.length < 1 ? t("pick_address") : t("delivery_area")
   );
  function closeModal() {
    setIsOpen(false);
  }
  function openModal() {
    setIsOpen(true);
  }

  const tabs = [
    {
      name: t("delivery_area"),
      href: "#",
      current: addresses.length < 1 ? false : true,
    },
    {
      name: t("pick_address"),
      href: "#",
      current: addresses.length < 1 ? true : false,
    },
  ];




  return (
    <>
      <div className=" my-4 md:px-0">
        <div className="py-6">
          <div className="lg:hidden">
            <label htmlFor="selected-tab" className="sr-only">
              Select a tab
            </label>
            <select
              id="selected-tab"
              name="selected-tab"
              onClick={(e) => {
                setTabValue(e.target.value);
              }}
              className="block w-full py-2 pl-3 pr-10 mt-1 text-base cursor-pointer border-gray-300 rounded-md focus:border-[#D90429] focus:outline-none focus:ring-[#D90429] sm:text-sm"
              defaultValue={tabs.find((tab) => tab.current).name}
            >
              {tabs.map((tab) => {
                if (tab?.name === t("delivery_area") && addresses.length < 1)
                  return null;
                return (
                  <option value={tab.name} key={tab.name}>
                    {tab.name}
                  </option>
                );
              })}
            </select>
          </div>
          <div className="hidden lg:block">
            <div className="border-b border-gray-200">
              <nav className="flex -mb-px space-x-8">
                {tabs.map((tab, i) => {
                  if (tab?.name === t("delivery_area") && addresses.length < 1)
                    return null;
                  return (
                    <a
                      key={tab.name}
                      onClick={() => setTabValue(tab.name)}
                      className={classNames(
                        tabValue === tab.name
                          ? "border-[#D90429] text-[#D90429]"
                          : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700",
                        "whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm cursor-pointer"
                      )}
                    >
                      {tab.name}
                    </a>
                  );
                })}
              </nav>
            </div>
          </div>
        </div>
      </div>
      {tabValue === t("delivery_area") && (
        <div>
          <div className="flex items-center flex-row justify-between my-2 ">
            <h4 className="block my-3 text-lg text-gray-800 align-baseline">
              {t("delivery_area")}
            </h4>
            <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
              <button
                type="button"
                onClick={openModal}
                className="inline-flex items-center justify-center px-4 py-2 text-sm font-medium text-white border border-transparent rounded-md shadow-sm bg-[#D90429] hover:bg-[#D9042990] focus:outline-none focus:ring-2 focus:ring-[#D90429] focus:ring-offset-2 sm:w-auto"
              >
                {t("add_address")}
              </button>
            </div>
          </div>

          {!resizeAdd && (
            <AddressTableList noCountry={true} addresses={addresses} />
          )}
          {resizeAdd && <AddressTableMobile addresses={addresses} />}
        </div>
      )}
      {tabValue === t("pick_address") && (
        <div>
          <div className="flex items-center flex-row justify-between my-2">
            <h4 className="block my-3 text-lg text-gray-800 align-baseline">
              {t("pick_address")}
            </h4>
            <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
              <button
                type="button"
                onClick={openModal}
                className="inline-flex items-center justify-center px-4 py-2 text-sm font-medium text-white border border-transparent rounded-md shadow-sm bg-[#D90429] hover:bg-[#D9042990] focus:outline-none focus:ring-2 focus:ring-[#D90429] focus:ring-offset-2 sm:w-auto"
              >
                {t("add_address")}
              </button>
            </div>
          </div>

          {!resizeAdd && <AddressTableList addresses={allLocations} />}
          {resizeAdd && <AddressTableMobile addresses={allLocations} />}
        </div>
      )}
      <ModalFlat
        isOpen={isOpen}
        children={<AddAddressForm closeModal={closeModal} />}
        title={"Add New Address"}
        closeModal={closeModal}
      />
    </>
  );
}

export default SelectLocations;