import React from 'react'

const Purchases = () => {
  return (
    <div>
      purchases
    </div>
  )
}

export default Purchases
