import React from 'react'
import { RadioGroup } from '@headlessui/react'
import { dispatch } from '../../app'
import { useSelector } from 'react-redux'
import { GiCommercialAirplane, GiCargoShip } from 'react-icons/gi'
import { handleSeaOrAir } from '../../app/features/itemSlice'
import { CheckCircleIcon } from '@heroicons/react/24/solid'
import { useTranslation } from 'react-i18next'
import { classNames, isRealValue } from '../../utilities'
import { useFormatter } from '../../app/hooks/useFormatter'
import LoadingButton from '../common/LoadingButton'

const DeliverySelectSection = () => {

  const { t } = useTranslation()
  const { lng } = useSelector((state) => state.app);
  const { item, seaOrAir } = useSelector(state => state.item)
  const { display, getOrderRouteData0, getOrderRouteData1 } = useFormatter();
  const canViewAirBtn = isRealValue(item?.basePrice?.cm?.airPrice) || (seaOrAir === false && isRealValue(item?.reducedPrice?.cm?.airPrice))

  const aerien = canViewAirBtn ? [{
    id: 1,
    title: display(getOrderRouteData1),
    icon: (checked) => (
      <GiCommercialAirplane
        className={classNames(
          !checked ? " text-gray-600" : "text-[#D90429]",
          "h-6 w-6 right-[45%] top-5"
        )}
        aria-hidden="true"
      />
    ),
    visible: canViewAirBtn
  }] : []

  const mailingLists = [].concat(aerien, [
    {
      id: 0,
      title: display(getOrderRouteData0),
      icon: (checked) => (
        <GiCargoShip
          className={classNames(
            !checked ? "text-gray-600" : "text-[#D90429]",
            "h-6 w-6 right-[45%] top-5 text-[#D90429]"
          )}
          aria-hidden="true"
        />
      ),
      visible: isRealValue(item?.basePrice?.cm?.seaPrice),
    }
  ])

  const [indexRoute, setIndexRoute] = React.useState(seaOrAir ? 0 : 1)

  const handleDeleryMethod = (val) => {
    setIndexRoute(val.id)
    dispatch(handleSeaOrAir(val?.id))
  }
  const [visible, setIsVisible] = React.useState(false);

  return (
    <>
      {!visible && canViewAirBtn && (
        <LoadingButton
          title={t("other_shipping_option")}
          onClick={() => setIsVisible(!visible)}
          style={{ margin: 0, backgroundColor: `rgb(234 88 12)` }}
        />
      )}
      {visible && (
        <RadioGroup
          checked={indexRoute}
          value={indexRoute}
          onChange={handleDeleryMethod}
        >
          <h2 className="text-sm font-medium text-gray-900">
            {t("slct_del_mthd")}
          </h2>

          <div className="mt-4 grid grid-cols-2 gap-y-6 sm:grid-cols-3 sm:gap-x-4">
            {mailingLists.map((mailingList, i) => {
              if (mailingList.visible) {
                return (
                  <RadioGroup.Option
                    key={i}
                    value={mailingList}
                    // onClick={() => handleDeleryMethod(i)}
                    className={({ checked }) =>
                      classNames(
                        checked ? "border-transparent" : "border-gray-300",
                        indexRoute === mailingList.id
                          ? "border-[#D90429] ring-2 ring-[#D90429]"
                          : "",
                        "relative flex cursor-pointer rounded-lg border bg-white p-4 shadow-sm focus:outline-none "
                      )
                    }
                  >
                    {({ _, active }) => (
                      <>
                        <span className="flex flex-1">
                          <span className="flex items-center space-x-2">
                            <RadioGroup.Label
                              as="span"
                              className="block text-md font-medium text-gray-900"
                            >
                              {mailingList.title}
                            </RadioGroup.Label>
                            <RadioGroup.Description
                              as="span"
                              className="mt-1 mx-auto flex items-center text-sm text-gray-500"
                            >
                              {mailingList.icon(indexRoute === mailingList.id)}
                            </RadioGroup.Description>
                          </span>
                        </span>
                        <CheckCircleIcon
                          className={classNames(
                            !(indexRoute === mailingList.id) ? "invisible" : "",
                            "h-5 w-5 text-[#D90429]"
                          )}
                          aria-hidden="true"
                        />
                        <span
                          className={classNames(
                            active ? "border" : "border-2",
                            indexRoute === mailingList.id
                              ? "border-[#D90429]"
                              : "border-transparent",
                            "pointer-events-none absolute -inset-px rounded-lg"
                          )}
                          aria-hidden="true"
                        />
                      </>
                    )}
                  </RadioGroup.Option>
                );
              }
            })}
          </div>
        </RadioGroup>
      )}
      {isRealValue(item?.info) && isRealValue(item?.info?.delivery) && (
          <div
            className="mt-2 text-sm font-medium text-gray-700 w-full bg-[#D90429] bg-opacity-80 p-3 rounded-md "
          >
            <span className="mx-auto text-white font-medium">
              {seaOrAir
                ? ` ${item?.info?.delivery?.sea[lng]}`
                : ` ${item?.info?.delivery?.air[lng]}`}
            </span>{" "}
          </div>
        )}
    </>
  );
}

export default DeliverySelectSection