import React, { useRef } from 'react'
import { useTranslation } from 'react-i18next';
import { IoCloseCircleSharp, IoPencilSharp } from 'react-icons/io5';
import NIC_img from "../../../assets/images/img.png";
import { isRealValue } from '../../../utilities';
import { motion } from "framer-motion";


const ImageSelect = ({ selectedImg, setSelectedImg }) => {

        const { t } = useTranslation();
  const rectoRef = useRef();

  const handleRectoIconClick = (e) => {
    rectoRef.current.click();
  };


     const handleImgChange = (event) => {
       const file = event.target.files[0];
       if (isRealValue(file)) {
         const reader = new FileReader();
         reader.readAsDataURL(file);
         reader.onloadend = () => {
           setSelectedImg({ uri: reader.result, file });
         };
       }
     };
  return (
    <div className="p-3 border-dashed border h-[fit-content] border-gray-400 rounded-md my-1 relative ">
      {selectedImg?.uri === undefined && (
        <div>
          <h4 className="text-lg font-semibold text-center text-gray-800">
            {t("pymt_prov")}
          </h4>
          <h6 className="text-sm font-medium text-center text-gray-600">
            {t("import_img")}
          </h6>
          <img
            onContextMenu={(event) => {
              event.preventDefault();
              window.open(event?.target?.currentSrc, "_blank");
            }}
            className="object-contain object-center mx-auto w-14 h-14 "
            src={NIC_img}
          />
        </div>
      )}
      {selectedImg?.uri != undefined && (
        <img
          onContextMenu={(event) => {
            event.preventDefault();
            window.open(event?.target?.currentSrc, "_blank");
          }}
          src={selectedImg?.uri}
          className="h-full w-[100%] object-contain object-center rounded-md"
          alt="user avatar logo"
        />
      )}
      <motion.div
        whileTap={{ scale: 0.95 }}
        whileHover={{ scale: 1.1 }}
        onClick={handleRectoIconClick}
        className="absolute z-30 flex flex-col items-center justify-center rounded-full cursor-pointer right-2 bottom-3 w-7 h-7 bg-[#D90429]"
      >
        <IoPencilSharp className="w-3 h-3 text-white " />
      </motion.div>
      {selectedImg?.file != null && (
        <motion.div
          whileTap={{ scale: 0.95 }}
          whileHover={{ scale: 1.1 }}
          onClick={() => setSelectedImg({ uri: null, file: null })}
          className="absolute z-30 flex flex-col items-center justify-center bg-red-500 rounded-full cursor-pointer left-2 top-3 w-7 h-7"
        >
          <IoCloseCircleSharp className="w-3 h-3 text-white " />
        </motion.div>
      )}
      <input
        type="file"
        ref={rectoRef}
        className="hidden"
        accept="image/*"
        onChange={handleImgChange}
      />
    </div>
  );
}

export default ImageSelect