import React from 'react'

import './styles.css'
import { useSelector } from 'react-redux'
import { useHomesItemsQuery } from '../../app/services/Api'
import { dispatch } from '../../app';

import ProductListItems from '../Products/ProductListItems'
import { handleContextSearch, handleKeyOfStoreOrCategory } from '../../app/features/AppSlice'
import LoadingComponent from '../../components/LoadingComponent'
import HomeSwiper from '../../components/HomeSwiper'
import useChangeLangParams from '../../app/hooks/useChangeLangParams'
import { isRealValue, scroolWindowToTop } from '../../utilities'
import ItemsListField from '../../components/common/ItemsListField'
import { useTranslation } from 'react-i18next'
import ModalFlat from '../../components/ModalFlat';
import UpdateItemToHomeForm from '../../components/UpdateItemToHomeForm';

const Home = () => {

  useChangeLangParams()
  const { t } = useTranslation()
  const { lng } = useSelector(state => state.app)
  const { isLogged, user } = useSelector(state => state.auth)

  const isForeignLog = isLogged && user?.role === "logistician-foreign"

  const [isVisible, setVisible] = React.useState(false)
  const [code, setCode] = React.useState("")
  const open = () => setVisible(true)
  const close = () => setVisible(false)

  const { data: datas, isLoading: isLoadingHome } = useHomesItemsQuery()
  const [homesItems, setHomesItems] = React.useState([])


  React.useEffect(() => {
    scroolWindowToTop();
    dispatch(handleContextSearch(null))
    dispatch(handleKeyOfStoreOrCategory(null))

    if (isRealValue(datas?.data)) setHomesItems(datas?.data ?? [])

  }, [datas?.data])

  const updateItemHome = (code) => {
    setCode(code)
    open()
  };

  return (
    <div className="py-8">
      <HomeSwiper />
      <div>
        <div>
          {
            homesItems?.map((section, index) => (
              <ItemsListField
                key={index}
                title={section?.name[lng]}
                children={
                  <ProductListItems
                    itemsFilter={section?.itemViews ?? []}
                    loading={isLoadingHome}
                  />
                }
                onClick={() => {
                  if (isForeignLog)
                    updateItemHome(section?._id)
                }}
              />
            ))
          }
        </div>
      </div>
      <LoadingComponent open={isLoadingHome} />
      {isForeignLog &&
        <ModalFlat
          isOpen={isVisible}
          children={<UpdateItemToHomeForm code={code} closeModal={close} />}
          title={t("add_item_home")}
          closeModal={close}
        />}
    </div>
  );
}

export default Home