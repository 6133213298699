import { ArrowBack } from '@mui/icons-material'
import { Box, Container, Stack, Typography, Avatar, TextField, IconButton} from '@mui/material'
import React from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useFormatter } from '../../../app/hooks/useFormatter'
import useMutationApi from '../../../app/hooks/useMutationApi'
import YButton from '../../../components/common/ButtonStyle'

const WithdrawFormUser = ({ setForm }) => {

    const { display, putOperationTransactionData } = useFormatter()
    const { withdrawalByCustomer, isWithdrawalByCustomer } = useMutationApi()
    const { control, handleSubmit, formState: { errors }, } = useForm({ defaultValues: { amount: "", operation: "withdrawal" } })
    const handleWithdrawal = (data) => {
        withdrawalByCustomer({
            amount: parseInt(data.amount, 10),
            operation: data.operation
        })
    }

    return (
        <Box>
            <Container maxWidth='sm' >
                <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"} my={2} >
                    <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"} >
                        <IconButton onClick={() => setForm(undefined)} aria-label='back button' >
                            <ArrowBack />
                        </IconButton>
                        <Typography  sx={{ fontSize: 15, fontFamily: 'Futura', color: '#333', ml: 2 }} >
                            Back
                        </Typography>
                    </Stack>
                    <Avatar>H</Avatar>
                </Stack>
                <Typography  sx={{ fontSize: 24, fontFamily: 'Futura', color: '#000', ml: 2, fontWeight: '700', my: 2, textDecorationLine: 'underline', }} >
                    Withdraw
                </Typography>
                <form onSubmit={handleSubmit(handleWithdrawal)}>
                    <Typography >
                        Operation
                    </Typography>
                    <TextField
                        type={"text"}
                        contentEditable={false}
                        value={display(putOperationTransactionData?.find(t => t.value === 'withdrawal'))}
                        fullWidth id="outlined-basic"
                        variant="outlined"
                        error={errors?.operation ? true : false}
                    />
                    <Typography >
                        Amount
                    </Typography>
                    <Controller
                        control={control}
                        rules={{
                            required: true,
                        }}
                        render={({ field: { onChange, value } }) => (
                            <TextField
                                onChange={onChange}
                                value={value}
                                fullWidth
                                id="outlined-basic"
                                variant="outlined"
                                error={errors?.amount ? true : false}
                            />
                        )}
                        name="amount"
                    />
                    <YButton loading={isWithdrawalByCustomer} title={"Ok"} type="submit" />
                </form>
            </Container>
        </Box>
    )
}

export default WithdrawFormUser