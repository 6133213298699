import React from 'react'
import { useSelector } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import { handleHeaderType } from '../../app/features/authSlice'
import CardComponent from '../../components/common/card'
import ConfirmForgotForm from '../../components/layout/ConfirmForgotForm'
import { HOME } from '../../constants/routes'
import { dispatch } from '../../app';
import logo from "../../assets/images/Logo_AFLP.png";
import { scroolWindowToTop } from '../../utilities'


const ConfirmForgetPassword = () => {
  const navigate = useNavigate()
  const { isLogged } = useSelector(state => state.auth)
  if (isLogged) navigate(`${HOME}`)


  dispatch(handleHeaderType({ headerType: "none", isStore: false, header: false, checkout: false }))

  React.useEffect(() => {
    scroolWindowToTop()
  }, [0])


  return (
    <div>
      <Link className="cursor-pointer" to={HOME}>
        <img
          onContextMenu={(event) => {
            event.preventDefault();
            window.open(event?.target?.currentSrc, "_blank");
          }}
          src={logo}
          className="w-20 h-20 object-contain object-center mx-auto my-4 "
        />
      </Link>
      <CardComponent title={"Confirm Reset Password"}>
        <h2>Confirm Password ?</h2>
        <p className="auth-text" style={{ width: "75%", marginBottom: 25 }}>
          Enter your new password.
        </p>
        <ConfirmForgotForm />
      </CardComponent>
    </div>
  );
}

export default ConfirmForgetPassword