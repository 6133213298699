import { Box, Checkbox, FormControlLabel, Grid, Link, Typography } from "@mui/material"
import React from "react"
import { diffLocalDate } from "../../../utilities"
import plane from '../../../assets/images/plane-black.svg'
import cargo from '../../../assets/images/cargo-ship.svg'
import { ORDER_HISTORY_DETAILS } from "../../../constants/routes"

 export const RowsOrders = (props) => {
    const { matches, matchesXS, order } = props
    return (
      <Box width={"100%"}>
        <div>
          status: {order?.status}. content: {order?.items?.length} product(s).
          TotalAmount : {order?.balance}. amountPaid: {order?.amountPaid}
          <br />
          <Link
            variant="body2"
            href={`${ORDER_HISTORY_DETAILS}/${order?.order}`}
          >
            buy
          </Link>
        </div>
        <FormControlLabel
          value="account"
          control={<Checkbox color="secondary" sx={{ color: "#D90429" }} />}
          sx={{ fontSize: 14 }}
          label={
            diffLocalDate(order?.created) +
            ` No ${order?.key} ${order?.storeName?.en || order?.storeName?.fr}`
          }
        />
        {order?.items.map((item, index) => (
          <Grid container item xs>
            <Grid container item xs={matchesXS ? 12 : 4}>
              <div
                className="row-flex"
                style={{
                  justifyContent: "flex-start",
                  alignItems: "flex-start",
                }}
              >
                <img onContextMenu = { (event)=> { event.preventDefault();    }  }
                  style={{ width: 120 }}
                  src={item?.picture}
                  alt="cart item img"
                />
                <div className="cart-item">
                  <span className="cart-item-title1">{"product name"}</span>
                  <div
                    className="row-flex"
                    style={{ justifyContent: "flex-start", width: "100%" }}
                  >
                    {/* <div style={{ height: 20, width: 20, borderColor: 'red', borderWidth: 1, borderRadius: 50, backgroundImage: `url(${ item?.configColor?.miniPictureUrl})`, backgroundSize: '100% 100%'}}>ds</div> */}
                    <div
                      className="color-holder"
                      style={{
                        backgroundImage: `url(${item?.picture})`,
                        backgroundSize: "100% 100%",
                      }}
                    ></div>
                    {item?.variants?.map((variant, index) => (
                      <span key={index + "variant"} className="gray">
                        {variant?.propertyValue?.en ||
                          variant?.propertyValue?.fr}
                      </span>
                    ))}
                  </div>
                </div>
              </div>
            </Grid>
            <Grid container item xs={matchesXS ? 12 : 8}>
              <Grid
                item
                xs={12}
                sm={12}
                md
                lg
                container
                alignItems={"center"}
                justifyContent={matches ? "flex-end" : "center"}
              >
                <div className="row-flex select2">
                  {matches && (
                    <Typography
                      
                      sx={{ fontWeight: "bold", mr: 1 }}
                      className="title_filter"
                    >
                      routing:
                    </Typography>
                  )}
                  <img onContextMenu = { (event)=> { event.preventDefault();    }  }
                    width={20}
                    src={item?.routing === "air" ? plane : cargo}
                    alt="air"
                  />
                </div>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md
                lg
                container
                justifyContent={matches ? "flex-end" : "center"}
              >
                <div className="row-flex-space">
                  {matches && (
                    <Typography
                      
                      sx={{ fontWeight: "bold", mr: 1 }}
                      className="title_filter"
                    >
                      Unit price:
                    </Typography>
                  )}
                  <Typography  className="title_filter">
                    {item?.unitPrice}
                  </Typography>
                </div>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md
                lg
                container
                justifyContent={matches ? "flex-end" : "center"}
              >
                <div className="row-flex-space">
                  {matches && (
                    <Typography
                      
                      sx={{ fontWeight: "bold", mr: 1 }}
                      className="title_filter"
                    >
                      Quantity:
                    </Typography>
                  )}
                  <Typography  className="title_filter">
                    {item?.quantity}
                  </Typography>
                </div>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md
                lg
                container
                justifyContent={matches ? "flex-end" : "center"}
              >
                <div className="row-flex-space">
                  {matches && (
                    <Typography
                      
                      sx={{ fontWeight: "bold", mr: 1 }}
                      className="title_filter"
                    >
                      Total price:
                    </Typography>
                  )}
                  <Typography  className="title_filter">
                    {item?.totalPrice}
                  </Typography>
                </div>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md
                lg
                container
                justifyContent={matches ? "flex-end" : "center"}
              >
                <div className="row-flex-space">
                  {matches && (
                    <Typography
                      
                      sx={{ fontWeight: "bold", mr: 1 }}
                      className="title_filter"
                    >
                      Status:
                    </Typography>
                  )}
                  <Typography  className="title_filter">
                    {item?.status}
                  </Typography>
                </div>
              </Grid>
            </Grid>
          </Grid>
        ))}
      </Box>
    );
}
