import { useLiveQuery } from 'dexie-react-hooks';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useBookmarkCardItemsOfflineMutation } from '../services/Api';
import { db } from './aflpDb';
import React from 'react';
import { toJson, toString } from '../../utilities';
import { handleSetProposeSizeTyped } from '../features/itemSlice';
import { dispatch } from '../..';

db.open()

export function useRedirection() {
       async function getReturnLinkForAfterLogin() {
              const result = await db.returnPages.toArray()
              return result.length > 0 ? result[0] : null
       }
       async function setReturnLinkForAfterLogin(linkPage, stringifyStateJson) {
              try {
                     await db.returnPages.delete(linkPage);
                     await db.returnPages.add({ linkPage, stringifyStateJson });
              } catch (error) {
              }
       }
       async function deleteReturnLinkForAfterLogin(linkPage) {
              try {
                     await db.returnPages.delete(linkPage);

              } catch (error) {
              }
       }
       return {
              setReturnLinkForAfterLogin, getReturnLinkForAfterLogin,
              deleteReturnLinkForAfterLogin,
       }
}

export function useLocalDbHook() {
       const { isLogged } = useSelector(state => state.auth)

       const [isLoadingAddBookmark, setIsLoadingAddBookmark] = useState(false);
       const [isLoadingAddBookmarkStores, setIsLoadingAddBookmarkStores] = useState(false);
       const [bookmarkItemsOff, setBookmarkKeyOffline] = useState([]);
       const [bookmarkStoresOff, setBookmarkStoresOffline] = useState([]);
       const [bookmarkCartsOff, setBookmarkCartsOffline] = useState([]);

       const [bookmarkCardItemsOffline] = useBookmarkCardItemsOfflineMutation()

       React.useEffect(() => { }, [])

       async function addOrRemoveBookmarkItem(key) {
              try {
                     setIsLoadingAddBookmark(true)
                     if (bookmarkItemsOff?.map(v => v.key).includes(key)) {
                            await db.bookmarksItems.delete(
                                   key
                            )
                     } else {
                            await db.bookmarksItems.add({
                                   key
                            })
                     }
                     setIsLoadingAddBookmark(false)

              } catch (error) {
                     setIsLoadingAddBookmark(false)
              }
       }
       async function addOrRemoveBookmarkStores(key) {
              try {
                     setIsLoadingAddBookmarkStores(true)
                     if (bookmarkStoresOff?.map(v => v.key).includes(key)) {
                            await db.bookmarksStore.delete(
                                   key
                            )
                     } else {
                            await db.bookmarksStore.add({
                                   key
                            })
                     }
                     setIsLoadingAddBookmarkStores(false)

              } catch (error) {
                     setIsLoadingAddBookmarkStores(false)
              }
       }
       async function addBookmarkCart(cart) {
              await db.bookmarksCart.put(cart, { itemKey: cart?.itemKey, variantKey: cart?.variantKey })
       }
       async function removeBookmarkCart(arrayItemKeyVariantKey) {
              arrayItemKeyVariantKey?.map(a => db.bookmarksCart.delete([a.itemKey, a?.variantKey]))
       }

       async function proposeSizeTypedQuery() {
              const result = await db.proposeSizes.toArray()
              if (result?.length > 0) {
                     var toSet = {}
                     for (const [key, value] of Object.entries(result)) {
                            toSet[`${value?.key}`] = value?.value
                     }
                     dispatch(handleSetProposeSizeTyped(toSet))
              }
       }
       async function proposeSizeTypedUpdate(jsonArray = []) {
              await db.proposeSizes.clear()
              await db.proposeSizes.bulkAdd(jsonArray)
       }

       useLiveQuery(
              async () => {
                     if (!isLogged) {
                            const result = await db.bookmarksItems.toArray()
                            setBookmarkKeyOffline(result)
                     }
              }
       );
       useLiveQuery(
              async () => {
                     if (!isLogged) {
                            const result = await db.bookmarksStore.toArray()
                            setBookmarkStoresOffline(result)
                     }
              }
       );
       useLiveQuery(
              async () => {
                     if (!isLogged) {
                            const result = await db.bookmarksCart.toArray()
                            setBookmarkCartsOffline(result)
                            bookmarkCardItemsOffline(result)
                     }
              }
       );

       return {
              addOrRemoveBookmarkItem, isLoadingAddBookmark, bookmarkItemsOff,
              bookmarkStoresOff, isLoadingAddBookmarkStores, addOrRemoveBookmarkStores,
              bookmarkCartsOff, addBookmarkCart, removeBookmarkCart,
              proposeSizeTypedQuery, proposeSizeTypedUpdate
       }

}
