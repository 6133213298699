import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    division: null,
    parent: null,
    division1: [],
    division2: [],
    division3: [],
}

const territorySlice = createSlice({
    name: 'territories',
    initialState,
    reducers: {
        handleDivisions(state, action){
            const { division , territories} = action.payload
            state[division] =territories
        },
        handleCountryUser(state, action){
            state.country = action.payload
        },
        handleTerritories(state, action){
            const {parent, division }= action.payload
            if("division1" === division) {
                state.division1=[]
                state.division2=[]
                state.division3=[]
            }else if("division2" === division) {
                state.division2=[]
                state.division3=[]
            }else if("division3" === division) {
                state.division3=[]
            }
            state.parent = parent
            state.division = division
        },
    },
})

export const {
    handleDivisions,
    handleTerritories,
    handleCountryUser,

} = territorySlice.actions

export default territorySlice