
import { Box, MenuItem, Typography } from "@mui/material";
import React, { useRef } from "react";
import { ChevronRightOutlined } from "@mui/icons-material";
import { useFormatter } from "../../../app/hooks/useFormatter";
import { useNavigate } from "react-router-dom";
import { dispatch } from "../../..";
import { Api } from "../../../app/services/Api";
import { PRODUCT } from "../../../constants/routes";
import { useSelector } from "react-redux";
import { handleKeyCat } from "../../../app/features/AppSlice";
import { classNames } from "../../../utilities";

export default function MenuItemsMobile(props) {
  const { items, category, selected, handleClick, sizeSubMenu, setAnchorEl, level } =
    props;
  const { display } = useFormatter()
  const { lng } = useSelector(
    (state) => state.app
  );

  const spanRef = useRef()

  const navigate = useNavigate();


    const handlerItemsByCategory = () => {

         setAnchorEl(null);
      // dispatch(handleKeyCat(items?.key))

      navigate(`${PRODUCT}/${items.key}?page=1`);
          dispatch({
            type: "categories/selectedCategoryItem",
            payload: items?.name[lng],
          });
          dispatch(
            Api.endpoints.getItemsByCategory.initiate({ key: items?.key })
          );
    }

    const handleNextLevelClick = (event)=> {
        event.preventDefault();
        sizeSubMenu > 0 ? handleClick() : handlerItemsByCategory()
    }
    return (
      <MenuItem onClick={(e) => handleNextLevelClick(e)} selected={selected}>
        <Box className="row-flex">
          {items?.icon && (
            <Typography
              className="row-flex circle"
              sx={{
                width: "35px",
                height: "35px",
              }}
            >
              <img
                onContextMenu={(event) => {
                  event.preventDefault();
                  window.open(event?.target?.currentSrc, "_blank");
                }}
                src={items.icon}
                alt="logos"
              />
            </Typography>
          )}
          <Typography
            style={{
              color: "var(--title-gray)",
              fontSize: 16,
              fontWeight: "500",
              marginLeft: "0.5em",
              padding: 2,
            }}
          >
            {level === 3 ? `${items?.name[lng]}...` : items?.name[lng]}
          </Typography>
          <Typography
            className={classNames(level === 3 ? "row-flex" : "", "row-flex")}
            sx={{ right: 0, position: "absolute" }}
          >
            {sizeSubMenu > 0 ? (
              <ChevronRightOutlined fontSize="small" />
            ) : (
              <></>
            )}
          </Typography>
        </Box>
      </MenuItem>
    );
}