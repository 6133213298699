import { Checkbox, CircularProgress, Tooltip, useMediaQuery } from "@mui/material"
import { useSelector } from "react-redux"
import {
  handleSelectedItem,
  handleSetUpdateKey,
  handleItemToUpdate,
  handleUpdateImg,
} from "../../app/features/bookmarkCardItemsSlice";
import { classNames, getFakeName, isRealValue } from "../../utilities"
import { dispatch } from "../../app"
import useMutationApi from "../../app/hooks/useMutationApi"
import { useTranslation } from "react-i18next"
import { IoHeartCircleSharp } from "react-icons/io5"
import { useNavigate } from "react-router-dom"
import { PRODUCT_DETAILS } from "../../constants/routes"
import React, { useState } from "react"
import UpdateCartItem from "../../components/CartCheckout/UpdateCartItem"
import { useFormatter } from "../../app/hooks/useFormatter";
import { useLocalDbHook } from "../../app/localDatabase/useLocalDbHook";

const TableRowCards = (props) => {
  const resizeTxt = useMediaQuery("(min-width:1024px)");
  const { item = {}, store, labelId } = props
  const { bookmarkItemsOff } = useLocalDbHook()
  const navigate = useNavigate();
  const { formatPrice } = useFormatter();

  const [openUpdate, setOpenUpdate] = useState(false)
  const [isChecked, setIsChecked] = useState(true);

  const { cartItemsSelected } = useSelector((state) => state.bookmarkCard);
  const { lng } = useSelector(state => state.app)
  const { isLogged } = useSelector(state => state.auth)
  const { items } = useSelector((state) => state.bookmarkItems);
  const { t } = useTranslation()
  const {
    handleAddBookmarkItem, deleteToCartItemsByUser,
    isLoadingFavorite, loadingDeleteBookmarkCard,
  } = useMutationApi();

  const isFavorite = () => {
    const itemFav = isLogged ? items?.find((article) => article?.key === item?.key) : bookmarkItemsOff?.find(v => v.key === item?.key)
    if (itemFav) {
      return true;
    }
    return false;
  }

  const handlerDetailsItems = function (e) {
    e.preventDefault()
    navigate(`${PRODUCT_DETAILS}/${item?.key}`);
  };

  const handleUpdate = function (e) {
    e.preventDefault()
    dispatch(handleSetUpdateKey(item?.key));
    dispatch(handleItemToUpdate({ item, store }));
    dispatch(handleUpdateImg(item?.picture));
    setOpenUpdate(true);
  };
  const handleSelectItem = () => dispatch(handleSelectedItem({ ...item, store }))
  const isSelected = () => isRealValue(cartItemsSelected.find(i => i.key === item?.key && i.variantKey === item?.variantKey))

  const handleDeleteCarItem = () => {
    if (isSelected()) handleSelectItem()
    deleteToCartItemsByUser(isLogged ? [{ variantKey: item?.variantKey, route: item?.route }] : [{ itemKey: item?.key, variantKey: item?.variantKey }])
  }

  React.useEffect(() => {
    if (isSelected()) {
      setIsChecked(true);
    } else {
      setIsChecked(false);
    }
  }, [cartItemsSelected]);

  return (
    <li className="relative flex px-3 my-5 sm:items-center"  >
      <div>
        <div
          className={classNames(
            resizeTxt ? "w-[310px]" : "w-full ",
            " flex flex-row items-start justify-start"
          )}
        >
          <div className="flex items-start ml-1 lg:items-center ">
            <div className="flex items-center h-24 lg:h-32">
              <Checkbox
                onClick={handleSelectItem}
                checked={isChecked}
                inputProps={{ "aria-labelledby": labelId }}
                sx={{ color: "#D90429", margin: 0, padding: 0 }}
              />
            </div>
            <img
              onContextMenu={(event) => {
                event.preventDefault();
                window.open(event?.target?.currentSrc, "_blank");
              }}
              onClick={handlerDetailsItems}
              className="flex-none w-24 h-24 mr-2 border border-gray-200 rounded-lg cursor-pointer lg:h-32 lg:w-32"
              src={item?.picture /*item?.configColor?.pictureUrl*/}
              alt="cart item img"
            />
            <div>
              <p
                onClick={handlerDetailsItems}
                className="text-base font-medium text-gray-700 cursor-pointer hover:underline max-w-[200px] overflow-hidden truncate"
              >
                {item?.description[lng]}
              </p>
              <div>
                <div>
                  {item.variants.map((variant) => (
                    <div className="text-sm text-gray-600 ">
                      <span className="text-[#D90429] text-xs font-semibold">
                        {variant?.propertyName[lng]}
                      </span>{" "}
                      <Tooltip title={variant?.propertyValue[lng]}>
                        <span className="line-clamp-1">
                          {variant?.propertyValue[lng]}
                        </span>
                      </Tooltip>
                    </div>
                  ))}
                </div>
                {!resizeTxt && (
                  <div>
                    <div className="flex items-center justify-start space-x-6 ">
                      <h5 className="text-[#D90429] text-xs font-semibold ">
                        {t("route")}
                      </h5>
                      <div className="flex flex-row justify-center p-1 rounded-3xl">
                        <h4 className="text-base font-normal text-gray-700">
                          {item?.route === "air" ? t("air") : t("sea")}
                        </h4>
                      </div>
                    </div>
                    <div className="flex items-center justify-start space-x-6 ">
                      <h5 className="text-[#D90429] text-xs font-semibold ">
                        {t("price")}
                      </h5>
                      <div className=" md:flex-1 md:flex lg:justify-center">
                        <h6 style={{ marginRight: 2 }} >{formatPrice(item?.unitPrice)}</h6>
                        {item?.unitPrice != item?.basePrice && (
                          <h6
                            style={{
                              textAlign: "center",
                              color: "#c0c0c0",
                              textDecoration: "line-through",
                              fontSize: 14,
                            }}
                          >
                            {formatPrice(item?.basePrice)}
                          </h6>
                        )}
                      </div>
                    </div>
                    <div className="flex items-center justify-start space-x-6 ">
                      <h5 className="text-[#D90429] text-xs font-semibold ">
                        {t("quantity")}
                      </h5>
                      <div style={{ paddingLeft: 15, paddingRight: 15 }}>
                        <span
                          style={{
                            marginLeft: 3,
                            marginRight: 3,
                            fontSize: 16,
                          }}
                        >
                          {item?.quantity}
                        </span>
                      </div>
                    </div>
                    <div>
                      <div className="relative flex items-center justify-start space-x-6">
                        <h5 className="text-[#D90429] text-xs font-semibold ">
                          {t("tot_price")}{" "}
                        </h5>
                        {"  "}
                        {formatPrice(item?.unitPrice * item?.quantity)}
                      </div>
                    </div>
                    <div
                      className="relative sm:absolute sm:bottom-2 sm:right-3 "
                      style={{
                        justifyContent: "center",
                        alignItems: "center",
                        paddingTop: 10,
                      }}
                    >
                      {loadingDeleteBookmarkCard ? (
                        <CircularProgress
                          size={22}
                          sx={{ color: "#D90429" }}
                        />
                      ) : (
                        <span
                          className="pointer w-[fit-content] hover:underline hover:text-[#D90429] text-red-800"
                          onClick={(e) => { e.preventDefault(); handleDeleteCarItem() }}
                        >
                          {t("del")}
                        </span>
                      )}
                      <span
                        className="pointer hover:underline hover:text-[#D90429] text-red-800"
                        onClick={() => handleAddBookmarkItem(item?.key)}
                      >
                        {isFavorite()
                          ? t("rem_to_favorite")
                          : t("add_to_favorite")}
                      </span>
                      {isLoadingFavorite && (
                        <CircularProgress
                          size={22}
                          sx={{ color: "#D90429" }}
                        />
                      )}

                      <span
                        className="pointer hover:underline line-clamp-1 hover:text-[#D90429] text-red-800"
                        onClick={handleUpdate}
                      >
                        {t("edit")}
                      </span>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      {resizeTxt && (
        <div className=" md:flex-1 md:flex lg:justify-center">
          <div className="flex flex-row justify-center p-1 rounded-3xl">
            <h4 className="text-base font-normal text-gray-700">
              {item?.route === "air" ? t("air") : t("sea")}
            </h4>
          </div>
        </div>
      )}
      {resizeTxt && (
        <div className=" md:flex-1 md:flex-col lg:items-center">
          <h6 style={{ textAlign: "center" }}>
            {formatPrice(item?.unitPrice)}
          </h6>
          {item?.unitPrice != item?.basePrice && (
            <h6
              style={{
                textAlign: "center",
                color: "#c0c0c0",
                textDecoration: "line-through",
                fontSize: 14,
              }}
            >
              {formatPrice(item?.basePrice)}
            </h6>
          )}
        </div>
      )}
      {resizeTxt && (
        <div className=" md:flex-1 md:flex lg:justify-center">
          <div style={{ paddingLeft: 15, paddingRight: 15 }}>
            <span style={{ marginLeft: 3, marginRight: 3, fontSize: 16 }}>
              {item?.quantity}
            </span>
          </div>
        </div>
      )}
      {resizeTxt && (
        <div className=" md:flex-1 md:flex lg:justify-center">
          {formatPrice(item?.unitPrice * item?.quantity)}
        </div>
      )}
      {resizeTxt && (
        <div className="flex flex-col items-end justify-center flex-1 ">
          {loadingDeleteBookmarkCard ? (
            <CircularProgress size={22} sx={{ color: "#D90429" }} />
          ) : (
            <span
              className="cursor-pointer line-clamp-1 hover:underline hover:text-[#D90429] text-red-800"
              onClick={(e) => { e.preventDefault(); handleDeleteCarItem() }}
            >
              {t("del")}
            </span>
          )}
          <span
            className="cursor-pointer line-clamp-1 hover:underline hover:text-[#D90429] text-red-800"
            onClick={() => handleAddBookmarkItem(item?.key)}
          >
            {isFavorite() ? t("rem_to_favorite") : t("add_to_favorite")}
          </span>
          {isLoadingFavorite && (
            <CircularProgress size={22} sx={{ color: "#D90429" }} />
          )}

          <span
            className="cursor-pointer hover:underline line-clamp-1 ml-3 hover:text-[#D90429] text-red-800"
            onClick={handleUpdate}
          >
            {t("edit")}
          </span>

        </div>
      )}
      {isFavorite() && (
        <div className="absolute top-0 right-10">
          <IoHeartCircleSharp
            className={classNames(" w-6 h-6 text-[#D90429] ")}
          />
        </div>
      )}
      {openUpdate && (
        <UpdateCartItem
          open={openUpdate}
          setOpen={setOpenUpdate}
          itemKey={item?.key}
          route={item?.route}
          quantity={item?.quantity}
          variantKey={item?.variantKey}
          itemCard={item}
        />
      )}
    </li>
  );
}

export const TableRowCard = React.memo(TableRowCards)
