import React from 'react'
import { RadioGroup } from '@headlessui/react'
import { getCardImage } from '../app/hooks/useCustomStripe';

import { useSelector } from 'react-redux';
import { handleCurrencyCard, handleSelectCard } from '../app/features/StripeSlice';
import { dispatch } from '..';
import useMutationApi from '../app/hooks/useMutationApi';
import { useFormatter } from '../app/hooks/useFormatter';
import { isRealValue } from '../utilities';
import { useEuroRelatedQuery } from '../app/services/Api';
import { Controller, useForm } from 'react-hook-form';
import InputFlatBorder from './common/InputFlatBorder';
import { IoCashOutline } from 'react-icons/io5';
import { useTranslation } from 'react-i18next';
import LoadingButton from './common/LoadingButton';
import { CircularProgress } from '@mui/material';


export default function CreditCardItem({ updateAmount, totalAmount = 0, isFirstOrdered = true, showButton }) {

  const { cardList, card, currencyCard, euroRelated, amountToPay } = useSelector(state => state.stripe)
  const { requireDeposit } = useSelector(state => state.orders);
  const { order } = useSelector(state => state.bookmarkCard);
  const [isOpen, setIsOpen] = React.useState(false)
  const { t } = useTranslation()

  const { formatPrice, formatAmount } = useFormatter()

  const {
    isCreateOrder,
    isCreateIntentionPayment,
    isConfirmIntentionPayment,
    isLoadingPaymentForex,
    buildOrderAndPayWithCardByUser,
    buildOrderAndPayForexByUser,
    handleAmountToPayXaf,
  } = useMutationApi()

  const payWithCardCredit = (e) => {
    e.preventDefault();
    if (euroRelated?.currency === "XAF") {
      buildOrderAndPayWithCardByUser()
    } else {
      const body = {
        amount: amountToPay,
        amountConverted: euroRelated?.amount,
        currency: euroRelated?.currency
      }
      buildOrderAndPayForexByUser(card, body)
    }
  }

  const open = () => setIsOpen(!isOpen)
  const handleSelectCards = (id, index) => dispatch(handleSelectCard({ id, index }))
  const { control, clearErrors, setError, setValue, formState: { errors } } = useForm({ defaultValues: isFirstOrdered ? { amount: "" } : {} })

  const onSelected = (e) => {
    e.preventDefault();
    const v = e?.target.value
    dispatch(handleCurrencyCard(v))
  }

  const handleAmount = (e, onChange) => {
    e.preventDefault()
    const v = e.target.value
    if (!isFirstOrdered) {
      updateAmount(v)
    }
    onChange(v)
    if (isRealValue(v)) {
      handleAmountToPayXaf(v)
      if (Number(v) < (isFirstOrdered ? requireDeposit : 1) || Number(v) > totalAmount) {
        setError("amount", { type: "required" })
        return false
      } else {
        clearErrors("amount")
        return true
      }
    } else {
      handleAmountToPayXaf(0)
    }
  }
  const { isFetching: isLoading } = useEuroRelatedQuery({ card, amount: amountToPay }, {
    skip: !(isRealValue(card) && isRealValue(amountToPay) && (isFirstOrdered ? requireDeposit : 1 <= Number(amountToPay) <= totalAmount)),
    refetchOnMountOrArgChange: true
  })

  const ForexType = () => {
    return (
      <div className="w-full max-w-xl mx-auto">
        {!isLoading &&
              isRealValue(euroRelated) &&
              euroRelated?.currency !== "XAF" && <h4 className="inline-block text-lg text-gray-800 align-baseline">
          {t("slct_curr")}
        </h4>}
        <fieldset className="mx-auto mt-2">
          <div className="divide-x divide-gray-200 md:flex md:flex-row md:items-center md:justify-around">
            {isLoading && <CircularProgress sx={{ color: "#D90429" }} />}
            {!isLoading &&
              isRealValue(euroRelated) &&
              euroRelated?.currency !== "XAF" && (
                <>
                  <div
                    key={"accountIdx"}
                    className="relative flex items-start py-4"
                  >
                    <div className="flex-1 min-w-0 text-sm">
                      <label
                        htmlFor={`account-xaf`}
                        className="font-medium text-gray-700"
                      >
                        {formatAmount(amountToPay)}
                      </label>
                      <p
                        id={`account-xaf-description`}
                        className="text-gray-500"
                      >
                        XAF
                      </p>
                    </div>
                    <div className="flex items-center h-5 ml-3">
                      <input
                        id={`account-xaf`}
                        aria-describedby={`account-xaf-description`}
                        name="currency"
                        type="radio"
                        defaultChecked={currencyCard === "XAF"}
                        value={"XAF"}
                        onChange={onSelected}
                        className="w-4 h-4 text-indigo-600 border-gray-300 focus:ring-indigo-500"
                      />
                    </div>
                  </div>

                  <div
                    key={"accountIdx"}
                    className="relative flex items-start py-4"
                  >
                    <div className="flex-1 min-w-0 text-sm">
                      <label
                        htmlFor={`account-c`}
                        className="font-medium text-gray-700"
                      >
                        {euroRelated?.amount.toString()}
                      </label>
                      <p id={`account-c-description`} className="text-gray-500">
                        {euroRelated?.currency}
                      </p>
                    </div>
                    <div className="flex items-center h-5 ml-3">
                      <input
                        id={`account-c`}
                        aria-describedby={`account-c-description`}
                        name="currency"
                        type="radio"
                        value={euroRelated?.currency}
                        onChange={onSelected}
                        // checked={currencyCard === euroRelated?.currency}
                        defaultChecked={currencyCard === euroRelated?.currency}
                        className="w-4 h-4 text-indigo-600 border-gray-300 focus:ring-indigo-500"
                      />
                    </div>
                  </div>
                </>
              )}
          </div>
        </fieldset>
      </div>
    );
  }


  return (
    <div className="w-full px-1 py-16 mb-10  ">
      <div className="w-full h-[620px] overflow-y-scroll max-w-md mx-auto">
        <Controller
          control={control}
          rules={{
            required: true,
            validate: (v) => {
              if (
                Number(v) < isFirstOrdered
                  ? requireDeposit
                  : 1 || Number(v) > totalAmount
              ) {
                setError("amount", { type: "required" });
                return false;
              }
              return true;
            },
          }}
          name="amount"
          render={({ field: { onChange, value } }) => (
            <InputFlatBorder
              label={t("Amount")}
              rightIcon={<IoCashOutline className="h-5 w-5 text-[#D90429]" />}
              placeholder={"Amount"}
              onChange={(e) => handleAmount(e, onChange)}
              value={value}
              error={
                errors.amount
                  ? `${t("payment_btn")} ${formatPrice(
                    isFirstOrdered ? requireDeposit : 1
                  ).substring(
                    0,
                    formatPrice(isFirstOrdered ? requireDeposit : 1).length -
                    5
                  )} - ${formatPrice(totalAmount)}`
                  : undefined
              }
            />
          )}
        />
        <RadioGroup>
          <div className="space-y-2">
            {cardList?.length < 1 && (
              <h4 className="mb-4 text-center text-gray-800 text-md">
                {t("no_credit_cards")}
              </h4>
            )}
            {cardList?.length >= 1 &&
              cardList.map((card, index) => (
                <RadioGroup.Option
                  key={index}
                  onClick={() => handleSelectCards(card?.id, index)}
                  value={card?.id}
                  checked={true}
                  className={({ active, checked }) =>
                    `${
                      active
                        ? "ring-2 ring-[#D90429] ring-opacity-60 "
                        : ""
                    }
                  ${
                    checked
                      ? "bg-[#D90429] bg-opacity-40 text-white"
                      : "bg-white"
                  }
                    relative flex cursor-pointer rounded-lg px-5 py-4 shadow-md focus:outline-none`
                  }
                >
                  {({ active, checked }) => (
                    <>
                      <div className="flex items-center justify-between w-full">
                        <div className="flex items-center">
                          <img
                            onContextMenu={(event) => {
                              event.preventDefault();
                              window.open(event?.target?.currentSrc, "_blank");
                            }}
                            src={getCardImage((card?.brand).toLowerCase())}
                            className="self-center justify-center object-contain object-center h-34 w-34 "
                            style={{
                              alignSelf: "center",
                              resizeMode: "contain",
                              height: 40,
                              width: 40,
                              justifyContent: "center",
                            }}
                          />
                          <div className="ml-3 text-sm">
                            <RadioGroup.Label
                              as="p"
                              className={`font-medium  ${
                                checked ? "text-[#D90429]" : "text-gray-900"
                              }`}
                            >
                              <h2 className="text-sm font-medium text-gray-700">{`***** ${card?.last4}`}</h2>
                            </RadioGroup.Label>
                            <RadioGroup.Description
                              as="span"
                              className={`inline ${
                                checked ? "text-[#D90429]" : "text-gray-500"
                              }`}
                            >
                              {`${card?.name}`}
                            </RadioGroup.Description>
                          </div>
                        </div>
                        {checked && (
                          <div className=" text-white shrink-0">
                            <CheckIcon className="w-6 h-6" />
                          </div>
                        )}
                      </div>
                    </>
                  )}
                </RadioGroup.Option>
              ))}
          </div>
        </RadioGroup>
      </div>
      <ForexType />
      {showButton && (
        <LoadingButton
          style={{ marginTop: 10 }}
          title={t("Confirm")}
          onClick={payWithCardCredit}
          isLoading={
            isLoadingPaymentForex ||
            isCreateIntentionPayment ||
            isCreateOrder ||
            isConfirmIntentionPayment
          }
          disabled={
            !(
              (isRealValue(card) &&
                isRealValue(euroRelated) &&
                amountToPay >= 500 &&
                ((euroRelated?.euro && euroRelated?.currency === "XAF") || (!euroRelated?.euro && euroRelated?.currency !== "XAF") ) &&
                isRealValue(order?.order?.pickupLocation) &&
                requireDeposit <= amountToPay && amountToPay <= totalAmount
              )
            )
          }
        />
      )}
    </div>
  );
}

function CheckIcon(props) {
  return (
    <svg viewBox="0 0 24 24" fill="none" {...props}>
      <circle cx={12} cy={12} r={12} fill="#fff" opacity="0.2" />
      <path
        d="M7 13l3 3 7-7"
        stroke="#fff"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
