import React from 'react'
import { Controller, useForm } from 'react-hook-form';
import InputFlatBorder from '../../common/InputFlatBorder';
import SelectMenu from '../../common/SelectMenus';
import { Slider } from '@mui/material';

const Countries = [
    { id: 1, name: "Cameroon" }
]

const Division1 = [
    { id: 1, name: "Division1" }
]

function valuetext(value) {
    return `${value}°C`;
}


const WithCoordinates = () => {
    const [value, setValues] = React.useState(3);

    const { handleSubmit, control, setValue, setError, clearErrors, formState: { errors, } } = useForm({
        defaultValues: {
            description: '',
            code: "",
            country: "",
            coordinates: "",
        }
    });

  return (
    <div>
        <Controller control={control} rules={{ required: true }}
            render={({ field: { onChange, onBlur, value } }) => (
                <InputFlatBorder
                    value={value}
                    label={"Code"}
                    onChange={onChange}
                    name="Code"
                    autoComplete="code"
                    error={errors.code && "Required field"}
                />
            )}
            name="code"
        />

        <Controller control={control} rules={{ required: true }}
            render={({ field: { onChange, onBlur, value } }) => (
                <InputFlatBorder
                    name={"Description"}
                    value={value}
                    label={"Description"}
                    onChange={onChange}
                    error={errors.description && "Required field"}
                />
            )}
            name="description"
        />

        <div className="sm:col-span-4">
            <SelectMenu defaultText="Countries" data={Countries} />
        </div>

        <div className="sm:col-span-4">
            <SelectMenu defaultText="Division1" data={Division1} />
        </div>

        <p className="text-base font-normal text-gray-900 my-2 ">Coller le code JSON ci-dessous</p>
        {/* <Slider
            aria-label="Temperature"
            defaultValue={3}
            onChange={(e) => { setValues(e.target.value) }}
            getAriaValueText={valuetext}
            valueLabelDisplay="auto"
            step={1}
            // value={value}
            marks
            min={3}
            max={50}
        /> */}

          <Controller control={control} rules={{ required: true }} 
              render={({ field: { onChange, value, onBlur } }) => (
                  <textarea
                    onChange={onChange}
                    value={value}
                    className="border-2 rounded-md border-dashed border-gray-400 min-h-[55px] w-full "
                    name="coordinates"
                  />
              )}
              name='coordinates'
          />

       
    </div>
  )
}

export default WithCoordinates