import React from 'react'
import Spinner from '../Spinner'

const classNames = (...classes) => {
  return classes.filter(Boolean).join(' ');
}

const LoadingButton = ({ title, style, isLoading, onClick, disabled, icon }) => {
  return (
    <button
      disabled={disabled}
      type="submit"
      style={style}
      onClick={onClick}
      className={classNames(
        isLoading || disabled
          ? " bg-slate-600"
          : " bg-[#D90429] hover:bg-[#D9042990] ",
        "flex w-[fit-content] cursor-pointer mx-auto items-center justify-center rounded-md border border-transparent py-3 px-8 text-base font-medium text-white focus:outline-none focus:ring-2 focus:ring-[#D90429] focus:ring-offset-2 focus:ring-offset-gray-50 "
      )}
    >
      {" "}
      {icon && icon}
      {title}
      {isLoading && <Spinner />}
    </button>
  );
}

export default React.memo(LoadingButton)