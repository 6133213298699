
import React from 'react'
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { useSelector } from 'react-redux';
import { IconButton } from '@mui/material';
import { dispatch } from '../../app';
import './styles.css';
import { handleCounter, handleOnchangeCounter } from '../../app/features/itemSlice';

export const ProductQuantityUpdate = () => {
  const { counter } = useSelector(state => state?.item)
  const setCounter = (op) => { dispatch(handleCounter(op)) }
  const onChangeCounter = (val) => dispatch(handleOnchangeCounter(val))

  const ProductQuantityDesktop = () => {
    return (
      <div
        className="row-flex-space"
        style={{ marginTop: 15, marginBottom: 15 }}
      >
        <div
          className="space-x-2 row-flex-space"
          style={{ paddingLeft: 15, paddingRight: 15, appearance: "unset" }}
        >
          <IconButton
            onClick={() => setCounter("rm")}
            aria-label="delete"
            color="primary"
          >
            <RemoveIcon sx={{ fontSize: 24 }} />
          </IconButton>
          <input
            value={counter}
            type="number"
            min={1}
            className=" focus:outline-[#D90429] focus:border-0 focus:border-transparent focus:outline-none rounded-md "
            style={{
              appearance: "unset",
              MozAppearance: "unset",
              WebkitAppearance: "unset",
              width: "180px",
              textAlign: "center",
            }}
            onChange={(e) => onChangeCounter(Math.abs(e.target.value))}
          />
          <IconButton
            onClick={() => setCounter("add")}
            aria-label="delete"
            color="primary"
          >
            <AddIcon sx={{ fontSize: 24 }} />
          </IconButton>
        </div>
      </div>
    );
  }
  const ProductQuantityMobile = () => {
    return (
      <div id="pdt-qty-mob">
        <div
          className="row-flex-space"
          style={{ marginTop: 15, marginBottom: 15 }}
        >
          <div
            className="row-flex-space counter"
            style={{ paddingLeft: 15, paddingRight: 15 }}
          >
            <RemoveIcon
              className="cursor-pointer "
              sx={{ fontSize: 24 }}
              onClick={() => setCounter("rm")}
            />
            <span style={{ marginLeft: 3, marginRight: 3 }}>{counter}</span>
            <AddIcon
              className="cursor-pointer "
              sx={{ fontSize: 24 }}
              onClick={() => setCounter("add")}
            />
          </div>
        </div>
      </div>
    );
  }
  return (
    <>
      <ProductQuantityDesktop />
    </>
  )
}