import { Box, Container, Divider, Grid } from '@mui/material'
import React, { useState } from 'react'
import Input from '../../../components/common/Input'
import SearchIcon from '@mui/icons-material/Search';
import '../styles.css'
import TransactionHistComponentEmployee from './TransactionHistComponentEmployee';
import DepositForm from './DepositForm';
import PaymentForm from './PaymentForm';
import TransfertForm from './TransfertForm';
import WithdrawForm from './WithdrawForm';
import PaperButton from '../../../components/common/PaperButton';
import InputFlatBorder from '../../../components/common/InputFlatBorder';
import { XMarkIcon, MagnifyingGlassIcon } from '@heroicons/react/24/outline';
import ButtonFlat from '../../../components/ButtonFlat';
import { useTranslation } from 'react-i18next';

const listData = [
    "Payment",
    "Deposit",
    "Transfer",
    "Withdraw",
]

const DashboardEmployees = () => {
    const { t } = useTranslation()
    const [Active, setActive] = useState(undefined);

    return (
      <main className=" px-4 sm:px-6 lg:px-8 ">
        {/* <div className="flex flex-col max-w-7xl mx-auto md:flex-row justify-between items-center space-x-5 right-0 flex-1 ">
          <div className="sm:px-2 ">
            <div className="max-w-2xl px-4 mx-auto lg:max-w-7xl lg:px-0">
              <h1 className="text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl">
                {t("ord_his")}
              </h1>
              <p className="mt-2 text-sm text-gray-500">{t("ord_his_desc")}</p>
            </div>
          </div>
        </div>
        <div className=" bg-red-500 w-full py-3 flex items-start justify-center space-x-5 flex-1 min-h-[510px] ">
          <div className=" w-[58%] bg-teal-400 border border-gray-400 rounded-md shadow-md "></div>
          <div className="w-[38%] bg-purple-400 border border-gray-400 rounded-md shadow-md "></div>
        </div> */}
      </main>
    );
}

export default DashboardEmployees