import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    pagination: {
        "total": 0,
        "count": 0,
        "perPage": 0,
        "currentPage": 0,
        "totalPages": 0,
        "nextLink": ""
    },
    canFectFavoritesStore: true,
    toFetchStores: [],
    storesFavorites: [],
    totalBookmarkStore: 0,


    bookmarkStoresFilter: [],
    bookmarkStores: [],
}

const bookmarkStoresSlice = createSlice({
    name: 'bookmarkStore',
    initialState,
    reducers: {
        handlePagination(state, action) {
            state.pagination = parseInt(action.payload, 10)
        },
        handleCanFectFavoritesStore(state, action) {
            state.canFectFavoritesStore = action.payload
        },
        handleToFetchStore(state, action) {
            const stores = action.payload?.map(store => parseInt(store, 10))
            state.toFetchStores = stores
            state.totalBookmarkStore = stores?.length || 0
        },
        handleDataStoreFavorites(state, action) {
            state.pagination = action.payload?.pagination
            state.storesFavorites = action.payload?.stores
        },
        handleBookmarkStore(state, { payload }) {
            state.bookmarkStores = payload
            state.bookmarkStoresFilter = payload
        }
    },

})

export const { 
    handlePagination, 
    handleCanFectFavoritesStore, 
    handleToFetchStore, 
    handleDataStoreFavorites ,
    handleBookmarkStore,
    
} = bookmarkStoresSlice.actions

export default bookmarkStoresSlice