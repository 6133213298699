import { LocalizationProvider, } from "@mui/x-date-pickers";
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import React from 'react';
import { useSelector } from "react-redux";
import { useAuthentication } from "./app/hooks/useAuthentication";
import { useLanguage } from "./app/hooks/useLanguage";
import { SnackbarProvider } from 'notistack';
import RouterNav from "./Router";

import "tailwindcss/tailwind.css"
import {
  useBookmarkCardItemsQuery, useBookmarkItemsOfflineQuery, useBookmarkItemsQuery, useBookmarkStoresOfflineQuery, useBookmarkStoresQuery,
  useCategoriesQuery, useCodeValuesQuery, useInfosAccountBankQuery,
  useListCountriesQuery
} from "./app/services/Api";
import { Elements } from "@stripe/react-stripe-js";
import { STRIPE_KEY } from "./app/services";
import { loadStripe } from "@stripe/stripe-js";
import { getHomeType, getHomeValue, getOrderRoute } from "./app/hooks";
import { ThemeProvider, createTheme } from "@mui/material";
import { isRealValue, scroolWindowToTop, toString } from "./utilities";
import { useLocalDbHook } from "./app/localDatabase/useLocalDbHook";

const stripePromise = loadStripe(STRIPE_KEY);

const App = () => {

  useAuthentication()
  useLanguage()
  useListCountriesQuery()
  useCategoriesQuery()
  const { isLogged, user } = useSelector(state => state.auth)
  const { lng } = useSelector(state => state.app)

  const { bookmarkItemsOff, bookmarkStoresOff } = useLocalDbHook()
  useBookmarkItemsOfflineQuery({ arr: toString(bookmarkItemsOff?.map(b => b?.key)) }, { skip: !(isLogged === false && bookmarkItemsOff?.length > 0) })
  useBookmarkStoresOfflineQuery({ arr: toString(bookmarkStoresOff?.map(b => b?.key)) }, { skip: !(isLogged === false && bookmarkStoresOff?.length > 0) })

  useBookmarkItemsQuery(null, { skip: !(isLogged && isRealValue(user?._id)) })
  useBookmarkStoresQuery(null, { skip: !(isLogged && isRealValue(user?._id)) })
  useBookmarkCardItemsQuery(null, { skip: !(isLogged && isRealValue(user?._id)) })
  useCodeValuesQuery(getOrderRoute, { refetchOnMountOrArgChange: true, refetchOnReconnect: true })
  useInfosAccountBankQuery(null, { skip: !(isLogged && isRealValue(user?._id)) })

  scroolWindowToTop()
  const isForeignLog = isLogged && user?.role === "logistician-foreign"

  useCodeValuesQuery(getHomeValue, { skip: !isForeignLog, })
  useCodeValuesQuery(getHomeType, { skip: !isForeignLog })


  const myTheme = createTheme({
    palette: {
      primary: {
        main: "#D90420",
        light: "#D9042990",
      },
    },
  });

  return <LocalizationProvider dateAdapter={AdapterMoment} >
    <SnackbarProvider maxSnack={3}>
      <Elements stripe={stripePromise} options={{ locale: lng }} >
        <ThemeProvider theme={myTheme} >
          <RouterNav />
        </ThemeProvider>
      </Elements>
    </SnackbarProvider>
  </LocalizationProvider>
};
export default App;



