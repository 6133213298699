import React from 'react'

const usePermission = () => {
    const [coordinates, setCoordinates] =React.useState(null)
    var options = {
        enableHighAccuracy: true,
        timeout: 50000,
        maximumAge: 0
    };

    function success(pos) {
        var coords = pos.coords;
        // console.log('pos', pos)
        const { latitude, longitude } = coords
        setCoordinates({ latitude, longitude })
    }

    function error(err) {
        // console.warn(`ERREUR (${err.code}): ${err.message}`);
        getCurrentPosition()
    }


    const getCurrentPosition = () => navigator.geolocation.getCurrentPosition(success, error, options)



    return { getCurrentPosition, coordinates }
}

export default usePermission