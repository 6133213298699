import React, { useRef, useState } from 'react'
import { useNotifications } from '../../../../app/hooks/useNotifications';
import { AudioRecorder, useAudioRecorder } from "react-audio-voice-recorder";
import { IoCloseSharp } from 'react-icons/io5';
import pdfIcon from '../../../../assets/images/pdf-svgrepo-com.svg'
const ChatInput = () => {

  const [isRecording, setIsRecording] = useState(false);
  const [selectedImages, setSelectedImages] = useState([]);
  const [selectedDocuments, setSelectedDocuments] = useState([]);
  const [track, setAudio] = useState('');
  const [isImagePreviewOpen, setImagePreviewOpen] = useState(false);
  const [isDocumentPreviewOpen, setDocumentPreviewOpen] = useState(false);

  const { warningNotification } = useNotifications()

  const recorderControls = useAudioRecorder(
    {
      noiseSuppression: true,
      echoCancellation: true,
    },
    (err) => console.table(err)
  );

   

    const addAudioElement = (blob) => {
      const url = URL.createObjectURL(blob);
      console.info('blob', blob)
      console.info("url", url);
      setAudio(url)
    };

   const handleImageChange = (e) => {
     // Handle image selection and preview logic here
     const files = e.target.files;
     const previewImages = [];

     for (const element of files) {
       const file = element;
       const imageUrl = URL.createObjectURL(file);
       previewImages.push({ file, imageUrl });
     }

     setSelectedImages(previewImages);
   };

   const handleDocumentChange = (e) => {
     // Handle document selection and preview logic here
     const files = e.target.files;
     const previewDocuments = [];

     for (const element of files) {
       const file = element;
       console.info('element', element)
       previewDocuments.push({ file });
     }

     setSelectedDocuments(previewDocuments);
   };

   const removeImage = (index) => {
     // Implement image removal logic here
     const updatedImages = [...selectedImages];
     updatedImages.splice(index, 1);
     setSelectedImages(updatedImages);
   };

   const removeDocument = (index) => {
     // Implement document removal logic here
     const updatedDocuments = [...selectedDocuments];
     updatedDocuments.splice(index, 1);
     setSelectedDocuments(updatedDocuments);
   };

  const el = document.getElementById("messages");
  // el.scrollTop = el.scrollHeight
  return (
    <div className="border-t-2 border-gray-200 px-4 pt-4 mb-2 sm:mb-0">
      <div className="relative flex flex-col min-h-max">
        <input
          type="text"
          placeholder="Write your message!"
          className="w-full border-none focus:border-none border-transparent focus:border-transparent focus:outline-transparent focus:outline-none outline-none focus:placeholder-gray-400 text-gray-400 placeholder-gray-600 pl-12 bg-gray-50 rounded-md py-3"
        />
        {recorderControls.isRecording && (
          <AudioRecorder
            onRecordingComplete={addAudioElement}
            recorderControls={recorderControls}
            onNotAllowedOrFound={(err) => console.table(err)}
            // downloadOnSavePress={true}
            // downloadFileExtension="webm"
            mediaRecorderOptions={{
              audioBitsPerSecond: 128000,
            }}
            
          />
        )}
        {track.length > 0 && (
          <audio src={track} autoplay controls style={{ margin: `10px 0` }} >
            Your browser does not support the <code>audio</code> element.
          </audio>
        )}
        <div className="relative items-center justify-between inset-y-0 hidden sm:flex">
          <span className="inset-y-0 flex space-x-2 items-center">
            <button
              type="button"
              onClick={recorderControls.startRecording}
              className="cursor-pointer inline-flex items-center justify-center rounded-full h-10 w-10 transition duration-500 ease-in-out text-gray-500 hover:bg-gray-200 focus:outline-none"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                className="h-6 w-6 text-gray-400"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M19 11a7 7 0 01-7 7m0 0a7 7 0 01-7-7m7 7v4m0 0H8m4 0h4m-4-8a3 3 0 01-3-3V5a3 3 0 116 0v6a3 3 0 01-3 3z"
                ></path>
              </svg>
            </button>
            <button
              type="button"
              className="inline-flex items-center justify-center rounded-full h-10 w-10 transition duration-500 ease-in-out text-gray-500 hover:bg-gray-300 focus:outline-none"
            >
              <label className=" text-gray-400 cursor-pointer">
                <input
                  type="file"
                  accept=".pdf,.doc,.docx"
                  multiple
                  onChange={handleDocumentChange}
                  className="hidden"
                />
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  className="h-6 w-6 text-gray-400"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M15.172 7l-6.586 6.586a2 2 0 102.828 2.828l6.414-6.586a4 4 0 00-5.656-5.656l-6.415 6.585a6 6 0 108.486 8.486L20.5 13"
                  ></path>
                </svg>
                {/* 📄 */}
              </label>
            </button>
            <button
              type="button"
              className="inline-flex items-center justify-center rounded-full h-10 w-10 transition duration-500 ease-in-out text-gray-500 hover:bg-gray-300 focus:outline-none"
            >
              <label className=" text-gray-400 cursor-pointer">
                <input
                  type="file"
                  accept="image/*"
                  multiple
                  onChange={handleImageChange}
                  className="hidden"
                />
                {/* Implement image icon */}
                {/* 📷 */}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  className="h-6 w-6 text-gray-400"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M3 9a2 2 0 012-2h.93a2 2 0 001.664-.89l.812-1.22A2 2 0 0110.07 4h3.86a2 2 0 011.664.89l.812 1.22A2 2 0 0018.07 7H19a2 2 0 012 2v9a2 2 0 01-2 2H5a2 2 0 01-2-2V9z"
                  ></path>
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M15 13a3 3 0 11-6 0 3 3 0 016 0z"
                  ></path>
                </svg>
              </label>
            </button>
          </span>
          <button
            type="button"
            className="cursor-pointer inline-flex items-center justify-center rounded-lg px-4 py-3 transition duration-500 ease-in-out text-white bg-transparent focus:outline-none"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              className="h-6 w-6 ml-2 transform rotate-90 text-gray-400"
            >
              <path d="M10.894 2.553a1 1 0 00-1.788 0l-7 14a1 1 0 001.169 1.409l5-1.429A1 1 0 009 15.571V11a1 1 0 112 0v4.571a1 1 0 00.725.962l5 1.428a1 1 0 001.17-1.408l-7-14z"></path>
            </svg>
          </button>
        </div>
      </div>
      {/* Image Preview */}
      {selectedImages.length > 0 && (
        <div className="relative w-full h-full flex items-start overscroll-x-scroll justify-start bg-opacity-75">
          {/* Implement image preview component */}
          {selectedImages.map((image, index) => (
            <div key={index} className="mr-4 relative ">
              <img
                src={image.imageUrl}
                alt={`Image ${index + 1}`}
                className="w-20 h-20 rounded-md object-cover object-center "
              />
              <button
                onClick={() => removeImage(index)}
                className="absolute -top-1.5 -right-2 flex items-center justify-center h-6 w-6 rounded-full bg-[#D90429]  "
              >
                <IoCloseSharp className="text-white h-4 w-4 " />
              </button>
            </div>
          ))}
        </div>
      )}

      {/* Document Preview */}
      {selectedDocuments.length > 0 && (
        <div className="w-full h-full flex pt-5 space-x-2 overflow-x-scroll items-start justify-start bg-opacity-75">
          {/* Implement document preview component */}
          {selectedDocuments.map((document, index) => (
            <div
              key={index}
              className="relative flex p-2 bg-gray-200 rounded-md h-[fit-content] w-[150px] "
            >
              <img
                src={pdfIcon}
                alt={`Image ${index + 1}`}
                className="w-8 h-8 object-contain object-center "
              />
              <p className="text-gray-500 flex-1 line-clamp-1 truncate ">
                {index + 1} {document?.file?.name}
              </p>
              <button
                onClick={() => removeDocument(index)}
                className="absolute -top-1.5 -right-2 flex items-center justify-center h-6 w-6 rounded-full bg-[#D90429]  "
              >
                <IoCloseSharp className="text-white h-4 w-4 " />
              </button>
            </div>
          ))}
          {/* Send button for documents */}
        </div>
      )}
    </div>
  );
}

export default ChatInput