
import { Box, CircularProgress, Grid, Typography, useMediaQuery, useTheme } from '@mui/material'
import React from 'react'
import './styles.css'

import { useSelector } from 'react-redux'
import { useAllOrdersQuery } from '../../../app/services/Api'
import { RowsOrders } from './rowsOrders'

const ItemTable = () => {
    const theme = useTheme()
    const matches = useMediaQuery(theme.breakpoints.down('md'))
    const matchesXS = useMediaQuery(theme.breakpoints.down('xs'))
    const { isLoading: isLoadingOrder } = useAllOrdersQuery()
    const { orderFiltered } = useSelector(state => state.orders)
    return (
      <Box>
        <Grid container spacing={0}>
          {!matches && (
            <Grid container item xs={12}>
              <Grid item xs={4} container justifyContent={"center"}>
                <Typography  className="title_filter">
                  Products Information
                </Typography>
              </Grid>
              <Grid item xs container justifyContent={"center"}>
                <Typography  className="title_filter">
                  Routing
                </Typography>
              </Grid>
              <Grid item xs container justifyContent={"center"}>
                <Typography  className="title_filter">
                  Unit Price(cfa)
                </Typography>
              </Grid>
              <Grid item xs container justifyContent={"center"}>
                <Typography  className="title_filter">
                  Quantity
                </Typography>
              </Grid>
              <Grid item xs container justifyContent={"center"}>
                <Typography  className="title_filter">
                  Total Price(cfa)
                </Typography>
              </Grid>
              <Grid item xs container justifyContent={"center"}>
                <Typography  className="title_filter">
                  Status
                </Typography>
              </Grid>
            </Grid>
          )}
          {isLoadingOrder ? (
            <Box width={"100%"} padding={11} textAlign={"center"}>
              <CircularProgress sx={{ color: "#D90429" }} />
            </Box>
          ) : orderFiltered?.length <= 0 ? (
            <Box width={"100%"} padding={11} textAlign={"center"}>
              No orders found !
            </Box>
          ) : (
            orderFiltered?.map((order, index) => (
              <RowsOrders
                key={index + "row_orders"}
                matches={matches}
                matchesXS={matchesXS}
                order={order}
              />
            ))
          )}
        </Grid>
      </Box>
    );
}

export default ItemTable