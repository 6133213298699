import { createSlice } from "@reduxjs/toolkit";
import { applySelectVariant, isRealValue } from "../../utilities";

const initialState = {
    item: null,
    cartToAdd: {},
    counter: 1,
    seaOrAir: true,
    fetchBrand: true,
    active: 0,
    activeSize: 0,
    activeColor: null,
    isVideo: false,
    selectedVariants: {},
    selectableProperties: null,
    imageDetailSpecs: "",
    previewImg: [],
    previewImgIndex: 0,
    previewVariantsImg: [],
    previewMainImg: [],
    previewMedImg: [],
    previewImgUri: "",
    previewType: "main",
    itemImgContext: undefined,
    fistThreeZeroLevelCategoriesKeys: [],

    proposesSize: {},
    proposeSizeTyped: {}

};

const itemSlice = createSlice({
    name: 'item',
    initialState,
    reducers: {
        handleIsVideo(state, action) {
            state.isVideo = action.payload
        },
        handleIndexChange(state, { payload }) {
            if (payload > 0) {
                if (state.previewImgIndex < (state.previewImg.length - 1)) {
                    state.previewImgIndex += payload;
                    state.previewImgUri = state.previewImg[state.previewImgIndex]?.uri
                    return
                }
            }
            if (payload < 0) {
                if (state.previewImgIndex > 0) {
                    state.previewImgIndex += payload;
                    state.previewImgUri = state.previewImg[state.previewImgIndex]?.uri
                    return
                }
            }
        },
        handleImgPreview(state, { payload }) {
            const { uri, type, index } = payload;
            if (state.previewType === type) {
                state.previewImgUri = uri;
                state.previewImgIndex = index;
                return
            }
            state.previewType = type;
            state.previewImgUri = uri;
            state.previewImgIndex = index;

        },
        handleSlideImgPreview(state, { payload }) {
            if (state.previewType === "main") {
                state.previewImg = state.previewMainImg;
            } else {
                state.previewImg = state.previewVariantsImg;
            }

        },
        handleOnchangeCounter(state, { payload }) {
            if (parseInt(payload) <= 1) {
                state.counter = 1
                state.cartToAdd = { ...state.cartToAdd, quantity: 1 }
            } else {
                state.counter = parseInt(payload)
                state.cartToAdd = { ...state.cartToAdd, quantity: state.counter }
            }
        },
        handleBrand(state, action) {
            state.contextConfig = { ...state.contextConfig, brand: action.payload }
            state.contextConfig.brand = action.payload
        },
        handleVariants(state, action) {
            const { vid, pid } = action.payload
            const variantsConfig = state.item?.variantsConfig
            state.selectedVariants[pid] = vid
            state.onActionUp = {}
            state.onActionUp[pid] = vid

            const variants = state.item.variants
            const selectableProperties = applySelectVariant(pid.replace("p_", ""), vid.replace("v_", ""), variants);
            state.selectableProperties = selectableProperties

            Object.keys(variantsConfig).forEach(getPid => {
                var alreadySelected = isRealValue(state.selectedVariants[getPid])
                var selectable = isRealValue(selectableProperties[getPid])
                if (!selectable && getPid !== pid) {
                    state.selectedVariants[getPid] = null;
                }
                if (!alreadySelected && selectable) {
                    state.selectedVariants[getPid] = selectableProperties[getPid][0];
                }
            });
            const varian = state.item.variants.find(v => v.configurators.every(c => state.selectedVariants[`p_${c.pid}`] === `v_${c.vid}`))
            const { basePrice, reducedPrice } = varian
            state.cartToAdd = { ...state.cartToAdd, variantKey: varian?.key }

            const selected = state.item.variantsConfig[pid].values[vid]
            if (isRealValue(basePrice)) state.item.basePrice = basePrice;
            if (isRealValue(reducedPrice)) state.item.reducedPrice = reducedPrice;

            if (isRealValue(selected?.pictureUrl)) {
                state.imageDetailSpecs = selected?.pictureUrl
            }
        },
        handleItem(state, { payload }) {
            var { item, ...rest } = payload;
            const itemVideo = item?.video ?? undefined;
            state.isVideo = isRealValue(item?.video) ? true : false
            state.onActionUp = null

            const canSeeAirPrice = isRealValue(item?.basePrice?.cm?.airPrice) || isRealValue(item?.reducedPrice?.cm?.airPrice)
            state.seaOrAir = canSeeAirPrice ? false : true

            let configPrices = {}
            var variantsConfig = {}
            item.variants.forEach(variant => {
                variant.configurators.forEach(configurator => {
                    let valueNew = {
                        variantKey: variant?.key,
                        propertyValue: configurator?.propertyValue,
                        pictureUrl: configurator?.pictureUrl || null,
                        miniPictureUrl: configurator?.miniPictureUrl || null
                    };
                    const basePrice = variant?.basePrice ?? null
                    const reducedPrice = variant?.reducedPrice ?? null
                    var config = {}
                    if (basePrice !== null) config = { ...config, basePrice };
                    if (reducedPrice !== null) config = { ...config, reducedPrice };

                    if (Object.keys(variantsConfig).includes(`p_${configurator.pid}`)) {
                        if (!(Object.keys(variantsConfig[`p_${configurator.pid}`].values).includes(`v_${configurator?.vid}`))) {
                            configPrices[`p_${configurator.pid}`][`v_${configurator.vid}`] = config
                            variantsConfig[`p_${configurator.pid}`].values[`v_${configurator.vid}`] = valueNew
                        }
                    } else {
                        var values = {}; values[`v_${configurator.vid}`] = valueNew
                        configPrices[`p_${configurator.pid}`] = {}
                        configPrices[`p_${configurator.pid}`][`v_${configurator.vid}`] = config
                        variantsConfig[`p_${configurator.pid}`] = { propertyName: configurator.propertyName, values }
                    }
                    variantsConfig[`p_${configurator.pid}`].havePicture = false
                    if (isRealValue(valueNew?.pictureUrl) || isRealValue(valueNew?.miniPictureUrl)) {
                        variantsConfig[`p_${configurator.pid}`].havePicture = true
                    }
                });
            });
            const previewImages = item?.pictures?.map(picture => { return { uri: picture.xlarge } });
            state.previewMedImg = item?.pictures?.map(picture => { return { uri: picture.large } });
            if (itemVideo) {
                state.previewMedImg.unshift({ uri: itemVideo?.url });
            }
            state.previewImg = previewImages;
            state.previewMainImg = previewImages;
            state.previewImgUri = state.previewMedImg[0].uri;
            state.cartToAdd = { itemKey: item.key, route: canSeeAirPrice ? "air" : "sea", quantity: state.counter, variantKey: null, store: item?.store }
            state.imageDetailSpecs = item.mainPicture

            var filterVC = {}
            Object.keys(variantsConfig).forEach(getPid => {
                let v = {}; v[getPid] = variantsConfig[getPid]
                let varImg = [];

                if (variantsConfig[getPid]?.havePicture === true) {
                    Object.keys(variantsConfig[getPid].values).forEach((v_id, _) => {
                        varImg.push({ uri: variantsConfig[getPid].values[v_id].pictureUrl })
                    })
                    state.previewVariantsImg = varImg;
                    filterVC = { ...v, ...filterVC }
                } else {
                    filterVC = { ...filterVC, ...v }
                }
            })
            variantsConfig = filterVC
            state.item = { ...item, configPrices, variantsConfig, ...rest };
            state.proposesSize = {};
        },
        handleSeaOrAir(state, { payload }) {
            state.active = payload
            const bool = state.active === 0 ? true : false
            state.seaOrAir = bool
            state.cartToAdd = { ...state.cartToAdd, route: bool ? "sea" : "air" }
        },
        handleCounter(state, action) {
            let op = action.payload
            if (op === 'add') {
                state.counter += 1
            } else {
                let count = state.counter <= 1 ? 1 : state.counter - 1
                state.counter = count
            }
            state.cartToAdd = { ...state.cartToAdd, quantity: state.counter }
        },
        handleCounterType(state, { payload }) {
            state.counter = Number(payload);

            state.cartToAdd = { ...state.cartToAdd, quantity: state.counter }
        },
        handleMainImg(state, action) {
            state.item = { ...state.item, mainPicture: action.payload }
            state.isVideo = false
        },
        handleInvalidateVariants(state, action) {
            state.selectedVariants = {};
            state.item = null
        },
        handleInvalidate(state, action) {
            state.selectedVariants = {};
        },
        handleItemImgContext(state, { payload }) {
            state.itemImgContext = payload;
        },
        handleCartToAdd(state, { payload }) {
            state.item.seaOrAir = payload.seaOrAir
            state.cartToAdd = { ...state.cartToAdd, ...payload }
            state.seaOrAir = payload.seaOrAir
            state.item.counter = payload.quantity
            state.counter = payload.quantity
        },
        handleSelectedVariants(state, { payload }) {
            state.selectedVariants = { ...payload }
        },
        handleFirstThreeZeroLevelMenuForDefautAirbtn(state, { payload }) {
            state.fistThreeZeroLevelCategoriesKeys = payload
        },
        handleProposeSize(state, { payload }) {
            state.proposesSize = payload
        },
        handleSetProposeSizeTyped(state, { payload }) {
            state.proposeSizeTyped = payload
        }
    },
})

export const {
    handleBrand,
    handleIndexChange,
    handleItem,
    handleVariants,
    handleSeaOrAir,
    handleCounter,
    handleCounterType,
    handleIsVideo,
    handleMainImg,
    handleImgPreview,
    handleSlideImgPreview,
    handleInvalidateVariants,
    handleInvalidate,
    handleItemImgContext,
    handleCartToAdd,
    handleSelectedVariants,
    handleOnchangeCounter,
    handleFirstThreeZeroLevelMenuForDefautAirbtn,
    handleProposeSize,
    handleSetProposeSizeTyped,


} = itemSlice.actions

export default itemSlice
