import React from 'react'
import MenuItems from '../MenuItems';
import './styles.css'
import { classNames } from '../../../utilities';

const Dropdown = ({ submenus, dropdown, depthLevel, AllCategories }) => {
  depthLevel = depthLevel + 1;
  const dropdownClass = depthLevel > 1 ? "dropdown-submenu" : "";

  return (
    <ul
      className={classNames(
        `dropdown ${dropdownClass} ${dropdown ? "show" : ""}`,
        ` list-none `
      )}
    >
      {submenus.map((submenu, index) => {
        return (
          <MenuItems
            items={submenu}
            key={index}
            depthLevel={depthLevel}
            AllCategories={AllCategories}
          />
        );
      })}
    </ul>
  );
}

export default Dropdown;