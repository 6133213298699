
import {
  DASHBOARD, DASHBOARD_BANKS, DASHBOARD_CHATS, DASHBOARD_CLOSED_FULFILLMENTS, DASHBOARD_CUSTOMERS, DASHBOARD_HISTTRANSACTION, DASHBOARD_LOCATIONS,
  DASHBOARD_LOCATIONS_CREATE,
  DASHBOARD_OPENED_FULFILLMENTS,
  DASHBOARD_ORDERS_HISTORY, DASHBOARD_ORDER_HISTORY_BY_CARDS, DASHBOARD_SETTINGS, DASHBOARD_SHIPMENTS_PURCHASES, DASHBOARD_SHIPMENTS_TRANSITS,
  DASHBOARD_SHIPPING_ADDRESS, DASHBOARD_WALLET, DASHBOARD_DELIVERY_AREA, DASHBOARD_DELIVERY_AREA_CREATE, DASHBOARD_STATEMENT, DASHBOARD_STATEMENT_CUSTOMER_BY_STAFF, DASHBOARD_OPENED_BILL_OF_LADING, DASHBOARD_CLOSED_BILL_OF_LADING, DASHBOARD_HOLDS,
} from '../../constants/routes';

import {
  ArrowPathIcon,
  MapPinIcon,
  ChatBubbleLeftRightIcon,
  UserIcon,
  UsersIcon,
  WalletIcon,
  Cog8ToothIcon,
  BuildingLibraryIcon,
  BanknotesIcon,
  ArrowsRightLeftIcon,
  BookOpenIcon,
} from '@heroicons/react/24/outline'

const headers = [
  { name: "profile", url: DASHBOARD, icon: UserIcon },
];
const endings = [
  // { name: "chat", url: DASHBOARD_CHATS, icon: ChatBubbleLeftRightIcon },
  { name: "settings", url: DASHBOARD_SETTINGS, icon: Cog8ToothIcon },
];
const customerUser = [
  { name: "wallet", icon: WalletIcon, url: DASHBOARD_WALLET },
  { name: "transaction_history", url: DASHBOARD_HISTTRANSACTION, icon: ArrowPathIcon },
  { name: "ord_his", url: DASHBOARD_ORDERS_HISTORY, icon: ArrowPathIcon },
  { name: "statement", url: DASHBOARD_STATEMENT, icon: BookOpenIcon },
  { name: "ashA", url: DASHBOARD_SHIPPING_ADDRESS, icon: MapPinIcon },
  { name: "holds", url: DASHBOARD_HOLDS, icon: MapPinIcon },
];
const admin = [
  { name: "create_location", url: DASHBOARD_LOCATIONS_CREATE, icon: MapPinIcon },
  { name: "list_locations", url: DASHBOARD_LOCATIONS, icon: MapPinIcon },
  { name: "create_pickup_location", url: DASHBOARD_DELIVERY_AREA_CREATE, icon: MapPinIcon },
  { name: "list_pickup_location", url: DASHBOARD_DELIVERY_AREA, icon: MapPinIcon },
];
const adminAnd = [
  { name: "list_users", icon: UsersIcon, url: DASHBOARD_CUSTOMERS },
];
const adminControllerSupervisor = [
  { name: "banks", url: DASHBOARD_BANKS, icon: BuildingLibraryIcon },
];

const logisticians = [
  { name: "transits", url: DASHBOARD_SHIPMENTS_TRANSITS, icon: ArrowsRightLeftIcon },
  { name: "Purchases", url: DASHBOARD_SHIPMENTS_PURCHASES, icon: BanknotesIcon },
];
const logisticianLocal = [
  { name: "opened_fulfillments", url: DASHBOARD_OPENED_FULFILLMENTS, icon: BanknotesIcon },
  { name: "closed_fulfillments", url: DASHBOARD_CLOSED_FULFILLMENTS, icon: BanknotesIcon },
];
const controllerOrSupervisor = [
  { name: "commandes_by_card", url: DASHBOARD_ORDER_HISTORY_BY_CARDS, icon: BanknotesIcon },
  { name: "statement", url: DASHBOARD_STATEMENT_CUSTOMER_BY_STAFF, icon: BanknotesIcon },
];
const supervisorLogisticianLocal = [
  { name: "opened_Bills", url: DASHBOARD_OPENED_BILL_OF_LADING, icon: BanknotesIcon },
  { name: "closed_Bills", url: DASHBOARD_CLOSED_BILL_OF_LADING, icon: BanknotesIcon },
];

export function getSideBarNavArray(user) {
  return [].concat(
    headers,
    (user?.role === "customer" || user?.role === "user") ? customerUser : [],
    (user?.role === "admin" || user?.role === "supervisor" || user?.role === "controller") ? adminControllerSupervisor : [],
    (user?.role === "admin") ? admin : [],
    (user?.role !== "user" && user?.role !== "customer") ? adminAnd : [],
    (user?.role === "logistician-local") ? logisticianLocal : [],
    (user?.role === "logistician-local" || user?.role === "supervisor") ? supervisorLogisticianLocal : [],
    (user?.role === "logistician-local" || user?.role === "logistician-foreign") ? logisticians : [],
    (user?.role === "supervisor" || user?.role === "controller") ? controllerOrSupervisor : [],
    endings,
  )
}