import React from 'react'
import { Grid, Pagination, Stack, useMediaQuery } from '@mui/material'
import { FilterAltOutlined } from '@mui/icons-material'
import { useTheme } from "@mui/material/styles"
import { ContainerSort, ContentDesktop, ContentMobile, ContentSortDesktop, ContentSortMobile } from "./style";
import FilterProduct from '../../components/common/FilterProduct';
import { useSelector } from 'react-redux'
import LoadingComponent from '../../components/LoadingComponent'
import ProductListItems from './ProductListItems'
import SelectMenu from '../../components/common/SelectMenus'
import FilterProductMobile from '../../components/Mobile/FilterProductMobile';
import { useCodeValuesQuery } from '../../app/services/Api';
import { isRealValue, toJson, toString } from '../../utilities';
import { useTranslation } from 'react-i18next';
import { all, getItemGrade } from '../../app/hooks';
import { useFormatter } from '../../app/hooks/useFormatter';
import { PRODUCT, STORE } from '../../constants/routes';
import { useLocation, useSearchParams } from 'react-router-dom';
import { motion } from 'framer-motion';

const ProductList = ({
  isLoading = false,
  params = {},
  setParams = (v) => { },
  rangePrice,
  setRangePrice,
  resetApplyFilter,
}) => {
  const { pagination, grades, itemsFilter, attributesCategories, stores, categories } = useSelector(state => state.items)
  const { brand } = useSelector(state => state.filters)
  const All = [{ name: { en: 'All', fr: 'Toutes' }, key: "All" }]
  const [searchParams] = useSearchParams();

  useCodeValuesQuery(getItemGrade)
  const { display } = useFormatter()
  const { t } = useTranslation();
  const sortByData = [
    { label: t("no_filt"), value: "All" },
    { label: t("Best_Sellers"), value: "sales" },
    { label: t("Newest_Arrivals_Sellers"), value: "new" },
    { label: t("Best_Scores"), value: "score" },
    { label: t("Price_High_to_Low"), value: "-price" },
    { label: t("Price_Low_to_Low"), value: "price" },
  ];

  const theme = useTheme()
  const matches = useMediaQuery(theme.breakpoints.down('md'))
  const resizeBtn = useMediaQuery("(max-width:728px)");
  const Content = matches ? ContentMobile : ContentDesktop;
  const ContentSort = matches ? ContentSortMobile : ContentSortDesktop;

  const category = searchParams.get('category') ? searchParams.get('category') : "";
  const store = searchParams.get('store') ? searchParams.get('store') : ""
  const sort = searchParams.get('sort') ? searchParams.get('sort') : ""
  const grade = searchParams.get('grade') ? toJson(searchParams.get('grade')) : []

  const [propertyKey, setPropertiesKeys] = React.useState(searchParams.get('propertyKey') ? toJson(searchParams.get('propertyKey')) : [])
  const [valueKey, setValuesKeys] = React.useState(searchParams.get('valueKey') ? toJson(searchParams.get('valueKey')) : [])

  const [virtualPropertyKey, virtualSetPropertiesKeys] = React.useState(null)
  const [virtualValueKey, virtualSetValuesKeys] = React.useState(null)

  const [open, setOpen] = React.useState(false)
  const sortBy = sortByData?.find(vs => vs?.value === sort)
  const [sortByGrade, setSortByGrade] = React.useState(grades?.find(vs => grade?.includes(vs?.value)))
  const [sortByStores, setSortByStores] = React.useState(stores?.find(vs => store === vs?.key))
  const [sortByCategories, setSortByCategories] = React.useState(categories.find(vs => category === vs?.key))

  const onChangeSortBy = (v) => {
    setParams({ ...params, page: 1, sort: v?.value !== "All" ? v?.value : "" })
  }
  
  const selectGrades = (grad) => {
    setSortByGrade(grad)
    setParams({ ...params, page: 1, grade: grad.value === "All" ? [] : toString([grad?.value]) })
  }

  const onChangeStores = (v) => {
    setSortByStores(v)
    setParams({ ...params, page: 1, store: v?.key !== "All" ? v?.key : "" })
  }

  const onChangeCategories = (v) => {
    setSortByCategories(v)
    setParams({ ...params, page: 1, category: v?.key !== "All" ? v?.key : "" })
  }

  const applyFilters = () => {

    const brands = brand?.length > 0 ? { brand: toString(brand) } : {}
    const priceMins = (isRealValue(rangePrice?.priceMin) && rangePrice?.priceMin !== "" && Number(rangePrice?.priceMin) > 0) ? { priceMin: rangePrice?.priceMin } : {}
    const priceMaxs = (isRealValue(rangePrice?.priceMax) && rangePrice?.priceMax !== "" && Number(rangePrice?.priceMax) > 0) ? { priceMax: rangePrice?.priceMax } : {}

    var valuesKeys = { valueKey: "" }
    if (isRealValue(virtualValueKey) || typeof (virtualValueKey) === "object") {
      if (virtualValueKey?.length > 0) {
        valuesKeys = { valueKey: toString(virtualValueKey) }
      }
      else valuesKeys = { valueKey: "" }
    } else {
      valuesKeys = valueKey?.length > 0 ? { valueKey: toString(valueKey) } : { valueKey: "" }
    }

    var propertyKeys = { propertyKey: "" }
    if (isRealValue(virtualPropertyKey) || typeof (virtualPropertyKey) === "object") {
      if (virtualPropertyKey?.length > 0) {
        propertyKeys = { propertyKey: toString(virtualPropertyKey) }
      }
      else propertyKeys = { propertyKey: "" }
    } else {
      propertyKeys = propertyKey?.length > 0 ? { propertyKey: toString(propertyKey) } : { propertyKey: "" }
    }

    const filter = {
      page: 1,
      ...params,
      ...brands,
      ...priceMins, ...priceMaxs,
      ...valuesKeys, ...propertyKeys,
    }
    setParams(filter);
  }

  const handlePagePaginations = (e, value) => {
    e.preventDefault();
    setParams({ ...params, page: value })
    window.scrollTo({ behavior: 'smooth', top: 0 })
  }

  const location = useLocation()
  const { pathname } = location
  const inProductsPageByCategory = pathname.startsWith(PRODUCT)
  const inStorePage = pathname.startsWith(STORE)

  const handleOpen = () => { setOpen(true); return true; }
  const resetApplyFilters = () => {
    setPropertiesKeys([])
    setValuesKeys([])
    resetApplyFilter()
  }

  React.useEffect(() => {
    setSortByGrade(grades?.find(vs => grade?.includes(vs?.value)))
    setSortByStores(stores?.find(vs => store === vs?.key))
    setSortByCategories(categories?.find(vs => category === vs?.key))
    window.scrollTo({ behavior: "smooth", top: 0 });
  }, [])

  return (
    <React.Fragment>
      <Content container>
        <div className="relative flex flex-row items-center justify-end mb-10">
          {matches ? (
            <motion.div
              whileTap={{ scale: 0.999 }}
              onClick={handleOpen}
              className="space-x-1 text-sm font-medium text-[#D90429] bg-[#D9042920] py-2 px-3 my-1 rounded-lg w-[fit-content] cursor-pointer "
            >
              <FilterAltOutlined fontSize="small" sx={{ color: "#D90420" }} />
              <span>{t('filter')}</span>
            </motion.div>
          ) : (
            ""
          )}
        </div>
        <Grid xs={12} mt={2}>
          <Grid container>
            {/* // Filter head */}
            <ContainerSort xs={12} container className={`row-flex-space`}>
              <ContentSort className="justify-end" xs={12}>
                {matches ? (
                  ""
                ) : (
                  <>
                    <span
                      style={{
                        fontFamily: "Futura, Medium",
                        color: "#979797",
                        marginRight: 10,
                      }}
                    >
                      Page
                    </span>
                    <h4 className="text-gray-600 text-md ">
                      <span>{pagination?.currentPage}</span>
                      <span className="mx-2">/</span>
                      <span>{pagination?.totalPages}</span>
                    </h4>
                  </>
                )}
                {!resizeBtn && (
                  <div className="flex items-center justify-end flex-1 space-x-1 flex-rox">
                    {!inProductsPageByCategory && categories?.length > 0 && (
                      <SelectMenu
                        style={{ width: 200 }}
                        data={[...All, ...categories]}
                        onChange={onChangeCategories}
                        display={display}
                        defaultText={t("categories")}
                        selectedValues={sortByCategories}
                      />
                    )}
                    {!inStorePage && stores?.length > 0 && (
                      <SelectMenu
                        style={{ width: 200 }}
                        data={[...All, ...stores]}
                        onChange={onChangeStores}
                        display={display}
                        defaultText={t("stores")}
                        selectedValues={sortByStores}
                      />
                    )}
                    {grades?.length > 0 && (
                      <SelectMenu
                        onChange={selectGrades}
                        style={{ width: 200 }}
                        data={[...all, ...grades]}
                        display={display}
                        defaultText={"Grades"}
                        selectedValues={sortByGrade}
                      />
                    )}
                    <SelectMenu
                      style={{ width: 200 }}
                      data={sortByData}
                      onChange={onChangeSortBy}
                      display={(v) => v.label}
                      defaultText={t("sort_by")}
                      selectedValues={sortBy}
                    />
                  </div>
                )}
              </ContentSort>
            </ContainerSort>
            <Grid mt={1} item xs={12} container>
              <div className="flex flex-row items-start justify-start w-full space-x-2 ">
                {attributesCategories.length > 0 && !matches && (
                  <div className="max-w-[320px]">
                    <FilterProduct
                      attributes={attributesCategories}
                      rangePrice={rangePrice}
                      setRangePrice={setRangePrice}
                      applyFilter={applyFilters}
                      resetApplyFilter={resetApplyFilters}
                      valueKeys={valueKey}
                      propertyKeys={propertyKey}
                      setPropertiesKeys={setPropertiesKeys}
                      setValuesKeys={setValuesKeys}
                      virtualSetValuesKeys={virtualSetValuesKeys}
                      virtualSetPropertiesKeys={virtualSetPropertiesKeys}
                    />
                  </div>
                )}
                <div className="w-[100%]">
                  <ProductListItems
                    itemsFilter={itemsFilter}
                    xs={attributesCategories.length > 0 && !matches ? 9 : 12}
                  />
                </div>
              </div>
            </Grid>
            {pagination?.totalPages > 1 && (
              <Grid
                xs={12}
                container
                sx={{ margin: "auto", mt: 5, mb: 5 }}
                className="row-flex"
              >
                <Stack spacing={8}>
                  <Pagination
                    onChange={handlePagePaginations}
                    shape="rounded"
                    color="primary"
                    sx={{ color: "#D90429" }}
                    size="large"
                    count={pagination?.totalPages}
                    page={pagination?.currentPage}
                  />
                </Stack>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Content>
      <FilterProductMobile
        open={open}
        setOpen={setOpen}
        children={
          <div>
            {resizeBtn && (
              <div>
                {!inProductsPageByCategory && categories?.length > 0 && (
                  <SelectMenu
                    style={{ marginTop: 7 }}
                    data={[...All, ...categories]}
                    onChange={(v) => {
                      onChangeCategories(v);
                      setOpen(!open);
                    }}
                    display={display}
                    defaultText={t("categories")}
                    selectedValues={sortByCategories}
                  />
                )}
                {!inStorePage && stores?.length > 0 && (
                  <SelectMenu
                    style={{ marginTop: 7 }}
                    data={[...All, ...stores]}
                    onChange={(v) => {
                      onChangeStores(v);
                      setOpen(!open);
                    }}
                    display={display}
                    defaultText={t("stores")}
                    selectedValues={sortByStores}
                  />
                )}
                <SelectMenu
                  onChange={(v) => {
                    selectGrades(v);
                    setOpen(!open);
                  }}
                  style={{ marginTop: 7 }}
                  data={[...all, ...grades]}
                  display={display}
                  defaultText={"Grades"}
                  selectedValues={sortByGrade}
                />
                <SelectMenu
                  style={{ marginTop: 7 }}
                  data={sortByData}
                  onChange={(v) => {
                    onChangeSortBy(v);
                    setOpen(!open);
                  }}
                  display={(v) => v.defaultText}
                  defaultText={t("sort_by")}
                  selectedValues={sortBy}
                />
              </div>
            )}
            <FilterProduct
              attributes={attributesCategories}
              rangePrice={rangePrice}
              setRangePrice={setRangePrice}
              applyFilter={applyFilters}
              resetApplyFilter={resetApplyFilters}
              valueKeys={valueKey}
              propertyKeys={propertyKey}
              setPropertiesKeys={setPropertiesKeys}
              setValuesKeys={setValuesKeys}
              virtualSetValuesKeys={virtualSetValuesKeys}
              virtualSetPropertiesKeys={virtualSetPropertiesKeys}
            />
          </div>
        }
      />
      <LoadingComponent open={isLoading} />
    </React.Fragment>
  );
}

export default ProductList