import React from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  List,
  ListItem,
  ListItemText,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import {
  EmailOutlined,
  ExpandMore,
  Facebook,
  LocalPhoneOutlined,
  Wifi,
  YouTube,
} from "@mui/icons-material";


import {
  PhoneIcon,
  EnvelopeIcon,
  MapPinIcon,
} from "@heroicons/react/24/outline";
import { IoLogoAppleAppstore, IoLogoGooglePlaystore } from "react-icons/io5";
import { useSelector } from "react-redux";
import { appUrl } from "../../../app/services";
import visa from "../../../assets/images/cards/new-visa-logo-high-quality-png-latest.png"
import master_card from "../../../assets/images/cards/mastercard.png"
import union_pay from "../../../assets/images/cards/unionpay.png";
import american_express from "../../../assets/images/cards/americanexpress.png";
import scb from "../../../assets/images/scb__logo.png";
import play_store from "../../../assets/images/play-store.svg";
import { useTranslation } from "react-i18next";
import { classNames } from "../../../utilities";
import { Link } from "react-router-dom";
import { COMMANDER, CONDITIONS, LIVRAISON, PAYMENTS, POLICY,  } from "../../../constants/routes";
import { motion } from "framer-motion";



function Footer() {

  const { lng } = useSelector(state=>state.app)
  const { t } = useTranslation();

  const [email, setEmail] = React.useState("");
  const handleEmail = (e) => {
    setEmail(e.target.value);
  };
  const [expanded, setExpanded] = React.useState("panel1");
  function linkItems(to, text, icon = null, component = null) {
    return { to, text, icon, component };
  }
  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };
  const footerNavigation = {
    "Contact us": [
      {
        name: "tel",
        href: "+237 690 86 87 91",
        icon: <PhoneIcon className="w-5 h-5 text-white " />,
      },
      {
        name: "mailto",
        href: "info@aflp.com",
        icon: <EnvelopeIcon className="w-5 h-5 text-white " />,
      },
    ],
    Payment: [
      {
        name: t("brch_pymt"),
        imgs: [],
      },
      {
        name: t("bnk_pymt"),
        imgs: [scb],
      },
      {
        name: t("bnk_tfr"),
        imgs: [scb],
      },
      {
        name: t("cards"),
        imgs: [visa, master_card, union_pay, american_express],
      },
    ],
  };

  const openLinkPlayStore = () => window.open("https://play.google.com/store/apps/details?id=com.youshop_mobile", "_blank", 'noreferrer');

  return (
    <footer aria-labelledby="footer-heading" className="mt-20 bg-gray-700 ">
      <div className="px-4 mx-auto sm:px-6 lg:px-8 ">
        <div className=" py-6 space-y-1 h-[fit-content] flex flex-col-reverse items-start justify-between md:items-center lg:flex-row lg:items-start">
          <div>
            <Link to={CONDITIONS} target="_blank">
              <p className="my-2 text-sm text-left text-white">
                {t("trm_n_conditions")}
              </p>
            </Link>
            <Link to={COMMANDER} target="_blank">
              <p className="my-2 text-sm text-left text-white">
                {t("hw_to_ordr")}
              </p>
            </Link>
            <Link to={PAYMENTS} target="_blank">
              <p className="my-2 text-sm text-left text-white">
                {t("pymt_trms")}
              </p>
            </Link>
            <Link to={LIVRAISON} target="_blank">
              <p className="my-2 text-sm text-left text-white">
                {t("dev_n_col")}
              </p>
            </Link>
            <Link to={POLICY} target="_blank">
              <p className="my-2 text-sm text-left text-white">
                {t("privacy_pol")}
              </p>
            </Link>
            <a
              target="blank"
              className="text-white text-[11px] text-center my-2"
              href="https://otcommerce.com/"
            >
              Powered by &copy; OT Commerce
            </a>
            <p className="mx-auto text-sm text-center text-white ">
              Copyright &copy; 2023 AFLP.COM LTD
            </p>
          </div>
          <div className="hidden sm:block ">
            <div className="flex flex-row items-start justify-evenly ">
              <div>
                <h3 className="text-sm font-semibold text-white md:text-center lg:text-start">
                  {t("ctc_us")}
                </h3>
                <ul
                  role="list"
                  className="flex flex-col items-start justify-center mt-6 space-y-6 md:items-center "
                >
                  {footerNavigation["Contact us"].map(
                    ({ href, icon, name }) => (
                      <li key={name} className="flex space-x-1 text-sm ">
                        {icon && icon}
                        <a
                          href={`${name}:${href}`}
                          className="text-white hover:text-white"
                        >
                          {href}
                        </a>
                      </li>
                    )
                  )}
                </ul>
              </div>
            </div>
          </div>
          <div className="block">
            <div>
              <p
                className="text-white text-sm font-semibold hover:text-gray-200 md:text-center lg:text-start "
              >
                {lng === "en"
                  ? "Download android application"
                  : "Téléchargez l'application android "}
              </p>
            </div>
            <motion.button
              whileHover={{ scale: 1.015 }}
              whileTap={{ scale: 0.9875 }}
              className="flex items-center px-3 py-2 space-x-2 bg-black border border-white rounded-md shadow-md "
              onClick={e => { e.preventDefault(); openLinkPlayStore(); }}
            >
              <img
                src={play_store}
                alt="Play store logo"
                className="object-contain object-center w-10 h-10 "
              />
              <div>
                <h6 className="text-xs font-bold text-left text-white font-outfitMedium ">
                  {t("get_it_on")}
                </h6>
                <h3 className="text-xl font-bold text-white">Google Play</h3>
              </div>
            </motion.button>
          </div>
          <div>
            <div className="">
              <div>
                <h3 className="text-sm font-semibold text-white md:text-center lg:text-start ">
                  {t("paymny_method")}
                </h3>
                <ul role="list" className="mt-6 space-y-6">
                  {footerNavigation["Payment"].map(({ imgs, name }, idx) => (
                    <li
                      key={name}
                      className="flex flex-row items-center space-x-1 md:justify-center lg:justify-start "
                    >
                      <p className="text-sm text-white hover:text-white">
                        {name}
                      </p>
                      <div className="flex flex-row items-center justify-center">
                        {imgs.map((item, i) => (
                          <img
                            className={classNames(
                              idx === 1 || idx === 2 ? " w-5 h-5" : "w-6 h-6",
                              " object-contain"
                            )}
                            src={item}
                            key={i}
                          />
                        ))}
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
