import React from "react";
import { HeartIcon } from "@heroicons/react/24/outline";
import { useSelector } from "react-redux";
import ViewSeaOrAirPrice from "../../pages/ProductDetails/ViewSeaOrAirPrice";
import { getFakeName, isRealValue } from "../../utilities";
import Spinner from "../Spinner";
import useMutationApi from "../../app/hooks/useMutationApi";
import { useNavigate } from "react-router-dom";
import { STORE } from "../../constants/routes";
import { useTranslation } from "react-i18next";

import DeliverySelect from "./DeleverySelect";
import { ProductQuantityUpdate } from "./ProductQuantityUpdate";
import VariantsUpdate from "./VariantsUpdate";
import { handleCartToAdd, handleSelectedVariants } from "../../app/features/itemSlice";
import { dispatch } from "../../app";
import { handleSelectedItem } from "../../app/features/bookmarkCardItemsSlice";
import { useLocalDbHook } from "../../app/localDatabase/useLocalDbHook";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}
const ProductInfoSectionUpdate = ({ setOpen, route, quantity, variantKey, itemCard, itemKey, open }) => {

  const navigate = useNavigate();
  const { t } = useTranslation();
  const [InPress, setInPress] = React.useState(false);
  const { items } = useSelector((state) => state.bookmarkItems);
  const { lng } = useSelector((state) => state.app);
  const { isLogged } = useSelector((state) => state.auth);
  const { cartToAdd, seaOrAir, item } = useSelector(state => state?.item)
  const { cartItemsSelected } = useSelector(state => state.bookmarkCard)

  const goToStore = (e) => {
    e.preventDefault();
    navigate(`${STORE}/${item?.store}`);
  };
  const { bookmarkItemsOff } = useLocalDbHook()
  const isFavorite = () => {
    const itemFav = !isLogged ? bookmarkItemsOff?.find(v => v.key === item?.key) : items?.find((article) => article?.key === item?.key)
    if (itemFav) {
      return true;
    }
    return false;
  }

  const {
    handleAddBookmarkItem, isLoadingFavorite,
    handleAddToFavoriteCart, isLoadingBookmarkCardItem,
    deleteToCartItemsByUser, loadingDeleteBookmarkCard,
  } = useMutationApi()

  const handleSelectItem = () => dispatch(handleSelectedItem({ ...itemCard, store: cartToAdd?.store }))
  const isSelected = () => isRealValue(cartItemsSelected.find(i => i.key === itemKey && i.variantKey === variantKey))
  const handleDeleteAddCarItem = (cardToUpdate) => {
    if (isSelected()) handleSelectItem()
    deleteToCartItemsByUser(isLogged ? [{ variantKey, route }] : [{ itemKey, variantKey }],
      handleAddToFavoriteCart(cardToUpdate, () => setOpen(!open)))
  }

  const handleUpdateCartItem = () => {
    let cardToUpdate = {
      itemKey: cartToAdd?.itemKey,
      variantKey: cartToAdd?.variantKey,
      store: cartToAdd?.store,
      route: cartToAdd?.route,
      quantity: cartToAdd?.quantity,
    };
    if (cardToUpdate.itemKey === itemKey && cardToUpdate.variantKey === variantKey) {
      handleAddToFavoriteCart(cardToUpdate, () => setOpen(!open))
    } else {
      handleDeleteAddCarItem(cardToUpdate);
    }
  };


  React.useEffect(() => {
    const setCartToAddAndSelectedVariants = () => {
      if (isRealValue(item) && cartToAdd?.variantKey === null) {
        const d = item?.variants?.find(v => v?.key === variantKey)
        const variants = d?.configurators
        var selectedV = {}
        variants?.forEach(v => {
          selectedV[`p_${v?.pid}`] = `v_${v?.vid}`
        })
        dispatch(handleCartToAdd({ route, quantity, variantKey, seaOrAir: route === "sea" ? true : false }))
        dispatch(handleSelectedVariants(selectedV))
      }
    }
    setCartToAddAndSelectedVariants();
  }, [cartToAdd])

  return (
    <div className="px-4 mt-10 sm:mt-16 sm:px-0 lg:mt-0 ">
      <h1 className="text-3xl font-bold tracking-tight text-gray-900 w-full truncate overflow-hidden">
        {item?.description[lng]}
      </h1>
      <div className="mt-3">
        {!isLogged &&
          item?.grade === "silver" &&
          isRealValue(item?.info) &&
          isRealValue(item?.info?.price) && (
            <div
              as="div"
              className=" text-sm font-medium text-gray-700 w-full bg-[#D90429] bg-opacity-80 p-3 rounded-md mt-5 "
            >
              <span className="mx-auto font-medium text-white ">
                {item?.info?.price[lng]}
              </span>{" "}
            </div>
          )}
        <h2 className="sr-only">Product information</h2>
        <ViewSeaOrAirPrice
          item={item}
          seaOrAir={seaOrAir}
        />
      </div>
      <div className="mt-6">
        <h3 className="sr-only">Description</h3>
        <div className="space-y-6 text-base text-gray-700">
          <span className="">{item?.description[lng]}</span>
        </div>
      </div>
      <div className="mt-6">
        <DeliverySelect isLogged={isLogged} />
      </div>
      {isRealValue(item?.info) && isRealValue(item?.info?.size) && (
        <div
          as="div"
          className=" text-sm font-medium text-gray-700 w-full bg-[#D90429] bg-opacity-80 p-3 rounded-md mt-5 "
        >
          <span className="mx-auto font-medium text-white ">
            {item?.info?.size[lng]}
          </span>{" "}
        </div>
      )}
      <div className="mt-6">
        <VariantsUpdate item={item} />
        <div className="mt-6">
          <h2 className="text-sm font-medium text-gray-900">{t("quantity")}</h2>
          <ProductQuantityUpdate />
        </div>
        <div
          onClick={goToStore}
          className="flex flex-row items-center justify-start bg-transparent rounded-md cursor-pointer "
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="#D90429"
            className="w-10 h-10 "
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M13.5 21v-7.5a.75.75 0 01.75-.75h3a.75.75 0 01.75.75V21m-4.5 0H2.36m11.14 0H18m0 0h3.64m-1.39 0V9.349m-16.5 11.65V9.35m0 0a3.001 3.001 0 003.75-.615A2.993 2.993 0 009.75 9.75c.896 0 1.7-.393 2.25-1.016a2.993 2.993 0 002.25 1.016c.896 0 1.7-.393 2.25-1.016a3.001 3.001 0 003.75.614m-16.5 0a3.004 3.004 0 01-.621-4.72L4.318 3.44A1.5 1.5 0 015.378 3h13.243a1.5 1.5 0 011.06.44l1.19 1.189a3 3 0 01-.621 4.72m-13.5 8.65h3.75a.75.75 0 00.75-.75V13.5a.75.75 0 00-.75-.75H6.75a.75.75 0 00-.75.75v3.75c0 .415.336.75.75.75z"
            />
          </svg>
          <h4 className="ml-3 text-xl font-medium text-gray-700 ">
            {item?.storeName[lng]}
          </h4>
        </div>
        <div className="flex mt-10 space-x-1 sm ">
          <button
            type="button"
            onClick={() => handleAddBookmarkItem(item?.key)}
            className={
              isFavorite()
                ? "ml-4 flex items-center justify-center rounded-md py-3 px-3 text-white bg-[#D90429]"
                : "ml-4 cursor-pointer flex items-center justify-center rounded-md py-3 px-3 text-red-900 bg-red-100"
            }
          >
            {!isLoadingFavorite && (
              <HeartIcon
                className="flex-shrink-0 w-6 h-6"
                aria-hidden="true"
              />
            )}
            {isLoadingFavorite && <Spinner />}
            <span className="sr-only">{t("add_to_favorite")}</span>
          </button>
          <button
            type="submit"
            onMouseDown={() => {
              setInPress(!InPress);
            }}
            onMouseUp={() => {
              setInPress(!InPress);
            }}
            onClick={(e) => {
              e.preventDefault()
              handleUpdateCartItem();
            }}
            className={classNames(
              (isLoadingBookmarkCardItem || loadingDeleteBookmarkCard)
                ? " bg-[#D90429] bg-opacity-80"
                : InPress
                  ? "bg-[#D90429]"
                  : " bg-[#D90429] hover:bg-[#D90429] hover:bg-opacity-80",
              "flex flex-1 flex-row items-center justify-center rounded-md border border-transparent py-3 px-8 text-base font-medium text-white focus:outline-none focus:ring-2 focus:ring-[#D90429] focus:ring-offset-2 focus:ring-offset-gray-50 sm:w-full cursor-pointer"
            )}
          >
            {t("up_cart")}
            {(isLoadingBookmarkCardItem || loadingDeleteBookmarkCard) && <Spinner />}
          </button>
        </div>
      </div>
    </div>
  );
};

export default ProductInfoSectionUpdate;
