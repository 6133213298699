import React from "react"
import { Checkbox, TableSortLabel, Typography } from "@mui/material";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

export const EnhancedTableHead = (props) => {
    const { selectedAll } = useSelector((state) => state.bookmarkCard);
    const { onSelectAllClick} = props;
    const { t } = useTranslation();
    const headCells = [
      {
        id: t("pdt_info"),
        numeric: false,
        disablePadding: false,
        label: t("pdt_info"),
      },
      {
        id: t("routing"),
        numeric: false,
        disablePadding: false,
        label: t("routing"),
      },
      {
        id: t("unit price"),
        numeric: true,
        disablePadding: false,
        label: t("unit price"),
      },
      {
        id: t("quantity"),
        numeric: true,
        disablePadding: false,
        label: t("quantity"),
      },
      {
        id: t("tot_price"),
        numeric: true,
        disablePadding: false,
        label: t("tot_price"),
      },
    ];

    return (
      <div>
        <div className="relative flex sm:items-center ">
          <div padding="checkbox">
            <Checkbox
              checked={selectedAll}
              onClick={onSelectAllClick}
              sx={{ color: "#D90429" }}
              inputProps={{ "aria-label": "select all desserts" }}
            />
            <span style={{ color: "#D90429" }}>{t("slct_all")}</span>
          </div>
          {headCells?.map((headCell) => (
            <Typography
              sx={{
                color: "#D90429",
                width: headCell.label === "Items" ? "310px" : null,
                flex: headCell.label === "Items" ? null : 1,
              }}
              className = "line-clamp-1"
              key={headCell?.id}
              rowSpan={headCell.label === "Items" ? 2 : 1}
              align={headCell?.numeric ? "center" : "center"}
              padding={headCell?.disablePadding ? "none" : "normal"}
              component={"div"}
            >
              <TableSortLabel  >
                {headCell?.label}
                {headCell?.label === ("Prices" || "Totals") ? "FCFA" : null}
              </TableSortLabel>
            </Typography>
          ))}
          <Typography
            sx={{
              flex: 1,
            }}
            component={"div"}
          >
          </Typography>
        </div>
      </div>
    );
}