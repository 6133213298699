import React from 'react'
import UsersTable from '../../components/Users/UsersTable'
import { scroolWindowToTop } from '../../utilities'

function Customer() {

    React.useEffect(() => {
        scroolWindowToTop()
    }, [0])

    return (
        <div className="px-4 sm:px-6 lg:px-8" >
            <div className="sm:flex sm:items-center">
                <div className="sm:flex-auto">
                    <h1 className="text-xl font-semibold text-gray-900">Users</h1>
                    <p className="mt-2 text-sm text-gray-700">
                        A list of all the users in your account including their name, title, email and role.
                    </p>
                </div>
            </div>
            <UsersTable />
        </div>
    )
}

export default Customer
