import React from "react";
import { Disclosure } from "@headlessui/react";
import { HeartIcon, MinusIcon, PlusIcon } from "@heroicons/react/24/outline";
import DeliverySelectSection from "./DeleverySelectSection";
import { ProductQuantity } from "../../pages/ProductDetails/ProductQuantity";
import { useSelector } from "react-redux";
import ViewSeaOrAirPrice from "../../pages/ProductDetails/ViewSeaOrAirPrice";
import { getFakeName, isRealValue, toString } from "../../utilities";
import VariantsSections from "./VariantsSections";
import Spinner from "../Spinner";
import useMutationApi from "../../app/hooks/useMutationApi";
import { useNavigate } from "react-router-dom";
import { HOME, SECURE_CHECKOUT, SIGN_IN, STORE } from "../../constants/routes";
import { useTranslation } from "react-i18next";
import { useNotifications } from "../../app/hooks/useNotifications";
import {
  useLocalDbHook,
  useRedirection,
} from "../../app/localDatabase/useLocalDbHook";
import LoadingButton from "../common/LoadingButton";
import ModalFlat from "../ModalFlat";
import AddItemToHomeForm from "../AddItemToHomeForm";
import { Button as ButtonP } from "@material-tailwind/react";
import ProposeUserSizeDialog from "../../pages/ProductDetails/ProposeUserSizeDialog";
import ceinture from "../../assets/images/measure.png";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}
const ProductInfoSection = ({ sourceItem }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [InPress, setInPress] = React.useState(false);
  const [InPress2, setInPress2] = React.useState(false);
  const [isOpen, setIsOpen] = React.useState(false);
  const closeModal = () => setIsOpen(false);
  const openModal = () => setIsOpen(true);
  const { bookmarkItemsOff } = useLocalDbHook();
  const { setReturnLinkForAfterLogin } = useRedirection();

  const { warningNotification } = useNotifications();
  const { isLogged, user } = useSelector((state) => state.auth);
  const { items } = useSelector((state) => state.bookmarkItems);
  const { lng } = useSelector((state) => state.app);
  const { item, seaOrAir, selectedVariants, cartToAdd, proposesSize } =
    useSelector((state) => state?.item);
  const {
    handleAddToFavoriteCart,
    handleAddBookmarkItem,
    isLoadingBookmarkCardItem,
    isLoadingFavorite,
  } = useMutationApi();
  const isForeignLog = isLogged && user?.role === "logistician-foreign";

  const goToStore = (e) => {
    e.preventDefault();
    navigate(`${STORE}/${item?.store}`);
  };

  const processToPayment = () => {
    const state = { state: { orderTmp: produceTmpOrder() } };
    if (!isLogged) {
      setReturnLinkForAfterLogin(SECURE_CHECKOUT, toString(state));
      navigate(`${SIGN_IN}`);
    } else {
      navigate(`${SECURE_CHECKOUT}`, state);
    }
  };
  const produceTmpOrder = () => {
    const basePrice =
      cartToAdd?.route === "sea"
        ? item?.basePrice["cm"]?.seaPrice
        : item?.basePrice["cm"]?.airPrice;
    const reducedPrice = isRealValue(item?.reducedPrice)
      ? cartToAdd?.route === "sea"
        ? item?.reducedPrice["cm"]?.seaPrice
        : item?.reducedPrice["cm"]?.airPrice
      : null;
    const unitPrice = isRealValue(reducedPrice) ? reducedPrice : basePrice;
    const totalPrice = unitPrice * cartToAdd?.quantity;
    return {
      order: {
        totalAmount: totalPrice,
        pickupLocation: "",
        items: [
          {
            itemKey: cartToAdd?.itemKey,
            variantKey: cartToAdd?.variantKey,
            store: cartToAdd?.store,
            route: cartToAdd?.route,
            quantity: cartToAdd?.quantity,
            unitPrice,
            totalPrice,
          },
        ],
      },
    };
  };
  const buildOrderAndDirectCheckout = (e) => {
    e.preventDefault();
    processToPayment();
  };

  const isFavorite = () => {
    const itemFav = isLogged
      ? items?.find((article) => article?.key === item?.key)
      : bookmarkItemsOff?.find((v) => v.key === item?.key);
    if (itemFav) {
      return true;
    }
    return false;
  };

  const viewSourceItem = () =>
    window.open(
      sourceItem?.startsWith("https://") || sourceItem?.startsWith("http://")
        ? sourceItem
        : "https://" + sourceItem,
      "_blank",
      "noreferrer"
    );

  const [openRight, setOpenRight] = React.useState(false);
  const openDrawerProposeSize = () => setOpenRight(true);
  const closeDrawerProposeSize = () => setOpenRight(false);
  const refM = React.useRef(null);

  return (
    <div className="px-4 mt-10 sm:mt-16 sm:px-0 lg:mt-0 " ref={refM}>
      <h1 className="text-3xl w-full font-bold tracking-tight text-gray-900 truncate overflow-hidden  ">
        {item?.description[lng]}
      </h1>
      <div className="mt-3">
        {!isLogged &&
          item?.grade === "silver" &&
          isRealValue(item?.info) &&
          isRealValue(item?.info?.price) && (
            <div
              as="div"
              className=" text-sm font-medium text-gray-700 w-full bg-[#D90429] bg-opacity-80 p-3 rounded-md mt-5 "
            >
              <span className="mx-auto font-medium text-white ">
                {item?.info?.price[lng]}
              </span>{" "}
            </div>
          )}
        <h2 className="sr-only">Product information</h2>
        <ViewSeaOrAirPrice
          item={item}
          isLogged={isLogged}
          seaOrAir={seaOrAir}
        />
      </div>
      <div className="mt-6">
        <h3 className="sr-only">Description</h3>
        <div className="space-y-6 text-base text-gray-700">
          <span>{item?.description[lng]}</span>
        </div>
      </div>
      <div className="mt-6">
        <DeliverySelectSection isLogged={isLogged} />
      </div>

      {isRealValue(item?.info) && isRealValue(item?.info?.size) && (
        <div
          as="div"
          className=" text-sm font-medium text-gray-700 w-full bg-[#D90429] bg-opacity-80 p-3 rounded-md mt-5 "
        >
          <span className="mx-auto font-medium text-white ">
            {item?.info?.size[lng]}
          </span>{" "}
        </div>
      )}

      <div className="mt-6 ">
        <VariantsSections item={item} />

        {isRealValue(item?.gender) && isRealValue(item?.measures) && item?.measures?.length > 0 &&
          <ButtonP
            onClick={openDrawerProposeSize}
            variant="outlined"
            className="justify-start flex normal-case min-w-[55%] max-w-[55%] md:max-w-[50%] md:min-w-[50%] mt-5"
          >
            <img
              className="h-5 w-10 object-cover object-center mr-4"
              src={ceinture}
              alt=""
            />
            {isRealValue(proposesSize?.data) ? proposesSize?.data[lng] : t("fit_online")}
          </ButtonP>}

        <div className="relative mt-6">
          <h2 className="text-sm font-medium text-gray-900">{t("quantity")}</h2>
          <ProductQuantity />
          {isForeignLog && (
            <div className=" sm:absolute sm:right-0 sm:top-9">
              <LoadingButton
                style={{ marginBottom: 11 }}
                title={t("add_item_home")}
                onClick={(e) => {
                  e.preventDefault();
                  openModal();
                }}
              />
              <LoadingButton
                style={{ width: "fit-content", margin: 0 }}
                title={t("itm_src")}
                onClick={(e) => {
                  e.preventDefault();
                  viewSourceItem();
                }}
              />
            </div>
          )}
        </div>
        <div
          onClick={goToStore}
          className="flex flex-row items-center justify-start bg-transparent rounded-md cursor-pointer "
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="#D90429"
            className="w-10 h-10 "
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M13.5 21v-7.5a.75.75 0 01.75-.75h3a.75.75 0 01.75.75V21m-4.5 0H2.36m11.14 0H18m0 0h3.64m-1.39 0V9.349m-16.5 11.65V9.35m0 0a3.001 3.001 0 003.75-.615A2.993 2.993 0 009.75 9.75c.896 0 1.7-.393 2.25-1.016a2.993 2.993 0 002.25 1.016c.896 0 1.7-.393 2.25-1.016a3.001 3.001 0 003.75.614m-16.5 0a3.004 3.004 0 01-.621-4.72L4.318 3.44A1.5 1.5 0 015.378 3h13.243a1.5 1.5 0 011.06.44l1.19 1.189a3 3 0 01-.621 4.72m-13.5 8.65h3.75a.75.75 0 00.75-.75V13.5a.75.75 0 00-.75-.75H6.75a.75.75 0 00-.75.75v3.75c0 .415.336.75.75.75z"
            />
          </svg>
          <h4 className="ml-3 text-xl font-medium text-gray-700 ">
            {item?.storeName[lng]}
          </h4>
        </div>

        <div className="flex mt-10 space-x-1 ">
          <button
            type="button"
            onClick={() => handleAddBookmarkItem(item?.key)}
            className={
              isFavorite()
                ? "ml-4 flex items-center justify-center rounded-md py-3 px-3 text-white bg-[#D90429]"
                : "ml-4 cursor-pointer flex items-center justify-center rounded-md py-3 px-3 text-red-900 bg-red-100"
            }
          >
            {!isLoadingFavorite && (
              <HeartIcon className="flex-shrink-0 w-6 h-6" aria-hidden="true" />
            )}
            {isLoadingFavorite && <Spinner />}
            <span className="sr-only">{t("add_to_favorite")}</span>
          </button>
          <button
            type="submit"
            onMouseDown={() => {
              setInPress(!InPress);
            }}
            onMouseUp={() => {
              setInPress(!InPress);
            }}
            onClick={(e) => {
              if (Object.keys(selectedVariants).length < 1) {
                warningNotification(t("select_variant_warning"));
                return;
              }
              handleAddToFavoriteCart(cartToAdd);
            }}
            className={classNames(
              isLoadingBookmarkCardItem ||
                Object.keys(selectedVariants).length < 1
                ? " bg-[#D90429] bg-opacity-80"
                : InPress
                  ? "bg-[#D90429]"
                  : " bg-[#D90429] hover:bg-[#D90429] hover:bg-opacity-80",
              "flex w-[45%] flex-row items-center justify-center rounded-md border border-transparent py-3 px-8 text-base font-medium text-white focus:outline-none focus:ring-2 focus:ring-[#D90429] focus:ring-offset-2 focus:ring-offset-gray-50 sm:w-full cursor-pointer"
            )}
          >
            {t("atc")}
            {isLoadingBookmarkCardItem && <Spinner />}
          </button>
          <button
            type="submit"
            onMouseDown={() => {
              setInPress2(!InPress2);
            }}
            onMouseUp={() => {
              setInPress2(!InPress2);
            }}
            onClick={(e) => {
              if (Object.keys(selectedVariants).length < 1) {
                warningNotification(t("select_variant_warning"));
                return;
              }
              buildOrderAndDirectCheckout(e);
            }}
            className={classNames(
              isLoadingBookmarkCardItem ||
                Object.keys(selectedVariants).length < 1
                ? " bg-[#d8572a] bg-opacity-80"
                : InPress2
                  ? " bg-orange-600"
                  : " bg-orange-600 hover:bg-orange-500",
              "flex flex-row w-[45%] h-[55px] cursor-pointer items-center justify-center rounded-md border border-transparent text-base font-medium text-white focus:outline-none focus:ring-2 focus:ring-[#D90429] focus:ring-offset-2 focus:ring-offset-gray-50 sm:w-full"
            )}
          >
            {t("buy_now")}
          </button>
        </div>
      </div>
      <section aria-labelledby="details-heading" className="mt-12">
        <h2 id="details-heading" className="sr-only">
          Additional details
        </h2>
        <div className="border-t divide-y divide-gray-200">
          <Disclosure as="div">
            {({ open }) => (
              <>
                <h3>
                  <Disclosure.Button className="relative flex items-center justify-between w-full py-6 text-left group">
                    <h3
                      className={classNames(
                        open ? "text-[#D90429]" : "text-gray-900",
                        "text-sm font-medium"
                      )}
                    >
                      {t("desc")}
                    </h3>
                    <span className="flex items-center ml-6">
                      {open ? (
                        <MinusIcon
                          className="block w-6 h-6 text-fuchsia-400 group-hover:text-[#D90429]"
                          aria-hidden="true"
                        />
                      ) : (
                        <PlusIcon
                          className="block w-6 h-6 text-gray-400 group-hover:text-gray-500"
                          aria-hidden="true"
                        />
                      )}
                    </span>
                  </Disclosure.Button>
                </h3>
                <Disclosure.Panel as="div" className="pb-6 prose-sm prose">
                  <ul role="list">
                    <li>
                      <span className="mr-3 font-medium">
                        {/* {lng === 'en' && `Store name :`} */}
                        {/* {lng === 'fr' && `Nom Boutique :`} */}
                        Store Name:
                      </span>
                      <span>{item?.storeName[lng]}</span>
                    </li>
                    {item?.attributes?.map((attribute, index) => (
                      <li key={attribute}>
                        <span className="mr-3 font-medium">
                          {attribute?.propertyName[lng]} :
                        </span>
                        <span>{attribute?.valueName[lng]}</span>
                      </li>
                    ))}
                  </ul>
                </Disclosure.Panel>
              </>
            )}
          </Disclosure>
        </div>
      </section>
      {isForeignLog && (
        <ModalFlat
          isOpen={isOpen}
          children={
            <AddItemToHomeForm itemKey={item?.key} closeModal={closeModal} />
          }
          title={t("add_item_home")}
          closeModal={closeModal}
        />
      )}
      {openRight &&
        <ProposeUserSizeDialog
          ref={refM}
          close={closeDrawerProposeSize}
          open={openRight}
        />
      }
    </div>
  );
};

export default ProductInfoSection;
