import React from 'react'
import useMutationApi from '../app/hooks/useMutationApi'
import { useFormatter } from '../app/hooks/useFormatter'
import { useTranslation } from 'react-i18next'
import { Controller, useForm } from 'react-hook-form'
import SelectMenu from './common/SelectMenus'
import LoadingButton from './common/LoadingButton'

const AddItemToHomeForm = ({ itemKey, closeModal }) => {
  const { addItemsHome, isLoadingAddItemsHomesByForeignLogistician } = useMutationApi()
  const { display, getHomeTypeData, getHomeValueData /* code*/ } = useFormatter()
  const [required, setRequired] = React.useState(false)
  const { t } = useTranslation()
  const { control, handleSubmit, formState: { errors }, } = useForm({
    defaultValues: { type: "", code: "" }
  });

  const handleSubmitAddItem = (data) => {
    const type = data.type?.value
    var body = { type, itemKey }
    if (type === "selection")
      body.code = data?.code?.value
    addItemsHome(body, () => closeModal())
  }

  const onChangeType = (v, onChange) => {
    onChange(v)
    if (v?.value === "selection") setRequired(true)
    else setRequired(false)
  }

  return (
    <form
      className='reg-form'
      style={{ overflow: "scroll", overflowX: "unset", height: "auto", alignItems: "center" }}
      onSubmit={handleSubmit(handleSubmitAddItem)}
    >
      <Controller control={control} rules={{ required: true }}
        render={({ field: { onChange, value } }) => (
          <SelectMenu
            style={{ width: 200, marginBottom: 11 }}
            data={getHomeTypeData}
            onChange={v => onChangeType(v, onChange)}
            display={display}
            defaultText={t("type")}
            label={t("type")}
            selectedValues={value}
            errorProp={errors?.type}
          />
        )}
        name="type"
      />

      <Controller control={control} rules={{ required: required }}
        render={({ field: { onChange, value } }) => (
          <SelectMenu
            style={{ width: 200, marginBottom: 22, marginTop: 22, opacity: !required ? 0.3 : 1 }}
            data={getHomeValueData}
            onChange={onChange}
            display={display}
            defaultText={t("code")}
            label={t("code")}
            selectedValues={value}
            disabled={!required}
            errorProp={errors?.code}
          />
        )}
        name="code"
      />
      <LoadingButton
        style={{ width: "fit-content" }}
        title={t("Confirm")}
        isLoading={isLoadingAddItemsHomesByForeignLogistician}
      />
    </form>
  )
}

export default AddItemToHomeForm