import React from "react";
import { IoCashOutline, IoFolder } from "react-icons/io5";
import InputFlatBorder from "../common/InputFlatBorder";
import { Controller, useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { useFormatter } from "../../app/hooks/useFormatter";
import LoadingButton from "../common/LoadingButton";
import useMutationApi from "../../app/hooks/useMutationApi";
import { isRealValue } from "../../utilities";
import CreditCardComponent from "../CreditCardComponent";
import { useTranslation } from "react-i18next";
import ImageSelect from "../common/ImageSelect";

const CompletePaymentForm = ({ orderId }) => {

  const [BankProof, setBankProof] = React.useState(null)
  const [BranchProof, setBranchProof] = React.useState(null);
  const { totalBalanceViews } = useSelector(state => state.orders);
  const { card, euroRelated, amountToPay } = useSelector(state => state.stripe)
  const [method, setMethod] = React.useState({})
  const { t } = useTranslation()
  const {
    display, handleFormatAmount, rmSpace, formatAmount, formatPrice,
    putOperationTransactionData, putOperationMethodData,
  } = useFormatter()
  const { control, handleSubmit, clearErrors, setError, setValue, formState: { errors }, } = useForm({
    defaultValues: { amount: `${totalBalanceViews || 0}`, operation: "payment", method: "", }
  })
  const {
    isPaymentOrderByUser,
    isCreateIntentionPayment,
    isConfirmIntentionPayment,
    isLoadingPaymentForex,
    payOrderByUser,
    payWithCreditCard,
    paymentForex,
  } = useMutationApi()


  const handlePaymentOrder = (data) => {
    // console.log('data', data)
    const amount = parseInt(rmSpace(data.amount), 10)
    const base = { operation: data.operation, amount }
    const m = data.method
    let body = {}
    if (m === 'wallet') {
      body = { ...base, method: m }
      payOrderByUser(orderId, body)
    } else if (m === 'bank transfer' || m === 'bank deposit') {
      body = new FormData();
      body.append("operation", base?.operation)
      body.append("amount", amount)
      body.append("method", m)
      body.append("transactionImage", {
        // uri: Platform.OS === 'ios' ? imgLoad.uri.replace('file://', '') : imgLoad.uri,
        // type: imgLoad.type === 'image/jpeg' ? 'image/jpg' : imgLoad.type,
        // name: imgLoad.uri.substring(imgLoad.uri.lastIndexOf('/') + 1)
      })
      payOrderByUser(orderId, body)
    }
    else if (m === 'credit card') {
      // console.log("data", data)
      if (euroRelated?.currency === "XAF") {
        payWithCreditCard(amount, orderId, card)
      } else {
        body = {
          amount: amountToPay,
          amountConverted: euroRelated?.amount,
          currency: euroRelated?.currency
        }
        paymentForex(orderId, card, body)
      }
    }
  }

  const updateAmount = (v) => {
    // console.log("v", v)
    if (isRealValue(v)) {
      // const value = rmSpace(v)
      const value = v
      if (Number(value) <500 || Number(value) >= totalBalanceViews) {
        setError("amount", { type: "required" })
        setValue("amount", "")
        return false
      } else {
        // setValue("amount", formatAmount(v))
        setValue("amount", v)
        clearErrors("amount")
        return true
      }
    } else {
      setValue("amount", "")
    }
  }

  // const { order } = useSelector((state) => state.bookmarkCard);
  // const { requireDeposit } = useSelector((state) => state.orders);


  return (
    <div className="mx-auto min-w-2xl ">
      <InputFlatBorder
        label={t("Operation")}
        rightIcon={<IoFolder className="h-5 w-5 text-[#D90429]" />}
        defaultValue={display(
          putOperationTransactionData.find((t) => t.value === "payment")
        )}
        disabled
      />
      <div>
        <label
          htmlFor="selected-tab"
          className="block text-sm font-medium text-gray-700"
        >
          {t("Method")}
        </label>
        <Controller
          control={control}
          rules={{ required: true }}
          name="method"
          render={({ field: { onChange } }) => (
            <select
              id="selected-tab"
              name="selected-tab"
              onClick={(e) => {
                const v = e.target.value;
                setMethod(v);
                onChange(v);
                setValue("amount", 0);
                updateAmount(0);
              }}
              className="block w-full py-2 pl-3 pr-10 mt-1 text-base border border-gray-300 rounded-md focus:border-[#D9042990] focus:outline-none focus:ring-[#D9042990] sm:text-sm"
            >
              {(
                putOperationMethodData?.filter((v) => v.value != "cash") || []
              )?.map((tab, index) => (
                <option value={tab.value} className="" key={index}>
                  {display(tab)}
                </option>
              ))}
            </select>
          )}
        />
      </div>
      {method !== "credit card" && (
        <Controller
          control={control}
          rules={{
            required: true,
            validate: (v) => {
              if (parseInt(v) < 500 || parseInt(v) > totalBalanceViews) {
                setError("amount", { type: "required" });
                setValue("amount", v);

                return false;
              }
              setValue("amount", v);
              clearErrors("amount");
              return true;
            },
          }}
          name="amount"
          render={({ field: { onChange, value, onBlur } }) => (
            <InputFlatBorder
              label={"Amount"}
              rightIcon={<IoCashOutline className="h-5 w-5 text-[#D90429]" />}
              placeholder={"Amount"}
              onChange={onChange}
              value={value}
              type={"number"}
              defaultValue={amountToPay}
              error={
                errors.amount
                  ? `Payment amount is between ${formatAmount(
                      500
                    )} - ${formatPrice(totalBalanceViews)}`
                  : undefined
              }
            />
          )}
        />
      )}
      {method === "credit card" && (
        <CreditCardComponent
          showButton={false}
          totalAmount={totalBalanceViews}
          isFirstOrdered={false}
          updateAmount={updateAmount}
        />
      )}
      {method === "bank transfer" && (
        <ImageSelect setSelectedImg={setBankProof} selectedImg={BankProof} />
      )}
      {method === "bank deposit" && (
        <ImageSelect
          setSelectedImg={setBranchProof}
          selectedImg={BranchProof}
          
        />
      )}
      <LoadingButton
        title={t("confirm_payment")}
        style={{ marginTop: 3, marginBottom: 7 }}
        onClick={handleSubmit(handlePaymentOrder)}
        isLoading={
          isPaymentOrderByUser ||
          isCreateIntentionPayment ||
          isConfirmIntentionPayment ||
          isLoadingPaymentForex
        }
        disabled={
          (method?.value === "credit card" &&
            isRealValue(card) &&
            isRealValue(euroRelated) &&
            500 <= amountToPay &&
            amountToPay <= totalBalanceViews) ||
          // ((euroRelated?.euro && euroRelated?.currency === "XAF") || (!euroRelated?.euro && euroRelated?.currency !== "XAF"))
          !(
            method?.value !== "credit card" &&
            amountToPay >= 500 &&
            amountToPay <= totalBalanceViews
          )
        }
      />
    </div>
  );
};

export default CompletePaymentForm;
