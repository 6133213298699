export const HOME = '/';

export const SIGN_UP = '/user/register';

export const SIGN_IN = '/user/sign-in';
export const FORGOT_PASSWORD = '/user/sign-in/reset-password'
export const CONFIRM_PASSWORD_RESET = '/password-reset'

export const SIGN_IN_SIGN_UP = '/user/sign-in-sign-up'
export const FAVORITES = '/user/favorites/items'
export const FAVORITES_STORE = '/user/favorites/stores'

export const CART = '/user/cart/checkout'
export const PAYMENT = '/user/cart/proceed-payment'
export const SECURE_CHECKOUT = '/user/checkout_securely'
export const VERIFY_OTP_CODE = '/user/checkout_securely/otp/verify/code'
export const RESEND_OTP_CODE = '/user/checkout_securely/otp/resend/code'
export const CHECKOUT_FILTER = '/user/orders/checkout_filter'
export const PROFILE = '/user/profile'
export const CONDITIONS = '/condition-general/'
export const POLICY = '/politique-de-confidentialite/'
export const PAYMENTS = '/modalites-de-paiement/'
export const LIVRAISON = '/livraison-et-collecte/'
export const COMMANDER = '/comment-commander/'

export const PRODUCT_DETAILS = '/items/items-details/view'
export const PRODUCT = '/items/category'

export const STORE_PRODUCTS = '/stores/items'
export const STORE_SIZE = '/stores/sizes'
export const STORE_HOME = '/stores/'
export const STORE = '/stores'
export const SEARCH = '/search'

export const WHATSAPP = '/whatsapp';
export const ORDER_HISTORY_DETAILS = '/order-history/edit/details';

export const ORDERS = '/user/orders'
export const ACKNOWLEDGEMENT = `${ORDERS}/acknowledgement`
export const ORDER_DETAILS = `/order/details/`

export const DASHBOARD = "/dashboard/account/"
export const DASHBOARD_CHATS = `${DASHBOARD}chats`
export const DASHBOARD_DELIVERY_AREA = `${DASHBOARD}delivery-area`
export const DASHBOARD_DELIVERY_AREA_CREATE = `${DASHBOARD}delivery-area/create`
export const DASHBOARD_WALLET = `${DASHBOARD}wallet`
export const DASHBOARD_STATEMENT = `${DASHBOARD}statements`
export const DASHBOARD_HISTTRANSACTION = `${DASHBOARD}transactions/history`
export const DASHBOARD_CUSTOMERS = `${DASHBOARD}customers`
export const DASHBOARD_SETTINGS = `${DASHBOARD}settings`
export const DASHBOARD_SHIPPING_ADDRESS = `${DASHBOARD}shippings/addresses`
export const DASHBOARD_HOLDS = `${DASHBOARD}holds`
export const DASHBOARD_ORDERS_HISTORY = `${DASHBOARD}orders/history`


export const DASHBOARD_BANKS = `${DASHBOARD}banks`
export const DASHBOARD_LOCATIONS = `${DASHBOARD}locations`
export const DASHBOARD_LOCATIONS_CREATE = `${DASHBOARD}locations/create`
export const DASHBOARD_SHIPMENTS_TRANSITS = `${DASHBOARD}shipments/transits`
export const DASHBOARD_SHIPMENTS_PURCHASES = `${DASHBOARD}shipments/purchases`

export const DASHBOARD_OPENED_FULFILLMENTS = `${DASHBOARD}fulfillments/opened`
export const DASHBOARD_CLOSED_FULFILLMENTS = `${DASHBOARD}fulfillments/closed`

export const DASHBOARD_ORDER_HISTORY_BY_CARDS = `${DASHBOARD_ORDERS_HISTORY}/credit-cards`

export const DASHBOARD_STATEMENT_CUSTOMER_BY_STAFF = `${DASHBOARD_STATEMENT}/users/{{userId}}`

export const DASHBOARD_OPENED_BILL_OF_LADING = `${DASHBOARD_STATEMENT}/bills-of-lading/opened`
export const DASHBOARD_CLOSED_BILL_OF_LADING = `${DASHBOARD_STATEMENT}/bills-of-lading/closed`
