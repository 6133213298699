import React from 'react'
import { Link } from 'react-router-dom'
import { HOME } from '../../../constants/routes'
import LockIcon from '@mui/icons-material/Lock';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import logo from '../../../assets/images/Logo_AFLP.png'
import './styles.css'

const CheckoutBar = () => {
  return (
    <div className='row-flex-space bar-wrapper' >
        <div className='row-flex' >
            <div>
                <Link className = 'link' to={HOME} >
                      <img onContextMenu = { (event)=> { event.preventDefault();    }  } src={logo} className="w-20 md:w-20 lg:w-32 lg:h-20 object-contain object-center" />
                </Link>
            </div>
        </div>
        <Link className='link' to={ HOME } >
            <div className='row-flex'>
                <span className = 'ship-text' > Continue Shopping </span>
                <ChevronRightIcon sx = {{ color: 'var(--unnamed-color)' }} />
            </div>
        </Link>
    </div>
  )
}

export default CheckoutBar