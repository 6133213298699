import { createSlice } from "@reduxjs/toolkit";
import {  getUser, isLogged, updateAuth, setAuthUser, refreshToken, authorization, compare, SetFiles, setLanguage, setStorage, timeDate, deleteAuth, isRealValue } from "../../utilities";

var indexSms = 0
export function setIndexSms(index){
    indexSms = index
}
export function getIndexSms(){
    return indexSms 
}

const initialState = {
    authorization: authorization(),
    refreshToken: refreshToken(),
    user: getUser(),
    isLogged: isLogged(),
    formLogin: {},
    headerType: "none",
    isStore: false,
    header: true,
    checkout: false,
    titlePage: 'Home',
    messages: [],
    rooms: [],
    message: "",
    indexMessage: null,
    imagePreview: null,
    audioPreview: null,
    videoPreview: null,
    file: {},
    recordState: null,
    recordCanvas: false,
    InfoAccount:{},
    localization: {},
    selected: 0,

}

const authSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {
        handleAuthentication(state, { payload }) {
            const auth = payload
            if(isRealValue(auth?.user)){
                state.user = auth?.user
                state.authorization = auth?.accessToken.includes("Bearer ") ? auth?.accessToken : "Bearer ".concat(auth?.accessToken)
                state.refreshToken = auth?.refreshToken
                state.isLogged = true
            }
            else{
                deleteAuth()
                state = initialState
            }
        },
        handleVoiceRecording(state, action){
            state.recordState = action.payload
            if(state.recordState==="start"){
                state.recordCanvas = true
            }
        },
        handleDeleteVoiceRecording(state, action){
            state.recordState = action.payload
            state.recordCanvas = false
            state.recordState = null
        },
        handleFile(state, action){
            state.file = action.payload
        },
        handleMessage(state, action){
            state.message = action.payload
        },
        handleImagePreview(state, action){
            state.imagePreview = action.payload
            state.videoPreview = null
            state.audioPreview = null
            state.message = ""
        },
        handleVideoPreview(state, action){
            state.videoPreview = action.payload
             state.imagePreview = null
            state.audioPreview = null
            state.message = ""
        },
        handleAudioPreview(state, action){
            state.audioPreview = action.payload
            state.imagePreview = null
            state.videoPreview = null
            state.message = ""
        },
        handleLanguage(state, action){
            state.language = action.payload
            setLanguage(action.payload)
        },
        handleHeaderType(state, action){
            state.header= action.payload?.header
            state.isStore= action.payload?.isStore
            state.headerType= action.payload?.headerType
            state.checkout = action.payload?.checkout
        },
        handleClearFiles(state){
            state.imagePreview = null
            state.videoPreview = null
            state.audioPreview = null
            state.message=""
            SetFiles(null)
        },
        handleTitleMobilePage(state, action){
            state.titlePage= action.payload
        },
        setChangeHeaderPage(state, action) {
            state.header = { ...state.header, ...action.payload }
        },
        setFormLogin(state, action) {
            state.formLogin = { ...state.formLogin, ...action.payload };
        },
        setAuthorization(state, {payload}) {
            const accessToken = payload
            const auth = {
                user: state.user,
                accessToken,
                refreshToken: state.refreshToken
            }
            updateAuth(auth)
            state.authorization = auth?.accessToken.includes("Bearer ") ? auth?.accessToken : "Bearer ".concat(auth?.accessToken)
        },
        setAuth(state, action) {
            setAuthUser(action.payload)
            state.isLogged = true
        },
        loggedOut(state) {
            deleteAuth()
            localStorage.clear()
            state.isLogged = false
            state.refreshToken = null
            state.user = null
            state.authorization = null
        },
        handleMessages(state, action) {
            const {response, arg} = action.payload
            const { index, user_id} = arg
            setIndexSms(index)
            state.indexMessage= index
            response?.result?.messages?.sort((m1 , m2) => compare(m1?.created, m2.created))
            const copyMessage = state.messages
            copyMessage[index] = {response, user_id}

            state.messages = copyMessage

        },
        handleRooms(state, action){
            state.rooms = action.payload
        },
        handleAddMessageHistory(state, action){
            const message = action.payload
            state.messages[state.indexMessage].response.result.messages.push(message)
        },
        handleIndexMessage(state, action){
            state.indexMessage = action.payload
        },
        handleInfosAccount(state, action){
            state.InfoAccount = action.payload
        },
        handleLocalization(state, action){
            state.localization = action.payload
        },
        handleSelected(state, action){
            state.selected = parseInt(action.payload)
            setStorage('dsbcheck', action.payload)
        },

        //TODO
        handleContacts(state, action) {
            state.contacts = action.payload
        },
        handleSendMessage(state, action) {
            var messages = []
            var messages = state.messages
            messages.push(action.payload)
            messages.sort((a, b) => (timeDate(timeDate(b.createdAt) - a.createdAt)))
            state.messages = messages
        },
        handleCountries(state, action) {
            state.countries = action.payload
            state.country = state.countries[0].country
            state.callingCode = state.countries[0].callingCode.replace("+", "%2b")
        },
        handleSelectCountry(state, action) {
            const data = action.payload
            state.country = data.country
            state.callingCode = data.callingCode.replace("+", "%2b")
        },

//TODO


       
      
    },
})

export const { 
    handleFile, 
    handleMessage, 
    handleImagePreview, 
    handleVideoPreview,
    handleAudioPreview, 
    handleClearFiles, 
    setChangeHeaderPage, 
    setAuth, 
    loggedOut, 
    setAuthorization, 
    handleMessages, 
    handleRooms, 
    handleVoiceRecording, 
    handleDeleteVoiceRecording,
    handleHeaderType, 
    setFormLogin, 
    handleTitleMobilePage, 
    handleSetUser, 
    handleLanguage, 
    handleAddMessageHistory, 
    handleIndexMessage,
    handleInfosAccount,
    handleLocalization,
    handleSelected,


    handleAuthentication,
    handleContacts,
    handleSendMessage,
    handleCountries,
    handleSelectCountry,


} = authSlice.actions

export default authSlice