import React from 'react'
import { useNavigate } from 'react-router-dom';
import { PRODUCT_DETAILS, STORE, } from '../../../constants/routes';
import { getFakeName, isRealValue } from '../../../utilities'
import { useSelector } from 'react-redux';
import ItemLoading from './ItemLoading'
import ViewSeaOrAirPrice from '../../../pages/ProductDetails/ViewSeaOrAirPrice';
import { handleInvalidateVariants } from '../../../app/features/itemSlice';
import { dispatch } from '../../..';
import { handleItemCategory } from '../../../app/features/categoriesSlice';
import { ClipboardIcon, FolderOpenIcon } from "@heroicons/react/24/outline";

import './styles.css'
import { Menu, Item, Separator, useContextMenu, RightSlot, } from "react-contexify";

import "react-contexify/ReactContexify.css";
import { useNotifications } from '../../../app/hooks/useNotifications';
import { useTranslation } from 'react-i18next';

const ProductItem = (props) => {
  const { lng } = useSelector(state => state?.app)
  const { item, loading } = props
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { successNotification } = useNotifications()
  const { show } = useContextMenu({ id: item?.key });

  function handleItemClick() {
    const link = `${PRODUCT_DETAILS}/${item?.key}`;
    window.open(link, "_blank")
  }
  function handleCopyClick({ }) {
    navigator.clipboard.writeText(
      `${window.location.host}${PRODUCT_DETAILS}/${item?.key}`
    );
    successNotification(t("copy_succ"))
  }
  function displayMenu(e) {
    e.preventDefault();
    show({
      event: e, props: {}
    });
  }
  const handleStoreHome = (e) => {
    e.preventDefault();
    navigate(`${STORE}/${item?.store}`);
  }

  const handlerDetailsItems = function (e) {
    dispatch(handleInvalidateVariants())
    dispatch(handleItemCategory(item?.category));
    e.preventDefault();
    navigate(`${PRODUCT_DETAILS}/${item?.key}`)
  }
  if (loading) {
    return <ItemLoading />
  }

  const canSeeairPrice = isRealValue(item?.basePrice?.cm?.airPrice) || isRealValue(item?.reducedPrice?.cm?.airPrice)
  const seaOrAir = canSeeairPrice ? false : true

  return (
    <div className="mx-auto group relative shadow-sm shadow-[#D9042930] flex flex-col overflow-hidden rounded-lg bg-white max-w-[256px] ">
      <div
        onClick={handlerDetailsItems}
        className="bg-transparent group-hover:opacity-75 relative pb-[120%] cursor-pointer "
      >
        <img
          onContextMenu={displayMenu}
          loading="eager"
          src={item?.picture?.medium}
          alt={item?.description[lng]}
          className="object-contain object-center h-full mx-auto w-full top-0 absolute"
        />
      </div>
      <div className="flex flex-col pl-2">
        <h3 className="w-full text-md font-medium text-gray-900 truncate" >
          {item?.description[lng]}
        </h3>
        <a
          onClick={handleStoreHome}
          className="sm:text-sm lg:text-base font-medium text-gray-600 cursor-pointer focus:text-[#D90429] truncate focus:underline "
        >
          {isRealValue(item?.storeName) ? item?.storeName[lng] : ""}
        </a>
        <div className="flex flex-1 flex-col justify-end w-[100%]">
          <ViewSeaOrAirPrice
            item={{
              basePrice: item?.basePrice,
              reducedPrice: item?.reducedPrice,
            }}
            seaOrAir={seaOrAir}
          />
        </div>
      </div>
      <Menu className="shadow-sm" id={item?.key}>
        <Item onClick={handleItemClick}>
          {t("open_link_in_new_tab")}
          <RightSlot>
            {" "}
            <FolderOpenIcon className="mr-2 h-5 w-5" aria-hidden="true" />
          </RightSlot>
        </Item>
        <Separator />
        <Item onClick={handleCopyClick}>
          {t("copy_item_link")}
          <RightSlot>
            <ClipboardIcon className="mr-2 h-5 w-5" aria-hidden="true" />
          </RightSlot>
        </Item>
        <Separator />
      </Menu>
    </div>
  );
}

export default ProductItem
