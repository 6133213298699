import React from 'react'
import { useSelector } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import CardComponent from '../../components/common/card'
import ForgotForm from '../../components/layout/ForgotForm'
import { HOME } from '../../constants/routes'
import { useTranslation } from 'react-i18next'
import logo from "../../assets/images/Logo_AFLP.png";
import { scroolWindowToTop } from '../../utilities'


const ForgetPassword = () => {
  const { t } = useTranslation();

  const { isLogged } = useSelector(state => state.auth)
  const navigate = useNavigate()
  if (isLogged) navigate(`${HOME}`)

  React.useEffect(() => {
    scroolWindowToTop()
  }, [0])

  return (
    <div>
      <Link className="cursor-pointer" to={HOME}>
        <img
          onContextMenu={(event) => {
            event.preventDefault();
            window.open(event?.target?.currentSrc, "_blank");
          }}
          src={logo}
          className="w-20 h-20 object-contain object-center mx-auto my-4 "
        />
      </Link>
      <CardComponent title={t("forgot_pass")}>
        <h2>{t("forgot_pass")}</h2>
        <p className="auth-text" style={{ width: "75%", marginBottom: 25 }}>
          {t("ent_mail_phone")} <br /> {t("recover_acc")}
        </p>
        <ForgotForm />
      </CardComponent>
    </div>
  );
}

export default ForgetPassword