import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    allLocations: [],
    locations: [],

    locationsBanks: [],
    locationsBranchs: [],

    selectedLocation: {},

}

const locationSlice = createSlice({
    name: 'locations',
    initialState,
    reducers: {

        handleAllLocations(state, { payload }) {
            state.allLocations = payload
            state.locations = payload
        },
        handleAllLocationsBanks(state, { payload }) {
            state.locationsBanks = payload
        },
        handleAllLocationsBranchs(state, { payload }) {
            state.locationsBranchs = payload
        },
        handleLocationByType(state, action) {
            action.payload === "All" ? state.allLocations = state.locations :
                state.allLocations = state.locations.filter(loc => loc.type === action.payload)
        },
        handleSelectLocation(state, { payload }) {
            state.selectedLocation = payload
        }
    },
})

export const {
    handleAllLocations,
    handleLocationByType,
    handleAllLocationsBanks,
    handleAllLocationsBranchs,
    handleSelectLocation,

} = locationSlice.actions

export default locationSlice