import React, { useState } from 'react'
import Badge from '@mui/material/Badge';
import { Divider, Stack, useMediaQuery, } from '@mui/material';
import { AnimatePresence, motion } from 'framer-motion';
import './styles.css'
import { Link } from 'react-router-dom';
import { CART, FAVORITES, FAVORITES_STORE, HOME, SIGN_IN, SIGN_UP } from '../../../constants/routes';
import fav from '../../../assets/images/heart.png'
import cart from '../../../assets/images/cart.png'
import DropdownMenu from '../../layout/DropdownMenu';
import { HiMenuAlt1 } from "react-icons/hi";
import { useSelector } from 'react-redux';
import ClickAwayListener from '@mui/material/ClickAwayListener';

import logo from "../../../assets/images/Logo_AFLP.png"
import logo_gif from "../../../assets/images/quality.gif";
import InputFlatBorder from '../InputFlatBorder';
import { IoSearchSharp } from 'react-icons/io5';
import DropdownStoreMenu from '../../layout/DropdownMenu/DropdownStoreMenu';
import MenuMobile from '../Menus/indexMobIle';
import SearchPanel from '../SearchPanel';
import { useFormatter } from '../../../app/hooks/useFormatter';
import { useTranslation } from 'react-i18next';
import AccountSettingsMenu from '../../layout/AccountSettingsMenu';
import DialogLayout from '../../DialogLayout';
import SearchInput from '../SearchPanel';
import { dispatch } from '../../..';
import { handleCatHover } from '../../../app/features/categoriesSlice';
import { useLocalDbHook } from '../../../app/localDatabase/useLocalDbHook';

const Header = () => {
  const { headerType, isStore, user, isLogged } = useSelector(state => state.auth)
  const { selectedStore, StoreGrade } = useSelector(state => state.store)
  const { numberCartBookmark } = useSelector(state => state.bookmarkCard)
  const { items } = useSelector(state => state.bookmarkItems)
  const { catHover } = useSelector((state) => state.categories);
  const { bookmarkStoresFilter } = useSelector(state => state.bookmarkStore)
  const { bookmarkStoresOff, bookmarkItemsOff, bookmarkCartsOff } = useLocalDbHook()
  const resize = useMediaQuery("(max-width:420px)");


  const { t } = useTranslation()

  const [openS, setOpenS] = React.useState(false);


  const handleClickAway = () => dispatch(handleCatHover(false));
  const handleClickAway2 = () => {
    if (catHover) { dispatch(handleCatHover(false)); return }
    dispatch(handleCatHover(true));
  };


  return (
    <nav>
      <header className="px-2 navbar ">
        {headerType === "store" ? (
          <>
            <div
              className="row-flex-space"
              style={{
                paddingTop: 7,
                paddingBottom: 5,
                justifyContent: "flex-end",
                position: "relative",
              }}
            >
              <Link className="link" to={HOME}>
                <img
                  onContextMenu={(event) => {
                    event.preventDefault();
                    window.open(event?.target?.currentSrc, "_blank");
                  }}
                  src="../../../assets/images/Logo_AFLP.png"
                  className="w-6 h-6"
                />
              </Link>
              {user?.role === "customer" && (
                <div className="items-start space-x-6 row-flex-space head-icons">
                  <Link
                    className="flex flex-col justify-center link"
                    to={FAVORITES}
                  >
                    <Badge badgeContent={items?.length} color="secondary">
                      <img
                        onContextMenu={(event) => {
                          event.preventDefault();
                          window.open(event?.target?.currentSrc, "_blank");
                        }}
                        className="object-contain object-center w-6 h-6 mx-auto "
                        src={fav}
                        alt="favs logo"
                      />
                    </Badge>
                    <h6 className="block text-xs font-medium text-gray-600 lg:hidden line-clamp-1 ">
                      {t("Items")}
                    </h6>
                    <h6 className="hidden text-xs font-medium text-gray-600 lg:block line-clamp-1 ">
                      {t(" Items")}
                    </h6>
                  </Link>
                  <div className="row-flex">
                    <Link
                      className="flex flex-col justify-center link"
                      to={FAVORITES_STORE}
                    >
                      <Badge
                        badgeContent={bookmarkStoresFilter?.length}
                        color="secondary"
                      >
                        <img
                          onContextMenu={(event) => {
                            event.preventDefault();
                            window.open(event?.target?.currentSrc, "_blank");
                          }}
                          className="object-contain object-center w-6 h-6 mx-auto "
                          src={fav}
                          alt="favs logo"
                        />
                      </Badge>
                      <h6 className="block text-xs font-medium text-gray-600 lg:hidden line-clamp-1 ">
                        Favorite stores
                      </h6>
                      <h6 className="hidden text-xs font-medium text-gray-600 lg:block line-clamp-1 ">
                        Favorite stores
                      </h6>
                    </Link>
                    <Link className="link " to={CART}>
                      <Badge
                        badgeContent={numberCartBookmark}
                        color="secondary"
                      >
                        <img
                          onContextMenu={(event) => {
                            event.preventDefault();
                            window.open(event?.target?.currentSrc, "_blank");
                          }}
                          className="object-contain object-center w-6 h-6 mx-auto "
                          src={cart}
                          alt="cart logo"
                        />
                      </Badge>
                      <h6 className="block text-xs font-medium text-gray-600 lg:hidden line-clamp-1 ">
                        {t("cart")}
                      </h6>
                      <h6 className="hidden text-xs font-medium text-gray-600 lg:block line-clamp-1 ">
                        {t("cart")}
                      </h6>
                    </Link>
                    {/* <div
                      className="hidden lg:block cart-txt"
                      style={{ marginLeft: "1.2em" }}
                    >
                      <h3
                        className="line-clamp-1 "
                        style={{ fontWeight: "normal" }}
                      >
                        {formatPrice(totalAmount)}
                      </h3>
                    </div> */}
                  </div>
                </div>
              )}
            </div>
            <Divider
              flexItem
              sx={{ borderWidth: 0.5, borderColor: "#f0f0f0" }}
            />
            <div
              className="header-btm"
              style={{ justifyContent: "flex-start" }}
            >
              {!isStore && (
                <div className="store-logo">
                  <h1>{selectedStore?.name?.en}</h1>
                  <span>{selectedStore?.grade}</span>
                </div>
              )}
              <div className="row-flex" style={{ paddingLeft: 10 }}>
                <DropdownStoreMenu />
                <div
                  className="flex-1 hidden md:px-10 md:block "
                  onClick={() => setOpenS(!openS)}
                >
                  <InputFlatBorder
                    placeholder={"Search"}
                    inputStyle={{ backgroundColor: "#00000005" }}
                    rightIcon={
                      <IoSearchSharp className=" h-6 w-6 text-[#D90429] " />
                    }
                  />
                </div>
                {/* {isStore ? <StoreGrades grades={StoreGrade} /> : <></>} */}
              </div>
            </div>
          </>
        ) : (
          <ClickAwayListener onClickAway={() => handleClickAway()}>
            <div>
              <div
                className="row-flex-space"
                style={{ paddingTop: 7, paddingBottom: 5 }}
              >
                <div className="flex flex-row items-center space-x-3">
                  <Link className="link " to={HOME}>
                    <img
                      onContextMenu={(event) => {
                        event.preventDefault();
                        window.open(event?.target?.currentSrc, "_blank");
                      }}
                      src={logo}
                      className="object-fill object-center w-28 md:w-44 xl:w-40 "
                    />
                  </Link>
                </div>

                <div className="flex-1 hidden max-w-2xl lg:block ">
                  {/* <img
                    onContextMenu={(event) => {
                      event.preventDefault();301115
                      window.open(event?.target?.currentSrc, "_blank");
                    }}
                    src={logo_gif}
                    className="object-fill object-center h-24 w-28 md:w-44 xl:w-full "
                  /> */}
                  <SearchInput filterType={false} />
                </div>
                {isLogged && (
                  /*user?.role === "customer" && */ <div className=" row-flex-space items-start head-icons space-x-6 md:mr-3 mr-1 w-[full] md:space-x-6 md:mx-3 ">
                    <AccountSettingsMenu />
                    <div className="row-flex">
                      <Link className="link " to={CART}>
                        <Badge
                          badgeContent={numberCartBookmark}
                          color="error"
                          sx={{ color: "var(--primary)" }}
                        >
                          <img
                            onContextMenu={(event) => {
                              event.preventDefault();
                              window.open(event?.target?.currentSrc, "_blank");
                            }}
                            className="object-contain object-center w-6 h-6 mx-auto "
                            src={cart}
                            alt="cart logo"
                          />
                        </Badge>
                        <h6 className="sm:block lg:hidden text-[10px] font-medium text-gray-600 line-clamp-1 ">
                          {t("cart")}
                        </h6>
                        <h6 className="hidden text-xs font-medium text-gray-600 lg:block line-clamp-1 ">
                          {t("cart")}
                        </h6>
                      </Link>
                    </div>
                    <Link
                      className="flex flex-col justify-center link"
                      to={FAVORITES}
                    >
                      <Badge
                        badgeContent={items?.length}
                        color="error"
                        sx={{ color: "var(--primary)" }}
                      >
                        <img
                          onContextMenu={(event) => {
                            event.preventDefault();
                            window.open(event?.target?.currentSrc, "_blank");
                          }}
                          className="object-contain object-center w-6 h-6 mx-auto "
                          src={fav}
                          alt="favs logo"
                        />
                      </Badge>
                      <h6 className="block text-xs font-medium text-gray-600 lg:hidden line-clamp-1 ">
                        {t("Items")}
                      </h6>
                      <h6 className="hidden text-xs font-medium text-gray-600 lg:block line-clamp-1 ">
                        {t("Items")}
                      </h6>
                    </Link>
                    <div className="flex flex-col justify-center">
                      <Link className="relative link" to={FAVORITES_STORE}>
                        <Badge
                          badgeContent={bookmarkStoresFilter?.length}
                          color="error"
                          sx={{ color: "var(--primary)" }}
                        >
                          <img
                            onContextMenu={(event) => {
                              event.preventDefault();
                              window.open(event?.target?.currentSrc, "_blank");
                            }}
                            className="object-contain object-center w-6 h-6 mx-auto "
                            src={fav}
                            alt="favs logo"
                          />
                        </Badge>
                      </Link>
                      <h6 className="sm:block lg:hidden text-[10px] font-medium text-gray-600 line-clamp-1 ">
                        {t("stores")}
                      </h6>
                      <h6 className="hidden text-xs font-medium text-gray-600 lg:block line-clamp-1 ">
                        {t("stores")}
                      </h6>
                    </div>
                  </div>
                )}
                {!isLogged && (
                  <div className=" row-flex-space items-start head-icons space-x-2 md:mr-3 mr-1 w-[full] md:space-x-6 md:mx-3">
                    <Stack
                      // resize ? "column" :
                      direction={"row"}
                      alignItems={resize ? "flex-end" : "center"}
                      divider={<Divider orientation="vertical" flexItem />}
                      spacing={resize ? 0.55 : 1}
                      style={{ marginLeft: 10 }}
                    >
                      <Link to={SIGN_IN}>
                        <h6 className=" text-sm font-medium text-gray-600 line-clamp-1 cursor-pointer hover:underline hover:text-[#D90420] ">
                          {t("sign_in")}
                        </h6>
                      </Link>
                      <Link to={SIGN_UP}>
                        <h6 className="text-sm font-medium text-gray-600 line-clamp-1 cursor-pointer hover:underline hover:text-[#D90420] ">
                          {t("sign_up")}
                        </h6>
                      </Link>
                    </Stack>
                    <Link className="link " to={CART}>
                      <Badge
                        badgeContent={bookmarkCartsOff?.length}
                        color="error"
                        sx={{ color: "var(--primary)" }}
                      >
                        <img
                          onContextMenu={(event) => {
                            event.preventDefault();
                            window.open(event?.target?.currentSrc, "_blank");
                          }}
                          className="object-contain object-center w-6 h-6 mx-auto "
                          src={cart}
                          alt="cart logo"
                        />
                      </Badge>
                      <h6 className="sm:block lg:hidden text-[10px] font-medium text-gray-600 line-clamp-1 ">
                        {t("cart")}
                      </h6>
                      <h6 className="hidden text-xs font-medium text-gray-600 lg:block line-clamp-1 ">
                        {t("cart")}
                      </h6>
                    </Link>
                    <Link
                      className="flex flex-col justify-center link"
                      to={FAVORITES}
                    >
                      <Badge
                        badgeContent={bookmarkItemsOff?.length}
                        color="error"
                        sx={{ color: "var(--primary)" }}
                      >
                        <img
                          onContextMenu={(event) => {
                            event.preventDefault();
                            window.open(event?.target?.currentSrc, "_blank");
                          }}
                          className="object-contain object-center w-6 h-6 mx-auto "
                          src={fav}
                          alt="favs logo"
                        />
                      </Badge>
                      <h6 className="block text-xs font-medium text-gray-600 lg:hidden line-clamp-1 ">
                        {t("Items")}
                      </h6>
                      <h6 className="hidden text-xs font-medium text-gray-600 lg:block line-clamp-1 ">
                        {t("Items")}
                      </h6>
                    </Link>
                    <Link
                      className="flex flex-col justify-center link"
                      to={FAVORITES_STORE}
                    >
                      <Badge
                        badgeContent={bookmarkStoresOff?.length}
                        color="error"
                        sx={{ color: "var(--primary)" }}
                      >
                        <img
                          onContextMenu={(event) => {
                            event.preventDefault();
                            window.open(event?.target?.currentSrc, "_blank");
                          }}
                          className="object-contain object-center w-6 h-6 mx-auto "
                          src={fav}
                          alt="favs logo"
                        />
                      </Badge>
                      <h6 className="sm:block lg:hidden text-[10px] font-medium text-gray-600 line-clamp-1 ">
                        {t("stores")}
                      </h6>
                      <h6 className="hidden text-xs font-medium text-gray-600 lg:block line-clamp-1 ">
                        {t("stores")}
                      </h6>
                    </Link>
                  </div>
                )}
              </div>
              <div className="flex-1 block md:px-10 lg:hidden ">
                <SearchInput filterType={false} />
              </div>
              <Divider
                flexItem
                sx={{ borderWidth: 0.5, borderColor: "#f0f0f0" }}
              />
              {/* <DropdownFlatMenu />   */}
              <div className="z-50 hidden cursor-pointer md:block">
                <div className="cat-trigger link bg-[#D90429] bg-opacity-90 ">
                  <motion.div
                    whileTap={{ opacity: 0.75, scale: 0.95 }}
                    className=" w-[100%] p-[12px] md:flex md:flex-row relative hidden"
                    onClick={() => handleClickAway2()}
                  >
                    <HiMenuAlt1 color="white" size={35} />
                    <h3 className="font-bold cat-txt">{t("category")}</h3>
                  </motion.div>
                  {catHover ? ( // console.log('itemFav', itemFav)
                    <AnimatePresence>
                      <DropdownMenu />
                    </AnimatePresence>
                  ) : null}
                </div>
              </div>
            </div>
          </ClickAwayListener>
        )}
      </header>
      <DialogLayout open={openS} setOpen={setOpenS} />
      {/* <SearchPanel open={openS} setOpen={setOpenS} /> */}
    </nav>
  );
}

export default Header