import React, { useState } from 'react'
import { RadioGroup } from '@headlessui/react'
import { dispatch } from '../../app'
import { useSelector } from 'react-redux'
import { GiCommercialAirplane, GiCargoShip } from 'react-icons/gi'
import { handleSeaOrAir } from '../../app/features/itemSlice'
import { CheckCircleIcon } from '@heroicons/react/24/solid'
import { useTranslation } from 'react-i18next'
import { isRealValue } from '../../utilities'
import { handleUpdateRoute } from '../../app/features/bookmarkCardItemsSlice'



const mailingLists = [
  {
    id: 0, title: 'Sea', icon: (checked) => <GiCargoShip
      className={classNames(!checked ? 'text-gray-600' : 'text-[#D90429]', 'h-6 w-6 absolute right-[45%] top-5 text-[#D90429]')}
      aria-hidden="true"
    />, delay: '621 delay'
  },
  {
    id: 1, title: 'Air', icon: (checked) => <GiCommercialAirplane
      className={classNames(!checked ? ' text-gray-600' : 'text-[#D90429]', 'h-6 w-6 absolute right-[45%] top-5')}
      aria-hidden="true"
    />, delay: 'in three month from now'
  },
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const DeliverySelect = ({ isLogged }) => {

  const { t } = useTranslation()

  const { lng } = useSelector(state => state?.app)
  const { item, seaOrAir } = useSelector(state => state.item)
  const {
    itemToUpdate
  } = useSelector((state) => state.bookmarkCard);

  const [selectedMailingLists, setSelectedMailingLists] = useState(
    itemToUpdate?.route === "sea" ? mailingLists[0] : mailingLists[1]
  );
  const handleDeleryMethod = (val) => {
    // console.log("val",val)
    setSelectedMailingLists(val)
    dispatch(handleSeaOrAir(val?.id));
    dispatch(handleUpdateRoute(val?.id));
  }
  const { active } = useSelector(state => state?.item)

  return (
    <RadioGroup value={selectedMailingLists} onChange={handleDeleryMethod}>
      <RadioGroup.Label className="text-base font-medium text-gray-900">
        {t("slct_del_mthd")}
      </RadioGroup.Label>

      <div className="mt-4 grid grid-cols-2 gap-y-6 sm:grid-cols-3 sm:gap-x-4">
        {mailingLists.map((mailingList, i) => (
          <RadioGroup.Option
            key={i}
            value={mailingList}
            // onClick={() => handleDeleryMethod(i)}
            className={({ checked, active }) =>
              classNames(
                checked ? "border-transparent" : "border-gray-300",
                active ? "border-[#D90429] ring-2 ring-[#D90429]" : "",
                "relative flex cursor-pointer rounded-lg border bg-white p-4 shadow-sm focus:outline-none "
              )
            }
          >
            {({ checked, active }) => (
              <>
                <span className="flex flex-1">
                  <span className="flex flex-col">
                    <RadioGroup.Label
                      as="span"
                      className="block text-md font-medium text-gray-900"
                    >
                      {t(`${mailingList.title}`)}
                    </RadioGroup.Label>
                    <RadioGroup.Description
                      as="span"
                      className="mt-1 mx-auto flex items-center text-sm text-gray-500"
                    >
                      {mailingList.icon(checked)}
                    </RadioGroup.Description>
                  </span>
                </span>
                <CheckCircleIcon
                  className={classNames(
                    !checked ? "invisible" : "",
                    "h-5 w-5 text-[#D90429]"
                  )}
                  aria-hidden="true"
                />
                <span
                  className={classNames(
                    active ? "border" : "border-2",
                    checked ? "border-[#D90429]" : "border-transparent",
                    "pointer-events-none absolute -inset-px rounded-lg"
                  )}
                  aria-hidden="true"
                />
              </>
            )}
          </RadioGroup.Option>
        ))}
      </div>
      {isRealValue(item?.info) && isRealValue(item?.info?.delivery) && isLogged && (
        <RadioGroup.Description
          as="div"
          className="mt-2 text-sm font-medium text-gray-700 w-full bg-[#D90429] bg-opacity-80 p-3 rounded-md "
        >
          <span className="mx-auto text-white font-medium">
            {seaOrAir
              ? ` ${item?.info?.delivery?.sea[lng]}`
              : ` ${item?.info?.delivery?.air[lng]}`}
          </span>{" "}
        </RadioGroup.Description>
      )}
    </RadioGroup>
  );
}

export default DeliverySelect;