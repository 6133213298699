import { Grid, Typography } from "@mui/material";
import React from "react";
import StoreItem from "./common/StoreItem";

const StoreListItems = ({
  itemsFilter = [],
  loading,
  attributes = [],
  xs = 12,
}) => {
  return (
    <div class="grid grid-cols-2 justify-items-center md:grid-cols-3 lg:grid-cols-4 2xl:grid-cols-6 my-6">
        {
            itemsFilter?.map((item, i) => (
                <div className=" w-[100%]" >
                    <StoreItem store={item} />
                </div>
            ))
        }
    </div>
  );
};

export default StoreListItems;
