import React from 'react';
import { useSelector } from 'react-redux'
import { useFormatter } from '../../app/hooks/useFormatter'
import { getOrderItemStatus, getOrderOrderStatus, getPaymentStatus, putOperationMethod, putOperationTransaction, all } from '../../app/hooks'
import { useAllOrdersQuery, useAllStripeOrdersByControllerOrSupervisorQuery, useCodeValuesQuery } from '../../app/services/Api'
import { dispatch } from '../..'
import { handleOrdersByStatus } from '../../app/features/ordersSlice'
import OrderItem from './OrderItem'
import SelectMenu from '../../components/common/SelectMenus';
import LoadingComponent from '../../components/LoadingComponent';
import { useTranslation } from 'react-i18next';
import { scroolWindowToTop } from '../../utilities';


const OrdersHistory = () => {

  const { orderFiltered } = useSelector(state => state.orders);
  const { lng } = useSelector(state => state.app);
  const { user } = useSelector(state => state.auth);
  const { display, getOrderOrderStatusData } = useFormatter()
  const filterByStatus = value => dispatch(handleOrdersByStatus(value?.value));

  const { t } = useTranslation();

  const { isLoading } = useAllOrdersQuery(null, { skip: !(user?.role === "customer") });
  const { isLoading: isLoadingSO } = useAllStripeOrdersByControllerOrSupervisorQuery({}, {
    skip: !(user?.role !== "customer")
  });
  const { isLoading: isLoadingM } = useCodeValuesQuery(putOperationMethod, { refetchOnFocus: true })
  const { isLoading: isLoadingTO } = useCodeValuesQuery(putOperationTransaction, { refetchOnFocus: true })
  const { isLoading: isLoadingT } = useCodeValuesQuery(getOrderItemStatus, { refetchOnReconnect: true })
  const { isLoading: isLoadingPS } = useCodeValuesQuery(getPaymentStatus, { refetchOnFocus: true })
  const { isLoading: isLoadingS } = useCodeValuesQuery(getOrderOrderStatus, { refetchOnReconnect: true })

  React.useEffect(() => {
    scroolWindowToTop()
  }, [])

  return (
    <main className="px-4 sm:px-6 lg:px-8">
      <div className="flex flex-col max-w-7xl mx-auto md:flex-row justify-between items-center space-x-5 right-0 flex-1 ">
        <div className="sm:px-2 ">
          <div className="max-w-2xl px-4 mx-auto lg:max-w-7xl lg:px-0">
            <h1 className="text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl">
              {t("ord_his")}
            </h1>
            <p className="mt-2 text-sm text-gray-500">{t("ord_his_desc")}</p>
          </div>
        </div>
        <SelectMenu
          style={{ maxWidth: "160px" }}
          defaultText={t("fill_by")}
          onChange={filterByStatus}
          data={[...all, ...getOrderOrderStatusData]}
          display={display}
        />
      </div>
      <section aria-labelledby="recent-heading" className="w-full mt-16">
        <h2 id="recent-heading" className="sr-only">
          Recent orders
        </h2>
        <div className="mx-auto max-w-7xl ">
          <div className="max-w-2xl mx-auto space-y-8 sm:px-4 lg:max-w-7xl lg:px-0">
            {orderFiltered?.map((order, index) => (
              <OrderItem order={order} key={index} lng={lng} />
            ))}
          </div>
        </div>
      </section>
      <LoadingComponent
        open={
          isLoading ||
          isLoadingSO ||
          isLoadingM ||
          isLoadingTO ||
          isLoadingT ||
          isLoadingPS ||
          isLoadingS
        }
      />
    </main>
  );
}

export default OrdersHistory