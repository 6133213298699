import { Avatar, Button, Divider, Grid, IconButton, InputBase, MenuItem, Paper} from '@mui/material';
import DatePicker from 'react-date-picker';
import React, { useState } from 'react'
import EditIcon from '@mui/icons-material/Edit';
import SearchIcon from '@mui/icons-material/Search';
import ArchiveIcon from '@mui/icons-material/Archive';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import StyledMenu from './StyledMenu';
import { dispatch } from '../../../app';
import { handleByDate, handleItemStatus, handleRouting, handleSearchOrderNumber, handleSearchStoreName } from '../../../app/features/ordersSlice';
import { useSelector } from 'react-redux';
import { useGetItems } from '../../../app/services/Api';
import { diffLocalDate } from '../../../utilities';
import { MagnifyingGlassIcon } from '@heroicons/react/24/outline';
import SelectMenu from '../../common/SelectMenus';

const locationService = [
    { id: 1, name: "All" },
    { id: 2, name: "Silver" },
    { id: 3, name: "Standard", },
    { id: 4, name: "Platinum", },
    { id: 5, name: "Gold" },
]

const routing = [
    { id: 1, name: "All" },
    { id: 2, name: "Plane" },
    { id: 3, name: "Ship", },
]

const product_status = [
    { id: 1, name: "All" },
    { id: 2, name: "not processed" },
    { id: 3, name: "purchased", },
    { id: 4, name: "international transit", },
    { id: 5, name: "destination country", },
    { id: 6, name: "national transit", },
    { id: 7, name: "delivered", },
    { id: 8, name: "cancelled", },
]

const FilterBar = () => {
    // const {data: itemsStatus} =  useGetItemsStatusOfOrdersQuery()
    const { searchByKey , searchByStoreName } = useSelector(state => state.orders)
    const [date, setDate] = useState(new Date());
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [openRoute, setOpenRoute] = React.useState(null);
    const [openStatus, setOpenStatus] = React.useState(null);
    const open = Boolean(anchorEl);
    const routeOpen = Boolean(openRoute);
    const statusOpen = Boolean(openStatus);
    const handleClick = (event) => setAnchorEl(event.currentTarget)
    const handleClick2 = (event) => setOpenRoute(event.currentTarget)
    const handleClick3 = (event) => setOpenStatus(event.currentTarget);
    const handleClose = () => setAnchorEl(null);
    const handleClose2 = () => setOpenRoute(null)
    const searchByItemsStatus = (value) => { setOpenStatus(null);dispatch(handleItemStatus(value)) }
    const searchByOrderNumber = (e) =>  dispatch(handleSearchOrderNumber(e.target.value))
    const searchByStoreNames = (e) => dispatch(handleSearchStoreName(e.target.value))
    const onClickHandleRouting =(value) => dispatch(handleRouting(value))
    const findByDate = (date) =>{ setDate(date); dispatch(handleByDate(diffLocalDate(date)))}
    
    return (
      <Grid container justifyContent={"flex-end"} spacing={0}>
        <Grid
          xs={12}
          sm={12}
          md={6}
          lg={2}
          xl={2}
          my={1}
          item
          container
          justifyContent={"center"}
          alignItems={"center"}
        >
          <div className="w-full max-w-lg lg:max-w-xs ">
            <label
              htmlFor="search"
              className="block text-sm font-medium text-gray-700"
            >
              Search
            </label>
            <div className="relative text-gray-400 focus-within:text-gray-500 mt-1">
              <div
                onClick={() => dispatch(handleSearchOrderNumber(searchByKey))}
                className="cursor-pointer absolute inset-y-0 left-0 flex items-center pl-3"
              >
                <IconButton type="button" aria-label="search">
                  <MagnifyingGlassIcon className="h-5 w-5" aria-hidden="true" />
                </IconButton>
              </div>
              <input
                id="search"
                className="block w-full rounded-md border border-gray-300 bg-white text-base py-2 pl-10 pr-3 leading-5 text-gray-900 placeholder-gray-500 focus:border-[#D90429] focus:placeholder-gray-500 focus:outline-none focus:ring-1 focus:ring-[#D90429] sm:text-sm"
                placeholder="Order number or store name"
                type="search"
                name="search"
                onChange={searchByOrderNumber}
              />
            </div>
          </div>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={6}
          lg={2}
          xl={2}
          my={1}
          container
          justifyContent={"center"}
          alignItems={"center"}
        >
          <SelectMenu
            defaultText="Filter"
            style={{ maxWidth: "200px" }}
            data={locationService}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={6}
          lg={2}
          xl={2}
          my={1}
          container
          justifyContent={"center"}
          alignItems={"center"}
        >
          <SelectMenu
            defaultText="Routes"
            style={{ maxWidth: "200px" }}
            data={routing}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={6}
          lg={2}
          xl={2}
          my={1}
          container
          justifyContent={"center"}
          alignItems={"center"}
        >
          {/* <DatePicker onChange={findByDate} value={date} /> */}
          <div className="w-full max-w-lg lg:max-w-xs ">
            <label
              htmlFor="search"
              className="block text-sm font-medium text-gray-700"
            >
              Date
            </label>
            <div className="relative text-gray-400 focus-within:text-gray-500 mt-1">
              <input
                id="date"
                className="block w-full rounded-md border border-gray-300 bg-white text-base py-2 pl-10 pr-3 leading-5 text-gray-900 placeholder-gray-500 focus:border-[#D90429] focus:placeholder-gray-500 focus:outline-none focus:ring-1 focus:ring-[#D90429] sm:text-sm"
                placeholder="Search date"
                type="date"
                name="date"
                onChange={searchByOrderNumber}
              />
            </div>
          </div>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={6}
          lg={2}
          xl={2}
          my={1}
          container
          justifyContent={"center"}
          alignItems={"center"}
        >
          {/* <Button
                    id="demo-customized-button"
                    variant="text"
                    disableElevation
                    size="large"
                    sx={{ textTransform: 'inherit', width: '90%', height: 46, bgcolor: '#f1f1f120', color: '#000', border: 'none' }}
                    onClick={handleClick3}
                    endIcon={statusOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                >
                    Products Status
                </Button>
                <StyledMenu
                    id="demo-customized-menu"
                    MenuListProps={{ 'aria-labelledby': 'demo-customized-button' }}
                    anchorEl={openStatus}
                    open={statusOpen}
                    onClose={() => setOpenStatus(null)}
                >
                { itemsStatus?.map((status, index) => (<>
                    <MenuItem key={index+"itemStatus"} onClick={() => searchByItemsStatus(status)} disableRipple>{status}</MenuItem>
                    <Divider sx={{ my: 0.2 }} />
                </>))}
                </StyledMenu> */}
          <SelectMenu
            style={{ maxWidth: "200px" }}
            defaultText="Product status"
            data={product_status}
          />
        </Grid>
      </Grid>
    );
}

export default FilterBar