import React from "react";

import { Button, Drawer, IconButton, Input, Spinner, Typography } from "@material-tailwind/react";
import { useTranslation } from "react-i18next";
import { Controller, useForm } from "react-hook-form";
import { classNames, isRealValue } from "../../utilities";
import { useProposeUserSizeMutation } from "../../app/services/Api";
import { useSelector } from "react-redux";
import { handleProposeSize } from "../../app/features/itemSlice";
import { dispatch } from "../../app";
import { useFormatter } from "../../app/hooks/useFormatter";
import { MEDIA_URL } from "../../app/services";
import ceinture from "../../assets/images/measure.png";
import { useLocalDbHook } from "../../app/localDatabase/useLocalDbHook";

const ProposeUserSizeDialog = ({ open, close }, ref) => {
  const { t } = useTranslation();
  const { lng } = useSelector((state) => state.app);
  const { item, proposesSize, proposeSizeTyped } = useSelector((state) => state.item);
  const { getDimensionBodyData, display } = useFormatter();

  const { proposeSizeTypedQuery, proposeSizeTypedUpdate } = useLocalDbHook()
  React.useEffect(() => { proposeSizeTypedQuery(); }, [])

  const getFieldsForm = () => {
    let f = {};
    item?.measures?.map((v) => {
      f[`${v}`] = isRealValue(proposeSizeTyped[`${v}`]) ? proposeSizeTyped[`${v}`]?.toString() : ""
      // isRealValue(proposesSize?.measurement) ? proposesSize?.measurement[`${v}`].toString() : ""
    });
    return f;
  };

  const [proposeUserSize, { isLoading, }] = useProposeUserSizeMutation();
  const [proposeIndex, setProposeIndex] = React.useState(0);
  const [errorPropose, setErrorPropose] = React.useState(!isRealValue(proposesSize?.size));
  const { control, handleSubmit, formState: { errors }, clearErrors, setError, } = useForm({ defaultValues: getFieldsForm() });
  const [textPropose, setTextPropose] = React.useState(isRealValue(proposesSize?.data) ? proposesSize?.data[lng] : `${t("fit_online")}`);

  const saveAndClose = (data) => {
    handleProposeUserSize({
      gender: item?.gender,
      measurement: data,
    });
  };
  const handleProposeUserSize = async (body) => {
    const resp = await proposeUserSize({ body, key: item?.key });
    if (resp?.error) {
      setTextPropose(resp?.error?.data?.translation[lng]);
      setErrorPropose(true);
      dispatch(handleProposeSize({
        data: resp?.error?.data?.translation,
        measurement: body?.measurement,
      }));
    } else {
      setTextPropose(resp?.data?.data?.message[lng]);
      setErrorPropose(false);
      dispatch(handleProposeSize({
        data: resp?.data?.data?.message,
        measurement: body?.measurement,
        size: resp?.data?.data?.size,
      }));
      var toSet = []
      for (const [key, value] of Object.entries(body?.measurement)) {
        toSet = toSet.concat({ key: key, value: value })
      }
      proposeSizeTypedUpdate(toSet)
    }
  };
  const [measures] = React.useState(item?.measures?.map((m) => getDimensionBodyData?.find((d) => d.value === m)));

  return (
    <Drawer
      placement="right"
      open={open}
      onClose={close}
      className="p-4 overflow-y-auto overscroll-contain overflow-x-hidden"
      size={350}
      overlayRef={ref}
      overlay={false}

    >
      <div className="mb-6 flex items-center justify-between">
        <Typography variant="h5" color="blue-gray">
          {t("fit_online")}
        </Typography>
        <IconButton variant="text" color="blue-gray" onClick={close}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={2}
            stroke="currentColor"
            className="h-5 w-5"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </IconButton>
      </div>
      <img
        className="h-72 w-full rounded-lg object-cover object-center"
        src={`${MEDIA_URL}${item?.bodyPictures[proposeIndex]}`}
        alt=""
      />
      <Button
        size="sm"
        variant="outlined"
        className={classNames(
          "w-[95%] m-3 self-center justify-start flex normal-case text-sm font-outfitBold text-black px-2",
          isLoading ? "bg-gray-400" : "bg-inherit", errorPropose ? ' text-gray-900 ' : 'text-green-900 border-green-700'
        )}
      >
        {!isLoading && (
          <img
            className="h-5 w-10 object-cover object-center mr-2"
            src={ceinture}
            alt=""
          />
        )}
        {isLoading && <Spinner color="green" className="mr-2 h-5 w-5" />}
        {textPropose}
      </Button>
      <form
        className='h-[50%] w-full'
        onSubmit={handleSubmit(saveAndClose)}
      >
        <div className="min-h-[90%] max-h-[95%] h-[60%] overflow-y-auto overscroll-contain pb-5 scroll-m-0 scroll-p-0 scroll-mx-0 	">
          {measures?.map((measure, index) => (
            <>
              <div className="mb-1 flex items-center p-1 justify-between relative ">
                <Typography
                  variant="h6"
                  color="blue-gray"
                  className="w-[30%] text-xs"
                >
                  {display(measure)}
                </Typography>
                <div className="relative flex w-[73%] ">
                  <Controller
                    control={control}
                    rules={{
                      required: true,
                      max: measure?.max,
                      min: measure?.min,

                      onChange: (e) => {
                        const v = Number(e.target.value);
                        if (v < measure?.min || v > measure?.max) {
                          if (v < measure?.min)
                            setError(measure?.value, { type: "min" });
                          if (v > measure?.max)
                            setError(measure?.value, { type: "max" });
                        } else clearErrors(measure?.value);
                      },
                      validate: (v) => {
                        if (v < measure?.min || v > measure?.max)
                          setError(measure?.value);
                        else clearErrors(measure?.value);
                        return true;
                      },
                    }}
                    render={({ field: { onChange, value, onBlur } }) => (
                      <Input
                        autoFocus={index === proposeIndex}
                        name={measure?.value}
                        value={value}
                        key={index}
                        variant="static"
                        labelProps={{ className: "hidden" }}
                        className={`block w-[100%] h-full bg-transparent rounded-xl shadow-lg
                         shadow-gray-900/5 outline-none  hover:shadow-sm hover:shadow-blue-gray-300
                           border-x-1 border-y-1 py-1.2 pl-5 pr-14focus:ring-1 focus:ring-[#cac4c5] text-sm sm:leading-6 `
                        }
                        type="number"
                        onMouseEnter={() => setProposeIndex(index)}
                        onFocus={() => setProposeIndex(index)}
                        onChange={onChange}
                        onBlur={onBlur}
                        min={measure?.min}
                        max={measure?.max}
                        error={errors[measure?.value] ? true : false}
                      />
                    )}
                    name={`${measure?.value}`}
                  />
                  <div className="absolute inset-y-0 right-8  top-2">
                    <Typography
                      variant="h6"
                      className="text-gray-700 font-zillaLight"
                    >
                      {measure?.unit}
                    </Typography>
                  </div>
                </div>
              </div>
              {errors[measure?.value] && (
                <Typography
                  variant="small"
                  className="my-0 flex items-center gap-1 font-outfitBold justify-end text-sm text-red-700 text-right px-5"
                >
                  {errors[measure?.value].type === "max" &&
                    `Maximum: ${measure?.max} ${measure?.unit}`}
                  {errors[measure?.value].type === "min" &&
                    `Minimum: ${measure?.min} ${measure?.unit}`}
                </Typography>
              )}
            </>
          ))}
        </div>
        <div className="flex items-center justify-between gap-2 mt-2">
          <Button
            size="sm"
            className="normal-case w-32"
            variant="outlined"
            onClick={close}
          >
            {t("close")}
          </Button>
          <Button
            size="sm"
            className="w-32 normal-case bg-[#d90429]"
            type="submit"
          // onClick={handleSubmit(saveAndClose)}
          >
            {t("OK")}
          </Button>
        </div>
      </form>
    </Drawer>
  );
};

export default React.forwardRef(ProposeUserSizeDialog);





// export function CustomAutocomplete({ options = [], getOptionLabel = v => v, ...props }) {
//   const { onChange, onMouseEnter } = props

//   const [hidden, setHidden] = React.useState(true)
//   const [optioned, setOptioned] = React.useState(options)

//   const open = () => { setHidden(false); onMouseEnter(); }
//   const close = () => setHidden(true)
//   const onSelect = (index) => {
//     onChange(getOptionLabel(optioned[index]))
//     close()
//   }
//   const onchanged = v => {
//     var vs = v.target.value
//     const filterd = options.filter(opt => opt.includes(vs))
//     setOptioned(filterd)
//     onChange(v)
//     if (filterd.length === 0)
//       close()
//     else open()
//   }
//   const onClick = () => {
//     setOptioned(options)
//     open()

//   }

//   // React.useEffect(() => { console.log("options", options); }, [options])

//   return (
//     <div className="relative" onMouseLeave={close}  >
//       <Input {...props} onMouseEnter={open} onFocus={open} onChange={onchanged} onClick={onClick} />
//       {optioned?.length > 0 ? (
//         <div onMouseEnter={open} className={classNames("absolute w-full h-28 pt-5 pb-2 px-2 z-30 overflow-scroll overflow-x-hidden bg-white", hidden ? "hidden" : "block")} >
//           {optioned?.map((option, index) => (
//             <Typography key={index} onClick={() => onSelect(index)} className="hover:bg-blue-gray-300 py-1">
//               {getOptionLabel(option)}
//             </Typography>
//           ))}
//         </div>
//       ) : null}
//     </div>
//   );
// }
