import React from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import CardComponent from '../../components/common/card'
import ResendOtpForm from '../../components/layout/ResendOtpForm'
import { HOME } from '../../constants/routes'
import { scroolWindowToTop } from '../../utilities'

const ResendOtpCode = () => {

    const { isLogged } = useSelector(state => state.auth)
    const navigate = useNavigate()
    if (isLogged) navigate(`${HOME}`)
    React.useEffect(() => {
        scroolWindowToTop()
    }, [0])
    return (
        <div>
            <div className='row-flex logo' >
                <h1 style={{ color: '#D90429', fontSize: 38, fontWeight: 'bolder' }} >YOU</h1>
                <h1 style={{ color: '#303030', fontSize: 38, fontWeight: 'bolder' }} >SHOP</h1>
            </div>
            <CardComponent title={'Verify code'} >
                <h2>Resend Code</h2>
                <p className='auth-text' style={{ width: '75%', marginBottom: 25 }} >
                    Please give information <br /> and receive new code
                </p>
                <ResendOtpForm />
            </CardComponent>
        </div>
    )
}

export default ResendOtpCode