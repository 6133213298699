import React from 'react'
import "./styles.css"
import ChatInput from './ChatInput'
import ChatConversationHead from './ChatConversationHead';
import ChatConversationList from './ChatConversationList';

const ChatConversation = () => {
  const el = document.getElementById("messages");
  // el.scrollTop = el.scrollHeight
  return (
    <div className="flex-1 p:2 sm:p-6 justify-between flex flex-col h-screen">
     <ChatConversationHead />
      <ChatConversationList />
      <ChatInput />
    </div>
  );
}

export default ChatConversation