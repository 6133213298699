import React from 'react'
import './styles.css'

const Input = (props) => {
    const { validate, error, placeholder, type, style, icon } = props
    const [focused, setFocused] = React.useState(false)

    const getBorderColor = () => {
        if (focused) {
            return 'input-wrapper focused'
        }
        else if (error) {
            return 'input-wrapper error'
        }

        else return 'input-wrapper'
    }
    return (
        <div className='wrapper' style={style}  >
            <div className={getBorderColor()}>
                <input
                    className='input'
                    type={type}
                    placeholder={placeholder}
                    onFocus={() => setFocused(!focused)}
                    onBlur={() => setFocused(!focused)}
                    validate={validate}
                    {...props}
                />
                {icon && <div whileTap={{ scale: 0.97 }} className='input-side-icon' >  {icon}  </div>}
            </div>
            {error && <div className='error-input' >{error}</div>}
        </div>
    
    )
}

export default Input