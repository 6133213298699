import { Box, Button, Modal } from '@mui/material'
import React from 'react'

const SimpleModal = (props) => {
    const { children, open, handleClose } = props
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        borderRadius: 8,
        transform: 'translate(-50%, -50%)',
        width: "90%",
        height: "90%",
        bgcolor: 'background.paper',
        // border: '2px solid #000',
        boxShadow: 24,
        p: 4,
        overflowY: "scroll",
    };
    return (
        <Modal
            keepMounted
            open={open}
            onClose={handleClose}
            aria-labelledby="keep-mounted-modal-title"
            aria-describedby="keep-mounted-modal-description"
        >
            <Box sx={style}>
                <Button onClick={()=>handleClose()}>Close</Button>
                {children}
            </Box>
        </Modal>
    );
}

export default SimpleModal




