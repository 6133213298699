import React from "react";
import ProductItem from "../../components/common/ProductItem";
import LoadingProductListItems from "./LoadingProductListItems";

const ProductListItems = ({
  itemsFilter = [],
  loading,
}) => {

  if (loading) {
    return <LoadingProductListItems />
  }

  return (
    <div className="grid grid-cols-2 gap-3 justify-items-center md:grid-cols-3 lg:grid-cols-4 2xl:grid-cols-6 my-6">
        {
            itemsFilter?.map((item, i) => (
                <div className=" w-[100%]" key={i} >
                    <ProductItem item={item} loading = {loading} />
                </div>
            ))
        }
    </div>
  );
};

export default ProductListItems;