import * as React from 'react';
import TablePagination from '@mui/material/TablePagination';
import { useSelector } from 'react-redux';
import { useAllOrdersQuery } from '../../../app/services/Api';
import { useTranslation } from 'react-i18next';
import { useFormatter } from '../../../app/hooks/useFormatter';


export default function TransactionTableUser() {
    const { transactions } = useSelector(state => state.users)
    const { t } = useTranslation()
    const { asDate, display, getOperationMethodData, getOperationStateData, getOperationTransactionData } = useFormatter()
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const handleChangePage = (event, newPage) => setPage(newPage)
    const handleChangeRowsPerPage = (event) => {
        event.preventDefault()
        const v = event.target.value
        setRowsPerPage(v)
        setPage(0)
    }
    useAllOrdersQuery()

    const tabHeaders = [
      t("Date"),
      t("Operation"),
      t("Method"),
      t("State"),
      t("account"),
      t("country"),
      t("dest_acc"),
      t("Amount"),
      t("Note"),
      t("reprted"),
    ];
    return (
        <div className="w-full">
            <div className="flex flex-col mt-8">
                <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                        <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                            <table className="w-[100%] divide-y divide-gray-300">
                                <thead className="bg-gray-50">
                                    <tr>
                                        {
                                            tabHeaders?.map((h, index) => (
                                                <th key={index} scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                                                    {h}
                                                </th>
                                            ))
                                        }
                                    </tr>
                                </thead>
                                <tbody className="bg-white divide-y divide-gray-200">
                                    {transactions?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)?.map((transaction, index) => (
                                        <tr key={index}>
                                            <td className="py-4 pl-4 pr-3 text-sm font-medium text-gray-900 whitespace-nowrap sm:pl-6">
                                                {asDate(transaction?.created)}
                                            </td>
                                            <td className="px-3 py-4 text-sm text-gray-500 text-center whitespace-nowrap">
                                                {display(getOperationTransactionData?.find(o => o.value === transaction?.operation))}
                                            </td>
                                            <td className="px-3 py-4 text-sm text-center text-gray-500 whitespace-nowrap">
                                                {display(getOperationMethodData?.find(o => o.value === transaction?.method))}
                                            </td>
                                            <td className="px-3 py-4 text-sm text-center text-gray-500 whitespace-nowrap">
                                                {display(getOperationStateData?.find(o => o.value === transaction?.state))}
                                            </td>
                                            <td className="px-3 py-4 text-sm text-center text-gray-500 whitespace-nowrap">
                                                {transaction?.account}
                                            </td>
                                            <td className="px-3 py-4 text-sm text-center text-gray-500 whitespace-nowrap">
                                                {transaction?.country}
                                            </td>
                                            <td className="px-3 py-4 text-sm text-center text-gray-500 whitespace-nowrap">
                                                {transaction?.destinationAccount}
                                            </td>
                                            <td className="px-3 py-4 text-sm text-center text-gray-500 whitespace-nowrap">
                                                {transaction?.amountDisplayed}
                                            </td>
                                            <td className="px-3 py-4 text-sm text-center text-gray-500 whitespace-nowrap">
                                                {transaction?.note}
                                            </td>
                                            <td className="px-3 py-4 text-sm text-center text-gray-500 whitespace-nowrap">
                                                {`${transaction?.reported}`}
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <TablePagination
                rowsPerPageOptions={[10, 15, 20, 25, 40, 50, { label: t('All'), value: -1 }]}
                component="div"
                count={transactions?.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </div>

    );
}