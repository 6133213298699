import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    data: [],
    keyword: '',
    error: null,
    levelOne: [],
    levelTwo: [],
    levelThree: [],
    lastLevel: [],
    all: [],
    selectedCategoryItem: "All",
    catHover: false,
    canFetchWithCategories: true,

    level: 1,
    indexLevelOne: 0,
    indexLevelTwo: 0,
    indexLevelThree: null,
    indexLastLevel: null,
    storesCategory: [],
    category: null,
    itemCategory: null,

    obCategory: null,
}

const categoriesSlice = createSlice({
    name: 'categories',
    initialState,
    reducers: {
        handleSearchCategory(state, { payload }) {
            const { keyword, lng } = payload
            state.keyword = keyword
            const categories = state.all
            if (keyword === '') state.data = categories
            else state.data = categories.filter(item => item.name[lng].toLowerCase().includes(keyword.toLowerCase()))
        },
        handleResetState(state) {
            state.levelOne = []
            state.levelTwo = []
            state.levelThree = []
            state.lastLevel = []
            state.all = []
            state.selectedCategoryItem = "All"

            state.indexLevelOne = 0
            state.indexLevelTwo = 0
            state.indexLevelThree = null
            state.indexLastLevel = null
            state.storesCategory = []
            state.category = null
        },
        handleCanFetchWithCategories(state, action) {
            state.canFetchWithCategories = action.payload
        },
        menuOne(state, action) {
            state.levelOne = action.payload;
        },

        menuTwo(state, action) {
            state.levelTwo = action.payload;
        },

        menuThree(state, action) {
            state.levelThree = action.payload;
        },
        selectedCategoryItem(state, action) {
            state.selectedCategoryItem = action.payload
        },
        resetCategoryItem(state, action) {
            state.selectedCategoryItem = null
        },
        handleSetAllCategories(state, {payload}) {

            state.all = payload
            state.data = payload
            var categories = payload;

            let menu = categories?.filter(category => category.parent === "")
            state.levelOne = menu?.sort((a, b) => Number(a?.orderHint) - Number(b?.orderHint));

            let level_2 = state.all?.filter(cat => cat?.parent === menu[0]?.key)?.sort((a, b) => Number(a?.orderHint) - Number(b?.orderHint))

            level_2 = level_2.map((level) => {
                let subMenus = state.all.filter(category => category.parent === level.key)
                subMenus = subMenus.map((sub) => {
                    const subMenus = state.all.filter(category => category.parent === sub.key)?.sort((a, b) => Number(a?.orderHint) - Number(b?.orderHint))
                    return { ...sub, subMenus }
                })
                return { ...level, subMenus }
            })
            state.levelTwo = level_2?.sort((a, b) => Number(a?.orderHint) - Number(b?.orderHint))
            const level_3 = state.all?.filter(cat => cat?.parent === level_2[0]?.key)
            state.levelThree = level_3?.sort((a, b) => Number(a?.orderHint) - Number(b?.orderHint))

            // state.all = payload

            function compare(a, b) {
                if (a?.name?.en < b?.name?.en) {
                    return -1;
                }
                if (a?.name?.en > b?.name?.en) {
                    return 1;
                }
                return 0;
            }

            //  categories = payload;
            let Menu = []
            let SubMenu = []
            let InnerSubMenu = []
            // Add any fetched posts to the array
             menu = categories?.filter(category => category.parent === "")

            menu?.map((item1, i) => {
                Menu.push({ name: item1.name, key: item1.key, orderBy: item1.orderHint, submenu: [] })
                return null
            })

            menu?.map((item1, i) => {
                let level_2 = categories?.filter(category => category.parent === item1.key)
                level_2.map((item2, i) => {
                    let level_3 = categories?.filter(category => category.parent === item2.key)
                    level_2 = level_3?.sort((a, b) => compare(a, b))
                    // SubMenu.push({ title: item2.name, key: item2.key, parent: item2.parent, submenu: level_3.sort(compare) })
                    SubMenu.push({ name: item2.name, key: item2.key, orderBy: item2.orderHint, parent: item2.parent, submenu: [] })
                    return null
                })
                return null
            })

            menu?.map((item1, i) => {
                let level_2 = categories?.filter(category => category.parent === item1.key)
                level_2.map((item2, i) => {
                    let level_3 = categories?.filter(category => category.parent === item2.key)
                    level_3.map((item3, i) => {
                        let level_4 = categories?.filter(category => category.parent === item3.key)
                        level_3 = level_4?.sort((a, b) => compare(a, b))
                        InnerSubMenu.push({ name: item3.name, key: item3.key, orderBy: item3.orderHint, parent: item3.parent, submenu: level_4.sort(compare) })
                        return null
                    })
                    return null
                })
                return null
            })

            SubMenu.map((item2, i) => {
                InnerSubMenu.map((item3, i) => {
                    if (item3.parent === item2.key) {
                        let existingItem = SubMenu.find(item => item.key === item2.key)
                        existingItem?.submenu?.push(item3)
                    }
                    return null
                })
                return null
            })

            menu?.map((item1, i) => {
                SubMenu.map((item2, i) => {
                    if (item2.parent === item1.key) {
                        let existingItem = Menu.find(item => item.key === item1.key)
                        existingItem?.submenu?.push(item2)
                    }
                    return null
                })
                return null
            })

            Menu = Menu?.sort((item1, item2) => {
                return item1.orderBy - item2.orderBy;
            })
            state.data = (Menu)

            // state.all = payload
            // state.data = payload
            // const categories = payload;

            // let menu = categories?.filter(category => category.parent === "")
            // state.levelOne = menu?.sort((a, b) => Number(a?.orderHint) - Number(b?.orderHint));

            // let level_2 = state.all?.filter(cat => cat?.parent === menu[0]?.key)?.sort((a, b) => Number(a?.orderHint) - Number(b?.orderHint))

            // level_2 = level_2.map((level) => {
            //     let subMenus = state.all.filter(category => category.parent === level.key)
            //     subMenus = subMenus.map((sub) => {
            //         const subMenus = state.all.filter(category => category.parent === sub.key)?.sort((a, b) => Number(a?.orderHint) - Number(b?.orderHint))
            //         return { ...sub, subMenus }
            //     })
            //     return { ...level, subMenus }
            // })
            // state.levelTwo = level_2?.sort((a, b) => Number(a?.orderHint) - Number(b?.orderHint))
            // const level_3 = state.all?.filter(cat => cat?.parent === level_2[0]?.key)
            // state.levelThree = level_3?.sort((a, b) => Number(a?.orderHint) - Number(b?.orderHint))
        },
        handleGetAllCategories(state, { payload }) {
            state.all = payload
            state.data = payload
            const categories = payload;

            let menu = categories?.filter(category => category.parent === "")
            state.levelOne = menu?.sort((a, b) => Number(a?.orderHint) - Number(b?.orderHint));

            let level_2 = state.all?.filter(cat => cat?.parent === menu[0]?.key)?.sort((a, b) => Number(a?.orderHint) - Number(b?.orderHint))

            level_2 = level_2.map((level) => {
                let subMenus = state.all.filter(category => category.parent === level.key)
                subMenus = subMenus.map((sub) => {
                    const subMenus = state.all.filter(category => category.parent === sub.key)?.sort((a, b) => Number(a?.orderHint) - Number(b?.orderHint))
                    return { ...sub, subMenus }
                })
                return { ...level, subMenus }
            })
            state.levelTwo = level_2?.sort((a, b) => Number(a?.orderHint) - Number(b?.orderHint))
            const level_3 = state.all?.filter(cat => cat?.parent === level_2[0]?.key)
            state.levelThree = level_3?.sort((a, b) => Number(a?.orderHint) - Number(b?.orderHint))
        },
        handleToLevelTwo(state, action) {
            const { index, category } = action.payload
            let level_2 = state.all?.filter(cat => cat?.parent === category?.key)

            level_2 = level_2.map((level) => {
                let subMenus = state.all.filter(category => category.parent === level.key)
                subMenus = subMenus.map((sub) => {
                    const subMenus = state.all.filter(category => category.parent === sub.key)?.sort((a, b) => Number(a?.orderHint) - Number(b?.orderHint))
                    return { ...sub, subMenus }
                })
                return { ...level, subMenus }
            })
            state.levelTwo = level_2?.sort((a, b) => Number(a?.orderHint) - Number(b?.orderHint))
            state.levelThree = []
            state.indexLevelThree = null
            state.lastLevel = []
            state.indexLevelTwo = 0
            state.indexLevelOne = index
        },
        handleToLevelThree(state, action) {
            const { index, category } = action.payload
            state.levelThree = category?.subMenus
            state.lastLevel = state.levelThree[0].subMenus
            state.indexLevelTwo = index
            state.indexLevelThree = 0
            state.indexLastLevel = null
            state.category = state.levelThree[0]?.key
        },
        handleToLastLevel(state, action) {
            const { index, category } = action.payload
            state.category = category?.key
            state.indexLevelThree = index
            state.indexLastLevel = null
            state.lastLevel = category?.subMenus
        },
        handleProductByCategory(state, action) {
            const { index } = action.payload
            state.indexLastLevel = index
        },
        handleBackLevel(state) {
            state.indexLevelThree = null
            state.indexLastLevel = null
            state.lastLevel = []
        },
        handleStoreCategory(state, { payload }) {
            state.storesCategory = payload
        },
        handleCategory(state, { payload }) {
            state.obCategory = payload
        }, 
        
        handleLevel(state, { payload }) {
            state.level = payload
        },

        handleCatHover(state, { payload }) {
            state.catHover = payload
        },

        handleItemCategory(state, { payload }) {
            // console.log("payload", payload)
            state.itemCategory = state.all.find(item => item?.key === payload)
        },

    },
})

export const {
    handleResetState,
    handleCatHover,
    handleSearchCategory,
    menuOne,
    menuTwo,
    menuThree,
    selectedCategoryItem,
    handleSetAllCategories,
    handleGetAllCategories,
    handleCanFetchWithCategories,
    handleBackLevel,
    handleCategory,
    handleProductByCategory,
    handleStoreCategory,
    handleToLastLevel,
    handleToLevelThree,
    handleToLevelTwo, 
    handleLevel,
    handleItemCategory,
    resetCategoryItem
} = categoriesSlice.actions

export default categoriesSlice