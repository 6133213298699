import { motion } from 'framer-motion'
import React from 'react'
import './styles.css'

const Button = (props) => {
  const { title, style, onClick } = props
  return (
    <motion.button 
      whileTap={{ scale: 0.92, opacity: 0.86 }}
      whileHover = {{ scale: 1.035 }}
      className='button' 
      type='submit'
      style={style} 
      onClick = {onClick} 
    >
     { title } 
    </motion.button>
  )
}

export default Button