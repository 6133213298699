import React from "react";
import "./styles.css";
// import { useNavigate } from "react-router-dom";
// import { PRODUCT } from "../../../constants/routes";
// import { dispatch } from "../../..";
// import { Api } from "../../../app/services/Api";/
import { useTranslation } from "react-i18next";

const ItemsListField = ({
  children,
  title,
  id = undefined,
  style = {},
  backgroundColor = "white",
  onClick
}) => {
  // const navigate = useNavigate();
  const { t } = useTranslation();

  // const handlerItemsByCategory = () => {
  //   if (id !== undefined) {
  //     navigate(`${PRODUCT}/${id}?page=1`);
  //     dispatch({
  //       type: "categories/selectedCategoryItem",
  //     });
  //     dispatch(Api.endpoints.getItemsByCategory.initiate({ key: id }));
  //   }
  // };

  const RenderChild = () => {
    return (
      <div className="mx-auto min-h-[140px] flex flex-col items-center justify-centrer">
        <h2 style={backgroundColor !== "white" ? { color: "white" } : { color: '#000' }} className="text-2xl text-center py-10">{t("empty_cart")}</h2>
      </div>
    );

  };

  return (
    <fieldset
      style={{ backgroundColor }}
      className="border border-gray-400 rounded-md shadow-md shadow-[#00000010] my-5 px-2 "
    >
      <legend
        onClick={onClick}
        className="bg-[#D90429] cursor-pointer px-3 py-3 mx-auto relative top-5 mb-3 w-full text-center rounded-lg "
      >
        <span className="text-white flex-1 w-full text-xl sm:text-2xl md:text-4xl lg:text-5xl font-semibold">
          {title}
        </span>
      </legend>
      {children ?? <RenderChild />}
    </fieldset>
  );
};

export default ItemsListField;
