import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import {
    CART, FORGOT_PASSWORD, HOME, PAYMENT, PRODUCT_DETAILS, SIGN_IN, SIGN_IN_SIGN_UP,
    SIGN_UP, FAVORITES, PRODUCT, STORE, CONFIRM_PASSWORD_RESET,
    FAVORITES_STORE, CHECKOUT_FILTER, DASHBOARD, VERIFY_OTP_CODE, RESEND_OTP_CODE,
    PROFILE, DASHBOARD_WALLET, ORDER_DETAILS, SECURE_CHECKOUT, ACKNOWLEDGEMENT, DASHBOARD_STATEMENT, SEARCH,
    CONDITIONS, LIVRAISON, COMMANDER, POLICY, PAYMENTS, DASHBOARD_CHATS, DASHBOARD_HISTTRANSACTION, DASHBOARD_CUSTOMERS, DASHBOARD_SETTINGS, DASHBOARD_SHIPPING_ADDRESS, DASHBOARD_BANKS, DASHBOARD_ORDERS_HISTORY, DASHBOARD_LOCATIONS, DASHBOARD_SHIPMENTS_TRANSITS, DASHBOARD_SHIPMENTS_PURCHASES, DASHBOARD_DELIVERY_AREA, DASHBOARD_HOLDS
} from "../constants/routes";
import CartCheckout from "../pages/CartCheckout";
import ForgetPassword from "../pages/ForgotPassword";
import Home from "../pages/Home";
import ProceedToPayment from "../pages/ProceedToPayment";
import ProductDetails from "../pages/ProductDetails";
import SignIn from "../pages/SignIn";
import SignInSignUp from "../pages/SignInSignUp";
import SignUp from "../pages/SignUp";
import Products from "../pages/Products";
import Favorites from "../pages/Favoris";
import StoreProducts from "../pages/StoreProducts";
import IndexPage from "../pages";
import ConfirmForgetPassword from "../pages/ConfirmForgotPassword";
import FavoritesStores from "../pages/FavorisStore";
import SecureCheckout from "../pages/CheckoutSecurely";
import CheckoutFilter from "../pages/CheckoutFilter";
import Chats from "../pages/Chats";
import Dashboard from "../pages/Dashboard";
import TransactionHistory from "../pages/TransactionHistory";
import DashboardIndex from "../pages/DashboardIndex";
import VerifyOtpCode from "../pages/VerifyOtpCode";
import ResendOtpCode from "../pages/ResendOtpCode";
import Customer from "../pages/Customer";
import ShippingAddresses from "../pages/ShippingAddresses";
import OrdersHistory from "../pages/OrdersHistory";
import Banks from "../pages/Banks";
import Locations from "../pages/Locations";
import Transits from "../pages/Transits";
import Purchases from "../pages/Purchases";
import UserProfile from "../pages/UserProfile";
import NotFound from "../pages/NotFound";
import Settings from "../pages/Settings";
import OrderDetails from "../pages/OrderDetail";
import DeliveryArea from "../pages/DeliveryArea";
import StoreHome from "../pages/StoreHome";
import Acknowledgement from "../pages/Acknowledgement";
import StoreIndex from "../pages/StoreHome/StoreIndex";
import Statements from "../pages/Statements";
import Search from "../pages/Search";
import ConditionGeneral from "../pages/TermSections/ConditionGeneral";
import PolitiqueConfidentialite from "../pages/TermSections/PolitiqueConfidentialite";
import LivraisonCollecte from "../pages/TermSections/LivraisonCollecte";
import ModalitePaiement from "../pages/TermSections/ModalitePaiement";
import CommentCommander from "../pages/TermSections/CommentCommander";
import CategorySizeUpdate from "../pages/CategorieSizeUpdate";
import HoldsPage from "../pages/CustomerPages/HoldsPage";

const RouterNav = () => (
    <Router>
        <Routes >
            <Route path={HOME} element={<IndexPage />} >
                <Route path="" element={<Home />} index exact />
                <Route path={CART} element={<CartCheckout />} />
                <Route path={`${SEARCH}`} element={<Search />} />

                <Route path={SECURE_CHECKOUT} element={<SecureCheckout />} />
                <Route path={CHECKOUT_FILTER} element={<CheckoutFilter />} />
                <Route path={PAYMENT} element={<ProceedToPayment />} />
                <Route path={SIGN_UP} element={<SignUp />} />
                <Route path={SIGN_IN} element={<SignIn />} />
                <Route path={`${ORDER_DETAILS}:key`} element={<OrderDetails />} />
                <Route path={FORGOT_PASSWORD} element={<ForgetPassword />} />
                <Route path={ACKNOWLEDGEMENT} element={<Acknowledgement />} />
                <Route path={`${CONFIRM_PASSWORD_RESET}/:userId/:token`} element={<ConfirmForgetPassword />} />
                <Route path={SIGN_IN_SIGN_UP} element={<SignInSignUp />} />
                <Route path={`${PRODUCT_DETAILS}/:key`} element={<ProductDetails />} />
                <Route path={`${PRODUCT}/:key`} element={<Products />} />
                <Route path={PROFILE} element={<UserProfile />} />
                <Route path={CONDITIONS} element={<ConditionGeneral />} />
                <Route path={POLICY} element={<PolitiqueConfidentialite />} />
                <Route path={PAYMENTS} element={<ModalitePaiement />} />
                <Route path={LIVRAISON} element={<LivraisonCollecte />} />
                <Route path={COMMANDER} element={<CommentCommander />} />

                <Route path={`${STORE}/:key`} element={<StoreIndex />} >
                    <Route path={""} element={<StoreHome />} index />
                    <Route path={`items`} element={<StoreProducts />} />
                </Route>
                <Route path={`${STORE}/:key/sizes/:category`} element={<CategorySizeUpdate />} />

                <Route path={FAVORITES} element={<Favorites />} />
                <Route path={FAVORITES_STORE} element={<FavoritesStores />} />

                <Route path={VERIFY_OTP_CODE} element={<VerifyOtpCode />} />
                <Route path={RESEND_OTP_CODE} element={<ResendOtpCode />} />

                <Route path={DASHBOARD} element={<DashboardIndex />} >
                    <Route path='' element={<UserProfile />} index />
                    <Route path={DASHBOARD_WALLET} element={<Dashboard />} />
                    <Route path={DASHBOARD_CHATS} element={<Chats />} />
                    <Route path={DASHBOARD_HISTTRANSACTION} element={<TransactionHistory />} />
                    <Route path={DASHBOARD_CUSTOMERS} element={<Customer />} />
                    <Route path={DASHBOARD_SETTINGS} element={<Settings />} />
                    <Route path={DASHBOARD_SHIPPING_ADDRESS} element={<ShippingAddresses />} />
                    <Route path={DASHBOARD_HOLDS} element={<HoldsPage />} />
                    <Route path={DASHBOARD_ORDERS_HISTORY} element={<OrdersHistory />} />
                    <Route path={DASHBOARD_BANKS} element={<Banks />} />
                    <Route path={DASHBOARD_STATEMENT} element={<Statements />} />
                    <Route path={DASHBOARD_LOCATIONS} element={<Locations />} />
                    <Route path={DASHBOARD_SHIPMENTS_TRANSITS} element={<Transits />} />
                    <Route path={DASHBOARD_SHIPMENTS_PURCHASES} element={<Purchases />} />
                    <Route path={DASHBOARD_DELIVERY_AREA} element={<DeliveryArea />} />
                </Route>
            </Route>
            <Route
                path="*"
                element={
                    <NotFound />
                }
            />
        </Routes>
    </Router>
)

export default RouterNav 