import React, { useEffect } from 'react'
import "../styles.css"
import { handleHeaderType } from '../../../app/features/authSlice'
import { dispatch } from '../../..'
import { scroolWindowToTop } from '../../../utilities'

const LivraisonCollecte = () => {
  useEffect(() => {
    dispatch(handleHeaderType({ headerType: "none", isStore: false, header: false, checkout: true }))
    scroolWindowToTop()

  }, [])


  return (
    <div className="page-content">
      <h1 className="heading-1">Livraison et collecte</h1>

      <section>
        <p style={{ marginBottom: "10px" }}>Selon le cas :</p>

        <ul>
          <li
            style={{
              marginBottom: "10px",
              listStyleType: "disc",
              marginLeft: 45,
            }}
          >
            Nous effectuons nos livraisons uniquement au Cameroun dans les zones
            géographiques que nous avons défini à condition pour le client de
            s’y trouver et d’avoir activée la localisation au moment de
            l’enregistrement de son adresse. Nous livrons exclusivement à
            l'adresse indiquée par le client.
          </li>
          <li
            style={{
              marginBottom: "10px",
              listStyleType: "disc",
              marginLeft: 45,
            }}
          >
            Nous effectuons nos expéditions uniquement au Cameroun dans nos
            agences et auprès de nos partenaires logistiques à condition pour le
            client de sélectionner le lieu de collecte des produits de sa
            commande. Les expéditions sont collectées exclusivement au lieu
            indiqué par le client.
          </li>
        </ul>

        <p style={{ marginBottom: "10px" }}>
          Les délais de livraison sont donnés à titre indicatif. Ils peuvent
          évoluer en raison de différents facteurs comme la disponibilité des
          vols et navires, les délais en douane Export et Import, la
          disponibilité de notre transporteur local.
        </p>
      </section>
    </div>
  );
}

export default LivraisonCollecte