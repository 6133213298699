import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    fulfillments: [],
}

const billSlice = createSlice({
    name: 'bills',
    initialState,
    reducers: {
        
    },
})

export const {
 

} = billSlice.actions

export default billSlice