import { styled } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import React from 'react'
import { purple } from '@mui/material/colors';

const CustomButton = styled(LoadingButton)(({ theme }) => ({
    color: theme.palette.getContrastText(purple[500]),
    backgroundColor: theme.palette.primary.main,
    borderRadius: 8,
    height: 45,
    paddingLeft: 15,
    paddingRight: 15,
    '&:hover': {
        backgroundColor: theme.palette.primary.light,
    },
}));



const YButton = ({ loading, onClick, title, type }) => {
    return (
        <CustomButton type={type? type :"text"} loading={loading} onClick={onClick} sx={{ textTransform: 'none', fontWeight: '600', width: '100%', my: 1.3 }}  >
            {title}
        </CustomButton>
    )
}

export default YButton;