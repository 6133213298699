import React, { useEffect } from 'react'
import "../styles.css"
import { handleHeaderType } from '../../../app/features/authSlice'
import { dispatch } from '../../..'
import { scroolWindowToTop } from '../../../utilities'

const CommentCommander = () => {
  useEffect(() => {
    dispatch(handleHeaderType({ headerType: "none", isStore: false, header: false, checkout: true }))
    scroolWindowToTop()

  }, [])


  return (
    <div className="page-content">
      <h1 className="heading-1">Comment commander ?</h1>

      <section>
        <p style={{ marginBottom: "10px" }}>
          Vous avez la possibilité de commander nos produits directement sur
          notre site internet ou par l’intermédiaire de notre application
          Android.
        </p>
        <p style={{ marginBottom: "10px" }}>
          Pour passer une commande sur notre site, choisissez vos produits et
          ajoutez-les au panier. Sélectionner les produits que vous souhaiteriez
          commander votre panier.
        </p>

        <p style={{ marginBottom: "10px" }}>Selon le cas :</p>

        <ul>
          <li
            style={{
              marginBottom: "10px",
              listStyleType: "disc",
              marginLeft: 45,
            }}
          >
            Si vous possédez déjà un compte client sur notre site, veuillez-vous
            identifier.
          </li>
          <li
            style={{
              marginBottom: "10px",
              listStyleType: "disc",
              marginLeft: 45,
            }}
          >
            Si vous ne possédez pas de compte client sur notre site, veuillez en
            créer un.
          </li>
        </ul>

        <p style={{ marginBottom: "10px" }}>Selon le cas :</p>

        <ul>
          <li
            style={{
              marginBottom: "10px",
              listStyleType: "disc",
              marginLeft: 45,
            }}
          >
            Choisissez un lieu de collecte dans la case « Lieux de collecte »
            pour valider l’endroit où vous iriez récupérer les produits de votre
            commande une fois disponible muni d’une pièce d’identité.
          </li>
          <li
            style={{
              marginBottom: "10px",
              listStyleType: "disc",
              marginLeft: 45,
            }}
          >
            Si le montant donne droit à la livraison gratuite dans une zone
            couverte, veuillez sélectionner dans la case « Adresses de livraison
            » l’une de vos adresses pour valider l’endroit où vous sera livré
            les produits de votre commande. Vous pouvez toujours y ajouter une
            adresse à condition d’activer la localisation de votre téléphone ou
            ordinateur afin que nous puissions vérifier que vous êtes dans une
            zone de livraison.
          </li>
        </ul>

        <p style={{ marginBottom: "10px" }}>Selon le cas :</p>

        <ul>
          <li
            style={{
              marginBottom: "10px",
              listStyleType: "disc",
              marginLeft: 45,
            }}
          >
            Sélectionner votre mode de paiement dans le cadre des options de
            paiements.
          </li>
          <li
            style={{
              marginBottom: "10px",
              listStyleType: "disc",
              marginLeft: 45,
            }}
          >
            Cochez la case d’acception et lecture des conditions générales de
            vente.
          </li>
        </ul>

        <p style={{ marginBottom: "10px" }}>Validez votre paiement.</p>

        <p style={{ marginBottom: "10px" }}>
          Vous recevrez un message de confirmation de votre commande sur
          l'adresse électronique ou au numéro de téléphone que vous avez
          indiquée lors de la création de votre compte client.
        </p>

        <p style={{ marginBottom: "10px" }}>
          Le transfert de propriété du produit n'aura lieu qu'au paiement
          complet de votre commande.
        </p>
      </section>

    </div>
  );
}

export default CommentCommander