import { Box } from '@mui/material';
import React from 'react';
import TabHeader from '../../components/checkoutfilter/TabHeader';
import FilterBar from '../../components/checkoutfilter/FilterBar';
import ItemTable from '../../components/checkoutfilter/ItemTable';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { SIGN_IN } from '../../constants/routes';
import { dispatch } from '../../app';
import { handleHeaderType, handleTitleMobilePage } from '../../app/features/authSlice';
import { scroolWindowToTop } from '../../utilities';

const CheckoutFilter = () => {
    const navigate = useNavigate()
    const { isLogged } = useSelector(state => state.auth)
    if (!isLogged) navigate(`${SIGN_IN}`)
    dispatch(handleHeaderType({ headerType: "none", isStore: false, header: true, checkout: false }))
    dispatch(handleTitleMobilePage("Orders History's"))

    React.useEffect(() => {
        scroolWindowToTop()
    }, [0])

    return (
        <div className="w-full lg:container mx-auto " >
            <Box margin={2.5} py={2} >
                <TabHeader />
                <FilterBar />
                <ItemTable />
            </Box>
        </div>
    )
}

export default CheckoutFilter