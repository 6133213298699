import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import PersonIcon from '@mui/icons-material/Person';
import SearchIcon from '@mui/icons-material/Search';
import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined';
import { Box, Divider, IconButton, Stack, Typography } from '@mui/material';
import FilterSelect from '../../../components/common/FilterSelect';
import { useTranslation } from 'react-i18next';

const people = [
  { date: '01 Jul 2022', desc: 'Order 123', type: 'payment', amount: '125 000' },
  { date: '04 Jul 2022', desc: 'Bank deposit', type: 'payment', amount: '125 000' },
  { date: '05 Jul 2022', desc: 'Bank deposit', type: 'payment', amount: '125 000' },
  { date: '10 Aug 2022', desc: 'Bonanjo', type: 'withdrawal', amount: '125 000' },
]

export default function TransactionTableEmployee({ filters }) {

  const { t } = useTranslation()
 
return (
    <div className="w-full">
        <div className="flex flex-row justify-end " >
           { filters &&  <FilterSelect />}
        </div>
      <div className="mt-8 flex flex-col">
        <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
              <table className="w-[100%] divide-y divide-gray-300">
                <thead className="bg-gray-50">
                  <tr>
                    <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                      {t("date")}
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      {t("desc")}
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Type
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      {t("Amount")} (FCFA)
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                  {people.map((person) => (
                    <tr key={person.desc}>
                      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                        {person.date}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{person.desc}</td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{person.type}</td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{person.amount}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      {/* <TablePagination
          rowsPerPageOptions={[5, 8, 10]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
      /> */}
    </div>
        
    );
}