import React from 'react'
import InputFlat from './InputFlat'
import { useTranslation } from 'react-i18next'
import FilterSelect from './common/FilterSelect'
import { useSelector } from 'react-redux'
import { Controller, useForm } from 'react-hook-form'
import { useGetCountryOfUserQuery, useGetSubDivisionsQuery } from '../app/services/Api'
import { handleTerritories } from '../app/features/territorySlice'
import { isRealValue } from '../utilities'
import LoadingComponent from './LoadingComponent'
import { dispatch } from '../app'
import LoadingButton from './common/LoadingButton'
import useMutationApi from '../app/hooks/useMutationApi'
import usePermission from '../app/hooks/usePermission'
import { FormControl, FormControlLabel, FormGroup, Switch } from '@mui/material'

const EditAddressForm = ({ closeModal, address }) => {
  const { lng } = useSelector(state => state.app);
  const { coordinates, getCurrentPosition } = usePermission()
  const { t } = useTranslation()
  const {
    deleteShippingAddressByUser, isLoadingDeleteAddress,
    updateShippingAddressByUser, isLoadingUpdateAddress
  } = useMutationApi()
  const display = v => v?.value[lng]
  const { division, parent, division1, division2, division3, } = useSelector(state => state.territories);
  const { handleSubmit, control, setValue, formState: { /*errors*/ } } = useForm({
    defaultValues:
    {
      address: address?.address,
      adminDivision3: address?.adminDivision3,
      deliveryArea: `${address?.deliveryArea}`,
      defaultAddress: false,//`${address?.defaultAddress}`,
      position: false,
    }
  });
  const handleTerritory = (parent, division) => dispatch(handleTerritories({ parent, division }))
  const handleSelectCountry = () => handleTerritory(country?._id, "division1")
  const selectDivision1 = (territory) => handleTerritory(territory?._id, "division2")
  const selectDivision2 = (territory) => handleTerritory(territory?._id, "division3")
  const selectDivision3 = (territory, onChange) => { setValue('address', territory?.value[lng]); onChange(territory?._id); }

  const { data: country, isFetching: isLoadingC } = useGetCountryOfUserQuery()
  const { isFetching: isLoadingD } = useGetSubDivisionsQuery({ parent, division }, { skip: !(parent !== null) })


  React.useEffect(() => {
    getCurrentPosition()
  }, [])
  const deleteAddress = () => deleteShippingAddressByUser(address?._id).then(() => closeModal())
  const handleUpdateAddress = (data) => {
    const { position, ...rest } = data
    var body = {}
    if (position === true)
      body = {
        ...rest, ...{
          gps: {
            lat: coordinates?.latitude,
            lon: coordinates?.longitude,
          },
        }
      };
    else body = rest
    updateShippingAddressByUser(address?._id, body).then(() => closeModal())
  }

  return (
    <form
      className="max-w-2xl overflow-y-scroll"
      onSubmit={handleSubmit(handleUpdateAddress)}
    >
      <FilterSelect
        defaultText={country?.name[lng] || ""}
        data={isRealValue(country) ? [country] : []}
        display={display}
        onChange={handleSelectCountry}
        width={96}
        key={1}
      />
      <FilterSelect
        data={division1}
        display={display}
        onChange={selectDivision1}
        defaultText={division1[0]?.name[lng] || ""}
        width={96}
        key={2}
      />
      <FilterSelect
        data={division2}
        display={display}
        onChange={selectDivision2}
        defaultText={division2[0]?.name[lng] || ""}
        width={96}
        key={3}
      />
      <Controller control={control} rules={{ required: true }}
        render={({ field: { onChange } }) => (
          <FilterSelect
            data={division3}
            display={display}
            onChange={v => selectDivision3(v, onChange)}
            defaultText={division3[0]?.name[lng] || ""}
            width={96}
            key={5}
          />
        )}
        name="adminDivision3"
      />
      <Controller control={control} rules={{ required: true }}
        render={({ field: { onChange, value } }) => (
          <InputFlat label={"Address"} placeholder={'address'} value={value} onChange={onChange} />
        )}
        name="address"
      />

      <FormControl component="fieldset">
        <Controller control={control} rules={{ required: false }}
          render={({ field: { onChange, value } }) => (
            <FormGroup aria-label="defaultAddress" row>
              <FormControlLabel
                value="defaultAddress"
                control={<Switch
                  color="primary"
                  size="small"
                  checked={value}
                  onChange={onChange}
                  disabled={true}
                />}
                label="Default address"
                labelPlacement="start"
              />
            </FormGroup>
          )}
          name="defaultAddress"
        />
        <Controller control={control} rules={{ required: false }}
          render={({ field: { onChange, value } }) => (
            <FormGroup aria-label="deliveryArea" row>
              <FormControlLabel
                value="deliveryArea"
                control={<Switch
                  color="primary"
                  size="small"
                  checked={value}
                  onChange={onChange}
                />}
                label="Delivery area "
                labelPlacement="start"
              />
            </FormGroup>
          )}
          name="deliveryArea"
        />
        <Controller control={control} rules={{ required: false }}
          render={({ field: { onChange, value } }) => (
            <FormGroup aria-label="position" row>
              <FormControlLabel
                value="position"
                control={<Switch
                  color="primary"
                  size="small"
                  checked={value}
                  onChange={onChange}
                />}
                label="Change Geo-position with actual position"
                labelPlacement="start"
              />
            </FormGroup>
          )}
          name="position"
        />
      </FormControl>
      <LoadingButton
        isLoading={isLoadingUpdateAddress}
        title={t('Update address')}
        disabled={isLoadingUpdateAddress}
        type={"submit"}
      />
      <LoadingButton
        isLoading={isLoadingDeleteAddress}
        title={t('Delete address')}
        disabled={isLoadingDeleteAddress}
        onClick={deleteAddress}
      />
      <LoadingComponent open={isLoadingC || isLoadingD} />
    </form>
  )
}

export default EditAddressForm