import { Visibility, VisibilityOff } from '@mui/icons-material'
import { CircularProgress } from '@mui/material'
import React from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { SIGN_UP, FORGOT_PASSWORD, VERIFY_OTP_CODE } from '../../../constants/routes'
import './styles.css'
import 'react-phone-number-input/style.css'
import ButtonFlat from '../../ButtonFlat'
import InputFlat from '../../InputFlat'
import useMutationApi from '../../../app/hooks/useMutationApi'
import { useTranslation } from 'react-i18next'
import CountryPicker from '../../common/CountryPicker'
import { isRealValue } from '../../../utilities'


const LoginForm = () => {
  const { t } = useTranslation()
  const { loginByUser, isLoadingLogin, errorLogin } = useMutationApi()
  const { countries } = useSelector(state => state.auth)

  const [showPassword, setShowPassword] = React.useState(false)
  const toogleShowPassword = () => setShowPassword(!showPassword)
  const { handleSubmit, control, formState: { errors } } = useForm({ defaultValues: { password: '', userEmailPhone: '', callingCode: isRealValue(countries) ? countries[0]?.callingCode : "" } })
  const handleSignIn = (data) => {
    loginByUser(data)
  }

  return (
    <form
      className='reg-form'
      style={{ width: '100%' }}
      onSubmit={handleSubmit(handleSignIn)}
    >
      {errorLogin && errorLogin?.status === "FETCH_ERROR" && <span style={{ color: "red" }}>{t("connexion_error")}</span>}
      <Controller control={control} rules={{ required: true, }}
        render={({ field: { onChange } }) => (
          <CountryPicker
            onSelect={onChange}
            errorProps={errors?.callingCode}
          />
        )}
        name="callingCode"
      />
      <Controller
        control={control}
        rules={{
          required: true,
        }}
        render={({ field: { onChange, value } }) => (
          <InputFlat
            error={(errors.userEmailPhone || (errorLogin && errorLogin.data?.error !== true)) ? (errorLogin?.data?.error || `email is ${errors?.userEmailPhone?.type}`) : (null)}
            type='text'
            placeholder={t('username_mail_phone')}
            style={{ fontSize: 16 }}
            value={value}
            onChange={onChange}
          />
        )}
        name="userEmailPhone"
      />
      <Controller
        control={control}
        rules={{
          required: true,
        }}
        render={({ field: { onChange, value } }) => (
          <InputFlat
            error={(errors.password || (errorLogin && errorLogin.data?.error === true && errorLogin?.data?.message))
              ? (errorLogin?.data?.message || `password is ${errors?.password?.type}`) : (null)}
            type={showPassword ? 'text' : 'password'}
            placeholder={t('password')}
            value={value}
            onChange={onChange}
            style={{ fontSize: 16 }}
            rightIcon={showPassword ? <Visibility fontSize='small' onClick={toogleShowPassword} /> : <VisibilityOff fontSize='small' onClick={toogleShowPassword} />}
          />
        )}
        name="password"
      />
      <div className='row-flex-space' style={{ alignSelf: 'center', width: '95%', marginBottom: 15 }} >
        <div className='auth-text forgot-pass' ><Link className='link' to={FORGOT_PASSWORD} >{t("forgot_pass")}</Link></div>
      </div>
      <div className='auth-text forgot-pass' ><Link className='link' to={VERIFY_OTP_CODE} >{t("verify_acc")}</Link></div>
      {
        isLoadingLogin ? <CircularProgress sx={{ color: "#D90429" }} /> :
          <ButtonFlat type={'submit'} title={t("sign_in")} />
      }
      <div className='.row-flex' style={{ alignSelf: 'center' }} >
        <span>{t("not_a_member_yet")}</span> <Link className='link' to={SIGN_UP} ><span className='signin-link' >{t("sign_up")}</span></Link>
      </div>
    </form>
  )
}

export default LoginForm