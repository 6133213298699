import React, { useEffect } from 'react'
import "../styles.css"
import { handleHeaderType } from '../../../app/features/authSlice'
import { dispatch } from '../../..'
import { scroolWindowToTop } from '../../../utilities'

const ModalitePaiement = () => {
  useEffect(() => {
    dispatch(handleHeaderType({ headerType: "none", isStore: false, header: false, checkout: true }))
    scroolWindowToTop()

  }, [])


  return (
    <div className="page-content">
      <h1 className="heading-1">Modalités de paiement</h1>

      <section>
        <p style={{ marginBottom: "10px" }}>
          Plusieurs modes de paiement sont acceptés. En tant que client, vous
          avez la possibilité de payer par :
        </p>

        <ul>
          <li
            style={{
              marginBottom: "10px",
              listStyleType: "disc",
              marginLeft: 45,
            }}
          >
            Cash dans l’une de nos agences ;
          </li>
          <li
            style={{
              marginBottom: "10px",
              listStyleType: "disc",
              marginLeft: 45,
            }}
          >
            Cash à l’un de nos livreurs lors de la réception de votre commande ;
          </li>
          <li
            style={{
              marginBottom: "10px",
              listStyleType: "disc",
              marginLeft: 45,
            }}
          >
            Dépôt bancaire dans l’un de nos comptes bancaires indiqués au moment
            de la commande ;
          </li>
          <li
            style={{
              marginBottom: "10px",
              listStyleType: "disc",
              marginLeft: 45,
            }}
          >
            Virement bancaire dans l’un de nos comptes bancaires indiqués au
            moment de la commande ;
          </li>
          <li
            style={{
              marginBottom: "10px",
              listStyleType: "disc",
              marginLeft: 45,
            }}
          >
            Carte de crédit sur notre site internet ou application.
          </li>
        </ul>

        <p style={{ marginBottom: "10px" }}>
          Les délais de livraison sont donnés à titre indicatif. Ils peuvent
          évoluer en raison de différents facteurs comme la disponibilité des
          vols et navires, les délais en douane Export et Import, la
          disponibilité de notre transporteur local.
        </p>

        <p style={{ marginBottom: "10px" }}>Selon le cas :</p>

        <ul>
          <li
            style={{
              marginBottom: "10px",
              listStyleType: "disc",
              marginLeft: 45,
            }}
          >
            Nous acceptons les paiements par carte [Visa / MasterCard/ Union
            Pay/ American Express]. Lors de votre paiement en ligne, indiquez le
            titulaire de la carte bancaire, le numéro de la carte bancaire, sa
            date d'expiration et les 3 chiffres inscrits au dos de celle-ci. Le
            paiement en ligne est sécurisé.
          </li>
          <li
            style={{
              marginBottom: "10px",
              listStyleType: "disc",
              marginLeft: 45,
            }}
          >
            Nous acceptons les preuves de paiement par virement ou dépôt
            bancaire dont vous ' pouvez nous envoyer une photo sur notre
            plateforme de conversation intégrée à votre compte ou au numéro +237
            690 86 87 91. Nous disposons d’un délai de 24 heures pour confirmer
            votre paiement par dépôt bancaire et 48 heures par virement
            bancaire.
          </li>
        </ul>

        <p style={{ marginBottom: "10px" }}>Éventuellement :</p>

        <ul>
          <li
            style={{
              marginBottom: "10px",
              listStyleType: "disc",
              marginLeft: 45,
            }}
          >
            Dès la confirmation de votre paiement, nous procédons dans un délai
            de 24 heures à la réservation des produits de votre commande par un
            achat direct au près du fournisseur en Chine.
          </li>
          <li
            style={{
              marginBottom: "10px",
              listStyleType: "disc",
              marginLeft: 45,
            }}
          >
            En l'absence de la confirmation de votre paiement dans un délai de
            48 heures, votre commande sera supprimée de notre plateforme. Vous
            devriez procéder à la création d’une nouvelle commande si vous
            souhaitez acquérir les mêmes produits.
          </li>
        </ul>

        <p style={{ marginBottom: "10px" }}>
          Nous pouvons exiger un paiement intégral de la commande. Dans le cas
          d’un paiement partiel, le solde devra être intégralement payé avant
          réception de votre commande soit :
        </p>

        <ul>
          <li
            style={{
              marginBottom: "10px",
              listStyleType: "disc",
              marginLeft: 45,
            }}
          >
            Avant expédition pour collecte chez l’un de nos partenaires
            logistiques ;
          </li>
          <li
            style={{
              marginBottom: "10px",
              listStyleType: "disc",
              marginLeft: 45,
            }}
          >
            Avant collecte dans l’une de nos agences ;
          </li>
          <li
            style={{
              marginBottom: "10px",
              listStyleType: "disc",
              marginLeft: 45,
            }}
          >
            Avant la livraison au près d’un de nos livreurs.
          </li>
        </ul>
      </section>
    </div>
  );
}

export default ModalitePaiement