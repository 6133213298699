import { createApi } from "@reduxjs/toolkit/query/react";
import { ENDPOINTS, METHODS, chatUrl, EXPIRED_DATA } from ".";
import {
  deleteAuth,
  getId,
  getObjectAsSearchParameter,
  isRealValue,
  providesList,
  setAuthUser,
  setValidity,
} from "../../utilities";
import { baseQueryInterpolatorAuth } from "./interceptor";

import { handleAddresses, handleViewAddress } from "../features/addressSlice";
import {
  handleAuthentication,
  handleLocalization,
  handleContacts,
  handleInfosAccount,
  handleMessages,
  handleRooms,
  handleCountries,
  loggedOut,
} from "../features/authSlice";
import { handleBrand, handleItem } from "../features/itemSlice";
import {
  handleBrandsObject,
  handleGetAllItemsCategory,
  handleRecommendations,
} from "../features/itemsSlice";
import {
  handleCategory,
  handleSetAllCategories,
  handleStoreCategory,
} from "../features/categoriesSlice";
import { handleDeliveriesAreas } from "../features/deliveryAreaSlice";
import {
  handleBookTrends,
  handleCartTrends,
  handleOrderTrends,
} from "../features/homeSlice";
import {
  handleAllLocations,
  handleAllLocationsBanks,
  handleAllLocationsBranchs,
} from "../features/locationSlice";
import {
  handleOrderData,
  handleOrderViews,
  handleRequireDeposit,
} from "../features/ordersSlice";
import { handleCommands } from "../features/purchaseShipmentSlice";
import {
  handleClosedPurchases,
  handleOpenedPurchases,
} from "../features/purchaseShipmentSlice";
import { handleMenuStore, handleStore } from "../features/storeSlice";
import { handleGetAllStores } from "../features/storesSlice";
import {
  handleCardDetails,
  handleCardsList,
  handleClientStripe,
  handleEuroRelated,
} from "../features/StripeSlice";
import { handleCountryUser, handleDivisions } from "../features/territorySlice";
import {
  handleClosedTransits,
  handleOpenedTransits,
} from "../features/transitShipmentSlice";
import { handleItemsCartBookMarker } from "../features/bookmarkCardItemsSlice";
import { handleFavoriteItems } from "../features/bookmarkFavoriteItemsSlice";
import {
  handleBookmarkStore,
  handleDataStoreFavorites,
} from "../features/bookmarkStoresSlice";
import {
  handleCustomerView,
  handleHolds,
  handlePayments,
  handleStatements,
  handleTransactions,
  handleUsers,
} from "../features/usersSlice";
import { handleCodeValues, handleItemsSearch } from "../features/AppSlice";
import { handleBanks } from "../features/bankSlice";
import { dispatch } from "../..";
import {
  handleClosedFulfillments,
  handleOpenFulfillments,
} from "../features/fulfillmentSlice";

const tagTypes = [
  "Addresses",
  "banks",
  "OpenBill",
  "CloseBill",
  "CourierCodes",
  "LocalAgents",
  "Brand",
  "AllCategories",
  "DeliveryArea",
  "OpenFulfillment",
  "CloseFulfillment",
  "bookTrends",
  "cartTrends",
  "orderTrends",
  "Unpublished",
  "OrderViews",
  "Item",
  "HOME_ITEMS",
  "ItemViews",
  "ItemViewsByCategory",
  "itemsSearch",
  "locations",
  "locationsBanks",
  "locationsBranchs",
  "Order",
  "ListBookmarkCardKeys",
  "FavoritesCardItems",
  "OpenPurchase",
  "ClosePurchase",
  "Store",
  "Client",
  "Card",
  "Transit",
  "CloseTransit",
  "OpenTransit",
  "Transactions",
  "FavoritesCardItems",
  "ListBookmarkFavorite",
  "FavoritesStoreItems",
  "Informations",
  "Rooms",
  "Contacts",
  "Messages",
  "UserGradeValues",
  "UsersByStaff",
  "UsersByAdmin",
  "Statements",
  "ListSizesStores",
];

export const Api = createApi({
  reducerPath: "Api",
  baseQuery: baseQueryInterpolatorAuth,
  tagTypes,
  endpoints: (builder) => ({
    /**
     *
     *  USER, CUSTOMER ACCESS *************************************************************
     *
     */
    //ADDRESSES SERVICE
    createAddressByUser: builder.mutation({
      query: (body) => ({
        url: `${ENDPOINTS?.ADDRESS}/${getId()}`,
        body,
        method: METHODS.POST,
      }),
      refetchOnReconnect: true,
      keepUnusedDataFor: 0,
      extraOptions: { maxRetries: 0 },
      invalidatesTags: ["Addresses"],
    }),
    updateAddressByUser: builder.mutation({
      query: ({ id, body }) => ({
        url: `${ENDPOINTS?.ADDRESS}/${getId()}/${id}`,
        body,
        method: METHODS.PUT,
      }),
      refetchOnReconnect: true,
      keepUnusedDataFor: 0,
      extraOptions: { maxRetries: 0 },
      invalidatesTags: ["Addresses"],
    }),
    getAddressesByUser: builder.query({
      query: () => ({
        url: `${ENDPOINTS?.ADDRESS}/${getId()}`,
      }),
      refetchOnReconnect: true,
      keepUnusedDataFor: 0,
      providesTags: ["Addresses"],
      transformResponse: (response) => {
        const datas = response?.data?.map((data) => {
          return { ...data, ...{ label: data?.address, value: data?._id } };
        });
        dispatch(handleAddresses(datas));
        return datas;
      },
    }),
    deleteAddressByUser: builder.mutation({
      query: (id) => ({
        url: `${ENDPOINTS?.ADDRESS}/${getId()}/${id}`,
        method: METHODS.DELETE,
      }),
      refetchOnReconnect: true,
      keepUnusedDataFor: 0,
      extraOptions: { maxRetries: 0 },
      invalidatesTags: ["Addresses"],
    }),
    getDeliverableAddressByUser: builder.query({
      query: (amount) => ({
        url: `${ENDPOINTS?.DELIVERABLE_ADDRESS}/${getId()}?amount=${amount}`,
      }),
      transformResponse: (response) => {
        const datas = response?.data?.map((data) => {
          return { ...data, ...{ label: data?.address, value: data?._id } };
        });
        dispatch(handleAddresses(datas));
        return datas;
      },
    }),
    getDeliverableAddressByIdForUser: builder.query({
      query: (addressId) => ({
        url: `${ENDPOINTS?.ADDRESS}/${getId()}/${addressId}`,
      }),
      refetchOnReconnect: true,
      keepUnusedDataFor: 0,
      transformResponse: (response) => {
        dispatch(handleViewAddress(response?.data));
        return response?.data;
      },
    }),
    //ORDERS SERVICE
    allOrders: builder.query({
      query: () => ({
        url: `${ENDPOINTS.ORDERS}/user/${getId()}`,
      }),
      transformResponse: (resp) => {
        dispatch(handleOrderData(resp?.data));
        return resp;
      },
      providesTags: ["Order"],
      refetchOnReconnect: true,
      keepUnusedDataFor: 0,
    }),
    allOrdersViews: builder.query({
      query: () => ({
        url: `${ENDPOINTS?.ORDER_VIEWS}/${getId()}`,
      }),
      transformResponse: (resp) => {
        dispatch(handleOrderData(resp?.data));
        return resp;
      },
      providesTags: ["OrderViews"],
      refetchOnReconnect: true,
      keepUnusedDataFor: 0,
    }),
    //controller et suppervisor
    allStripeOrdersByControllerOrSupervisor: builder.query({
      query: () => ({
        url: `stripeorders/${getId()}`,
      }),
      transformResponse: (resp) => {
        dispatch(handleOrderData(resp?.data));
        return resp;
      },
      providesTags: ["OrderViews"],
      refetchOnReconnect: true,
      keepUnusedDataFor: 0,
    }),
    createOrder: builder.mutation({
      query: (body) => ({
        url: `${ENDPOINTS?.ORDERS}/${getId()}`,
        method: `${METHODS?.POST}`,
        body,
      }),
      extraOptions: { maxRetries: 0 },
      invalidatesTags: [
        { type: "ListBookmarkCardKeys" },
        { type: "FavoritesCardItems" },
      ],
    }),
    getRequiredDeposit: builder.query({
      query: (params) => ({
        url: `${ENDPOINTS?.ORDERS}/${getId()}${getObjectAsSearchParameter(
          params
        )}`,
      }),
      transformResponse: (response) => {
        dispatch(handleRequireDeposit(response.data?.requiredDeposit));
        return response;
      },
      keepUnusedDataFor: 0,
    }),
    // ALL USERS SAUF ADMIN
    getOrderById: builder.query({
      query: (id) => ({
        url: `${ENDPOINTS?.ORDERS}/${getId()}/${id}`,
      }),
      refetchOnReconnect: true,
      transformResponse: (response) => {
        dispatch(handleOrderViews([response?.data]));
        return response;
      },
    }),
    getOrderByRef: builder.query({
      query: (ref) => ({
        url: `${ENDPOINTS?.ORDERS}/ref/${getId()}/${ref}`,
      }),
      refetchOnReconnect: true,
    }),
    getOrderByKey: builder.query({
      query: (ref) => ({
        url: `${ENDPOINTS?.ORDERS}/key/${getId()}/${ref}`,
      }),
      refetchOnReconnect: true,
    }),
    getOrderviewById: builder.query({
      query: (id) => ({
        url: `${ENDPOINTS?.ORDER_VIEWS}/${getId()}/${id}`,
      }),
      refetchOnReconnect: true,
      transformResponse: (response) => {
        dispatch(handleOrderViews(response));
        return response;
      },
    }),
    getOrderviewByKey: builder.query({
      query: (id) => ({
        url: `${ENDPOINTS?.ORDER_VIEWS}/${getId()}/${id}`,
      }),
      refetchOnReconnect: true,
      transformResponse: (response) => {
        dispatch(handleOrderViews(response));
        return response;
      },
    }),
    // only by OPERATOR, CONTROLLER SUPERVISOR, LOGISTICIAN-FIELD
    getOrdersUserByStaffUser: builder.query({
      query: (user) => ({
        url: `${ENDPOINTS?.ORDERS}/staff/${getId()}${getObjectAsSearchParameter(
          { user }
        )}`,
      }),
      transformResponse: (response) => {
        return response.data;
      },
      keepUnusedDataFor: 0,
    }),
    getOrdersviewUserByStaffUser: builder.query({
      query: (user) => ({
        url: `${ENDPOINTS?.ORDER_VIEWS
          }/staff/${getId()}${getObjectAsSearchParameter({ user })}`,
      }),
      transformResponse: (response) => {
        return response.data;
      },
      keepUnusedDataFor: 0,
    }),
    // only by SUPERVISOR
    updateItemsOrderById: builder.mutation({
      query: ({ items, id }) => ({
        url: `${ENDPOINTS?.ORDERS}/${getId()}/${id}`,
        method: `${METHODS?.PUT}`,
        body: { items },
      }),
      extraOptions: { maxRetries: 0 },
    }),
    //ONLY BY CONTROLLER SUPERVISOR
    updateStatusOrderById: builder.mutation({
      query: ({ body, id }) => ({
        url: `${ENDPOINTS?.ORDERS}/${getId()}/${id}`,
        method: `${METHODS?.PUT}`,
        body,
      }),
      extraOptions: { maxRetries: 0 },
    }),
    //ONLY BY logistician foreign
    getCommandedArticles: builder.query({
      query: () => ({
        url: `purchaseorderviews/${getId()}`, //TODO  orderviews/purchase
      }),
      refetchOnReconnect: true,
      transformResponse: (response) => {
        dispatch(handleCommands(response));
        return response;
      },
      transformErrorResponse: (error) => {
        console.error("error", error);
        return error;
      },
    }),
    getSourceItemsByForeignLog: builder.query({
      query: (itemKey) => ({
        url: `items/source/${getId()}?itemKey=${itemKey}`,
      }),
      refetchOnReconnect: true,
    }),
    //STRIPE SERVICES FOR PAYMENT
    viewClientStripe: builder.query({
      query: () => ({
        url: `stripe/user/${getId()}`,
      }),
      refetchOnReconnect: true,
      keepUnusedDataFor: 0,
      transformResponse: (response) => {
        dispatch(handleClientStripe(response?.data));
        return response;
      },
    }),
    addCreditCardClient: builder.mutation({
      query: () => ({
        url: `stripe/user/${getId()}`,
        method: METHODS?.POST,
      }),
      extraOptions: { maxRetries: 0 },
      invalidatesTags: ["Client"],
    }),
    deleteCreditCardClient: builder.mutation({
      query: () => ({
        url: `stripe/user/${getId()}`,
        method: METHODS?.DELETE,
      }),
      extraOptions: { maxRetries: 0 },
      invalidatesTags: ["Client"],
    }),
    viewCards: builder.query({
      query: () => ({
        url: `stripe/card/${getId()}`,
      }),
      providesTags: ["Card"],
      refetchOnReconnect: true,
      keepUnusedDataFor: 0,
      transformResponse: (response) => {
        dispatch(handleCardsList(response?.data?.data));
        return response;
      },
    }),
    viewCard: builder.query({
      query: (id) => ({
        url: `stripe/card/${getId()}/${id}`,
      }),
      transformResponse: (response) => {
        dispatch(handleCardDetails(response?.data));
        return response;
      },
    }),
    euroRelated: builder.query({
      query: ({ card, amount }) => ({
        url: `stripe/eurorelated/${getId()}/${card}?amount=${amount}`,
      }),
      transformResponse: (response) => {
        dispatch(handleEuroRelated(response?.data));
        return response;
      },
    }),
    paymentForexOrderByUserWithCard: builder.mutation({
      query: ({ orderId, cardId, body }) => ({
        url: `stripe/paymentforex/${getId()}/${orderId}?cardId=${cardId}`,
        method: METHODS?.POST,
        body,
      }),
      extraOptions: { maxRetries: 0 },
      invalidatesTags: ["Order"],
    }),
    addCards: builder.mutation({
      query: (body) => ({
        url: `stripe/card/${getId()}`,
        method: METHODS?.POST,
        body,
      }),
      extraOptions: { maxRetries: 0 },
      invalidatesTags: ["Card"],
    }),
    deleteCard: builder.mutation({
      query: (id) => ({
        url: `stripe/card/${getId()}/${id}`,
        method: METHODS?.DELETE,
      }),
      extraOptions: { maxRetries: 0 },
      invalidatesTags: ["Card"],
    }),
    createIntentionPayment: builder.mutation({
      query: ({ body, orderId, cardId }) => ({
        url: `stripe/payment/${getId()}/${orderId}${getObjectAsSearchParameter({
          cardId,
        })}`,
        method: METHODS?.POST,
        body,
      }),
      extraOptions: { maxRetries: 0 },
    }),
    confirmIntentionPayment: builder.mutation({
      query: ({ paymentIntent }) => ({
        url: `stripe/confirm/${getId()}`,
        method: METHODS?.POST,
        body: { paymentIntent },
      }),
      extraOptions: { maxRetries: 0 },
    }),

    capturePaymentIntentByController: builder.mutation({
      query: (body) => ({
        url: `stripe/capture/${getId()}`,
        method: METHODS?.POST,
        body,
      }),
      extraOptions: { maxRetries: 0 },
      invalidatesTags: ["OrderViews", "Order"],
    }),

    //END OF USER, CUSTOMER ACCESS *****************************************************

    /**
     *
     *
     *  ALL OTHERS USERS ELSE CUSTOMER AND USER ROLE ************************************
     *
     *
     **/
    //ADDRESSES SERVICE
    getAllAddressOfUserByEmployee: builder.query({
      query: (params) => ({
        url: `${ENDPOINTS?.ADDRESS}/${getId()}`,
        params: getObjectAsSearchParameter(params),
      }),
      refetchOnReconnect: true,
      keepUnusedDataFor: 0,
    }),
    //END OF ALL OTHERS USERS ELSE CUSTOMER AND USER ROLE *******************************

    /**
     *
     *
     *  ADMIN ACCESS *******************************************************************
     *
     *
     **/
    //BANK SERVICE
    createBankAccount: builder.mutation({
      query: (body) => ({
        url: `${ENDPOINTS?.BANKS}/${getId()}`,
        method: METHODS.POST,
        body,
      }),
      refetchOnReconnect: true,
      keepUnusedDataFor: 0,
      extraOptions: { maxRetries: 0 },
      invalidatesTags: ["banks"],
    }),
    deleteAccountBankById: builder.mutation({
      query: (id) => ({
        url: `${ENDPOINTS?.BANKS}/${getId()}/${id}`,
        method: METHODS.DELETE,
      }),
      refetchOnReconnect: true,
      keepUnusedDataFor: 0,
      invalidatesTags: ["banks"],
    }),
    //.........CONTROLLER, SUPERVISOR ACCESS LAST 3 ALSO
    allAccountBank: builder.query({
      query: () => ({
        url: `${ENDPOINTS?.BANKS}/${getId()}`,
      }),
      refetchOnReconnect: true,
      keepUnusedDataFor: 0,
      providesTags: ["banks"],
      transformResponse: (response) => {
        dispatch(handleBanks(response?.data));
      },
    }),
    accountBankById: builder.query({
      query: (id) => ({
        url: `${ENDPOINTS?.BANKS}/${getId()}/${id}`,
      }),
      refetchOnReconnect: true,
      keepUnusedDataFor: 0,
    }),
    accountBankByCode: builder.query({
      query: (id) => ({
        url: `${ENDPOINTS?.BANKS}/code/${getId()}/${id}`,
      }),
      refetchOnReconnect: true,
      keepUnusedDataFor: 0,
    }),
    //BRAND SERVICE
    updateBrand: builder.mutation({
      query: ({ brandKey, body }) => ({
        url: `${ENDPOINTS?.BRANDS
          }/admin/${getId()}/${getObjectAsSearchParameter({ brandKey })}`,
        body,
        method: METHODS.PUT,
      }),
      invalidatesTags: ["Brand"],
    }),
    //CATEGORIES SERVICE
    updateCategory: builder.mutation({
      query: ({ categoryKey, body }) => ({
        url: `${ENDPOINTS?.CATEGORIES
          }/admin/${getId()}/${getObjectAsSearchParameter({ categoryKey })}`,
        body,
        method: METHODS.PUT,
      }),
      invalidatesTags: ["AllCategories"],
    }),
    //DELIVERY-AREAS SERVICE
    createDeliveryAreaByAdmin: builder.mutation({
      query: (body) => ({
        url: `deliveryareas/${getId()}`,
        body,
        method: METHODS?.POST,
      }),
      keepUnusedDataFor: 0,
      extraOptions: { maxRetries: 0 },
      invalidatesTags: ["DeliveryArea"],
    }),
    allDeliveryAreaByAdmin: builder.query({
      query: () => ({
        url: `deliveryareas/${getId()}`,
      }),
      refetchOnReconnect: true,
      transformResponse: (response) => {
        dispatch(handleDeliveriesAreas(response?.data));
        return response;
      },
      providesTags: ["DeliveryArea"],
    }),
    getDeliveryAreaByIdByAdmin: builder.query({
      query: (id) => ({
        url: `deliveryareas/${getId()}/${id}`,
      }),
      keepUnusedDataFor: 0,
      // providesTags: ["DeliveryArea"]
    }),
    //ITEMS SERVICE
    updateItem: builder.mutation({
      query: ({ itemKey, body }) => ({
        url: `items/admin/${getId()}/${getObjectAsSearchParameter({
          itemKey,
        })}`,
        body,
        method: METHODS.PUT,
      }),
      invalidatesTags: ["Item"],
    }),
    pubOrUnpublishItem: builder.mutation({
      query: (body) => ({
        url: `items/publish/${getId()}`,
        body,
        method: METHODS.PUT,
      }),
      invalidatesTags: ["Item"],
    }),
    unpublishedItemByCategory: builder.query({
      query: (categoryKey) => ({
        url: `items/unpublished/${getId()}/${getObjectAsSearchParameter({
          categoryKey,
        })}`,
      }),
      providesTags: ["Unpublished"],
    }),
    //LOCATIONS SERVICES
    createLocationByAdmin: builder.mutation({
      query: (body) => ({
        url: `locations/${getId()}`,
        body,
        method: METHODS?.POST,
      }),
      keepUnusedDataFor: 0,
      extraOptions: { maxRetries: 0 },
    }),
    deleteLocationByAdmin: builder.mutation({
      query: (locationId) => ({
        url: `locations/${getId()}/${locationId}`,
        method: METHODS?.DELETE,
      }),
      keepUnusedDataFor: 0,
      extraOptions: { maxRetries: 0 },
    }),
    //STORES SERVICES
    updateStore: builder.mutation({
      query: ({ storeKey, body }) => ({
        url: `${ENDPOINTS?.STORES
          }/admin/${getId()}/${getObjectAsSearchParameter({ storeKey })}`,
        body,
        method: METHODS.PUT,
      }),
      invalidatesTags: ["Store"],
    }),
    putSizeOfStoreByCategoryLevel3ByLogForeign: builder.mutation({
      query: ({ storeKey, categoryKey, body }) => ({
        url: `stores/size/${getId()}/${storeKey}?category=${categoryKey}`,
        method: METHODS?.PUT,
        body,
      }),
      keepUnusedDataFor: 0,
      invalidatesTags: ["ListSizesStores"],
      extraOptions: { maxRetries: 1 },
    }),
    getListSizeOfStoreByCategoryLevel3ByLogForeign: builder.query({
      query: ({ storeKey, categoryKey }) => ({
        url: `stores/size/${getId()}/${storeKey}?category=${categoryKey}`,
      }),
      keepUnusedDataFor: 0,
      providesTags: ["ListSizesStores"],
    }),

    //END OF  ADMIN ACCESS ***************************************************************

    /**
     *
     *
     *  LOGISTICIAN-LOCAL ACCESS *******************************************************************
     *
     *
     **/
    //BILL SERVICE
    createBill: builder.mutation({
      query: (body) => ({
        url: `${ENDPOINTS?.BIllS}/${getId()}`,
        body,
        method: METHODS.POST,
      }),
      transformResponse: (result) => {
        return result;
      },
      invalidateTags: ["OpenBill", "CloseBill"],
    }),
    updateStatusBill: builder.mutation({
      query: ({ id, body }) => ({
        url: `${ENDPOINTS?.BIllS}/${getId()}/${id}`,
        body,
        method: METHODS.PUT,
      }),
      transformResponse: (result) => {
        return result;
      },
      invalidateTags: ["OpenBill", "CloseBill"],
    }),
    //........ OPERATOR, SUPERVISOR, LOGISTICIAN-FIELD LAST 2
    billById: builder.query({
      query: (id) => ({
        url: `${ENDPOINTS?.BIllS}/${getId()}/${id}`,
      }),
    }),
    billByKey: builder.query({
      query: (id) => ({
        url: `${ENDPOINTS?.BIllS}/key/${getId()}/${id}`,
      }),
    }),
    //.......... SUPERVISOR ALSO
    openBill: builder.query({
      query: () => ({
        url: `openbills/${getId()}`,
      }),
      transformResponse: (result) => {
        return result.data;
      },
      invalidateTags: ["OpenBill"],
    }),
    closeBill: builder.query({
      query: () => ({
        url: `closedbills/${getId()}`,
      }),
      transformResponse: (result) => {
        return result.data;
      },
      providesTags: ["CloseBill"],
    }),
    //FULFILLMENT SERVICE
    createFulfillment: builder.mutation({
      query: () => ({
        url: `${ENDPOINTS?.FULFILLMENtS}/${getId()}`,
        // body,
        method: METHODS.POST,
      }),
      transformResponse: (result) => {
        return result.data;
      },
      invalidateTags: ["OpenFulfillment", "CloseFulfillment"],
    }),
    updateStatusFulfillment: builder.mutation({
      query: ({ id, body }) => ({
        url: `${ENDPOINTS?.FULFILLMENtS}/${getId()}/${id}`,
        body,
        method: METHODS.PUT,
      }),
      transformResponse: (result) => {
        return result.data;
      },
      invalidateTags: ["OpenFulfillment", "CloseFulfillment"],
    }),
    fulfillmentById: builder.query({
      query: (id) => ({
        url: `${ENDPOINTS?.FULFILLMENtS}/${getId()}/${id}`,
      }),
    }),
    fulfillmentByKey: builder.query({
      query: (id) => ({
        url: `${ENDPOINTS?.FULFILLMENtS}/key/${getId()}/${id}`,
      }),
    }),
    openFulfillmentShipments: builder.query({
      query: () => ({
        url: `openfulfillments/${getId()}`,
      }),
      transformResponse: (result) => {
        dispatch(handleOpenFulfillments(result?.data || []));
        return result;
      },
      providesTags: ["OpenFulfillment"],
    }),
    closeFulfillmentShipments: builder.query({
      query: () => ({
        url: `closedfulfillments/${getId()}`,
      }),
      transformResponse: (result) => {
        dispatch(handleClosedFulfillments(result?.data || []));
        return result;
      },
      providesTags: ["CloseFulfillment"],
    }),
    updateFulfillmentLine: builder.mutation({
      query: ({ id, body }) => ({
        url: `fulfillmentline/${getId()}/${id}`,
        body,
        method: METHODS.PUT,
      }),
    }),
    updateOrderlineByLogLoc: builder.mutation({
      query: ({ orderId, body }) => ({
        url: `orderline/${getId()}/${orderId}`,
        body,
        method: METHODS.PUT,
      }),
    }),
    //..... ONLY BY OPERATOR OR LOGISTICIAN-FIELD
    receiveFulfillment: builder.mutation({
      query: ({ id, body }) => ({
        url: `fulfillmentreceive/${getId()}/${id}`,
        body,
        method: METHODS.PUT,
      }),
    }),
    deliverFulfillment: builder.mutation({
      query: ({ id, body }) => ({
        url: `fulfillmentdeliver/${getId()}/${id}`,
        body,
        method: METHODS.PUT,
      }),
    }),
    //END OF   LOGISTICIAN-LOCAL ACCESS ***************************************************************

    /**
     *
     *
     *   LOGISTICIAN-FOREIGN ACCESS *******************************************************************
     *
     *
     **/
    //PURCHASE SERVICE
    createPurchase: builder.mutation({
      query: (body) => ({
        url: `${ENDPOINTS?.PURCHASE}/${getId()}`,
        body,
        method: METHODS.POST,
      }),
      transformResponse: (result) => {
        return result;
      },
      invalidateTags: ["OpenPurchase", "ClosePurchase"],
    }),
    updatePurchase: builder.mutation({
      query: ({ id, body }) => ({
        url: `${ENDPOINTS?.PURCHASE}/${getId()}/${id}`,
        body,
        method: METHODS.PUT,
      }),
      transformResponse: (result) => {
        return result;
      },
      invalidateTags: ["OpenPurchase", "ClosePurchase"],
    }),
    purchaseById: builder.query({
      query: (id) => ({
        url: `${ENDPOINTS?.PURCHASE}/${getId()}/${id}`,
      }),
    }),
    deletePurchaseById: builder.mutation({
      query: (id) => ({
        url: `${ENDPOINTS?.PURCHASE}/${getId()}/${id}`,
        method: METHODS.DELETE,
      }),
      invalidateTags: ["OpenPurchase", "ClosePurchase"],
    }),
    addTrackingToAllPurchases: builder.query({
      query: () => ({
        url: `trackingpurchaseshipments/${getId()}`,
      }),
      invalidateTags: ["OpenPurchase", "ClosePurchase"],
    }),
    updateTrackingPurchase: builder.mutation({
      query: () => ({
        url: `trackingpurchaseshipments/${getId()}`,
        // body,
        method: METHODS.PUT,
      }),
      transformResponse: (result) => {
        return result;
      },
      invalidateTags: ["OpenPurchase", "ClosePurchase"],
    }),
    // logistician local access last 2
    openPurchaseShipments: builder.query({
      query: () => ({
        url: `openpurchaseshipments/${getId()}`,
      }),
      transformResponse: (result) => {
        dispatch(handleOpenedPurchases(result.data));
        return result;
      },
      providesTags: ["OpenPurchase"],
    }),
    closePurchaseShipments: builder.query({
      query: () => ({
        url: `closedpurchaseshipments/${getId()}`,
      }),
      transformResponse: (result) => {
        dispatch(handleClosedPurchases(result.data));
        return result;
      },
      providesTags: ["ClosePurchase"],
    }),
    // TRANSIT SERVICES
    createTransit: builder.mutation({
      query: (body) => ({
        url: `${ENDPOINTS?.TRANSIT}/${getId()}`,
        body,
        method: METHODS.POST,
      }),
      transformResponse: (result) => {
        return result.data;
      },
      invalidateTags: ["CloseTransit", "OpenTransit"],
    }),
    transitById: builder.query({
      query: (id) => ({
        url: `${ENDPOINTS?.TRANSIT}/${getId()}/${id}`,
      }),
    }),
    transitByKey: builder.query({
      query: (key) => ({
        url: `${ENDPOINTS?.TRANSIT}/key/${getId()}/${key}`,
      }),
    }),
    addPurchaseToTransitById: builder.mutation({
      query: ({ id, body }) => ({
        url: `${ENDPOINTS?.TRANSIT}/${getId()}/${id}`,
        method: METHODS.PUT,
        body,
      }),
      invalidateTags: ["OpenTransit", "CloseTransit"],
    }),
    updateOtherTransitShipmentsInfos: builder.mutation({
      query: ({ id, body }) => ({
        url: `othertransitshipments/${getId()}/${id}`,
        body,
        method: METHODS.PUT,
      }),
      invalidateTags: ["OpenTransit", "CloseTransit"],
    }),
    updateItineraryTransitShipmentsInfos: builder.mutation({
      query: ({ id, body }) => ({
        url: `itinerarytransitshipments/${getId()}/${id}`,
        body,
        method: METHODS.PUT,
      }),
      invalidateTags: ["OpenTransit", "CloseTransit"],
    }),
    openTransitShipments: builder.query({
      query: () => ({
        url: `opentransitshipments/${getId()}`,
      }),
      transformResponse: (result) => {
        dispatch(handleOpenedTransits(result?.data));
        return result;
      },
      invalidateTags: ["OpenTransit"],
    }),
    closeTransitShipments: builder.query({
      query: () => ({
        url: `closedtransitshipments/${getId()}`,
      }),
      transformResponse: (result) => {
        dispatch(handleClosedTransits(result?.data));
        return result;
      },
      providesTags: ["CloseTransit"],
    }),
    //END OF  LOGISTICIAN-FOREIGN ACCESS ***************************************************************

    /**
     *
     *
     *  PUBLIC ACCESS *******************************************************************
     *
     *
     **/
    //AUTHENTICATION SERVICE
    signIn: builder.mutation({
      query(body) {
        return {
          url: `${ENDPOINTS?.SING_IN}`,
          method: `${METHODS?.POST}`,
          body,
        };
      },
      transformResponse: (result) => {
        setValidity(new Date().getTime());
        setAuthUser(result);
        dispatch(handleAuthentication(result));
        return result;
      },
      keepUnusedDataFor: 0,
      extraOptions: { maxRetries: 0 },
    }),
    signUpUser: builder.mutation({
      query({ callingCode, body }) {
        return {
          url: `${ENDPOINTS?.USERS}${getObjectAsSearchParameter({
            callingCode,
          })}`,
          method: `${METHODS?.POST}`,
          body,
        };
      },
      extraOptions: { maxRetries: 0 },
    }),
    resetPassword: builder.mutation({
      query(body) {
        return {
          url: `${ENDPOINTS?.PASSWORD_RESET}`,
          method: `${METHODS?.POST}`,
          body,
        };
      },
      extraOptions: { maxRetries: 0 },
    }),
    confirmResetPassword: builder.mutation({
      query(param) {
        return {
          url: `${ENDPOINTS?.PASSWORD_RESET}/${param?.userId}/${param?.token}`,
          method: `${METHODS?.POST}`,
          body: param?.body,
        };
      },
      extraOptions: { maxRetries: 0 },
    }),
    geoInformation: builder.query({
      query() {
        return {
          url: `http://ip-api.com/json?fields=countryCode,lat,lon,currency`,
        };
      },
      transformResponse(response) {
        dispatch(handleLocalization(response));
        return response;
      },
      extraOptions: { maxRetries: 3 },
    }),
    sendOtpCode: builder.mutation({
      query(body) {
        return {
          url: `otp`,
          method: `${METHODS?.POST}`,
          body,
        };
      },
      extraOptions: { maxRetries: 0 },
    }),
    verifyOtpCode: builder.mutation({
      query(body) {
        return {
          url: `otp/verify`,
          method: `${METHODS?.POST}`,
          body,
        };
      },
      extraOptions: { maxRetries: 0 },
    }),
    resendOtpCode: builder.mutation({
      query(body) {
        return {
          url: `otp/resend`,
          method: `${METHODS?.POST}`,
          body,
        };
      },
      extraOptions: { maxRetries: 0 },
    }),
    //ADDRESSES SERVICE
    getLocateaddressWithGoogleMapByUser: builder.mutation({
      query: (body) => ({
        url: `${ENDPOINTS?.LOCATE_ADDRESS}/${getId()}`,
        body,
        method: METHODS?.PUT,
      }),
      refetchOnReconnect: true,
      keepUnusedDataFor: 0,
      extraOptions: { maxRetries: 0 },
    }),
    //BRAND SERVICE
    brands: builder.query({
      query: (params) => ({
        url: `${ENDPOINTS?.BRANDS}${getObjectAsSearchParameter(params)}`,
      }),
      refetchOnReconnect: true,
      providesTags: [{ type: "Brand", id: "LIST" }],
      transformResponse: (result) => {
        dispatch(handleBrandsObject(result.data));
        return result;
      },
    }),
    brandByKey: builder.query({
      query: (key) => ({
        url: `${ENDPOINTS?.BRANDS}/key/${key}`,
      }),
      refetchOnReconnect: true,
      providesTags: (result, error, key) => [{ type: "Brand", id: key }],
      transformResponse: (result) => {
        let name = result?.data.name?.latin;
        dispatch(handleBrand(name));
        return result.data;
      },
    }),
    brandById: builder.query({
      query: (id) => ({
        url: `${ENDPOINTS?.BRANDS}/${id}`,
      }),
      refetchOnReconnect: true,
      // providesTags: (result, error, id) => [{ type: 'Brand', id }],
      transformResponse: (result) => {
        return result.data;
      },
    }),
    categories: builder.query({
      query: () => ({
        url: `${ENDPOINTS?.CATEGORIES}`,
      }),
      refetchOnReconnect: true,
      keepUnusedDataFor: EXPIRED_DATA,
      providesTags: ["AllCategories"],
      transformResponse: (result) => {
        dispatch(handleSetAllCategories(result.data));
        return result;
      },
    }),
    //CATEGORIES SERVICE
    categoryById: builder.query({
      query: (id) => ({
        url: `${ENDPOINTS?.CATEGORIES}/${id}`,
      }),
      refetchOnReconnect: true,
    }),
    categoryByKey: builder.query({
      query: (key) => ({
        url: `${ENDPOINTS?.CATEGORIES}/key/${key}`,
      }),
      refetchOnReconnect: true,
      transformResponse: (response) => {
        dispatch(handleCategory(response.data));
        return response;
      },
    }),
    categoryByName: builder.query({
      query: (name) => ({
        url: `${ENDPOINTS?.CATEGORIES}/name/${name}`,
      }),
      refetchOnReconnect: true,
    }),
    storesByCategoryKey: builder.query({
      query: (category) => ({
        url: `${ENDPOINTS?.STORES
          }/category/levelthree${getObjectAsSearchParameter({ category })}`,
      }),
      refetchOnReconnect: true,
      transformResponse: (response) => {
        dispatch(handleStoreCategory(response.data));
        return response;
      },
      // providesTags: (result) => providesList(result?.stores, "Store"),
      keepUnusedDataFor: 24 * 60 * 60,
    }),
    searchStoresByKeyword: builder.mutation({
      query: (body) => {
        return {
          url: `stores/searches`,
          body,
          method: METHODS.PUT,
        };
      },
    }),
    //ITEMS SERVICE
    getItemByKey: builder.query({
      query: (key) => ({
        url: `items/key/${key}`,
      }),
      // keepUnusedDataFor: 36000000,
      // providesTags: (result, meta, id) => [{ type: 'Item', id }],
      transformResponse: (result) => {
        dispatch(handleItem(result.data));
        return result;
      },
    }),
    recommendationsItems: builder.query({
      query: (key) => ({
        url: `recommendations?key=${key}`,
      }),
      // keepUnusedDataFor: 36000000,
      // providesTags: (result, meta, id) => [{ type: 'Item', id }],
      transformResponse: (result) => {
        dispatch(handleRecommendations(result.data));
        return result;
      },
    }),

    bookTrends: builder.query({
      query: () => ({
        url: `${ENDPOINTS?.ITEMVIEWS}/booktrend`,
      }),
      keepUnusedDataFor: 290200,
      refetchOnReconnect: true,
      // providesTags: ["bookTrends"],
      transformResponse: (result) => {
        dispatch(handleBookTrends(result?.data));
        return result?.data;
      },
    }),
    cartTrends: builder.query({
      query: () => ({
        url: `${ENDPOINTS?.ITEMVIEWS}/carttrend`,
      }),
      keepUnusedDataFor: 290200,
      refetchOnReconnect: true,
      // providesTags: ["cartTrends"],
      transformResponse: (result) => {
        dispatch(handleCartTrends(result?.data));
        return result?.data;
      },
    }),
    orderTrends: builder.query({
      query: () => ({
        url: `${ENDPOINTS?.ITEMVIEWS}/ordertrend`,
      }),
      keepUnusedDataFor: 290200,
      refetchOnReconnect: true,
      // providesTags: ["orderTrends"],
      transformResponse: (result) => {
        dispatch(handleOrderTrends(result?.data));
        return result?.data;
      },
    }),

    homesItems: builder.query({
      query: () => ({
        url: `homes`,
      }),
      providesTags: ["HOME_ITEMS"],
    }),
    addItemsHomesByForeignLogistician: builder.mutation({
      query: (body) => {
        return {
          url: `homes/${getId()}`,
          body,
          method: METHODS.POST,
        };
      },
      invalidatesTags: ["HOME_ITEMS"],
      extraOptions: { maxRetries: 1 },
    }),
    updateItemsHomesByForeignLogistician: builder.mutation({
      query: ({ body, code }) => {
        return {
          url: `homes/${getId()}?code=${code}`,
          body,
          method: METHODS.PUT,
        };
      },
      invalidatesTags: ["HOME_ITEMS"],
      extraOptions: { maxRetries: 1 },
    }),

    getItemsByCategory: builder.query({
      query: (params) => ({
        url: `${ENDPOINTS?.ITEMVIEWS}/${ENDPOINTS?.CATEGORIES}/${params?.key
          }${getObjectAsSearchParameter(params?.params)}`,
      }),
      refetchOnReconnect: true,
      // providesTags: (result) => providesList(result?.items, "ItemViewsByCategory"),
      transformResponse: (result) => {
        dispatch(handleGetAllItemsCategory(result?.data));
        console.log("result: ", result);
        return result;
      },
      transformErrorResponse: (error) => {
        console.error("error cat", error);
        return error;
      },
    }),
    getItemsByStore: builder.query({
      query: (params) => ({
        url: `${ENDPOINTS?.ITEMVIEWS}/${ENDPOINTS?.STORES}/${params?.key
          }${getObjectAsSearchParameter(params?.params)}`,
      }),
      refetchOnReconnect: true,
      // providesTags: (result) => providesList(result?.data?.items, "Item"),
      transformResponse: (result) => {
        dispatch(handleGetAllItemsCategory(result?.data));
        return result;
      },
    }),
    getAllItemsViewsByStroreKey: builder.query({
      query: (storeId) => ({
        url: `${ENDPOINTS?.ITEMVIEWS}/${ENDPOINTS?.STORES}/${storeId}`,
      }),
      refetchOnReconnect: true,
      // providesTags: (result) => providesList(result?.data?.items, "ItemViews"),
      transformResponse: (result) => {
        return result;
      },
    }),
    searchItemsByKeyword: builder.mutation({
      query: ({ body, params }) => {
        return {
          url: `itemsearches${getObjectAsSearchParameter(params)}`,
          body,
          method: METHODS.PUT,
        };
      },
      // transformResponse: (result) => {
      //     dispatch(handleGetAllItemsCategory(result?.data));
      //     return result
      // },
      transformResponse: (result) => {
        dispatch(handleItemsSearch(result?.data));
        return result;
      },
      extraOptions: { maxRetries: 1 },
    }),
    searchItemsInStore: builder.mutation({
      query: ({ body, id, params }) => {
        return {
          url: `itemsearches/stores/${id}${getObjectAsSearchParameter(params)}`,
          body,
          method: METHODS.PUT,
        };
      },
      // transformResponse: (result) => {
      //     dispatch(handleGetAllItemsCategory(result?.data));
      //     return result
      // },
      // extraOptions: { maxRetries: 1 },

      transformResponse: (result) => {
        dispatch(handleItemsSearch(result?.data));
        return result;
      },
      extraOptions: { maxRetries: 1 },
    }),
    searchItemsInCategories: builder.mutation({
      query: ({ body, id, params }) => {
        return {
          url: `itemsearches/categories/${id}${getObjectAsSearchParameter(
            params
          )}`,
          body,
          method: METHODS.PUT,
        };
      },
      // transformResponse: (result) => {
      //     dispatch(handleGetAllItemsCategory(result?.data));
      //     return result
      // },
      // extraOptions: { maxRetries: 1 },
      transformResponse: (result) => {
        dispatch(handleItemsSearch(result?.data));
        return result;
      },
      extraOptions: { maxRetries: 1 },
    }),
    searchItemsInBrands: builder.mutation({
      query: ({ body, id, params }) => {
        return {
          url: `itemsearches/brands/${id}${getObjectAsSearchParameter(params)}`,
          body,
          method: METHODS.PUT,
        };
      },
      // transformResponse: (result) => {
      //     dispatch(handleGetAllItemsCategory(result?.data));
      //     return result
      // },
      // extraOptions: { maxRetries: 1 },
      transformResponse: (result) => {
        dispatch(handleItemsSearch(result?.data));
        return result;
      },
      extraOptions: { maxRetries: 1 },
    }),
    // LOCATIONS SERVICE WHEN LOGGED
    getAllLocationsByAllUsers: builder.query({
      query: (params) => ({
        url: `locations/${getId()}${getObjectAsSearchParameter(params)}`,
      }),
      refetchOnReconnect: true,
      keepUnusedDataFor: 0,
      transformResponse: (response) => {
        dispatch(handleAllLocations(response?.data));
        return response;
      },
      providesTags: ["locations"],
    }),

    getAllLocationsBank: builder.query({
      query: (params) => ({
        url: `locations/${getId()}${getObjectAsSearchParameter(params)}`,
      }),
      refetchOnReconnect: true,
      keepUnusedDataFor: 0,
      transformResponse: (response) => {
        dispatch(handleAllLocationsBanks(response?.data));
        return response;
      },
      providesTags: ["locationsBanks"],
    }),
    getAllLocationsBranchs: builder.query({
      query: (params) => ({
        url: `locations/${getId()}${getObjectAsSearchParameter(params)}`,
      }),
      refetchOnReconnect: true,
      keepUnusedDataFor: 0,
      transformResponse: (response) => {
        // console.log('response getAllLocationsBranchs', response?.data)
        dispatch(handleAllLocationsBranchs(response?.data));
        return response;
      },
      transformResponseError: (response, meta, args) => {
        // console.log('error getAllLocationsBranchs', response?.data, meta, args)
        return response;
      },
      providesTags: ["locationsBranchs"],
    }),
    getLocationById: builder.query({
      query: (locationId) => ({
        url: `locations/${getId()}/${locationId}`,
      }),
      keepUnusedDataFor: 0,
    }),
    putLocationInMapGoogle: builder.mutation({
      query: (locationId) => ({
        url: `locatelocation/${getId()}/${locationId}`,
        method: METHODS?.PUT,
      }),
      refetchOnReconnect: true,
      keepUnusedDataFor: 0,
    }),
    getDirectionFromAddressUserToOrderAddress: builder.mutation({
      query: ({ id, body, userAddress }) => ({
        url: `directtolocation/${getId()}/${id}${getObjectAsSearchParameter(
          isRealValue(userAddress) ? { userAddress } : {}
        )}`,
        body: isRealValue(body) ? body : null,
        //pour cela retirer le param. direction d'un point a un autre
      }),
      refetchOnReconnect: true,
      extraOptions: { maxRetries: 3 },
      keepUnusedDataFor: 0,
    }),
    //STORES SERVICE
    getAllStores: builder.query({
      query: (params) => ({
        url: `${ENDPOINTS?.STORES}${getObjectAsSearchParameter(params)}`,
      }),
      refetchOnReconnect: true,
      transformResponse: (response) => {
        dispatch(handleGetAllStores(response));
        return response;
      },
      providesTags: (result) => providesList(result?.stores, "Store"),
      keepUnusedDataFor: 0,
    }),
    getStoresByArray: builder.query({
      query: (params) => ({
        url: `${ENDPOINTS?.STORES}`,
        params: getObjectAsSearchParameter(params),
      }),
      refetchOnReconnect: true,
      refetchOnMountOrArgChange: true,
      transformResponse: (response) => {
        dispatch(handleDataStoreFavorites(response?.data));
        return response;
      },
    }),
    menuOfStore: builder.query({
      query: (params) => ({
        url: `${ENDPOINTS?.STORES}/category/menu${getObjectAsSearchParameter(
          params
        )}`,
      }),
      refetchOnReconnect: true,
      refetchOnMountOrArgChange: true,
      transformResponse: (response) => {
        // console.log("Response menu store", response)
        dispatch(handleMenuStore(response?.data));
        return response;
      },
    }),
    storeByKey: builder.query({
      query: (key) => ({
        url: `${ENDPOINTS?.STORES}/key/${key}`,
      }),
      refetchOnReconnect: true,
      refetchOnMountOrArgChange: true,
      transformResponse: (response) => {
        dispatch(handleStore(response.data));
        return response;
      },
    }),
    //TERRITORY SERVICE ONLY CONNECTED
    getAdminDivision: builder.query({
      query: () => ({
        url: `${ENDPOINTS?.TERRITORY}/${getId()}`,
      }),
      refetchOnReconnect: true,
      keepUnusedDataFor: 0,
    }),
    getCountryOfUser: builder.query({
      query: () => ({
        url: `territories/${getId()}`,
      }),
      refetchOnReconnect: true,
      transformResponse: (response) => {
        dispatch(handleCountryUser(response.data?.country));
        return response?.country;
      },
    }),
    getSubDivisions: builder.query({
      query: ({ parent, division }) => ({
        url: `territories/${getId()}${getObjectAsSearchParameter({ parent })}`,
      }),
      refetchOnReconnect: true,
      transformResponse: (response, m, args) => {
        // console.log('response', response)
        const { division } = args;
        dispatch(
          handleDivisions({ division, territories: response?.territories })
        );
        return response;
      },
    }),
    getTerritoryById: builder.query({
      query: (territoryId) => ({
        url: `territories/${getId()}/${territoryId}`,
      }),
      refetchOnReconnect: true,
      keepUnusedDataFor: 0,
    }),
    //END OF  PUBLIC ACCESS ***************************************************************

    //USERS SERVICE EXCEPTIONS ********************************
    signOut: builder.mutation({
      query() {
        return {
          url: `${ENDPOINTS?.SIGN_OUT}`,
          method: `${METHODS?.DELETE}`,
        };
      },
      invalidatesTags: tagTypes,
      extraOptions: { maxRetries: 0 },
      transformResponse: (result) => {
        deleteAuth();
        dispatch(loggedOut());
        return result;
      },
    }),
    bookmarkItems: builder.query({
      query() {
        return {
          url: `${ENDPOINTS?.BOOK_ITEMS}/${getId()}`,
        };
      },
      transformResponse: (result) => {
        // console.log('result books items : ', result)
        dispatch(handleFavoriteItems(result.data));
        return result;
      },
      providesTags: ["ListBookmarkFavorite"],
    }),
    bookmarkItemsOffline: builder.query({
      query(arr) {
        return {
          url: `${ENDPOINTS?.ITEMVIEWS}/list/${getObjectAsSearchParameter(
            arr
          )}`,
        };
      },
      transformResponse: (result) => {
        dispatch(handleFavoriteItems(result.data));
        return result;
      },
      providesTags: ["ListBookmarkFavorite"],
    }),

    bookmarkItem: builder.mutation({
      query(body) {
        return {
          url: `${ENDPOINTS?.BOOK_ITEMS}/${getId()}`,
          method: `${METHODS?.POST}`,
          body,
        };
      },
      extraOptions: { maxRetries: 0 },
      invalidatesTags: ["ListBookmarkFavorite"],
    }),
    bookmarkCardItems: builder.query({
      query() {
        return {
          url: `${ENDPOINTS?.CARTS}/${getId()}`,
        };
      },
      transformResponse: (result) => {
        console.info(result);
        dispatch(handleItemsCartBookMarker(result?.data || []));
        return result;
      },
      providesTags: ["FavoritesCardItems"],
    }),
    bookmarkCardItemsOffline: builder.mutation({
      query(body) {
        return {
          url: `usercarts`,
          method: `${METHODS?.PUT}`,
          body: {
            items: body,
          },
        };
      },
      transformResponse: (result) => {
        dispatch(handleItemsCartBookMarker(result?.data || []));
        return result;
      },
      providesTags: ["FavoritesCardItems"],
    }),
    bookmarkCardItem: builder.mutation({
      query(body) {
        return {
          url: `${ENDPOINTS?.CARTS}/${getId()}`,
          method: `${METHODS?.POST}`,
          body,
        };
      },
      extraOptions: { maxRetries: 0 },
      invalidatesTags: ["FavoritesCardItems"],
    }),
    deleteBookmarkCardItem: builder.mutation({
      query(body) {
        return {
          url: `${ENDPOINTS?.CARTS}/${getId()}`,
          method: `${METHODS?.DELETE}`,
          body,
        };
      },
      extraOptions: { maxRetries: 0 },
      invalidatesTags: ["FavoritesCardItems"],
    }),
    bookmarkStore: builder.mutation({
      query(body) {
        return {
          url: `${ENDPOINTS?.BOOK_STORES}/${getId()}`,
          method: `${METHODS?.POST}`,
          body,
        };
      },
      extraOptions: { maxRetries: 0 },
      invalidatesTags: ["FavoritesStoreItems"],
    }),
    bookmarkStores: builder.query({
      query() {
        return {
          url: `${ENDPOINTS?.BOOK_STORES}/${getId()}`,
        };
      },
      providesTags: ["FavoritesStoreItems"],
      transformResponse: (response) => {
        dispatch(handleBookmarkStore(response?.data));
        return response;
      },
    }),
    bookmarkStoresOffline: builder.query({
      query(arr) {
        return {
          url: `${ENDPOINTS?.STORES}/list/list/${getObjectAsSearchParameter(
            arr
          )}`,
        };
      },
      providesTags: ["FavoritesStoreItems"],
      transformResponse: (response) => {
        dispatch(handleBookmarkStore(response?.data));
        return response;
      },
    }),
    //operator or controller
    changeRoleUser: builder.mutation({
      query(body) {
        return {
          url: `${ENDPOINTS?.USERS}/customer/${getId()}`,
          method: `${METHODS?.PUT}`,
          body,
        };
      },
      extraOptions: { maxRetries: 0 },
      invalidatesTags: ["UsersByAdmin", "UsersByStaff"],
    }),
    checkCustomerAccount: builder.query({
      query(params) {
        return {
          url: `${ENDPOINTS?.USERS
            }/customer/${getId()}${getObjectAsSearchParameter(params)}`,
        };
      },
    }),
    /**
     * ADMIN
     */
    updateInfoUserByAdmin: builder.mutation({
      query({ body, user }) {
        return {
          url: `users/admin/${getId()}${getObjectAsSearchParameter({ user })}`,
          method: `${METHODS?.PUT}`,
          body,
        };
      },
      extraOptions: { maxRetries: 0 },
    }),
    //TRANSACTIONS **********************************************************************************************************
    transactionById: builder.query({
      query(id) {
        return {
          url: `transactions/${getId()}/${id}`,
        };
      },
    }),
    transactionByKey: builder.query({
      query(key) {
        return {
          url: `transactions/key/${getId()}/${key}`,
        };
      },
    }),
    transactionByRef: builder.query({
      query(key) {
        return {
          url: `transactions/ref/${getId()}/${key}`,
        };
      },
    }),
    updateTransactionByOperator: builder.mutation({
      query({ id, body }) {
        return {
          url: `transactions/${getId()}/${id}`,
          body,
          method: METHODS.PUT,
        };
      },
    }),
    updateDepositTransactionByController: builder.mutation({
      query({ id, body }) {
        return {
          url: `transactions/deposit/${getId()}/${id}`,
          body,
          method: METHODS.PUT,
        };
      },
    }),
    updateWithdrawalTransactionByOperator: builder.mutation({
      query({ id, body }) {
        return {
          url: `transactions/withdrawal/${getId()}/${id}`,
          body,
          method: METHODS.PUT,
        };
      },
    }),
    withdrawalAccountClientByOperator: builder.mutation({
      query(body) {
        return {
          url: `transactions/${getId()}`,
          method: `${METHODS?.POST}`,
          body,
        };
      },
      extraOptions: { maxRetries: 0 },
    }),
    withdrawalAccount: builder.mutation({
      query(body) {
        return {
          url: `transactions/${getId()}`,
          method: `${METHODS?.POST}`,
          body,
        };
      },
      extraOptions: { maxRetries: 0 },
    }),
    paymentOrderByUser: builder.mutation({
      query({ order, body }) {
        return {
          url: `transactions/${getId()}${getObjectAsSearchParameter({
            order,
          })}`,
          method: `${METHODS?.POST}`,
          body,
        };
      },
      extraOptions: { maxRetries: 0 },
    }),
    transfertAccount: builder.mutation({
      query(body) {
        return {
          url: `transactions/${getId()}`,
          method: `${METHODS?.POST}`,
          body,
        };
      },
      extraOptions: { maxRetries: 0 },
    }),
    //CUSTOMER*******************************************************
    createDepositByCustomer: builder.mutation({
      query(body) {
        return {
          url: `transactions/deposit/${getId()}`,
          method: `${METHODS?.POST}`,
          body,
        };
      },
      invalidatesTags: ["Informations", "Transactions"],
      extraOptions: { maxRetries: 0 },
    }),
    createTransferByCustomer: builder.mutation({
      query(body) {
        return {
          url: `transactions/transfer/${getId()}`,
          method: `${METHODS?.POST}`,
          body,
        };
      },
      invalidatesTags: ["Transactions"],
      extraOptions: { maxRetries: 0 },
    }),
    createWithdrawalByCustomer: builder.mutation({
      query(body) {
        return {
          url: `transactions/withdrawal/${getId()}`,
          method: `${METHODS?.POST}`,
          body,
        };
      },
      invalidatesTags: ["Transactions"],
      extraOptions: { maxRetries: 0 },
    }),
    createPaymentByCustomer: builder.mutation({
      query({ orderId, body }) {
        return {
          url: `transactions/payment/${getId()}/${orderId}`,
          method: `${METHODS?.POST}`,
          body,
        };
      },
      // invalidatesTags: ["Transactions"],
      extraOptions: { maxRetries: 0 },
    }),
    viewStatementsByCustomer: builder.query({
      query({ to, from }) {
        return {
          url: `statements/${getId()}${getObjectAsSearchParameter({
            from,
            to,
          })}`,
        };
      },
      providesTags: ["Statements"],
      transformResponse: (response) => {
        dispatch(handleStatements(response?.data));
        return response;
      },
    }),
    //OPERATORS*******************************************************
    createDepositByOperator: builder.mutation({
      query(body) {
        return {
          url: `transactions/deposit/${getId()}`,
          method: `${METHODS?.POST}`,
          body,
        };
      },
      invalidatesTags: ["Transactions"],
      extraOptions: { maxRetries: 0 },
    }),
    createPaymentByOperator: builder.mutation({
      query({ orderId, body }) {
        return {
          url: `transactions/payment/${getId()}/${orderId}`,
          method: `${METHODS?.POST}`,
          body,
        };
      },
      extraOptions: { maxRetries: 0 },
    }),
    confirmWithdrawalByOperator: builder.mutation({
      query({ body, transactionId }) {
        return {
          url: `transactions/withdrawal/${getId()}/${transactionId}`,
          method: `${METHODS?.PUT}`,
          body,
        };
      },
      invalidatesTags: ["Transactions"],
      extraOptions: { maxRetries: 0 },
    }),
    //Controllers*********************************************
    updateDepositByController: builder.mutation({
      query({ body, transactionId }) {
        return {
          url: `transactions/deposit/${getId()}/${transactionId}`,
          method: `${METHODS?.PUT}`,
          body,
        };
      },
      invalidatesTags: ["Transactions"],
      extraOptions: { maxRetries: 0 },
    }),
    confirmRefundBySupervisor: builder.mutation({
      query({ body, transactionId }) {
        return {
          url: `transactions/refund/${getId()}/${transactionId}`,
          method: `${METHODS?.PUT}`,
          body,
        };
      },
      // invalidatesTags: ['Informations'],
      invalidatesTags: ["Transactions"],
      extraOptions: { maxRetries: 0 },
    }),
    createRefundByController: builder.mutation({
      query(body) {
        return {
          url: `transactions/refund/${getId()}`,
          method: `${METHODS?.POST}`,
          body,
        };
      },
      // invalidatesTags: ['Informations'],
      invalidatesTags: ["Transactions"],
      extraOptions: { maxRetries: 0 },
    }),
    rejetTransactionToInitialStateBySupervisor: builder.mutation({
      query({ id, body }) {
        return {
          url: `transactions/reinstate/${getId()}/${id}`,
          method: `${METHODS?.PUT}`,
          body,
        };
      },
      // invalidatesTags: ['Informations'],
      invalidatesTags: ["Transactions"],
      extraOptions: { maxRetries: 0 },
    }),
    getTransactionsByUserOrByOperatorControllerSupervisor: builder.query({
      query(params) {
        return {
          url: `transactions/${getId()}${getObjectAsSearchParameter(params)}`,
        };
      },
      providesTags: ["Transactions"],
      transformResponse: (response) => {
        dispatch(handleTransactions(response?.data));
        return response;
      },
    }),
    getOpenedTransactionsByControllerSupervisor: builder.query({
      query() {
        return {
          url: `opentransactions/${getId()}`,
        };
      },
      providesTags: ["Transactions"],
      transformResponse: (response) => {
        dispatch(handleTransactions(response?.data));
        return response;
      },
    }),
    getClosedTransactionsByControllerSupervisor: builder.query({
      query() {
        return {
          url: `closedtransactions/${getId()}`,
        };
      },
      providesTags: ["Transactions"],
      transformResponse: (response) => {
        dispatch(handleTransactions(response?.data));
        return response;
      },
    }),

    viewStatementsByControllerOrSupervisor: builder.query({
      query({ to, from, account }) {
        return {
          url: `statements/${getId()}${getObjectAsSearchParameter({
            from,
            to,
            account,
          })}`,
        };
      },
      providesTags: ["Statements"],
      transformResponse: (response) => {
        // console.log("response ", response)
        dispatch(handleStatements(response?.data));
        return response;
      },
    }),

    // END TRANSACTIONs ************************************************************
    getAllUsersByAdmin: builder.query({
      query(params) {
        return {
          url: `${ENDPOINTS?.USERS
            }/admin/${getId()}${getObjectAsSearchParameter(params)}`,
        };
      },
      providesTags: ["UsersByAdmin"],
      transformResponse: (result) => {
        dispatch(handleUsers(result?.data));
        return result;
      },
      keepUnusedDataFor: 0,
    }),
    getAllUsersByStaff: builder.query({
      query(params) {
        return {
          url: `${ENDPOINTS?.USERS
            }/staff/${getId()}${getObjectAsSearchParameter(params)}`,
        };
      },
      providesTags: ["UsersByStaff"],
      transformResponse: (result) => {
        // console.log('result users', result)
        dispatch(handleUsers(result?.data));
        return result;
      },
      // keepUnusedDataFor: 0,
    }),
    changeProfileByUser: builder.mutation({
      query(body) {
        return {
          url: `users/profile/${getId()}`,
          body,
          method: METHODS?.PUT,
        };
      },
      extraOptions: { maxRetries: 0 },
      invalidatesTags: ["Informations"],
    }),
    changeIdentityCniProfileByUser: builder.mutation({
      query(body) {
        return {
          url: `users/identity/${getId()}`,
          method: `${METHODS?.PUT}`,
          body,
        };
      },
      invalidatesTags: ["Informations"],
      extraOptions: { maxRetries: 0 },
    }),
    updateInfoUserByEmployee: builder.mutation({
      query({ body, user }) {
        return {
          url: `users/staff/${getId()}${getObjectAsSearchParameter({ user })}`,
          method: `${METHODS?.PUT}`,
          body,
        };
      },
      extraOptions: { maxRetries: 0 },
    }),
    infosAccountBank: builder.query({
      query() {
        return {
          url: `${ENDPOINTS?.USERS}/${getId()}`,
        };
      },
      providesTags: ["Informations"],
      transformResponse: (response) => {
        // console.log('response infosAccountBank', response)
        dispatch(handleInfosAccount(response.data));
        return response;
      },
      // keepUnusedDataFor: 1000,
    }),
    getInfosAccountByEmployeeForUser: builder.query({
      query(userId) {
        return {
          url: `${ENDPOINTS?.USERS
            }/customer/${getId()}${getObjectAsSearchParameter({ userId })}`,
        };
      },
      transformResponse: (response) => {
        dispatch(handleCustomerView(response.data));
        return response;
      },
    }),
    getPaymentByCustomer: builder.query({
      query(params) {
        return {
          url: `payments/${getId()}${getObjectAsSearchParameter(params)}`,
        };
      },
      transformResponse: (response) => {
        // console.log("payment by user", response)
        dispatch(handlePayments(response));
        return response;
      },
    }),
    // all sauf logisticians and admin
    getPaymentById: builder.query({
      query(id) {
        return {
          url: `payments/${getId()}/${id}`,
        };
      },
    }),
    //all sauf foreinlog and admin
    getPaymentByOrderId: builder.query({
      query(id) {
        return {
          url: `payments/${getId()}/${id}`,
        };
      },
    }),
    getPaymentsOfCustomerByEmployee: builder.query({
      query(params) {
        return {
          url: `payments/${getId()}${getObjectAsSearchParameter(params)}`,
        };
      },
      transformResponse: (response) => {
        dispatch(handlePayments(response?.data));
        return response;
      },
    }),
    getHoldByCustomer: builder.query({
      query(params) {
        return {
          url: `holds/${getId()}${getObjectAsSearchParameter(params)}`,
        };
      },
      transformResponse: (response) => {
        dispatch(handleHolds(response?.data));
        return response;
      },
    }),
    getHoldsOfCustomerByEmployee: builder.query({
      query(params) {
        return {
          url: `holds/${getId()}${getObjectAsSearchParameter(params)}`,
        };
      },
      transformResponse: (response) => {
        dispatch(handleHolds(response?.data));
        return response;
      },
    }),
    rooms: builder.query({
      query: () => ({
        url: `${chatUrl}/${ENDPOINTS?.ROOMS}/${getId()}`,
      }),
      providesTags: ["Rooms"],
      refetchOnReconnect: true,
      // keepUnusedDataFor: 0,
      transformResponse: (response) => {
        // console.log("response =>", response)
        dispatch(handleRooms(response?.data));
        return response;
      },
    }),
    messages: builder.query({
      query: (to) => ({
        url: `${chatUrl}/${ENDPOINTS?.MESSAGES
          }/${getId()}${getObjectAsSearchParameter({ to })}`,
      }),
      // providesTags: (r, m, id) => [{ type: "Messages", id }],
      transformResponse: (response, m, to) => {
        dispatch(handleMessages({ data: response.data, to }));
        return response;
      },
      refetchOnReconnect: true,
      // keepUnusedDataFor: EXPIRED_DATA,
    }),
    messageForUpdate: builder.query({
      query: (to) => ({
        url: `${chatUrl}/${ENDPOINTS?.MESSAGES
          }/${getId()}${getObjectAsSearchParameter({ to })}`,
      }),
      invalidatesTags: (r, m, id) => [
        "Rooms",
        "Messages",
        { type: "Messages", id },
      ],
      transformResponse: (response, m, to) => {
        dispatch(handleMessages({ data: response.data, to }));
        return response;
      },
    }),
    searchAnUser: builder.query({
      query: (params) => ({
        url: `${ENDPOINTS?.USERS}/search/${getId()}${getObjectAsSearchParameter(
          params
        )}`,
      }),
      keepUnusedDataFor: 0,
    }),
    addContactUser: builder.mutation({
      query: (body) => ({
        url: `${ENDPOINTS?.USER_CONTACT}/${getId()}`,
        body,
        method: METHODS.POST,
      }),
      invalidatesTags: ["Contacts"],
      extraOptions: { maxRetries: 0 },
    }),
    lockContactUser: builder.mutation({
      query: (body) => ({
        url: `${ENDPOINTS?.USER_CONTACT}/${getId()}`,
        body,
        method: METHODS.POST,
      }),
      invalidatesTags: ["Contacts"],
      extraOptions: { maxRetries: 0 },
    }),
    unlockContactUser: builder.mutation({
      query: (body) => ({
        url: `${ENDPOINTS?.USER_CONTACT}/${getId()}`,
        body,
        method: METHODS.DELETE,
      }),
      invalidatesTags: ["Contacts"],
      extraOptions: { maxRetries: 0 },
    }),
    deleteContactUser: builder.mutation({
      query: (body) => ({
        url: `${ENDPOINTS?.USER_CONTACT}/${getId()}`,
        body,
        method: METHODS.DELETE,
      }),
      invalidatesTags: ["Contacts"],
      extraOptions: { maxRetries: 0 },
    }),
    allContactsUser: builder.query({
      query: () => ({
        url: `${ENDPOINTS?.USER_CONTACT}/${getId()}`,
      }),
      // keepUnusedDataFor: EXPIRED_DATA,
      providesTags: ["Contacts"],
      transformResponse: (response) => {
        dispatch(handleContacts(response?.data));
        return response;
      },
    }),
    //veryImportant endpoint
    codeValues: builder.query({
      query: ({ code, context, method }) => ({
        url: `codes${getObjectAsSearchParameter({ code, context, method })}`,
      }),
      keepUnusedDataFor: 0,
      providesTags: (r, m, arg) => [{ type: "CodeValues", id: toString(arg) }],
      transformResponse: (response, m, arg) => {
        dispatch(handleCodeValues({ ...arg, data: response }));
        return response;
      },
    }),
    listCountries: builder.query({
      query: () => ({
        url: `user/user_country_values`,
      }),
      keepUnusedDataFor: 0,
      transformResponse: (response) => {
        dispatch(handleCountries(response));
        return response;
      },
    }),
    deleteAccountInAppByUser: builder.mutation({
      query: () => ({
        url: `${ENDPOINTS.USERS}/${getId()}`,
      }),
      keepUnusedDataFor: 0,
      invalidatesTags: tagTypes,
    }),
    proposeUserSize: builder.mutation({
      query({ key, body }) {
        return {
          url: `dimensions/${key}`,
          method: `${METHODS?.POST}`,
          body,
        };
      },
      extraOptions: { maxRetries: 1 },
    }),

  }),
});

export const {
  useDeleteAccountInAppByUserMutation,
  useProposeUserSizeMutation,

  //ADDRESS SERVICE
  useCreateAddressByUserMutation,
  useDeleteAddressByUserMutation,
  useGetAddressesByUserQuery,
  useGetAllAddressOfUserByEmployeeQuery,
  useGetDeliverableAddressByIdForUserQuery,
  useGetDeliverableAddressByUserQuery,
  useGetLocateaddressWithGoogleMapByUserMutation,
  useUpdateAddressByUserMutation,
  //AUTHENTICATION SERVICE
  useSignInMutation,
  useSignUpUserMutation,
  useResetPasswordMutation,
  useConfirmResetPasswordMutation,
  useGeoInformationQuery,
  useSendOtpCodeMutation,
  useVerifyOtpCodeMutation,
  useResendOtpCodeMutation,
  //BANKS SERVICE
  useCreateBankAccountMutation,
  useAllAccountBankQuery,
  useAccountBankByIdQuery,
  useDeleteAccountBankByIdMutation,
  useAccountBankByCodeQuery,
  //BILL SERVICE
  useCloseBillQuery,
  useCreateBillMutation,
  useBillByIdQuery,
  useUpdateStatusBillMutation,
  useOpenBillQuery,
  useBillByKeyQuery,
  //BRAND SERVICE
  useBrandsQuery,
  useBrandByKeyQuery,
  useBrandByIdQuery,
  useUpdateBrandMutation,
  //CATEGORY SERVICE
  useCategoriesQuery,
  useCategoryByIdQuery,
  useCategoryByKeyQuery,
  useCategoryByNameQuery,
  useStoresByCategoryKeyQuery,
  useUpdateCategoryMutation,
  useSearchStoresByKeywordMutation,
  //DELIVERY-AREAS SERVICE
  useAllDeliveryAreaByAdminQuery,
  useCreateDeliveryAreaByAdminMutation,
  useGetDeliveryAreaByIdByAdminQuery,
  //FULFILLMENT-AREAS SERVICE
  useCloseFulfillmentShipmentsQuery,
  useCreateFulfillmentMutation,
  useDeliverFulfillmentMutation,
  useFulfillmentByIdQuery,
  useFulfillmentByKeyQuery,
  useReceiveFulfillmentMutation,
  useUpdateFulfillmentLineMutation,
  useUpdateStatusFulfillmentMutation,
  useOpenFulfillmentShipmentsQuery,
  useUpdateOrderlineByLogLocMutation,
  useUpdateStoreMutation,

  //ITEMS SERVICE
  useSearchItemsInBrandsMutation,
  useSearchItemsInCategoriesMutation,
  useSearchItemsInStoreMutation,
  useSearchItemsByKeywordMutation,
  useBookTrendsQuery,
  useCartTrendsQuery,
  useOrderTrendsQuery,
  useGetItemByKeyQuery,
  useGetAllItemsViewsByStroreKeyQuery,
  useGetItemsByCategoryQuery,
  useGetItemsByStoreQuery,
  useUpdateItemMutation,
  usePubOrUnpublishItemMutation,
  useUnpublishedItemByCategoryQuery,
  useRecommendationsItemsQuery,

  //LOCATIONS SERVICE
  useCreateLocationByAdminMutation,
  useDeleteLocationByAdminMutation,
  useGetAllLocationsByAllUsersQuery,
  useGetDirectionFromAddressUserToOrderAddressMutation,
  useGetLocationByIdQuery,
  usePutLocationInMapGoogleMutation,
  useGetAllLocationsBankQuery,
  useGetAllLocationsBranchsQuery,
  //ORDERS SERVICE
  useAllOrdersQuery,
  useGetOrderByIdQuery,
  useCreateOrderMutation,
  useGetOrdersUserByStaffUserQuery,
  useUpdateItemsOrderByIdMutation,
  useGetRequiredDepositQuery,
  useGetOrderviewByIdQuery,
  useGetOrderviewByKeyQuery,
  useGetCommandedArticlesQuery,
  useGetOrderByRefQuery,
  useUpdateStatusOrderByIdMutation,
  useGetOrderByKeyQuery,
  useAllOrdersViewsQuery,
  useGetOrdersviewUserByStaffUserQuery,
  useAllStripeOrdersByControllerOrSupervisorQuery,

  //PURCHASE SERVICE
  useAddTrackingToAllPurchasesQuery,
  useClosePurchaseShipmentsQuery,
  useCreatePurchaseMutation,
  useDeletePurchaseByIdMutation,
  useOpenPurchaseShipmentsQuery,
  usePurchaseByIdQuery,
  useUpdatePurchaseMutation,
  useUpdateTrackingPurchaseMutation,
  //STORES SERVICE
  useGetAllStoresQuery,
  useGetStoresByArrayQuery,
  useStoreByKeyQuery,
  useMenuOfStoreQuery,
  useLazyGetAllStoresQuery,
  useGetListSizeOfStoreByCategoryLevel3ByLogForeignQuery,
  usePutSizeOfStoreByCategoryLevel3ByLogForeignMutation,
  //STRIPE SERVICE FOR PAYMENT
  useViewClientStripeQuery,
  useAddCreditCardClientMutation,
  useDeleteCreditCardClientMutation,
  useViewCardsQuery,
  useViewCardQuery,
  useAddCardsMutation,
  useDeleteCardMutation,
  useConfirmIntentionPaymentMutation,
  useCreateIntentionPaymentMutation,
  useEuroRelatedQuery,
  useCapturePaymentIntentByControllerMutation,
  useGetSourceItemsByForeignLogQuery,
  //TERRITORY SERVICE
  useGetAdminDivisionQuery,
  useGetCountryOfUserQuery,
  useGetTerritoryByIdQuery,
  useGetSubDivisionsQuery,
  //TRANSIT SERVICE
  useCreateTransitMutation,
  useAddPurchaseToTransitByIdMutation,
  useUpdateOtherTransitShipmentsInfosMutation,
  useCloseTransitShipmentsQuery,
  useOpenTransitShipmentsQuery,
  useTransitByIdQuery,
  useTransitByKeyQuery,
  useUpdateItineraryTransitShipmentsInfosMutation,

  //USERS SERVICES EXCEPTIONS
  useCreateDepositByCustomerMutation,
  useCreateWithdrawalByCustomerMutation,
  useCreatePaymentByCustomerMutation,
  useCreateTransferByCustomerMutation,
  useCreatePaymentByOperatorMutation,
  useCreateDepositByOperatorMutation,
  useConfirmWithdrawalByOperatorMutation,
  useUpdateDepositByControllerMutation,
  useCreateRefundByControllerMutation,
  useConfirmRefundBySupervisorMutation,
  useTransactionByIdQuery,
  useTransactionByKeyQuery,
  useTransactionByRefQuery,

  useCodeValuesQuery,
  useSignOutMutation,
  useBookmarkItemMutation,
  useBookmarkItemsQuery,
  useBookmarkItemsOfflineQuery,
  useBookmarkCardItemMutation,
  useBookmarkCardItemsQuery,
  useBookmarkCardItemsOfflineMutation,
  useDeleteBookmarkCardItemMutation,
  useBookmarkStoreMutation,
  useBookmarkStoresQuery,
  useBookmarkStoresOfflineQuery,
  useGetAllUsersByAdminQuery,
  useGetAllUsersByStaffQuery,
  useChangeRoleUserMutation,
  useCheckCustomerAccountQuery,
  useWithdrawalAccountMutation,
  useWithdrawalAccountClientByOperatorMutation,
  useTransfertAccountMutation,
  useUpdateDepositTransactionByControllerMutation,
  useUpdateWithdrawalTransactionByOperatorMutation,
  useRejetTransactionToInitialStateBySupervisorMutation,

  useChangeIdentityCniProfileByUserMutation,
  useUpdateInfoUserByEmployeeMutation,
  useInfosAccountBankQuery,
  useGetInfosAccountByEmployeeForUserQuery,
  useGetTransactionsByUserOrByOperatorControllerSupervisorQuery,
  usePaymentOrderByUserMutation,
  useGetOpenedTransactionsByControllerSupervisorQuery,
  useGetClosedTransactionsByControllerSupervisorQuery,
  usePaymentForexOrderByUserWithCardMutation,
  useRoomsQuery,
  useMessagesQuery,
  useSearchAnUserQuery,
  useAddContactUserMutation,
  useAllContactsUserQuery,
  useLockContactUserMutation,
  useUnlockContactUserMutation,
  useDeleteContactUserMutation,
  useChangeProfileByUserMutation,
  useMessageForUpdateQuery,
  useGetPaymentByCustomerQuery,
  useGetHoldByCustomerQuery,
  useGetPaymentsOfCustomerByEmployeeQuery,
  useGetHoldsOfCustomerByEmployeeQuery,
  useGetPaymentByIdQuery,
  useGetPaymentByOrderIdQuery,
  useUpdateTransactionByOperatorMutation,
  useUpdateInfoUserByAdminMutation,
  useListCountriesQuery,

  useViewStatementsByCustomerQuery,
  useViewStatementsByControllerOrSupervisorQuery,

  useHomesItemsQuery,
  useAddItemsHomesByForeignLogisticianMutation,
  useUpdateItemsHomesByForeignLogisticianMutation,
} = Api;
