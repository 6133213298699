import { configureStore } from "@reduxjs/toolkit"
import { setupListeners } from "@reduxjs/toolkit/query/react"

import authSlice from "./features/authSlice"
import categoriesSlice from "./features/categoriesSlice"
import itemSlice from "./features/itemSlice"
import itemsSlice from "./features/itemsSlice"
import bookmarkCardItemsSlice from "./features/bookmarkCardItemsSlice"
import bookmarkFavoriteItemsSlice from "./features/bookmarkFavoriteItemsSlice"
import homeSlice from "./features/homeSlice"
import ordersSlice from "./features/ordersSlice"
import storeSlice from "./features/storeSlice"
import storesSlice from "./features/storesSlice"
import bookmarkStoresSlice from "./features/bookmarkStoresSlice"
import usersSlice from "./features/usersSlice"
import filterItemSlice from "./features/filterItemSlice"
import addressesSlice from "./features/addressSlice"
import banksSlice from "./features/bankSlice"
import purchaseShipmentSlice from "./features/purchaseShipmentSlice"
import transitShipmentSlice from "./features/transitShipmentSlice"
import fulfillmentSlice from "./features/fulfillmentSlice"
import billSlice from "./features/billSlice"
import locationSlice from "./features/locationSlice"
import AppSlice from "./features/AppSlice"
import deliveryAreaSlice from "./features/deliveryAreaSlice"
import StripeSlice from "./features/StripeSlice"
import { Api } from "./services/Api"
import territorySlice from "./features/territorySlice"

const store = configureStore({

    reducer: {
        [authSlice.name]: authSlice.reducer,
        [bookmarkCardItemsSlice.name]: bookmarkCardItemsSlice.reducer,
        [bookmarkFavoriteItemsSlice.name]: bookmarkFavoriteItemsSlice.reducer,
        [bookmarkStoresSlice.name]: bookmarkStoresSlice.reducer,
        [categoriesSlice.name]: categoriesSlice.reducer,
        [itemSlice.name]: itemSlice.reducer,
        [deliveryAreaSlice.name]: deliveryAreaSlice.reducer,
        [itemsSlice.name]: itemsSlice.reducer,
        [homeSlice.name]: homeSlice.reducer,
        [ordersSlice.name]: ordersSlice.reducer,
        [storeSlice.name]: storeSlice.reducer,
        [storesSlice.name]: storesSlice.reducer,
        [usersSlice.name]: usersSlice.reducer,
        [addressesSlice.name]: addressesSlice.reducer,
        [banksSlice.name]: banksSlice.reducer,
        [purchaseShipmentSlice.name]: purchaseShipmentSlice.reducer,
        [transitShipmentSlice.name]: transitShipmentSlice.reducer,
        [fulfillmentSlice.name]: fulfillmentSlice.reducer,
        [billSlice.name]: billSlice.reducer,
        [locationSlice.name]: locationSlice.reducer,
        [AppSlice.name]: AppSlice.reducer,
        [StripeSlice.name]: StripeSlice.reducer,
        [filterItemSlice.name]: filterItemSlice.reducer,
        [territorySlice.name]: territorySlice.reducer,

        [Api.reducerPath]: Api.reducer,
    },

    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
        immutableCheck: false,
        serializableCheck: false,
        thunk: true
    }).concat(Api.middleware),

    devTools: process.env.REACT_APP_DEVTOOLS_ENABLED || false,
})

setupListeners(store.dispatch)

export default store