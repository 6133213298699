import React, { useRef, useState } from 'react'
import { useSelector } from 'react-redux';
import ModalFlat from '../ModalFlat';
import { IoCloseSharp, IoPencilSharp } from 'react-icons/io5';
import EditAddressForm from '../EditAddressForm ';
import { motion } from 'framer-motion';
import { CircularProgress } from '@mui/material';
import useMutationApi from '../../app/hooks/useMutationApi';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}


const AddressTable = () => {
  const { addresses } = useSelector(state => state.addresses);
  const { deleteShippingAddressByUser, isLoadingDeleteAddress } = useMutationApi()
  const [isOpen, setIsOpen] = React.useState(false);
  const [shippingAddress, setAddress] = React.useState(null);

  function closeModal() {
    setIsOpen(false);
  }
  function openModal() {
    setIsOpen(true);
  }

  const checkbox = useRef()
  const [checked, setChecked] = useState(false)
  const [selectedPeople, setSelectedPeople] = useState([])


  function toggleAll() {

  }
  return (
    <div className="flex flex-col mt-8">
      <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
          <div className="relative overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
            {[].length > 0 && (
              <div className="absolute top-0 flex items-center h-12 space-x-3 left-12 bg-gray-50 sm:left-16">
                <button
                  type="button"
                  className="inline-flex items-center rounded border border-gray-300 bg-white px-2.5 py-1.5 text-xs font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-[#D90429] focus:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-30"
                >
                  Bulk edit
                </button>
                <button
                  type="button"
                  className="inline-flex items-center rounded border border-gray-300 bg-white px-2.5 py-1.5 text-xs font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-[#D90429] focus:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-30"
                >
                  Delete all
                </button>
              </div>
            )}
            <table className="min-w-full divide-y divide-gray-300 table-fixed">
              <thead className="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    className="relative w-12 px-6 sm:w-16 sm:px-8"
                  >
                    <input
                      type="checkbox"
                      className="absolute w-4 h-4 -mt-2 border-gray-300 rounded left-4 top-1/2 text-[#D90429] focus:ring-[#D90429] sm:left-6"
                      ref={checkbox}
                      checked={checked}
                      onChange={toggleAll}
                    />
                  </th>
                  <th
                    scope="col"
                    className="min-w-[12rem] py-3.5 pr-3 text-left text-sm font-semibold text-gray-900"
                  >
                    Address
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    Short code
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    default Address
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    delivery Area
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    Valid
                  </th>
                  <th
                    scope="col"
                    className="relative py-3.5 pl-3 pr-4 sm:pr-6"
                  >
                    <span className="sr-only">Edit</span>
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {addresses?.map((address, index) => (
                  <tr
                    key={index}
                    className={classNames("",
                      shippingAddress?._id === address?._id ? "bg-gray-50" : undefined
                    )}
                    onClick={e => { e.preventDefault(); setAddress(address) }}
                    style={{ cursor: "pointer" }}
                  >
                    <td className="relative w-12 px-6 sm:w-16 sm:px-8">
                      {shippingAddress?._id === address?._id && (
                        <div className="absolute inset-y-0 left-0 w-0.5 bg-[#D90429]" />
                      )}
                      <input
                        type="checkbox"
                        className="absolute w-4 h-4 -mt-2 border-gray-300 rounded left-4 top-1/2 text-[#D90429] focus:ring-[#D90429] sm:left-6"
                        // value={address.Region}
                        checked={shippingAddress?._id === address?._id}
                        // onChange={(e) => setAddress(address)}
                      />
                    </td>
                    <td
                      className={classNames(
                        "whitespace-nowrap py-4 pr-3 text-sm font-medium",
                        shippingAddress?._id === address?._id
                          ? "text-[#D90429]"
                          : "text-gray-900"
                      )}
                    >
                      {address?.address}
                    </td>
                    <td className="px-3 py-4 text-sm text-gray-500 whitespace-nowrap">
                      {address?.shortCode}
                    </td>
                    <td className="px-3 py-4 text-sm text-gray-500 whitespace-nowrap">{`${address?.defaultAddress}`}</td>
                    <td className="px-3 py-4 text-sm text-gray-500 whitespace-nowrap">{`${address?.deliveryArea}`}</td>
                    <td className="px-3 py-4 text-sm text-gray-500 whitespace-nowrap">{`${address?.isValid}`}</td>
                    <td className="flex flex-row py-4 pl-3 pr-4 space-x-2 text-sm font-medium text-right whitespace-nowrap sm:pr-6 justify-evenly">
                      <motion.a
                        whileTap={{ scale: 0.98 }}
                        onClick={openModal}
                        className="flex flex-row items-center justify-center w-8 h-8 rounded-full shadow-md cursor-pointer bg-[#D90429] hover:text-fuchsia-900"
                      >
                        <IoPencilSharp className="w-4 h-4 text-white " />
                      </motion.a>
                      {isLoadingDeleteAddress && shippingAddress?._id === address?._id && <CircularProgress color='secondary' size={20} />}
                      {!isLoadingDeleteAddress && <motion.a
                        whileTap={{ scale: 0.98 }}
                        onClick={() => deleteShippingAddressByUser(address?._id)}
                        className="flex flex-row items-center justify-center w-8 h-8 bg-red-600 rounded-full shadow-md cursor-pointer hover:text-fuchsia-900"
                      >
                        <IoCloseSharp className="w-4 h-4 text-white " />
                      </motion.a>}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <ModalFlat
        isOpen={isOpen}
        children={<EditAddressForm closeModal={closeModal} address={shippingAddress} />}
        title={"Edit Address"}
        closeModal={closeModal}
       
      />
    </div>
  );
}

export default AddressTable