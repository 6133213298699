import React from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import CardComponent from "../../components/common/card";
import LoginForm from "../../components/layout/LoginForm";
import { HOME } from "../../constants/routes";
import logo from "../../assets/images/Logo_AFLP.png";
import "./styles.css";
import { scroolWindowToTop } from "../../utilities";

const SignIn = () => {
  const { isLogged } = useSelector((state) => state.auth);
  const navigate = useNavigate();
  React.useEffect(() => {
    if (isLogged) navigate(`${HOME}`);
    scroolWindowToTop()
  }, [0])

  return (
    <div>
      <Link className="cursor-pointer" to={HOME}>
        <img onContextMenu={(event) => { event.preventDefault(); }}
          src={logo}
          className="w-20 h-20 object-contain object-center mx-auto my-4 "
          alt="logo"
        />
      </Link>
      <CardComponent title={"Sign In"}>
        <LoginForm />
      </CardComponent>
    </div>
  );
};
export default SignIn;
