import { SaveOutlined } from '@mui/icons-material'
import { Box, Button, Card, CardActionArea, CardContent, CardHeader, Stack, TextField, Typography } from '@mui/material'
import React from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useCreateBankAccountMutation } from '../../app/services/Api'
import InputFlatBorder from '../../components/common/InputFlatBorder'
import ButtonFlat from '../../components/ButtonFlat'

const Add = () => {
    const [createBankAccount, { isLoading, error }] = useCreateBankAccountMutation()
    const { control, handleSubmit, formState: { errors }, } = useForm(
        {
            defaultValues: {
                code: '',
                country: 'cm',

                bankName: '',
                bankBranch: '',
                bankAddress: '',

                accountHolderName: '',
                accountHolderAddress: '',

                accountCodeBank: '',
                accountCodeBranch: '',
                accountCodeAccount: '',
                accountCodeKey: '',
            },
        }
    );
    const handleCreateAccountBanks = (data) => {
        createBankAccount(getData(data))
    }
    function getData(data) {
        return {
            "code": data?.code,
            "country": data?.country,
            "bank": {
                "name": data?.bankName,
                "branch": data?.bankBranch,
                "address": data?.bankAddress,
            },
            "accountHolder": {
                "name": data?.accountHolderName,
                "address": data?.accountHolderAddress
            },
            "accountCode": {
                "bank": data?.accountCodeBank,
                "branch": data?.accountCodeBranch,
                "account": data?.accountCodeAccount,
                "key": data?.accountCodeKey
            }
        }
    }
    return (
        < >
            <Typography  >{`Register new Bank`}</Typography>
            <Controller
                control={control}
                rules={{
                    required: true,
                }}
                render={({ field: { onChange, onBlur, value } }) => (
                    <InputFlatBorder
                        label={"code"}
                        autoCorrect
                        placeholder={"code"}
                        value={value}
                        onChange={onChange}
                        onBlur={onBlur}
                        variant='standard'
                        type="text"                        
                    />
                )}
                name="code"
            />
            <Controller
                control={control}
                rules={{
                    required: true,
                }}
                render={({ field: { onChange, onBlur, value } }) => (
                    <InputFlatBorder
                        label={"country"}
                        placeholder={"country"}
                        value={value}
                        onChange={onChange}
                        variant='standard'
                        type="text"
                        onBlur={onBlur}
                    />
                )}
                name="country"
            />
            <Card >
                <CardHeader title={"Bank"} />
                <CardContent>
                    <Controller
                        control={control}
                        rules={{
                            required: true,
                        }}
                        render={({ field: { onChange, onBlur, value } }) => (
                            <InputFlatBorder
                                label={"name"}
                                placeholder='bank name'
                                value={value}
                                onChange={onChange}
                                variant='standard'
                                type="text"
                            />
                        )}
                        name="bankName"
                    />
                    <Controller
                        control={control}
                        rules={{
                            required: true,
                        }}
                        render={({ field: { onChange, onBlur, value } }) => (
                            <InputFlatBorder
                                label={"Branch"}
                                placeholder='bank branch'
                                value={value}
                                onChange={onChange}
                                variant='standard'
                                type="text"
                                onBlur={onBlur}
                            />
                        )}
                        name="bankBranch"
                    />
                    <Controller
                        control={control}
                        rules={{
                            required: true,
                        }}
                        render={({ field: { onChange, onBlur, value } }) => (
                            <InputFlatBorder
                                label={"Address"}
                                onBlur={onBlur}
                                placeholder='Bank address'
                                value={value}
                                onChange={onChange}
                                variant='standard'
                                type="text"
                            />
                        )}
                        name="bankAddress"
                    />
                </CardContent>
            </Card>
            <Card >
                <CardHeader title={"Account holder"} />
                <CardContent>
                    <Controller
                        control={control}
                        rules={{
                            required: true,
                        }}
                        render={({ field: { onChange, onBlur, value } }) => (
                            <InputFlatBorder
                                label={"name"}
                                autoCorrect
                                placeholder='account holder name'
                                value={value}
                                onBlur={onBlur}
                                onChange={onChange}
                                variant='standard'
                                type="text"

                            />
                        )}
                        name="accountHolderName"
                    />
                    <Controller
                        control={control}
                        rules={{
                            required: true,
                        }}
                        render={({ field: { onChange, onBlur, value } }) => (
                            <InputFlatBorder
                                label={"Address"}
                                autoCorrect
                                placeholder='Account holder address'
                                value={value}
                                onChange={onChange}
                                onBlur={onBlur}
                                variant='standard'
                                type="text"
                            />
                        )}
                        name="accountHolderAddress"
                    />
                </CardContent>
            </Card>
            <Card >
                <CardHeader title={"Account code"} />
                <CardContent>
                    <Controller
                        control={control}
                        rules={{
                            required: true,
                        }}
                        render={({ field: { onChange, onBlur, value } }) => (
                            <InputFlatBorder
                                label={"Bank"}
                                placeholder='Account code Bank'
                                value={value}
                                onChange={onChange}
                                variant='standard'
                                type="number"
                                onBlur={onBlur}
                            />
                        )}
                        name="accountCodeBank"
                    />
                    <Controller
                        control={control}
                        rules={{
                            required: true,
                        }}
                        render={({ field: { onChange, onBlur, value } }) => (
                            <InputFlatBorder
                                label={"Branch"}
                                placeholder='Account code branch'
                                value={value}
                                onChange={onChange}
                                variant='standard'
                                type="number"
                                onBlur={onBlur}
                            />
                        )}
                        name="accountCodeBranch"
                    />
                    <Controller
                        control={control}
                        rules={{
                            required: true,
                        }}
                        render={({ field: { onChange, onBlur, value } }) => (
                            <InputFlatBorder
                                label={"Account"}
                                placeholder='Account code account'
                                value={value}
                                onChange={onChange}
                                variant='standard'
                                type="number"
                                onBlur={onBlur}
                            />
                        )}
                        name="accountCodeAccount"
                    />
                    <Controller
                        control={control}
                        rules={{
                            required: true,
                        }}
                        render={({ field: { onChange, onBlur, value } }) => (
                            <InputFlatBorder
                                label={"Key"}
                                placeholder='Account code key'
                                value={value}
                                onChange={onChange}
                                variant='standard'
                                type="number"
                                onBlur={onBlur}
                            />
                        )}
                        name="accountCodeKey"
                    />
                </CardContent>
                <CardActionArea>
                    <Stack direction={'row'} justifyContent={'center'} >
                        <ButtonFlat
                        startIcon={<SaveOutlined />}
                        style={{
                            borderRadius: 20,
                            marginHorizontal: 0,
                            width: 250
                        }}
                        title = {"Save"}
                        onClick={handleSubmit(handleCreateAccountBanks)}
                        loading={isLoading} />
                    </Stack>
                </CardActionArea>
            </Card>
        </>
    );
}

export default Add
