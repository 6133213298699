import React, { useState } from 'react'
import { MagnifyingGlassIcon } from '@heroicons/react/24/outline'
import SelectMenu from '../common/SelectMenus'
import ModalFlat from '../ModalFlat'
import UpdateUsersForm from './UpdateUsersForm'
import CountryPicker from '../common/CountryPicker'

const people = [
    {
        name: 'Lindsay Walton',
        phone: '758362649',
        email: 'lindsay.walton@example.com',
        balance: '374 038 CFA',
        orders: '04',
        image:
            'https://images.unsplash.com/photo-1517841905240-472988babdf9?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
    },
    {
        name: 'Lindsay Walton',
        phone: '758362649',
        email: 'lindsay.walton@example.com',
        balance: '374 038 CFA',
        orders: '04',
        image:
            'https://images.unsplash.com/photo-1517841905240-472988babdf9?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
    },
    {
        name: 'Lindsay Walton',
        phone: '758362649',
        email: 'lindsay.walton@example.com',
        balance: '374 038 CFA',
        orders: '04',
        image:
            'https://images.unsplash.com/photo-1517841905240-472988babdf9?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
    },
    // More people...
]

const locationService = [
    { id: 1, name: "All" },
    { id: 2, name: "Silver" },
    { id: 3, name: "Standard", },
    { id: 4, name: "Platinum", },
    { id: 5, name: "Gold" },
]

const searchHandler = ()=>{}

const UsersTable = () => {
    let [isOpen, setIsOpen] = useState(false)

    function closeModal() {
        setIsOpen(false)
    }

    function openModal() {
        setIsOpen(true)
    }
  return (
    <div className="mt-8 flex flex-col">
      <div className="flex flex-col md:flex-row justify-end items-center space-x-5 right-0 ">
        <CountryPicker label={"Country"} />
        <SelectMenu
          style={{ maxWidth: "160px" }}
          defaultText={"Grade users"}
          data={locationService}
        />
        <SelectMenu
          style={{ maxWidth: "160px" }}
          defaultText={"Locations"}
          data={locationService}
        />
        <SelectMenu
          style={{ maxWidth: "160px" }}
          defaultText={"Roles"}
          data={locationService}
        />
      </div>
      <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
          <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
            <table className="min-w-full divide-y divide-gray-300">
              <thead className="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                  >
                    Name
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    Phone
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    Email
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    Net Balance
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    No Of Orders
                  </th>
                  <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                    <span className="sr-only">Edit</span>
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200 bg-white">
                {people.map((person) => (
                  <tr key={person.email}>
                    <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6">
                      <div className="flex items-center">
                        <div className="h-10 w-10 flex-shrink-0">
                          <img
                            onContextMenu={(event) => {
                              event.preventDefault();
                            }}
                            className="h-10 w-10 rounded-md"
                            src={person.image}
                            alt=""
                          />
                        </div>
                        <div className="ml-4">
                          <div className="font-medium text-gray-900">
                            {person.name}
                          </div>
                        </div>
                      </div>
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                      <div className="text-gray-500">{person.phone}</div>
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                      <div className="text-gray-900">{person.email}</div>
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                      <div className="text-gray-900">{person.balance}</div>
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                      <div className="text-gray-900">{person.orders}</div>
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                      {person.role}
                    </td>
                    <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm space-x-3 font-medium sm:pr-6">
                      <a
                        onClick={openModal}
                        className="text-[#D90429] hover:text-fuchsia-900 "
                      >
                        Edit<span className="sr-only">, {person.name}</span>
                      </a>
                      <a href="#" className="text-red-600 hover:text-red-900">
                        Delete<span className="sr-only">, {person.name}</span>
                      </a>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <ModalFlat
        isOpen={isOpen}
        children={<UpdateUsersForm />}
        title={"Update User"}
        closeModal={closeModal}
      />
    </div>
  );
}

export default UsersTable