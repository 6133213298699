import React from 'react'
import HeadingFour from '../../components/common/HeadingFour'
import TextSmallGray from '../../components/common/TextSmallGray'
import TextSmall from '../../components/common/TextSmall'
import userImg from '../../assets/images/profileImage.jpeg'
import { isRealValue } from '../../utilities'
import { useSelector } from 'react-redux'
import { MEDIA_URL } from '../../app/services'
import { useTranslation } from 'react-i18next'

const UserAvatarSection = () => {
  const { InfoAccount } = useSelector(state => state?.auth);
  const { t } = useTranslation();

  return (
    <div className="flex flex-row items-center justify-between h-full ">
      <img
        onContextMenu={(event) => {
          event.preventDefault();
        }}
        src={
          isRealValue(InfoAccount?.profilePicture)
            ? `${MEDIA_URL}${InfoAccount?.profilePicture}`
            : userImg
        }
        className="w-16 h-16 rounded-full"
        alt="user avatar logo"
      />
      <div className="h-[fit-content] flex-1 ml-2 flex flex-col justify-start items-start md:flex-row md:justify-between md:items-center">
        <div className="">
          <HeadingFour
            content={`${InfoAccount?.firstName} ${InfoAccount?.lastName}`}
          />
          <div className="flex flex-row items-center justify-start">
            <TextSmallGray content={t("balance")} /> :
            <TextSmall
              content={InfoAccount?.customer?.balanceDisplayed}
              style={{ color: `#D90429`, marginLeft: 4 }}
            />
          </div>
          <div className="flex flex-row items-center justify-start">
            <TextSmallGray content={t("available Balance")} /> :
            <TextSmall
              content={InfoAccount?.customer?.availableBalanceDisplayed}
              style={{ color: `#D90429`, marginLeft: 4 }}
            />
          </div>
          <div className="flex flex-row items-center justify-start">
            <TextSmallGray content={t("hold amount")} /> :
            <TextSmall
              content={InfoAccount?.customer?.holdAmountDisplayed}
              style={{ color: `#D90429`, marginLeft: 4 }}
            />
          </div>
        </div>
        <HeadingFour
          style={{ color: "silver" }}
          content={InfoAccount?.grade?.toUpperCase()}
        />
      </div>
    </div>
  );
}

export default UserAvatarSection