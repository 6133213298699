import React from 'react'
import './styles.css'
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { SECURE_CHECKOUT, SIGN_IN } from '../../constants/routes';
import EnhancedTable from './CartTable';
import { useBookmarkCardItemsQuery, useDeleteBookmarkCardItemMutation } from '../../app/services/Api';
import { useFormatter } from '../../app/hooks/useFormatter';
import LoadingButton from '../../components/common/LoadingButton';
import { useTranslation } from 'react-i18next';
import { useNotifications } from '../../app/hooks/useNotifications';
import { useLocalDbHook, useRedirection } from '../../app/localDatabase/useLocalDbHook';
import { scroolWindowToTop, toString } from '../../utilities';
import LoadingComponent from '../../components/LoadingComponent';

const CartCheckout = () => {
  useLocalDbHook()
  const { setReturnLinkForAfterLogin } = useRedirection()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { dangerNotification, successNotification } = useNotifications();
  const { isLogged } = useSelector(state => state.auth)
  const { innerWidth, innerHeight } = window;
  const { totalAmountSelected, cartItemsSelected } = useSelector(state => state.bookmarkCard)
  const { isFetching: loadingCardUser } = useBookmarkCardItemsQuery("", { skip: !isLogged, refetchOnReconnect: true, refetchOnMountOrArgChange: true })

  const processToPayment = () => {
    const state = { state: { orderTmp: produceTmpOrder() } }
    if (!isLogged) {
      setReturnLinkForAfterLogin(`${SECURE_CHECKOUT}`, toString(state));
      navigate(`${SIGN_IN}`)
    } else {
      navigate(`${SECURE_CHECKOUT}`, state)
    }
  }

  const [deleteBookmarkCardItem, { isLoading: loadingDelete }] = useDeleteBookmarkCardItemMutation();

  const deleteCartItem = (data) =>
    deleteBookmarkCardItem(data)
      .then((r) => {
        successNotification(t('sop'))
      })
      .catch(() => dangerNotification(t('eop')));

  const handleDeleteAllCarItem = () => {
    let varKeys = cartItemsSelected.map((item) => {
      return { variantKey: item.variantKey, route: item.route };
    });
    deleteCartItem({ variantKey: varKeys });
  };
  const produceTmpOrder = () => {
    return {
      order: {
        totalAmount: totalAmountSelected,
        pickupLocation: "",
        items: cartItemsSelected?.map((item) => {
          return {
            itemKey: item?.key,
            variantKey: item?.variantKey,
            store: item?.store,
            route: item?.route,
            unitPrice: item.unitPrice,
            quantity: item.quantity,
            totalPrice: item.unitPrice * item.quantity
          }
        })
      }
    }
  }
  const createOrder = () => processToPayment()
  const { formatPrice } = useFormatter()

  React.useEffect(() => {
    scroolWindowToTop()
    window.addEventListener("resize", updateDimensions);
    return _ => {
      window.removeEventListener("resize", updateDimensions);
    }
  })

  const [screen, setScreen] = React.useState({
    height: innerHeight,
    width: innerWidth
  })

  const updateDimensions = () => {
    setScreen({
      height: innerHeight,
      width: innerWidth
    })
  }


  return (
    <main className="px-4 pt-16 pb-24 mx-auto max-w-7xl sm:px-6 lg:max-w-full lg:px-8 ">
      <div className="flex flex-col items-start justify-between my-2 sm:flex-row sm:items-center">
        <h1 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
          {t("shop_cart")}
        </h1>
        <div className="flex items-center justify-end mt-4 space-x-2 sm:mt-0 sm:ml-16">
          <div className="flex items-center justify-between">
            <dt className="mr-2 text-sm text-gray-600">{t("slctd_pdt")}: </dt>
            <dd className="text-sm font-medium text-[#D90429]">
              {cartItemsSelected.length}
            </dd>
          </div>
          <div className="flex items-center justify-between border-gray-200">
            <dt className="text-base font-medium text-gray-900">Total: </dt>
            <dd className="text-base font-medium text-[#D90429]">
              {formatPrice(totalAmountSelected)}
            </dd>
          </div>
        </div>
      </div>
      <div className="mt-12 lg:items-start gap-x-5">
        <section className="mb-2" aria-labelledby="cart-heading">
          <EnhancedTable />
        </section>
        <section
          aria-labelledby="summary-heading"
          className="w-full px-4 py-6 mt-16 space-x-4 rounded-lg md:flex md:items-center md:justify-end bg-gray-50 sm:p-6 lg:mt-0 lg:p-8"
        >
          {totalAmountSelected > 0 && (
            <span
              onClick={handleDeleteAllCarItem}
              className="cursor-pointer hover:underline hover:text-[#D90429] text-red-800"
            >
              {t("del")}
            </span>
          )}
          <div className="flex items-center justify-between">
            <dt className="mr-2 text-sm text-gray-600">{t("slctd_pdt")}: </dt>
            <dd className="text-sm font-medium text-[#D90429]">
              {cartItemsSelected.length}
            </dd>
          </div>
          <div className="flex items-center justify-between border-gray-200">
            <dt className="text-base font-medium text-gray-900">Total: </dt>
            <dd className="text-base font-medium text-[#D90429]">
              {formatPrice(totalAmountSelected)}
            </dd>
          </div>
          <div>
            <LoadingButton
              title={t("Checkout")}
              style={{ fontWeight: "bold" }}
              onClick={createOrder}
              disabled={totalAmountSelected > 0 ? false : true}
            />
          </div>
        </section>
      </div>
      <LoadingComponent open={loadingCardUser} />
    </main>
  );
}

export default React.memo(CartCheckout)