import React from "react";
import { isRealValue } from "../../utilities";

const classNames = (...classes) => {
  return classes.filter(Boolean).join(" ");
};

const ViewSeaOrAirPrice = ({ item, seaOrAir }) => {
  return (
    <div className="w-[100%]" style={{ flex: 1, justifyContent: "flex-start" }}>
      {
        (isRealValue(item?.reducedPrice) || isRealValue(item?.basePrice)) && (
          <div>
            {seaOrAir ? (
              <div className="flex flex-row items-center justify-start space-y-1 truncate ">
                {isRealValue(item?.reducedPrice) &&
                  <p className="text-md md:text-xl lg:text-xl line-clamp-1 truncate font-bold tracking-tight text-[var(--primary)]">
                    {item?.reducedPrice?.cm?.seaPriceDisplayed}{` `}
                  </p>
                }
                <p
                  className={classNames(
                    isRealValue(item?.reducedPrice)
                      ? " line-through  text-gray-500 font-normal text-sm"
                      : "text-md md:text-xl lg:text-xl ",
                    " truncate font-bold tracking-tight text-[var(--primary)] "
                  )}
                >
                  {item?.basePrice?.cm?.seaPriceDisplayed}
                </p>
              </div>
            ) : (
              <div className="flex flex-row items-center justify-start truncate ">
                {isRealValue(item?.reducedPrice) &&
                  <p className="text-md md:text-xl lg:text-xl line-clamp-1 truncate font-bold tracking-tight text-[var(--primary)]">
                    {item?.reducedPrice?.cm?.airPriceDisplayed}
                  </p>
                }
                <p
                  className={classNames(
                    isRealValue(item?.reducedPrice)
                      ? " line-through text-gray-500 font-normal text-sm "
                      : "text-md md:text-xl lg:text-xl ",
                    " truncate font-bold tracking-tight text-[var(--primary)] "
                  )}
                >
                  {` `}
                  {item?.basePrice?.cm?.airPriceDisplayed}
                </p>
              </div>
            )}
          </div>
        )}
    </div>
  );
};

export default ViewSeaOrAirPrice;
