import React from 'react'
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useFormatter } from '../../../app/hooks/useFormatter';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const HoldsTable = () => {
  const { holds } = useSelector(state => state.users)
  const { t } = useTranslation()
  const { asDate } = useFormatter()

  return (
    <div className="flex flex-col mt-8">
      <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
          <div className="relative overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
            <table className="min-w-full divide-y divide-gray-300 table-fixed">
              <thead className="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    className="min-w-[12rem] py-3.5 pr-3 text-left text-sm font-semibold text-gray-900"
                  >
                    {t("Date")}
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    {t("Amount")}
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    {t("Validated")}
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {holds?.map((item, index) => (
                  <tr
                    key={index}
                    style={{ cursor: "pointer" }}
                  >
                    <td
                      className={classNames(
                        "whitespace-nowrap py-4 pr-3 text-sm font-medium",
                        "text-gray-900"
                      )}
                    >
                      {asDate(item?.created)}
                    </td>
                    <td className="px-3 py-4 text-sm text-gray-500 whitespace-nowrap">
                      {item?.amountDisplayed}
                    </td>
                    <td className="px-3 py-4 text-sm text-gray-500 whitespace-nowrap">
                      {`${t(item?.isValid ? "Yes" : "No")}`}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HoldsTable