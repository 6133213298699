import {
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import "./styles.css";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import PaymentIcon from "@mui/icons-material/Payment";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import PullToRefresh from "react-simple-pull-to-refresh";
import React from "react";
import { Box } from "@mui/system";
import {
  useGetDeliverableAddressByUserQuery,
  useGetRequiredDepositQuery,
} from "../../app/services/Api";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  useCodeValuesQuery,
  useInfosAccountBankQuery,
  useViewCardsQuery,
} from "../../app/services/Api";
import { useSelector } from "react-redux";
import { CART, CONDITIONS, SIGN_IN } from "../../constants/routes";
import { WalletOutlined } from "@mui/icons-material";
import CreditCardComponent from "../../components/CreditCardComponent";
import WalletPayment from "../../components/CheckoutSecurely/WalletPayment";
import BranchesSelect from "../../components/CheckoutSecurely/BranchesSelect";
import BanksSelect from "../../components/CheckoutSecurely/BanksSelect";
import {
  useGetAllLocationsBankQuery,
  useGetAllLocationsBranchsQuery,
  useGetAllLocationsByAllUsersQuery,
} from "../../app/services/Api";
import { useAllAccountBankQuery } from "../../app/services/Api";
import { getLocationService, putOperationTransaction } from "../../app/hooks";
import { useFormatter } from "../../app/hooks/useFormatter";
import { isRealValue, scroolWindowToTop, toString } from "../../utilities";
import SelectLocations from "../../components/CheckoutSecurely/SelectLocations";
import LoadingComponent from "../../components/LoadingComponent";
import { useTranslation } from "react-i18next";
import { handleHeaderType } from "../../app/features/authSlice";
import { dispatch } from "../../app";
import { handleSetCartOrder } from "../../app/features/bookmarkCardItemsSlice";

const SecureCheckout = () => {
  const navigate = useNavigate();
  const { formatPrice } = useFormatter();
  const [operation, setOperation] = React.useState("");
  const { user, isLogged, InfoAccount } = useSelector((state) => state?.auth);
  const { order, searchCart } = useSelector((state) => state.bookmarkCard);
  const { requireDeposit } = useSelector((state) => state.orders);
  const { cardList } = useSelector((state) => state.stripe);
  const { t } = useTranslation()

  const { state } = useLocation()
  const orderTmp = state?.orderTmp ?? null

  React.useEffect(() => {
    if (!isLogged) navigate(`${SIGN_IN}`)
    dispatch(handleHeaderType({ headerType: "none", isStore: false, header: true, checkout: false }))
    scroolWindowToTop()
    if (isRealValue(orderTmp)) {
      dispatch(handleSetCartOrder(orderTmp))
    }
    else navigate(`${CART}`)

  }, [0])



  const { isFetching: isLoadingRD, refetch: refetchRD } = useGetRequiredDepositQuery({ totalAmount: order?.order?.totalAmount });
  const { isFetching: isLoadingI, refetch: refetchI } = useInfosAccountBankQuery();
  const { isFetching: isLoadingLS, refetch: refetchLS } = useCodeValuesQuery(getLocationService);
  const { isFetching: isLoadingVC, refetch: refetchVC } = useGetAllLocationsByAllUsersQuery({ arrayService: toString(["pickup"]) });
  const { isFetching: isLoadingA, refetch: refetchA } = useGetDeliverableAddressByUserQuery(order?.order?.totalAmount);
  const { isFetching: isLoadingCC, refetch: refetchCC } = useViewCardsQuery(null, { skip: !(user?.role === "customer" || user?.role === "user") });
  const { isFetching: isLoadingSV, refetch: refetchSV } = useCodeValuesQuery(putOperationTransaction, { refetchOnReconnect: true });
  const { isFetching: isLoadingBank, refetch: refetchBank } = useGetAllLocationsBankQuery({ arrayService: toString(["cash in"]), type: "bank", });
  const { isFetching: isLoadingBranch, refetch: refetchBranch } = useGetAllLocationsBranchsQuery({ arrayService: toString(["cash in"]), type: "branch", });
  const { isFetching: isLoadingB, refetch: refetchB } = useAllAccountBankQuery();
  const refetch = () => {
    refetchB();
    refetchI();
    refetchA();
    refetchRD();
    refetchCC();
    refetchVC();
    refetchSV();
    refetchBank();
    refetchBranch();
    refetchLS();
  };

  const backOrderPage = () => navigate(`${CART}`);
  const handleSelectOption = (e, value) => { e.preventDefault(); setOperation(value); }

  return (
    <div style={{ margin: `10px 0px` }}>
      {/* <PullToRefresh
        onRefresh={refetch}
        canFetchMore={false }
      > */}
      <Box>
        <Grid container direction="column">
          <Grid
            item
            container
            justifyContent={"flex-start"}
            my={0.5}
            alignItems="center"
            className="pointer"
            onClick={backOrderPage}
          >
            <ArrowBackIosIcon sx={{ color: "#00000050", fontSize: 24 }} />
            <Typography variant="h6" fontSize={18} Size color="#00000050">
              {t("Back_to_cart")}
            </Typography>
          </Grid>
          {
            <div item container p={0} spacing={0}>
              <Grid item xs={12}>
                <Box
                  // className="head-wrapper"
                  height={45}
                  alignItems={"center"}
                  flexDirection={"row"}
                  display={"flex"}
                  justifyContent={"flex-end"}
                >
                  {/* <Box mx={2}>
                    <Divider
                      color={"#F0DEF3"}
                      orientation={"vertical"}
                      height={"95%"}
                      width={1}
                    />
                    <Typography
                      
                      variant="h6"
                      fontSize={14}
                      Size
                      color="#0A0909"
                    >
                      {t("Edit_order")}
                    </Typography>
                  </Box> */}
                  <Box mx={2}>
                    <Divider
                      color={"#F0DEF3"}
                      orientation={"vertical"}
                      height={"95%"}
                    />
                    <Typography variant="h6" fontSize={14} Size color="#0A0909">
                      {t("delivery_area")}
                    </Typography>
                  </Box>
                  <Box mx={2}>
                    <Typography variant="h6" fontSize={16} Size color="#0A0909">
                      {t("Total_amount")}
                    </Typography>
                    <Typography variant="h6" fontSize={14} Size color="#D90429">
                      {formatPrice(order?.order?.totalAmount)}
                    </Typography>
                  </Box>
                </Box>
              </Grid>
              <Grid
                item
                xs={12}
                mt={2}
                borderBottom={1}
                borderColor={"#707070"}
                py={2}
              >
                <Typography
                  pl={1}
                  variant="h4"
                  fontSize={26}
                  fontStyle={"normal"}
                  fontWeight={"500"}
                  Size
                  color="#0A0909"
                >
                  {t(
                    "Please_review_your_order_and_choose_your_preferred_method_payment"
                  )}
                </Typography>
              </Grid>
              <PullToRefresh onRefresh={refetch} canFetchMore={true}>
                <SelectLocations />
                {order?.order?.pickupLocation.length > 0 && (
                  <div pt={3}>
                    <div className="my-2 ">
                      <div className="border-[1px] shadow-sm rounded-md border-slate-300 bg-white py-3 ">
                        <Typography
                          variant="h6"
                          fontSize={15}
                          fontStyle={"normal"}
                          fontWeight={"500"}
                          Size
                          color="#0A0909"
                        ></Typography>
                        <h5 className="px-3 font-medium text-gray-900 text-md ">
                          {t("Choose_a_payment_method")}
                        </h5>
                        <Grid item xs={12}>
                          <RadioGroup
                            aria-labelledby="demo-radio-buttons-group-label"
                            // defaultValue={operations?.payment}
                            value={operation}
                            name="radio-buttons-group"
                            onChange={handleSelectOption}
                          >
                            {InfoAccount?.customer?.availableBalance >
                              order?.order?.totalAmount && (
                              <Box
                                pl={1}
                                display={"flex"}
                                flexDirection={"row"}
                                alignItems={"center"}
                                justifyContent={"flex-start"}
                              >
                                <FormControlLabel
                                  control={<Radio color="error" />}
                                  value={"My Account"}
                                  // disabled={
                                  //
                                  //   order?.order?.pickupLocation.length < 0
                                  // }
                                  label={t("my_acc")}
                                />
                                <WalletOutlined color={"black"} />
                              </Box>
                            )}
                            {operation === "My Account" && <WalletPayment />}
                            <Divider color={"#F0DEF3"} width={"100%"} />
                            <Box
                              pl={1}
                              display={"flex"}
                              flexDirection={"row"}
                              alignItems={"center"}
                              justifyContent={"flex-start"}
                            >
                              <FormControlLabel
                                control={<Radio color="error" />}
                                value={"Credit Card"}
                                // disabled={
                                //   user?.role === "customer" ? false : true
                                // }
                                disabled={cardList?.length > 0 ? false : true}
                                label={t("credit carts")}
                              />
                              <PaymentIcon color={"black"} />
                            </Box>
                            {operation === "Credit Card" && (
                              <CreditCardComponent
                                totalAmount={order?.order?.totalAmount}
                              />
                            )}
                            <Divider color={"#F0DEF3"} width={"100%"} />
                            <Box
                              pl={1}
                              display={"flex"}
                              flexDirection={"column"}
                              alignItems={"flex-start"}
                              justifyContent={"flex-start"}
                            >
                              <FormControlLabel
                                control={<Radio color="error" />}
                                value="Pay at branch"
                                disabled={
                                  order?.order?.pickupLocation.length < 0
                                }
                                label={t("pay_branch")}
                              />
                              {operation === "Pay at branch" && (
                                <Box
                                  display={"flex"}
                                  flexDirection={"row"}
                                  alignItems={"flex-start"}
                                  justifyContent={"flex-start"}
                                >
                                  <InfoOutlinedIcon
                                    color={"var(--primary)"}
                                    sx={{
                                      fontSize: 18,
                                      color: "var(--primary)",
                                    }}
                                  />
                                  <Typography
                                    variant="caption"
                                    fontStyle={"normal"}
                                    ml={2}
                                    Size
                                    color="#979797"
                                  >
                                    {t(
                                      "The_order_needs_to_be_paid_into_one_of_our_bank_accounts_within_Xhrs",
                                      { hour: 48 }
                                    )}
                                    <br />
                                    {t(
                                      "If_you_do_not_pay_your_order_will_be_cancelled"
                                    )}
                                  </Typography>
                                </Box>
                              )}
                            </Box>
                            {operation === "Pay at branch" && (
                              <BranchesSelect />
                            )}
                            <Divider color={"#F0DEF3"} width={"100%"} />
                            <Box
                              pl={1}
                              display={"flex"}
                              flexDirection={"column"}
                              alignItems={"flex-start"}
                              justifyContent={"flex-start"}
                            >
                              <FormControlLabel
                                control={<Radio color="error" />}
                                value="Pay at bank"
                                disabled={
                                  order?.order?.pickupLocation.length < 0
                                }
                                label={t("pay_bank")}
                              />
                              {operation === "Pay at bank" && (
                                <Box
                                  display={"flex"}
                                  flexDirection={"row"}
                                  alignItems={"flex-start"}
                                  justifyContent={"flex-start"}
                                >
                                  <InfoOutlinedIcon
                                    color={"var(--primary)"}
                                    sx={{
                                      fontSize: 18,
                                      color: "var(--primary)",
                                    }}
                                  />
                                  <Typography
                                    variant="caption"
                                    fontStyle={"normal"}
                                    ml={2}
                                    Size
                                    color="#979797"
                                  >
                                    {t(
                                      "The_order_needs_to_be_paid_into_one_of_our_bank_accounts_within_Xhrs",
                                      { hour: 48 }
                                    )}
                                    <br />
                                    {t(
                                      "If_you_do_not_pay_your_order_will_be_cancelled"
                                    )}
                                    <br />
                                    {t(
                                      "Details_to_concerning_the_payment_and_the_link_to_our_bank_account_can_be_found_below_on_the_acknowledgement_page"
                                    )}
                                  </Typography>
                                </Box>
                              )}
                            </Box>
                            {operation === "Pay at bank" && (
                              <BanksSelect isLoadingBank={isLoadingBank} />
                            )}
                          </RadioGroup>
                        </Grid>
                        <Grid item xs={12} ml={3} height={20}></Grid>
                        <Divider color={"#F0DEF3"} width={"100%"} />
                        <Grid
                          container
                          item
                          alignItems={"center"}
                          justifyContent={"center"}
                          xs={12}
                        >
                          <Grid
                            container
                            alignItems={"center"}
                            justifyContent={"space-between"}
                            px={2}
                            borderBottom={1}
                            mb={2}
                            borderColor={"#70707057"}
                            width={"95%"}
                            item
                            xs={11.5}
                          >
                            <Grid
                              item
                              container
                              xs={12}
                              display={"flex"}
                              flexDirection={"row"}
                              alignItems={"center"}
                              justifyContent={"space-between"}
                            >
                              <Typography
                                variant="h6"
                                fontSize={20}
                                fontStyle={"normal"}
                                fontWeight={"600"}
                                Size
                                color="#0A0909"
                              >
                                {t("tot_price")}
                              </Typography>
                              <Typography
                                variant="h6"
                                fontSize={22}
                                textAlign={"right"}
                                fontStyle={"normal"}
                                fontWeight={"700"}
                                Size
                                color="#0A0909"
                              >
                                {formatPrice(order?.order?.totalAmount)}
                              </Typography>
                            </Grid>
                            <Grid
                              item
                              container
                              xs={12}
                              display={"flex"}
                              flexDirection={"row"}
                              alignItems={"center"}
                              justifyContent={"space-between"}
                            >
                              <Typography
                                variant="h6"
                                fontSize={14}
                                textAlign={"center"}
                                fontStyle={"normal"}
                                fontWeight={"500"}
                                Size
                                color="#979797"
                              >
                                {t("Minimum_payment_to_confirm_order")}
                              </Typography>
                              <Typography
                                variant="h6"
                                fontSize={18}
                                textAlign={"right"}
                                fontStyle={"normal"}
                                fontWeight={"500"}
                                Size
                                color="#0A0909"
                              >
                                {formatPrice(requireDeposit)}
                              </Typography>
                            </Grid>
                          </Grid>
                          <Grid pl={1} item xs={12}>
                            <Typography
                              variant="h6"
                              fontSize={14}
                              fontStyle={"normal"}
                              fontWeight={"500"}
                              Size
                              color="#0A0909"
                            >
                              {t(
                                "Once_your_order_has_been_confirmed_we_will_send_your_order_confirmation_message"
                              )}
                            </Typography>
                            <Box
                              display={"flex"}
                              flexDirection={"row"}
                              alignItems={"center"}
                              justifyContent={"flex-start"}
                            >
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    size="small"
                                    defaultChecked
                                    onChange={(e) => console.info(`CHANGING`)}
                                    onClick={(e) => console.info(`CHANGING ${e}`)}
                                    readOnly
                                    sx={{ color: "#D90429" }}
                                  />
                                }
                                sx={{ fontSize: 16 }}
                                value={""}
                              />
                              <Typography
                                variant="h6"
                                fontSize={14}
                                fontStyle={"normal"}
                                Size
                                color="#0A0909"
                              >
                                {t(
                                  "I_have_read_nad_accept_the_general_conditions_of_sales"
                                )}
                                <span>
                                  <Link to={CONDITIONS} target="_blank">
                                    <span className="text-[#D90429] text-sm font-semibold text-left my-2">
                                      {t("trm_n_conditions")}
                                      {"."}
                                    </span>
                                  </Link>
                                </span>
                              </Typography>
                            </Box>
                          </Grid>
                        </Grid>
                      </div>
                    </div>
                  </div>
                )}
              </PullToRefresh>
            </div>
          }
        </Grid>
      </Box>
      <LoadingComponent
        open={
          isLoadingB ||
          isLoadingBranch ||
          isLoadingBranch ||
          isLoadingRD ||
          isLoadingI ||
          isLoadingVC ||
          isLoadingVC ||
          isLoadingA ||
          isLoadingCC ||
          isLoadingSV
        }
      />
    </div>
  );
};

export default SecureCheckout;
