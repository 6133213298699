import React from 'react'
import useMutationApi from '../app/hooks/useMutationApi'
import { useFormatter } from '../app/hooks/useFormatter'
import { useTranslation } from 'react-i18next'
import { Controller, useForm } from 'react-hook-form'
import SelectMenu from './common/SelectMenus'
import LoadingButton from './common/LoadingButton'
import InputFlat from './InputFlat'
import { isRealValue } from '../utilities'

const UpdateItemToHomeForm = ({ code, closeModal }) => {
  const { t } = useTranslation()
  const { updateItemsHome, isLoadingUpdateItemsHomesByForeignLogistician } = useMutationApi()
  const { display } = useFormatter()

  const { control, handleSubmit, formState: { errors }, setError, clearErrors } = useForm({
    defaultValues: {
      active: "", nameEn: "", nameFr: ""
    }
  });

  const handleSubmitUpdateItem = (data) => {
    // console.log("********************", data)
    var sendRequest = true
    const active = data?.active?.value
    const name = { en: data?.nameEn, fr: data?.nameFr }

    const activeProvided = isRealValue(active)
    const nameEnProvided = isRealValue(name?.en)
    const nameFrProvided = isRealValue(name?.fr)

    var body = {}

    if (!activeProvided && !(nameEnProvided && nameFrProvided)) {
      setError("active")
      setError("nameEn")
      setError("nameFr")
      sendRequest = false
    } else {
      if (activeProvided) {
        body.active = active
        clearErrors("nameEn")
        clearErrors("nameFr")
        sendRequest = true
      }
      if (nameEnProvided && nameFrProvided) {
        body.name = name
        clearErrors("active")
        sendRequest = true
      }
      else if (nameEnProvided || nameFrProvided) {
        if (!nameEnProvided) {
          sendRequest = false
          setError("nameEn")
        }
        if (!nameFrProvided) {
          sendRequest = false
          setError("nameFr")
        }
      }
    }
    if (sendRequest) {
      updateItemsHome(body, code, () => closeModal())
    }
  }

  const data = [
    { name: { en: "Actived", fr: "Activé" }, value: "true" },
    { name: { en: "Disabled", fr: "Désactivé" }, value: "false" },
  ]

  return (
    <form
      className='reg-form'
      style={{ overflow: "scroll", overflowX: "unset", height: "auto", alignItems: "center" }}
      onSubmit={handleSubmit(handleSubmitUpdateItem)}
    >
      <Controller control={control} rules={{ required: false }}
        render={({ field: { onChange, value } }) => (
          <SelectMenu
            style={{ width: 200, marginBottom: 11 }}
            data={data}
            onChange={onChange}
            display={display}
            defaultText={t("active")}
            label={t("active")}
            selectedValues={value}
            errorProp={errors?.active}
          />
        )}
        name="active"
      />
      <Controller
        control={control}
        rules={{
          required: false,
        }}
        render={({ field: { onChange, value } }) => (
          <InputFlat
            error={errors.nameEn ? `${t('name')} ${t('en')}` : null}
            type='text'
            label={t("en")}
            style={{ fontSize: 16, marginBottom: 11 }}
            value={value}
            onChange={onChange}
          />
        )}
        name="nameEn"
      />
      <Controller
        control={control}
        rules={{
          required: false,
        }}
        render={({ field: { onChange, value } }) => (
          <InputFlat
            error={errors.nameFr ? `${t('name')} ${t('fr')}` : null}
            type='text'
            label={t("fr")}
            style={{ fontSize: 16, marginBottom: 11 }}
            value={value}
            onChange={onChange}
          />
        )}
        name="nameFr"
      />
      <LoadingButton
        style={{ width: "fit-content" }}
        title={t("Confirm")}
        isLoading={isLoadingUpdateItemsHomesByForeignLogistician}
      />
    </form>
  )
}

export default UpdateItemToHomeForm