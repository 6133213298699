import React, { useState } from 'react'

import locationImg from '../../assets/images/Address-bro.svg'
import SelectMenu from '../common/SelectMenus'
import ModalFlat from '../ModalFlat'
import LocationDetails from './LocationDetails'

const people = [
    {
        name: 'Leonard Krasner',
        handle: 'leonardkrasner',
    },
            
    {
        name: 'Floyd Miles',
        handle: 'floydmiles',
        imageUrl:
            '../../assets/images/Address-bro.svg',
    },
    {
        name: 'Emily Selman',
        handle: 'emilyselman',
        imageUrl:
            '../../assets/images/Address-bro.svg',
    },
    {
        name: 'Kristin Watson',
        handle: 'kristinwatson',
        imageUrl:
            '../../assets/images/Address-bro.svg',
    },
]

const locationType = [
    { id: 1, name: "All" },
    { id: 2, name: "Carrier" },
    { id: 3, name: "Deliverer" },
    { id: 4, name: "Warehouse" },
    { id: 5, name: "Branch" },
    { id: 6, name: "Bank" }
]

const locationService = [
    { id: 1, name: "All" },
    { id: 2, name: "Cash payment"},
    { id: 3, name: "Delivery", },
    { id: 4, name: "Cash receipt", },
    { id: 5, name: "Pickup" },
]




const LocationList = () => {
    let [isOpen, setIsOpen] = useState(false)

    function closeModal() {
        setIsOpen(false)
    }

    function openModal() {
        setIsOpen(true)
    }
  return (
      <div className='relative' >
            
              <div className="flex flex-col md:flex-row justify-end items-center space-x-5 right-0 " >
                    <SelectMenu style={{ maxWidth: "160px" }} defaultText={"Location Type"} data={locationType} />
                    <SelectMenu style={{ maxWidth: "160px" }} defaultText={"Location Service"} data={locationService} />
              </div>
            
          {/* <h3>Location List</h3> */}
          <div className="mt-6 flow-root">
              <ul role="list" className="-my-5 divide-y divide-gray-200">
                  {people.map((person) => (
                      <li key={person.handle} className="py-4 hover:bg-gray-100">
                          <div className="flex items-center space-x-4">
                              <div className="flex-shrink-0">
                                  <img onContextMenu = { (event)=> { event.preventDefault();    }  } className="h-14 w-14 rounded-full" src={locationImg} alt="" />
                              </div>
                              <div className="min-w-0 flex-1">
                                  <p className="truncate text-sm font-medium text-gray-900">{person.name}</p>
                                  <p className="truncate text-sm text-gray-500">{'@' + person.handle}</p>
                              </div>
                              <div>
                                  <a
                                      onClick={openModal}
                                      className="inline-flex items-center rounded-full cursor-pointer border border-gray-300 bg-white px-2.5 py-0.5 text-sm font-medium leading-5 text-gray-700 shadow-sm hover:bg-gray-50"
                                  >
                                      View
                                  </a>
                              </div>
                          </div>
                      </li>
                  ))}
              </ul>
          </div>
          <ModalFlat
              isOpen={isOpen}
              children={<LocationDetails />}
              title={"Location Details"}
              closeModal={closeModal} />
          {/* <div className="mt-6">
              <a
                  href="#"
                  className="flex w-full items-center justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50"
              >
                  View all
              </a>
          </div> */}
      </div>
  )
}

export default LocationList