import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    items: [],
    itemsFilter: [],
    attributes: [],
    search: "",
}

const bookmarkFavoriteItemsSlice = createSlice({
    name: 'bookmarkItems',
    initialState,
    reducers: {
        handleFavoriteItems(state, action) {
            state.items = action.payload
            state.itemsFilter = action.payload
            var attributes = []
            state.items.forEach((item) => { attributes = attributes.concat(item?.attributes) })
            state.attributes = getAttributes(attributes)

            function getAttributes(attributes = []) {
                let results = [];
                attributes.map((attribute) => {
                    let propertyKey = attribute.propertyKey;
                    let newValue = {
                        valueName: attribute.valueName,
                        valueKey: attribute.valueKey
                    };
                    if (results?.length === 0) {
                        results = Array.of({ propertyName: attribute.propertyName, propertyKey, values: [newValue] });
                    }
                    else {
                        let exist = false;
                        let results0 = results?.map(old => {
                            if (old.propertyKey === propertyKey) {
                                var values = old.values
                                values.push(newValue)
                                exist = true;
                                return { ...old, values }
                            }
                            else return old
                        });
                        results = results0;
                        if (!exist) results.push({ propertyName: attribute.propertyName, propertyKey, values: [newValue] });
                    }
                    return null;
                })
                return results;
            }
        },
    },

})

export const { 
    handleFavoriteItems,
   
} = bookmarkFavoriteItemsSlice.actions
export default bookmarkFavoriteItemsSlice