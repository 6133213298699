import { Divider, Stack } from '@mui/material'
import React from 'react'
import HeadingTwo from '../../components/common/HeadingTwo'
import HeadingThree from '../../components/common/HeadingThree'
import { useLocation, useNavigate } from 'react-router-dom'
import { useCodeValuesQuery } from '../../app/services/Api'
import { getOperationMethod, getOperationState, getOperationTransaction, getOrderOrderStatus } from '../../app/hooks'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import LoadingComponent from '../../components/LoadingComponent'
import LoadingButton from '../../components/common/LoadingButton'
import { isRealValue, scroolWindowToTop } from '../../utilities'
import { DASHBOARD, HOME } from '../../constants/routes'
import { useFormatter } from '../../app/hooks/useFormatter'
import { handleHeaderType } from '../../app/features/authSlice'
import { dispatch } from '../../app'
import { handleResetCartItemsSelected } from '../../app/features/bookmarkCardItemsSlice'
import { IoCheckmarkCircleOutline } from 'react-icons/io5'

const Acknowledgement = () => {
  const navigation = useNavigate()
  const location = useLocation()
  const state = location.state ?? null
  const { user, isLogged } = useSelector(state => state.auth)
  const { lng } = useSelector(state => state.app)
  const error = state?.error ?? false
  const wallet = state?.wallet ?? null
  const payment = state?.payment ?? null
  const order = state?.order ?? null
  const translation = state?.translation ?? null;
  const bank = state?.location?.bank ?? null
  const branch = state?.location?.branch ?? null

  React.useEffect(() => {
    if (!isLogged || !(user.role === "customer" || user.role === "user") || !isRealValue(state)) navigation(HOME)
    if (!isLogged) navigation(`${DASHBOARD}orders/history`)
    dispatch(handleHeaderType({ headerType: "none", isStore: false, header: true, checkout: false }))
    dispatch(handleResetCartItemsSelected())
    scroolWindowToTop()
  }, [0])

  const isConfirmationOrder = (!error && isRealValue(order)) && !isRealValue(payment) ? true : false
  const isPaymentOrderStripe = (!error && isRealValue(payment) && !isRealValue(order)) ? true : false
  const isPaymentOrderWallet = (!error && isRealValue(wallet)) ? true : false

  const { isFetching: isLoading } = useCodeValuesQuery(getOrderOrderStatus, { skip: !isPaymentOrderWallet })
  const { isFetching: isLoadingOT } = useCodeValuesQuery(getOperationTransaction, { skip: !isPaymentOrderWallet })
  const { isFetching: isLoadingOM } = useCodeValuesQuery(getOperationMethod, { skip: !isPaymentOrderWallet })
  const { isFetching: isLoadingOS } = useCodeValuesQuery(getOperationState, { skip: !isPaymentOrderWallet })

  const { t } = useTranslation()
  const { display, formatPrice, formatAmount, getOrderOrderStatusData, getOperationMethodData, getOperationTransactionData, getOperationStateData } = useFormatter()
  const { isLoading: isLoadingCV } = useCodeValuesQuery(getOrderOrderStatus)

  const SeeOrderButton = () => (
    <LoadingButton
      style={{ marginTop: 30, marginBottom: 30 }}
      title={t("View_my_orders")}
      onClick={() => navigation(`${DASHBOARD}orders/history`)}
    />
  )

  const StackViewLine = ({ title1, title2 }) => (
    <Stack my={1.5} direction="row" justifyContent={"space-between"} alignItems={"center"} >
      <HeadingTwo content={title1} />
      <HeadingThree content={title2} />
    </Stack>
  )

  const ErrorDetails = ({ payment }) => (
    <div>
      {isRealValue(payment) &&
        <div>
          {/* card_declined */}
          <StackViewLine title1={`${t(payment?.data?.code)}`} title2={payment?.translation[lng]} />
          <StackViewLine title1={t("funding")} title2={payment?.data?.payment_method?.card?.funding} />
          <StackViewLine title1={t("Message")} title2={payment?.data?.raw?.message} />
          <StackViewLine title1={"\n"} title2={""} />
          <StackViewLine title1={t("payment_method")} title2={payment?.data?.payment_method?.card?.brand} />
          <StackViewLine title1={t("country")} title2={payment?.data?.payment_method?.card?.country} />
          <StackViewLine title1={t("exp_month")} title2={payment?.data?.payment_method?.card?.exp_month} />
          <StackViewLine title1={t("exp_year")} title2={payment?.data?.payment_method?.card?.exp_year} />
          <StackViewLine title1={t("last4")} title2={payment?.data?.payment_method?.card?.last4} />
          <StackViewLine title1={t('currency')} title2={payment?.data?.payment_intent?.currency} />
          <StackViewLine title1={"\n"} title2={""} />
          <StackViewLine title1={t('order_reference')} title2={payment?.data?.payment_intent?.description?.slice(-4)} />
          <StackViewLine title1={t('Total_amount')} title2={formatPrice(Number(payment?.data?.payment_intent?.metadata?.originalAmount))} />

          <StackViewLine title1={""} title2={""} />
          <SeeOrderButton />

        </div>
      }
    </div>
  )

  const ConfirmationMessage = ({ message }) => (
    <React.Fragment>
      <div className="flex flex-col md:flex-row items-center space-x-1 justify-center py-5 border-b border-gray-500 ">
        <IoCheckmarkCircleOutline className="h-24 w-24 text-[#D90420]  " />
        <div>
          <h2 className="text-2xl text-[#D90420]">{translation[lng]} </h2>
        </div>
      </div>
    </React.Fragment>
  );

  const ConfirmationOrderOnly = ({ order }) => (
    <div>
      <ConfirmationMessage message={order?.translation} />

      <StackViewLine
        title1={t("Your_order_is_status")}
        title2={display(
          getOrderOrderStatusData?.find((v) => v.value === order?.status)
        )}
      />
      <StackViewLine title1={t("order_reference")} title2={order?.ref} />
      <StackViewLine
        title1={t("the_order_need_to_be_pay_at")}
        title2={`48 ${t("hours")}`}
      />
      <StackViewLine
        title1={t("If_you_do_not_pay_within_the_required_time")}
        title2={t("The_order_will_be_canceled")}
      />
      {isRealValue(branch) && (
        <span className="flex items-center">
          <span className="flex flex-col text-sm">
            <span className="font-medium text-gray-900">
              {branch?.name[lng]}
            </span>
            <span className="text-gray-500">
              <span className="block text-gray-600 sm:inline">
                {branch?.address[lng]}
              </span>{" "}
              <br />
              <span className="block sm:inline">
                {branch?.openingHour[lng]}
              </span>{" "}
              <br />
              <span className="block sm:inline">tel: {branch?.phone}</span>
              <br />
              <span className="block sm:inline">
                {`${t("Total_amount")} : ${order?.totalAmountDisplayed}`}
              </span>
              <br />
              <span className="block sm:inline">
                {`${t("Amt_deposit")} : ${order?.requiredDepositDisplayed}`}
              </span>
              <br />
            </span>
          </span>
        </span>
      )}
      {isRealValue(bank) && (
        <span className="flex flex-col ml-3">
          <span className={"text-gray-900 block text-sm font-medium"}>
            {t("bank")}: {bank.bank.name}
          </span>
          <span className={"text-gray-500 block text-sm"}>
            {`${t("benef")} : ${bank?.accountHolder?.name}`}
            <br />
            {`${t("acc_num")} : ${bank?.accountCode?.bank}-${bank?.accountCode?.branch
              }-${bank?.accountCode?.account}-${bank?.accountCode?.key}`}
            <br />
            {`${t("Total_amount")} : ${order?.totalAmountDisplayed}`}
            <br />
            {`${t("Amt_deposit")} : ${order?.requiredDepositDisplayed}`}
          </span>
        </span>
      )}
      <SeeOrderButton />
    </div>
  );

  const PaymentOrderStripe = ({ payment }) => (
    <div>
      <ConfirmationMessage />
      <StackViewLine
        title1={`${t("Your_order_is_status")} (${t(payment?.status)})`}
        title2={""}
      />
      <StackViewLine
        title1={t("order_reference")}
        title2={`${payment?.description?.slice(-4)}`}
      />
      <StackViewLine
        title1={`${t("the_order_need_to_be_pay_at")}`}
        title2={`48 ${t("hours")}`}
      />
      <StackViewLine
        title1={`${t("If_you_do_not_pay_within_the_required_time")} : `}
        title2={t("The_order_will_be_canceled")}
      />
      {isRealValue(payment?.payment_method_details?.type) && (
        <StackViewLine
          title1={" Please note that:"}
          title2={payment?.payment_method_details?.type}
        />
      )}
      <StackViewLine title1={t("payment_details")} title2={""} />
      <StackViewLine
        title1={`${t("payment_method")} :`}
        title2={
          isRealValue(payment?.payment_method_details?.card?.brand)
            ? payment?.payment_method_details?.card?.brand
            : t("manual")
        }
      />
      <StackViewLine
        title1={t("Total_amount")}
        title2={
          payment?.currency === "eur"
            ? formatPrice(payment?.amount)
            : `${formatAmount(payment?.amount)} ${payment?.currency}`
        }
      />
      <StackViewLine
        title1={t("Amt_deposit")}
        title2={
          payment?.currency === "eur"
            ? formatPrice(payment?.amount_capturable)
            : `${formatAmount(payment?.amount_capturable)} ${payment?.currency
            }`
        }
      />
      <SeeOrderButton />
    </div>
  );

  const PaymentOrderWallet = ({ data }) => (
    <div>
      <ConfirmationMessage />
      <StackViewLine title1={t('State')} title2={display(getOperationStateData?.find(v => v.value === data?.data?.transaction?.state))} />
      <StackViewLine title1={t('Total_amount')} title2={data?.data?.transaction?.amountDisplayed} />
      <StackViewLine title1={t('account')} title2={data?.data?.transaction?.account} />
      <StackViewLine title1={t('Method')} title2={display(getOperationMethodData?.find(v => v.value === data?.data?.transaction?.method))} />
      <StackViewLine title1={t('Operation')} title2={display(getOperationTransactionData?.find(v => v.value === data?.data?.transaction?.operation))} />
      <StackViewLine title1={t('Message')} title2={data?.translation[lng]} />

      <SeeOrderButton />


    </div>
  )

  return (
    <div className="container w-11/12 mx-auto my-[20px] relative p-[10px] h-[fit-content] border-[1px] shadow-sm rounded-md border-slate-300 " >
      <div className='' >
        {isConfirmationOrder && <ConfirmationOrderOnly order={order} />}
        {isPaymentOrderStripe && <PaymentOrderStripe payment={payment} />}
        {isPaymentOrderWallet && <PaymentOrderWallet data={wallet} />}
        {error === true && <ErrorDetails payment={payment} />}
      </div>
      <LoadingComponent
        open={isLoading || isLoadingOT || isLoadingOM || isLoadingOS || isLoadingCV}
      />
    </div>
  )
}

export default Acknowledgement;