import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    openFulfillments: [],
    closedFulfillments: [],
    fulfillmentFilter: [],
}

const fulfillmentSlice = createSlice({
    name: 'fulfillments',
    initialState,
    reducers: {
        handleOpenFulfillments(state, { payload }) {
            state.openFulfillments = payload
            state.fulfillmentFilter = payload
        },
        handleClosedFulfillments(state, { payload }) {
            state.closedFulfillments = payload
            state.fulfillmentFilter = payload
        },

    },
})

export const {
    handleClosedFulfillments,
    handleOpenFulfillments,


} = fulfillmentSlice.actions

export default fulfillmentSlice