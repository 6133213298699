import { ArrowBack } from '@mui/icons-material'
import { Box, Container, Stack, Typography, Avatar, TextField, IconButton } from '@mui/material'
import React from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useFormatter } from '../../../app/hooks/useFormatter'
import useMutationApi from '../../../app/hooks/useMutationApi'
import YButton from '../../../components/common/ButtonStyle'

const TransfertFormUser = ({ setForm }) => {
    
    const { display, putOperationTransactionData } = useFormatter()
    const { transferByCustomer, isTransferByCustomer } = useMutationApi()
    const { control, handleSubmit, formState: { errors }, } = useForm({
        defaultValues: {
            amount: "", operation: "transfer", destinationAccount: ""
        }
    })
    const handleTransfer = (data) => {
        transferByCustomer({
            operation: data?.operation,
            amount: parseInt(data.amount, 10),
            destinationAccount: parseInt(data.destinationAccount, 10)
        })
    }
   
    return (
        <Box>
            <Container maxWidth='sm' >
                <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"} my={2} >
                    <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"} >
                        <IconButton onClick={() => setForm(undefined)} aria-label='back button' >
                            <ArrowBack />
                        </IconButton>
                        <Typography  sx={{ fontSize: 15, fontFamily: 'Futura', color: '#333', ml: 2 }} >
                            Back
                        </Typography>
                    </Stack>
                    <Avatar>H</Avatar>
                </Stack>
                <Typography  sx={{ fontSize: 24, fontFamily: 'Futura', color: '#000', ml: 2, fontWeight: '700', my: 2, textDecorationLine: 'underline', }} >
                    Transfer
                </Typography>
                <form onSubmit={handleSubmit(handleTransfer)}>
                    <Typography >
                        Operation
                    </Typography>
                    <TextField
                        type={"text"}
                        contentEditable={false}
                        value={display(putOperationTransactionData?.find(t => t.value === 'transfer'))}
                        fullWidth id="outlined-basic" variant="outlined"
                        error={errors?.operation ? true : false}
                    />
                    <Typography >
                        Destination Account
                    </Typography>
                    <Controller
                        control={control}
                        rules={{
                            required: true,
                        }}
                        render={({ field: { onChange, value } }) => (
                            <TextField
                                onChange={onChange}
                                value={value}
                                fullWidth
                                id="outlined-basic"
                                variant="outlined"
                                type="number"
                                error={errors?.destinationAccount ? true: false}
                            />
                        )}
                        name="destinationAccount"
                    />
                    <Typography >
                        Amount
                    </Typography>
                    <Controller
                        control={control}
                        rules={{
                            required: true,
                        }}
                        render={({ field: { onChange, value } }) => (
                            <TextField
                                onChange={onChange}
                                value={value}
                                fullWidth
                                id="outlined-basic"
                                variant="outlined"
                                type="number"
                                error={errors?.destinationAccount ? true : false}
                            />
                        )}
                        name="amount"
                    />
                    <YButton loading={isTransferByCustomer} title={"Confirm"} type="submit" />
                </form>
            </Container>
        </Box>
    )
}

export default TransfertFormUser