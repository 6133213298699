import { useEffect } from "react";
import { useSelector } from "react-redux";
import { dispatch } from "../..";
import { cryptKey, deleteItem, getItem, isRealValue, saveItem, toJson } from "../../utilities";
import { handleAuthentication, loggedOut } from "../features/authSlice";
import { chatUrl, HOST_CHAT } from "../services";
const { io } = require("socket.io-client");


export let getId = null
export let token = ""
export let refreshToken = null
const eventMessage = 'private message'
const eventImage = 'private image'
const eventAudio = 'private audio'

const keyAuth = 'ayeuioshp';
export const setAuthUser = (auth) => saveItem(keyAuth, toString(auth))
export const getAuth = () => toJson(getItem(cryptKey(keyAuth))) || null
export const updateAuth = (accessToken) => { let auth = getAuth(); auth.accessToken = accessToken; setAuthUser(auth); }

export function useAuthentication(){
    const { authorization } = useSelector(state => state.auth)
    const deleteAuth = () => deleteItem(keyAuth)
    const { connectSocket } = useSocket()
    const availableAuthentication = () => {
        const result = getAuth()
        if (!isRealValue(result) || !isRealValue(result?.user)) { dispatch(loggedOut()); deleteAuth(); }
        else {
            dispatch(handleAuthentication(result))
            getId = result?.user?._id
            token = "Bearer " + result?.accessToken
            refreshToken = result?.refreshToken
            // connectSocket()
        }
    }    
    useEffect(() => {
        availableAuthentication()
    }, [authorization])
    return { availableAuthentication }
}

export function useSocket() {
    const { authorization } = useSelector(state => state.auth)

    const socket = io(`${HOST_CHAT}`, {
        transports: ["websocket"],
        autoConnect: false,
        auth: { accessToken: authorization ? authorization?.substring(7) : "" },
    })

    // const { isLoading: isLoadingSms } = useMessageForUpdateQuery(toUser, { skip: !(toUser !== null) })

    socket.on("error", (error) => {
        // console.error("socket error", error)
    })
        .on("connect_error", (e) => {
            // console.log("connect_error", e)
        })
        .on("connect", () => {
            // console.log("socket connected ", socket.connected)
        })
        .on(eventMessage, (args) => {
          
            // dispatch(userApi.endpoints.messages.initiate(args.to)))
            // setToUser(args.to)
        });

    function connectSocket() {
        socket.connect()
    }
    function textMessage(to, body) {
        // console.log('to, body', to, body)
        socket.emit(eventMessage, { to, body })
    }

    function imageMessage(to, file) {
        socket.emit(eventImage, { to }, file)
    }

    function audioMessage(to, file) {
        socket.emit(eventAudio, { to }, file)
    }

    return { connectSocket, textMessage, imageMessage, audioMessage }
}