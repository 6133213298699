import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    banks: [],
    selected: null,
    opened: null,
}

const banksSlice = createSlice({
    name: 'banks',
    initialState,
    reducers: {
        handleBanks(state, action) {
            state.banks = action.payload
        },
        handleSelected(state, action) {
            state.selected = action.payload
        },
        handleOpened(state, action) {
            state.opened = action.payload
        },
    },
})

export const {
    handleBanks,
    handleSelected,
    handleOpened

} = banksSlice.actions

export default banksSlice