import { ArrowBack } from '@mui/icons-material'
import { Box, Container, Stack, Typography, Avatar, TextField, IconButton, Select, MenuItem, FormControl } from '@mui/material'
import React from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useFormatter } from '../../../app/hooks/useFormatter'
import useMutationApi from '../../../app/hooks/useMutationApi'
import YButton from '../../../components/common/ButtonStyle'

const DepositFormUser = ({ setForm }) => {
    const { depositByCustomer, isDepositByCustomer } = useMutationApi()
    const { display, putOperationTransactionData, putOperationMethodData } = useFormatter()
    const { control, handleSubmit, formState: { errors }, } = useForm({
        defaultValues: { amount: "", operation: "deposit", method: "", transactionImage: "" },
    })
    const handleDeposit = (data) => {
        const body = new FormData();
        body.append("operation", data?.operation)
        body.append("amount", parseInt(data?.amount))
        body.append("method", data?.method)
        body.append("transactionImage", data?.transactionImage)
        depositByCustomer(body)
    }
    const handleChangeImage = (e, onChange) => {
        e.preventDefault()
        const image = e.target.files[0]
        onChange(image)
    }

    return (
        <Box>
            <Container maxWidth='sm' >
                <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"} my={2} >
                    <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"} >
                        <IconButton onClick={() => setForm("")} aria-label='back button' >
                            <ArrowBack />
                        </IconButton>
                        <Typography  sx={{ fontSize: 15, fontFamily: 'Futura', color: '#333', ml: 2 }} >
                            Back
                        </Typography>
                    </Stack>
                    <Avatar>H</Avatar>
                </Stack>
                <Typography  sx={{ fontSize: 24, fontFamily: 'Futura', color: '#000', ml: 2, fontWeight: '700', my: 2, textDecorationLine: 'underline', }} >
                    Deposit
                </Typography>
                <form onSubmit={handleSubmit(handleDeposit)}>
                    <Typography >
                        Operation
                    </Typography>
                    <TextField type={"text"} contentEditable={false}
                        value={display(putOperationTransactionData?.find(t => t.value === 'deposit'))}
                        fullWidth id="outlined-basic" variant="outlined"
                        error={errors?.operation ? true : false}
                    />
                    <Typography >
                        Method
                    </Typography>
                    <Controller
                        control={control}
                        rules={{
                            required: true,
                        }}
                        render={({ field: { onChange, value } }) => (
                            <FormControl fullWidth sx={{ my: 1, }}>
                                <Select
                                    labelId="demo-simple-select-helper-label"
                                    id="demo-simple-select-helper"
                                    value={value}
                                    color='primary'
                                    onChange={onChange}
                                    error={errors?.method}
                                >
                                    {
                                        putOperationMethodData
                                            ?.filter(v => v.value.includes("bank"))
                                            ?.map((item, index) => <MenuItem key={index} value={item?.value}>{display(item)}</MenuItem>)
                                    }
                                </Select>
                            </FormControl>
                        )}
                        name="method"
                    />
                    <Typography >
                        Amount
                    </Typography>
                    <Controller
                        control={control}
                        rules={{
                            required: true,
                        }}
                        render={({ field: { onChange, value } }) => (
                            <TextField
                                onChange={onChange}
                                value={value}
                                fullWidth
                                id="outlined-basic"
                                type={"number"}
                                variant="outlined"
                                error={errors?.amount}
                                aria-errormessage='error here'
                            />
                        )}
                        name="amount"
                    />
                    <Typography >
                        Image
                    </Typography>
                    <Controller
                        control={control}
                        rules={{
                            required: true,
                            validate: () => { },
                        }}
                        render={({ field: { onChange } }) => (
                            <TextField fullWidth
                                onChange={(e) => handleChangeImage(e, onChange)}
                                variant="outlined"
                                type={"file"}
                                id="img"
                                error={errors?.transactionImage}
                            />
                        )}
                        name="transactionImage"
                    />
                    <YButton loading={isDepositByCustomer} title={"Confirm"} type="submit" />
                </form>
            </Container>
        </Box>
    )
}

export default DepositFormUser