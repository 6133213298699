import React, { useState } from 'react'
import { useInfosAccountBankQuery } from '../../../app/services/Api'
import AccountSection from './AccontSection'
import { useTranslation } from 'react-i18next'
import LoadingButton from '../../common/LoadingButton'
import { IoTrashBinSharp, IoTrashSharp } from 'react-icons/io5'
import ModalFlat from '../../ModalFlat'
import useMutationApi from '../../../app/hooks/useMutationApi'
import Spinner from '../../Spinner'

const GeneralTab = () => {

  const { data, isLoading, error } = useInfosAccountBankQuery()
  const [Open, setOpen] = useState(false)
    const { t } = useTranslation();

    const { deleteAccountByUser, isLoadingDeleteAccountInAppByUser} = useMutationApi()

  return (
    <div className="relative container md:mx-auto md:px-8 xl:px-0">
      <div className="pt-3 pb-16">
        <div className="px-4 sm:px-6 md:px-0">
          <div className="py-6">
            <AccountSection />
            <div className="flex flex-row w-full justify-between items-end relative">
              <div className="space-y-1 mt-6">
                <h3 className="text-base font-medium leading-6 text-gray-900">
                  {t("del_acc")}
                </h3>
                <p className="max-w-2xl text-sm text-gray-500">
                  {t("del_acc_desc")}
                </p>
              </div>

              <LoadingButton
                icon={<IoTrashSharp className="text-white w-5 h-5 mr-3" />}
                style={{ width: "fit-content", position: "absolute", right: 0 }}
                title={t("del_acc")}
                onClick={() => setOpen(true)}
              />
              <ModalFlat
                children={
                  <div class="relative w-full max-w-md max-h-full">
                    <div class="relative bg-white">
                      <div class="p-6 text-center">
                        <svg
                          class="mx-auto mb-4 text-[#D90429] w-12 h-12 "
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 20 20"
                        >
                          <path
                            stroke="currentColor"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            d="M10 11V6m0 8h.01M19 10a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                          />
                        </svg>
                        <h3 class="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">
                          {t("dlt_acc_msg")}
                        </h3>
                        <button
                          data-modal-hide="popup-modal"
                          type="button"
                          onClick={deleteAccountByUser}
                          class="text-white bg-red-600 space-x-2 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center mr-2"
                        >
                          {t("del_acc")}
                          { isLoadingDeleteAccountInAppByUser && <Spinner /> }
                        </button>
                        <button
                          data-modal-hide="popup-modal"
                          type="button"
                          disabled={isLoadingDeleteAccountInAppByUser}
                          onClick={() => setOpen(false)}
                          class="text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-200 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600"
                        >
                          {t("cancel")}
                        </button>
                      </div>
                    </div>
                  </div>
                }
                isOpen={Open}
                closeModal={() => setOpen(false)}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default GeneralTab