import React from 'react'
import { RadioGroup } from '@headlessui/react'
import { useSelector } from 'react-redux'
import ListPlaceholders from '../common/Skeletons/ListPlaceholders'
import { handleOpened, handleSelected } from '../../app/features/bankSlice'
import { dispatch } from '../..'
import useMutationApi from '../../app/hooks/useMutationApi'
import { useTranslation } from 'react-i18next'
import { isRealValue } from '../../utilities'
import LoadingButton from '../common/LoadingButton'
import ModalFlat from '../ModalFlat'
import BankDetails from './BankDetails'
import BankLocationList from './BankLocationList'
import { useNavigate } from 'react-router-dom'

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function BanksSelect({ isLoadingBank, showButton = true }) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [modal, setModal] = React.useState(false);
  const openDetail = (e, item) => {
    e.preventDefault();
    dispatch(handleOpened(item));
    setModal(!modal);
  };
  const closeModal = () => setModal(false);
  const { locationsBanks } = useSelector((state) => state.locations);
  const { buildOrderByUser, isCreateOrder } = useMutationApi();
  const { banks, selected } = useSelector((state) => state.banks);
  const { lng } = useSelector((state) => state.app);
  const { order } = useSelector((state) => state.bookmarkCard);

  if (isLoadingBank) {
    return (
      <div className="p-6">
        <ListPlaceholders />
      </div>
    );
  }

  return (
    <div className="p-6">
      <RadioGroup value={selected} onChange={() => null}>
        <RadioGroup.Label className="sr-only">
          {" "}
          Privacy setting{" "}
        </RadioGroup.Label>
        <div className="-space-y-px bg-white rounded-md">
          {banks.map((bank, bankIdx) => {
            const setMode = () => {
              dispatch(handleSelected(bank));
            };

            return (
              <RadioGroup.Option
                key={bankIdx}
                value={bank}
                checked={selected?._id === bank?._id}
                onClick={setMode}
                className={({ checked }) =>
                  classNames(
                    bankIdx === 0 ? "rounded-tl-md rounded-tr-md" : "",
                    bankIdx === banks.length - 1
                      ? "rounded-bl-md rounded-br-md"
                      : "",
                    checked
                      ? "bg-[#D9042950] border-[#D9042980] z-10"
                      : "border-gray-200",
                    "relative border p-4 flex cursor-pointer focus:outline-none"
                  )
                }
              >
                {({ active, checked }) => (
                  <>
                    <span
                      className={classNames(
                        checked
                          ? "bg-[#D9042990] border-transparent"
                          : "bg-white border-gray-300",
                        active ? "ring-2 ring-offset-2 ring-[#D9042980]" : "",
                        "mt-0.5 h-4 w-4 shrink-0 cursor-pointer rounded-full border flex items-center justify-center"
                      )}
                      aria-hidden="true"
                    >
                      <span className="rounded-full bg-white w-1.5 h-1.5" />
                    </span>
                    <span className="flex flex-col ml-3">
                      <RadioGroup.Label
                        as="span"
                        className={classNames(
                          checked ? "text-[#D90429]" : "text-gray-900",
                          "block text-sm font-medium"
                        )}
                      >
                        {t("bank")}: {bank.bank.name}
                      </RadioGroup.Label>
                      <RadioGroup.Description
                        as="span"
                        className={classNames(
                          checked ? "text-[#D90429]" : "text-gray-500",
                          "block text-sm"
                        )}
                      >
                        {`${t("benef")} : ${bank?.accountHolder?.name}`}
                        <br />
                        {`${t("acc_num")} : ${bank?.accountCode?.bank}-${
                          bank?.accountCode?.branch
                        }-${bank?.accountCode?.account}-${
                          bank?.accountCode?.key
                        }`}
                      </RadioGroup.Description>
                    </span>
                    {selected?._id === bank?._id && (
                      <RadioGroup.Description
                        onClick={(e) => openDetail(e, bank)}
                        className="absolute top-5 right-3 cursor-pointer h-[fit-content] w-[fit-content] p-1 rounded-3xl drop-shadow-sm border-2 border-white "
                      >
                        <div>
                          <p className="text-[10px] text-gray-50">
                            {t("see_loc")}
                          </p>
                        </div>
                      </RadioGroup.Description>
                    )}
                  </>
                )}
              </RadioGroup.Option>
            );
          })}
        </div>
      </RadioGroup>
      { showButton && <LoadingButton
        style={{ marginTop: 30, marginBottom: 30 }}
        title={t("save command")}
        onClick={() => buildOrderByUser({bank: selected})}
        loading={isCreateOrder}
        disabled={
          !(isRealValue(selected?._id) && isRealValue(order?.order?.pickupLocation))
        }
      />}
      <ModalFlat
        isOpen={modal}
        children={<BankLocationList locationsBanks={locationsBanks} lng={lng} navigate={navigate} />}
        title={t("bank_det")}
        closeModal={closeModal}
      />
    </div>
  );
}
