import React from 'react'
import UserActivitySection from './UserActivitySection'
import UserDetailsSection from './UserDetailsSection'
import { useInfosAccountBankQuery } from '../../app/services/Api'

const UserProfileSection = () => {
  const { data, isLoading } = useInfosAccountBankQuery()
  return (
    <div className="grid grid-cols-1 gap-10 " >
        <UserActivitySection isLoading={isLoading} />
        <UserDetailsSection  isLoading={isLoading} />
    </div>
  )
}

export default UserProfileSection