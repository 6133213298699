import * as React from 'react';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import SearchIcon from '@mui/icons-material/Search';
import Slide from '@mui/material/Slide';
import { Box, colors, Container } from '@mui/material';

import logo from '../assets/images/Logo_AFLP.png'

import { useSelector } from 'react-redux';
import InputFlatBorder from './common/InputFlatBorder';
import { IoSearchSharp } from 'react-icons/io5';
import { classNames } from '../utilities';


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function DialogLayout({ open, setOpen }) {
    const [focus, setFocus] = React.useState(false)
    const handleClose = () => setOpen(false)


    return (
      <div>
        <Dialog
          fullScreen
          open={open}
          onClose={handleClose}
          TransitionComponent={Transition}
        >
          <AppBar elevation={1} sx={{ position: "relative", bgcolor: "#fff" }}>
            <Toolbar>
              <Stack
                direction={"row"}
                width={"100%"}
                alignItems={"center"}
                justifyContent={"space-between"}
              >
                <Box flexGrow={1}>
                  <img onContextMenu = { (event)=> { event.preventDefault();    }  } src={logo} style={{ height: 40 }} />
                </Box>
                <Button
                  autoFocus
                  color="primary"
                  onClick={handleClose}
                  sx={{ textTransform: "none" }}
                >
                  Fermer
                </Button>
              </Stack>
            </Toolbar>
          </AppBar>
          <Container maxWidth="lg" sx={{ mt: 5 }}>
            <InputFlatBorder
              style={{ width: "100%", mb: 4 }}
              placeholder={"Rechercher..."}
              onFocus={() => setFocus(true)}
              onBlur={() => setFocus(false)}
              rightIcon={
                <IoSearchSharp
                  className={classNames(
                    focus ? "text-[#D90429]" : "text-gray-800",
                    " h-6 w-6"
                  )}
                />
              }
            />
            {/* {( searchService !=="" ||  !isMainService) && <ServiceItemList onItemClick={handleCloseList} /> }
                    { searchService==="" && isMainService && <ServiceList />} */}
          </Container>
        </Dialog>
      </div>
    );
}