import {AppBar, Box, InputBase, List, ListItemButton, ListItemIcon, styled, Typography} from "@mui/material";
import { Link } from 'react-router-dom';

export const  AppbarContainer = styled(AppBar)(() => ({
    position: "static",
    paddingLeft: 0,
    backgroundColor: "transparent",
    marginBottom: 1,
    elevation: 5,
}));
export const AppbarHeader = styled(Typography)(() => ({
    padding: '4px',
    flexGrow: 1,
    fontSize: '1.5em',
    
    fontWeight:"bold",
    color: "#D90429",
    textAlign:"center",
}));
export const TitlePageHeader = styled(Typography)(({theme}) =>({
    padding: theme.spacing(1, 0, 1, 0),
    textAlign: 'center',
    background:"black" ,
    color:"white", 
    fontSize:"1.5em", 
    fontWeight:"bold"
}));

export const MyMenu = styled(List)(( { type }) => ({
    display: type === 'row' ? 'flex' : 'block',
    flexGrow: 1,
    justifyContent: 'center',
    alignItems: 'center',
    margin: 0,
    padding: 0,
}));

export const MenuContainerMobile = styled(Box)(({theme}) => ({
    display: 'flex',
    background: 'white',
    position: 'fixed',
    bottom: 0,
    left: 0,
    alignItems: 'center',
    zIndex: 1000,
    borderTop: `2px solid #D90429`,
    margin: theme.spacing(10, 0, 0, 0),
    padding: (0, 0, 0, 0),
}));
export const MyMenuItem = styled(ListItemButton)(() => ({
    justifyContent: 'center', 
    display: "block"
}));
export const MyMenuItemIcon = styled(ListItemIcon)(() => ({
    display: 'flex',
    justifyContent: 'center',
    color: 'black'
}));
export const MyMenuItemTitle = styled(Typography)(() => ({
    fontSize: 14, 
    textAlign: "center", 
    marginTop: 5,
}));
export const SearchDrawer = styled('div')(({ theme }) => ({
    position: 'relative',
    border: "none",
    margin: theme.spacing(0,1,2,0),
    width: 'auto',    
  }));
export const SearchIconWrapperDrawer = styled('div')(({ theme }) => ({
    padding: theme.spacing(0),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    top:0,
    right:0,
  }));
export const StyledInputBaseDrawer = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    '& .MuiInputBase-input': {
      padding: theme.spacing(1, 1, 1, 1),
      marginRight: `calc(1em + ${theme.spacing(1)})`,
      width: '100%',
      [theme.breakpoints.up('md')]: {
        width: '25ch',
      },
    },
}));
export const ListItemTextDrawer = styled(Typography)(({ theme }) => ({
  
  fontWeight: 500,
  fontSize: 16,
  color: "black",
  textDecoration: "none",
  display: "block",
}));

export const LinkItemTextDrawer = styled(Link)(({theme}) => ({
    margin: theme.spacing(1.5, 0, 1.5 , 1.5),
    fontWeight: 500,
    fontSize: 16,
    color: "black",
    textDecoration: "none",
    display: "block",
}));