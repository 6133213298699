import { Box, Typography } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import { dispatch } from "../../app";
import { handleContextSearch, handleKeyOfStoreOrCategory } from "../../app/features/AppSlice";
import { useLocalDbHook } from "../../app/localDatabase/useLocalDbHook";
import { useBookmarkItemsOfflineQuery, useBookmarkItemsQuery } from "../../app/services/Api";

import FavoriteItem from "../../components/common/Favorite";
import LoadingComponent from "../../components/LoadingComponent";
import { isRealValue, scroolWindowToTop, toString } from "../../utilities";
import { GridNotFound } from "../Products/style";
import { useTranslation } from "react-i18next";

function Favorites() {
  const { isLogged, user } = useSelector(state => state.auth)
  const { t } = useTranslation()
  const { itemsFilter } = useSelector(state => state.bookmarkItems);
  const { bookmarkItemsOff } = useLocalDbHook()
  const { isFetching: isLoadingBookmarks } = useBookmarkItemsQuery(null, { skip: !(isLogged && isRealValue(user?._id)), refetchOnMountOrArgChange: true, refetchOnReconnect: true })
  const { isFetching: isLoadingBookmarksOffLine } = useBookmarkItemsOfflineQuery({ arr: toString(bookmarkItemsOff?.map(b => b?.key)) }, { skip: !(isLogged === false && bookmarkItemsOff?.length > 0) })

  React.useEffect(() => {

    scroolWindowToTop()
    dispatch(handleContextSearch(null))
    dispatch(handleKeyOfStoreOrCategory(null))
  })

  return (
    <div>
      <div className="max-w-full px-2 mx-auto overflow-hidden sm:px-10 lg:px-16">
        <Box
          className="row-flex"
          sx={{
            display: "grid",
            gridTemplateColumns: "0.5fr 0.5fr",
            gridGap: "16px",
          }}
        >
          <h2 style={{ fontSize: 28, fontWeight: "bold" }}>{t("fav_items")}</h2>
        </Box>
        <div className="grid grid-cols-2 border-gray-200  gap-y-1 gap-x-1 md:gap-y-2 md:gap-x-2 lg:gap-y-3 lg:gap-x-3 xl:gap-y-4 xl:gap-x-4 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-6">
          {itemsFilter?.length <= 0 ? (
            <GridNotFound container>
              <Typography>No data found !</Typography>
            </GridNotFound>
          ) : (
            itemsFilter?.map((item, i) => <FavoriteItem item={item} />)
          )}
        </div>
      </div>
      <LoadingComponent open={isLoadingBookmarks || isLoadingBookmarksOffLine} />
    </div>
  );
}
export default Favorites; 