import { createSlice } from "@reduxjs/toolkit";

const initialState = {
   deliveriesAreas : [],
   deliveriesAreasFilter : [],
}

const deliveryAreaSlice = createSlice({
    name: 'deliveriesAreas',
    initialState,
    reducers: {
       handleDeliveriesAreas(state, {payload}) {
            state.deliveriesAreas = payload
            state.deliveriesAreasFilter = payload
       },
       handleFilterByStatus(status, {payload}) {

       },
    },
})

export const {
   handleDeliveriesAreas,
   handleFilterByStatus

} = deliveryAreaSlice.actions

export default deliveryAreaSlice