import React, { Fragment } from 'react'
import { Menu, Transition } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/20/solid'
import { isRealValue } from '../../../utilities'

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export default function FilterSelect({
  data = [],
  onChange = (v) => {},
  display = (v) => v,
  defaultText = "Filter",
  width = 72,
  label,
  zIndex,
  style
}) {
  const [selected, setSelected] = React.useState(null);
  const onSelected = (v) => {
    setSelected(v);
    onChange(v);
  };

  return (
    <div 
    style={{ 
      zIndex: zIndex ?? 170
    }}
    className="w-[full] ">
      {label && (
        <label className="block text-sm font-medium text-gray-700">
          {label}
        </label>
      )}
      <Menu
        as="div"
        className="relative inline-block text-left w-[full] "
      >
        <div>
          <Menu.Button
          style={style}
            className={classNames(
              "w-[fit-content]",
              "inline-flex line-clamp-1 truncate justify-center w-full px-4 py-2 text-sm font-medium text-gray-700 bg-white rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-100"
            )}
          >
            {isRealValue(selected) ? display(selected) : defaultText}
            <ChevronDownIcon
              className="w-5 h-5 ml-2 -mr-1"
              aria-hidden="true"
            />
          </Menu.Button>
        </div>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="absolute bg-white left-0 w-56 mt-2 origin-top-right divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
            {data?.map((item, index) => (
              <div key={index} className="py-1">
                <Menu.Item onClick={() => onSelected(item)}>
                  {({ active }) => (
                    <a
                      className={classNames(
                        active ? "bg-red-100 text-gray-900" : "text-gray-700",
                        "block px-4 py-2 text-sm"
                      )}
                    >
                      {display(item)}
                    </a>
                  )}
                </Menu.Item>
              </div>
            ))}
          </Menu.Items>
        </Transition>
      </Menu>
    </div>
  );
}