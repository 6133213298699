
import { useLayoutEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { STORE } from "../../constants/routes";


function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function SizeUpdateTable({
  setSelectedSize = () => { }, sizes=[],
  selectedSize = [],
  setEdit = () => { }, storeKey
}) {
  const checkbox = useRef();
  const [checked, setChecked] = useState(false);
  const { t } = useTranslation();
  const navigate = useNavigate();
  useLayoutEffect(() => {
    const isIndeterminate = selectedSize.length > 0 && selectedSize.length < sizes.length;
    setChecked(selectedSize.length === sizes.length);
    checkbox.current.indeterminate = isIndeterminate;
  }, [selectedSize]);

  function toggleAll() {
    if (!checked) {
      setChecked(true)
      setSelectedSize(sizes);
    } else if (checked) {
      setSelectedSize( [] );
      setChecked(false)
    }
  }

  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <div className="mt-8 flex flex-col">
        <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
            <div className="relative overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
              <table className="min-w-full table-fixed divide-y divide-gray-300">
                <thead className="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      className="relative w-12 px-6 sm:w-16 sm:px-8"
                    >
                      <input
                        type="checkbox"
                        className="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-[#D90429] focus:ring-[#D90429] sm:left-6"
                        ref={checkbox}
                        checked={checked}
                        onChange={toggleAll}
                      />
                    </th>
                    <th
                      scope="col"
                      className="min-w-[12rem] py-3.5 pr-3 text-left text-sm font-semibold text-gray-900"
                    >
                      Property Value
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Corrected English
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Corrigé français
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                  {sizes.map((sizeCat, index) => (
                    <tr
                      key={index}
                      className={
                        selectedSize.includes(sizeCat)
                          ? "bg-gray-50"
                          : undefined
                      }
                    >
                      <td className="relative w-12 px-6 sm:w-16 sm:px-8">
                        {selectedSize.findIndex(s => s?.propertyValue === sizeCat?.propertyValue) !== -1 && (
                          <div className="absolute inset-y-0 left-0 w-0.5 bg-[#D90429]" />
                        )}
                        <input
                          type="checkbox"
                          className="absolute left-4 top-1/2 -mt-2 cursor-pointer h-4 w-4 rounded border-gray-300 text-[#D90429] focus:ring-[#D90429] sm:left-6"
                          value={sizeCat}
                          checked={selectedSize.includes(sizeCat)}
                          onChange={(e) => {
                            setSelectedSize(
                              e.target.checked ? [...selectedSize, sizeCat]
                                : selectedSize.filter( (p) => p?.propertyValue !== sizeCat?.propertyValue )
                            );
                          }}
                        />
                      </td>
                      <td
                        className={classNames(
                          "whitespace-nowrap py-4 pr-3 text-sm font-medium",
                          selectedSize.includes(sizeCat)
                            ? "text-[#D90429]"
                            : "text-gray-900"
                        )}
                      >
                        {sizeCat.propertyValue}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        {sizeCat.valueCorrected?.en}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        {sizeCat.valueCorrected?.fr}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      {selectedSize?.length > 0 && (
        <div className=" left-12 my-3 flex h-12 items-center justify-end space-x-3 bg-gray-50 sm:left-16">
          <button
            type="button"
            onClick={() => navigate(`${STORE}/${storeKey}`)}
            className="inline-flex items-center justify-center px-4 py-2 text-sm font-medium text-white border border-transparent rounded-md shadow-sm bg-[#D90429] hover:bg-[#D9042990] focus:outline-none focus:ring-2 focus:ring-[#D90429] focus:ring-offset-2 sm:w-auto"
          >
            {t("bck_store")}
          </button>
          <button
            type="button"
            onClick={()=>setEdit(true)}
            className="inline-flex items-center justify-center px-4 py-2 text-sm font-medium text-white border border-transparent rounded-md shadow-sm bg-[#D90429] hover:bg-[#D9042990] focus:outline-none focus:ring-2 focus:ring-[#D90429] focus:ring-offset-2 sm:w-auto"
          >
            {t("blk_edt")}
          </button>
        </div>
      )}
    </div>
  );
}
