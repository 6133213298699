import { createSlice } from "@reduxjs/toolkit";
import { isRealValue } from "../../utilities";
const pagination = {
    "total": 0,
    "count": 0,
    "perPage": 0,
    "currentPage": 0,
    "totalPages": 0,
    "nextLink": ""
}

const initialState = {
    lng: "fr",
    platform: null,
    theme: {},
    pushNotifications: {
        lastId: 0,
        channelsIds: []
    },
    codeValues: {},
    paginations: pagination,
    items: [],
    itemsFilters: [],
    keyword: "",
    context: null,
    key: null, //key of store or category to perform search item
    limit: 96,
    page: 1,

    state: {},

    paginationSearch: pagination,
    itemsSearch: [],
    itemsSearchFilter: [],
    keywordSearch: "",
    limitSearch: 96,
    pageSearch: 1,
    searchElement: "products",

    keyCat: null,

}


const AppSlice = createSlice({
    name: 'app',
    initialState,
    reducers: {
        handleState(state, { payload }) {
            state.state = { ...state.state, ...payload }
        },
        handleTheme(state, action) {
            state.theme = action.payload
        },
        handlePlatform(state, {payload}) {
            state.platform = payload
        },
        handleLanguage(state, action) {
            state.lng = action.payload
        },
        handleContextSearch(state, { payload }) {
            state.context = payload
        }, 
        handleKeyOfStoreOrCategory(state, { payload }) {
            state.key = payload
        },  
        handleKeyCat(state, { payload }) {
            state.keyCat = payload
        },
        handleSearchElement(state, { payload }) {
            state.searchElement = payload
        },
        handleCodeValues(state, { payload }) {
            const { code, context, method, data } = payload
            var sc = {}
            sc = { ...state.codeValues }
            if (!isRealValue(sc[method]))
                sc[method] = {}
            if (!isRealValue(sc[method][context]))
                sc[method][context] = {}
            if (!isRealValue(sc[method][context][code]))
                sc[method][context][code] = {}
            sc[method][context][code] = data
            state.codeValues = sc
        },
        handleSearchItems(state, { payload }) {
            const { keyword, lng } = payload
            if (keyword === '') state.itemsFilters = state.items
            else
            {
                if (lng === "en") state.itemsFilters = state.items.filter(item => item.description[0]?.value.toLowerCase().includes(keyword.toLowerCase()))
                else state.itemsFilter = state.items.filter(item => item.description[1]?.value.toLowerCase().includes(keyword.toLowerCase()))
            }
            state.itemsFilters = state.items.filter(item => item.description[lng].toLowerCase().includes(keyword.toLowerCase()))
            state.keyword = keyword
        },
        handleItemsSearch(state, { payload }) {
            const { items, pagination } = payload
            state.paginationSearch = pagination
            state.itemsSearch = items
            state.itemsSearchFilter = items
        },
        handlePageItemsSearch(state, {payload}) {
            var page = payload
            // page = state.paginationSearch.totalPages > page ? page + 1 : state.paginationSearch?.totalPages
            if (page !== state.pageSearch) state.pageSearch = page
        },
    },
})

export const {
    handleTheme,
    handleLanguage,
    handlePlatform,
    handleChannelIds,
    handleIds,
    handleCodeValues,
    handleSearchItems,
    handleItemsSearch,
    handleState,
    handleContextSearch,
    handleKeyOfStoreOrCategory,
    handleSearchElement,
    handlePageItemsSearch,
    handleKeyCat
    
} = AppSlice.actions

export default AppSlice