import React, { useState } from 'react'
import CreditCardItem from './CreditCardItem'
import ModalFlat from './ModalFlat'
import AddCartForm from './AddCartForm'
import { useTranslation } from 'react-i18next';

const CreditCardComponent = ({ totalAmount = 0, showButton = true, isFirstOrdered =  true,  updateAmount  }) => {
  let [isOpen, setIsOpen] = useState(false);
  const {t} = useTranslation()
  function closeModal() {
    setIsOpen(false);
  }
  function openModal() {
    setIsOpen(true);
  }

  return (
    <div className="px-4">
      <div className="flex items-center justify-between my-3 ">
        <h4 className="inline-block text-lg text-gray-800 align-baseline">
          {t("ur_cards")}
        </h4>
        <button
          type="button"
          onClick={openModal}
          className="cursor-pointer w-[fit-content] rounded-md bg-[#D90429] px-4 py-2 text-sm font-medium text-white hover:bg-opacity-30 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75"
        >
          {t("add_card")}
        </button>
      </div>
      <CreditCardItem
        showButton={showButton}
        totalAmount={totalAmount}
        updateAmount={updateAmount}
        isFirstOrdered={isFirstOrdered}
      />
      <ModalFlat
        isOpen={isOpen}
        children={<AddCartForm />}
        title={t("add_card")}
        closeModal={closeModal}
      />
    </div>
  );
};

export default CreditCardComponent