import React from 'react'
import { useSelector } from 'react-redux';
import 'intl';
import moment from 'moment';
import { isRealValue } from '../../utilities';

export function useFormatter() {
  const { lng, codeValues } = useSelector(state => state.app)
  const display = (v) => v?.name[lng] || ""
  const rmSpace = value => isRealValue(value) ? value.toString().replace(/\s/g, "") : ""
  const formatAmount = amount => isRealValue(amount) ? new Intl.NumberFormat('fr-FR').format(rmSpace(amount)) : ""
  /**
   * 
   * @param {*} value the amount value to format
   * @param {*} onChange  the onchange from input
   */
  const handleFormatAmount = (value, onChange) => onChange(formatAmount(value))

  const formatPrice = (number = 0) => new Intl.NumberFormat("fr", { style: "currency", currency: "XAF" }).format(number)
  const formatDate = (date) => new Date(date).toDateString()
  //  new Intl.DateTimeFormat(lng, { dateStyle: 'short', timeStyle: 'short', timeZone: 'UTC' }).format(date);


  moment.locale(lng)
  const asDate = (date) => moment(date, ["MM-DD-YYYY", "YYYY-MM-DD"], lng).format("YYYY-MM-DD")
  const asHour = (date) => moment(date, ["hmm", moment.ISO_8601], lng).format("HH:mm")
  const beautyJson = (jsonObject) => JSON.stringify(jsonObject, null, "\n\t")
  const isValidJSON = str => {
    try {
      JSON.parse(str);
      return true;
    } catch (e) {
      return false;
    }
  };

  const getItemGradeData = codeValues?.get?.item?.grade?.data || []
  const putItemGradeData = codeValues?.put?.item?.grade?.data || []

  const getLocationTypeData = codeValues?.get?.location?.type?.data || []
  const getLocationServiceData = codeValues?.get?.location?.service?.data || []
  const putLocationTypeData = codeValues?.put?.location?.type?.data || []
  const putLocationServiceData = codeValues?.put?.location?.service?.data || []

  const getUserGradeData = codeValues?.get?.user?.grade?.data || []
  const getUserRoleData = codeValues?.get?.user?.role?.data || []
  const getUserDocumentData = codeValues?.get?.user?.document?.data || []
  const getUserCountryData = codeValues?.get?.user?.country?.data || []  //only by admin

  const putUserGradeData = codeValues?.put?.user?.grade?.data || []
  const putUserRoleData = codeValues?.put?.user?.role?.data || []
  const putUserDocumentData = codeValues?.put?.user?.document?.data || []
  const putUserCountryData = codeValues?.put?.user?.country?.data || []  //only by admin

  const getOrderOrderStatusData = codeValues?.get?.order?.orderStatus?.data || []
  const getOrderItemStatusData = codeValues?.get?.order?.itemStatus?.data || []
  const getOrderRouteData0 = codeValues?.get?.order?.route?.data[0]
  const getOrderRouteData1 = codeValues?.get?.order?.route?.data[1]

  const getPaymentStatusData = codeValues?.get?.payment?.status?.data || []

  const putTransitDestinationData = codeValues?.put?.transit?.destination?.data || []
  const putTransitCarrierData = codeValues?.put?.transit?.carrier?.data || []
  const putTransitStatusData = codeValues?.put?.transit?.status?.data || []
  const putTransitRouteData = codeValues?.put?.transit?.route?.data || []
  const putTransitAgentData = codeValues?.put?.transit?.agent?.data || []
  const putTransitItineraryArrivalFlagData = codeValues?.put?.transit?.itineraryArrivalFlag?.data || []
  const getTransitDestinationData = codeValues?.get?.transit?.destination?.data || []
  const getTransitCarrierData = codeValues?.get?.transit?.carrier?.data || []
  const getTransitStatusData = codeValues?.get?.transit?.status?.data || []
  const getTransitRouteData = codeValues?.get?.transit?.route?.data || []
  const getTransitAgentData = codeValues?.get?.transit?.agent?.data || []
  const getTransitItineraryArrivalFlagData = codeValues?.get?.transit?.itineraryArrivalFlag?.data || []

  const getFulfillmentTypeData = codeValues?.get?.fulfillment?.type?.data || []
  const getFulfillmentStatusData = codeValues?.get?.fulfillment?.status?.data || []
  const getFulfillmentHoldReasonData = codeValues?.get?.fulfillment?.holdReason?.data || []
  const putFulfillmentTypeData = codeValues?.put?.fulfillment?.type?.data || []
  const putFulfillmentStatusData = codeValues?.put?.fulfillment?.status?.data || []
  const putFulfillmentHoldReasonData = codeValues?.put?.fulfillment?.holdReason?.data || []

  const getBillStatusData = codeValues?.get?.bill?.status?.data || []
  const getBillTypeData = codeValues?.get?.bill?.type?.data || []
  const putBillStatusData = codeValues?.put?.bill?.status?.data || []
  const putBillTypeData = codeValues?.put?.bill?.type?.data || []


  const putOperationTransactionData = codeValues?.put?.operation?.transaction?.data || []
  const putOperationMethodData = codeValues?.put?.operation?.method?.data || []
  const getOperationTransactionData = codeValues?.get?.operation?.transaction?.data || []
  const getOperationStateData = codeValues?.get?.operation?.state?.data || []
  const putOperationStateData = codeValues?.get?.operation?.state?.data || []
  const getOperationMethodData = codeValues?.get?.operation?.method?.data || []

  const getHomeValueData = codeValues?.get?.home?.value?.data || []
  const getHomeTypeData = codeValues?.get?.home?.type?.data || []

  const getDimensionBodyData = codeValues?.get?.dimension?.body?.data || []


  return {
    formatPrice,
    handleFormatAmount,
    rmSpace,
    formatAmount,
    formatDate,
    asHour,
    asDate,
    beautyJson,
    isValidJSON,
    display,

    getItemGradeData,
    putItemGradeData,

    putBillTypeData,
    putBillStatusData,
    getBillTypeData,
    getBillStatusData,

    getUserGradeData,
    getUserRoleData,
    getUserDocumentData,
    getUserCountryData,
    putUserGradeData,
    putUserRoleData,
    putUserDocumentData,
    putUserCountryData,
    getLocationTypeData,
    getLocationServiceData,
    putLocationTypeData,
    putLocationServiceData,

    getOrderOrderStatusData,
    getOrderItemStatusData,
    getOrderRouteData0,
    getOrderRouteData1,
    getPaymentStatusData,

    putTransitDestinationData,
    putTransitCarrierData,
    putTransitStatusData,
    putTransitRouteData,
    putTransitAgentData,
    putTransitItineraryArrivalFlagData,
    getTransitDestinationData,
    getTransitCarrierData,
    getTransitStatusData,
    getTransitRouteData,
    getTransitAgentData,
    getTransitItineraryArrivalFlagData,

    getFulfillmentHoldReasonData,
    getFulfillmentStatusData,
    getFulfillmentTypeData,
    putFulfillmentStatusData,
    putFulfillmentHoldReasonData,
    putFulfillmentTypeData,

    putOperationTransactionData,
    getOperationTransactionData,
    getOperationStateData,
    putOperationStateData,
    putOperationMethodData,
    getOperationMethodData,

    getHomeTypeData,
    getHomeValueData,
    getDimensionBodyData,
  };
}