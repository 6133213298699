import React from 'react'

const LoadingUserActivity = () => {
  return (
      <div role="status" class="flex flex-row items-center justify-between h-full w-[fit-content] p-4 border border-gray-200 rounded shadow animate-pulse md:p-6 dark:border-gray-700">
        <div>
            <div class="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-14 mb-2"></div>
            <div class="w-9 h-2 bg-gray-200 rounded-full dark:bg-gray-700"></div>
        </div>
      </div>

  )
}

export default LoadingUserActivity