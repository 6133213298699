
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Outlet, useNavigate } from 'react-router-dom'
import { MEDIA_URL } from '../../app/services'
import { PRODUCT_DETAILS, STORE } from '../../constants/routes'
import { isRealValue } from '../../utilities'
import ViewSeaOrAirPrice from '../ProductDetails/ViewSeaOrAirPrice'
import LoadingButton from '../../components/common/LoadingButton'
import { IoHeartCircleSharp } from 'react-icons/io5'
import useMutationApi from '../../app/hooks/useMutationApi'
import { Rating } from '@mui/material'
import StarIcon from "@mui/icons-material/Star";
import { useLocalDbHook } from '../../app/localDatabase/useLocalDbHook'

const StoreIndex = () => {
  const { lng } = useSelector(state => state.app)
  const { isLogged } = useSelector(state => state.auth)
  const { store, categoryLevel3, indexLevel3 } = useSelector(state => state.store)
  const { itemsFilter } = useSelector(state => state.items)
  const { bookmarkStores } = useSelector(state => state.bookmarkStore)

  const { t } = useTranslation()
  const navigate = useNavigate()

  const { handleBookmarkStore, isLoadingBookmarkStores } = useMutationApi()

  const getGradeColor = (grade) => {
    switch (grade) {
      case "standard":
        return "#CD7F32";
      case "silver":
        return "#C0C0C0";
      case "gold":
        return "#FFD700";
      case "platinum":
        return "#E5E4E2";
      default:
        return '#FFF'
    }
  }
  const { bookmarkStoresOff } = useLocalDbHook()

  const isFavorite = () => {
    const itemFav = isLogged ? bookmarkStores?.find((article) => article?.key === store?.key) : bookmarkStoresOff?.find(v => v.key === store?.key)
    if (itemFav) {
      return true;
    }
    return false;
  }

  const gotoListSizesOfCategory = () => navigate(`${STORE}/${store?.key}/sizes/${categoryLevel3[indexLevel3]?.key}`)

  return (
    <>
      <div className="relative">
        <div
          aria-hidden="true"
          className="absolute inset-0 hidden sm:flex sm:flex-col"
        >
          <div className="relative flex-1 w-full bg-gray-800">
            <div className="absolute inset-0 bg-white opacity-50" />
          </div>
          <div className="w-full h-32 bg-white md:h-40 lg:h-48" />
        </div>
        <div className="relative px-4 mx-auto text-center pb-96 sm:px-6 sm:pb-0 lg:px-8">
          {/* Background image and overlap */}
          <div
            aria-hidden="true"
            className="absolute inset-0 flex flex-col sm:hidden"
          >
            <div className="relative flex-1 w-full bg-gray-800">
              <div className="absolute inset-0 overflow-hidden">
                {/* <img onContextMenu = { (event)=> { event.preventDefault();    }  }
                    src={
                      isRealValue(store?.assets)
                        ? MEDIA_URL + store?.assets[0]?.url
                        : MEDIA_URL
                    }
                    alt=""
                    className="object-cover object-center w-full h-full"
                  /> */}
              </div>
              <div className="absolute inset-0 bg-white opacity-50" />
            </div>
            <div className="w-full h-48 bg-white" />
          </div>
          <div className="relative py-32 ">
            <LoadingButton
              isLoading={isLoadingBookmarkStores}
              style={{
                position: "absolute",
                top: 10,
                right: 0,
                background: isFavorite() ? "#D90429" : "transparent",
              }}
              icon={<IoHeartCircleSharp className="text-white w-7 h-7" />}
              title={t("add_to_fav")}
              onClick={() => handleBookmarkStore(store?.key)}
            />
            <img
              onContextMenu={(event) => {
                event.preventDefault();
                window.open(event?.target?.currentSrc, "_blank");
              }}
              src={
                isRealValue(store?.assets)
                  ? MEDIA_URL + store?.assets[0]?.url
                  : MEDIA_URL
              }
              alt=""
              className=" mx-auto top-4 left-0 object-contain object-center w-[210px] h-[180px] rounded-md"
            />
            <h1 className="text-4xl font-bold tracking-tight text-white sm:text-5xl md:text-6xl">
              {isRealValue(store?.name[lng]) ? store?.name[lng] : ""}
            </h1>
            <span
              style={{ color: getGradeColor(store?.grade) }}
              className="text-xl mx-2 font-medium"
            >
              {store?.grade}
              {store?.score && (
                <Rating
                  name="text-feedback"
                  value={store?.score / 4}
                  readOnly
                  max={5}
                  size="small"
                  precision={1}
                  emptyIcon={
                    <StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />
                  }
                />
              )}
            </span>
          </div>
        </div>
        <section
          aria-labelledby="collection-heading"
          className="relative -mt-96 sm:mt-0"
        >
          <h2 id="collection-heading" className="sr-only">
            {t("Items")}
          </h2>
          <div className="grid max-w-md grid-cols-1 px-4 mx-auto gap-y-6 sm:max-w-7xl sm:grid-cols-3 sm:gap-y-0 sm:gap-x-6 sm:px-6 lg:gap-x-8 lg:px-8">
            {itemsFilter?.slice(0, 3)?.map((product, index) => (
              <div
                key={index}
                className="relative bg-white rounded-lg shadow-xl group h-96 sm:aspect-w-4 sm:aspect-h-5 sm:h-auto"
              >
                <div>
                  <div
                    aria-hidden="true"
                    className="absolute inset-0 overflow-hidden rounded-lg"
                  >
                    <div className="absolute inset-0 overflow-hidden group-hover:opacity-75">
                      <img
                        onContextMenu={(event) => {
                          event.preventDefault();
                          window.open(event?.target?.currentSrc, "_blank");
                        }}
                        src={product?.picture?.xlarge}
                        alt={"product"}
                        className="object-cover object-center w-full h-full"
                      />
                    </div>
                    <div className="absolute inset-0 opacity-50 bg-gradient-to-b from-transparent to-black" />
                  </div>
                  <div className="absolute inset-0 flex items-end p-6 rounded-lg">
                    <div>
                      <p aria-hidden="true" className="text-sm text-white">
                        <ViewSeaOrAirPrice item={product} seaOrAir={"sea"} />
                      </p>
                      <h3 className="mt-1 font-semibold text-white">
                        <a href={`${PRODUCT_DETAILS}/${product?.key}`}>
                          <span className="absolute inset-0" />
                          {isRealValue(product?.description[lng])
                            ? product?.description[lng]
                            : ""}
                        </a>
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </section>
      </div>
      <div className="h-auto ">
        <Outlet />
      </div>
    </>
  );
}

export default StoreIndex