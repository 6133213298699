import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    bookTrends: [],
    cartTrends: [],
    orderTrends: [],
    filteredItems: [],
    keyword: ''
}

const homeSlice = createSlice({
    name: 'home',
    initialState,
    reducers: {
        handleBookTrends(state, action) {
            state.bookTrends = action.payload
            state.keyword = ''
            state.filteredItems = []
        },
        handleCartTrends(state, action) {
            state.cartTrends = action.payload
            state.keyword = ''
            state.filteredItems = []
        },
        handleOrderTrends(state, action) {
            state.orderTrends = action.payload
            state.keyword = ''
            state.filteredItems = []
        },
        handleSearchItemHome(state, { payload }) {
            const { keyword, lng } = payload
            const tmp = [...state.cartTrends, ...state.orderTrends, ...state.bookTrends]
            if (keyword === '') state.filteredItems = tmp
            else state.filteredItems = tmp.filter(item => item.description[lng].toLowerCase().includes(keyword.toLowerCase()))
            state.keyword = keyword
        },
    },
})

export const {
    handleBookTrends,
    handleCartTrends,
    handleOrderTrends,
    handleSearchItemHome,

} = homeSlice.actions

export default homeSlice