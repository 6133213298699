import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    clientStripe: null,
    cardList: [],
    card: null,
    cardDetails: null,
    cardIndex: -1,
    euroRelated: null, //amount converted are here
    currencyCard: null,
    amountToPay: 0

}

const StripeSlice = createSlice({
    name: 'stripe',
    initialState,
    reducers: {
        handleClientStripe(state, { payload }) {
            state.clientStripe = payload
        },
        handleCardsList(state, { payload }) {
            state.cardList = payload
        },
        handleCardDetails(state, { payload }) {
            state.cardDetails = payload
        },
        handleSelectCard(state, { payload }) {
            state.card = payload.id
            state.cardIndex = payload.index
        },
        setCardIndex(state, { payload }) {
            state.cardIndex = payload
        },
        handleEuroRelated(state, { payload }) {
            state.euroRelated = payload
            if(state.euroRelated?.currency !== "XAF")
            state.currencyCard = state.euroRelated.currency
        },
        handleCurrencyCard(state, { payload }) {
            state.currencyCard = payload
        }, 
        handleAmountToPay(state, { payload }) {
            state.amountToPay = payload
        },
    },
})

export const {
    handleClientStripe,
    handleCardsList,
    handleSelectCard,
    setCardIndex,
    handleCardDetails,
    handleEuroRelated,
    handleCurrencyCard,
    handleAmountToPay


} = StripeSlice.actions

export default StripeSlice