import React from 'react'
import { useTranslation } from 'react-i18next';
import ProductListItems from '../Products/ProductListItems';

const StoreProducts = ({ itemsFilter = [] }) => {
  const { t } = useTranslation();

  return (
    <section aria-labelledby="trending-heading">
      <div className="px-4 py-24 lg:pt-32">
        <div className="md:flex md:items-center md:justify-between">
          <h2
            id="favorites-heading"
            className="text-2xl font-bold tracking-tight text-gray-900"
          >
            {t("Items")}
          </h2>
        </div>
        <ProductListItems
          itemsFilter={itemsFilter?.slice(0, 12)}
        />
      </div>
    </section>
  );
};

export default StoreProducts