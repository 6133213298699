import React, { useRef, useState } from "react";
import Card from "../common/Card";
import userImg from "../../assets/images/profileImage.jpeg";
import InputFlatBorder from "../common/InputFlatBorder";
import {
  IoCameraSharp,
  IoCloseCircleSharp,
  IoMailSharp,
  IoPencilSharp,
  IoPersonSharp,
  IoPhonePortraitSharp,
} from "react-icons/io5";
import { motion } from "framer-motion";

import NIC_img from "../../assets/images/cardId.png";
import { Controller, useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { isRealValue } from "../../utilities";
import { MEDIA_URL } from "../../app/services";
import useMutationApi from "../../app/hooks/useMutationApi";
import { CircularProgress } from "@mui/material";
import LoadingButton from "../common/LoadingButton";
import { useTranslation } from "react-i18next";

const UserEditProfile = ({ onBackClick, onSaveClick }) => {
  const { t } = useTranslation();

  const [rectoImg, setRectoImg] = useState({ uri: null, file: null });
  const [versoImg, setVersoImg] = useState({uri: null, file: null});
  const inputRef = useRef();
  const rectoRef = useRef();
  const versoRef = useRef();

  const { InfoAccount } = useSelector(state => state?.auth);
  const {
    changeImageProfileByUser,  isLoadingChangeProfile ,
    changeImageIdentityCnByUser, isLoadingChangeIdentityCniProfile
  } = useMutationApi();
  const changeProfile = body => changeImageProfileByUser(body)
  const handleProfileChange = (event) => {
    const file = event.target.files[0];
    if (isRealValue(file)) {
      const data = new FormData();
      data.append('profilePicture', file)
      changeProfile(data);
    }
  };

  const handleRectoChange = (event) => {
    const file = event.target.files[0];
    if (isRealValue(file)) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        setRectoImg({ uri: reader.result, file });
      };
    }
  };
  const handleVersoChange = (event) => {
    const file = event.target.files[0];
    if (isRealValue(file)) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        setVersoImg({uri: reader.result, file});
      };
    }
  };

  const handleUploadIconClick = (e) => {
    inputRef.current.click();
  };

  const handleRectoIconClick = (e) => {
    rectoRef.current.click();
  };

  const handleVersoIconClick = (e) => {
    versoRef.current.click();
  };

  const { control, handleSubmit, formState: { errors }, } = useForm({
    defaultValues: {},
  })
  
  const handleChangeIdentityUser = (_) => {
    const data = new FormData();
    data.append('frontPicture', rectoImg?.file)
    data.append('backPicture', versoImg?.file)
    changeImageIdentityCnByUser(data)
  }

  return (
    <Card style={{ margin: `20px 0px`, padding: `15px 20px` }}>
      <div>
        <div className="h-[fit-content] w-[fit-content] relative flex-1 flex flex-row justify-between items-start md:flex-row md:justify-between md:items-center">
          <img
            onContextMenu={(event) => {
              event.preventDefault();
              window.open(event?.target?.currentSrc, "_blank");
            }}
            src={
              isRealValue(InfoAccount?.profilePicture)
                ? `${MEDIA_URL}${InfoAccount?.profilePicture}`
                : userImg
            }
            className="object-cover object-center rounded-full h-28 w-28"
            alt="profile"
          />
          {isLoadingChangeProfile && (
            <CircularProgress
              color="primary"
              size={30}
              style={{ position: "absolute", left: 50, right: 50 }}
            />
          )}
          <motion.div
            whileTap={{ scale: 0.95 }}
            whileHover={{ scale: 1.1 }}
            onClick={handleUploadIconClick}
            className="absolute bottom-0 flex flex-col items-center justify-center w-10 h-10 rounded-full cursor-pointer -right-2 bg-[#D90429]"
          >
            <IoCameraSharp className="w-6 h-6 text-white " />
          </motion.div>
          <input
            type="file"
            ref={inputRef}
            className="hidden"
            accept="image/*"
            onChange={handleProfileChange}
          />
        </div>
        <div className="grid grid-cols-1 mt-4 mb-6 gap-x-8 md:grid-cols-2">
          {/* <Controller control={control} rules={{ required: false }} name="firstName"
            render={({ field: { onChange } }) => ( */}
          <InputFlatBorder
            label={t("fname")}
            rightIcon={<IoPersonSharp className="w-5 h-5 text-[#D90429]" />}
            placeholder={t("fname")}
            editable={false}
            // onChange={onChange}
            value={InfoAccount?.firstName}
          />
          {/* )}
          /> */}
          <InputFlatBorder
            label={t("lname")}
            rightIcon={<IoPersonSharp className="w-5 h-5 text-[#D90429]" />}
            placeholder={t("lname")}
            editable={false}
            value={InfoAccount?.lastName}
          />
          <InputFlatBorder
            label={"Email"}
            rightIcon={<IoMailSharp className="w-5 h-5 text-[#D90429]" />}
            placeholder={"Email"}
            editable={false}
            value={InfoAccount?.email}
          />
          <InputFlatBorder
            label={t("phone")}
            rightIcon={
              <IoPhonePortraitSharp className="w-5 h-5 text-[#D90429]" />
            }
            type="tel"
            placeholder={t("phone")}
            editable={false}
            value={InfoAccount?.phone}
          />
          <div className="p-3 border-dashed border h-[fit-content] border-gray-400 rounded-md my-1 relative ">
            {rectoImg?.uri === null && (
              <div>
                <h4 className="text-lg font-semibold text-center text-gray-800">
                  {t("recto_nic")}
                </h4>
                <h6 className="text-sm font-medium text-center text-gray-600">
                  {t("import_img")}
                </h6>
                <img
                  onContextMenu={(event) => {
                    event.preventDefault();
                    window.open(event?.target?.currentSrc, "_blank");
                  }}
                  className="object-contain object-center mx-auto w-14 h-14 "
                  src={NIC_img}
                />
              </div>
            )}
            {rectoImg?.uri != null && (
              <img
                onContextMenu={(event) => {
                  event.preventDefault();
                  window.open(event?.target?.currentSrc, "_blank");
                }}
                src={rectoImg?.uri}
                className="h-full w-[100%] object-contain object-center rounded-md"
                alt="user avatar logo"
              />
            )}
            <motion.div
              whileTap={{ scale: 0.95 }}
              whileHover={{ scale: 1.1 }}
              onClick={handleRectoIconClick}
              className="absolute z-30 flex flex-col items-center justify-center rounded-full cursor-pointer right-2 bottom-3 w-7 h-7 bg-[#D90429]"
            >
              <IoPencilSharp className="w-3 h-3 text-white " />
            </motion.div>
            {rectoImg?.file != null && (
              <motion.div
                whileTap={{ scale: 0.95 }}
                whileHover={{ scale: 1.1 }}
                onClick={() => setRectoImg({ uri: null, file: null })}
                className="absolute z-30 flex flex-col items-center justify-center bg-red-500 rounded-full cursor-pointer left-2 top-3 w-7 h-7"
              >
                <IoCloseCircleSharp className="w-3 h-3 text-white " />
              </motion.div>
            )}
            <input
              type="file"
              ref={rectoRef}
              className="hidden"
              accept="image/*"
              onChange={handleRectoChange}
            />
          </div>
          <div className="p-3 border-dashed border h-[fit-content] border-gray-400 rounded-md my-1 relative ">
            {versoImg?.uri === null && (
              <div>
                <h4 className="text-lg font-semibold text-center text-gray-800">
                  {t("verso_img")}
                </h4>
                <h6 className="text-sm font-medium text-center text-gray-600">
                  {("import_img")}
                </h6>
                <img
                  onContextMenu={(event) => {
                    event.preventDefault();
                    window.open(event?.target?.currentSrc, "_blank");
                  }}
                  className="object-contain object-center mx-auto w-14 h-14 "
                  src={NIC_img}
                />
              </div>
            )}
            {versoImg?.uri !== null && (
              <img
                onContextMenu={(event) => {
                  event.preventDefault();
                  window.open(event?.target?.currentSrc, "_blank");
                }}
                src={versoImg?.uri}
                className="h-full w-[100%] object-contain object-center rounded-md"
                alt="user avatar logo"
              />
            )}
            <motion.div
              whileTap={{ scale: 0.95 }}
              whileHover={{ scale: 1.1 }}
              onClick={handleVersoIconClick}
              className="absolute z-30 flex flex-col items-center justify-center rounded-full cursor-pointer right-2 bottom-3 w-7 h-7 bg-[#D90429]"
            >
              <IoPencilSharp className="w-3 h-3 text-white " />
            </motion.div>
            {versoImg?.file !== null && (
              <motion.div
                whileTap={{ scale: 0.95 }}
                whileHover={{ scale: 1.1 }}
                onClick={() => setVersoImg({ uri: null, file: null })}
                className="absolute z-30 flex flex-col items-center justify-center bg-red-500 rounded-full cursor-pointer left-2 top-3 w-7 h-7"
              >
                <IoCloseCircleSharp className="w-3 h-3 text-white " />
              </motion.div>
            )}
            <input
              type="file"
              ref={versoRef}
              className="hidden"
              accept="image/*"
              onChange={handleVersoChange}
            />
          </div>
        </div>
        <LoadingButton
          style={{ width: "fit-content" }}
          title={t("save_changes")}
          onClick={handleSubmit(handleChangeIdentityUser)}
          isLoading={isLoadingChangeIdentityCniProfile}
          disabled={rectoImg?.file === null || versoImg?.file === null}
        />
      </div>
    </Card>
  );
};

export default UserEditProfile;
