import { useStripe } from '@stripe/react-stripe-js';

import visa from "../../assets/images/cards/visa.png";
import mastercard from "../../assets/images/cards/mastercard.png";
import amex from "../../assets/images/cards/americanexpress.png";
import card from "../../assets/images/cards/card.png";
import unionpay from "../../assets/images/cards/unionpay.png";

import { isRealValue } from '../../utilities';


export const cards = {
  amex: {
    length: "15",
    pattern: /^3[47][0-9]{13}$/,
    patternDetect: /^3[47][0-9]*$/,
  },
  visa: {
    length: "13,16", //13 ou 16
    pattern: /^4[0-9]{12}(?:[0-9]{3})?$/,
    patternDetect: /^4[0-9]*$/,
  },
  mastercard: {
    length: "16",
    pattern: /^5[1-5][0-9]{14}$|^2(?:2(?:2[1-9]|[3-9][0-9])|[3-6][0-9][0-9]|7(?:[01][0-9]|20))[0-9]{12}$/,
    patternDetect: /^5[1-5][0-9]*$|^2(?:2(?:2[1-9]|[3-9][0-9])|[3-6][0-9][0-9]|7(?:[01][0-9]|20))[0-9]*$/,
  },
  unionpay: {
    length: "16, 17, 18, 19",  // 16-19
    pattern: /^(62|81)[0-9]{14,17}$/,
    patternDetect: /^(62|81)[0-9]*$/,
  },
};

function detectCardBrand(cardNumber = "") {
  for (const card in cards) {
    if (cards[card].patternDetect.test(cardNumber)) {
      return card;
    }
  }
  return null;
}

export const creditCardType = (cc = " ") => returnField(detectCardBrand(cc))

const returnField = (brand) => { return { brand, image: getCardImage(brand) } }

export function getCardImage(type = "") {
  switch (type) {
    case "amex":
      return amex;
    case "visa":
      return visa;
    case "mastercard":
      return mastercard;
    case "unionpay":
      return unionpay;

    default:
      return card;
  }
}

export const formatCardNumber = (value) => value?.replace(/\s?/g, '')?.replace(/(\d{4})/g, '$1 ')?.trim();

export default function useCustomStripe() {

  const stripe = useStripe()
  const handleNextActionWeb = async (clientSecret) => await stripe.handleNextAction({ clientSecret })
  const retrievePaymentIntentWeb = async (clientSecret) =>  await stripe.retrievePaymentIntent(clientSecret)

  return {
    retrievePaymentIntentWeb,
    handleNextActionWeb,
  }
}

const luhnAlgoCheck = val => {
  let checksum = 0;
  let j = 1;
  for (let i = val.length - 1; i >= 0; i--) {
    let calc = 0;
    calc = Number(val.charAt(i)) * j;
    if (calc > 9) {
      checksum += 1;
      calc -= 10;
    }
    checksum += calc;
    if (j === 1) j = 2;
    else j = 1;
  }
  return (checksum % 10) === 0;
}
const validateCardNumber = number => {
  const regex = new RegExp("^[0-9]{13,19}$");
  if (!regex.test(number)) {
    return false;
  }
  return luhnAlgoCheck(number);
}


export const checkCreditCard = cardNumber => {
  const ccErrors = [
    "unknown_card_type",
    "no_card_number_provided",
    "credit_card_number_is_in_invalid_format",
    "credit_card_number_is_invalid",
    "credit_card_number_has_an_inappropriate_number_of_digits",
    "warning_This_credit_card_number_is_associated_with_a_scam_attempt",
  ];
 
  const response = (success, message = null, type = null) => ({
    message,
    success,
    type
  });
  // cardNumber = isRealValue(cardNumber) ? cardNumber.replaceAll(/\s/g, '') : ""
  if (cardNumber.length === 0) {
    return response(false, ccErrors[1]);
  }
  if (!validateCardNumber(cardNumber)) {
    return response(false, ccErrors[2]);
  }
  if (cardNumber === '5490997771092064') {
    return response(false, ccErrors[5]);
  }
  var lengthValid = false;
  var prefixValid = false;
  var cardCompany = "";

  for (const card in cards) {
    if (cards[card].pattern.test(cardNumber)) {
      prefixValid = true
      const lengths = cards[card].length.split(",");
      for (let j = 0; j < lengths.length; j++) {
        if (cardNumber?.length == parseInt(lengths[j])) {
          lengthValid = true;
          cardCompany = card
          return response(true, null, card);
        }
      }
    }
  }
  if (!prefixValid) {
    return response(false, ccErrors[3]);
  }
  if (!lengthValid) {
    return response(false, ccErrors[4]);
  };
}
