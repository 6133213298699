import React from "react";

import { useSnackbar } from 'notistack'
import { useSelector } from "react-redux";

export function useNotifications() {
  const { theme, lng } = useSelector(state => state.app)
  const { enqueueSnackbar } = useSnackbar();

  
  //success, error, info, default, warning
  const notification = (message, variant, ...props) =>
    enqueueSnackbar(message, {
      variant,
      ...props,
      preventDuplicate: true
    })

  const customNotification = (message, variant) => notification(message, variant)

  const successNotification = (message) => customNotification(message, "success")
  const dangerNotification = (message) => customNotification(message, "error")
  const warningNotification = (message) => customNotification(message, "warning")


  return { successNotification, dangerNotification, warningNotification }
}