
import React, { useEffect, useState } from "react";
import { useNotifications } from "./useNotifications";
import { dispatch } from "../..";
import { handlePlatform } from "../features/AppSlice";


export function useMobile() {
   const [isLandscape, setIsLandscape] = useState(false)
    const [isMobile, setIsMobile] = React.useState(false);
    const [platform, setPlatform] = useState("");
    const { successNotification } = useNotifications()


    useEffect(() => {

        //Mobile device detection
        const userAgent = window.navigator.userAgent.toLowerCase();
        const mobileKeywords = ["iphone", "android", "windows phone",];
        const isMobileDevice = mobileKeywords.some(
            keyword => {
                if (userAgent.includes(keyword)) {
                    // console.log("Mobile OS", keyword);
                    dispatch(handlePlatform(keyword));
                    return true;
                } else {
                    setPlatform("unknown")
                    return false;
                }
            }
        );

        setIsMobile(isMobileDevice);
        
        if (isMobileDevice) {
            // console.log("Mobile device", platform);
            
            // successNotification("Mobile device Detected");
        }

        //Mobile device orientation
        const handleOrientationChange = ()=> {
            setIsLandscape(window.orientation === 90 || window.orientation === -90);
        }

        handleOrientationChange();

        window.addEventListener('orientationchange', handleOrientationChange);

        return ()=> {
            window.removeEventListener('orientationchange', handleOrientationChange);
        }
    }, []);

    return { isLandscape, isMobile, platform };
}
