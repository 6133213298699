
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { getStoreGrade } from '../../app/hooks';
import { useCodeValuesQuery, useGetItemsByStoreQuery, useMenuOfStoreQuery, useStoreByKeyQuery } from '../../app/services/Api';
import LoadingComponent from '../../components/LoadingComponent';
import { classNames, isRealValue, scroolWindowToTop } from '../../utilities';
import { dispatch } from '../..';
import { handleHeaderType } from '../../app/features/authSlice';
import StoreProducts from './StoreProducts';
import StoresProducts from '../StoreProducts';
import { handleContextSearch, handleKeyOfStoreOrCategory } from '../../app/features/AppSlice';
import DropdownStoreMenu from '../../components/layout/DropdownMenu/DropdownStoreMenu';
import LoadingButton from '../../components/common/LoadingButton';
import { STORE } from '../../constants/routes';
import { setTabValue } from '../../app/features/storeSlice';

const tabs = [
  { name: "home", href: "#", current: true },
  { name: "Items", href: "#", current: false }
];

export default function StoreHome() {

  const { category, toViewSizeStore, tabValue } = useSelector(state => state.store)
  // const [tabValue, setTabValue] = useState("home");
  const params = useParams()
  const navigate = useNavigate();
  const key = params?.key
  const { filter } = useSelector(state => state.filters)
  const { user, isLogged } = useSelector(state => state.auth)

  const asAccess = isLogged && (user?.role === "logistician-foreign" || user?.role === "supervisor")

  const { store } = useSelector(
    (state) => state.store
  );

  const { page, limit, itemsFilter } = useSelector(state => state.items)
  const { t } = useTranslation()
  const { isFetching: isLoadingS } = useStoreByKeyQuery(key, { refetchOnReconnect: true, refetchOnMountOrArgChange: true })
  const { isFetching: isLoadingC } = useGetItemsByStoreQuery({ key, params: { page, limit, category, ...filter } }, { refetchOnReconnect: true, refetchOnMountOrArgChange: true })
  const { isFetching: isLoadingM } = useMenuOfStoreQuery({ store: key }, { refetchOnReconnect: true, refetchOnMountOrArgChange: true })
  const { isFetching: isLoadingSG } = useCodeValuesQuery(getStoreGrade)

  const gotoListSizesOfCategory = () =>
    navigate(
      `${STORE}/${store?.key}/sizes/${toViewSizeStore}`
    );

  React.useEffect(() => {
    scroolWindowToTop()
    dispatch(handleContextSearch("stores"))
    dispatch(handleKeyOfStoreOrCategory(key))
  }, [category, filter])

  return (
    <div className="bg-white  ">
      <main>
        <div className="px-4 mx-auto max-w-7xl sm:px-6 md:px-0">
          <div className="py-6">
            {/* Tabs */}
            <div className="lg:hidden">
              <label htmlFor="selected-tab" className="sr-only">
                Select a tab
              </label>
              <select
                id="selected-tab"
                name="selected-tab"
                onClick={(e) => {
                  dispatch(setTabValue(e.target.value));
                }}
                className="block w-full py-2 pl-3 pr-10 mt-1 text-base border-gray-300 rounded-md focus:border-[#D9042990] focus:outline-none focus:ring-[#D9042990] sm:text-sm"
                defaultValue={tabs.find((tab) => tab.current).name}
              >
                {tabs.map((tab) => (
                  <option value={tab.name} className="" key={tab.name}>
                    {tab.name}
                  </option>
                ))}
              </select>
            </div>
            <div className="hidden lg:block">
              <div className="border-b border-gray-200">
                <nav className="flex -mb-px space-x-8">
                  {tabs.map((tab, i) => (
                    <a
                      key={tab.name}
                      onClick={() => dispatch(setTabValue(tab.name))}
                      className={classNames(
                        tabValue === tab.name
                          ? " border-[#D9042990] text-[#D90429] "
                          : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700 ",
                        " whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm cursor-pointer "
                      )}
                    >
                      {t(`${tab.name}`)}
                    </a>
                  ))}
                </nav>
              </div>
            </div>
          </div>
          <div className="flex justify-between items-center w-full">
            <DropdownStoreMenu />
            {asAccess &&
              <LoadingButton
                style={{
                  width: "fit-content",
                }}
                title={t("edt_cat_sizes")}
                onClick={() => gotoListSizesOfCategory()}
                disabled={!isRealValue(toViewSizeStore)}
              />}
          </div>
        </div>
        {tabValue === "home" && (
          <StoreProducts
            isLoading={isLoadingC}
            itemsFilter={itemsFilter}
            key={"home"}
          />
        )}
        {tabValue === "Items" && (
          <StoresProducts
            isLoading={isLoadingC}
            itemsFilter={itemsFilter}
            key={"items"}
          />
        )}
      </main>
      <LoadingComponent
        open={isLoadingS || isLoadingC || isLoadingM || isLoadingSG}
      />
    </div>
  );
}