import { ClassNames } from '@emotion/react'
import React from 'react'

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const InputFlatBorder = ({ name, type, leftIcon, rightIcon, placeholder, label, error, style, inputStyle, rightIconClick, classInput, ...props }) => {
  const [Focus, setFocus] = React.useState(false);
  return (
    <div style={style} className="my-2 w-full ">
      <h3 htmlFor={name} className="block text-sm font-medium text-gray-700">
        {label}
      </h3>
      <div
        className={classNames(
          Focus ? "border-1 ring-[#D90429] " : "ring-gray-300",
          "relative inline-flex w-full space-x-1 ring-1 rounded-xl bg-transparent h-[40px] hover:ring-[#D90429] "
        )}
      >
        {leftIcon && (
          <div className=" inset-y-0 mr-2 flex items-center pl-3">
            {leftIcon}
          </div>
        )}
        <input
          type={type}
          name={name}
          onFocus={() => setFocus(true)}
          onBlur={() => setFocus(false)}
          style={inputStyle}
          className={classNames(
            error
              ? "focus:border-red-500 focus:ring-red-500 border-red-500 ring-red-500 "
              : "border-transparent outline-none focus:outline-none ",
            "block w-full h-full bg-transparent ring-0 ring-transparent focus:ring-0 focus:ring-transparent rounded-xl outline-none focus:outline-none focus:border-0 focus:border-transparent border-0 py-1.5  text-gray-900 placeholder:text-gray-400 sm:text-sm sm:leading-6"
          )}
          placeholder={placeholder}
          {...props}
        />
        {rightIcon && (
          <div
            onClick={rightIconClick}
            className=" inset-y-0 pr-5 flex items-center cursor-pointer"
          >
            {rightIcon}
          </div>
        )}
      </div>
      {error && (
        <h5 className="text-red-500 text-xs font-normal text-left block ">
          {error}
        </h5>
      )}
    </div>
  );
}

export default InputFlatBorder