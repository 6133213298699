import React from "react";
import Carousel from "nuka-carousel";

import men_shoes from "../../assets/images/pngimg.com_men_shoes.png"
import women_shoes from "../../assets/images/pngimg.com - women_shoes.png"
import computers from "../../assets/images/pngimg.com - iphone_14.png"
import sport_shoes from "../../assets/images/chaussures de sport.png"
import laptops from "../../assets/images/pngimg.com - laptop.png";
import t_shirt from "../../assets/images/pngimg.com - tshirt.png";
import football from "../../assets/images/pngimg.com - football_boots.png";
import hand_bag from "../../assets/images/hand bag.png";
import sport_wear from "../../assets/images/sportwear.png";
import camera from "../../assets/images/camera.png";
import men_watch from "../../assets/images/men watch.png";
import jewels from "../../assets/images/jewelry.png";

import "./styles.css";

import { Box } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { dispatch } from "../..";
import { Api } from "../../app/services/Api";
import { PRODUCT, PRODUCT_DETAILS } from "../../constants/routes";
import { handleInvalidateVariants } from "../../app/features/itemSlice";
import { handleItemCategory } from "../../app/features/categoriesSlice";
import { useTranslation } from "react-i18next";

export default function HomeSwiper() {

  const navigate = useNavigate();
  const { t } = useTranslation()

  const handlerItemsByCategory = (key) => {
    // console.log('key', key)
    navigate(`${PRODUCT}/${key}?page=1`);
    dispatch({
      type: "categories/selectedCategoryItem",
    });
    dispatch(Api.endpoints.getItemsByCategory.initiate({ key: key }));
  };

  const handlerDetailsItems = function (e, key) {
    dispatch(handleInvalidateVariants());
    dispatch(handleItemCategory(key));
    e.preventDefault();
    navigate(`${PRODUCT_DETAILS}/${key}`);
  };

  const sections = [
    {
      name: t("men_shoes"),
      img: men_shoes,
      bgcolor: "#FFBE0B",
      onClick: () => handlerItemsByCategory(102274),
    },
    {
      name: t("women_shoes"),
      img: women_shoes,
      bgcolor: "#FB5607",
      onClick: () => handlerItemsByCategory(101270),
    },
    {
      name: t("football_items"),
      img: football,
      bgcolor: "#DDA15E",
      onClick: () => handlerItemsByCategory(301211),
    },
    {
      name: t("phone"),
      img: computers,
      bgcolor: "#FF006E",
      onClick: () => handlerItemsByCategory(405110),
    },
    {
      name: t("t_shirts"),
      img: t_shirt,
      bgcolor: "#83C5BE",
      onClick: () => handlerItemsByCategory(102117),
    },
    {
      name: t("hand_bag"),
      img: hand_bag,
      bgcolor: "#DDA15E",
      onClick: () => handlerItemsByCategory(101613),
    },
    {
      name: t("sprt_shoes"),
      img: sport_shoes,
      bgcolor: "#8338EC",
      onClick: () => handlerItemsByCategory(301200),
    },
    {
      name: t("laptops"),
      img: laptops,
      bgcolor: "#83C57E",
      onClick: () => handlerItemsByCategory(401210),
    },
    {
      name: t("men_watch"),
      img: men_watch,
      bgcolor: "#DDA15E",
      onClick: () => handlerItemsByCategory(102500),
    },
    {
      name: t("jewelry"),
      img: jewels,
      bgcolor: "#83C57E",
      onClick: () => handlerItemsByCategory(101400),
    },
    {
      name: t("sprt_wear"),
      img: sport_wear,
      bgcolor: "#83C5BE",
      onClick: () => handlerItemsByCategory(301100),
    },
    {
      name: t("camera"),
      img: camera,
      bgcolor: "#DDA15E",
      onClick: () => handlerItemsByCategory(407100),
    },
  ];

  return (
    <div className="grid grid-cols-2 grid-rows-4 lg:grid-cols-6 lg:grid-rows-2 gap-2 ">
      <div className="col-span-2 row-span-2 lg:row-span-2 lg:col-span-2 ">
        <Carousel
          autoplay
          autoplayInterval={5000}
          wrapAround
          defaultControlsConfig={{
            pagingDotsContainerClassName: "dot-container",
            pagingDotsClassName: "dot",
            containerClassName: "container-cl",
            pagingDotsStyle: {
              backgroundColor: "transparent",
              margin: `0px 5px`,
              color: "magenta",
            },
          }}
          adaptiveHeightAnimation
          dragging
        >
          <>
            <Box
              onClick={(e) => handlerDetailsItems(e, 12063204)}
              className="home-hero-1 cursor-pointer "
            ></Box>
          </>
          <>
            <Box
              onClick={(e) => handlerDetailsItems(e, 12065922)}
              className="home-hero-2 cursor-pointer "
            ></Box>
          </>
          <>
            <Box
              onClick={(e) => handlerDetailsItems(e, 12045600)}
              className="home-hero-3 cursor-pointer "
            ></Box>
          </>
          <>
            <Box
              onClick={(e) => handlerDetailsItems(e, 12056328)}
              className="home-hero-4 cursor-pointer "
            ></Box>
          </>
          <>
            <Box
              onClick={(e) => handlerDetailsItems(e, 10041984)}
              className="home-hero-5 cursor-pointer "
            ></Box>
          </>
          <>
            <Box
              onClick={(e) => handlerDetailsItems(e, 10813549)}
              className="home-hero-6 cursor-pointer "
            ></Box>
          </>
          <>
            <Box
              onClick={(e) => handlerDetailsItems(e, 10000674)}
              className="home-hero-7 cursor-pointer "
            ></Box>
          </>
          {/* <>
            <Box
              onClick={(e) => handlerDetailsItems(e, 10096038)}
              className="home-hero-8 cursor-pointer "
            ></Box>
          </> */}
        </Carousel>
      </div>
      <div className="row-span-2 col-span-2 lg:col-span-4 w-[100%] grid grid-cols-2 grid-rows-6 sm:grid-cols-3 sm:grid-rows-4 md:grid-cols-4 md:grid-rows-3 gap-1">
        {sections.map(({ name, img, onClick }, index) => (
          <div
            onClick={onClick}
            key={index}
            className="w-[100%] cursor-pointer bg-gray-100 flex flex-col items-center justify-center rounded-md p-2  "
          >
            <img
              onContextMenu={(event) => {
                event.preventDefault();
                window.open(event?.target?.currentSrc, "_blank");
              }}
              alt="item"
              src={img}
              className="w-16 h-16 lg:w-20 lg:h-20 xl:w-26 xl:h-26 object-contain object-center mx-auto "
            />
            <div className="px-3 mx-auto ">
              <h4 className="text-lg text-gray-00 text-center font-semibold">
                {name}
              </h4>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}