import React from 'react'
import { useSelector } from 'react-redux'
import MenuItems from '../../common/MenuItems'
import './styles.css'
import { motion } from 'framer-motion'


const DropdownMenu = () => {
  const { data } = useSelector((state) => state.categories);

  return (
    <motion.ul
      key={data?.id}
      initial={{
        opacity: 0,
      }}
      animate={{
        opacity: 1,
        y: 0,
      }}
      transition={{ delay: 0.4, ease: "easeIn" }}
      exit={{
        opacity: 0,
        y: -1000,
      }}
      className="bg-white shadow-md absolute top-[3.4rem] rounded-md p-3 w-[fit-content]  "
    >
      {data?.map((menu, i) => {
        const depthLevel = 0;
        return (
          <MenuItems
            items={menu}
            key={i}
            depthLevel={depthLevel}
            AllCategories={data}
           
          />
        );
      })}
    </motion.ul>
  );
};

export default DropdownMenu