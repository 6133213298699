import React, { Fragment } from 'react'
import { Listbox, Transition } from '@headlessui/react'
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid'
import { classNames, isRealValue } from '../../../utilities'


const SelectMenu = ({
  data = [],
  classStyle = "",
  label = "",
  style,
  defaultText = "",
  onChange = (v) => { },
  display = (v) => v?.defaultText,
  multiple = false,
  disabled = false,
  selectedValues,
  errorProp
}) => {
  const onChangeValues = (v) => {
    if (!multiple) {
      onChange(v);
    }
  };

  const selectMenu = (v) => onChangeValues(v);

  return (
    <Listbox value={selectedValues} onChange={selectMenu} disabled={disabled}  >
      {({ open }) => (
        <div style={style} className={classNames("w-full my-0" + classStyle)}>
          {label && (
            <h3 className="block text-sm font-medium text-gray-700 cursor-pointer">
              {label}
            </h3>
          )}
          <div className={classNames(errorProp && "border border-[#D90429]", "relative cursor-pointer")}>
            <Listbox.Button className="relative w-full py-2 pl-3 pr-10 text-left bg-white border border-gray-300 rounded-md shadow-sm cursor-default focus:border-[#D90429] focus:outline-none focus:ring-1 focus:ring-[#D90429] sm:text-sm">
              <span className="block truncate">
                {isRealValue(selectedValues)
                  ? display(selectedValues)
                  : defaultText}
              </span>
              <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                <ChevronUpDownIcon
                  className="w-5 h-5 text-gray-400"
                  aria-hidden="true"
                />
              </span>
            </Listbox.Button>

            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options className="absolute z-10 w-full py-1 mt-1 overflow-auto text-base bg-white rounded-md shadow-lg max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                {data?.map((item, index) => (
                  <Listbox.Option
                    key={index}
                    className={({ active }) =>
                      classNames(
                        active ? "text-white bg-[#D90429]" : "text-gray-900",
                        "relative cursor-default select-none py-2 pl-8 pr-4"
                      )
                    }
                    value={item}
                  >
                    {({ selected, active }) => (
                      <>
                        <span
                          className={classNames(
                            selected ? "font-semibold" : "font-normal",
                            "block truncate"
                          )}
                        >
                          {display(item)}
                        </span>
                        {selected ? (
                          <span
                            className={classNames(
                              active ? "text-white" : "text-[#D90429]",
                              "absolute inset-y-0 left-0 flex items-center pl-1.5"
                            )}
                          >
                            <CheckIcon className="w-5 h-5" aria-hidden="true" />
                          </span>
                        ) : null}
                      </>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </div>
      )}
    </Listbox>
  );
};

export default SelectMenu