import React, { useState } from 'react'
import PageTitle from '../components/common/PageTitle'
import { BsFillPersonFill } from 'react-icons/bs'
import UserProfileSection from '../components/UserProfile/UserProfileSection'
import UserEditProfile from '../components/UserProfile/UserEditProfile'
import { useAllOrdersQuery, useCodeValuesQuery, useInfosAccountBankQuery } from '../app/services/Api'
import LoadingComponent from '../components/LoadingComponent'
import { getOrderOrderStatus } from '../app/hooks'
import { useTranslation } from 'react-i18next'

const UserProfile = () => {
  const [Edit, setEdit] = useState(false)
  const { t } = useTranslation()
  const handleEditState = () => setEdit(!Edit);
  const { isLoading } = useAllOrdersQuery();
  const { isFetching: isLoadingInfos } = useInfosAccountBankQuery()
  const { isFetching: isLoadingS } = useCodeValuesQuery(getOrderOrderStatus, { refetchOnReconnect: true })

  return (
    <div className="h-[fit-content] w-full xs:px-4 ">
      <PageTitle
        title={t("my_profile")}
        style={{ fontSize: 25 }}
        btnText={!Edit ? t("edit_pfl") : t("back_pfl")}
        onBtnClick={handleEditState}
        btnStyle={{ margin: `0px 0px`, width: `fit-content` }}
        icon={<BsFillPersonFill color="#D90429" size={22} />}
      />
      {Edit ? <UserEditProfile /> : <UserProfileSection />}
      <LoadingComponent open={isLoading || isLoadingInfos || isLoadingS} />
    </div>
  );
}

export default UserProfile