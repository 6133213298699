import React from 'react'
import AddressTable from '../../components/ShippingAddress/AddressTable';
import AddAddressForm from '../../components/AddAddressForm';
import ModalFlat from '../../components/ModalFlat';
import { useTranslation } from 'react-i18next';
import LoadingComponent from '../../components/LoadingComponent';
import { useGetAddressesByUserQuery } from '../../app/services/Api';
import { scroolWindowToTop } from '../../utilities';

const ShippingAddresses = () => {
  const [isOpen, setIsOpen] = React.useState(false)
  function closeModal() {
    setIsOpen(false)
  }
  function openModal() {
    setIsOpen(true)
  }
  const { t } = useTranslation()
  const { isFetching: isLoading } = useGetAddressesByUserQuery();
  React.useEffect(() => {
    scroolWindowToTop()
  }, [])
  return (
    <div className="px-4 sm:px-6 lg:px-8" >
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-2xl font-semibold text-gray-900">{t("Shipping Addresses")}</h1>
          <p className="mt-2 text-sm text-gray-700">
            A list of all the shipping addresses in your account.
          </p>
        </div>
        <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
          <button
            type="button"
            onClick={openModal}
            className="inline-flex items-center justify-center px-4 py-2 text-sm font-medium text-white border border-transparent rounded-md shadow-sm bg-[#D90429] hover:bg-[#D9042990] focus:outline-none focus:ring-2 focus:ring-[#D90429] focus:ring-offset-2 sm:w-auto"
          >
            Add address
          </button>
        </div>
      </div>
      <AddressTable />
      <ModalFlat
        isOpen={isOpen}
        children={<AddAddressForm closeModal={closeModal} />}
        title={"Add New Address"}
        closeModal={closeModal}
      />
      <LoadingComponent open={isLoading} />
    </div>
  )
}

export default ShippingAddresses
