import { DeleteOutline, SaveOutlined } from '@mui/icons-material'
import { Box, Button, Card, CardActionArea, CardContent, CardHeader, Divider, TextField, Typography } from '@mui/material'
import React from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useDeleteAccountBankByIdMutation } from '../../app/services/Api'
import InputFlatBorder from '../../components/common/InputFlatBorder'
import ButtonFlat from '../../components/ButtonFlat'

const Update = (props) => {
    const { bank } = props
    const [deleteAccountBankById, { isLoading, error }] = useDeleteAccountBankByIdMutation()
    const { control, handleSubmit, formState: { errors }, } = useForm(
        {
            defaultValues: {
                code: bank.code,
                country: bank.country,

                bankName: bank.bank.name,
                bankBranch: bank.bank.branch,
                bankAddress: bank.bank.address,

                accountHolderName: bank.accountHolder.name,
                accountHolderAddress: bank.accountHolder.address,

                accountCodeBank: bank.accountCode.bank,
                accountCodeBranch: bank.accountCode.branch,
                accountCodeAccount: bank.accountCode.account,
                accountCodeKey: bank.accountCode.key,
            },
        }
    );
    const handleUpdateAccountBank = (data) => {
        // console.log('data :>> ', getData(data));
      
    }
    const deleteById = () => {
        deleteAccountBankById(bank?._id).unwrap().then((resp) => {
            // console.log('resp :>> ', resp);
        }).catch((err) => {
            console.error(err);
        })
    }

    function getData(data) {
        return {
            "code": data?.code,
            "country": data?.country,
            "bank": {
                "name": data?.bankName,
                "branch": data?.bankBranch,
                "address": data?.bankAddress,
            },
            "accountHolder": {
                "name": data?.accountHolderName,
                "address": data?.accountHolderAddress
            },
            "accountCode": {
                "bank": data?.accountCodeBank,
                "branch": data?.accountCodeBranch,
                "account": data?.accountCodeAccount,
                "key": data?.accountCodeKey
            }
        }
    }

    return (
        <Box >
            <Controller
                control={control}
                rules={{
                    required: true,
                }}
                render={({ field: { onChange, onBlur, value } }) => (
                    <InputFlatBorder
                        label={"code"}
                        autoCorrect
                        placeholder={bank.code}
                        value={value}
                        onChange={onChange}
                        onBlur={onBlur}
                        variant='standard'
                        type="text"
                    />
                )}
                name="code"
            />
            <Controller
                control={control}
                rules={{
                    required: true,
                }}
                render={({ field: { onChange, onBlur, value } }) => (
                    <InputFlatBorder
                        label={"country"}
                        autoCorrect
                        placeholder={bank.country}
                        value={value}
                        onChange={onChange}
                        variant='standard'
                        type="text"
                    />
                )}
                name="country"
            />
            <div className="grid grid-cols-1 gap-2 p-3 md:grid-cols-1 md:gap-1" >
                <Card >
                    <CardHeader title={"Bank"} />
                    <CardContent>
                        <Controller
                            control={control}
                            rules={{
                                required: true,
                            }}
                            render={({ field: { onChange, onBlur, value } }) => (
                                <InputFlatBorder
                                    label={"name"}
                                    autoCorrect
                                    placeholder='bank name'
                                    value={value}
                                    onChange={onChange}
                                    variant='standard'
                                    type="text"
                                />
                            )}
                            name="bankName"
                        />
                        <Controller
                            control={control}
                            rules={{
                                required: true,
                            }}
                            render={({ field: { onChange, onBlur, value } }) => (
                                <InputFlatBorder
                                    label={"Branch"}
                                    autoCorrect
                                    placeholder='bank branch'
                                    value={value}
                                    onChange={onChange}
                                    variant='standard'
                                    type="text"
                                />
                            )}
                            name="bankBranch"
                        />
                        <Controller
                            control={control}
                            rules={{
                                required: true,
                            }}
                            render={({ field: { onChange, onBlur, value } }) => (
                                <InputFlatBorder
                                    label={"Address"}
                                    onBlur={onBlur}
                                    placeholder='Bank address'
                                    value={value}
                                    onChange={onChange}
                                    variant='standard'
                                    type="text"
                                />
                            )}
                            name="bankAddress"
                        />
                    </CardContent>
                </Card>
                <Card >
                    <CardHeader title={"Account holder"} />
                    <CardContent>
                        <Controller
                            control={control}
                            rules={{
                                required: true,
                            }}
                            render={({ field: { onChange, onBlur, value } }) => (
                                <InputFlatBorder
                                    label={"name"}
                                    autoCorrect
                                    placeholder='account holder name'
                                    value={value}
                                    onBlur={onBlur}
                                    onChange={onChange}
                                    variant='standard'
                                    type="text"
                                />
                            )}
                            name="accountHolderName"
                        />
                        <Controller
                            control={control}
                            rules={{
                                required: true,
                            }}
                            render={({ field: { onChange, onBlur, value } }) => (
                                <InputFlatBorder
                                    label={"Address"}
                                    autoCorrect
                                    placeholder='Account holder address'
                                    value={value}
                                    onChange={onChange}
                                    onBlur={onBlur}
                                    variant='standard'
                                    type="text"
                                />
                            )}
                            name="accountHolderAddress"
                        />
                    </CardContent>
                </Card>
            </div>
            <Card >
                <CardHeader title={"Account code"} />
                <CardContent>
                    <Controller
                        control={control}
                        rules={{
                            required: true,
                        }}
                        render={({ field: { onChange, onBlur, value } }) => (
                            <InputFlatBorder
                                label={"Bank"}
                                placeholder='Account code Bank'
                                value={value}
                                onChange={onChange}
                                variant='standard'
                                type="number"
                            />
                        )}
                        name="accountCodeBank"
                    />
                    <Controller
                        control={control}
                        rules={{
                            required: true,
                        }}
                        render={({ field: { onChange, onBlur, value } }) => (
                            <InputFlatBorder
                                label={"Branch"}
                                placeholder='Account code branch'
                                value={value}
                                onChange={onChange}
                                variant='standard'
                                type="number"
                            />
                        )}
                        name="accountCodeBranch"
                    />
                    <Controller
                        control={control}
                        rules={{
                            required: true,
                        }}
                        render={({ field: { onChange, onBlur, value } }) => (
                            <InputFlatBorder
                                label={"Account"}
                                placeholder='Account code account'
                                value={value}
                                onChange={onChange}
                                variant='standard'
                                type="number"
                            />
                        )}
                        name="accountCodeAccount"
                    />
                    <Controller
                        control={control}
                        rules={{
                            required: true,
                        }}
                        render={({ field: { onChange, onBlur, value } }) => (
                            <InputFlatBorder
                                label={"Key"}
                                placeholder='Account code key'
                                value={value}
                                onChange={onChange}
                                variant='standard'
                                type="number"
                            />
                        )}
                        name="accountCodeKey"
                    />
                </CardContent>
            </Card>
            <div className="grid grid-cols-2 mx-auto max-w-[440px] gap-1 " >
                        <ButtonFlat
                            disabled={true}
                            startIcon={<SaveOutlined />}
                            style={{
                                borderRadius: 20,
                                marginHorizontal: 0,
                            }}
                            title={"Update"}
                            onClick={handleSubmit(handleUpdateAccountBank)}
                            loading={isLoading} />
                        <ButtonFlat
                            startIcon={<DeleteOutline />}
                            style={{
                                borderRadius: 20,
                                marginHorizontal: 4,
                            }}
                            title= {"Delete"}
                            onClick={() => deleteById()}
                            loading={isLoading} />
                    </div>
        </Box>
    );
}

export default Update
