import { CircularProgress } from '@mui/material'
import React from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useResetPasswordMutation } from '../../../app/services/Api'
import Button from '../../common/Button'
import Input from '../../common/Input'
import './styles.css'
import { useTranslation } from 'react-i18next'
import CountryPicker from '../../common/CountryPicker'
import InputFlatBorder from '../../common/InputFlatBorder'
import useMutationApi from '../../../app/hooks/useMutationApi'

const ForgotForm = () => {
  const { t } = useTranslation();

  const { control, handleSubmit, formState: { errors }, } = useForm({ defaultValues: { userEmailPhone: '', callingCode: '' } });
  const { resetPasswordByUser, isLoadingResetPassword } = useMutationApi()
  const handleResetPassword = (data) => {
    // console.log('data', data)
    resetPasswordByUser({
    callingCode: data.callingCode.callingCode,
    userEmailPhone: data.userEmailPhone
  })
}


  const Separator = ({ text, viewTest }) => (
    <div className='row-flex-space' style={{ width: '100%', justifyContent: 'space-around', }} >
      <div style={{ backgroundColor: 'lightgray', width: '35%', height: 1 }} ></div>
      {viewTest && <p style={{ fontSize: 18, fontWeight: 'bold', color: 'lightgray' }} > {text} </p>}
      <div style={{ backgroundColor: 'lightgray', width: '35%', height: 1 }}  ></div>
    </div>
  )

  return (
    <form className='reg-form' style={{ width: '100%' }} onSubmit={handleSubmit(handleResetPassword)} >
      <Controller control={control} rules={{ required: true, }}
        render={({ field: { onChange } }) => (
          <CountryPicker
            onSelect={onChange}
            errorProps={errors?.callingCode}
            // containerStyle={{ marginVertical: 25 }}
          />
        )}
        name="callingCode"
      />
      <Controller
        control={control}
        rules={{
          required: true,
          validate:()=>{},
          // pattern: paternemail
        }}
        render={({ field: { onChange, value } }) => (
          <InputFlatBorder
            error={errors?.userEmailPhone?.type}
            type='text'  
            placeholder={t("username_mail_phone")}  
            value={value}  
            onChange={onChange}
          />
        )}
        name="userEmailPhone"
      />
      {/* <p>{isSuccess && <span>{t("check_email")}</span>}</p> */}
      {isLoadingResetPassword ? <CircularProgress sx={{ color: "#D90429" }} /> :
        <Button
          title={t("reset_pass")}
          style={{ marginTop: 10, backgroundColor: '#D90429' }}
          type="submit"
        />
      }
    </form>
  )
}

export default ForgotForm