import React, { useEffect } from 'react';
import { motion } from 'framer-motion';
import './styles.css'

const ActionButton = (props)=> {
    const { activeIcon, inactiveIcon, id, activ, addBookmarkItem, style } =
      props;
    const [ active, setActive ] = React.useState(activ)
    const [ actionStyle, setActionStyle ] = React.useState('action-btn')

     useEffect(() => {
       getStyle()
     }, [active])
    

    const getStyle = ()=> {
        active ? setActionStyle('action-btn active') : setActionStyle('action-btn inactive')
    }


    return (
      <motion.div
        id={id}
        style={style}
        whileHover={{ scale: 1.12 }}
        whileTap= {{ scale: 0.98 }} 
        onClick={ (e) => { e.preventDefault(); addBookmarkItem(); setActive(!active) }} 
        className= {`${actionStyle}`}  
      >
        { active ? activeIcon : inactiveIcon }
      </motion.div>
    )
  }

  export default ActionButton;