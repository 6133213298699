import { RadioGroup } from '@headlessui/react'
import { useSelector } from 'react-redux'
import ListPlaceholders from '../common/Skeletons/ListPlaceholders'
import LoadingButton from '../common/LoadingButton'
import { useTranslation } from 'react-i18next'
import { handleSelectLocation } from '../../app/features/locationSlice'
import useMutationApi from '../../app/hooks/useMutationApi'
import { isRealValue } from '../../utilities'
import { dispatch } from '../../app'
import { useFormatter } from '../../app/hooks/useFormatter'
import { useNotifications } from '../../app/hooks/useNotifications'
import React from 'react'

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function BranchesSelect({ isLoadingBranch, showButton = true }) {

  const { t } = useTranslation();
  const { lng } = useSelector(state => state.app)
  const { order } = useSelector(state => state.bookmarkCard);
  const { locationsBranchs, selectedLocation } = useSelector(state => state.locations)
  const { buildOrderByUser, isCreateOrder } = useMutationApi();
  const { display, getLocationServiceData } = useFormatter()

    const { dangerNotification } = useNotifications();


   const [currentPosition, setCurrentPosition] = React.useState(null);

   React.useEffect(() => {
     // Function to get the current position
     const getCurrentPosition = () => {
       if (navigator.geolocation) {
         navigator.geolocation.getCurrentPosition(
           (position) => {
             const { latitude, longitude } = position.coords;
             setCurrentPosition({ latitude, longitude });
           },
           (error) => {
             dangerNotification(
               "Error getting current position:" + error.message
             );
           }
         );
       } else {
         console.error("Geolocation is not supported by this browser.");
       }
     };

     getCurrentPosition();
   }, []);

   const openGoogleMaps = (latitude, longitude) => {
     const url = `https://www.google.com/maps?q=${latitude},${longitude}`;
     window.open(url, "_blank");
   };

   const openGoogleMapsDirections = (latitude, longitude) => {
     if (currentPosition != null) {
       const url = `https://www.google.com/maps/dir/${currentPosition?.latitude},${currentPosition?.longitude}/${latitude},${longitude}`;
       window.open(url, "_blank");
       return;
     }

     dangerNotification("Error getting current position. Try again");
   };

  if (isLoadingBranch) {
    return <div className="p-6" >
      <ListPlaceholders />
    </div>
  }

  return (
    <div className="p-6">
      <RadioGroup value={selectedLocation} onChange={()=>null}>
        <RadioGroup.Label className="sr-only"> Server size </RadioGroup.Label>
        <div className="space-y-4">
          {locationsBranchs?.map((plan, index) => {
            const setMode = () => {
              dispatch(handleSelectLocation(plan));
            };
            return (
              <RadioGroup.Option
                key={index}
                value={plan}
                onClick={setMode}
                className={({ checked, active }) =>
                  classNames(
                    checked ? "border-transparent" : "border-gray-300",
                    active ? "border-[#D90429] ring-2 ring-[#D90429]" : "",
                    "relative block cursor-pointer rounded-lg border bg-white px-6 py-4 shadow-sm focus:outline-none sm:flex sm:justify-between"
                  )
                }
              >
                {({ active, checked }) => (
                  <>
                    <span className="flex items-center">
                      <span className="flex flex-col text-sm">
                        <RadioGroup.Label
                          as="span"
                          className="font-medium text-gray-900"
                        >
                          {plan?.name[lng]}
                        </RadioGroup.Label>
                        <RadioGroup.Description
                          as="span"
                          className="text-gray-500"
                        >
                          <span className="block text-gray-600 sm:inline">
                            {plan?.address[lng]}
                          </span>{" "}
                          <br />
                          <span className="block sm:inline">
                            {plan?.openingHour[lng]}
                          </span>{" "}
                          <br />
                          <span className="block sm:inline">
                            tel: {plan?.phone}
                          </span>
                        </RadioGroup.Description>
                      </span>
                    </span>
                    <RadioGroup.Description className="space-x-1 divide-x-2 divide-gray-400 text-sm font-medium text-right whitespace-nowrap sm:pr-6">
                      <span
                        onClick={() =>
                          openGoogleMaps(
                            plan?.geometry?.coordinates[1],
                            plan?.geometry?.coordinates[0]
                          )
                        }
                        className="text-[10px] text-[#D90429] cursor-pointer hover:underline"
                      >
                        {t("map_view")}
                      </span>
                      <span
                        onClick={(e) =>
                          openGoogleMapsDirections(
                            plan?.geometry?.coordinates[1],
                            plan?.geometry?.coordinates[0]
                          )
                        }
                        className="text-[10px] text-[#D90429] cursor-pointer hover:underline ml-1"
                      >
                        {t("get_dir")}
                      </span>
                    </RadioGroup.Description>
                    <span
                      className={classNames(
                        active ? "border" : "border-2",
                        checked ? "border-[#D90429]" : "border-transparent",
                        "pointer-events-none absolute -inset-px rounded-lg"
                      )}
                      aria-hidden="true"
                    />
                  </>
                )}
              </RadioGroup.Option>
            );
          }
          )}
        </div>
      </RadioGroup>
      {showButton && <LoadingButton
        style={{ marginTop: 30, marginBottom: 30 }}
        title={t("save command")}
        onClick={() => buildOrderByUser({ branch: selectedLocation })}
        loading={isCreateOrder}
        disabled={
          !(isRealValue(selectedLocation?._id) && isRealValue(order?.order?.pickupLocation))
        }
      />}
    </div>
  );
}
