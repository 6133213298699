import React from 'react'
import { Box, Typography } from '@mui/material'
import { PathTitle, PathUrl } from "./style";
import { useSelector } from 'react-redux'
import { useParams, useSearchParams } from 'react-router-dom'
import { useGetItemsByCategoryQuery } from '../../app/services/Api'
import { dispatch } from '../../app'
import ProductList from './ProductsList'
import { handleContextSearch, handleKeyCat, handleKeyOfStoreOrCategory } from '../../app/features/AppSlice';
import { isRealValue, scroolWindowToTop } from '../../utilities';
import { returnFilterWithoutLang } from '../../app/hooks';
import { useTranslation } from 'react-i18next';

const Products = () => {

  const { keyCat } = useSelector(state => state.app)
  const { selectedCategoryItem } = useSelector((state) => state.categories);
  const { pagination } = useSelector(state => state.items)
  const params = useParams()
  const category = params?.key ?? ""

  const [searchParams, setSearchParams] = useSearchParams();
  const page = keyCat !== category ? 1 : searchParams.get('page') ?? 1

  var filterGet = {}
  for (let entry of searchParams.entries()) {
    if (isRealValue(entry[1])) {
      filterGet[entry[0]] = entry[1]
    }
  }
  filterGet['page'] = page
  // filterGet['lang'] = lng

  const [request, setRequest] = React.useState(returnFilterWithoutLang(filterGet))
  const [rangePrice, setRangePrice] = React.useState(
    {
      priceMin: filterGet?.priceMin ? filterGet?.priceMin : "",
      priceMax: filterGet?.priceMax ? filterGet?.priceMax : ""
    })

  const resetApplyFilter = () => {
    setRangePrice({ priceMin: "", priceMax: "" })
    setSearchParams({})
    setRequest({})
  }
  const setRequestParams = (obj) => {
    setRequest(returnFilterWithoutLang(obj))
    setSearchParams(obj)
  }

  React.useEffect(() => {
    if (keyCat !== category) {
      dispatch(handleKeyCat(category))
      setRequestParams({ ...filterGet, page: 1 })
    } else {
      setRequestParams(filterGet)
    }
    dispatch(handleContextSearch("categories"))
    dispatch(handleKeyOfStoreOrCategory(category))
    scroolWindowToTop()
  }, [category, searchParams])
  const { t } = useTranslation()

  const { isFetching: isLoading } = useGetItemsByCategoryQuery({ key: category, params: { ...request } }, { refetchOnMountOrArgChange: true })

  return (
    <>
      <div>
        <Box>
          <PathUrl variant="h6">
            {t("home")} / {t("category")} /{selectedCategoryItem}
          </PathUrl>
          <div className="flex items-center justify-between" >
            <PathTitle variant="h4">{selectedCategoryItem}</PathTitle>

            <Typography
              className="truncate"

              style={{
                marginLeft: 20,
                color: "#979797",
              }}
            >
              {t("showing_x_to_y_result", { count: pagination?.count, total: pagination?.total })}
            </Typography>
          </div>
        </Box>
        <ProductList
          isLoading={isLoading}
          params={request}
          setParams={setRequestParams}
          rangePrice={rangePrice}
          setRangePrice={setRangePrice}
          resetApplyFilter={resetApplyFilter}
        />
      </div>
    </>
  );
}
export default Products