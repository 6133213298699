
import React from 'react'

import { useSelector } from 'react-redux'
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { PRODUCT, SEARCH, STORE } from '../../../constants/routes'
import { dispatch } from '../../../app'
import { handleSearchElement } from '../../../app/features/AppSlice'

import FilterSelect from '../FilterSelect'
import InputFlatBorder from '../InputFlatBorder'
import { IoCloseSharp, IoSearchSharp } from 'react-icons/io5'
import { useTranslation } from 'react-i18next'
import { getObjectAsSearchParameter, isRealValue } from '../../../utilities'
import { useMediaQuery } from '@mui/material'
import MenuMobile from '../Menus/indexMobIle'
function classNames(...classes) {
  return classes.filter(Boolean).join('')
}

export default function SearchInput({ placeholder, showOption = true }) {

  const { searchElement } = useSelector(state => state.app)
  const [searchTxt, setSearchTxt] = React.useState("");
  const { t } = useTranslation()
  const navigate = useNavigate()
  const onSelectSearchElement = (v) => dispatch(handleSearchElement(v.value))
  const location = useLocation()
  const [searchParams] = useSearchParams()
  const params = useParams()

  const resize = useMediaQuery("(max-width:420px)");


  const keywordSearch = searchParams.get("keyword") ?? ""
  const context = searchParams.get("c") ?? null
  const key = searchParams.get("key") ?? null

  const { pathname } = location
  const inProductsPage = pathname.startsWith(PRODUCT)
  const inStorePage = pathname.startsWith(STORE)
  const inSearchPage = pathname.startsWith(SEARCH)

  const searchText = (e) => {
    e.preventDefault()
    var searchP = {}
    if (inProductsPage){
      searchP = { c: "categories", key: searchParams.get("category") }
    } else if (inStorePage){
      searchP = { c: "stores", key: params?.key }
    }
    else if (inSearchPage) {
      if (isRealValue(context)) searchP = { ...searchP , c: context}
      if (isRealValue(key)) searchP = { ...searchP, key }
      if (isRealValue(keywordSearch)) searchP = { ...searchP, keyword: keywordSearch }
    }
      const param = {
        ...searchP,
        keyword: searchTxt
      }
    navigate(`${SEARCH}${getObjectAsSearchParameter(param)}`);
  }

  const RenderLeftIcon = () => {
    return (
      <div className="flex flex-row items-center space-x-2">
        {searchTxt.length > 0 &&
          <IoCloseSharp
            onClick={() => setSearchTxt("")}
            className="w-6 h-6 text-gray-700 cursor-pointer "
          />}
        <div
          onClick={searchText}
          id={"searchBtn"}
          className={classNames(
            // searchTxt.length > 0 ? "bg-[#D90429]" : "bg-transparent",
            "bg-[#D90429]",
            " flex flex-row justify-center items-center h-7 w-7 rounded-full cursor-pointer "
          )}
        >
          <IoSearchSharp
            className={classNames(
              searchTxt.length > 0 ? "text-[#fff]" : "text-gray-600",
              " h-4 w-4"
            )}
          />
        </div>
      </div>
    );
  }

  const handleWrite = (e) => {
    const v = e.target.value
    setSearchTxt(v)
  }
  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      searchText(e)
    }
  }


  return (
    <div className="flex flex-row items-center px-1 space-x-1 sm:space-x-0">
      <div className="block md:hidden">
        <MenuMobile />
      </div>
      <InputFlatBorder
        type="text"
        style={{ flex: 1, mb: 4, paddingRight: 0 }}
        placeholder={placeholder ? placeholder : t("items_or_stores")}
        onChange={handleWrite}
        onKeyPress={handleKeyPress}
        value={searchTxt}
        defaultValue={keywordSearch}
        leftIcon={
          !resize && (
            <>
              {showOption && (
                <FilterSelect
                  data={[
                    { title: t("products"), value: "products" },
                    { title: t("stores"), value: "stores" },
                  ]}
                  display={(v) => v?.title}
                  defaultText={
                    searchElement === "products" ? t("products") : t("stores")
                  }
                  onChange={onSelectSearchElement}
                  key={1}
                />
              )}
              <RenderLeftIcon />
            </>
          )
        }
      />
      {resize && (
        <FilterSelect
          data={[
            { title: t("products"), value: "products" },
            { title: t("stores"), value: "stores" },
          ]}
          display={(v) => v?.title}
          defaultText={
            searchElement === "products" ? t("products") : t("stores")
          }
          onChange={onSelectSearchElement}
          key={1}
        />
      )}
    </div>
  );
}
