import React from 'react'
import LocalPhoneOutlinedIcon from '@mui/icons-material/LocalPhoneOutlined';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import './styles.css'
import Header from '../Header';
import CheckoutBar from '../../layout/CheckoutBar';
import { useSelector } from 'react-redux';
import { classNames } from '../../../utilities';
import { useMobile } from '../../../app/hooks/useMobileDetection';
import { useLanguage } from '../../../app/hooks/useLanguage';
import SelectMenu from '../SelectMenus';

const AppBar = () => {
  const { header, checkout } = useSelector(state => state.auth)
  const { lng } = useSelector(state => state.app)
  const { changeLanguage } = useLanguage()

  const onChangeLanguage = (e) => {
    // if (isRealValue(lang)) {
    //   setSearchParams(p => {
    //     p.set("lang", e.value);
    //     return p
    //   })
    // }
    changeLanguage(e.value)
  }

  const Languages = [
    { name: { en: "English", fr: "Anglais" }, value: "en" },
    { name: { en: "French", fr: "Francais" }, value: "fr" }
  ]
  const { isLandscape, isMobile } = useMobile()
  const serviceNumber = '+237 690 86 87 91'
  const serviceMail = `info@aflp.com`;

  // React.useEffect(() => {
  //   if (isRealValue(lang)) {
  //     changeLanguage(lang === "fr" ? "fr" : lang === "en" ? "en" : lng)
  //   } else {
  //     setSearchParams(p => {
  //       p.set("lang", lng);
  //       return p
  //     });
  //   }
  // }, [window.location.pathname])

  return (
    <div
      className={classNames(
        isLandscape && isMobile ? "static z-50" : "sticky",
        "app-bar-container"
      )}
    >
      <div className="row-flex-space app-bar">
        <div className="row-flex">
          <a
            href={`tel:${serviceNumber}`}
            className="cursor-pointer row-flex"
            style={{ marginLeft: 8 }}
          >
            <LocalPhoneOutlinedIcon sx={{ fontSize: 22, color: "white" }} />
            <span
              className="link-text"
              style={{
                fontSize: 14,
                color: "white",
                fontWeight: "normal",
                marginLeft: 8,
              }}
            >
              {" "}{serviceNumber}{" "}
            </span>
          </a>
          <div className="cursor-pointer row-flex" style={{ marginLeft: 8 }}>
            <EmailOutlinedIcon sx={{ fontSize: 22, color: "white" }} />
            <a
              className="link-text"
              href={`mailto:${serviceMail}`}
              style={{
                fontSize: 14,
                color: "white",
                fontWeight: "normal",
                marginLeft: 8,
              }}
            >
              {` ${serviceMail} `}
            </a>
          </div>
        </div>
        <div className="row-flex ">
          <SelectMenu
            selectedValues={Languages?.find(l => l.value === lng)}
            classStyle='h-[30px]'
            data={Languages}
            onChange={onChangeLanguage}
            display={(v) => v?.name[lng]}
          />
        </div>
      </div>
      {header && <Header />}
      {checkout && <CheckoutBar />}
    </div>
  );
}

export default AppBar
