import {
  Box,
  CircularProgress,
  Container,
  Divider,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import React from "react";
import { motion } from "framer-motion";

import "../styles.css";
import TransactionHistComponentUser from "./TransactionHistComponentUser";
import WithdrawFormUser from "./WithdrawFormUser";
import TransfertFormUser from "./TransfertFormUser";
import DepositFormUser from "./DepositFormUser";
import PaymentFormUser from "./PaymentFormUser";
import { useSelector } from "react-redux";
import { useFormatter } from "../../../app/hooks/useFormatter";
import {
  useAllOrdersQuery,
  useCodeValuesQuery,
  useInfosAccountBankQuery,
} from "../../../app/services/Api";
import {
  getOrderOrderStatus,
  putOperationMethod,
  putOperationTransaction,
} from "../../../app/hooks";
import { isRealValue } from "../../../utilities";
import { IoRepeatSharp } from "react-icons/io5";
import { useTranslation } from "react-i18next";

const DashboardUser = () => {
  const [selectedLabel, setSelectedLabel] = React.useState("");
  const { t } = useTranslation();
  const { InfoAccount } = useSelector((state) => state.auth);
  const { display, putOperationTransactionData, formatPrice } = useFormatter();

  const { isLoading, refetch: refetchI } = useInfosAccountBankQuery();
  const { isLoading: isLoadingOP, refetch: refetchOP } = useAllOrdersQuery();
  const { isLoading: isLoadingOT, refetch: refetchOT } = useCodeValuesQuery(
    putOperationTransaction,
    { refetchOnReconnect: true }
  );
  const { isLoading: isLoadingO, refetch: refetchO } = useCodeValuesQuery(
    putOperationMethod,
    { refetchOnReconnect: true }
  );
  const { isLoading: isLoadingS, refetch: refetchS } = useCodeValuesQuery(
    getOrderOrderStatus,
    { refetchOnReconnect: true }
  );

  const refetch = () => {
    refetchI();
    refetchOT();
    refetchOP();
    refetchO();
    refetchS();
  };

  const handleFormChange = (formType) => {
    switch (selectedLabel) {
      case "payment":
        return <PaymentFormUser setForm={setSelectedLabel} />;
      case "deposit":
        return <DepositFormUser setForm={setSelectedLabel} />;
      case "transfer":
        return <TransfertFormUser setForm={setSelectedLabel} />;
      case "withdrawal":
        return <WithdrawFormUser setForm={setSelectedLabel} />;
      default:
        return null;
    }
  };
  const renderButton = () =>
    putOperationTransactionData?.map((item, i) => (
      <Grid key={i} item xs={12} sm={6} md={6} lg={3}>
        <div className="my-0 shadow-sm ">
          <motion.div
            onClick={() => setSelectedLabel(item?.value)}
            whileTap={{ scale: 1.05 }}
            whileHover={{ scale: 1.025 }}
            className="rounded-3xl border border-[#D90429] shadow-md shadow-[#D9042955] drop-shadow-lg py-3 "
            style={{
              backgroundColor:
                selectedLabel === item?.value && "var(--primary)",
            }}
          >
            <Typography
              variant="inherit"
              sx={{
                fontSize: 18,
                color: selectedLabel === item?.value ? "white" : "black",
              }}
              className="flex items-center space-x-2 justify-center  "
            >
              <IoRepeatSharp
                className="w-6 h-6 mr-2 "
                color={
                  selectedLabel === item?.value ? "#FFF" : "var(--primary)"
                }
              />
              {display(item)}
            </Typography>
          </motion.div>
        </div>
      </Grid>
    ));

  return (
    <Container maxWidth="xl">
      {isLoading || isLoadingO || isLoadingOP || isLoadingOT || isLoadingS ? (
        <CircularProgress sx={{ color: "#D90429" }} />
      ) : (
        <Box flex={1}>
          <div className=" shadow-md rounded-md p-4 bg-white my-2 ">
            <Box>
              <Typography>
                {t("account")}: {InfoAccount?.userName}
              </Typography>
              <Box className="flex items-center justify-between">
                <Stack direction={"column"} spacing={2}>
                  <h5 className="text-[14px] font-semibold text-gray-400">
                    {t("balance")}:
                  </h5>
                  <h4 className="text-xl font-semibold text-[#D90429]">
                    {formatPrice(InfoAccount?.customer?.balance) || 0}
                  </h4>
                </Stack>
                <Stack direction={"column"} spacing={2}>
                  <h5 className="text-[14px] font-semibold text-gray-400">
                    {t("available Balance")}:
                  </h5>
                  <h4 className="text-xl font-semibold text-[#D90429]">
                    {formatPrice(InfoAccount?.customer?.availableBalance) || 0}
                  </h4>
                </Stack>
                <Stack direction={"column"} spacing={2}>
                  <h5 className="text-[14px] font-semibold text-gray-400">
                    {t("hold amount")}:
                  </h5>
                  <h4 className="text-xl font-semibold text-[#D90429]">
                    {formatPrice(InfoAccount?.customer?.holdAmount) || 0}
                  </h4>
                </Stack>
              </Box>
            </Box>
            <Grid container spacing={2} columns={12} my={2}>
              {renderButton()}
            </Grid>
          </div>
          <Divider color="#E2E2E2" />
          {!isRealValue(selectedLabel) ? (
            <TransactionHistComponentUser />
          ) : (
            handleFormChange(selectedLabel)
          )}
        </Box>
      )}
    </Container>
  );
};

export default DashboardUser;
