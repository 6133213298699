import React, { useState } from 'react'
import SizeUpdateTable from '../../components/CategorySizeUpdate/SizeUpdateTable';
import UpdateTable from '../../components/CategorySizeUpdate/UpdateTable';
import { isRealValue, scroolWindowToTop } from '../../utilities';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useGetListSizeOfStoreByCategoryLevel3ByLogForeignQuery } from '../../app/services/Api';
import LoadingComponent from '../../components/LoadingComponent';
import { MEDIA_URL } from '../../app/services';

const CategorySizeUpdate = () => {
  const { lng } = useSelector((state) => state.app);
  const params = useParams()
  const { t } = useTranslation();

  const storeKey = params?.key ?? ""
  const categoryKey = params?.category ?? ""

  const { data, isFetching: isLoading } = useGetListSizeOfStoreByCategoryLevel3ByLogForeignQuery({ storeKey, categoryKey }, { refetchOnMountOrArgChange: true, refetchOnReconnect: true })
  const [selectedSize, setSelectedSize] = useState([])
  const [sizes, setSizes] = useState([])
  const [Edit, setEdit] = useState(false);

  React.useEffect(() => {
    setSizes(data?.data?.sizes ?? [])
    scroolWindowToTop()
  }, [data])

  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <div className="flex items-center justify-start space-x-2 my-3">
        <img
          onContextMenu={(event) => {
            event.preventDefault();
            window.open(event?.target?.currentSrc, "_blank");
          }}
          src={
            isRealValue(data?.data?.store?.assets)
              ? MEDIA_URL + data?.data?.store?.assets[0]?.url
              : MEDIA_URL
          }
          alt=""
          className=" object-contain object-center w-[210px] h-[180px] rounded-md "
        />
        <h1 className="text-4xl font-bold tracking-tight text-[#D90429] sm:text-5xl md:text-6xl">
          {isRealValue(data?.data?.store?.name) ? data?.data?.store?.name[lng] : ""}
        </h1>
      </div>
      <div className="flex items-center justify-start space-x-2 mt-3 mb-5 ">
        <h3 className="w-[210px] text-gray-500 pl-8">{t("cat")}</h3>
        <h1 className="text-2xl font-bold tracking-tight text-[#D90429]">
          {isRealValue(data?.data?.category?.name) ? data?.data?.category?.name[lng] : ""}
        </h1>
      </div>
      <hr />
      {!Edit && (
        <SizeUpdateTable
          setSelectedSize={setSelectedSize}
          selectedSize={selectedSize}
          setEdit={setEdit}
          sizes={sizes}
          storeKey={storeKey}
        />
      )}
      {Edit && <UpdateTable sizeList={selectedSize} setEdit={setEdit} storeKey={storeKey} categoryKey={categoryKey} />}
      <div className="sm:flex sm:items-center">
        <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none"></div>
      </div>
      <LoadingComponent open={isLoading} />
    </div>
  );
}

export default CategorySizeUpdate;