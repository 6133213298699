import { useState } from "react";
import { Popover, Transition } from "@headlessui/react";
import { Bars3Icon } from "@heroicons/react/20/solid";
import { ClickAwayListener } from "@mui/material";
import { useSelector } from "react-redux";

import { dispatch } from "../../..";
import { useNavigate } from "react-router-dom";
import { PRODUCT } from "../../../constants/routes";
import { AnimatePresence, motion } from "framer-motion";
import { IoArrowBackSharp } from "react-icons/io5";
import { Api } from "../../../app/services/Api";
import { handleMenuLevel2, handleMenuLevel3, handleMenuLevel4, handleToSizesStore } from "../../../app/features/storeSlice";
import { handleApplyCategoryToFilter } from "../../../app/features/filterItemSlice";
import { handleKeyCat } from "../../../app/features/AppSlice";


function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function DropdownStoreMenu() {
  const {
    categoryLevel2,
    categoryLevel3,
    categoryLevel4,
    toViewSizeStore
  } = useSelector((state) => state.store);
  const { lng } = useSelector((state) => state.app);
  const { user, isLogged } = useSelector((state) => state.auth);
  const asAccess = isLogged && (user?.role === "logistician-foreign" || user?.role === "supervisor")

  const [showLevelTwo, setShowLevelTwo] = useState(false);
  const [showLevelThree, setShowLevelThree] = useState(false);
  const [showLastLevel, setShowLastLevel] = useState(false);
  const [checked, setChecked] = useState(null)

  const navigate = useNavigate();

  const handleCloseAll = (state) => {
    setShowLastLevel(false);
    setShowLevelThree(false);
    setShowLevelTwo(false);
  };

  const handleMenu2 = (parent, index) =>
    dispatch(handleMenuLevel2({ parent, index }));
  const handleMenu3 = (parent, index) =>
    dispatch(handleMenuLevel3({ parent, index }));

  const handleMenu = (category, index) => {
    handleCloseAll();
    dispatch(handleMenuLevel4({ category, index }));
    dispatch(handleApplyCategoryToFilter(category));
  };


  const handleForUpdateSizes = (key) => {
    setChecked(key)
    dispatch(handleToSizesStore(key))
  }


  const variantsAnime = {
    init: {
      opacity: 0,
      x: "300px",
    },
    visible: {
      opacity: 1,
      x: 0,
      transition: {
        duration: 0.3,
      },
    },
    exit: {
      opacity: 0,
    },
  };

  return (
    <ClickAwayListener onClickAway={handleCloseAll}>
      <div className="z-30 flex flex-row items-center ">
        <Popover className="relative w-[320px] z-30">
          <Popover.Button
            onClick={() => setShowLevelTwo(true)}
            className={classNames(
              showLevelTwo ? "text-white" : "text-gray-100",
              "group inline-flex items-center rounded-md w-[fit-content] p-2 bg-[var(--primary)]  text-base font-medium hover:text-white focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
            )}
          >
            <Bars3Icon
              className={classNames(
                showLevelTwo ? "text-white" : "text-white",
                "ml-2 h-6 w-6 group-hover:text-white"
              )}
              aria-hidden="true"
            />
            <span className="ml-3 text-xl font-semibold">Menu</span>
          </Popover.Button>
          <>
            <Transition
              as="div"
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel className="absolute z-30 w-screen max-w-xs px-2 mt-3 transform -translate-x-1/2 bg-gray-400 left-1/2 sm:px-0">
                <div className="relative w-[320px]">
                  <AnimatePresence exitBeforeEnter>
                    {showLevelTwo && (
                      <motion.div
                        variants={variantsAnime}
                        animate="visible"
                        initial="init"
                        exit="exit"
                        className="overflow-scroll no-scrollbar h-[fit-content] rounded-tl-md rounded-bl-md shadow-lg ring-1 ring-black ring-opacity-5"
                      >
                        <div className="relative px-5 py-6 bg-white sm:gap-8 sm:p-8">
                          {categoryLevel2?.map((category, i) => (
                            <a
                              key={category?.name[lng]}
                              onClick={() => {
                                // console.log(category);
                                handleMenu2(category?.key, i);
                                setShowLevelTwo(false);
                                setShowLevelThree(true);
                              }}
                              className="block p-3 -m-3 transition duration-150 ease-in-out rounded-md hover:bg-gray-50 cursor-pointer "
                            >
                              <p className="text-base font-medium text-gray-900 truncate line-clamp-1 ">
                                {category?.name[lng]}
                              </p>
                            </a>
                          ))}
                        </div>
                      </motion.div>
                    )}
                    {showLevelThree && (
                      <motion.div
                        variants={variantsAnime}
                        animate="visible"
                        initial="init"
                        exit="exit"
                        className="absolute  bg-white left-0 z-30 mt-[30px] w-screen max-w-xs -translate-x-1/2 transform px-2 sm:px-0"
                        static
                      >
                        <div className="overflow-scroll border-t border-b no-scrollbar h-[fit-content] ring-opacity-5">
                          <div
                            onClick={() => {
                              setShowLevelThree(false);
                              setShowLevelTwo(true);
                            }}
                            className="flex flex-row items-center ml-2 space-x-1 cursor-pointer "
                          >
                            <IoArrowBackSharp className="w-6 h-6 text-gray-600" />
                            <span className="text-xs font-medium text-gray-600">
                              Back
                            </span>
                          </div>
                          <div className="relative px-5 py-6 bg-white sm:gap-8 sm:p-8">
                            {categoryLevel3?.map((category, i) => (
                              <a
                                key={i}
                                className=" p-3 flex items-center space-x-4 -m-3 transition duration-150 ease-in-out rounded-md hover:bg-gray-50 cursor-pointer "
                              >
                                {asAccess && <input
                                  type="checkbox"
                                  className="  h-4 w-4 rounded border-gray-300 text-[#D90429] focus:ring-[#D90429]"
                                  checked={checked === category?.key}
                                  onChange={() => handleForUpdateSizes(category?.key)}
                                />}
                                <p
                                  onClick={() => {
                                    handleMenu3(category?.key, i);
                                    setShowLevelTwo(false);
                                    setShowLastLevel(true);
                                  }}
                                  className="text-base font-medium text-gray-900"
                                >
                                  {category?.name[lng]}
                                </p>
                              </a>
                            ))}
                          </div>
                        </div>
                      </motion.div>
                    )}

                    {showLastLevel && (
                      <motion.div
                        variants={variantsAnime}
                        animate="visible"
                        initial="init"
                        exit="exit"
                        className="absolute  bg-white left-0 z-30 mt-[30px] w-screen max-w-xs -translate-x-1/2 transform px-2 sm:px-0"
                        static
                      >
                        <div className="overflow-scroll border-t border-b no-scrollbar h-[fit-content] ring-opacity-5">
                          <div className="overflow-scroll no-scrollbar h-[fit-content] rounded-tr-md rounded-br-md shadow-lg ring-1 ring-black ring-opacity-5">
                            <div
                              onClick={() => {
                                setShowLastLevel(false);
                                setShowLevelThree(true);
                              }}
                              className="flex flex-row items-center ml-2 space-x-1 cursor-pointer "
                            >
                              <IoArrowBackSharp className="w-6 h-6 text-gray-600" />
                              <span className="text-xs font-medium text-gray-600">
                                Back
                              </span>
                            </div>

                            <div className="relative px-5 py-6 bg-white sm:gap-8 sm:p-8">
                              {categoryLevel4?.map((category, i) => (
                                <a
                                  key={category?.name[lng]}
                                  onClick={() => handleMenu(category?.key, i)}
                                  className="block p-3 -m-3 transition duration-150 ease-in-out rounded-md hover:bg-gray-50 cursor-pointer "
                                >
                                  <p className="text-base font-medium text-gray-900">
                                    {category?.name[lng]}
                                  </p>
                                </a>
                              ))}
                            </div>
                          </div>
                        </div>
                      </motion.div>
                    )}
                  </AnimatePresence>
                </div>
              </Popover.Panel>
            </Transition>
          </>
        </Popover>
      </div>
    </ClickAwayListener>
  );
}
