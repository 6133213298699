import React from 'react'
import { IoCashOutline, IoFolder } from 'react-icons/io5'
import InputFlatBorder from '../common/InputFlatBorder'
import { Controller, useForm } from 'react-hook-form'
import { useSelector } from 'react-redux'
import { useFormatter } from '../../app/hooks/useFormatter'
import LoadingButton from '../common/LoadingButton'
import useMutationApi from '../../app/hooks/useMutationApi'
import { isRealValue } from '../../utilities'
import { useTranslation } from 'react-i18next'

const WalletPayment = () => {
  const {
    isPaymentOrderByUser,
    isCreateOrder,
    buildOrderAndPayByUser,
  } = useMutationApi();
  const {t} = useTranslation()
  const { order } = useSelector(state => state.bookmarkCard);
  const { requireDeposit } = useSelector(state => state.orders);
  const { InfoAccount } = useSelector(state => state.auth);
  const { formatPrice, formatAmount } = useFormatter()

  const { control, handleSubmit, setError, formState: { errors } } = useForm({
    defaultValues: {
      operation: "payment",
      method: "wallet",
      amount: order?.order?.totalAmount.toString()
    }
  })

  const handleNext = (data) => {
    const body = {
      operation: data?.operation,
      method: data?.method,
      amount: parseInt(data.amount, 10)
    }
    buildOrderAndPayByUser(body)
  }

  return (
    <div className="max-w-4xl mx-auto " >
      <InputFlatBorder
        label={t("Operation")}
        rightIcon={<IoFolder className='h-5 w-5 text-[#D90429]' />}
        defaultValue="Payment"
        disabled />
      <Controller
        control={control}
        rules={{
          required: true, validate: v => {
            if (parseInt(v) < requireDeposit || parseInt(v) > order?.order?.totalAmount) {
              setError("amount", { type: "required" })
              return false
            }
            return true
          }
        }}
        name="amount"
        render={({ field: { onChange, value, onBlur } }) => (
          <InputFlatBorder
            label={t("Amount")}
            rightIcon={<IoCashOutline className='h-5 w-5 text-[#D90429]' />}
            placeholder={t('Amount')}
            onChange={onChange}
            value={value}
            type={"number"}
            error={errors.amount ? `${t('payment_btn')} ${formatAmount(requireDeposit)} - ${formatPrice(order?.order?.totalAmount)}` : undefined}
          />
        )}
      />
      <LoadingButton
        title={t("pay")}
        style={{ marginTop: 30, marginBottom: 30 }}
        onClick={handleSubmit(handleNext)}
        isLoading={isCreateOrder || isPaymentOrderByUser}
        disabled={requireDeposit < InfoAccount?.account?.availableBalance && !isRealValue(order?.order?.pickupLocation)}
      />
    </div>
  )
}

export default WalletPayment