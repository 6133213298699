import { AddOutlined, ChevronRight } from '@mui/icons-material'
import { Box, Button, CircularProgress, Container, Divider, Grid, Typography } from '@mui/material'
import React from 'react'
import { useSelector } from 'react-redux'
import { dispatch } from '../..'
import { handleSelected } from '../../app/features/bankSlice'
import { useAllAccountBankQuery, } from '../../app/services/Api'
import SimpleModal from '../../components/common/SimpleModal'
import Add from './Add'
import Update from './Update'
import ModalFlat from '../../components/ModalFlat'
import { scroolWindowToTop } from '../../utilities'

const Banks = () => {
  const { banks, selected } = useSelector(state => state.banks)
  const { isLoading } = useAllAccountBankQuery(null, { refetchOnReconnect: true, refetchOnMountOrArgChange: true })
  const [modalVisible, setModalVisible] = React.useState(false);
  const [modalVisible1, setModalVisible1] = React.useState(false);

  React.useEffect(() => {
    scroolWindowToTop()
  }, [])

  return (
    <Container >
      <SimpleModal open={modalVisible} handleClose={() => setModalVisible(false)} >
        <Add />
      </SimpleModal>

      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-xl font-semibold text-gray-900">Bank Accounts</h1>
          <p className="mt-2 text-sm text-gray-700">
            A list of all the bank accounts in your account including their name, account, and address.
          </p>
        </div>
        <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
          <button
            type="button"
            onClick={() => setModalVisible(true)}
            className="inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto"
          >
            Add New Bank Account
          </button>
        </div>
      </div>
      <br />
      {isLoading && <CircularProgress color='primary' />}
      <ul role="list" className="divide-y divide-gray-200">
        {
          !isLoading && banks?.length > 0 && banks?.map((bank, i) => {
            const setMode = (i, bool) => { dispatch(handleSelected(i)); setModalVisible1(bool); }
            return (
              <li
                key={i + bank}
                onClick={() => setMode(i, true)}
                className="relative bg-white rounded-sm py-5 px-4 my-[1px] focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 hover:bg-gray-50"
              >
                <div className="flex justify-between space-x-3">
                  <div className="min-w-0 flex-1">
                    <div className="block focus:outline-none">
                      <span className="absolute inset-0" aria-hidden="true" />
                      <p className="truncate text-sm font-medium text-gray-900">{`${bank.bank.name}`}</p>
                      <p className="truncate text-sm text-gray-500">{`${bank.bank.branch} : ${bank.bank.address}`}</p>
                    </div>
                  </div>
                  <p onClick={() => setMode(i, true)} className="flex-shrink-0 whitespace-nowrap cursor-pointer text-md text-indigo-500">
                    See Details
                  </p>
                </div>
                <div className="mt-1">
                  <p className="text-sm text-gray-600 line-clamp-2">{bank?.preview}</p>
                </div>
                <ModalFlat isOpen={selected === i && modalVisible1} closeModal={() => setMode(null, false)} title={bank?.bank?.name} >
                  <Update bank={bank} />
                </ModalFlat>
              </li>
            )
          })
        }
      </ul>

    </Container>
  )
}

export default Banks
