import { CircularProgress } from '@mui/material'
import React from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useResendOtpCodeMutation } from '../../../app/services/Api'
import { VERIFY_OTP_CODE } from '../../../constants/routes'
import Button from '../../common/Button'
import Input from '../../common/Input'
import './styles.css'

const ResendOtpForm = () => {
  const [resendOtpCode, { isLoading, error }] = useResendOtpCodeMutation()
  const { handleSubmit, control, formState: { errors } } = useForm({
    defaultValues: {
      "user": "",
      "phone": null,
      "firstName": ""
    }
  })

  const handleVerifyOtp = (data) => {
    resendOtpCode(data).unwrap().then((resp) => {
      if (resp?.response?.error === false) window.location.href = `${VERIFY_OTP_CODE}`
    })
  }

  return (
    <form className='reg-form' style={{ width: '100%' }} onSubmit={handleSubmit(handleVerifyOtp)} >
      {error && <span> {error?.data?.response?.message}</span>}
      <Controller
        control={control}
        rules={{
          required: true,
        }}
        render={({ field: { onChange, value } }) => (
          <Input
            type='text'
            placeholder='user id'
            value={value}
            onChange={onChange}
          />
        )}
        name="user"
      />
      {errors?.user && <span>user identifier required</span>}

      <Controller
        control={control}
        rules={{
          required: true,
        }}
        render={({ field: { onChange, value } }) => (
          <Input
            type='number'
            placeholder='237651...70'
            value={value}
            onChange={onChange}
          />
        )}
        name="phone"
      />
      {errors?.phone && <span>Phone number required</span>}

      <Controller
        control={control}
        rules={{
          required: true,
        }}
        render={({ field: { onChange, value } }) => (
          <Input
            type='text'
            placeholder='Ulrich Bertrand'
            value={value}
            onChange={onChange}
          />
        )}
        name="firstName"
      />
      {errors?.firstName && <span>First name required</span>}

      {isLoading ? <CircularProgress sx={{ color: "#D90429" }} /> :
        <Button
          title={"Resend code"}
          style={{ marginTop: 10 }}
          type="submit"
        />
      }
    </form>
  )
}

export default ResendOtpForm