import { useEffect, useState } from "react"

const getOnlineStatus = () => {
    return typeof navigator !== 'undefined' && typeof navigator.onLine === 'boolean' ? navigator.onLine: true
}

export function useNetworkOnline() {
    const [online, setOnline] = useState(getOnlineStatus())
    const goOnline = () => setOnline(true)
    const goOffline = () => setOnline(false)

    useEffect(() => {
        window.addEventListener('online', goOnline)
        window.addEventListener('offline', goOffline)
        return () => {
            window.removeEventListener('online', goOnline)
            window.removeEventListener('offline', goOffline)
        }
    }, [])
    return online
}