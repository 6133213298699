import { CircularProgress } from '@mui/material'
import React from 'react'
import Button from '../../common/Button'
import Input from '../../common/Input'
import './styles.css'
import { useNavigate, useParams } from 'react-router-dom';
import { SIGN_IN } from '../../../constants/routes';
import { Controller, useForm } from 'react-hook-form'
import { Visibility, VisibilityOff } from '@mui/icons-material'
import { useConfirmResetPasswordMutation } from '../../../app/services/Api'
import InputFlatBorder from '../../common/InputFlatBorder'
import { useTranslation } from 'react-i18next'
import useMutationApi from '../../../app/hooks/useMutationApi'

const ConfirmForgotForm = () => {
    const params = useParams()
    const userId = params?.userId
    const token = params?.token
    const [showPassword, setShowPassword] = React.useState(false)
    const [showPassword2, setShowPassword2] = React.useState(false);
    const toogleShowPassword = () => setShowPassword(!showPassword)
    const toogleShowPassword2 = () => setShowPassword2(!showPassword2);
    const [ errorPwd, setErrorPwd] = React.useState(null)

    // console.log('userId', token)
    
     const { t } = useTranslation();
     const {
       control,
       handleSubmit,
       watch,
       formState: { errors },
     } = useForm({ defaultValues: { confirmPassword: "", password: "" } });
     const { confirmResetPasswordByUser, isLoadingConfirmPassword } =
       useMutationApi();
     const handleUpdatePass = (data) =>{
      // { console.log("data", userId);  
      confirmResetPasswordByUser(userId, token, { password: data.password });
    }

    const Separator = ({ text, viewTest }) => (
        <div className='row-flex-space' style={{ width: '100%', justifyContent: 'space-around', }} >
            <div style={{ backgroundColor: 'lightgray', width: '35%', height: 1 }} ></div>
            {viewTest && <p style={{ fontSize: 18, fontWeight: 'bold', color: 'lightgray' }} > {text} </p>}
            <div style={{ backgroundColor: 'lightgray', width: '35%', height: 1 }}  ></div>
        </div>
    )
   
   return (
     <form
       className="reg-form"
       style={{ width: "100%" }}
       onSubmit={handleSubmit(handleUpdatePass)}
     >
       <Controller
         control={control}
         rules={{
           required: true,
           minLength: 4,
           validate: () => {},
           // pattern:
         }}
         render={({ field: { onChange, value } }) => (
           <InputFlatBorder
             error={
               errors?.password
                 ? errors.password.type == "required"
                   ? "Mot de passe obligatoire"
                   : errors.password.type == "minLength"
                   ? "Entrer au moins 4 caractères."
                   : errors.password.type == "validate"
                   ? "Mots de passe non identiques."
                   : undefined
                 : undefined
             }
             type={showPassword ? "text" : "password"}
             name={"password"}
             placeholder="New password"
             value={value}
             onChange={onChange}
             rightIcon={
               showPassword ? (
                 <Visibility fontSize="small" onClick={toogleShowPassword} />
               ) : (
                 <VisibilityOff fontSize="small" onClick={toogleShowPassword} />
               )
             }
           />
         )}
         name="password"
       />
       <Controller
         control={control}
         rules={{
           required: true,
           minLength: 4,
           validate: (value) => {
             if (watch("password") != value) {
               return false;
             }
           },
         }}
         render={({ field: { onChange, value } }) => (
           <InputFlatBorder
             error={
               errors?.confirmPassword
                 ? errors.confirmPassword.type == "required"
                   ? "Mot de passe obligatoire"
                   : errors.confirmPassword.type == "minLength"
                   ? "Entrer au moins 4 caractères."
                   : undefined
                 : undefined
             }
             type={showPassword2 ? "text" : "password"}
             name={"confirm_pass"}
             placeholder="Confirm new password"
             value={value}
             onChange={onChange}
             rightIcon={
               showPassword2 ? (
                 <Visibility fontSize="small" onClick={toogleShowPassword2} />
               ) : (
                 <VisibilityOff
                   fontSize="small"
                   onClick={toogleShowPassword2}
                 />
               )
             }
           />
         )}
         name="confirmPassword"
       />

       {isLoadingConfirmPassword ? (
         <CircularProgress sx={{ color: "#D90429" }} />
       ) : (
         <Button title={"Confirm"} style={{ marginTop: 10, backgroundColor: '#D90429', }} type="submit" />
       )}
     </form>
   );
}

export default ConfirmForgotForm