import React from 'react'
import { useState } from 'react'
import { Tab } from '@headlessui/react'
import { motion } from 'framer-motion'
import Carousel from '../Carousel/Caroussel'
import { useSelector } from 'react-redux'
import { handleImgPreview, handleSlideImgPreview } from '../../app/features/itemSlice'
import { dispatch } from '../..'
import Video from '../common/video'


function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const ImagesSection = () => {

  const [isModalOpen, setIsModalOpen] = useState(false);
  const { item, previewImgUri, previewMedImg, previewType, previewImgIndex } = useSelector(state => state?.item)
  const openModal = () => {
    dispatch(handleSlideImgPreview())
    setIsModalOpen(true);
  };
  const closeModal = () => {
    setIsModalOpen(false);
  };
  const handleImgChange = (uri, type, index) => dispatch(handleImgPreview({ uri, type, index }))

  return (
    <div>
      <Tab.Group as="div" className="flex flex-col-reverse h-[fit-content]">
        {/* Image selector */}
        <div className="mx-auto mt-6 block w-full max-w-2xl sm:block lg:max-w-none">
          <Tab.List className="flex flex-row items-center flex-wrap justify-start space-x-3 space-y-3 ">
            {previewMedImg?.map((image, index) => (
              <Tab
                key={index}
                onClick={() => handleImgChange(image?.uri, "main", index)}
                className="relative flex h-16 w-16 cursor-pointer items-center justify-center rounded-md bg-red-600  text-sm font-medium uppercase text-gray-900 hover:bg-gray-50 focus:outline-none focus:ring focus:ring-opacity-50 focus:ring-offset-4 "
              >
                {({ selected }) => (
                  <>
                    <span className="absolute inset-0 overflow-hidden rounded-md">
                      {image?.uri.includes(".mp4") ? (
                        <div className="relative h-full w-full z-50">
                          <div className="absolute bg-black bg-opacity-30 h-full w-full z-40 flex flex-row items-center justify-center">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth={1.5}
                              stroke="currentColor"
                              className="w-10 h-10 text-white"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                              />
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M15.91 11.672a.375.375 0 010 .656l-5.603 3.113a.375.375 0 01-.557-.328V8.887c0-.286.307-.466.557-.327l5.603 3.112z"
                              />
                            </svg>
                          </div>
                          <img
                            onContextMenu={(event) => {
                              event.preventDefault();
                            }}
                            loading="lazy"
                            src={previewMedImg[1]?.uri}
                            alt=""
                            className="mx-auto object-cover object-center"
                          />
                        </div>
                      ) : (
                        <img
                          onContextMenu={(event) => {
                            event.preventDefault();
                          }}
                          loading="lazy"
                          src={image?.uri}
                          alt=""
                          className="mx-auto object-cover object-center"
                        />
                      )}
                    </span>
                    <span
                      className={classNames(
                        previewType === "main" && previewImgIndex === index
                          ? "ring-[#D90429]"
                          : "ring-transparent",
                        "pointer-events-none absolute inset-0 rounded-md ring-2 ring-offset-2"
                      )}
                      aria-hidden="true"
                    />
                  </>
                )}
              </Tab>
            ))}
          </Tab.List>
        </div>

        <Tab.Panels className="max-w-[600px] max-h-[600px] ">
          {previewMedImg?.map((image, index) => (
            <Tab.Panel key={index}>
              {!previewImgUri.includes(".mp4") && (
                <img
                  onContextMenu={(event) => {
                    event.preventDefault();
                  }}
                  loading="eager"
                  onClick={openModal}
                  src={previewImgUri}
                  alt={image.alt}
                  className="sm:rounded-lg mx-auto cursor-pointer"
                />
              )}
              {previewImgUri.includes(".mp4") && (
                <Video
                  poster={previewMedImg[0]?.uri}
                  src={previewImgUri}
                  height={600}
                  width={`100%`}
                />
              )}
            </Tab.Panel>
          ))}
        </Tab.Panels>
      </Tab.Group>

      {isModalOpen && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          className="fixed top-0 z-[130] left-0 right-0 bottom-0 flex items-center justify-center bg-black bg-opacity-50 "
          onClick={closeModal}
        >
          <button
            className="absolute z-[130] top-10 left-4 text-white bg-[#D90429] flex flex-row justify-center items-center rounded-full h-12 w-12"
            onClick={closeModal}
          >
            <svg
              Xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
          <motion.div
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
            className="relative w-[800px] h-[800px] bg-gray-50 flex flex-col justify-center items-center"
            onClick={(e) => e.stopPropagation()}
          >
            {/* Render carousel content */}
            {/* You can use your preferred carousel library or build a custom one */}
            <Carousel images={item?.pictures} />
          </motion.div>
        </motion.div>
      )}
    </div>
  );
}

export default ImagesSection