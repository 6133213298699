import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import useMutationApi from "../../app/hooks/useMutationApi";
import { isRealValue } from "../../utilities";
import LoadingComponent from "../LoadingComponent";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function UpdateTable({
  sizeList = [],
  setEdit = () => { },
  storeKey,
  categoryKey,
}) {
  const { t } = useTranslation();
  const { updateSizeCategoryStoreByLogistician, isLoadingUpdateSizeStore } = useMutationApi();
  function getFormModel() {
    let form = {};
    sizeList?.forEach((size, index) => {
      form[`${index}_en`] = "";
      form[`${index}_fr`] = "";
    });
    return form;
  }

  const handleUpdateSize = (data) => {
    const body = sizeList?.map((size, index) => {
      const vfr = isRealValue(data[`${index}_fr`])
        ? { fr: data[`${index}_fr`] }
        : {};
      return {
        initial: size.propertyValue,
        corrected: {
          en: data[`${index}_en`],
          ...vfr,
        },
      };
    });
    updateSizeCategoryStoreByLogistician(storeKey, categoryKey, body, () => setEdit(false));
  };

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({ defaultValues: { ...getFormModel() } });

  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <form onSubmit={handleSubmit(handleUpdateSize)}>
        <div className="mt-8 flex flex-col">
          <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
              <div className="relative overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                <table className="min-w-full table-fixed divide-y divide-gray-300">
                  <thead className="bg-gray-50">
                    <tr>
                      <th
                        scope="col"
                        className="min-w-[12rem] py-3.5 px-3 text-left text-sm font-semibold text-gray-900"
                      >
                        Property Value
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        Corrected English
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        Corrigé français
                      </th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200 bg-white">
                    {sizeList.map((size, index) => (
                      <tr key={index}  >
                        <td
                          className={classNames(
                            "whitespace-nowrap py-4 px-3 text-sm font-medium"
                          )}
                        >
                          {size.propertyValue}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          <div className="mt-1 w-[fit-content] focus-within:border-[#D90429]">
                            <Controller
                              control={control}
                              rules={{ required: true }}
                              render={({
                                field: { onChange, value, onBlur },
                              }) => (
                                <input
                                  type="text"
                                  className={classNames(
                                    "block w-[fit-content] border-0 border-b border-transparent bg-gray-50 focus:border-[#D90429] focus:ring-0 sm:text-sm",
                                    errors[`${index}_en`]
                                      ? "border-amber-900"
                                      : " border-[#D90429]"
                                  )}
                                  placeholder=""
                                  value={value}
                                  onChange={onChange}
                                  onBlur={onBlur}
                                />
                              )}
                              name={`${index}_en`}
                            />
                          </div>
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          <div className="mt-1 w-[fit-content] focus-within:border-[#D90429]">
                            <Controller
                              control={control}
                              rules={{ required: false }}
                              render={({
                                field: { onChange, value, onBlur },
                              }) => (
                                <input
                                  type="text"
                                  className="block w-[fit-content] border-0 border-b border-transparent bg-gray-50 focus:border-[#D90429] focus:ring-0 sm:text-sm"
                                  placeholder=""
                                  value={value}
                                  onChange={onChange}
                                  onBlur={onBlur}
                                />
                              )}
                              name={`${index}_fr`}
                            />
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>

        <div className=" left-12 my-3 flex h-12 items-center justify-end space-x-3 bg-gray-50 sm:left-16">
          <button
            type="button"
            onClick={() => setEdit(false)}
            className="inline-flex items-center justify-center px-4 py-2 text-sm font-medium text-white border border-transparent rounded-md shadow-sm bg-[#D90429] hover:bg-[#D9042990] focus:outline-none focus:ring-2 focus:ring-[#D90429] focus:ring-offset-2 sm:w-auto"
          >
            {t("cancel")}
          </button>
          <button
            type={"submit"}
            className="inline-flex items-center justify-center px-4 py-2 text-sm font-medium text-white border border-transparent rounded-md shadow-sm bg-[#D90429] hover:bg-[#D9042990] focus:outline-none focus:ring-2 focus:ring-[#D90429] focus:ring-offset-2 sm:w-auto"
          >
            {"OK"}
          </button>
        </div>
      </form>
      <LoadingComponent open={isLoadingUpdateSizeStore} />
    </div>
  );
}
