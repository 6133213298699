import { Box, Grid, styled, Button, Typography, Paper, InputBase, alpha } from "@mui/material";

//main container page
export const Container = styled(Box)(({ theme }) => ({
    padding: "0px 20px 0px 40px",
    [theme.breakpoints.down('md')]: {
        padding: 1,
        paddingTop: 3,
    },
}));
export const ContainerMobile = styled(Box)(() => ({

}));

//main content page
export const ContentDesktop = styled(Grid)(() => ({
    marginTop: 10,
}));
export const ContentMobile = styled(Box)(() => ({
    width: "100%",
    maxWidth: "99%",
}));

// content filter
export const ContentFilterDesktop = styled(Grid)(() => ({
    marginRight: "0.3em",

}));
export const ContentFilterMobile = styled(Box)(() => ({
    display: "flex",
    flexDirection: "row",
}));

export const CardContentBox = styled(Box)(({ theme }) => ({
    [theme.breakpoints.down('md')]: {
        display: "flex",
        flexDirection: "row",
        justifyContent: 'left',
        alignItems: "center",
        marginLeft: -12,
        paddingTop: 5,
        paddingLeft: 5,
        marginTop: -3,
        overflow: "hidden",
        overflowX: "scroll",
        maxWidth: "197%",
        width: "80%",
        height: "25px",
    },
}));
export const ContainerSort = styled(Grid)(({ theme }) => ({
    [theme.breakpoints.down('md')]: {
        maxWidth: "100%",
        display: "flex",
        width: "100%",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "left",
        marginBottom: 3,
        paddingLeft: 0,
        position: "relative",
    }
}));
export const ContentSortDesktop = styled(Grid)(() => ({
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: 3,
    paddingLeft: 1,
}));
export const ContentSortMobile = styled(Box)(() => ({
    alignItems: "right",
    display: "flex",
    flexDirection: "row",
    position: "absolute",
    right: 1,
}));
export const Chips = styled(Button)(({ theme }) => ({
    marginRight: "0.2rem",
    marginBottom: "0.5rem",
    fontSize: "14px",
    padding: 1,
    paddingLeft: 3,
    paddingRight: 3,
    color: "#646464",
    borderRadius: 30,
    border: "1px solid gray",
    fontFamily: 'Futura, Medium, serif',
    textAlign: "center",
    textTransform: "none",
    [theme.breakpoints.down('md')]: {
        padding: 0,
        marginRight: "0.2rem",
        fontSize: "12px",
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        minWidth: 90,
    },
}));
export const PathUrl = styled(Typography)(({ theme }) => ({
    fontSize: '13px',
    opactiy: 0.7,
    color: '#979797',
    marginTop: '1em',
    marginBottom: '1em',
    [theme.breakpoints.down('md')]: {
        textAlign: "center",
        marginTop: 0,
    },
}));
export const PathTitle = styled(Typography)(({ theme }) => ({
    fontSize: '1.28em',
    marginTop: '0.8em',
    color: '#646464',
    fontWeight: 'bold',
    [theme.breakpoints.down('md')]: {
        textAlign: "center",
        marginTop: 0,
        marginBottom: 10,
    },
}));
export const FilterOption = styled(Typography)(() => ({
    fontWeight: 'bold',
    fontFamily: 'Futura',
    fontSize: '16px',
    color: '#303030',
}));
export const GridItems = styled(Grid)(({ theme }) => ({
    [theme.breakpoints.down('md')]: {
        margin: 0,
        alignItems: "center",
        padding: 0,
        justifyContent: "center",
    },

}));

export const FilterButton = styled(Button)(() => ({
    '&:hover': {
        borderColor: "#646464",
    },
    border: "none",
    color: "#646464",
    fontWeight: 400,
    fontSize: 20,
    padding: 0,
    marginLeft: 0,
    marginRight: 15,
}));

export const PaginationButton = styled(Paper)(() => ({
    borderColor: "#303030",
    fontSize: "12px",
    width: "32px",
    height: "32px",
    fontFamily: 'Roboto Regular',
    textAlign: "center",
    justifyContent: "center",
    alignItems: "center",
    margin: "6px",
    color: "#303030",
}));

export const SearchContainer = styled(Box)(() => ({
    justifyContent: 'center',
    alignItems: 'center',
    // width: '95%',
    margin: "auto",
    // marginTop:2,
}));

export const SearchBar = styled(Paper)(() => ({
    justifyContent: 'center',
    height: 50,
    width: "80%",
    padding: 2,
    paddingRight: 0,
    marginTop: 5,
    marginRight: 20,
    border: "1px solid gray",
    borderRadius: "20px",
    background: "#00000008"
}));

export const InputSearch = styled('input')(() => ({
    height: '100%',
    width: '100%',
    border: "none",
    outline: "none",
    backgroundColor: "#00000008",
    borderRadius: "20px 20px 20px 20px",
    '&:focus': {
        border: "none",
    },
    paddingLeft: 60,
}));

export const Search = styled('div')(({ theme }) => ({
    position: 'relative',
    border: "1px solid gray",
    justifyContent: "center",
    borderRadius: 20,
    '&:hover': {
        backgroundColor: alpha(theme.palette.common.white, 0.25),
        border: "1px solid #D90429",
    },
    marginRight: theme.spacing(2),
    marginTop: theme.spacing(0.5),
    maxWidth: '400px',
    minWidth: '400px',
    width: '400px',
    [theme.breakpoints.up('sm')]: {
        width: '200px',
    },
}));
export const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    top: 0,
    right: 0,
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: "white",
    borderRadius: "0px 20px 20px 0px",
    backgroundColor: "#D90429",
}));
export const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    fontFamily: 'Futura',
    '& .MuiInputBase-input': {
        padding: theme.spacing(1, 8, 1, 0),
        paddingLeft: `calc(1em + ${theme.spacing(0)})`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('md')]: {
            width: '20ch',
        },
    },
}));

export const GridNotFound = styled(Box)(() => ({
    width: "100%",
    height: "250px",
    textAlign: "center",
    alignItems: "center",
    justifyContent: "center",
}));

export const InputLimit = styled('input')(() => ({
    border: "0px",
    textAlign: "center",
    background: "none",
    width: "fit-content",
    marginRight: "10px",
    '&:hover': {
        border: "none",
    },
    '&:focus': {
        border: "none",
    },
}));