import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    pagination :{
        "total": 0, 
        "count": 0,
        "perPage": 0,
        "currentPage": 0, 
        "totalPages": 0,
        "nextLink": ""
    },
    stores: [],
    storesFilter : [],
    grade: "",
    isLoading: false,
    page: 1,
    limit: 50,
    keyword: ''
}

const storesSlice = createSlice({  
    name: 'stores',
    initialState,
    reducers: {
        handleSetLoading(state, action){
            state.isLoading= action.payload
        },
        handleStoreChange(state, action) {
            let search = action.payload
            if(search ==='') state.storesFilter = state.stores
            else state.storesFilter = state.stores.filter(store => {
                return store?.name?.en?.toLowerCase().includes(search?.toString().toLowerCase())
                || store?.name?.fr?.toLowerCase().includes(search?.toString().toLowerCase())
                || store?.grade?.toLowerCase().includes(search?.toString().toLowerCase())
            })
        },
        handleGetAllStoresPages(state, action) {
            state.stores = action.payload?.stores
            state.pagination = action.payload?.pagination
            state.storesFilter = action.payload?.stores
        },
        handleGetAllStores(state, action) {
            const { stores, pagination } = action.payload
            state.stores = state.stores.concat(stores)
            state.storesFilter = state.stores
            state.pagination = pagination
        },
        // handleByGrade(state, action) {
        //     const grade = action.payload
        //     if(grade==='All'){ state.storesFilter = stores;
        //         state.grade = ""
        //     }
        //     else{
        //         state.storesFilter = state.stores.filter(store => store.grade===grade)
        //         state.grade = grade
        //     }
        // },
        handleByName(state, action){
            const {lng, keyword} = action.payload
            if(keyword!==""){
                state.keyword = keyword
                state.storesFilter = state.stores.filter(store => store.name[lng].toLowerCase().includes(keyword.toLowerCase())) 
            }else{
                state.keyword = keyword
                state.storesFilter = state.stores
            }
        },
        handlePagePagination(state) {
            state.page = state.pagination.totalPages > state.page ? state.page +1 : state.pagination?.totalPages
        },

        
    },
 })

 export const { 
    handleSetLoading, 
    handleStoreChange, 
    handlePagePagination, 
    handleGetAllStoresPages,
    handleGetAllStores,
    handleByGrade,
    handleByName,

} = storesSlice.actions
 export default storesSlice