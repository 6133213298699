import React from 'react'
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNotifications } from '../../app/hooks/useNotifications';



const BankLocationList = ({ locationsBanks, lng, navigate }) => {
    const {  selected, opened } = useSelector((state) => state.banks);
    const { dangerNotification } = useNotifications()
    const { t } = useTranslation();

    const [currentPosition, setCurrentPosition] = React.useState(null);

    React.useEffect(() => {
      // Function to get the current position
      const getCurrentPosition = () => {
        if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition(
            (position) => {
              const { latitude, longitude } = position.coords;
              setCurrentPosition({ latitude, longitude });
            },
            (error) => {
              dangerNotification("Error getting current position:"+error.message);
            }
          );
        } else {
          console.error("Geolocation is not supported by this browser.");
        }
      };

      getCurrentPosition();
    }, []);


  const openGoogleMaps = (latitude, longitude) => {
    const url = `https://www.google.com/maps?q=${latitude},${longitude}`;
    window.open(url, "_blank");
  };

  const openGoogleMapsDirections = (latitude, longitude) => {
    if (currentPosition != null) {
      const url = `https://www.google.com/maps/dir/${currentPosition?.latitude},${currentPosition?.longitude}/${latitude},${longitude}`;
      window.open(url, "_blank");
      return;
    }

    dangerNotification("Error getting current position. Try again");
  };


  return (
    <div className="max-w-5xl w- h-1/4 overflow-scroll">
      <div className="mt-6 flow-root">
        <ul role="list" className="-my-5 divide-y divide-gray-200">
          {locationsBanks
            ?.filter((l) =>
              l.code?.toString().includes(opened?.code.toString())
            )
            ?.map((bank) => (
              <li key={bank.id} className="py-5">
                <div className="relative focus-within:ring-2 focus-within:ring-indigo-500">
                  <h3 className="text-[16px] font-semibold text-gray-800">
                    <a className="hover:underline focus:outline-none">
                      {/* Extend touch target to entire panel */}
                      <span className="absolute inset-0" aria-hidden="true" />
                      {bank?.name[lng]}
                    </a>
                  </h3>
                  <h3 className="text-sm font-medium text-[#D90429]">
                    <a href="#" className="hover:underline focus:outline-none">
                      {/* Extend touch target to entire panel */}
                      <span className="absolute inset-0" aria-hidden="true" />
                      {bank?.address[lng]}
                    </a>
                  </h3>
                  <p className="mt-1 text-sm text-gray-600 line-clamp-2">
                    {bank?.openingHour[lng]}
                  </p>
                  <br />
                  <div className="flex items-center space-x-1">
                    <div
                      onClick={() =>
                        openGoogleMaps(
                          bank?.geometry?.coordinates[1],
                          bank?.geometry?.coordinates[0]
                        )
                      }
                      className=" cursor-pointer h-[fit-content] w-[fit-content] p-1 rounded-3xl drop-shadow-sm border-2 border-[#D90429] "
                    >
                      <div>
                        <p className="text-[10px] text-gray-700">
                          {t("map_view")}
                        </p>
                      </div>
                    </div>
                    <div
                      onClick={(e) =>
                        openGoogleMapsDirections(
                          bank?.geometry?.coordinates[1],
                          bank?.geometry?.coordinates[0]
                        )
                      }
                      className=" cursor-pointer h-[fit-content] w-[fit-content] p-1 rounded-3xl drop-shadow-sm border-2 border-[#D90429] "
                    >
                      <div>
                        <p className="text-[10px] text-gray-700">
                          {t("get_dir")}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
            ))}
        </ul>
      </div>
      {/* <div className="mt-6">
        <a
          href="#"
          className="flex w-full items-center justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50"
        >
          View all
        </a>
      </div> */}
    </div>
  );
};

export default BankLocationList