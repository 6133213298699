import React from 'react'
import './styles.css'
import DashboardEmployees from './Employees';
import DashboardUser from './Users';
import { useSelector } from 'react-redux';

const Dashboard = () => {
    const { user } = useSelector(state => state.auth)
    return (
        <>
            {user?.role==='customer' ? <DashboardUser /> : <DashboardEmployees />}
        </>
    )
}

export default Dashboard