import React from 'react'
import { PaperClipIcon } from '@heroicons/react/20/solid'
import ButtonFlat from '../ButtonFlat'

const LocationDetails = () => {
  return (
      <div className="overflow-hidden bg-white sm:rounded-lg max-w-5xl ">
          <div className="px-4 py-5 sm:px-6">
              <h3 className="text-md font-medium leading-6 text-gray-700">Location Code</h3>
              <p className="mt-1 max-w-2xl text-sm text-gray-500">CMRCRTRT05</p>
          </div>
          <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
              <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                  <div className="sm:col-span-1">
                      <dt className="text-sm font-medium text-gray-500">Name</dt>
                      <dd className="mt-1 text-sm text-gray-900">Margot Foster</dd>
                  </div>
                  <div className="sm:col-span-1">
                      <dt className="text-sm font-medium text-gray-500">Type</dt>
                      <dd className="mt-1 text-sm text-gray-900">Backend Developer</dd>
                  </div>
                  <div className="sm:col-span-1">
                      <dt className="text-sm font-medium text-gray-500">Address</dt>
                      <dd className="mt-1 text-sm text-gray-900">margotfoster@example.com</dd>
                  </div>
                  <div className="sm:col-span-1">
                      <dt className="text-sm font-medium text-gray-500">Service</dt>
                      <dd className="mt-1 text-sm text-gray-900">Pickup</dd>
                  </div>
                  <div className="sm:col-span-2">
                      <dt className="text-sm font-medium text-gray-500">Coordonnees</dt>
                      <dd className="mt-1 text-sm text-gray-900">
                          Longitude: 1.897826 <br />
                          Latitude: -23.44439 <br />
                          Type: Point 
                      </dd>
                  </div>
                  <div className="sm:col-span-1">
                      <dt className="text-sm font-medium text-gray-500">Country</dt>
                      <dd className="mt-1 text-sm text-gray-900">Cameroon</dd>
                  </div>
                  <div className="sm:col-span-1">
                      <dt className="text-sm font-medium text-gray-500">Region</dt>
                      <dd className="mt-1 text-sm text-gray-900">Littoral</dd>
                  </div>
                  <div className="sm:col-span-1">
                      <dt className="text-sm font-medium text-gray-500">Department</dt>
                      <dd className="mt-1 text-sm text-gray-900">Sanaga-Maritime</dd>
                  </div>
                  <div className="sm:col-span-1">
                      <dt className="text-sm font-medium text-gray-500">Arrondissement</dt>
                      <dd className="mt-1 text-sm text-gray-900">Edea 2e</dd>
                  </div>
                  <div className="sm:col-span-2">
                      <dt className="text-sm font-medium text-gray-500">Attachments</dt>
                      <div className="mt-1 flex flex-col md:flex-row justify-between items-center space-x-5">
                          <ButtonFlat title={"Disable"} />
                          <ButtonFlat title={"View on Map"} />
                      </div>
                  </div>
              </dl>
          </div>
      </div>
  )
}

export default LocationDetails