import React, { useRef } from 'react'
import { useSelector } from 'react-redux'
import { handleImgPreview, handleVariants } from '../../app/features/itemSlice'
import { dispatch } from '../..'
import { useTranslation } from 'react-i18next'
import { isRealValue } from '../../utilities'

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const VariantsSections = ({ item = {} }) => {
  const spanRef = useRef();

  const { t } = useTranslation()

  const { selectedVariants, selectableProperties, onActionUp } = useSelector((state) => state.item);
  const { lng } = useSelector((state) => state.app);
  const variantsConfigs = item?.variantsConfig ?? {};

  const descFunc = (p_id) => {
    for (const [pid, vid] of Object.entries(selectedVariants)) {
      if (p_id === pid)
        return variantsConfigs[pid]?.values[vid]?.propertyValue[lng];
    }
  };

  const VariantsTypes = ({ vid, pid, value, index, selectableList }) => {

    const { variantKey, pictureUrl, miniPictureUrl, propertyValue } = value;
    const handleImgChange = (uri, type, index) => {
      window.scrollTo({ behavior: "smooth", top: 0 });
      dispatch(handleVariants({ variantKey, vid, pid }));
      dispatch(handleImgPreview({ uri, type, index }));
    }
    const selectVariant = () => {
      dispatch(handleVariants({ variantKey, vid, pid }));
    }

    return pictureUrl && miniPictureUrl ? (
      <div className="relative ">
        <div
          style={isRealValue(onActionUp) && Object.keys(onActionUp)[0] !== pid && !selectableList.includes(vid) ?
            {
              pointerEvents: "none",
              opacity: 0.3
            } : {}
          }
          onClick={() => handleImgChange(pictureUrl, "variant", index)}
          className="relative flex h-14 w-14 cursor-pointer items-center justify-center rounded-md bg-[#D90429] text-sm font-medium uppercase text-gray-900 hover:bg-gray-50 focus:outline-none focus:ring focus:ring-opacity-50 focus:ring-offset-4 mr-1"
        >
          <>
            <span className="sr-only"> {propertyValue[lng]} </span>
            <span className="absolute inset-0 overflow-hidden rounded-md">
              <img onContextMenu={(event) => { event.preventDefault(); }}
                loading="lazy"
                src={pictureUrl}
                alt="item"
                className="mx-auto object-cover object-center"
              />
            </span>
            <span
              className={classNames(
                selectedVariants[pid] === vid
                  ? "ring-[#D90429]"
                  : "ring-transparent",
                "pointer-events-none absolute inset-0 rounded-md ring-2 ring-offset-2"
              )}
              aria-hidden="true"
            />
          </>
        </div>
      </div>
    ) : (
      <div
        onClick={(e) => { e.preventDefault(); selectVariant(); }}
        style={isRealValue(onActionUp) && Object.keys(onActionUp)[0] !== pid && !selectableList.includes(vid) ?
          {
            pointerEvents: "none",
            opacity: 0.3
          } : {}
        }
        className={classNames(
          isRealValue(onActionUp) && Object.keys(onActionUp)[0] !== pid && !selectableList.includes(vid) ? "bg-gray-200" : "",
          selectedVariants[pid] === vid ? "ring-1 ring-offset-1 ring-[#D90429]" : "",
          selectedVariants[pid] === vid
            ? "bg-[#D90429] border-transparent text-white hover:bg-[#D9042990]"
            : "bg-white border-gray-200 text-gray-900 hover:bg-gray-50",
          "cursor-pointer focus:outline-none border rounded-md py-2 px-2 flex items-center justify-center text-sm font-medium sm:flex-1 ",
        )}
      >
        <span
          ref={spanRef}
          onClick={(e) => {
            e.preventDefault();
          }}
          className="line-clamp-1 truncate break-words "
        >
          {propertyValue[lng] ?? ""}
        </span>
      </div>
    );
  }


  return (
    <div className="w-full ">
      {Object.keys(variantsConfigs)?.map((pid, i) => (
        <React.Fragment key={i}>
          <div className="mt-6" key={i + "pid"}>
            <h2 className="text-sm font-medium text-gray-900">
              {variantsConfigs[pid]?.propertyName[lng]}
            </h2>

            <div className="flex items-center flex-wrap gap-1 my-4 w-full mx-auto space-y-2 ">
              {Object.keys(variantsConfigs[pid]?.values).map((vid, index) => {
                // const colorName = variantsConfigs[pid];
                return (
                  <div key={index}>
                    <VariantsTypes
                      value={variantsConfigs[pid]?.values[vid]}
                      vid={vid}
                      pid={pid}
                      index={index}
                      selectableList={isRealValue(selectableProperties) && selectableProperties[pid] || []}
                    />
                  </div>
                );
              })}
            </div>
          </div>
          { }
          {Object.keys(selectedVariants).length > 0 &&
            // variantsConfigs[pid]?.propertyName[lng].toLowerCase().includes(t("color").toLowerCase())
            variantsConfigs[pid]?.havePicture && (
              <p className="text-[#D90429] text-sm font-medium">
                {descFunc(pid)}
              </p>
            )}
        </React.Fragment>
      ))}
    </div>
  );
};

export default VariantsSections;
