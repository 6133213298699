import React from 'react'
import CheckoutForm from './CheckoutForm'
import { loadStripe } from '@stripe/stripe-js'
import { Elements } from '@stripe/react-stripe-js'

const stripePromise = loadStripe("pk_test_51N0rbwCkDGyWUosUB3MNi4yloZ4BxO7PBohSkqOiURBPMOCRGTUJKlSYuq3VpeJf7gTgOy21UKGb3wse2Imp7N9B004zzuFRvF");

const appearance = {
  theme: 'flat',
  variables: {
    fontFamily: 'Futura-Medium, sans-serif',
    fontWeightNormal: '500',
    borderRadius: '8px',
    colorPrimary: '#D90429',
    colorPrimaryText: '#1A1B25',
    colorIconTab: 'white',
    colorLogo: 'dark'
  },
  rules: {
    '.Input, .Block': {
      backgroundColor: 'rgb(243 244 246)',
      border: '1.5px solid var(--primary)'
    }
  }
};

const options = {
    mode: 'payment',
    amount: 1099,
    currency: 'usd',
    // Fully customizable with appearance API.
    appearance: {
      ...appearance
    },
  };
const CheckoutFormWrapper = () => {
  return (
    <Elements stripe={stripePromise} options={options}>
      <CheckoutForm />
    </Elements>
  )
}

export default CheckoutFormWrapper