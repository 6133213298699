import React from 'react'
import { Controller, useForm } from 'react-hook-form';
import InputFlatBorder from '../../common/InputFlatBorder';
import SelectMenu from '../../common/SelectMenus';
import ButtonFlat from '../../ButtonFlat';

const Countries = [
    { id: 1, name: "Cameroon" }
]

const Division1 = [
    { id: 1, name: "Division1" }
]

const Division2 = [
    { id: 1, name: "Division2" }
]
const Division3 = [
    { id: 1, name: "Division3" }
]

const WithDivisions = () => {

    const { handleSubmit, control, setValue, setError, clearErrors, formState: { errors, } } = useForm({
        defaultValues: {
            description: '',
            code: "",
            country: "",
        }
    });

  return (
    <div>
        <Controller control={control} rules={{ required: true }}
            render={({ field: { onChange, onBlur, value } }) => (
                <InputFlatBorder
                  
                    value={value}
                    label={"Code"}
                    onChange={onChange}
                    name="Code"
                    autoComplete="code"
                    error={errors.code && "Required field"}
                />
            )}
            name="code"
        />

        <Controller control={control} rules={{ required: true }}
            render={({ field: { onChange, onBlur, value } }) => (
                <InputFlatBorder
                   name={"Description"}
                    value={value}
                    label={"Description"}
                    onChange={onChange}
                    error={errors.description && "Required field"}
                />
            )}
            name="description"
        />

        <div className="sm:col-span-4">
            <SelectMenu defaultText="Countries" data={Countries} />
        </div>

        <div className="sm:col-span-4">
            <SelectMenu defaultText="Division1" data={Division1} />
        </div>

        <div className="sm:col-span-4">
            <SelectMenu defaultText="Division2" data={Division2} />
        </div>

        <div className="sm:col-span-4">
              <SelectMenu defaultText="Division3" data={Division3} />
        </div>

        <ButtonFlat title={"Save"} />
    </div>
  )
}

export default WithDivisions