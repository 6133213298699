import { isRealValue } from "../../utilities"

// export const MEDIA_URL = process.env.MEDIA_URL || "https://cdn-dev.aflp.com"
// export const HOST_API = process.env.HOST_API || "http://sallaka.com:3000"
// export const HOST_CHAT = process.env.HOST_CHAT || "http://sallaka.com:5000"
// export const STRIPE_KEY = process.env.STRIPE_KEY || "pk_test_51N0rbwCkDGyWUosUB3MNi4yloZ4BxO7PBohSkqOiURBPMOCRGTUJKlSYuq3VpeJf7gTgOy21UKGb3wse2Imp7N9B004zzuFRvF"

export const MEDIA_URL = process.env.MEDIA_URL || "https://cdn.aflp.com"
export const HOST_API = process.env.HOST_API || "https://bkd.aflp.com"
export const HOST_CHAT = process.env.HOST_CHAT || "'https://cht.aflp.com"
export const STRIPE_KEY = process.env.STRIPE_KEY || "pk_live_51N0rbwCkDGyWUosUhltsYvYD8Fi5hJsp5FtqqO4akCQmTMRq3bJrR9LRkQAph25SuGBskx89dfChPFF7EuDr8RAC00YsjHqyaF"


export const baseUrl = `${HOST_API}/api/`
export const chatUrl = `${HOST_CHAT}/api`
export const appUrl = `${MEDIA_URL}/app/current/aflp.com.apk`

export const METHODS = {
    POST: 'POST',
    GET: 'GET',
    HEAD: 'HEAD',
    DELETE: 'DELETE',
    PUT: 'PUT',
    PATCH: 'PATCH',
    OPTIONS: 'OPTIONS',
};

export const ENDPOINTS = {
    SING_IN: 'auth/signin',
    SIGN_UP: 'users',
    SIGN_OUT: 'auth/signout',

    PASSWORD_RESET: 'password-reset',

    USERS: 'users',
    CATEGORIES: 'categories',
    STORES: 'stores',
    BRANDS: 'brands',
    ITEMS: 'itemviews',
    ITEMVIEWS: 'itemviews',
    BOOK_ITEMS: 'bookitems',
    BOOK_STORES: 'bookstores',
    CARTS: 'carts',
    ORDER: 'order',
    ORDERS: 'orders',
    ORDER_VIEWS: 'orderviews',
    REFRESH_TOKEN: 'userTokens',

    MESSAGES: 'messages',
    ROOMS: 'rooms',
    USER_CONTACT: 'usercontacts',
    ROLE_VALUES: "user/user_role_values",
    ADDRESS: "useraddresses",
    LOCATE_ADDRESS: "locateaddress",
    DELIVERABLE_ADDRESS: "deliverableaddresses",
    TERRITORY: "territory",
    BANKS: "banks",
    PURCHASE: "purchaseshipments",
    TRANSIT: "transitshipments",
    FULFILLMENtS: "fulfillments",
    BIllS: "bills",


};

export const maxRetries = 2
export const EXPIRED_TOKEN = 15 * 60000
export const EXPIRED_REFRESH_TOKEN = 60000 * 60 * 24 * 30
export const EXPIRED_DATA = 24 * 4 * 60 * 60 // 4 days
export const BAD_REQUEST = 400
export const BAD_REQUEST_MESSAGE = "jwt expired"

export const providesListWithItems = (results, arrayTagType) => {
    const list = { type: arrayTagType?.at(0), id: 'LIST' }
    if (isRealValue(results) || results?.length > 0) {
        const array = []
        results?.map(item => arrayTagType?.map(tagType => array.push(({ type: tagType, id: item?.key }))))
        return [list, ...array]
    }
    return [list]
}

export const providesKeyList = (results, tagType) => results ? [{ type: tagType, id: 'LIST' }, ...results.map((key) => ({ type: tagType, id: key })),] : [{ type: tagType, id: 'LIST' }]