export default function RadioGroupSimpleList({ value, setValue = () => { }, label, data }) {

  return (
    <div>
      <h5 className="text-md mt-2 font-medium leading-6 text-gray-700">{label}</h5>
      <div className="mt-4 divide-y divide-gray-200 border-t border-gray-200">
        {data.map((side, sideIdx) => (
          <div key={sideIdx} className="relative flex items-start py-4">
            <div className="min-w-0 flex-1 text-sm">
              <label as='dt' htmlFor={`${label}-${side.id}`} className="select-none text-sm font-medium text-gray-500">
                {side.name}
              </label>
            </div>
            <div className="ml-3 flex h-5 items-center">
              <input
                id={`${label}-${side.id}`}
                name={label}
                type="radio"
                defaultChecked={value === side.value}
                onChange={() => setValue(side.value)}
                className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-500"
              />
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}