import { Box } from '@mui/material'
import React from 'react'
import TransactionTableUser from './TransactionTableUser'
import { useNavigate } from 'react-router-dom'
import { DASHBOARD_HISTTRANSACTION } from '../../../constants/routes'
import { useTranslation } from 'react-i18next'

const TransactionHistComponentUser = () => {
  const navigate = useNavigate()
  const { t } = useTranslation()

  return (
    <React.Fragment>
      <h2
        sx={{
          fontSize: 22,
          fontWeight: "700",
          color: "black",
          my: 2,
        }}
      >

      </h2>
      <Box>
        <Box></Box>
        <TransactionTableUser />
        <div className="flex items-center justify-end space-x-4 my-1">
          <a
            onClick={() => navigate(DASHBOARD_HISTTRANSACTION)}
            className="whitespace-nowrap text-md font-medium text-[#D90429] hover:text-[#D90429] cursor-pointer "
          >
            {t("vw_all")}
            <span aria-hidden="true"> &rarr;</span>
          </a>
        </div>

      </Box>
    </React.Fragment>
  );
}

export default TransactionHistComponentUser