// import { Button, ButtonGroup } from '@mui/material';
import React from 'react'
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { dispatch } from '../..';
import { filterByMethod, filterByOperation, filterByState } from '../../app/features/usersSlice';
import { getOperationMethod, getOperationState, getOperationTransaction } from '../../app/hooks';
import { useFormatter } from '../../app/hooks/useFormatter';
import { useCodeValuesQuery, useGetClosedTransactionsByControllerSupervisorQuery, useGetOpenedTransactionsByControllerSupervisorQuery, useGetTransactionsByUserOrByOperatorControllerSupervisorQuery } from '../../app/services/Api';
import FilterSelect from '../../components/common/FilterSelect';
import LoadingComponent from '../../components/LoadingComponent';
import TransactionTableEmployee from '../Dashboard/Employees/TransactionTableEmployee';
import TransactionTableUser from '../Dashboard/Users/TransactionTableUser';
import { scroolWindowToTop } from '../../utilities';

const TransactionHistory = () => {
  const { user } = useSelector(state => state.auth)
  const { customerView } = useSelector(state => state.users)
  const [success, setSuccess] = React.useState(true)
  const isControllerOrSupervisor = user.role === "controller" || user.role === "supervisor"
  const { t } = useTranslation()

  const [openedTransaction] = React.useState(false)
  const { isFetching: isLoadingT } = useGetTransactionsByUserOrByOperatorControllerSupervisorQuery({ success }, { skip: !(user.role === "customer" || user.role === "user"), refetchOnMountOrArgChange: true, refetchOnReconnect: true })
  const { isFetching: isLoadingTE } = useGetTransactionsByUserOrByOperatorControllerSupervisorQuery({ success, account: customerView?.customer?.account }, { skip: !(user.role === "operator"), refetchOnMountOrArgChange: true, refetchOnReconnect: true })

  const { isFetching: isLoadingOT } = useGetOpenedTransactionsByControllerSupervisorQuery(null, { skip: !(isControllerOrSupervisor && openedTransaction === true), refetchOnReconnect: true })
  const { isFetching: isLoadingCT } = useGetClosedTransactionsByControllerSupervisorQuery(null, { skip: !(isControllerOrSupervisor && openedTransaction === false), refetchOnReconnect: true })

  const { isLoading: isLoadingSV } = useCodeValuesQuery(getOperationTransaction)
  const { isLoading: isLoadingST } = useCodeValuesQuery(getOperationState)
  const { isLoading: isLoadingM } = useCodeValuesQuery(getOperationMethod)


  const successData = [
    { value: false, name: { en: "No validated", fr: 'Non validatée' } },
    { value: true, name: { en: "Validated", fr: 'Validatée' } }
  ]

  const { display, getOperationTransactionData, getOperationStateData, getOperationMethodData } = useFormatter()

  const onChange = (v) => setSuccess(v.value)
  const onSelectState = (v) => dispatch(filterByState(v?.value))
  const onSelectOperation = (v) => dispatch(filterByOperation(v?.value))
  const onSelectMethod = (v) => dispatch(filterByMethod(v?.value))

  React.useEffect(() => {
    scroolWindowToTop()
  }, [])

  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-2xl font-semibold text-gray-900">
            {t("Transaction History")}
          </h1>
        </div>
      </div>
      <div className="flex flex-row justify-evenly ">
        {/* <ButtonGroup color="secondary" aria-label="medium secondary button group">
                    {
                        [t("Opened transaction"), t("Closed transaction")]?.map((s, index) => (
                            <Button
                                key={index}
                                onClick={e => onChangeState(e, s)}
                            >
                                {s}
                            </Button>

                        ))
                    }
                </ButtonGroup> */}
        <FilterSelect
          data={successData}
          display={display}
          defaultText={`${t("validted")} ?`}
          onChange={onChange}
          key={1}
        />
        <FilterSelect
          data={getOperationTransactionData}
          display={display}
          defaultText={t("Operation")}
          onChange={onSelectOperation}
          key={2}
        />
        <FilterSelect
          data={getOperationMethodData}
          display={display}
          defaultText={t("Method")}
          onChange={onSelectMethod}
          key={3}
        />
        <FilterSelect
          data={getOperationStateData}
          display={display}
          defaultText={t("State")}
          onChange={onSelectState}
          key={4}
        />
      </div>
      {user?.role === "customer" ? (
        <TransactionTableUser />
      ) : (
        <TransactionTableEmployee filters={true} />
      )}
      <LoadingComponent
        open={
          isLoadingT ||
          isLoadingST ||
          isLoadingSV ||
          isLoadingSV ||
          isLoadingCT ||
          isLoadingOT ||
          isLoadingTE ||
          isLoadingM
        }
      />
    </div>
  );
}

export default TransactionHistory;